import React from "react";
import {
  LinkWidget,
  Widget,
  WidgetHeader,
  WidgetList,
} from "../../../Shared/Components/Presentational/Widget";
import {
  CalendarEvent,
  CalendarResource,
} from "../../../Shared/Components/Presentational/Calendar";
import * as Routes from "../../../Constants/Routes";
import CalendarWidget from "./CalendarWidget";
import WelcomeMessageWidget from "./WelcomeMessageWidget";
import MenuWidget from "./MenuWidget";
import CalendarListWidget from "../../../Calendar/Components/Container/CalendarListWidget";
import Features from "../../../Constants/Features";
import FeatureToggle from "../../../Shared/Components/Presentational/FeatureToggle";
import "./HomePage.scss";
import NavOption from "../../../Shared/Components/Presentational/NavOption";
import TransactionWidget from "./TransactionWidget";
import { useTranslation } from "react-i18next";
import MyCalendarListHeader from "../../../Calendar/Components/Container/MyCalendarListHeader";
import ReservationLinks from "../../../Calendar/Components/Container/ReservationLinks";
//@ts-ignore
import Zoom from "react-reveal/Zoom";
import NewsCardContainer from "../../../Information/Components/Container/NewsCardContainer";

interface HomePageProps {
  calendarEvents: CalendarEvent[];
  resources: CalendarResource[];
  userFirstName: string;
  selectedLodge: DropdownListOption;
  hasSublettings: boolean;
  accountId: number;
  accountNumber: string;
  setAccountBalance: (newBalance: number) => void;
  setSelectedNewsItem: (selectedNewsItemId: string) => void;
  useMemberships: boolean;
}

const HomePage: React.FC<HomePageProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="home-page-container">
        <div className="widget-container">
          <WelcomeMessageWidget
            userFirstName={props.userFirstName}
            position={{
              columnIndex: 0,
              columnSpan: 3,
              rowIndex: 2,
              rowSpan: 2,
            }}
          />
          <FeatureToggle features={[Features.Transactions]}>
            <TransactionWidget
              position={{
                columnIndex: 0,
                columnSpan: 4,
                rowIndex: 4,
                rowSpan: 8,
              }}
              accountId={props.accountId}
              accountNumber={props.accountNumber}
              setAccountBalance={props.setAccountBalance}
            />
          </FeatureToggle>

          <MenuWidget
            position={{
              columnIndex: 5,
              columnSpan: 8,
              rowIndex: 2,
              rowSpan: 4,
            }}
          >
            <FeatureToggle features={[Features.ParkInfo]}>
              <LinkWidget
                to={Routes.MyParkAndHome}
                containerClassName="home-navigation"
              >
                <NavOption
                  text={t("navigation.myParkAndHome")}
                  icon="my-park-and-home.svg"
                  iconAlt="My park and home"
                />
              </LinkWidget>
            </FeatureToggle>
            {props.useMemberships ? (
              <LinkWidget
                to={Routes.MyGuests}
                containerClassName="home-navigation"
                id="MyMemberships"
              >
                <NavOption
                  text={t("navigation.myMemberships")}
                  icon="my-guests.svg"
                  iconAlt="My Members"
                />
              </LinkWidget>
            ) : (
              <FeatureToggle features={[Features.Passes]}>
                <LinkWidget
                  to={Routes.MyGuests2}
                  containerClassName="home-navigation"
                  id="MyGuests"
                >
                  <NavOption
                    text={t("navigation.myGuests")}
                    icon="my-guests.svg"
                    iconAlt="My Guests"
                  />
                </LinkWidget>
              </FeatureToggle>
            )}
            {props.hasSublettings && (
              <FeatureToggle features={[Features.SubletBookings, Features.OwnerBookings]}>
              <LinkWidget
                to={Routes.MyCalendar}
                containerClassName="home-navigation"
              >
                <NavOption
                  text={t("navigation.myCalendar")}
                  icon="my-calendar.svg"
                  iconAlt="My calendar"
                />
              </LinkWidget>
              </FeatureToggle>
            )}
            <FeatureToggle features={[Features.MyAccount]}>
              <LinkWidget
                to={Routes.MyAccount}
                containerClassName="home-navigation"
              >
                <NavOption
                  text={t("navigation.myAccount")}
                  icon="my-account.svg"
                  iconAlt="My account"
                />
              </LinkWidget>
            </FeatureToggle>
            <FeatureToggle features={[Features.MyRequests]}>
              <LinkWidget
                to={Routes.MyRequestsAndQueries}
                containerClassName="home-navigation"
              >
                <NavOption
                  text={t("navigation.myRequests")}
                  icon="my-requests.svg"
                  iconAlt="My requests"
                />
              </LinkWidget>
            </FeatureToggle>
          </MenuWidget>
          {props.hasSublettings && (
            <FeatureToggle features={[Features.SubletBookings, Features.OwnerBookings]}>
            <CalendarWidget
              events={props.calendarEvents}
              resources={props.resources}
              position={{
                columnIndex: 5,
                columnSpan: 4,
                rowIndex: 6,
                rowSpan: 6,
              }}
            />
            </FeatureToggle>
          )}

          {!props.hasSublettings && (
            <FeatureToggle
              features={[
                Features.Restaurant,
                Features.Activities,
                Features.Spa,
              ]}
              replacement={
                <Widget
                  containerClassName="home-park-image-widget-container"
                  position={{
                    columnIndex: 5,
                    columnSpan: 4,
                    rowIndex: 6,
                    rowSpan: 6,
                  }}
                >
                  <div className="home-reservations-header">
                    <WidgetHeader title={t("homePage.myPark")}></WidgetHeader>
                  </div>
                  <div className="park-home-park-image-container">
                    <Zoom>
                      <img
                        src={t("park.parkImage")}
                        alt="image of the park"
                      ></img>
                    </Zoom>
                  </div>
                </Widget>
              }
            >
              <Widget
                containerClassName="reservation-links-widget-container"
                position={{
                  columnIndex: 5,
                  columnSpan: 4,
                  rowIndex: 6,
                  rowSpan: 6,
                }}
              >
                <div className="home-reservations-header">
                  <WidgetHeader
                    title={t("myCalendar.reservations.header")}
                  ></WidgetHeader>
                </div>
                <ReservationLinks
                  showHeader={false}
                  lodgeId={Number(props.selectedLodge.value)}
                  accountId={props.accountId}
                />
              </Widget>
            </FeatureToggle>
          )}
          <FeatureToggle features={[Features.Events, Features.Offers]}>
            <CalendarListWidget
              position={{
                columnIndex: 9,
                columnSpan: 4,
                rowIndex: 6,
                rowSpan: 6,
              }}
              pageSize={3}
              canLoadMore={true}
              widgetComponents={{
                widgetHeader: <MyCalendarListHeader pageSize={3} />,
              }}
            />
          </FeatureToggle>
        </div>
      </div>
    </>
  );
};

export default HomePage;
