import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { Modal, ModalFooter, ModalProps } from '../../../Shared/Components/Presentational/Modal';
import './AddBookingModal.scss';
import AddBookingModalBookingForm from './AddBookingModalBookingForm';
import AddBookingModalMobileNumbersForm from './AddBookingModalMobileNumbersForm';
import AddBookingModalVehicleAccessForm from './AddBookingModalVehicleAccessForm';
import AddBookingsModalForms from '../../../Constants/Calendar/AddBookingsModalForms';
import { CalendarEvent } from '../../../Shared/Components/Presentational/Calendar';
import { Get, Post } from '../../../Api/ApiService';
import { PostSearchLodgeBookingsCall } from '../../../Api/Calls/PostSearchLodgeBookingsCall';
import moment from 'moment';
import { ConfirmModal } from '../../../Shared/Components/Presentational/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCoins} from '@fortawesome/free-solid-svg-icons';


//@ts-ignore
import Chips from 'react-chips';
import Chip from '../../../Shared/Components/Presentational/Chip';

interface SubletBookingInfoModalProps extends ModalProps {
   selectedCalendarEvent: CalendarEvent;
   setSelectedCalendarEvent: (event: CalendarEvent | undefined) => any
}

const SubletBookingInfoModal: React.FC<SubletBookingInfoModalProps> = (props) => {
    const { t } = useTranslation();

    const handleBackButton = () => {
        props.setSelectedCalendarEvent(undefined);
    };
    

    let bookingStartDate =null;
    let bookingDuration = null;

    if(props.selectedCalendarEvent ){
        bookingStartDate = props.selectedCalendarEvent.start ? moment(props.selectedCalendarEvent.start) : null;
        bookingDuration = bookingStartDate && props.selectedCalendarEvent.end ? moment(props.selectedCalendarEvent.end).diff(props.selectedCalendarEvent.start, "days") : null;
    }

    return (
        <>
            {props.selectedCalendarEvent &&  <Modal isOpen={props.isOpen} onRequestClose={handleBackButton} className={classNames('sublet-booking-info-modal', props.className)}>
                    <div  className="sublet-booking-info-header">
                        <div className="home-icon-container">
                        <FontAwesomeIcon icon={faCoins} size={"2x"}/>
                        </div >
                        <h1>
                            {bookingStartDate ? bookingStartDate.format("DD MMM YYYY") : '-'}  
                            {bookingDuration && bookingDuration > 0? ` for ${bookingDuration} night/s` : ''}
                        </h1>
                    </div>
                    {(props.selectedCalendarEvent.subletIncome != undefined || props.selectedCalendarEvent.subletCharges != undefined) ? (
                    <>
                        <span>{t('myCalendar.subletBookingInfo.subletValue')}</span>
                        <p> {props.selectedCalendarEvent.subletIncome != undefined? `£${props.selectedCalendarEvent.subletIncome!.toFixed(2)}` : "-"}</p>
                        {/* <span>{t('myCalendar.subletBookingInfo.cleaningFee')}</span>
                        <p> {props.selectedCalendarEvent.subletCharges != undefined? `£${props.selectedCalendarEvent.subletCharges!.toFixed(2)}` : "-"}</p> */}
                        <p className="sublet-booking-info-note">{t('myCalendar.subletBookingInfo.note')}</p>
                    </>
                    ) :  <p>{t('myCalendar.subletBookingInfo.bookingPending')}</p>}
                    <div className="sublet-booking-info-back-button" onClick={handleBackButton} >
                        <FontAwesomeIcon icon={faTimes} size={"2x"}/>
                    </div>
            </Modal> }
        </>
    );
};

export default SubletBookingInfoModal;
