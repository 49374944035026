import React from 'react';
import { ImageBackground } from '../../../Shared/Components/Presentational/Backgrounds';
import RegisterPopup from './RegisterPopup';

interface RegisterPageMobleProps {
    loginClick: (email: string, password: string, type: string) => void;
    isLoggingIn: boolean;
    loginFailureMessage: string;
}

const RegisterPageMobile: React.FC<RegisterPageMobleProps> = (props: RegisterPageMobleProps) => (
    <ImageBackground src="/images/mobile-login-image.svg" containerClassName="login-background">
        <RegisterPopup isLoggingIn={props.isLoggingIn} loginClick={props.loginClick} loginFailureMessage={props.loginFailureMessage} isMobile={true} />
    </ImageBackground>
);

export default RegisterPageMobile;
