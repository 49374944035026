import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MAKE_PAYMENT } from '../../Redux/ActionTypes';
import MakeAPaymentModalCardAddress from '../Presentational/MakeAPaymentModalCardAddress';
import { AppState } from '../../../RootReducer';

interface MakeAPaymentModalCardAddressContainerProps {
    onBackButtonClick: () => void;
    paymentAmount: number;
    accountNumber: string;
    makePayment: (paymentDetails: PaymentDetails) => void;
}

class MakeAPaymentModalCardAddressContainer extends Component<MakeAPaymentModalCardAddressContainerProps> {
    public render(): JSX.Element {
        return (
            <MakeAPaymentModalCardAddress
                paymentAmount={this.props.paymentAmount}
                accountNumber={this.props.accountNumber}
                onBackButtonClick={this.props.onBackButtonClick}
                makePayment={this.props.makePayment}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        accountNumber: state.user.lodge.AccountNo,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        makePayment: (paymentDetails: PaymentDetails) => {
            dispatch({ type: MAKE_PAYMENT, payload: paymentDetails });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeAPaymentModalCardAddressContainer);
