import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { ButtonContainer, InputContainer } from '../../../Shared/Components/Presentational/Containers';
import Version from '../../../Shared/Components/Presentational/Version'
import PasswordRequirements from '../../../Shared/Components/Presentational/PasswordRequirements';

interface RegisterPasswordProps {
    onSubmit: (e: any, password: string) => void;
    isRegistering: boolean;
    errorMessage: string;
    isMobile: boolean;
}

const getErrorMessage = (errorPlaceholder: string): string => {
    switch (errorPlaceholder) {
        case 'EMAIL_IN_USE':
            return 'register.emailInUse';
        case 'USER_NOT_FOUND':
            return 'register.emailNotAvailable';
        case 'PARCVU_ERROR':
        default:
            return 'register.genericError';
    }
};

export default function RegisterPassword(props: RegisterPasswordProps) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordValid, setPasswordValid] = useState(false);

    const CreateAccountButton = props.isMobile ? SecondaryButton : PrimaryButton;

    const { t } = useTranslation();

    return (
        <form name="form" onSubmit={e => props.onSubmit(e, password)}>
            <p className="register-step-title">{t('register.createAPassword')}</p>
            <div>
                <PasswordRequirements password={password} onPasswordChange={isValid => setPasswordValid(isValid)} />
                <InputContainer>
                    <input
                        name="password"
                        className={props.isMobile ? 'form-input-alternative' : 'form-input'}
                        type="password"
                        placeholder={t('register.passwordPlaceholder')}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        autoFocus={true}
                    />
                    <input
                        name="confirmPassword"
                        className={props.isMobile ? 'form-input-alternative' : 'form-input'}
                        type="password"
                        placeholder={t('register.confirmPasswordPlaceholder')}
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                </InputContainer>
                <p
                    className={classNames(
                        props.isMobile ? 'error-message-mobile login-failure-message register-error' : 'error-message',
                        {
                            show: password !== confirmPassword || props.errorMessage
                        }
                    )}
                >
                    {password !== confirmPassword && t('register.passwordRequirements.passwordsDontMatch')}
                    {password !== confirmPassword && props.errorMessage && <br />}
                    {props.errorMessage && <span className="register-error-inner-span" dangerouslySetInnerHTML={{ __html: t(getErrorMessage(props.errorMessage)) }} ></span>}
                </p>
            </div>
            <ButtonContainer className="register-button">
                <CreateAccountButton
                    type="submit"
                    disabled={
                        !password ||
                        !confirmPassword ||
                        !isPasswordValid ||
                        password !== confirmPassword ||
                        props.isRegistering
                    }
                    text={t('register.createAccount')}
                    isLoading={props.isRegistering}
                />
            </ButtonContainer>
            <div className="heading-center">
                <Version />
            </div>
        </form>
    );
}
