import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import UserRoles from '../../../Constants/UserRoles';
import { getOwnerMembershipAvailability } from '../../../User/Redux/ActionCreators';
import './RemainingMemberships.scss';

interface RemainingMembershipsProps {
  ownerMembershipsAvailability: OwnerMembershipAvailabilityDTO;
  getOwnerMembershipAvailability: () => void;
  userRoles: UserRoleDTO[];
}

const RemainingMemberships: React.FC<RemainingMembershipsProps> = (props) => {
  if (!props.ownerMembershipsAvailability) {
    props.getOwnerMembershipAvailability();
  }

  useEffect(() => {
    props.getOwnerMembershipAvailability();
  }, []);

  return (
    <>
      {props.userRoles && props.ownerMembershipsAvailability && (UserRoles.IsOwner(props.userRoles) || props.ownerMembershipsAvailability.allocated > 0) && (
        <div className='memberships-used-box used-box-display'>
          {props.ownerMembershipsAvailability && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>

              {props.ownerMembershipsAvailability ? (
                <p className='inline-p' style={{ marginLeft: "3px" }}>
                  {props.ownerMembershipsAvailability && props.ownerMembershipsAvailability.numberUsed} of {props.ownerMembershipsAvailability && props.ownerMembershipsAvailability.allocated} Club memberships used
                </p>
              ) : (
                <p className='inline-p' style={{ marginLeft: "3px" }}>
                  Club memberships remaining unavailable
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    ownerMembershipsAvailability: state.user.ownerMembershipsAvailability,
    userRoles: state.user.userRoles,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOwnerMembershipAvailability: () => {
      dispatch(getOwnerMembershipAvailability());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemainingMemberships);