import React, { Component } from 'react';
import './DropdownList.scss';
import classNames from 'classnames';
import { ValueType } from 'react-select/src/types';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

interface RadioButtonGroupProps extends WithTranslation {
  name: string;
  options: DropdownListOption[];
  selectedOption?: boolean | undefined;
  className?: string;
  classNamePrefix?: string;
  handleInputChange: any;
  isDisabled?: boolean;
  isLoading?: boolean;
}

class RadioButtonGroup extends Component<RadioButtonGroupProps> {
  constructor(props: RadioButtonGroupProps) {
    super(props);
  }


  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <div className={classNames('select-container', this.props.className)}>
        {this.props.options.map((v) => (
          <>
            <input
              type="radio"
              id={this.props.name + v.label}
              name={this.props.name}
              value={v.value}
              checked={this.props.selectedOption == v.value}
              onChange={this.props.handleInputChange}
            />
            <label htmlFor={this.props.name + v.label} className='select-text'>{t(v.label)}</label>
          </>
        ))}
      </div>
    )
  }
}

export default withTranslation()(RadioButtonGroup);