export class ParQFormInputs implements IParQFormInputs {
    heartConditionHistory?: FormInput;
    chestPainActivity?: FormInput;
    chestPainNoActivity?: FormInput;
    loseBalance?: FormInput;
    boneJointProblem?: FormInput;
    bloodPressureHeartMedication?: FormInput;
    otherReason?: FormInput;
    otherReasonComment?: FormInput;

    constructor() {
        this.heartConditionHistory = { errors: ["hasValue"] };
        this.chestPainActivity = { errors: ["hasValue"] };
        this.chestPainNoActivity = { errors: ["hasValue"] };
        this.loseBalance = { errors: ["hasValue"] };
        this.boneJointProblem = { errors: ["hasValue"] };
        this.bloodPressureHeartMedication = { errors: ["hasValue"] };
        this.otherReason = { errors: ["hasValue"] };
        this.otherReasonComment = { errors: [] };
    }
}