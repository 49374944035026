import React, { Component } from 'react';
import { connect } from 'react-redux';
import CalendarList, { CalendarListComponents } from '../Presentational/CalendarList';
import { getEvents, getOffers } from '../../Redux/ActionCreators';
import { WidgetPosition, Widget } from '../../../Shared/Components/Presentational/Widget';
import { SET_IS_EVENT_DETAILS_MODAL_OPEN, SET_PAGE_NUMBER, SelectedCalendarWidgetOptions } from '../../Redux/ActionTypes';
import { AppState } from '../../../RootReducer';
import { Entry } from 'contentful';

interface CalendarListWidgetProps {
    position: WidgetPosition;
    pageSize: number;
    canLoadMore: boolean;
    events: undefined | Entry<ContentfulEvent>[];
    eventTotal?: number;
    park?: any;
    getEvents: (userRoles: UserRoleDTO[]) => void;
    getOffers: () => void;
    loadMoreEvents?: (page: number) => void;
    widgetComponents?: CalendarListComponents;
    openEventDetailsModal?: () => void;
    closeEventDetailsModal?: () => void;
    setPageNumber: (pageNumber: number) => void;
    pageNumber: number;
    selectedFilter: SelectedCalendarWidgetOptions;
    offers: undefined | Entry<ContentfulOffer>[];
    offerTotal?: number;
    userRoles: UserRoleDTO[];
}

class CalendarListWidget extends Component<CalendarListWidgetProps> {

    public componentDidMount(): void {
        if (this.props.park) {
            this.props.setPageNumber(1);
            this.props.getEvents(this.props.userRoles);
            this.props.getOffers();
        }
    }

    public componentDidUpdate(prevProps: CalendarListWidgetProps): void {
        if (prevProps.park !== this.props.park) {
            this.props.setPageNumber(1);
            this.props.getEvents(this.props.userRoles);
            this.props.getOffers();
        }
    }

    public goNext(): void {
        const newPageNumber = this.props.pageNumber + 1;

        this.props.setPageNumber(newPageNumber);
        this.fetchData();
    }

    public goPrevious(): void {
        const newPageNumber = this.props.pageNumber - 1;

        this.props.setPageNumber(newPageNumber);
        this.fetchData();
    }

    public fetchData(): void {
        if (this.props.selectedFilter === 'events') {
            this.props.getEvents(this.props.userRoles);
        } else {
            this.props.getOffers();
        }
    }

    render() {

        let listItems: undefined | Entry<ContentfulEvent | ContentfulOffer>[] = [];
        let lastPage = 0;
        let canLoadMore = false;
        if (this.props.selectedFilter === 'events') {
            listItems = this.props.events;
            lastPage = this.props.eventTotal ? Math.ceil(this.props.eventTotal / this.props.pageSize) : 0;
            canLoadMore = this.props.canLoadMore && !!this.props.eventTotal && this.props.eventTotal > this.props.pageSize;
        } else {
            listItems = this.props.offers;
            lastPage = this.props.offerTotal ? Math.ceil(this.props.offerTotal / this.props.pageSize) : 0;
            canLoadMore = this.props.canLoadMore && !!this.props.offerTotal && this.props.offerTotal > this.props.pageSize;
        }

        return (
        <Widget position={this.props.position}>
            <CalendarList
                position={this.props.position}
                park={this.props.park ? this.props.park : []}
                eventsOrOffers={listItems ? listItems : []}
                currentPage={this.props.pageNumber}
                lastPage={lastPage}
                canLoadMore={canLoadMore}
                goNext={this.goNext.bind(this)}
                goPrevious={this.goPrevious.bind(this)}
                components={this.props.widgetComponents}
                openEventDetailsModal={this.props.openEventDetailsModal ? this.props.openEventDetailsModal : () => { }}
                closeEventDetailsModal={this.props.closeEventDetailsModal ? this.props.closeEventDetailsModal : () => { }}
                selectedFilter={this.props.selectedFilter}
            />
            </Widget>
        )
    }
}


const mapDispatchToProps = (dispatch: any, props: any) => {
    return {
        getEvents: (userRoles: UserRoleDTO[]) => dispatch(getEvents(props.pageSize, userRoles)),
        getOffers: () => dispatch(getOffers(props.pageSize)),
        openEventDetailsModal: () => dispatch({ type: SET_IS_EVENT_DETAILS_MODAL_OPEN, payload: true }),
        closeEventDetailsModal: () => dispatch({ type: SET_IS_EVENT_DETAILS_MODAL_OPEN, payload: false }),
        setPageNumber: (pageNumber: number) => dispatch({ type: SET_PAGE_NUMBER, payload: pageNumber })
    }
}

const mapStateToProps = (state: AppState) => ({
    events: state.calendar.events ? state.calendar.events.items : [],
    eventTotal: state.calendar.events ? state.calendar.events.total : 0,
    park: state.user.park,
    pageNumber: state.calendar.pageNumber,
    selectedFilter: state.calendar.selectedCalendarWidget,
    offers: state.calendar.offers ? state.calendar.offers.items : [],
    offerTotal: state.calendar.offers ? state.calendar.offers.total : 0,
    userRoles: state.user.userRoles
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarListWidget);