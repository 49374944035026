import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import Slide from "react-reveal/Slide";
import { ClickableIcon } from './Icons';
import "./MobileSideNav.scss";
import { LinkWidget } from './Widget';
import * as Routes from "../../../Constants/Routes";
import NavOption from './NavOption';
import Features from '../../../Constants/Features';
import FeatureToggle from './FeatureToggle';

interface MemberMobileSideNavProps {
  onClose: () => void;
  isHeaderOpen: boolean;
}

const MemberMobileSideNav: React.FC<MemberMobileSideNavProps> = ({
  onClose,
  isHeaderOpen,
}) => {
  useEffect(() => {
    if (isHeaderOpen) {
      document.body.classList.add("kill-scroll");
    }
    return () => {
      document.body.classList.remove("kill-scroll");
    }
  }, [isHeaderOpen]);

  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <Slide left collapse when={isHeaderOpen}>
      <div className='mobile-side-nav'>
        <div className='mobile-side-nav-content'>
          <div>
            <ClickableIcon
              icon={faTimes}
              clickAction={onClose}
              className="mobile-side-nav-close"
            />
          </div>
          <div className='mobile-side-nav-options'>
            <LinkWidget containerClassName='nav-option' to={Routes.Home}>
              <NavOption
                text={t("navigation.backToHome")}
                icon="back-to-home.svg"
                iconAlt="Back to home"
              />
            </LinkWidget>
            <LinkWidget
              containerClassName="nav-option"
              to={Routes.MyParkAndHome}
            >
              <NavOption
                text={t("navigation.myPark")}
                icon="my-park-and-home.svg"
                iconAlt="My park and home"
              />
            </LinkWidget>
            <FeatureToggle features={[Features.Passes]}>
              <LinkWidget containerClassName="nav-option" to={Routes.MyGuests}>
                <NavOption
                  text={t("navigation.myMemberships")}
                  icon="my-guests.svg"
                  iconAlt="My Members"
                />
              </LinkWidget>
            </FeatureToggle>
            <LinkWidget
              containerClassName="nav-option"
              to={Routes.MyRequestsAndQueries}
            >
              <NavOption
                text={t("navigation.myRequests")}
                icon="my-account.svg"
                iconAlt="My requests"
              />
            </LinkWidget>
          </div>
        </div>
      </div>
    </Slide>,
    document.body
  )
}

export default MemberMobileSideNav;