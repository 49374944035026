import { PostApiCall } from './ApiCall';

export class PostResetPasswordCall extends PostApiCall {
    constructor(resetPasswordDto: SendPasswordResetEmailDto) {
        const url = `account/setNewPassword`;

        super(url);

        this.body = resetPasswordDto;
        this.logoutOn401 = false;
    }
}
