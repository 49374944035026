import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { SET_EXIT_PROMPT_OPEN } from '../../../Modal/Redux/ActionTypes';
import React from "react";
import ExitPromptModal from "../Presentational/ExitPromptModal";
import { SAVE_AND_EXIT } from "../../../Guests/Redux/ActionTypes";
import { GetHTMlContentBlockCall } from "../../../Contentful/Calls/GetHTMLContentBlockCall";

interface ExitPromptProps extends WithTranslation {
  showExitPrompt: boolean;
  setExitModalOpen: (isOpen: boolean) => void;
  saveAndExit: () => void;
}

interface ReferMembershipsPromptState {
  modalText: ContentfulContentObject | undefined;
  contentLoaded: boolean;
}

class ExitPrompt extends Component<ExitPromptProps, ReferMembershipsPromptState> {
  constructor(props: any) {
    super(props);

    this.state = {
      modalText: undefined,
      contentLoaded: false,
    }
    Promise.all([
      this.getModalText("My Haulfryn - Membership - Other", "modalText")
    ]).then(r => {
      r.forEach(c => this.setState({ ...this.state, [c.name]: c }));
      this.setState({ contentLoaded: true });
    })
  }

  getModalText = async (name: string, label: string) => {
    const modalText = await GetHTMlContentBlockCall(name, label);
    return modalText;
  }

  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <>
        {this.state.contentLoaded && (
          <>
            <Desktop>
              <ExitPromptModal
                isOpen={this.props.showExitPrompt}
                setExitModalOpen={this.props.setExitModalOpen}
                saveAndExit={this.props.saveAndExit}
                modalText={this.state.modalText!}
              />
            </Desktop>
            <Mobile>
              <ExitPromptModal
                className="mobile-modal owner-contact-details-modal-mobile"
                isOpen={this.props.showExitPrompt}
                setExitModalOpen={this.props.setExitModalOpen}
                saveAndExit={this.props.saveAndExit}
                modalText={this.state.modalText!}
              />
            </Mobile>
          </>
        )}
      </>
    );
  };
};

const mapStateToProps = (state: any) => {
  return {
    showExitPrompt: state.modal.exit.isOpen,
  };
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    setExitModalOpen: (isOpen: boolean) => {
      dispatch({ type: SET_EXIT_PROMPT_OPEN, payload: isOpen })
    },
    saveAndExit: () => {
      dispatch({ type: SAVE_AND_EXIT })
    },
  };
};


export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ExitPrompt)
)