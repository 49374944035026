export const SET_USER_NAME = "SET_USER_NAME";
export const SET_OWNER_ID = "SET_OWNER_ID";
export const SET_LOGIN_EMAIL = "SET_LOGIN_EMAIL";
export const SET_USER_LODGES = "SET_USER_LODGES";
export const SET_SELECTED_LODGE = "SET_SELECTED_LODGE";
export const SET_SELECTED_PARK = "SET_SELECTED_PARK";
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const GET_MY_CONTACT_DETAILS_REQUEST = "GET_MY_CONTACT_DETAILS_REQUEST";
export const GET_MY_CONTACT_DETAILS_SUCCESS = "GET_MY_CONTACT_DETAILS_SUCCESS";
export const GET_MY_CONTACT_DETAILS_FAILURE = "GET_MY_CONTACT_DETAILS_FAILURE";
export const UPDATE_MY_CONTACT_DETAILS_REQUEST =
  "UPDATE_MY_CONTACT_DETAILS_REQUEST";
export const UPDATE_MY_CONTACT_DETAILS_SUCCESS =
  "UPDATE_MY_CONTACT_DETAILS_SUCCESS";
export const UPDATE_MY_CONTACT_DETAILS_FAILURE =
  "UPDATE_MY_CONTACT_DETAILS_FAILURE";
export const GET_MARKETING_PREFERENCES_REQUEST =
  "GET_MARKETING_PREFERENCES_REQUEST";
export const GET_MARKETING_PREFERENCES_SUCCESS =
  "GET_MARKETING_PREFERENCES_SUCCESS";
export const SET_MARKETING_PREFERENCES_REQUEST =
  "SET_MARKETING_PREFERENCES_REQUEST";
export const SET_MARKETING_PREFERENCES_SUCCESS =
  "SET_MARKETING_PREFERENCES_SUCCESS";
export const SET_MY_PASSWORD_REQUEST = "SET_MY_PASSWORD_REQUEST";
export const SET_MY_PASSWORD_SUCCESS = "SET_MY_PASSWORD_SUCCESS";
export const SET_MY_PASSWORD_FAILURE = "SET_MY_PASSWORD_FAILURE";
export const SET_ACCOUNT_BALANCE = "SET_ACCOUNT_BALANCE";
export const GET_SHOW_MEMBERSHIP_PROMPT_REQUEST = "GET_SHOW_MEMBERSHIP_PROMPT_REQUEST";
export const GET_SHOW_MEMBERSHIP_PROMPT_SUCCESS = "GET_SHOW_MEMBERSHIP_PROMPT_SUCCESS";
export const GET_SHOW_MEMBERSHIP_PROMPT_FAILURE = "GET_SHOW_MEMBERSHIP_PROMPT_FAILURE";
export const SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_REQUEST = "SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_REQUEST";
export const SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_SUCCESS = "SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_SUCCESS";
export const SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_FAILURE = "SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_FAILURE";
export const GET_OWNER_MEMBERSHIP_AVAILABILITY_REQUEST = "GET_OWNER_MEMBERSHIP_AVAILABILITY_REQUEST";
export const GET_OWNER_MEMBERSHIP_AVAILABILITY_SUCCESS = "GET_OWNER_MEMBERSHIP_AVAILABILITY_SUCCESS";
export const GET_OWNER_MEMBERSHIP_AVAILABILITY_FAILURE = "GET_OWNER_MEMBERSHIP_AVAILABILITY_FAILURE";
export const SET_IS_REFERRED_USER = 'SET_IS_REFERRED_USER';
export const SET_MEMBERSHIP_NUMBER = 'SET_MEMBERSHIP_NUMBER';
export const SET_CAN_REFER = 'SET_CAN_REFER';
export const SET_USER_ROLE_CLAIMS = 'SET_USER_ROLE_CLAIMS';

