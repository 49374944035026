import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GetParkCall } from '../../../Contentful/Calls/GetParkCall';
import { GetOutletPromotionsCall } from '../../../Contentful/Calls/GetOutletPromotionsCall';
import { Modal, ModalFooter, ModalHeader, ModalProps } from '../../../Shared/Components/Presentational/Modal';
import Spinner from '../../../Shared/Components/Presentational/Spinner';
import ParkRestaurantButton from '../Presentational/ParkRestaurantButton';
import './ParkRestaurantsModal.scss';
import { Entry } from 'contentful';

interface ParkRestaurantsModalProps extends ModalProps {
    parkCode: string;
    onRestaurantsFetched: (hasRestaurants: boolean) => void;
    onRestaurantSelected: (selectedRestaurant: PromotedRestaurant) => void;
}

const ParkRestaurantsModal: React.FC<ParkRestaurantsModalProps> = ({
    parkCode,
    onRestaurantsFetched,
    onRestaurantSelected,
    isOpen,
    onRequestClose
}) => {
    const { t } = useTranslation();
    const [restaurants, setRestaurants] = useState<PromotedRestaurant[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        const ObtainRestaurants = async () => {
            if (parkCode) {
                setIsLoading(true);
                setHasError(false);
                try {
                    const contentfulPark = await GetParkCall(parkCode);
                    const OutletPromotionsResult = await GetOutletPromotionsCall(contentfulPark.sys.id);

                    let restaurants = OutletPromotionsResult.items.flatMap((pr) => pr.fields.outlets.map(o => { return { ...o, bookAheadDays: pr.fields.bookAheadDays, bookingHelp: pr.fields.bookingHelp } as PromotedRestaurant}));
                    restaurants = restaurants.reduce((acc, r) => acc.filter(ar => ar.sys.id == r.sys.id).length > 0 ? acc : [...acc, r], [] as PromotedRestaurant[] )
                    setRestaurants(restaurants);
                   
                    setIsLoading(false);
                    onRestaurantsFetched(restaurants.length > 0);

                } catch{
                    setIsLoading(false);
                    setHasError(true);
                    onRestaurantsFetched(false);
                }
            }
            
        }
        ObtainRestaurants()
       
    }, [parkCode, onRestaurantsFetched]);

    const onBackButtonClick = () => {
        onRequestClose && onRequestClose();
    };

    return (
        <Modal isOpen={isOpen} className="restaurant-modal">
            <div className="restaurant-modal-container">
                <ModalHeader text={t('myCalendar.restaurants.title')} />
                {!isLoading && !hasError && restaurants.length === 0 && (
                    <p className="restaurant-message">{t('myCalendar.restaurants.noneAvailable')}</p>
                )}
                {isLoading && <Spinner />}
                {!isLoading && hasError && <p className="restaurant-message">{t('myCalendar.restaurants.error')}</p>}
                <div className="restaurant-list">
                    {restaurants.map((r, i) => {
                        return <ParkRestaurantButton restaurant={r} key={i} onClick={() => onRestaurantSelected(r)} />;
                    })}
                </div>
                <ModalFooter onBackButtonClick={onBackButtonClick} />
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    return {
        parkCode: state.user.park.parkCode
    };
};

export default connect(mapStateToProps)(ParkRestaurantsModal);
