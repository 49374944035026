import React, { FormEvent, useState, useEffect } from 'react';
import { Modal, ModalProps } from '../../../Shared/Components/Presentational/Modal';
import { useTranslation } from 'react-i18next';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import AddPassModalHeader from './AddPassModalHeader';
import AddPassModalFooter from './AddPassModalFooter';
import AddPassRow from './AddPassRow';
import ShortStayPassFields from './PassFields/ShortStayPassFields';
import CarPassFields from './PassFields/CarPassFields';
import SeasonPassFields from './PassFields/SeasonPassFields';
import './AddPassModal.scss';
import PassFunctionalityTypes from '../../../Constants/PassFunctionalityTypes';
import classNames from 'classnames';
import { PostGetSeasonPassesCall } from '../../../Api/Calls/PostGetSeasonPassesCall';
import { Post } from '../../../Api/ApiService';

interface RenewPassModalProps extends ModalProps {
    onBackButtonClick: () => void;
    renewPassesRequest: (passes: AddPassDTO[]) => void;
    submittedOption: ParkPassTypeDTO;
    passesToRenew: PassHeaderDTO[];
    lodgeId: string;
    isLoading?: boolean;
    isAnalyzingPassPhoto?: boolean;
    analyzingPassPhotoError?: any;
    isAnalyzingPassPhotoSuccessful?: boolean;
    isAddPassPhotoModalOpen: boolean;
    PassPhotoSizeLimitInKb: number;
    openAddPassPhotoModal: () => void;
    closeAddPassPhotoModal: () => void;
    analyzePassPhotoRequest: (passPhotoFile: File) => void;
    requirePassPhoto?: boolean;
}

const RenewPassModal: React.FC<RenewPassModalProps> = (props) => {
    const { t } = useTranslation();

    const getFieldList = (functionalityCode: string): FormField<any>[] => {
        switch (functionalityCode) {
            case PassFunctionalityTypes.ShortStay:
                return filterFieldList(ShortStayPassFields);
            case PassFunctionalityTypes.Car:
                return CarPassFields;
            case PassFunctionalityTypes.SeasonOwner:
            case PassFunctionalityTypes.SeasonResort:
                return filterFieldList(SeasonPassFields);
            default:
                return [];
        }
    };

    const filterFieldList = (fieldList: FormField<any>[]): FormField<any>[] => {
        if(props.requirePassPhoto) { return fieldList; }
        return fieldList.filter(a => a.id !== "passPhoto");
    };

    const createNewPass = (): AddPassForm<AddPassDTO> => {
        return {
            isValid: false,
            pass: {
                FirstName: '',
                LastName: '',
                ParkPassTypeId: '',
                LodgeId: 0,
                PassTypeFriendlyName: t(props.submittedOption.NameTranslationKey),
            } as AddPassDTO,
            fieldList: getFieldList(props.submittedOption.FunctionalityCode)
        };
    };

    const [passes, setPasses] = useState<AddPassForm<AddPassDTO>[]>([createNewPass()]);

    useEffect(() => {
        if (props.passesToRenew && props.passesToRenew.length > 0) {
            Post<AddSeasonPassDTO[]>(
                new PostGetSeasonPassesCall(
                    props.lodgeId,
                    props.passesToRenew.map((x) => x.PassId)
                )
            ).then((response) => {
                if (response.data && response.data.length > 0) {
                    const passFormsToAdd: AddPassForm<AddPassDTO>[] = [];
                    for (let i = 0; i < response.data.length; i++) {
                        const pass = response.data[i];
                        pass.PassTypeFriendlyName = t(props.submittedOption.NameTranslationKey);
                        passFormsToAdd.push({
                            pass,
                            fieldList: getFieldList(props.submittedOption.FunctionalityCode),
                            isValid: true
                        });
                    }
                    setPasses(passFormsToAdd);
                }
            });
        }
    }, [props.passesToRenew, props.submittedOption, t]);

    const updatePasses = (formObject: AddPassForm<AddPassDTO>) => {
        setPasses((prevPasses) => {
            prevPasses[prevPasses.indexOf(formObject)].isValid = formObject.isValid;
            return [...prevPasses];
        });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        props.renewPassesRequest(
            passes.map((tp) => {
                return tp.pass;
            })
        );
    };

    const formContents = (
        <>
            <AddPassModalHeader headerText={t('myGuests.passes.renew.header')} />
            <p>{t('myGuests.passes.renew.description')}</p>
            {passes.map((newPassForm, index) => (
                <React.Fragment key={`pass-row-${index}`}>
                    <AddPassRow
                        key={'row-' + index}
                        passForm={newPassForm}
                        showDeleteButton={false}
                        updatePasses={updatePasses}
                        isRenew={true}
                        isAnalyzingPassPhoto={props.isAnalyzingPassPhoto}
                        analyzingPassPhotoError={props.analyzingPassPhotoError}
                        isAnalyzingPassPhotoSuccessful={props.isAnalyzingPassPhotoSuccessful}
                        isAddPassPhotoModalOpen={props.isAddPassPhotoModalOpen}
                        openAddPassPhotoModal={props.openAddPassPhotoModal}
                        closeAddPassPhotoModal={props.closeAddPassPhotoModal}
                        analyzePassPhotoRequest={(file: File) => props.analyzePassPhotoRequest(file)}
                        submittedOption={props.submittedOption}
                        PassPhotoSizeLimitInKb={props.PassPhotoSizeLimitInKb}
                    ></AddPassRow>
                    {index === passes.length - 1 ? null : <div className="add-pass-modal-fields-seperator"></div>}
                </React.Fragment>
            ))}
            <AddPassModalFooter
                onBackButtonClick={props.onBackButtonClick}
                submitDisabled={
                    passes.length < 1 ||
                    passes.filter((np) => {
                        return !np.isValid;
                    }).length > 0
                }
                addAnotherDisabled={true}
                isRenew={true}
                primaryButtonText={t('myGuests.passes.renew.passes')}
                isLoading={props.isLoading}
            />
        </>
    );

    return (
        <Modal isOpen={props.isOpen} className={classNames('add-pass-modal', props.className)}>
            <Desktop>
                <form onSubmit={handleSubmit}>{formContents}</form>
            </Desktop>
            <Mobile>
                <form className="add-pass-modal-mobile-form" onSubmit={handleSubmit}>
                    {formContents}
                </form>
            </Mobile>
        </Modal>
    );
};

export default RenewPassModal;
