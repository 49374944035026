export default class FreshDeskSourceTypes {
  public static Reply = "Reply";
  public static Note = "Note";
  public static CreatedFromTweets = "CreatedFromTweets";
  public static CreatedFromSurveyFeedback = "CreatedFromSurveyFeedback";
  public static CreatedFromFaceBookPost = "CreatedFromFaceBookPost";
  public static CreatedFromForwardedEmail = "CreatedFromForwardedEmail";
  public static CreatedFromPhone = "CreatedFromPhone";
  public static ECommerce = "ECommerce";
}
