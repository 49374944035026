import { LOGOUT } from "../../Login/Redux/ActionTypes";
import {
  SET_SELECTED_LODGE,
  SET_SELECTED_PARK,
  SET_USER_LODGES,
  SET_USER_NAME,
  GET_MY_CONTACT_DETAILS_REQUEST,
  GET_MY_CONTACT_DETAILS_SUCCESS,
  GET_MY_CONTACT_DETAILS_FAILURE,
  UPDATE_MY_CONTACT_DETAILS_REQUEST,
  UPDATE_MY_CONTACT_DETAILS_SUCCESS,
  UPDATE_MY_CONTACT_DETAILS_FAILURE,
  SET_MY_PASSWORD_REQUEST,
  SET_MY_PASSWORD_SUCCESS,
  SET_MY_PASSWORD_FAILURE,
  SET_ACCOUNT_BALANCE,
  SET_OWNER_ID,
  GET_MARKETING_PREFERENCES_REQUEST,
  GET_MARKETING_PREFERENCES_SUCCESS,
  SET_MARKETING_PREFERENCES_SUCCESS,
  SET_LOGIN_EMAIL,
  GET_SHOW_MEMBERSHIP_PROMPT_REQUEST,
  GET_SHOW_MEMBERSHIP_PROMPT_FAILURE,
  GET_SHOW_MEMBERSHIP_PROMPT_SUCCESS,
  SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_REQUEST,
  SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_SUCCESS,
  SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_FAILURE,
  GET_OWNER_MEMBERSHIP_AVAILABILITY_REQUEST,
  GET_OWNER_MEMBERSHIP_AVAILABILITY_SUCCESS,
  GET_OWNER_MEMBERSHIP_AVAILABILITY_FAILURE,
  SET_USER_ROLES,
  SET_IS_REFERRED_USER,
  SET_MEMBERSHIP_NUMBER,
  SET_CAN_REFER,
  SET_USER_ROLE_CLAIMS,
} from "./ActionTypes";
import { PAYMENT_CONFIRMED } from "../../Account/Redux/ActionTypes";
import { faTruckMonster } from "@fortawesome/free-solid-svg-icons";

const initialState = {
  loginEmail: undefined,
  ownerId: undefined,
  firstname: undefined,
  lastName: undefined,
  phoneNumber: undefined,
  userRoles: undefined as UserRoleDTO[] | undefined,
  hasOwnMembership: undefined,
  lodges: undefined,
  lodge: undefined,
  park: undefined,
  myContactDetails: undefined,
  marketingPreferences: undefined,
  isGettingContactDetails: false,
  gettingContactDetailsError: false,
  isSavingContactDetails: false,
  updatingContactDetailsError: false,
  isUpdatingPassword: false,
  isUpdatePasswordSuccessModalOpen: false,
  updatingPasswordError: false,
  showMembershipPrompt: true,
  isGettingShowMembershipPrompt: false,
  gettingShowMembershipPromptError: false,
  isSettingDontShowAgainMembershipModal: false,
  settingDontShowAgainMembershipModalError: false,
  isGettingOwnerMembershipAvailability: false,
  gettingOwnerMembershipAvailabilityError: false,
  ownerMembershipsAvailability: undefined as OwnerMembershipAvailabilityDTO | undefined,
  membershipNumber: undefined,
  canRefer: false,
  canReferWithMemberships: false,
  canReferWithOutMemberships: false,
  forcedToMakeMembership: false,
  permissionCallSuccess: false,
  decommissionedParks: ["AB", "GR"],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_NAME:
      return { ...state, firstname: action.payload[0], lastName: action.payload[1] };
    case SET_LOGIN_EMAIL:
      return { ...state, loginEmail: action.payload[0], phoneNumber: action.payload[1] };
    case SET_OWNER_ID:
      return { ...state, ownerId: action.payload };
    case SET_USER_LODGES:
      return { ...state, lodges: action.payload };
    case SET_SELECTED_LODGE:
      return { ...state, lodge: action.payload };
    case SET_SELECTED_PARK:
      return { ...state, park: action.payload };
    case SET_USER_ROLES:
      return { ...state, userRoles: action.payload };
    case SET_IS_REFERRED_USER:
      return { ...state, hasOwnMembership: action.payload };
    case SET_CAN_REFER:
      return { ...state, canRefer: action.payload };
    case SET_MEMBERSHIP_NUMBER:
      return { ...state, membershipNumber: action.payload };
    case LOGOUT:
      return {
        ...state,
        currentUserDetails: undefined,
        myContactDetails: undefined,
      };
    case GET_MARKETING_PREFERENCES_REQUEST:
      return {
        ...state,
        marketingPreferences: undefined,
      };
    case GET_MARKETING_PREFERENCES_SUCCESS:
      return {
        ...state,
        marketingPreferences: action.payload,
      };
    case SET_MARKETING_PREFERENCES_SUCCESS:
      return {
        ...state,
        marketingPreferences: action.payload,
      };
    case GET_MY_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        myContactDetails: undefined,
        gettingContactDetailsError: false,
        isGettingContactDetails: true,
      };
    case GET_MY_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        myContactDetails: action.payload,
        isGettingBankDetails: false,
      };
    case GET_MY_CONTACT_DETAILS_FAILURE:
      return {
        ...state,
        myContactDetails: undefined,
        gettingContactDetailsError: true,
        isGettingContactDetails: false,
      };
    case UPDATE_MY_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        updatingContactDetailsError: false,
        isSavingContactDetails: true,
      };
    case UPDATE_MY_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        myContactDetails: action.payload,
        isSavingContactDetails: false,
      };
    case UPDATE_MY_CONTACT_DETAILS_FAILURE:
      return {
        ...state,
        updatingContactDetailsError: true,
        isGettingContactDetails: false,
      };
    case SET_MY_PASSWORD_REQUEST:
      return {
        ...state,
        updatingPasswordError: false,
        isUpdatingPassword: true,
      };
    case SET_MY_PASSWORD_SUCCESS:
      return { ...state, isUpdatingPassword: false };
    case SET_MY_PASSWORD_FAILURE:
      return {
        ...state,
        updatingPasswordError: true,
        isUpdatingPassword: false,
      };
    case PAYMENT_CONFIRMED:
    case SET_ACCOUNT_BALANCE:
      return {
        ...state,
        lodge: { ...(state.lodge as any), AccountBalance: action.payload },
      };
    case GET_SHOW_MEMBERSHIP_PROMPT_REQUEST:
      return { 
        ...state, 
        isGettingShowMembershipPrompt: true,
        gettingShowMembershipPrompt: false,
      };
    case GET_SHOW_MEMBERSHIP_PROMPT_SUCCESS:
      return {
        ...state,
        showMembershipPrompt: action.payload,
        gettingShowMembershipPrompt: false,
      };
    case GET_SHOW_MEMBERSHIP_PROMPT_FAILURE:
      return {
        ...state,
        isGettingShowMembershipPrompt: false,
        gettingShowMembershipPrompt: true,
      };
    case SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_REQUEST:
      return { 
        ...state, 
        isSettingDontShowAgainMembershipModal: true, 
        settingDontShowAgainMembershipModalError: false,
      };
    case SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_SUCCESS:
      return {
        ...state,
        showMembershipPrompt: action.payload,
        isSettingDontShowAgainMembershipModal: false,
      }
    case SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_FAILURE:
      return {
        ...state,
        isSettingDontShowAgainMembershipModal: false,
        settingDontShowAgainMembershipModalError: true,
      }
    case GET_OWNER_MEMBERSHIP_AVAILABILITY_REQUEST:
      return {
        ...state,
        isGettingOwnerMembershipAvailability: true,
        gettingOwnerMembershipAvailabilityError: false,
      }
    case GET_OWNER_MEMBERSHIP_AVAILABILITY_SUCCESS:
      return {
        ...state,
        isGettingOwnerMembershipAvailability: false,
        ownerMembershipsAvailability: action.payload,
      }
    case GET_OWNER_MEMBERSHIP_AVAILABILITY_FAILURE:
      return {
        ...state,
        isGettingOwnerMembershipAvailabiliy: false,
        gettingOwnerMembershipAvailabilityError: true,
      }
    case SET_USER_ROLE_CLAIMS:
      return {
        ...state,
        canRefer: action.payload[0] || action.payload[1],
        canReferWithMemberships: action.payload[0],
        canReferWithOutMemberships: action.payload[1],
        forcedToMakeMembership: action.payload[2],
        permissionCallSuccess: true,
      }
    default:
      return state;
  }
};
