import { GetApiCall } from "./ApiCall";

export class GetUpdateTermsAndConditionsSignedDateCall extends GetApiCall {
    constructor(basketId: string) {
        let url: string = "Membership/UpdateTermsAndConditionsSignedDate";

        let params: any = { basketId };

        super(url, params);
    }
}