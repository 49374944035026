import { PostApiCall } from "../ApiCall";

export class UpdateMemberPhotoCall extends PostApiCall {
  constructor(memberId: string, file: File) {
    let url: string = "photo-pass/member-image/" + memberId;

    var data = new FormData();
    data.append('file', file);

    super(url);

    this.body = data;
  }
}