import { PostApiCall } from "./ApiCall";

export class PostUpdateEditMemberDetailsCall extends PostApiCall {
    constructor(editMemberDetails: EditMemberDetailsDTO) {
        let url: string = "Membership/EditMemberDetails";

        super(url);

        this.body = editMemberDetails;
    }
}