import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {  ModalHeader } from '../../../Shared/Components/Presentational/Modal';
import { isInternationalPhoneNumber } from '../../../Shared/Utilities/Validation';


//@ts-ignore
import Chips from 'react-chips';
import Chip from '../../../Shared/Components/Presentational/Chip';

interface AddBookingModalMobileNumbersFormProps {
    className?: string
    mobileNumbers: string[];
    setMobileNumbers: (mobileNumbers: string[]) => void;
    invalidMobileNumbers: string[];
    setInvalidMobileNumbers: (mobileNumbers: string[]) => void;
    showMobileNumbersError: boolean;
    setHasPendingMobileNumber: (hasPending: boolean) => void;
    hasPendingMobileNumber: boolean;
}

const AddBookingModalMobileNumbersForm: React.FC<AddBookingModalMobileNumbersFormProps> = (props) => {
    const { t } = useTranslation();

    const isChipValid = (chipValue: string): boolean => {
        return isInternationalPhoneNumber(chipValue);
    };

    const handleChipsChange = (chips: string[]) => {
        if(chips.length > props.mobileNumbers.length){
            props.setHasPendingMobileNumber(false)
        }
        props.setMobileNumbers(chips);
        
        if (chips.length > 0) {
            chips.forEach((chipValue) => {
                if (!isChipValid(chipValue)) {
                    const existingIndex = props.invalidMobileNumbers.indexOf(chipValue);
                    if (existingIndex == -1) {
                        props.setInvalidMobileNumbers([...props.invalidMobileNumbers, ...[chipValue]]);
                    }
                }
            });
        } else {
            props.setInvalidMobileNumbers([]);
        }
    };

    const renderSuggestions = (pendingChipValue: string) =>{
        if(pendingChipValue){
            props.setHasPendingMobileNumber(true)
        } else{
            props.setHasPendingMobileNumber(false)
        }
    }

    return (
        <form className={props.className}>
            <ModalHeader text={t('myCalendar.addBooking.mobileNumbersTitle')} />
            <p
                className={classNames('info-message show')}
                dangerouslySetInnerHTML={{ __html: t('myCalendar.addBooking.onlineBookingsMobileNumbers') }}
            ></p>
            <Chips
                value={props.mobileNumbers}
                placeholder="Type or paste a mobile number and press tab"
                onChange={handleChipsChange}
                createChipKeys={[13, 9]}
                shouldRenderSuggestions={renderSuggestions}
                renderChip={(value: string) => (
                    <Chip
                        chips={props.mobileNumbers}
                        isValid={props.invalidMobileNumbers.indexOf(value) == -1}
                        onRemove={handleChipsChange}
                    >
                        {value}
                    </Chip>
                )}
            />
            <p className={props.hasPendingMobileNumber ? "helper-text show": "helper-text"} >Please press tab or enter to proceed</p>
            <p className={props.showMobileNumbersError ? 'error-message show' : 'error-message'}>
                {t('myCalendar.addBooking.mobileNumberError')}
            </p>
        </form>)

};

export default AddBookingModalMobileNumbersForm;