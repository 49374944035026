import React, { Component } from 'react';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { connect } from 'react-redux';
import MyCalendarPage from '../Presentational/MyCalendarPage';
import MyCalendarPageMobile from '../Presentational/MyCalendarPage.mobile';
import { CalendarEvent, CalendarResource } from '../../../Shared/Components/Presentational/Calendar';
import moment from 'moment';
import { AppState } from '../../../RootReducer';
import {
    SelectedCalendarWidgetOptions,
    SET_IS_ADD_BOOKING_MODAL_OPEN,
    SET_IS_ADD_BOOKING_SUCCES_MODAL_OPEN,
    SET_ADD_BOOKING_ERROR_MESSAGE,
} from '../../Redux/ActionTypes';
import { addBooking } from '../../Redux/ActionCreators';
import { PostSearchLodgeBookingsCall } from '../../../Api/Calls/PostSearchLodgeBookingsCall';
import { Post, Get } from '../../../Api/ApiService';
import { RELOAD_TRANSLATIONS } from '../../../Information/Redux/ActionTypes';
import PassFunctionalityTypes from '../../../Constants/PassFunctionalityTypes';
import { GetParkPassTypesCall } from '../../../Api/Calls/GetParkPassTypesCall';
import { GetParkCall } from '../../../Contentful/Calls/GetParkCall';
import { Entry } from 'contentful';

interface MyCalendarProps {
    dispatch: any;
    selectedWidget: SelectedCalendarWidgetOptions;
    isHeaderOpen: boolean;
    toggleBookingModal: (isOpen: boolean) => void;
    isAddBookingModalOpen: boolean;
    isAddBookingSuccessModalOpen: boolean;
    addBooking: (booking: BookingDTO) => void;
    toggleBookingSuccessModal: (isOpen: boolean) => void;
    lodgeId: number;
    accountId: number;
    addBookingErrorMessage: undefined | string;
    setAddingBookingErrorMessage: (message: any) => void;
    selectedPark: any;
}

interface MyCalendarState {
    minDate: Date;
    maxDate: Date;
    calendarDate: Date | undefined;
    events: CalendarEvent[];
    resources: CalendarResource[];
    filter: BookingFilterDTO;
    parkPassTypes: ParkPassTypeDTO[];
    selectedEvent?: CalendarEvent;
    contentfulParkData?: Entry<ContentfulPark>;
}

class MyCalendar extends Component<MyCalendarProps, MyCalendarState> {
    public state: MyCalendarState = {
        minDate: moment().subtract(4, 'months').toDate(),
        maxDate: moment().add(12, 'months').toDate(),
        calendarDate: undefined,
        events: [],
        parkPassTypes: [],
        selectedEvent: undefined,
        contentfulParkData: undefined,
        resources: [
            {
                id: 1,
                title: 'myCalendar.key.subletbookings',
                className: 'sublet-bookings',
            },
            {
                id: 2,
                title: 'myCalendar.key.showMyHolidays',
                className: 'my-holidays',
                canToggle: true,
                toggleValue: true,
            },
            {
                id: 3,
                title: 'myCalendar.key.showMyPendingHolidays',
                className: 'my-holidays pending',
                canToggle: true,
                toggleValue: true,
            },
        ],
        filter: {
            LodgeId: this.props.lodgeId,
            FromDate: moment().startOf('month').startOf('week').format('DD/MM/YYYY'),
            ToDate: moment().endOf('month').endOf('week').format('DD/MM/YYYY'),
        },
    };

    componentDidMount() {
        this.getContentfulParkDetails();
        this.searchBookings();
        this.getParkPassTypes();
        this.props.dispatch({ type: RELOAD_TRANSLATIONS });
    }

    componentDidUpdate(prevProps: MyCalendarProps) {
        if (prevProps.isAddBookingModalOpen && !this.props.isAddBookingModalOpen) {
            this.searchBookings();
        }
    }

    getContentfulParkDetails = async ()=>{
        const contentfulPark = await GetParkCall(this.props.selectedPark.parkCode);
        this.setState({ contentfulParkData: contentfulPark})
    }

    searchBookings = async () => {
        try {
            const bookings = await Post<CalendarBookingDTO[]>(new PostSearchLodgeBookingsCall(this.state.filter));

            const calendarBookings: CalendarEvent[] = bookings.data.map((booking) => {
                return {
                    allDay: true,
                    start: booking.FromDate,
                    end: moment(booking.ToDate).endOf('day').format() as any,
                    resourceId: booking.IsOwnerBooking ? (booking.IsPendingBooking ? 3 : 2) : 1,
                    subletCharges: booking.SubletCharges,
                    subletIncome: booking.SubletIncome,
                    isSubletBooking: !booking.IsOwnerBooking,
                    className: booking.IsOwnerBooking
                        ? booking.IsPendingBooking
                            ? 'my-holidays pending'
                            : 'my-holidays'
                        : 'sublet-bookings',
                };
            });
            this.setState({ events: calendarBookings });
        } catch {}
    };

    getParkPassTypes = async () => {
        Get<ParkPassTypeDTO[]>(
            new GetParkPassTypesCall(
                this.props.selectedPark.parkCode,
                this.props.lodgeId.toString(),
                this.props.accountId
            )
        ).then((response) => {
            this.setState({ parkPassTypes: response.data });
        });
    };

    hasANPRFeature = () => {
        return this.state.parkPassTypes.map((p) => p.FunctionalityCode).includes(PassFunctionalityTypes.Car);
    };

    onRangeChange = (range: { start: any; end: any }) => {
        this.setState(
            {
                filter: {
                    ...this.state.filter,
                    FromDate: moment(range.start).format('DD/MM/YYYY'),
                    ToDate: moment(range.end).format('DD/MM/YYYY'),
                },
            },
            this.searchBookings
        );
    };

    onCalendarNavigate = (date: Date) => {
        this.setState({ calendarDate: date });
    };

    setSelectedCalendarEvent = (event?: CalendarEvent)=>{
        if(event && (this.state.contentfulParkData && !this.state.contentfulParkData.fields.myHaulfrynShowSubletBookingValue || !event.isSubletBooking)){
            return;
        }
        this.setState({ selectedEvent: event} )
    };

    public render(): JSX.Element {
        return (
            <>
                <Desktop>
                    <MyCalendarPage
                        isHeaderOpen={this.props.isHeaderOpen}
                        minDate={this.state.minDate}
                        maxDate={this.state.maxDate}
                        events={this.state.events}
                        resources={this.state.resources}
                        selectedWidget={this.props.selectedWidget}
                        toogleBookingModal={this.props.toggleBookingModal}
                        isAddBookingModalOpen={this.props.isAddBookingModalOpen}
                        addBooking={this.props.addBooking}
                        toggleBookingSuccessModal={this.props.toggleBookingSuccessModal}
                        isAddBookingSuccessModalOpen={this.props.isAddBookingSuccessModalOpen}
                        onRangeChange={this.onRangeChange}
                        lodgeId={this.props.lodgeId}
                        addBookingErrorMessage={this.props.addBookingErrorMessage}
                        setAddingBookingErrorMessage={this.props.setAddingBookingErrorMessage}
                        hasANPRFeature={this.hasANPRFeature()}
                        onNavigate={this.onCalendarNavigate}
                        calendarDate={this.state.calendarDate}
                        accountId={this.props.accountId}
                        selectedCalendarEvent={this.state.selectedEvent}
                        setSelectedCalendarEvent={this.setSelectedCalendarEvent}
                        clickableEvents={!!(this.state.contentfulParkData && this.state.contentfulParkData.fields.myHaulfrynShowSubletBookingValue)}
                    />
                </Desktop>
                <Mobile>
                    <MyCalendarPageMobile
                        isHeaderOpen={this.props.isHeaderOpen}
                        minDate={this.state.minDate}
                        maxDate={this.state.maxDate}
                        events={this.state.events}
                        resources={this.state.resources}
                        toggleBookingModal={this.props.toggleBookingModal}
                        isAddBookingModalOpen={this.props.isAddBookingModalOpen}
                        addBooking={this.props.addBooking}
                        toggleBookingSuccessModal={this.props.toggleBookingSuccessModal}
                        isAddBookingSuccessModalOpen={this.props.isAddBookingSuccessModalOpen}
                        onRangeChange={this.onRangeChange}
                        lodgeId={this.props.lodgeId}
                        addBookingErrorMessage={this.props.addBookingErrorMessage}
                        setAddingBookingErrorMessage={this.props.setAddingBookingErrorMessage}
                        hasANPRFeature={this.hasANPRFeature()}
                        onNavigate={this.onCalendarNavigate}
                        calendarDate={this.state.calendarDate}
                        accountId={this.props.accountId}
                        selectedCalendarEvent={this.state.selectedEvent}
                        setSelectedCalendarEvent={this.setSelectedCalendarEvent}
                        clickableEvents={!!(this.state.contentfulParkData && this.state.contentfulParkData.fields.myHaulfrynShowSubletBookingValue)}
                    />
                </Mobile>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    selectedWidget: state.calendar.selectedCalendarWidget,
    isHeaderOpen: state.application.isHeaderOpen,
    isAddBookingModalOpen: state.calendar.isAddBookingModalOpen,
    isAddBookingSuccessModalOpen: state.calendar.isAddBookingSuccessModalOpen,
    lodgeId: state.user.lodge.LodgeId,
    accountId: state.user.lodge.AccountId,
    addBookingErrorMessage: state.calendar.addBookingErrorMessage,
    selectedPark: state.user.park,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        toggleBookingModal: (isOpen: boolean) => dispatch({ type: SET_IS_ADD_BOOKING_MODAL_OPEN, payload: isOpen }),
        toggleBookingSuccessModal: (isOpen: boolean) =>
            dispatch({ type: SET_IS_ADD_BOOKING_SUCCES_MODAL_OPEN, payload: isOpen }),
        addBooking: (booking: BookingDTO) => dispatch(addBooking(booking)),
        setAddingBookingErrorMessage: (message: any) => {
            dispatch({ type: SET_ADD_BOOKING_ERROR_MESSAGE, payload: message });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCalendar);
