import React, { ChangeEvent, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { vehicleValidation, isUnder10Characters } from "../../../../Shared/Utilities/Validation";
import "./MembershipPageStyles.scss";
import "./FormInputs/FormStyles.scss";
import { Desktop, Mobile } from "../../../../Shared/Components/Presentational/ResponsiveViews";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

interface VehiclesPageProps extends WithTranslation {
  membershipForm: any;
  onInput: (value: any, name: any, errors: string[]) => void;
  vehicleName: string;
  showErrors?: boolean;
  bypassAge?: boolean;
  description?: ContentfulContentObject;
}

interface Vehicle {
  registration: string;
  description: string;
}

const VehiclesPage: React.FC<VehiclesPageProps> = (props) => {
  const { t } = props;
  const [vehicle1, setVehicle1] = useState<Vehicle>((props.membershipForm && props.membershipForm.vehicles.value && props.membershipForm.vehicles.value[0]) ? props.membershipForm.vehicles.value[0] : { registration: '', description: '' });
  const [vehicle2, setVehicle2] = useState<Vehicle>((props.membershipForm && props.membershipForm.vehicles.value && props.membershipForm.vehicles.value[1]) ? props.membershipForm.vehicles.value[1] : { registration: '', description: '' });
  const [formFields, setFormFields] = useState<Vehicle[]>([vehicle1, vehicle2]);
  const [scrollPosition, setscrollPosition] = useState('');

  const validators: Validator[] = [{ name: "isUnder10Characters", validator: isUnder10Characters }, { name: "vehicleValidation", validator: vehicleValidation }];

  const calculateAge = (dateOfBirth: Date): number => {
    let today = new Date();
    let birthDate = new Date(dateOfBirth);
    let ageNow = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      ageNow--;
    }
    return ageNow;
  }

  const [age, setAge] = useState(props.membershipForm && props.membershipForm.dateOfBirth ? calculateAge(props.membershipForm.dateOfBirth.value) : 0);

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const key = e.target.name as keyof Vehicle;
    switch (index) {
      case 0:
        switch (key) {
          case "registration":
            setVehicle1({ ...vehicle1, registration: e.target.value });
            break;
          default:
            setVehicle1({ ...vehicle1, description: e.target.value });
        }
        break;
      default:
        switch (key) {
          case "registration":
            setVehicle2({ ...vehicle2, registration: e.target.value });
            break;
          default:
            setVehicle2({ ...vehicle2, description: e.target.value });
        }
        break;
    }
  }

  useEffect(() => {
    setFormFields([vehicle1, vehicle2]);
  }, [vehicle1]);
  useEffect(() => {
    setFormFields([vehicle1, vehicle2]);
  }, [vehicle2]);
  useEffect(() => {
    updateChange();
  }, [formFields]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateChange = () => {
    //Need to add section to check vehicle info against validators here
    const errors: string[] = [];
    for (let validator of validators) {
      for (let vehicle of formFields) {
        if (!validator.validator(vehicle)) {
          errors.push(validator.name);
        }
      }
    }
    props.onInput(formFields, props.vehicleName, errors);
  }

  const removeFields = (index: number) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  }

  const addFields = () => {
    let object = { registration: '', description: '' };
    setFormFields([...formFields, object]);
  }

  const trimmedErrors = (errors: string[]) => {
    return errors.filter((val, id, array) => array.indexOf(val) == id);
  }

  return (
    <>
      <Desktop>
        <div className="row" style={{ marginBottom: "25px" }}>
          <div className="float-left">
            <h2 style={{ margin: "0px" }}>{t("addMembership.vehicles.title")}</h2>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="row" style={{ marginBottom: "25px", marginTop: "50px" }}>
          <div className="title-header">
            <h2 style={{ margin: "0px" }}>{t("addMembership.vehicles.title")}</h2>
          </div>
        </div>
      </Mobile>

      {age >= 16 || props.bypassAge ? (
        <>
          <Desktop>
            {props.description && (
              <div className="contentful-text">
                {documentToReactComponents(props.description.content)}
              </div>
            )}
            {/* <p>{t("addMembership.vehicles.message")}</p> */}
            <h3>Vehicle 1:</h3>
            <table className="vehicle-table">
              <tbody>
                <tr>
                  <th>Registration Number</th>
                  <th>Description (optional)</th>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>
                    <input
                      name="registration"
                      onChange={event => handleFormChange(event, 0)}
                      value={vehicle1.registration}
                      className="add-form-input form-input"
                    />
                  </td>

                  <td style={{ width: "60%" }}>
                    <input
                      name="description"
                      onChange={event => handleFormChange(event, 0)}
                      value={vehicle1.description}
                      className="add-form-input form-input"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <h3>Vehicle 2:</h3>
            <table className="vehicle-table">
              <tbody>
                <tr>
                  <th>Registration Number</th>
                  <th>Description (optional)</th>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>
                    <input
                      name="registration"
                      onChange={event => handleFormChange(event, 1)}
                      value={vehicle2.registration}
                      className="add-form-input form-input"
                    />
                  </td>

                  <td style={{ width: "60%" }}>
                    <input
                      name="description"
                      onChange={event => handleFormChange(event, 1)}
                      value={vehicle2.description}
                      className="add-form-input form-input"
                    />
                  </td>
                </tr>
              </tbody>
              <br></br>
              {(props.showErrors && (props.membershipForm.vehicles.errors && props.membershipForm.vehicles.errors.length > 0)) &&
                <p style={{ textAlign: "left", color: "red" }}>{props.membershipForm && t("addMembership.errors." + trimmedErrors(props.membershipForm.vehicles.errors))}</p>}
            </table>
          </Desktop>
          <Mobile>
            {props.description && (
              <div className="contentful-text">
                {documentToReactComponents(props.description.content)}
              </div>
            )}
            <h3>Vehicle 1:</h3>
            <table className="vehicle-table">
              <tbody>
                <tr>
                  <th>Registration Number</th>
                </tr>
                <tr>
                  <td>
                    <input
                      name="registration"
                      onChange={event => handleFormChange(event, 0)}
                      value={vehicle1.registration}
                      className="add-form-input form-input"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Description (optional)</th>
                </tr>
                <tr>
                  <td>
                    <input
                      name="description"
                      onChange={event => handleFormChange(event, 0)}
                      value={vehicle1.description}
                      className="add-form-input form-input"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <h3>Vehicle 2:</h3>
            <table className="vehicle-table">
              <tbody>
                <tr>
                  <th>Registration Number</th>
                </tr>
                <tr>
                  <td>
                    <input
                      name="registration"
                      onChange={event => handleFormChange(event, 1)}
                      value={vehicle2.registration}
                      className="add-form-input form-input"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Description (optional)</th>
                </tr>
                <tr>
                  <td>
                    <input
                      name="description"
                      onChange={event => handleFormChange(event, 1)}
                      value={vehicle2.description}
                      className="add-form-input form-input"
                    />
                  </td>
                </tr>
              </tbody>
              <br></br>
              {(props.showErrors && (props.membershipForm.vehicles.errors && props.membershipForm.vehicles.errors.length > 0)) &&
                <p style={{ textAlign: "left", color: "red" }}>{props.membershipForm && t("addMembership.errors." + trimmedErrors(props.membershipForm.vehicles.errors))}</p>}
            </table>
          </Mobile>
        </>
      ) : (
        <>
          <p>This member is under 16 and is therefore not able to add vehicles</p>
          <p>Please continue to the next section</p>
        </>
      )}
    </>
  );
};

export default withTranslation()(VehiclesPage);