import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import * as Routes from '../../../Constants/Routes';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { Modal, ModalHeader } from '../../../Shared/Components/Presentational/Modal';
import { Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import Table, { ColumnDefinition } from '../../../Shared/Components/Presentational/Table';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import useMyAccountPage from '../../Hooks/useMyAccountPage';
import MyBankDetails from '../Container/MyBankDetails';
import './MyAccountPage.scss';
import TransactionBalance from './TransactionBalance';
import TransactionFilter from './TransactionFilter';
import TransactionTypeMobileCellRenderer from './TransactionTypeMobileCellRenderer';

const columns: ColumnDefinition[] = [
    {
        fieldName: 'Description',
        displayName: 'myAccount.transactionNumberColumn',
        canShowColumn: (_) => true,
        customCellRenderer: TransactionTypeMobileCellRenderer,
    },
];

export interface MyAccountPageMobileProps {
    pageSize: number;
    isSearchingTransactions: boolean;
    searchResults: PagedResultDTO;
    filter: TransactionFilterDTO;
    onFilterChange: (newFilter: any) => void;
    myBankDetails?: MyBankDetailsDTO;
    openMyBankDetailsModal: () => void;
    downloadStatement: () => void;
    downloadPdfStatement: () => void;
    bankTransferClick: () => void;
    setPaymentWindowOpen: (isOpen: boolean) => void;
    isMakeAPaymentModalOpen: boolean;
    haulfryBankDetails?: HaulfrynBankDetailsDTO;
    isDownloadingStatement: boolean;
    isDownloadingPdfStatement: boolean;
    ownerAccountNumber: string;
    ownerAccountId: number;
    accountBalance: undefined | number;
    paymentDetails?: PaymentDetails;
    paymentConfirmed: (newBalance: number) => void;
    paymentConfirming: () => void;
}

export default function MyAccountPageMobile(props: MyAccountPageMobileProps) {
    const { closePaymentResultModal, paymentResultModalOpen, paymentResultModalText, refreshPage } = useMyAccountPage(
        props
    );
    const { t } = useTranslation();
    const { accountBalance } = props;

    return (
        <div className="mobile-widget-container widget-container">
            <h1 className="widget-layout-title">{t('myAccount.title')}</h1>
            <p className="widget-layout-message">{t('myAccount.message')}</p>
            <Widget
                position={{
                    columnIndex: 1,
                    columnSpan: 12,
                    rowIndex: 3,
                    rowSpan: 4,
                }}
            >
                <div className="owner-account-number">
                    {t('myAccount.accountNumber') + ' - ' + props.ownerAccountNumber}
                </div>
                <TransactionBalance
                    isPaymentWindowOpen={props.isMakeAPaymentModalOpen}
                    downloadStatement={props.downloadStatement}
                    isDownloadingStatement={props.isDownloadingStatement}
                    downloadPdfStatement={props.downloadPdfStatement}
                    isDownloadingPdfStatement={props.isDownloadingPdfStatement}
                    balance={accountBalance}
                    bankTransferClick={props.bankTransferClick}
                    setPaymentWindowOpen={props.setPaymentWindowOpen}
                    haulfryBankDetails={props.haulfryBankDetails}
                    accountNumber={props.ownerAccountNumber}
                />
                {props.myBankDetails && (
                    <div className="view-bank-details-box" onClick={() => props.openMyBankDetailsModal()}>
                        {t('myAccount.viewBankDetails')}
                    </div>
                )}
            </Widget>
            <Widget
                position={{
                    columnIndex: 1,
                    columnSpan: 12,
                    rowIndex: 7,
                    rowSpan: 10,
                }}
            >
                <Table
                    title={t('myAccount.transactions')}
                    columns={columns}
                    pagedResults={props.searchResults}
                    filter={props.filter}
                    onFilterChange={props.onFilterChange}
                    pageSize={props.pageSize}
                    noResultsMessage={props.isSearchingTransactions === false ? t('myAccount.noResultsMessage') : ''}
                    filterComponent={<TransactionFilter onFilterChange={props.onFilterChange} filter={props.filter} />}
                    isLoading={props.isSearchingTransactions}
                />
            </Widget>
            {props.myBankDetails && <MyBankDetails myBankDetails={props.myBankDetails} />}

            <Mobile>
                <Modal isOpen={paymentResultModalOpen}>
                    <ModalHeader text={t('myAccount.payment.paymentHeader')}></ModalHeader>
                    <div className="payment-result">
                        <p>{paymentResultModalText}</p>
                        <PrimaryButton className="mobile" text={t('general.ok')} onClick={closePaymentResultModal} />
                    </div>
                </Modal>
            </Mobile>

            {refreshPage && <Redirect to={Routes.MyAccount} />}
        </div>
    );
}
