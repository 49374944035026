import React from 'react';
import './Chip.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ChipProps {
    onRemove: (chips: string[]) => void;
    chips: string[];
    isValid: boolean;
}

const Chip: React.FC<ChipProps> = props => {

    const handleRemove = (): void => {
        props.onRemove(props.chips);
    }

    return (
        <div className={props.isValid ? 'chip' : 'chip invalid'}>
            <div>{props.children}</div>
            <div className="chip-remove" onClick={handleRemove}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
        </div>
    );
};

export default Chip;