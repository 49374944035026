import React, { Component, FormEvent, ChangeEvent } from "react";
import { ImageBackground } from "../../../Shared/Components/Presentational/Backgrounds";
import {
  SecondaryButton,
  OutlineButton,
} from "../../../Shared/Components/Presentational/Buttons";
import {
  ButtonContainer,
  InputContainer,
} from "../../../Shared/Components/Presentational/Containers";
import { Translation } from "react-i18next";
import "./Login.scss";
import { Link, Redirect } from "react-router-dom";
import classNames from "classnames";
import * as Routes from "../../../Constants/Routes";
import GoogleLogin from "react-google-login";
import { googleClientId, facebookAppId } from "../../../Shared/Environment";
//@ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import DropdownList from "../../../Shared/Components/Presentational/DropdownList";

interface LoginState {
  emailAddress: string;
  password: string;
  isRegister: boolean;
}

interface LoginProps {
  loginClick: (email: string, password: string, type: string) => void;
  isLoggingIn: boolean;
  loginFailureMessage: string;
  sessionHasExpired: boolean;
  parksLoading: boolean;
  availableParks: DropdownListOption[];
  selectedParkCode: string | undefined;
  parkSelected: (parkOption: DropdownListOption) => void;
  useParkSelection: boolean;
}

class LoginPageMobile extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
  }

  public state: LoginState = {
    emailAddress: "",
    password: "",
    isRegister: false,
  };

  private handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    this.setState({ [name]: value } as any);
  }

  private handleSubmit(e: FormEvent) {
    e.preventDefault();

    const user = this.state;
    if (user.emailAddress && user.password) {
      this.props.loginClick(user.emailAddress, user.password, "email");
    }
  }

  private responseGoogle(response: any) {
    if (response.tokenId) {
      this.props.loginClick("", response.tokenId, "google");
    }
  }

  private responseFacebook(response: any) {
    if (response.accessToken) {
      this.props.loginClick("", response.accessToken, "facebook");
    }
  }

  render() {
    if (this.state.isRegister) {
      return <Redirect to={Routes.Register} />;
    }

    return (
      <ImageBackground
        src="/images/mobile-login-image.svg"
        containerClassName="login-background"
      >
        <Translation>
          {(t: (key: string) => string) => {
            return (
                <>
                    <div className="login-input-outer-container mobile">
                   {this.props.useParkSelection && (
                       <InputContainer className="login-input-container">
                            <label>Select your park</label>
                            <DropdownList
                                options={this.props.availableParks}
                                classNamePrefix=""
                                className="login-park-dropdown"
                                onSelectionChanged={this.props.parkSelected}
                                isLoading={this.props.parksLoading}
                                selectedOption={this.props.availableParks.find(
                                    (p) => p.value == this.props.selectedParkCode
                                )}
                            />
                        </InputContainer>
                        )}
                    </div>

                  {(!this.props.useParkSelection || this.props.selectedParkCode) && (
                  <>
                    <div className="external-login-container mobile">
                      {/* <div className="facebook-login-button-container"> disabled as broken
                        <FacebookLogin
                          appId={facebookAppId}
                          autoLoad={false}
                          fields="email"
                          callback={this.responseFacebook}
                          render={(renderProps: any) => (
                            <button
                              className="facebook-login-button"
                              onClick={renderProps.onClick}
                              disabled={
                                renderProps.disabled ||
                                  (!this.props.selectedParkCode && this.props.useParkSelection)
                              }
                            >
                              <img
                                src="images/facebook-logo.svg"
                                alt="Facebook"
                              />
                              <span className="mobile">
                                Continue with Facebook
                              </span>
                            </button>
                          )}
                        />
                      </div> */}
                      <div className="google-login-button-container">
                        <GoogleLogin
                          clientId={googleClientId}
                          autoLoad={false}
                          render={(renderProps) => (
                            <button
                              className="google-login-button"
                              onClick={renderProps.onClick}
                              disabled={
                                renderProps.disabled ||
                                  (!this.props.selectedParkCode && this.props.useParkSelection)
                              }
                            >
                              <img
                                src="images/Google__G__Logo.svg"
                                alt="Google"
                              />
                              <span className="mobile">
                                Continue with Google
                              </span>
                            </button>
                          )}
                          onSuccess={this.responseGoogle}
                          onFailure={this.responseGoogle}
                        />
                      </div>
                      <div className="separator">
                        <span className="line"></span>
                        <span className="text">or</span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                      <InputContainer>
                        <input
                          name="emailAddress"
                          className="form-input-alternative"
                          type="email"
                          placeholder={t("login.emailAddressPlaceholder")}
                          value={this.state.emailAddress}
                          onChange={this.handleChange}
                          disabled={!this.props.selectedParkCode && this.props.useParkSelection}
                        />
                        <input
                          name="password"
                          className="form-input-alternative"
                          type="password"
                          placeholder={t("login.passwordPlaceholder")}
                          value={this.state.password}
                          onChange={this.handleChange}
                          disabled={!this.props.selectedParkCode && this.props.useParkSelection}
                        />
                      </InputContainer>
                      <div className="forgotten-password-link">
                        {
                          <Link
                            to={Routes.ForgottenPassword}
                            className="link-right mobile"
                          >
                            {t("login.forgotPasswordLink")}
                          </Link>
                        }
                      </div>
                      <p
                        className={classNames(
                          "error-message-mobile",
                          "login-failure-message",
                          {
                            show:
                              this.props.loginFailureMessage ||
                              this.props.sessionHasExpired,
                          }
                        )}
                      >
                        {this.props.sessionHasExpired
                          ? t("login.sessionExpired")
                          : t(this.props.loginFailureMessage)}
                      </p>
                      <ButtonContainer>
                        <SecondaryButton
                          type="submit"
                          disabled={
                            !this.state.emailAddress ||
                            !this.state.password ||
                            this.props.isLoggingIn ||
                            (!this.props.selectedParkCode && this.props.useParkSelection)
                          }
                          text={t(
                            this.props.isLoggingIn
                              ? "login.loggingIn"
                              : "login.loginButton"
                          )}
                          isLoading={this.props.isLoggingIn}
                        ></SecondaryButton>
                      </ButtonContainer>
                      <p className="heading-right">
                        {t("login.dontHaveAccountTitle")}
                      </p>
                      <ButtonContainer className="register-button-container">
                        <OutlineButton
                          text={t("login.registerButton")}
                          onClick={() => this.setState({ isRegister: true })}
                          disabled={!this.props.selectedParkCode && this.props.useParkSelection}
                        />
                      </ButtonContainer>
                    </form>
                  </>
                )}
              </>
            );
          }}
        </Translation>
      </ImageBackground>
    );
  }
}

export default LoginPageMobile;
