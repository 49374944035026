import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { SET_REFER_SENT_PROMPT_OPEN } from '../../../Modal/Redux/ActionTypes';
import React from "react";
import ReferralSentAlertModal from "../Presentational/ReferralSentAlertModal";
import { GetHTMlContentBlockCall } from "../../../Contentful/Calls/GetHTMLContentBlockCall";
import Spinner from "../../../Shared/Components/Presentational/Spinner";

interface ReferralSentAlertProps extends WithTranslation {
    showReferSentPrompt: boolean;
    setReferralSentModalOpen: (isOpen: boolean) => void;
    operationType: string;
}

interface ReferralSentAlertState {
  createModalText: ContentfulContentObject | undefined;
  updateModalText: ContentfulContentObject | undefined;
  contentLoaded: boolean;
}

class ReferralSentAlert extends Component<ReferralSentAlertProps, ReferralSentAlertState> {
  constructor(props: any) {
    super(props);

    this.state = {
      createModalText: undefined,
      updateModalText: undefined,
      contentLoaded: false,
    }

    Promise.all([
      this.getModalText("My Haulfryn - Membership - Referral - Transfer Confirmation", "createModalText"),
      this.getModalText("My Haulfryn - Membership - Referral - Update Confirmation", "updateModalText"),
    ]).then(r => {
      r.forEach(c => this.setState({ ...this.state, [c.name]: c }));
      this.setState({ contentLoaded: true });
    })
  }

  getModalText = async (name: string, label: string) => {
    const modalText = await GetHTMlContentBlockCall(name, label);
    return modalText;
  }

  applyModalText(): ContentfulContentObject {
    if(this.props.operationType === "create"){
      return this.state.createModalText!
    }else if(this.props.operationType === "update"){
      return this.state.updateModalText!
    }else{
      return {
        title: "",
        content: "",
        name: ""
      }
    }
  }

  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <>
        {this.state.contentLoaded ? (
        <>
          <Desktop>
            <ReferralSentAlertModal
              isOpen={this.props.showReferSentPrompt}
              setReferralSentModalOpen = {this.props.setReferralSentModalOpen}
              modalText={this.applyModalText()}
            />
          </Desktop>
          <Mobile>
            <ReferralSentAlertModal
              className="mobile-modal owner-contact-details-modal-mobile"
              isOpen={this.props.showReferSentPrompt}
              setReferralSentModalOpen = {this.props.setReferralSentModalOpen}
              modalText={this.applyModalText()}
            />
          </Mobile>
        </>
        ) : (
          <div className='memberships-spinner'>
            <Spinner />
          </div>
        )}
      </>
    );
  };
};

const mapStateToProps = (state: any) => {
    return {
        showReferSentPrompt: state.modal.referralSent.isOpen,
    };
  };
  
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
        setReferralSentModalOpen: (isOpen: boolean) => {
            dispatch({ type: SET_REFER_SENT_PROMPT_OPEN, payload: isOpen })
        } 
    };
  };


export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ReferralSentAlert)
)