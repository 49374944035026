import UserRoles from "../Constants/UserRoles";

export class TagsFilter {

    public removeContent(content: any,userRoles: UserRoleDTO[]): boolean{
        if(content.metadata && content.metadata.tags && content.metadata.tags.length !== 0){
        
            let audienceTags: Set<string> = new Set();
            content.metadata.tags.forEach((tag:any)=>{
                let id: string = tag.sys.id;
                if(id.startsWith("audience")){
                    audienceTags.add(id);
                }
            })
    
            if(audienceTags.size > 0) {
                if(!UserRoles.IsOwner(userRoles)){
                    if(audienceTags.has("audienceMembers")){
                        return false;
                    }else{
                        return true;
                    }    
                }else if(!UserRoles.IsOwner(userRoles)){
                    if(audienceTags.has("audienceOwners")){
                        return false;
                    }else{
                        return true;
                    }    
                }else{
                    return false;
                }
            }else{
                return false;     
            }
    
        }else{
            return false;
        }
    }
}