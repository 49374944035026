import React, { useEffect, useState } from "react";
import LoginPage from "../Presentational/LoginPage";
import WelcomePageMobile from "../Presentational/WelcomePage.mobile";
import LoginPageMobile from "../Presentational/LoginPage.mobile";
import {
  Desktop,
  Mobile,
} from "../../../Shared/Components/Presentational/ResponsiveViews";
import { connect } from "react-redux";
import { login, goToLogin } from "../../Redux/ActionCreators";
import { Redirect, useLocation } from "react-router";
import { GetParksCall } from "../../../Contentful/Calls/GetParksCall";
import { GetSettingCall } from "../../../Contentful/Calls/GetSettingCall";
import { CustomerPortalUrls } from "../../../Constants/Login/CustomerPortalUrls";
import { Entry } from "contentful";

interface Props {
  loginClick: (email: string, password: string, type: string) => void;
  goToLoginClick: () => void;
  onWelcomePage: boolean;
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  loginFailureMessage: string;
  location: any;
  sessionHasExpired: boolean;
  userRoles: UserRoleDTO[];
}

interface LoginUrlParams {
  park?: string;
}

const useQuery = () => new URLSearchParams(useLocation().search);

const Login = (props: Props) => {
  const {
    location,
    loginClick,
    goToLoginClick,
    isLoggedIn,
    isLoggingIn,
    loginFailureMessage,
    sessionHasExpired,
    onWelcomePage,
  } = props;

  const query = useQuery();

  const selectedParkContentfulCode = query.get("p");
  const redirectUrl = query.get("r");

  const [contentfulParks, setContentfulParks] = useState<
    Entry<ContentfulPark>[]
  >([]);

  const [selectedParkCode, setSelectedParkCode] = useState<string>();
  const [selectPark, setSelectPark] = useState<boolean>(false);
  const [parksLoading, setParksLoading] = useState<boolean>(false);
  const [availableParks, setAvailableParks] = useState<DropdownListOption[]>(
    []
  );

  const getParks = async () => {
    setParksLoading(true);

    let selectParkString = await GetSettingCall('MyHaulfrynShowParkSelector');
    setSelectPark(selectParkString.fields.value.trim().toLowerCase() === 'true');

    let parks = await GetParksCall();
    let dropwDownParks = parks
      .filter((p) => !!p.fields.customerPortalUrl)
      .map((p) => {
        return {
          label: p.fields.name,
          value: p.fields.code,
        } as DropdownListOption;
      });

    if (selectedParkContentfulCode) {
      setSelectedParkCode(selectedParkContentfulCode);
    }

    setContentfulParks(parks);
    setAvailableParks(dropwDownParks);

    setParksLoading(false);
  };

  const parkSelected = (parkOption: DropdownListOption) => {
    let url = getPortalUrlFromParkCode(parkOption.value);

    if (url && url != window.location.origin) {
      url += `/Login?p=${parkOption.value}`;

      if (redirectUrl) {
        url += `&r=${redirectUrl}`;
      }

      setSelectedParkCode(undefined);
      window.open(url, "_self");
      return;
    }

    setSelectedParkCode(parkOption.value);
  };

  const getPortalUrlFromParkCode = (parkCode: string) => {
    const selectedContentfulPark = contentfulParks.find(
      (p) => p.fields.code == parkCode
    );

    if (!selectedContentfulPark) {
      return;
    }

    switch (selectedContentfulPark.fields.customerPortalUrl) {
      case CustomerPortalUrls.Url1:
        return process.env.REACT_APP_CUSTOMER_PORTAL_URL1;
      case CustomerPortalUrls.Url2:
        return process.env.REACT_APP_CUSTOMER_PORTAL_URL2;
      default:
        return;
    }
  };

  useEffect(() => {
    getParks();
  }, []);

  if (isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: redirectUrl ? redirectUrl : "/",
        }}
      />
    );
  } else {
    return (
      <>
        <Desktop>
          <LoginPage
            loginClick={loginClick}
            isLoggingIn={isLoggingIn}
            loginFailureMessage={loginFailureMessage}
            sessionHasExpired={sessionHasExpired}
            parksLoading={parksLoading}
            availableParks={availableParks}
            selectedParkCode={selectedParkCode}
            parkSelected={parkSelected}
            useParkSelection={selectPark}
          />
        </Desktop>
        <Mobile>
          {onWelcomePage ? (
            <WelcomePageMobile goToLoginClick={goToLoginClick} />
          ) : (
            <LoginPageMobile
              loginClick={loginClick}
              isLoggingIn={isLoggingIn}
              loginFailureMessage={loginFailureMessage}
              sessionHasExpired={sessionHasExpired}
              parksLoading={parksLoading}
              availableParks={availableParks}
              selectedParkCode={selectedParkCode}
              parkSelected={parkSelected}
              useParkSelection={selectPark}
            />
          )}
        </Mobile>
      </>
    );
  }
};

const mapStateToProps = (state: any) => {
  return {
    onWelcomePage: state.login.onWelcomePage,
    isLoggingIn: state.login.loggingIn,
    isLoggedIn: state.login.loggedIn,
    loginFailureMessage: state.login.loginFailureMessage,
    sessionHasExpired: state.login.sessionHasExpired,
    userRoles: state.user.userRoles,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loginClick: (email: string, password: string, type: string) => {
      dispatch(login(email, password, type));
    },
    goToLoginClick: () => {
      dispatch(goToLogin());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
