import { PostApiCall } from "./ApiCall";

export class PostAssociateMembershipWithReferredUserCall extends PostApiCall {
    constructor(membership: NewMembershipDTO) {
        let url: string = "Membership/AssociateMembershipWithReferedUser";

        super(url);

        this.body = membership;
    }
}