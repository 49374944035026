import React, { Component } from "react";
import {
  Calendar as BigCalendar,
  momentLocalizer,
  Event,
  Views,
} from "react-big-calendar";
import "./Calendar.scss";
import moment from "moment";
import { Toggle } from "./Toggle";
import { Translation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import "moment/locale/en-gb";

const localizer = momentLocalizer(moment);

export interface CalendarProps {
  events: CalendarEvent[];
  resources: CalendarResource[];
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  containerClassName?: string;
  selectable?: boolean | "ignoreEvents";
  onSelectSlot?: (selectedItem: any) => void;
  onClickEvent?: (event: any, e?: any) => void;
  onRangeChange?: (range: any) => void;
  onNavigate?: (date: Date) => void;
  defaultDate?: Date;
  clickableEvents?: boolean;
}

export interface CalendarEvent extends Event {
  resourceId: number;
  className: string;
  isAddedRange?: boolean;
  subletCharges?: number;
  subletIncome?: number;
  isSubletBooking?: boolean;
}

export interface CalendarResource {
  id: number;
  title: string;
  className?: string;
  canToggle?: boolean;
  toggleValue?: boolean;
}

export class Calendar extends Component<CalendarProps> {
  public render(): JSX.Element {
    return (
      <Translation>
        {(t: (key: string) => string) => {
          return (
            <div
              className={classNames(
                "calendar-container",
                this.props.containerClassName
              )}
            >
              <BigCalendar
                selectable={this.props.selectable}
                min={this.props.minDate}
                max={this.props.maxDate}
                className={this.props.className}
                localizer={localizer}
                culture="en-GB"
                events={this.props.events
                  .filter((e) => this.canShowEvent(e))
                  .map((event) => {
                    let temp = Object.assign({}, event);
                    if (temp.end) {
                      let dateString: string = temp.end as any;
                      dateString =
                        dateString.substr(0, dateString.length - 1) + "1"; //add one second due big calendar issue
                      temp.end = dateString as any;
                    }
                    return temp;
                  })}
                resources={this.props.resources}
                resourceIdAccessor="id"
                resourceTitleAccessor="title"
                components={{
                  dateCellWrapper: (props) => (
                    <this.DateCellWrapper
                      onSelect={this.props.onSelectSlot}
                      {...props}
                    />
                  ),
                  eventWrapper: this.eventWrapper.bind(this),
                  toolbar: this.toolbar.bind(this),
                }}
                views={{ month: true }}
                onSelectSlot={this.props.onSelectSlot}
                longPressThreshold={1}
                onRangeChange={this.props.onRangeChange}
                onNavigate={this.props.onNavigate}
                defaultDate={this.props.defaultDate}
                drilldownView={null}
                onDoubleClickEvent={() => {}}
              />
              <div className="calendar-keys">
                {this.props.resources.map((resource) => (
                  <span
                    key={"resource-" + resource.id}
                    className={
                      "calendar-key" +
                      (resource.className ? " " + resource.className : "")
                    }
                  >
                    {resource.canToggle ? (
                      <Toggle
                        value={resource.toggleValue}
                        onChange={(newValue) =>
                          this.toggleChanged(resource, newValue)
                        }
                        customClassName={resource.className}
                      />
                    ) : (
                      <i className="calendar-colour"></i>
                    )}
                    {t(resource.title)}
                  </span>
                ))}
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }

  private DateCellWrapper: any = (props: any) =>
    React.Children.map(props.children, (child) =>
      React.cloneElement(child, {
        onTouchEnd: (event) => {
          event.preventDefault();
          props.onSelect(props.value);
        },
      })
    );

  private eventWrapper(eventWrapperProps: any): JSX.Element {
    let isEndingOnFirstWeekDay =
      moment(eventWrapperProps.event.end).day() === 1; // Ends on a Monday
    let isStartOnLastWeekDay =
      moment(eventWrapperProps.event.start).day() === 0; // Starts on a Sunday
    return (
      <div
        className="rbc-event-wrapper"
        onClick={(e) =>
          this.props.onClickEvent &&
          this.props.clickableEvents &&
          this.props.onClickEvent(eventWrapperProps.event, e)
        }
      >
        <div
          className={classNames(
            "rbc-event",
            eventWrapperProps.event.className,
            {
              "rbc-event-allday": eventWrapperProps.event.allDay,
              "rbc-event-continues-prior": eventWrapperProps.continuesPrior,
              "rbc-event-continues-after": eventWrapperProps.continuesAfter,
              "rbc-event-ends-on-week-start": isEndingOnFirstWeekDay,
              "rbc-event-starts-on-week-end": isStartOnLastWeekDay,
              clickable: this.props.clickableEvents,
            }
          )}
        >
          <div className="rbc-event-content" />
        </div>
      </div>
    );
  }

  private toolbar(toolbarProps: any): JSX.Element {
    const navigate = (action: any) => {
      toolbarProps.onNavigate(action);
    };

    return (
      <div className="rbc-toolbar">
        <span className="rbc-toolbar-label">{toolbarProps.label}</span>
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={navigate.bind(null, "PREV")}
            disabled={
              this.props.minDate &&
              moment(toolbarProps.date).isSameOrBefore(
                this.props.minDate,
                "month"
              )
            }
          >
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
          </button>
          <button type="button" onClick={navigate.bind(null, "TODAY")}>
            Today
          </button>
          <button
            type="button"
            onClick={navigate.bind(null, "NEXT")}
            disabled={
              this.props.maxDate &&
              moment(toolbarProps.date).isSameOrAfter(
                this.props.maxDate,
                "month"
              )
            }
          >
            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
          </button>
        </span>
      </div>
    );
  }

  private toggleChanged(resource: CalendarResource, newValue?: boolean): void {
    resource.toggleValue = newValue;
    this.setState({});
  }

  private canShowEvent(event: CalendarEvent): boolean {
    if (!this.props.resources || !this.props.resources.length) {
      return true;
    }

    return (
      this.props.resources
        .filter((r) => r.id === event.resourceId)
        .filter((r) => !r.canToggle || (r.canToggle && r.toggleValue)).length >
      0
    );
  }
}
