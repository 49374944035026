import React from "react";
import "./CoOwnerAccessCellRenderer.scss";
import DropdownList from "../../../Shared/Components/Presentational/DropdownList";
import { CustomColumnCellRendererProps } from "../../../Shared/Components/Presentational/Table";
import PassStatus from "../../../Constants/PassStatus";

const CoOwnerAccessCellRenderer: React.FC<CustomColumnCellRendererProps> = (
  props
) => {
  const { clickAction, rowValue } = props;

  const dropdownOptions: DropdownListOption[] =
    (props.globalCellProps && props.globalCellProps.coOwnerAccessTypes) || [];

  return (
    <div className="my-haulfryn-access-dropdown">
      {[PassStatus.AwaitingApproval, PassStatus.Approved, PassStatus.AwaitingPhoto].includes(
        rowValue.StatusCode
      ) && (
        <DropdownList
          options={dropdownOptions}
          onSelectionChanged={(opt) =>
            clickAction
              ? clickAction({ ...rowValue, CoOwnerAccessTypeCode: opt.value })
              : null
          }
          selectedOption={
            dropdownOptions.find(
              (o) => o.value == rowValue.CoOwnerAccessTypeCode
            ) || dropdownOptions.find((o) => o.value == "None")
          }
          isLoading={dropdownOptions == []}
        />
      )}
    </div>
  );
};

export default CoOwnerAccessCellRenderer;
