import React, { Component, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { WithTranslation, withTranslation } from "react-i18next";
import MyMembershipsCreatePromptModal from "../Presentational/MyMembershipsCreatePromptModal";
import { setDontShowAgainMembershipModal } from '../../../User/Redux/ActionCreators';
import * as ActionTypes from '../../../Guests/Redux/ActionTypes';
import { GetHTMlContentBlockCall } from "../../../Contentful/Calls/GetHTMLContentBlockCall";
import Spinner from "../../../Shared/Components/Presentational/Spinner";

interface MyMembershipsCreatePromptProps extends WithTranslation {
  showMembershipPrompt: boolean;
  setDontShowAgainMembershipModal: () => void;
  updateMembershipFormPage: (page: number) => void;
  clearMembershipForm: () => void;
}

interface MyMembershipsCreatePromptState {
  modalText: ContentfulContentObject | undefined;
  contentLoaded: boolean;
}

class MyMembershipsCreatePrompt extends Component<MyMembershipsCreatePromptProps, MyMembershipsCreatePromptState> {
  constructor(props: any) {
    super(props);

    this.state = {
      modalText: undefined,
      contentLoaded: false,
    }
    Promise.all([
      this.getModalText("My Haulfryn - Membership - My Haulfryn Home", "modalText")
    ]).then(r => {
      r.forEach(c => this.setState({ ...this.state, [c.name]: c }));
      this.setState({ contentLoaded: true });
    })
  }

  getModalText = async (name: string, label: string) => {
    const modalText = await GetHTMlContentBlockCall(name, label);
    return modalText;
  }

  public render(): JSX.Element {
    const { t } = this.props;

    const setDontShowAgainMembershipModal = () => {
      this.props.setDontShowAgainMembershipModal();
    }

    return (
      <>
        {this.state.contentLoaded ? (
        <>
          <Desktop>
            <MyMembershipsCreatePromptModal
              isOpen={this.props.showMembershipPrompt}
              setDontShowAgainMembershipModal={setDontShowAgainMembershipModal}
              updateMembershipFormPage={this.props.updateMembershipFormPage}
              clearMembershipForm={this.props.clearMembershipForm}
              modalText={this.state.modalText!}
            />
          </Desktop>
          <Mobile>
            <MyMembershipsCreatePromptModal
              className="mobile-modal owner-contact-details-modal-mobile"
              isOpen={this.props.showMembershipPrompt}
              setDontShowAgainMembershipModal={setDontShowAgainMembershipModal}
              updateMembershipFormPage={this.props.updateMembershipFormPage}
              clearMembershipForm={this.props.clearMembershipForm}
              modalText={this.state.modalText!}
            />
          </Mobile>
        </>
        ) : (
          <div className='memberships-spinner'>
            <Spinner />
          </div>
        )}
      </>
    );
  };

};

const mapStateToProps = (state: any) => {
  return {
    showMembershipPrompt: state.user.showMembershipPrompt,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setDontShowAgainMembershipModal: () => {
      dispatch(setDontShowAgainMembershipModal())
    },
    updateMembershipFormPage: (page: number) => {
      dispatch({ type: ActionTypes.UPDATE_MEMBERSHIP_FORM_PAGE, payload: page })
    },
    clearMembershipForm: () => {
      dispatch({ type: ActionTypes.SAVE_AND_EXIT })
    }
      
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MyMembershipsCreatePrompt)
)