import { PostApiCall } from './ApiCall';

export class PostMembershipRenewalCall extends PostApiCall {
  constructor(renewalDto: RenewMembershipDTO) {
    const url = 'Membership/renew-membership';
    super(url);

    this.body = renewalDto;
  }
}
