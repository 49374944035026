import { PostApiCall } from "./ApiCall";

export class PostConfirmMemberAccountCall extends PostApiCall {
    constructor(memberDetails: ConfirmMemberDetailsDTO) {
        let url: string = 'account/confirm-member-account';

        super(url);

        this.body = memberDetails;
    }
}