import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SecondaryButton } from '../../../../Shared/Components/Presentational/Buttons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import MembershipStates from '../../../../Constants/MembershipStates';
import { formatDate } from '../../../../Shared/Utilities/DateUtils';
import { Link } from 'react-router-dom';
import * as Routes from "../../../../Constants/Routes";

interface MembershipDashboardCardProps extends WithTranslation {
  referredMembership?: ReferralDTO;
  membership?: MembershipDTO;
  buttonFunction?: (value: any) => void;
  state: string;
  deleteFunction?: (isOpen: boolean, membershipId: string) => void;
  membershipText?: string;
  canRenew?: boolean;
  renewFunction?: (value: any) => void;
}

const MembershipDashboardCard: React.FC<MembershipDashboardCardProps> = (props: MembershipDashboardCardProps) => {
  const { t } = props;

  const handleClick = () => {
    props.buttonFunction && props.buttonFunction({ state: props.state, membership: props.membership } as MembershipContainer);
  }

  const handleDelete = () => {
    props.membership && props.deleteFunction && props.deleteFunction(true, props.membership.id!);
  }

  const handleRenew = () => {
    props.renewFunction && props.renewFunction({ state: props.state, membership: props.membership } as MembershipContainer);
  }

  const formatMembershipDates = () => {
    if (props.membership && props.membership.startDate && props.membership.endDate) {
      return `${formatDate(props.membership.startDate)} to ${formatDate(props.membership.endDate)}`;
    } else {
      return '';
    }
  }

  const getFormattedName = () => {
    if (props.membership) {
      return props.membership.firstName + " " + props.membership.lastName;
    }

    if (props.referredMembership) {
      return props.referredMembership.firstName + " " + props.referredMembership.lastName;
    }

    return '';
  }

  return (
    <>
      <h4 style={{ fontWeight: "bold" }}>{getFormattedName()}

        {props.state === MembershipStates.Draft &&
          <div
            style={{ float: "right", cursor: "pointer", marginRight: "10px", marginTop: "5px" }}
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        }
      </h4>
      <p style={{ wordWrap: "break-word" }}>{props.membership && props.membership.email}</p>
      <p>{props.membership && props.membership.membershipType}</p>
      <p>{props.membership && formatMembershipDates()}</p>
      <p style={{ float: "left", marginBottom: "0px" }}>{props.membershipText}</p>

      {
        props.referredMembership &&
        <>
          <p>Club membership transferred: {props.referredMembership.transferredMemberships} {props.referredMembership.transferredMemberships != 0 ? "(" + props.referredMembership.availableMemberships + " available)" : ""}</p>
          <p>Members managed: </p>
          <ul>
            {props.referredMembership.memberships.map((member) => {
              return <li style={{ fontWeight: "bold" }}>{member.firstName} {member.lastName} - {member.membershipType}</li>
            })}
          </ul>
        </>
      }
      {props.buttonFunction && (
        <div style={{ position: "absolute", bottom: "0", right: "0" }}>
          {props.canRenew && (
            <Link to={Routes.RenewMembership + "?member-id=" + props.membership!.memberId + "&existingEndDate=" + props.membership!.endDate}>
              <SecondaryButton
                text={props.membership!.hasRenewed! ? "Renewed" : t("myMemberships." + MembershipStates.Expired + ".buttonText")}
                onClick={handleRenew}
                height={"30px"}
                fontSize={"14px"}
                disabled={props.membership!.hasRenewed!}
              />
            </Link>
          )}
          <SecondaryButton
            text={t("myMemberships." + props.state + ".buttonText")}
            onClick={handleClick}
            height={"30px"}
            fontSize={"14px"}
          />
        </div>
      )}
    </>
  );
};

export default withTranslation()(MembershipDashboardCard);
