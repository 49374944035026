import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Get, Post } from "../../../../Api/ApiService";
import { GetPassPhotoSizeLimit } from "../../../../Api/Calls/GetPassPhotoSizeLimit";
import SimpleImageCropper, { IFileFromCrop } from "../../../../Shared/Components/Presentational/SimpleImageCropper";
import "../AddMembershipPage.scss";
import AddPassPhotoHeader from "../AddPassPhotoHeader";
import { ICallSave } from "./ICallSave";
import { GetPhotoByMemberIdCall } from "../../../../Api/Calls/GetPhotoByMemberIdCall";
import { UpdateMemberPhotoCall } from "../../../../Api/Calls/Member/UpdateMemberPhotoCall";

interface PhotoUploadPageProps {
  memberId: string;
  instructions: ContentfulContentObject;
  fileRestrictions: ContentfulContentObject;
  exception: ContentfulContentObject;
  enableSaveAndContinue: () => void;
  disableSaveAndContinue: () => void;
  triggerNextPage: () => void;
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  onSaveError: () => void;
}

const PhotoUpload = React.forwardRef<ICallSave, PhotoUploadPageProps>((props, ref) => {
  const [photoSizeLimitInKb, setphotoSizeLimitInKb] = useState<number>(10000);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [isNewPhoto, setIsNewPhoto] = useState<boolean>(true);
  const cropperRef = useRef<IFileFromCrop>(null);
  const [isSubmittingPhoto, setIsSubmittingPhoto] = useState<boolean>(false);

  useEffect(() => {
    if (fileUrl === '') {
      getPhotoPassCall();
      getPhotoPassSizeLimit();
    }
    window.scrollTo(0, 0);
  }, []);

  // If it is a new photo, the prop is called to enable save in the
  // AddMembership component
  // When save is called, we use the cropper ref to get the image file
  // SimpleCropper calls fileFromCrop to return the file, which
  // in turn calls submitPhotoPass
  // submitPhotoPass sends the image, and if a 200 response is recieved
  // then nextPage is trigger, else saveAndContinue is disabled

  useImperativeHandle(ref, () => ({
    save() {
      if (!isSubmittingPhoto && isNewPhoto && cropperRef.current) {
        setIsSubmittingPhoto(true);
        cropperRef.current.getFile();
      }
    }
  }));

  const fileFromCrop = (photoFile: File) => {
    props.setIsLoading(true);
    submitPhotoPass(photoFile);
  }

  const onSizeError = () => {

  }

  const onImageRemoved = () => {
    // TODO - delete image?
    props.disableSaveAndContinue();
    setIsNewPhoto(true);
  }

  const onImageLoad = () => {
    props.enableSaveAndContinue();
    setIsNewPhoto(true);
  }

  const getPhotoPassCall = () => Get<PhotoPassDTO>(new GetPhotoByMemberIdCall(props.memberId))
    .then(result => {
      setFileUrl(result.data.PhotoUrl);
      setIsNewPhoto(false);
      setIsSubmittingPhoto(false);
      props.enableSaveAndContinue();
    })
    .catch(() => {
      setFileUrl('');
      setIsNewPhoto(true);
      setIsSubmittingPhoto(false);
    });

  const getPhotoPassSizeLimit = () => Get<any>(new GetPassPhotoSizeLimit())
    .then((response) => {
      setphotoSizeLimitInKb(response.data.PhotoPassSizeLimit);
    })
    .catch((error) => console.log(error));

  const submitPhotoPass = async (photoFile: File) => {
    await Post<string>(new UpdateMemberPhotoCall(props.memberId, photoFile))
      .then((response) => {
        props.setIsLoading(false);
        props.triggerNextPage();
      })
      .catch((error) => {
        props.onSaveError();
        props.setIsLoading(false);
        props.disableSaveAndContinue();
        setIsSubmittingPhoto(false);
        setErrorMessage(true);
      });
  }

  return (
    <div id="center-photo">
      <div className="add-pass-photo-modal">
        <div className='desktopVersion'>
          <AddPassPhotoHeader
            headerText={''}
            instructions={props.instructions}
          />
        </div>

        <div className='mobileVersion'>
          <div style={{ marginTop: "50px" }}>
            <AddPassPhotoHeader
              headerText={''}
              instructions={props.instructions}
            />
          </div>
        </div>

        <div style={{ display: "block", justifyContent: "center" }}>
          {errorMessage && (
            <div className='error-message show contentful-text'>
              {documentToReactComponents(props.exception.content)}
            </div>
          )}

          <SimpleImageCropper
            photoSizeLimitInKb={photoSizeLimitInKb}
            fileFromCrop={fileFromCrop}
            ref={cropperRef}
            fileUrl={fileUrl}
            onImageRemoved={onImageRemoved}
            imageLoaded={onImageLoad}
            setNewImage={() => {
              props.enableSaveAndContinue();
            }}
            onSizeError={onSizeError}
          />

          <div className='contentful-text'>
            {documentToReactComponents(props.fileRestrictions.content)}
          </div>

          {props.isLoading && (
            <div style={{ fontWeight: "bold" }}>Photo Uploading ...</div>
          )}
        </div>
      </div>
    </div>
  );
});

export default PhotoUpload;