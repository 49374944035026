export class EditMemberDetailsForm implements IEditMemberDetailsForm {
    id?: string;
    firstName?: FormInput;
    lastName?: FormInput;
    email?: FormInput;
    phonenumber?: FormInput;
    vehicles?: FormInput;
    autoRenew?: boolean;

    public static fromEditMemberDetailsDTOBack(membershipData: EditMemberDetailsDTOBack): EditMemberDetailsForm {
        let editMemberDetails = new this();
        editMemberDetails.id = membershipData.Id && membershipData.Id;
        editMemberDetails.firstName = membershipData.FirstName ? { value: membershipData.FirstName, errors: [] } : { errors: [] };
        editMemberDetails.lastName = membershipData.LastName ? { value: membershipData.LastName, errors: [] } : { errors: [] };
        editMemberDetails.email = membershipData.Email ? { value: membershipData.Email, errors: [] } : { errors: [] };
        editMemberDetails.phonenumber = membershipData.PhoneNumber ? { value: membershipData.PhoneNumber, errors: [] } : { errors: [] };
        editMemberDetails.autoRenew = membershipData.AutoRenew && membershipData.AutoRenew;
        if (membershipData.Vehicles) {
            editMemberDetails.vehicles = { value: [], errors: []};
            for (let vehicle of membershipData.Vehicles) {
                editMemberDetails.vehicles!.value = editMemberDetails.vehicles!.value.concat([{ id: vehicle.Id, registration: vehicle.Registration, description: vehicle.Description }])
            }
        }
        return editMemberDetails;
    }


    constructor() {
        this.firstName = { errors: [] };
        this.lastName = { errors: [] };
        this.email = { errors: [] };
        this.phonenumber = { errors: [] };
        this.vehicles = { errors: [] };
    }
}