import { PostApiCall } from "./ApiCall";

export class PostAddCarPassesCall extends PostApiCall {
    constructor(lodgeId: string, passes: AddCarPassDTO[]) {
        let url: string = "passes/" + lodgeId + "/carPass";

        super(url);

        this.body = passes;
    }
}