import { fileToUrl, urlToFile } from "../../../../Shared/Utilities/FileUtils";
import { hasValue, isValidDate, isFutureDate } from "../../../../Shared/Utilities/Validation";

const ShortStayPassFields: FormField<AddShortStayPassDTO>[] = [
    {
        id: 'firstname',
        type: 'text',
        maxLength: 50,
        labelTranslationKey: 'myGuests.passes.add.fields.firstname',
        placeholderTranslationKey: 'myGuests.passes.add.fields.firstname',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.FirstName,
        setter: (pass, value) => pass.FirstName = value,
        flexBasis: '33%'
    },
    {
        id: 'lastname',
        type: 'text',
        maxLength: 50,
        labelTranslationKey: 'myGuests.passes.add.fields.lastname',
        placeholderTranslationKey: 'myGuests.passes.add.fields.lastname',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.LastName,
        setter: (pass, value) => pass.LastName = value,
        flexBasis: '33%'
    },
    {
        id: 'dateofbirth',
        type: 'date',
        maxLength: 10,
        labelTranslationKey: 'myGuests.passes.add.fields.dateOfBirth',
        placeholderTranslationKey: 'myGuests.passes.add.fields.dateFormat',
        validator: (value) => isValidDate(value),
        getter: (pass) => pass.DateOfBirth,
        setter: (pass, value) => pass.DateOfBirth = value,
        flexBasis: '33%'
    },
    {
        id: 'phonenuumber',
        type: 'text',
        maxLength: 100,
        labelTranslationKey: 'myGuests.passes.add.fields.phoneNumber',
        placeholderTranslationKey: 'myGuests.passes.add.fields.phoneNumber',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.PhoneNumber,
        setter: (pass, value) => pass.PhoneNumber = value,
        flexBasis: '33%'
    },
    {
        id: 'email',
        type: 'email',
        maxLength: 255,
        labelTranslationKey: 'myGuests.passes.add.fields.email',
        placeholderTranslationKey: 'myGuests.passes.add.fields.email',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.Email,
        setter: (pass, value) => pass.Email = value,
        flexBasis: '66%'
    },
    {
        id: 'startdate',
        type: 'date',
        maxLength: 10,
        labelTranslationKey: 'general.startDate',
        placeholderTranslationKey: 'myGuests.passes.add.fields.dateFormat',
        validator: (value) => isFutureDate(value),
        getter: (pass) => pass.StartDate!,
        setter: (pass, value) => pass.StartDate = value,
        flexBasis: '33%'
    },
    {
        id: 'passlengthdays',
        type: 'dropdown',
        labelTranslationKey: 'myGuests.passes.add.fields.passLength',
        placeholderTranslationKey: 'myGuests.passes.add.fields.passLength',
        validator: (value) => hasValue(value) && !isNaN(parseInt(value)),
        getter: (pass) => pass.PassLengthDays ? pass.PassLengthDays.toString() : '',
        setter: (pass, value) => pass.PassLengthDays = parseInt(value),
        flexBasis: '33%',
        options: [
            { label: '3 nights', value: '3' },
            { label: '4 nights', value: '4' },
            { label: '7 nights', value: '7' },
            { label: '14 nights', value: '14' }
        ]
    },
    {
        id: 'passPhoto',
        type: 'photo',
        labelTranslationKey: 'Photo Upload',
        placeholderTranslationKey: 'Photo Upload',
        validator: (value) => hasValue(value),
        getter: (pass) => fileToUrl(pass.Photo),
        setter: (pass, value) => urlToFile(value).then(photo => pass.Photo = photo),
        flexBasis: '32%',
    }
];

export default ShortStayPassFields;