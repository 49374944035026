import { PostApiCall } from './ApiCall';

export class PostBookTableCall extends PostApiCall {
    constructor(body: BookTableDTO) {
        let url: string = `external/booktable`;

        super(url);

        this.body = body;
        this.logoutOn401 = false;
    }
}
