import { PostApiCall } from "./ApiCall";

export class PostReferralMembership extends PostApiCall {
    constructor(ReferMembership: ReferMembershipDTO) {
        let url: string = "Membership/ReferMembership";

        super(url);

        this.body = ReferMembership;
    }
}