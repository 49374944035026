import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../Shared/Components/Presentational/Buttons';
const iconPath = process.env.PUBLIC_URL + '/images/';

interface ManageDigitalPassProps extends WithTranslation {
  memberId: string | undefined;
  description?: ContentfulContentObject;
  cardNumber?: string;
  isDecommissionedPark: boolean;
}

const ManageDigitalPass: React.FC<ManageDigitalPassProps> = (props: ManageDigitalPassProps) => {
  const { t } = props;

  return (
    <>
      <div className='row'>
        <div className='float-left'>
          {props.description && (
            <h2 style={{ marginLeft: "30px" }}>{props.description.title}</h2>
          )}
        </div>
      </div>

      <div className='row'>
        {props.description && (
          <div className="contentful-text" style={{ marginLeft: "5%", marginRight: "5%", marginBottom: "5%" }}>
            {documentToReactComponents(props.description.content)}
          </div>
        )}
      </div>

      <div style={{ marginLeft: '5%', marginRight: '5%' }}>
        <div style={{ marginBottom: '2%', width: '280px', margin: "10px", padding: "10px" }}>
          <a href={process.env.PUBLIC_URL + "/api/photo-pass/member-pass/" + props.memberId} target="_blank">
            <PrimaryButton
              text='Download digital pass'
              disabled={!props.cardNumber}
              width='250px'
            />
          </a>
        </div>

        {!props.isDecommissionedPark && (
          <div style={{ marginBottom: '5%', marginLeft: "8px", marginRight: "8px" }}>
            {props.cardNumber ? (
              <a href={process.env.PUBLIC_URL + "/api/applepasses/v1/passes/" + props.memberId} target="_blank">
                <img src={`${iconPath}add-to-apple-wallet.svg`} alt="Add to Apple Wallet" width="300px" height="75px" />
                {/* <PrimaryButton 
              text='Download apple pass' 
              disabled={!props.cardNumber}
              width='280px'
            /> */}
              </a>
            ) : (
              <p>Apple Wallet pass currently unavailable</p>
            )}
          </div>
        )}

        {!props.cardNumber && (
          <p>No digital pass currently available</p>
        )}
      </div>
    </>
  );
};

export default withTranslation()(ManageDigitalPass);