import React from 'react';
import { Translation } from 'react-i18next';
import { SecondaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { SelectedCalendarWidgetOptions } from '../../Redux/ActionTypes';
import { AppState } from '../../../RootReducer';
import { connect } from 'react-redux';
import { setSelectedCalendarWidget } from '../../Redux/ActionCreators';
import FeatureToggle from '../../../Shared/Components/Presentational/FeatureToggle';
import Features from '../../../Constants/Features';
import classNames from 'classnames';

interface MyCalendarEventsHeaderProps {
    className?: string;
    selectedWidget: SelectedCalendarWidgetOptions;
    setSelectedWidget: (selected: SelectedCalendarWidgetOptions, pageSize: number) => void;
    pageSize: number;
    userRoles: UserRoleDTO[];
}

const MyCalendarListHeader: React.FC<MyCalendarEventsHeaderProps> = props => {
    return (
        <Translation>
            {t => (
                <div className={props.className}>
                    <div className="button-group">
                        <FeatureToggle features={[Features.Events]}>
                            <SecondaryButton
                                type="button"
                                text={t('myCalendar.eventsFilterTitle')}
                                onClick={() => props.setSelectedWidget('events', props.pageSize)}
                                className={classNames({ active: props.selectedWidget === 'events' })}
                            />
                        </FeatureToggle>
                        <FeatureToggle features={[Features.Offers]}>
                            <SecondaryButton
                                type="button"
                                text={t('myCalendar.offersFilterTitle')}
                                onClick={() => props.setSelectedWidget('offers', props.pageSize)}
                                className={classNames({ active: props.selectedWidget === 'offers' })}
                            />
                        </FeatureToggle>
                    </div>
                </div>
            )}
        </Translation>
    );
};

const mapDispatchToProps = (dispatch: any, props: any) => {
    return {
        setSelectedWidget: (selected: SelectedCalendarWidgetOptions) =>
            dispatch(setSelectedCalendarWidget(selected, props.pageSize, props.userRoles))
    };
};

const mapStateToProps = (state: AppState) => ({
    selectedWidget: state.calendar.selectedCalendarWidget,
    parkName: (state.user.park && state.user.park.parkName) || 'your park',
    userRoles: state.user.userRoles
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCalendarListHeader);
