import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import * as Routes from "../../../Constants/Routes";
import { Desktop } from '../../../Shared/Components/Presentational/ResponsiveViews';

interface InvalidSessionPageProps extends WithTranslation {
  isHeaderOpen: boolean;
}

const InvalidSessionPage: React.FC<InvalidSessionPageProps> = (props) => {
  const { t } = props;

  return (
    <div className='widget-container'>
      <Desktop>
        {!props.isHeaderOpen && (
          <h1 className="widget-layout-title" style={{ color: "white" }}>{t("addMembership.membershipAgreement.title")}</h1>
        )}
      </Desktop>

      <Widget
        position={{
          columnIndex: 1,
          columnSpan: 12,
          rowIndex: 3,
          rowSpan: 9,
        }}
      >
        <div style={{ margin: "15px" }}>
          <div className="row" style={{ marginBottom: "25px" }}>
            <div className="float-left">
              <h1 style={{ margin: "20px", color: "#184f6d" }}>{t("finaliseMemberships.invalidSessionPage.title")}</h1>
            </div>
          </div>
        </div>

        <div className='row' style={{ margin: "30px" }}>
          <p>{t("finaliseMemberships.invalidSessionPage.message")}</p>
        </div>
        <div className='row' style={{ marginBottom: "25px", marginLeft: "30px" }}>
          <Link to={Routes.MyGuests}>
            <PrimaryButton
              text={t("addMembership.paymentSuccess.toMyMemberships")}
            />
          </Link>
        </div>
      </Widget>

    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isHeaderOpen: state.application.isHeaderOpen,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {

  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InvalidSessionPage));