import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../Shared/Components/Presentational/Buttons';
import RemainingMemberships from '../../../../Shared/Components/Presentational/RemainingMemberships';

interface FinaliseDisplayContainerProps extends WithTranslation {
  finaliseNowContainers: MembershipContainer[];
  finaliseLaterContainers: MembershipContainer[];
  state: string;
  signAndPay?: () => void;
  renderComponent: (container: MembershipContainer | undefined, isNow: boolean | undefined, referredMembership?: ReferralDTO | undefined) => any;
  willRequirePayment: (membershipContainers: MembershipContainer[]) => Promise<boolean>;
}

const FinaliseDisplayContainer: React.FC<FinaliseDisplayContainerProps> = (props: FinaliseDisplayContainerProps) => {
  const { t } = props;
  const [paymentRequired, setPaymentRequired] = useState(false);

  useEffect(() => {
    if (props.finaliseNowContainers) {
      props.willRequirePayment(props.finaliseNowContainers)
        .then(r => {
          setPaymentRequired(r);
        })
    }
  }, [props.finaliseNowContainers]);

  const handleSignAndPayClick = () => {
    props.signAndPay && props.signAndPay();
  }

  return (
    <>
      {props.finaliseNowContainers && props.finaliseNowContainers.length > 0 && (
        <div style={{ margin: "25px", marginTop: "15px", marginBottom: "15px" }}>
          <div className='dashboard-owner-memberships'>
            <RemainingMemberships />
          </div>
          <h2 className='container-title'>{t("myMemberships." + props.state + ".nowTitle")}</h2>

          <div>
            <div className='gridDisplay'>
              {props.finaliseNowContainers && props.finaliseNowContainers.map((membership, key) => props.renderComponent(membership, true))}
            </div>
            <div className="sign-agreement-button">
              <PrimaryButton
                text={t("myMemberships" + (paymentRequired ? ".signAgreementAndPay" : ".signAgreement"))}
                onClick={handleSignAndPayClick}
                width={"280px"}
              />
            </div>
          </div>
        </div>
      )}
      {props.finaliseLaterContainers && props.finaliseLaterContainers.length > 0 && (
        <div style={{ margin: "25px", marginTop: "15px", marginBottom: "15px" }}>
          <h2 className='container-title'>{t("myMemberships." + props.state + ".laterTitle")}</h2>

          <div>
            <div className='gridDisplay'>
              {props.finaliseLaterContainers && props.finaliseLaterContainers.map(membership => props.renderComponent(membership, undefined))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default withTranslation()(FinaliseDisplayContainer);