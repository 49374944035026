import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { Modal, ModalHeader, ModalProps } from '../../../Shared/Components/Presentational/Modal';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import * as Routes from "../../../Constants/Routes";
import { GetHTMlContentBlockCall } from '../../../Contentful/Calls/GetHTMLContentBlockCall';
import Spinner from '../../../Shared/Components/Presentational/Spinner';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface FriendMembershipModalProps extends ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  datesExceededFriendQuota: FriendQuotaExceededDTO[];
  isFinalisePage: boolean;
}

const FriendMembershipModal: React.FC<FriendMembershipModalProps> = (props: FriendMembershipModalProps) => {
  const { t } = useTranslation();

  const organiseDates = (dates: FriendQuotaExceededDTO[]) => {
    let dateRanges: DateRange[] = [];
    for (let date of dates) {
      let dateMinusOne = new Date(date.dateExceeded);
      dateMinusOne.setDate(dateMinusOne.getDate() - 1);
      let dateRange = dateRanges.find(d => d.endDate.toLocaleDateString() === dateMinusOne.toLocaleDateString() && d.quotaExceededBy === (date.quotaUsed - date.authorisedOverlaps - date.quota));
      if (dateRange === undefined) {
        dateRange = {
          startDate: date.dateExceeded,
          endDate: date.dateExceeded,
          quotaExceededBy: date.quotaUsed - date.authorisedOverlaps - date.quota
        };
        dateRanges = dateRanges.concat(dateRange);
      } else {
        dateRange.endDate = date.dateExceeded;
      }
    }
    return dateRanges;
  }

  const [quota, setQuota] = useState(props.datesExceededFriendQuota[0].quota);
  const [dateRanges, setDateRanges] = useState(organiseDates(props.datesExceededFriendQuota));
  const [membershipsExceedingQuota, setMembershipExceedingQuota] = useState<MembershipWithDates[]>([]);


  useEffect(() => {
    let tempArray: MembershipWithDates[] = [];
    props.datesExceededFriendQuota.map(date => {
      for (let membership of date.membershipsOnDate) {
        if (tempArray.findIndex(m => m.id === membership.id) === -1) {
          tempArray.push(membership);
        }
      }
    });
    setMembershipExceedingQuota(tempArray);
  }, [props.datesExceededFriendQuota]);

  const handleClick = () => {
    props.closeModal();
  }

  const toPluralise = (array: any) => {
    return array.length > 1;
  }

  const formContents = (
    <div className='button-container-modal' style={{ display: "block" }}>
      <div className='container'>
        <ModalHeader text={"Friend membership concurrent quota exceeded"} />
        <div>
          <p style={{ marginBottom: "0px" }}>You are trying to finalise {toPluralise(membershipsExceedingQuota) ? "" : " a "}Friend membership{toPluralise(membershipsExceedingQuota) ? "s" : ""} that will exceed the daily quota of {quota} on {toPluralise(props.datesExceededFriendQuota) ? "these" : "this"} date{toPluralise(props.datesExceededFriendQuota) ? "s" : ""}:</p>
          <ul style={{ textAlign: "left" }}>
            {dateRanges.map((dateRange) => (
              //TO-DO: Change date formula calculation to use function that is on James' branch
              <li>Between {dateRange.startDate.getDate().toString() + "/" + String(dateRange.startDate.getMonth() + 1) + "/" + dateRange.startDate.getFullYear().toString()} and {dateRange.endDate.getDate().toString() + "/" + String(dateRange.endDate.getMonth() + 1) + "/" + dateRange.endDate.getFullYear().toString()} the quota will exceed by {dateRange.quotaExceededBy}</li>
            ))}
          </ul>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {props.isFinalisePage ? (
            <Link to={Routes.MyGuests} style={{ width: "100%" }}>
              <PrimaryButton
                text="Back"
                onClick={handleClick}
                width="300px"
              />
            </Link>
          ) : (
            <PrimaryButton
              text={t("general.close")}
              onClick={handleClick}
              width="300px"
            />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {membershipsExceedingQuota && membershipsExceedingQuota.length > 0 && (
        <>
          <Desktop>
            <Modal
              isOpen={props.isOpen}
              className={classNames("owner-contact-details-modal")}
            >
              {formContents}
            </Modal>
          </Desktop>
          <Mobile>
            <Modal
              isOpen={props.isOpen}
              className={classNames("owner-contact-details-modal-mobile mobile-modal")}
            >
              {formContents}
            </Modal>
          </Mobile>
        </>
      )}
    </>
  )
}

export default FriendMembershipModal;