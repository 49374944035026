import React, { Component } from 'react';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import { Translation } from 'react-i18next';
import { WidgetPosition } from '../../../Shared/Components/Presentational/Widget';
import './WelcomeMessageWidget.scss';
//@ts-ignore
import Fade from 'react-reveal/Fade';

interface WelcomeMessageWidgetProps {
    position: WidgetPosition;
    userFirstName: string;
}

class WelcomeMessageWidget extends Component<WelcomeMessageWidgetProps> {
    render() {
        return (
            <Translation>
                {
                    (t: (key: string, args?: {}) => string) => {
                        return (
                            <Widget containerClassName="welcome-widget" position={this.props.position}>
                                <Fade left>
                                    <span dangerouslySetInnerHTML={{ __html: t('welcome.message', { firstname: this.props.userFirstName, interpolation: { escapeValue: false } }) }}></span>
                                </Fade>
                            </Widget>
                        );
                    }
                }
            </Translation>
        )
    };
}

export default WelcomeMessageWidget;