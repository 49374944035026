import React, { Component } from "react";
import { Route } from "react-router";
import { connect } from "react-redux";
import Layout from "./Shared/Components/Presentational/Layout";
import { logout } from "./Login/Redux/ActionCreators";
import {
  setUserLodge,
  setUserPark,
  getMyContactDetails,
  getMarketingPreferences,
} from "./User/Redux/ActionCreators";
import { setLodgeDetails } from "./Information/Redux/ActionCreators";
import { getParkPassTypes } from "./Guests/Redux/ActionCreators";
import MyContactDetails from "./Modal/Components/Container/MyContactDetails";
import { SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN } from "./Modal/Redux/ActionTypes";

interface DefaultLayoutRouteProps {
  lodges: LodgeDTO[];
  lodgeOptions: [DropdownListOption];
  selectedLodge: DropdownListOption;
  setSelectedLodge: (lodge: SelectedLodgeDTO) => void;
  setSelectedPark: (
    parkCode: string,
    parkName: string,
    parkFeatures: string[]
  ) => void;
  logoutClick: () => void;
  contractEndDate: string;
  myContactDetails: MyContactDetailsDTO;
  openMyContactDetailsModal: () => void;
  closeMyContactDetailsModal: () => void;
  getMyContactDetails: any;
  getMarketingPreferences: any;
  marketingPreferences: MarketingPreferencesDTO | undefined;
  hasSublettings: boolean;
  ownerId: number;
  loginMethod: string;
  userRoles: UserRoleDTO[];
  useMemberships: boolean;
}

class DefaultLayoutRoute extends Component<DefaultLayoutRouteProps> {
  componentDidMount() {
    if (!this.props.myContactDetails) {
      this.props.getMyContactDetails();
    }

    if (!this.props.marketingPreferences && this.props.ownerId > 0) {
      this.props.getMarketingPreferences();
    }
  }

  public isEmptyOrUndefined(obj: {} | undefined) {
    if (obj !== undefined) {
      return Object.keys(obj).length === 0;
    } else {
      return true;
    }
  }

  public showContactDetailsModal() {
    return ((this.props.loginMethod === "email" && this.props.ownerId < 1) || (this.props.ownerId > 0 && !this.isEmptyOrUndefined(this.props.marketingPreferences) && !this.isEmptyOrUndefined(this.props.myContactDetails)));
  }

  render() {
    return (
      <Route
        path="/"
        render={({ match: { url } }) => (
          <Layout
            lodges={this.props.lodges}
            lodgeOptions={this.props.lodgeOptions}
            selectedLodge={this.props.selectedLodge}
            setSelectedLodge={this.props.setSelectedLodge}
            setSelectedPark={this.props.setSelectedPark}
            logoutClick={this.props.logoutClick}
            contractEndDate={this.props.contractEndDate}
            myContactDetails={this.props.myContactDetails}
            openMyContactDetailsModal={this.props.openMyContactDetailsModal}
            hasSublettings={this.props.hasSublettings}
            ownerId={this.props.ownerId}
            showContactDetailsModal={this.showContactDetailsModal()}
            userRoles={this.props.userRoles}
            useMemberships={this.props.useMemberships}
          >
            {this.props.children}
            {(this.showContactDetailsModal()) && (
              <MyContactDetails
                myContactDetails={this.props.myContactDetails}
                onBackButtonClick={this.props.closeMyContactDetailsModal}
                marketingPreferences={this.props.marketingPreferences}
              />
            )}
          </Layout>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutClick: () => {
      dispatch(logout(false));
    },
    setSelectedLodge: (lodge: SelectedLodgeDTO) => {
      dispatch(setUserLodge(lodge));
      dispatch(setLodgeDetails(lodge.LodgeId.toString()));
      dispatch(getParkPassTypes());
    },
    setSelectedPark: (
      parkCode: string,
      parkName: string,
      parkFeatures: string[]
    ) => {
      dispatch(setUserPark({ parkCode, parkName, parkFeatures }));
    },
    getMyContactDetails: () => dispatch(getMyContactDetails()),
    getMarketingPreferences: () => dispatch(getMarketingPreferences()),
    openMyContactDetailsModal: () =>
      dispatch({ type: SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN, payload: true }),
    closeMyContactDetailsModal: () =>
      dispatch({ type: SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN, payload: false }),
  };
};

const mapStateToProps = (state: any) => {
  let lodges: LodgeDTO[] = [];
  let selectedLodge: DropdownListOption = { value: "", label: "" };
  let lodgeOptions: [DropdownListOption] = [{ value: "", label: "" }];
  let contractEndDate: string = "";
  let hasSublettings = false;

  if (state.user) {
    lodges = state.user.lodges ? state.user.lodges : [];

    for (var i = 0; i < lodges.length; i++) {
      lodgeOptions[i] = {
        value: lodges[i].LodgeId.toString(),
        label: lodges[i].Name,
      };

      if (state.user.lodge && state.user.lodge.LodgeId === lodges[i].LodgeId) {
        selectedLodge = lodgeOptions[i];
      }
    }

    if (state.user.lodge) {
      contractEndDate = state.user.lodge.ContractEndDate;

      const selectedLodge: LodgeDTO = state.user.lodges.find(
        (x: LodgeDTO) => x.LodgeId === state.user.lodge.LodgeId
      );
      if (selectedLodge) {
        hasSublettings = selectedLodge.HasSublettings;
      }
    }
  }

  return {
    lodges,
    selectedLodge,
    lodgeOptions,
    contractEndDate,
    myContactDetails: state.user.myContactDetails || {},
    marketingPreferences: state.user.marketingPreferences,
    ownerId: state.user.ownerId,
    hasSublettings,
    loginMethod: state.login.loginMethod,
    userRoles: state.user.userRoles,
    useMemberships: state.guests.useMemberships,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayoutRoute);
