import React, { Component } from 'react';
import './Toggle.scss';
import classNames from 'classnames';

interface ToggleProps {
    value?: boolean;
    onChange?: (value?: boolean) => void;
    customClassName?: string;
}

interface ToggleState {
    value: boolean;
}

export class Toggle extends Component<ToggleProps, ToggleState> {
    public state: ToggleState = {
        value: this.props.value || false
    };

    public render(): JSX.Element {
        return (
            <div
                className={classNames('toggle-container', { active: this.state.value }, this.props.customClassName)}
                onClick={() => this.toggleClicked()}
            >
                <button className="toggle-knob"></button>
            </div>
        );
    }

    private toggleClicked(): void {
        const newValue = !this.state.value;

        this.setState({ value: newValue });

        if (typeof this.props.onChange == 'function') {
            (this.props.onChange as (value: boolean) => void)(newValue);
        }
    }
}
