import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { SET_REFER_MEMBERSHIPS_PROMPT_OPEN } from '../../../Modal/Redux/ActionTypes';
import ReferMembershipsPromptModal from "../Presentational/ReferMembershipsPromptModal";
import React from "react";
import * as ActionTypes from '../../../Guests/Redux/ActionTypes';
import { GetHTMlContentBlockCall } from "../../../Contentful/Calls/GetHTMLContentBlockCall";
import { Entry } from "contentful";

interface ReferMembershipsPromptProps extends WithTranslation {
  showReferPrompt: boolean;
  setReferMembershipModalOpen: (isOpen: boolean) => void;
  clearMembershipForm: () => void;
  contentfulMembershipTypes: Entry<ContentfulMembershipType>[];
  canRefer: boolean;
}

interface ReferMembershipsPromptState {
  modalText: ContentfulContentObject | undefined;
  contentLoaded: boolean;
}

class ReferMembershipsPrompt extends Component<ReferMembershipsPromptProps, ReferMembershipsPromptState> {
  constructor(props: any) {
    super(props);

    this.state = {
      modalText: undefined,
      contentLoaded: false,
    }
    Promise.all([
      this.getModalText("My Haulfryn - Membership - Add or Refer", "modalText")
    ]).then(r => {
      r.forEach(c => this.setState({ ...this.state, [c.name]: c }));
      this.setState({ contentLoaded: true });
    })
  }

  getModalText = async (name: string, label: string) => {
    const modalText = await GetHTMlContentBlockCall(name, label);
    return modalText;
  }


  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <>
        {this.state.contentLoaded && (
          <>
            <Desktop>
              <ReferMembershipsPromptModal
                isOpen={this.props.showReferPrompt}
                setReferMembershipModalOpen={this.props.setReferMembershipModalOpen}
                clearMembershipForm={this.props.clearMembershipForm}
                modalText={this.state.modalText!}
                canAdd={this.props.contentfulMembershipTypes && this.props.contentfulMembershipTypes.length > 0}
                canRefer={this.props.canRefer}
              />
            </Desktop>
            <Mobile>
              <ReferMembershipsPromptModal
                className="mobile-modal owner-contact-details-modal-mobile"
                isOpen={this.props.showReferPrompt}
                setReferMembershipModalOpen={this.props.setReferMembershipModalOpen}
                clearMembershipForm={this.props.clearMembershipForm}
                modalText={this.state.modalText!}
                canAdd={this.props.contentfulMembershipTypes && this.props.contentfulMembershipTypes.length > 0}
                canRefer={this.props.canRefer}
              />
            </Mobile>
          </>
        )}
      </>
    );
  };
};

const mapStateToProps = (state: any) => {
  return {
    showReferPrompt: state.modal.referMembership.isOpen,
    contentfulMembershipTypes: state.guests.contentfulMembershipTypes,
    canRefer: state.user.canReferWithMemberships || state.user.canReferWithOutMemberships,
  };
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    setReferMembershipModalOpen: (isOpen: boolean) => {
      dispatch({ type: SET_REFER_MEMBERSHIPS_PROMPT_OPEN, payload: isOpen })
    },
    clearMembershipForm: () => {
      dispatch({ type: ActionTypes.SAVE_AND_EXIT });
    },
  };
};


export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ReferMembershipsPrompt)
)