import React from 'react';
import './Fab.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ClickableIcon } from './Icons';
import classNames from 'classnames';

interface FabProps {
    icon: IconProp;
    clickAction: () => void;
    className: string;
    id?: string;
}

const Fab: React.FC<FabProps> = props => {
    return (
        <ClickableIcon
            id={props.id}
            icon={props.icon}
            className={classNames('fab', props.className)}
            clickAction={props.clickAction}
        />
    );
};

export default Fab;
