import { BLOCKS, Block, Inline } from '@contentful/rich-text-types'
import { Options, RenderNode } from '@contentful/rich-text-react-renderer';
import React, { ReactNode } from 'react';
import { Asset } from 'contentful';

const RichTextRenderOptions: Options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline, children: ReactNode): ReactNode => {
            const asset = node.data.target as Asset;
            switch (asset.fields.file.contentType.split('/')[0]) {
                case 'image':
                    return (
                        <img
                            src={`https://${asset.fields.file.url}`}
                            alt={asset.fields.description}
                            title={asset.fields.title}
                        />
                    );
                case 'text':
                    return(
                        <a
                            title={asset.fields.title}
                            href={asset.fields.file.url}
                        >{asset.fields.file.fileName}</a>
                    );
            }

        }
    }
}

export default RichTextRenderOptions;