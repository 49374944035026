import { icon, library, Icon, IconProp } from '@fortawesome/fontawesome-svg-core';
import { fas, IconLookup, IconName, IconPrefix } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import './ImportantInformationWidget.scss';
import classNames from 'classnames';
import { Entry } from 'contentful';
import { fab } from '@fortawesome/free-brands-svg-icons';

interface ImportantInformationWidgetProps {
    importantInformation: Entry<ContentfulHTMLContentBlock>;
    onClick: () => void;
    containerClassName?: string;
}

const ImportantInformationWidget: React.FC<ImportantInformationWidgetProps> = ({
    importantInformation,
    onClick,
    containerClassName,
}) => {
    const [fontAwesomeIcon, setFontAwesomeIcon] = useState<Icon>();
    useEffect(() => {
        let faIcon: IconLookup = { prefix: '' as IconPrefix, iconName: '' as IconName };
        if (importantInformation.fields.icon) {
            const split = importantInformation.fields.icon.split(' ');
            if (split.length === 2) {
                faIcon = {
                    prefix: split[0] as IconPrefix,
                    iconName: split[1].replace('fa-', '') as IconName,
                };
                library.add(fas);
                library.add(fab);

                setFontAwesomeIcon(icon(faIcon));
            } else {
                const split = importantInformation.fields.icon.split('-');
                let faIcon: IconLookup = {
                    prefix: split.splice(0, 1).toString() as IconPrefix,
                    iconName: split.join('-') as IconName,
                };
                library.add(fas);
                library.add(fab);
                setFontAwesomeIcon(icon(faIcon));
            }
        }
    }, [importantInformation.fields.icon]);

    return (
        <>
            <Desktop>
                <div className={classNames('important-info-container', containerClassName)} onClick={onClick}>
                    <div className="important-info-icon">
                        {fontAwesomeIcon && <FontAwesomeIcon icon={fontAwesomeIcon} />}
                    </div>
                    <div className="important-info-title">{importantInformation.fields.title}</div>
                </div>
            </Desktop><Mobile>
                <div className={classNames('important-info-container-mobile', containerClassName)} onClick={onClick}>
                    <div className="important-info-icon">
                        {fontAwesomeIcon && <FontAwesomeIcon icon={fontAwesomeIcon} />}
                    </div>
                    <div className="important-info-title-mobile">{importantInformation.fields.title}</div>
                </div>
            </Mobile>
        </>
    );
};

export default ImportantInformationWidget;
