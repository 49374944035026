import { Action } from 'redux';

export const SEARCH_TRANSACTIONS_REQUEST = 'SEARCH_TRANSACTIONS_REQUEST';
export const SEARCH_TRANSACTIONS_SUCCESS = 'SEARCH_TRANSACTIONS_SUCCESS';
export const SEARCH_TRANSACTIONS_FAILURE = 'SEARCH_TRANSACTIONS_FAILURE';
export const SET_TRANSACTION_FILTER = 'SET_TRANSACTION_FILTER';
export const SET_IS_MY_BANK_DETAILS_MODAL_OPEN = 'SET_IS_MY_BANK_DETAILS_MODAL_OPEN';
export const GET_MY_BANK_DETAILS_REQUEST = 'GET_MY_BANK_DETAILS_REQUEST';
export const GET_MY_BANK_DETAILS_SUCCESS = 'GET_MY_BANK_DETAILS_SUCCESS';
export const GET_MY_BANK_DETAILS_FAILURE = 'GET_MY_BANK_DETAILS_FAILURE';
export const SET_IS_HAULFRYN_BANK_DETAILS_MODAL_OPEN = 'SET_IS_HAULFRYN_BANK_DETAILS_MODAL_OPEN';
export const GET_HAULFRYN_BANK_DETAILS_REQUEST = 'GET_HAULFRYN_BANK_DETAILS_REQUEST';
export const GET_HAULFRYN_BANK_DETAILS_SUCCESS = 'GET_HAULFRYN_BANK_DETAILS_SUCCESS';
export const GET_HAULFRYN_BANK_DETAILS_FAILURE = 'GET_HAULFRYN_BANK_DETAILS_FAILURE';
export const DOWNLOAND_STATEMENT_REQUEST = 'DOWNLOAND_STATEMENT_REQUEST';
export const DOWNLOAND_STATEMENT_SUCCESS = 'DOWNLOAND_STATEMENT_SUCCESS';
export const DOWNLOAND_STATEMENT_FAILURE = 'DOWNLOAND_STATEMENT_FAILURE';
export const DOWNLOAND_PDF_STATEMENT_REQUEST = 'DOWNLOAND_PDF_STATEMENT_REQUEST';
export const DOWNLOAND_PDF_STATEMENT_SUCCESS = 'DOWNLOAND_PDF_STATEMENT_SUCCESS';
export const DOWNLOAND_PDF_STATEMENT_FAILURE = 'DOWNLOAND_PDF_STATEMENT_FAILURE';
export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const PAYMENT_CONFIRMING = 'PAYMENT_CONFIRMING';
export const PAYMENT_CONFIRMED = 'PAYMENT_CONFIRMED';

export interface SearchTransactionsAction extends Action<typeof SEARCH_TRANSACTIONS_REQUEST> {
    payload: boolean;
}

export interface SearchTransactionsSuccessAction extends Action<typeof SEARCH_TRANSACTIONS_SUCCESS> {
    payload: PagedResultDTO;
}

export interface SearchTransactionsFailureAction extends Action<typeof SEARCH_TRANSACTIONS_FAILURE> {
    payload: any;
}

export interface SetTransactionFilterAction extends Action<typeof SET_TRANSACTION_FILTER> {
    payload: TransactionFilterDTO;
}

export interface SetIsMyBankDetailsModalOpenAction extends Action<typeof SET_IS_MY_BANK_DETAILS_MODAL_OPEN> {
    payload: boolean;
}

export interface SetIsHaulfrynBankDetailsModalOpenAction
    extends Action<typeof SET_IS_HAULFRYN_BANK_DETAILS_MODAL_OPEN> {
    payload: boolean;
}

export interface GetMyBankDetailsRequestAction extends Action<typeof GET_MY_BANK_DETAILS_REQUEST> {
    payload: any;
}

export interface GetMyBankDetailsSuccessAction extends Action<typeof GET_MY_BANK_DETAILS_SUCCESS> {
    payload: MyBankDetailsDTO;
}

export interface GetMyBankDetailsFailureAction extends Action<typeof GET_MY_BANK_DETAILS_FAILURE> {
    payload: any;
}

export interface GetHaulfrynBankDetailsRequestAction extends Action<typeof GET_HAULFRYN_BANK_DETAILS_REQUEST> {
    payload: any;
}
export interface GetHaulfrynBankDetailsSuccessAction extends Action<typeof GET_HAULFRYN_BANK_DETAILS_SUCCESS> {
    payload: HaulfrynBankDetailsDTO;
}

export interface GetHaulfrynBankDetailsFailureAction extends Action<typeof GET_HAULFRYN_BANK_DETAILS_FAILURE> {
    payload: any;
}

export interface DownloadStatementAction extends Action<typeof DOWNLOAND_STATEMENT_REQUEST> {
    payload: boolean;
}

export interface DownloadStatementSuccessAction extends Action<typeof DOWNLOAND_STATEMENT_SUCCESS> {
    payload: boolean;
}

export interface DownloadStatementFailureAction extends Action<typeof DOWNLOAND_STATEMENT_FAILURE> {
    payload: string;
}

export interface DownloadPdfStatementAction extends Action<typeof DOWNLOAND_PDF_STATEMENT_REQUEST> {
    payload: boolean;
}

export interface DownloadPdfStatementSuccessAction extends Action<typeof DOWNLOAND_PDF_STATEMENT_SUCCESS> {
    payload: boolean;
}

export interface DownloadPdfStatementFailureAction extends Action<typeof DOWNLOAND_PDF_STATEMENT_FAILURE> {
    payload: string;
}


export interface MakePaymentAction extends Action<typeof MAKE_PAYMENT> {
    payload: PaymentDetails;
}
export interface PaymentConfirmingAction extends Action<typeof PAYMENT_CONFIRMING> {
    payload: any;
}
export interface PaymentConfirmedAction extends Action<typeof PAYMENT_CONFIRMED> {
    payload: number;
}

export type AccountActionTypes =
    | SearchTransactionsAction
    | SearchTransactionsSuccessAction
    | SearchTransactionsFailureAction
    | SetTransactionFilterAction
    | SetIsMyBankDetailsModalOpenAction
    | GetMyBankDetailsRequestAction
    | GetMyBankDetailsSuccessAction
    | GetMyBankDetailsFailureAction
    | DownloadStatementAction
    | DownloadStatementSuccessAction
    | DownloadStatementFailureAction
    | DownloadPdfStatementAction
    | DownloadPdfStatementSuccessAction
    | DownloadPdfStatementFailureAction
    | SetIsHaulfrynBankDetailsModalOpenAction
    | GetHaulfrynBankDetailsRequestAction
    | GetHaulfrynBankDetailsSuccessAction
    | GetHaulfrynBankDetailsFailureAction
    | MakePaymentAction
    | PaymentConfirmingAction
    | PaymentConfirmedAction;
