import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import CalendarItemDetailsModal from '../Presentational/CalendarItemDetailsModal';
import { AppState } from '../../../RootReducer';
import { SelectedCalendarWidgetOptions } from '../../Redux/ActionTypes';
import { Entry } from 'contentful';

interface CalendarItemDetailsProps {
    isOpen: boolean;
    item: Entry<ContentfulEvent | ContentfulOffer>;
    onBackButtonClick: () => void;
    itemType: SelectedCalendarWidgetOptions;
}

class CalendarItemDetails extends Component<CalendarItemDetailsProps> {

    public render(): JSX.Element {

        return (
            <>
                {
                    this.props.isOpen &&
                    <>
                        <Desktop>
                            <CalendarItemDetailsModal
                                isOpen={true}
                                item={this.props.item}
                                onBackButtonClick={this.props.onBackButtonClick}
                                onRequestClose={this.props.onBackButtonClick}
                                itemType={this.props.itemType} />
                        </Desktop>
                        <Mobile>
                            <CalendarItemDetailsModal
                                isOpen={true}
                                item={this.props.item}
                                onBackButtonClick={this.props.onBackButtonClick}
                                itemType={this.props.itemType} />
                        </Mobile>
                    </>
                }
            </>
        );
    };
}

const mapStateToProps = (state: AppState) => {
    return {
        isOpen: state.calendar.isEventDetailsModalOpen,
    }
}

export default connect(mapStateToProps)(CalendarItemDetails);