import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { SecondaryButton } from "../../../../Shared/Components/Presentational/Buttons";
import FileUpload from "../../../../Shared/Components/Presentational/FileUpload";
import { Desktop, Mobile } from "../../../../Shared/Components/Presentational/ResponsiveViews";
import "./MembershipPageStyles.scss";

interface AgeVerificationProps extends WithTranslation {
  form: IMembershipForm;
  uploadFile: (file: any, membershipId: string, firstName: string, lastName: string, dateOfBirth: Date) => void;
  showErrors: boolean;
  description: ContentfulContentObject;
  uploadSuccess: boolean;
  disableSaveFunc: (disable: boolean) => void;
  isLoading: boolean;
  addingAgeVerificationImageError: boolean;
  addingAgeVerificationImageErrorMessage: string;
}

const AgeVerification: React.FC<AgeVerificationProps> = (props) => {
  const { t } = props;

  useEffect(() => {
    props.disableSaveFunc(!props.form.ageVerificationSent && !props.uploadSuccess);
    window.scrollTo(0, 0);
  }, []);

  const calculateAge = (dateOfBirth: Date): number => {
    let today = new Date();
    let birthDate = new Date(dateOfBirth);
    let ageNow = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      ageNow--;
    }
    return ageNow;
  }

  const handleUpload = (file: any, membershipId: string) => {
    props.form && props.uploadFile(file, membershipId, props.form.firstName!.value, props.form.lastName!.value, props.form.dateOfBirth!.value);
  }

  const [age, setAge] = useState(props.form.dateOfBirth ? calculateAge(props.form.dateOfBirth.value) : 0);

  return (
    <div className="form">
      <div className='row' style={{ marginBottom: "25px" }}>
        <div className='title-header'>
          <Desktop>
            <h2 style={{ margin: "0px" }}>{t("addMembership.ageVerification.title")}</h2>
          </Desktop>
          <Mobile>
            <h2 style={{ margin: "0px", marginTop: "50px" }}>{t("addMembership.ageVerification.title")}</h2>
          </Mobile>
        </div>
      </div>

      <div className="contentful-text">
        {documentToReactComponents(props.description.content)}
      </div>
      {props.form && props.uploadSuccess ? (
        <p style={{ marginTop: "20px" }}>File Uploaded</p>
      ) : (
        <FileUpload
          handleFile={handleUpload}
          membershipId={props.form.id}
          id="fileUpload"
          textOverride={true}
        />
      )}

      {props.form && props.form.ageVerificationSent && (
        <p>File already uploaded</p>
      )}

      {props.addingAgeVerificationImageError && (
        <p style={{ color: "red" }}>{props.addingAgeVerificationImageErrorMessage}</p>
      )}

      {props.isLoading && !props.uploadSuccess && (
        <div style={{ fontWeight: "bold", marginTop: "15px" }}>File Uploading ...</div>
      )}


      {props.showErrors &&
        <p style={{ textAlign: "left", color: "red" }}>Cannot continue without uploading a file</p>
      }
    </div>
  );
};

export default withTranslation()(AgeVerification);