import React, { Component } from 'react';
import './TransactionBalance.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import MakeAPaymentModal from './MakeAPaymentModal';
import { PrimaryButton, SecondaryButton, TertiaryButton } from '../../../Shared/Components/Presentational/Buttons';

interface TransactionBalanceProps extends WithTranslation {
    balance: undefined | number;
    downloadStatement: () => void;
    downloadPdfStatement: () => void;
    bankTransferClick: () => void;
    isDownloadingStatement: boolean;
    isDownloadingPdfStatement: boolean;
    setPaymentWindowOpen: (isOpen: boolean) => void;
    isPaymentWindowOpen: boolean;
    haulfryBankDetails?: HaulfrynBankDetailsDTO;
    accountNumber: string;
}

class TransactionBalance extends Component<TransactionBalanceProps> {
    render() {
        const {
            t,
            balance,
            downloadStatement,
            downloadPdfStatement,
            isDownloadingStatement,
            isDownloadingPdfStatement,
            bankTransferClick,
            isPaymentWindowOpen,
            setPaymentWindowOpen,
        } = this.props;
        return (
            <>
                <div className="transaction-balance-widget">
                    <div className="title">{t('myAccount.myBalance')}</div>
                    {this.props.balance !== undefined && (
                        <div className="balance">£{(balance as number).toFixed(2)}</div>
                    )}
                    <PrimaryButton
                        text={t('myAccount.makeAPayment')}
                        className="full-width"
                        onClick={() => setPaymentWindowOpen(true)}
                    />
                     <SecondaryButton
                        text={t('myAccount.downloadPdfStatement')}
                        onClick={downloadPdfStatement}
                        isLoading={isDownloadingPdfStatement}
                        className="full-width"
                        disabled={isDownloadingPdfStatement}
                    />
                    <TertiaryButton
                        text={t('myAccount.downloadStatement')}
                        onClick={downloadStatement}
                        isLoading={isDownloadingStatement}
                        className="full-width"
                        disabled={isDownloadingStatement}
                    />
                  
                </div>
                <MakeAPaymentModal
                    isOpen={isPaymentWindowOpen}
                    onBackButtonClick={() => setPaymentWindowOpen(false)}
                    bankTransferClick={bankTransferClick}
                    haulfryBankDetails={this.props.haulfryBankDetails}
                    currentBalance={this.props.balance}
                    accountNumber={this.props.accountNumber}
                />
            </>
        );
    }
}

export default withTranslation()(TransactionBalance);
