import {
    SEARCH_TRANSACTIONS_REQUEST,
    SEARCH_TRANSACTIONS_SUCCESS,
    SEARCH_TRANSACTIONS_FAILURE,
    SET_TRANSACTION_FILTER,
    SET_IS_MY_BANK_DETAILS_MODAL_OPEN,
    GET_MY_BANK_DETAILS_REQUEST,
    GET_MY_BANK_DETAILS_SUCCESS,
    GET_MY_BANK_DETAILS_FAILURE,
    DOWNLOAND_STATEMENT_REQUEST,
    DOWNLOAND_STATEMENT_SUCCESS,
    DOWNLOAND_STATEMENT_FAILURE,
    DOWNLOAND_PDF_STATEMENT_REQUEST,
    DOWNLOAND_PDF_STATEMENT_SUCCESS,
    DOWNLOAND_PDF_STATEMENT_FAILURE,
    SET_IS_HAULFRYN_BANK_DETAILS_MODAL_OPEN,
    GET_HAULFRYN_BANK_DETAILS_REQUEST,
    GET_HAULFRYN_BANK_DETAILS_SUCCESS,
    GET_HAULFRYN_BANK_DETAILS_FAILURE,
    
    MAKE_PAYMENT,
    PAYMENT_CONFIRMING,
    AccountActionTypes,
} from './ActionTypes';
import moment from 'moment';

interface AccountState {
    transactions: PagedResultDTO;
    transactionFilter: TransactionFilterDTO;
    isSearchingTransactions: boolean;
    transactionSearchError: string | undefined;
    isMyBankDetailsModalOpen: boolean;
    myBankDetails?: MyBankDetailsDTO;
    isGettingBankDetails: boolean;
    gettingBankDetailsError: boolean;
    isDownloadingStatement: boolean;
    downloadStatementError: string | undefined;
    isDownloadingPdfStatement: boolean;
    downloadPdfStatementError: string | undefined; 
    isMakeAPaymentModalOpen: boolean;
    haulfrynBankDetails?: HaulfrynBankDetailsDTO;
    isGettingHaulfrynBankDetails: boolean;
    paymentDetails?: PaymentDetails;
}

const initialState: AccountState = {
    transactions: {
        Dtos: [],
        Total: 0,
        PageSize: 10,
    },
    transactionFilter: {
        Page: 1,
        PageSize: 10,
        SortField: 'TransactionDate',
        SortDirection: 'desc',
        FromDate: moment().startOf('month').subtract(6, 'months').format('DD/MM/YYYY'),
        ToDate: moment().format('DD/MM/YYYY'),
        AccountId: 0,
        AccountNumber: '',
        TransactionCategory: '',
    },
    isSearchingTransactions: false,
    transactionSearchError: undefined,
    isMyBankDetailsModalOpen: false,
    myBankDetails: undefined,
    isGettingBankDetails: false,
    gettingBankDetailsError: false,
    isDownloadingStatement: false,
    downloadStatementError: undefined,
    isDownloadingPdfStatement: false,
    downloadPdfStatementError: undefined,
    isMakeAPaymentModalOpen: false,
    haulfrynBankDetails: undefined,
    isGettingHaulfrynBankDetails: false,
    paymentDetails: undefined,
};

export default (state = initialState, action: AccountActionTypes): AccountState => {
    switch (action.type) {
        case SEARCH_TRANSACTIONS_REQUEST:
            return { ...state, isSearchingTransactions: action.payload };
        case SEARCH_TRANSACTIONS_SUCCESS:
            return { ...state, transactions: action.payload, isSearchingTransactions: false };
        case SEARCH_TRANSACTIONS_FAILURE:
            return { ...state, transactionSearchError: action.payload, isSearchingTransactions: false };
        case SET_TRANSACTION_FILTER:
            return { ...state, transactionFilter: action.payload };
        case SET_IS_MY_BANK_DETAILS_MODAL_OPEN:
            return { ...state, isMyBankDetailsModalOpen: action.payload };
        case GET_MY_BANK_DETAILS_REQUEST:
            return { ...state, myBankDetails: undefined, gettingBankDetailsError: false, isGettingBankDetails: true };
        case GET_MY_BANK_DETAILS_SUCCESS:
            return { ...state, myBankDetails: action.payload, isGettingBankDetails: false };
        case GET_MY_BANK_DETAILS_FAILURE:
            return { ...state, myBankDetails: undefined, gettingBankDetailsError: true, isGettingBankDetails: false };
        case DOWNLOAND_STATEMENT_REQUEST:
            return { ...state, isDownloadingStatement: action.payload };
        case DOWNLOAND_STATEMENT_SUCCESS:
            return { ...state, isDownloadingStatement: action.payload };
        case DOWNLOAND_STATEMENT_FAILURE:
            return { ...state, downloadStatementError: action.payload, isDownloadingStatement: false };
        case DOWNLOAND_PDF_STATEMENT_REQUEST:
            return { ...state, isDownloadingPdfStatement: action.payload };
        case DOWNLOAND_PDF_STATEMENT_SUCCESS:
            return { ...state, isDownloadingPdfStatement: action.payload };
        case DOWNLOAND_PDF_STATEMENT_FAILURE:
            return { ...state, downloadPdfStatementError: action.payload, isDownloadingPdfStatement: false };
        case SET_IS_HAULFRYN_BANK_DETAILS_MODAL_OPEN:
            return { ...state, isMakeAPaymentModalOpen: action.payload };
        case GET_HAULFRYN_BANK_DETAILS_REQUEST:
            return { ...state, haulfrynBankDetails: undefined, isGettingHaulfrynBankDetails: true };
        case GET_HAULFRYN_BANK_DETAILS_SUCCESS:
            return { ...state, haulfrynBankDetails: action.payload, isGettingHaulfrynBankDetails: false };
        case GET_HAULFRYN_BANK_DETAILS_FAILURE:
            return { ...state, haulfrynBankDetails: undefined, isGettingHaulfrynBankDetails: false };
        case MAKE_PAYMENT:
            return { ...state, paymentDetails: action.payload };
        case PAYMENT_CONFIRMING:
            return { ...state, paymentDetails: undefined };
        default:
            return state;
    }
};
