import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { Widget } from "../../../Shared/Components/Presentational/Widget";
import * as ActionTypes from '../../Redux/ActionTypes';
import * as Routes from "../../../Constants/Routes";
import { SecondaryButton } from "../../../Shared/Components/Presentational/Buttons";
import { CheckoutStates } from "../../../Constants/CheckoutStates";
import { Desktop } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { GetHTMlContentBlockCall } from "../../../Contentful/Calls/GetHTMLContentBlockCall";
import Spinner from "../../../Shared/Components/Presentational/Spinner";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";


interface PaymentSuccessProps extends WithTranslation {
  isHeaderOpen: boolean;
  isPaymentRequired: boolean;
  checkoutSession: CheckoutSession;
}

const useQuery = () => new URLSearchParams(useLocation().search);

const PaymentSuccess: React.FC<PaymentSuccessProps> = (props) => {
  const { t } = props;
  const query = useQuery();
  const sessionId = query.get("session_id");
  const basketId = query.get("basket_id");
  const [isValidSession, setIsValidSession] = useState<boolean>((basketId === props.checkoutSession.sessionId) && (props.checkoutSession.state === CheckoutStates.Active));

  const [contentLoaded, setContentLoaded] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState<ContentfulContentObject | undefined>(undefined);


  useEffect(() => {
    Promise.all([
      getContentBlock("My Haulfryn - Membership - Payment Outcomes - Success", "paymentSuccess"),
    ]).then(r => {
      setPaymentSuccess(r[0]);
      setContentLoaded(true);
    })
  }, []);

  const getContentBlock = async (name: string, label: string) => {
    const text = await GetHTMlContentBlockCall(name, label);
    return text;
  }

  return (
    <>
      {contentLoaded ? (
        <div className="widget-container">
          <Widget
            position={{
              columnIndex: 1,
              columnSpan: 12,
              rowIndex: 3,
              rowSpan: 9,
            }}
          >
            <div className='row'>
              <div className='title-header'>
                <h1 style={{ margin: "20px", color: "#184f6d" }}>{paymentSuccess!.title}</h1>
              </div>
            </div>
            <div className='row contentful-text-payment' style={{ marginLeft: "20px", marginRight: "20px" }}>
              {/* <p>{props.isPaymentRequired ? t("addMembership.completeMembership.messagePaid") : t("addMembership.completeMembership.message")}</p> */}
              {documentToReactComponents(paymentSuccess!.content)}
            </div>
            <div style={{ marginLeft: "10px", marginTop: "15px" }}>
              <Link to={Routes.MyGuests}>
                <SecondaryButton
                  text={t("addMembership.paymentSuccess.toMyMemberships")}
                />
              </Link>
            </div>

          </Widget>

        </div>
      ) : (
        <div className='memberships-spinner'>
          <Spinner />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isHeaderOpen: state.application.isHeaderOpen,
    isPaymentRequired: state.guests.isPaymentRequired,
    checkoutSession: state.guests.checkoutSession,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSessionId: (sessionId: string | null) => {
      dispatch({ type: ActionTypes.SET_SESSION_ID, payload: sessionId });
    },
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess));