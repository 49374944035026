import React, { Component } from 'react';
import './Containers.scss';
//@ts-ignore
import Zoom from 'react-reveal/Zoom';
import classNames from 'classnames';

interface ButtonContainerProps {
    className?: string;
}

export class ButtonContainer extends Component<ButtonContainerProps> {
    render() {
        return <div className={classNames('button-container', this.props.className)}>{this.props.children}</div>;
    }
}

interface InputContainerProps {
    className?: string;
}

export class InputContainer extends Component<InputContainerProps> {
    render() {
        return <div className={classNames('input-container', this.props.className)}>{this.props.children}</div>;
    }
}

interface IconLinkContainerProps {
    className?: string;
    href: string;
    label: string;
    iconSrc: string;
    onClick?: () => void;
}
export class IconLinkContainer extends Component<IconLinkContainerProps> {
    onClick = (e: any) => {
        if (this.props.onClick) {
            e.preventDefault();
            this.props.onClick();
        }
    };
    render() {
        return (
            <div className={classNames('icon-link-container', this.props.className)}>
                <a href={this.props.href} target="_blank" rel="noopener noreferrer" onClick={this.onClick}>
                    <div className="image-container-wrapper">
                        <Zoom>
                            <div className="image-container">
                                <img src={this.props.iconSrc} alt="" />
                            </div>
                        </Zoom>
                    </div>
                    <div className="label">{this.props.label}</div>
                </a>
            </div>
        );
    }
}
