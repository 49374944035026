import { faSleigh } from "@fortawesome/free-solid-svg-icons";
import UserRoles from "../../Constants/UserRoles";
import ContentfulClient from "../ContentfulClient";
import { TagsFilter } from "../TagsFilter";

export async function  GetParkFAQsCall(parkCode: string,userRoles: UserRoleDTO[]) {
    let filter = {
        content_type: 'park',
        'fields.code': parkCode,
        select:[
            'fields.faQs',
        ]
    }
    let result =  await ContentfulClient.getEntries<ContentfulPark>(filter);

    if (result.errors) {
        throw {message: "GetParkFAQsCall returned error/s", errors: result.errors} 
    }
    
    let indexToBeRemoved: Array<string> = [];
    if(userRoles.length > 0){
        result.items[0].fields.faQs.forEach((faq)=>{
            if(new TagsFilter().removeContent(faq,userRoles)){
                indexToBeRemoved.push(faq.sys.id);
            }
        });
    }

    if (indexToBeRemoved.length !== 0){
        indexToBeRemoved.forEach((val)=>{
            result.items[0].fields.faQs.forEach((faq,index)=>{
                if(faq.sys.id === val){
                    result.items[0].fields.faQs.splice(index,1);        
                }
            });
        })
    }

    return result.items[0].fields.faQs;
}