export const Home: string = '/';
export const Login: string = '/Login';
export const ForgottenPassword: string = '/ForgottenPassword';
export const MyCalendar: string = '/MyCalendar';
export const MyGuests: string = '/MyMemberships';
export const MyGuests2: string = '/MyGuests';
export const MyParkAndHome: string = '/MyParkAndHome';
export const MyAccount: string = '/MyAccount';
export const ConfirmEmail: string = '/ConfirmEmail';
export const ConfirmAccount: string = '/ConfirmAccount';
export const ImportantInformation: string = '/ImportantInformation';
export const ParkNews: string = '/ParkNews';
export const Register: string = '/Register';
export const MyRequestsAndQueries: string = '/MyRequestsAndQueries';
export const AddMembership: string = '/AddMembership';
export const ManageActiveMemberDetails: string = '/ManageActiveMemberDetails';
export const EditMember: string = '/EditMember';
export const ReferMembership: string = '/ReferMembership';
export const AddPassPhoto: string = '/upload-pass-photo';
export const ParQForm: string = '/par-Q-form';
export const PaymentSuccess: string = '/payment-success';
export const PaymentCancelled: string = '/payment-cancelled';
export const DocuSignAuthorised: string = '/auth-docuSign';
export const DocuSignSigned: string = '/docuSign-signed';
export const FinaliseMemberships: string = '/FinaliseMemberships';
export const SelectPayment: string = '/select-payment';
export const MembershipAgreement: string = '/membership-agreement';
export const InvalidSessionPage: string = '/invalid-session';
export const ConfirmMembership: string = '/confirm-member-account';
export const RenewMembership: string = '/renew-membership';