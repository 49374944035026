import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { useLocation } from "react-router";
import SelectMembershipTypeForm from '../Presentational/AddMembershipSubPages/SelectMembershipTypeForm';
import { compose } from "redux";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Entry } from 'contentful';
import { getContentfulMembershipTypes } from '../../Redux/ActionCreators';
import { getOwnerMembershipAvailability } from '../../../User/Redux/ActionCreators';
import RenewalSummary from '../Presentational/AddMembershipSubPages/RenewalSummary';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import { Desktop } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { GetMemberByIdCall } from '../../../Api/Calls/GetMemberIdByCall';
import { Get, Post } from '../../../Api/ApiService';
import { PostMembershipRenewalCall } from '../../../Api/Calls/PostMembershipRenewalCall';
import * as Routes from '../../../Constants/Routes';
import { PostPhotoFacialRecognitionCheckCall } from '../../../Api/Calls/PostPhotoFacialRecognitionCheckCall';
import { GetPhotoByMemberIdCall } from '../../../Api/Calls/GetPhotoByMemberIdCall';
import { GetApiCall } from '../../../Api/Calls/ApiCall';

interface RenewMembershipProps extends RouteComponentProps {
  contentfulMembershipTypes: Entry<ContentfulMembershipType>[];
  termDurations: DropdownListOption[][] | undefined;
  ownerMembershipsAvailability: OwnerMembershipAvailabilityDTO;
  setContentfulMembershipTypes: () => void;
  getOwnerMembershipAvailability: () => void;
  isHeaderOpen: boolean;
}

enum RenewPage {
  MembershipType,
  Summary
}

const useQuery = () => new URLSearchParams(useLocation().search);

const RenewMembership: React.FC<RenewMembershipProps> = (props: RenewMembershipProps) => {
  const query = useQuery();
  const [page, setPage] = useState<RenewPage>(RenewPage.MembershipType);
  const [memberId, setMemberId] = useState<string>()
  const [membershipType, setMembershipType] = useState<string>();
  const [termFormula, setTermFormula] = useState<string>();
  const [membershipStartDate, setMembershipStartDate] = useState<Date>();
  const [membershipTypeName, setMembershipTypeName] = useState<string>();
  const [member, setMember] = useState<MemberDTO>();
  const [membershipTermLabel, setMembershipTermLabel] = useState<string>();
  const [editMemberChecked, setEditMemberChecked] = useState<boolean>(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState<boolean>(false);
  const [redirectToEditMember, setRedirectToEditMember] = useState<boolean>(false);
  const [existingEndDate, setExistingEndDate] = useState<Date>(new Date());

  const [fileUrl, setFileUrl] = useState<string>();
  const [newFile, setFile] = useState<any>();

  useEffect(() => {
    props.getOwnerMembershipAvailability();
    props.setContentfulMembershipTypes();
    
    setMembershipStartDate(new Date());
    
    const id = query.get('member-id');
    if (!!id) {
      setMemberId(id);
      getMemberCall(id);
    }

    const existingEndDate = query.get('existingEndDate');
    if (existingEndDate) {
      let endDate = new Date(existingEndDate);
      endDate.setDate(endDate.getDate() + 1);
      setExistingEndDate(endDate);
      setMembershipStartDate(endDate);
    }

  }, []);

  useEffect(() => {
    getPhotoPassCall();
  }, [memberId]);

  const getPhotoPassCall = () => Get<PhotoPassDTO>(new GetPhotoByMemberIdCall(memberId!))
    .then(result => {
      setFileUrl(result.data.PhotoUrl);
    })
    .catch(() => { });

  const onMembershipTypeSelected = (membershipTypeId: string, termFormula: any) => {
    setMembershipType(membershipTypeId);
    setTermFormula(termFormula.value);
    setMembershipTermLabel(termFormula.label);
    setPage(RenewPage.Summary);
    const constentfulMembershipType = props.contentfulMembershipTypes
      .filter(type => type.sys.id === membershipTypeId);
    setMembershipTypeName(constentfulMembershipType[0].fields.publicName);
  }

  const onStartDateUpdate = (startDate: Date) => {
    setMembershipStartDate(startDate);
  }

  const onSummaryBackButton = () => {
    setPage(RenewPage.MembershipType);
  }

  const onConfirm = async () => {
    if (memberId &&
      termFormula &&
      membershipStartDate &&
      membershipType) {

      const dto: RenewMembershipDTO = {
        memberId: memberId,
        termFormula: termFormula,
        startDate: membershipStartDate,
        contentfulMembershipTypeId: membershipType,
      }

      await Post<PostPhotoFacialRecognitionCheckCall>(new PostPhotoFacialRecognitionCheckCall(memberId))
        .then(r => {
          Post<PostMembershipRenewalCall>(new PostMembershipRenewalCall(dto))
            .then(r => {
              editMemberChecked ? setRedirectToEditMember(true) : setRedirectToDashboard(true)
            })
            .catch(e => {});
        })
        .catch(e => {
          setRedirectToEditMember(true)
        });

      
      
    }
  }

  const getMemberCall = (memberId: string) => Get<MemberDTO>(new GetMemberByIdCall(memberId))
    .then(result => {
      setMember(result.data)
    })
    .catch((e) => {console.log(e)});

  return (
    <>
      {redirectToDashboard && <Redirect to={Routes.MyGuests}/>}
      {
        redirectToEditMember && 
        <Redirect
          to={{
            pathname: Routes.EditMember,
            state: { memberId: memberId }
          }}
        />
      }

      <div className='widget-container'>
        <Desktop>
          {!props.isHeaderOpen && (
            <>
              <h1 className="widget-layout-title">{"Renew Membership"}</h1>
              <div className="widget-layout-message">
                Complete the information to renew the selected membership.
              </div>
            </>
          )}
        </Desktop>

        <Widget
          position={{
            columnIndex: 1,
            columnSpan: 12,
            rowIndex: 3,
            rowSpan: 9,
          }}
        >
          <div className='form-container'>
            {
              page === RenewPage.MembershipType &&
              props.termDurations &&
              props.contentfulMembershipTypes &&
              props.ownerMembershipsAvailability &&
              memberId &&
              <SelectMembershipTypeForm
                contentfulMembershipTypes={props.contentfulMembershipTypes}
                termDurations={props.termDurations}
                ownerMembershipsAvailability={props.ownerMembershipsAvailability}
                onValueSelected={onMembershipTypeSelected}
              />
            }

            {
              page === RenewPage.Summary &&
              memberId &&
              termFormula &&
              membershipStartDate &&
              membershipTypeName &&
              member &&
              membershipTermLabel &&
              <RenewalSummary
                memberId={memberId}
                memberName={member.FirstName + " " + member.LastName}
                memberEmail={member.Email ? member.Email : ''}
                membershipTypeName={membershipTypeName}
                termDuration={termFormula}
                startDate={membershipStartDate}
                onStartDateUpdate={onStartDateUpdate}
                onBackButton={onSummaryBackButton}
                termFormula={membershipTermLabel}
                onConfirm={onConfirm}
                onEditMemberCheckChange={(value: boolean) => {setEditMemberChecked(value)}}
                fileUrl={fileUrl}
                minDatePickerDate={existingEndDate}
              />
            }
          </div>
        </Widget>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    contentfulMembershipTypes: state.guests.contentfulMembershipTypes,
    termDurations: state.guests.termDurations,
    ownerMembershipsAvailability: state.user.ownerMembershipsAvailability,
    isHeaderOpen: state.application.isHeaderOpen,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setContentfulMembershipTypes: () => {
      dispatch(getContentfulMembershipTypes(true));
    },
    getOwnerMembershipAvailability: () => {
      dispatch(getOwnerMembershipAvailability());
    },
  }
}

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps))
  (RenewMembership);
