import { GetEntryCall } from "./Contentful/Calls/GetEntryCall";

export class MembershipForm implements IMembershipForm {
    id?: string;
    contentfulMembershipTypeId?: FormInput;
    myHaulfrynAccess?: FormInput;
    membershipState?: string;
    startDate?: FormInput;
    transferredMemberships?: number;
    marketing?: FormInput;
    firstName?: FormInput;
    lastName?: FormInput;
    dateOfBirth?: FormInput;
    email?: FormInput;
    phonenumber?: FormInput;
    vehicles?: FormInput;
    payment?: FormInput;
    photoURL?: FormInput;
    contentfulAgeBracketPriceId?: string;
    ageVerificationSent?: Date;
    termFormula?: FormInput;

    private hasValueErrorForm: FormInput = { errors: ["hasValue"] };

    public static async fromAddMembership(membershipData: AddMembershipDTO): Promise<MembershipForm> {
        let membership = new this();
        membership.id = membershipData.Id;
        membership.contentfulMembershipTypeId = (membershipData.ContentfulMembershipTypeId ? { value: membershipData.ContentfulMembershipTypeId, errors: [] } : { errors: [] });
        membership.payment = (membershipData.Payment ? { value: membershipData.Payment, errors: [] } : { errors: [] });
        membership.photoURL = (membershipData.PhotoURL ? { value: membershipData.PhotoURL, errors: [] } : { errors: [] });
        membership.myHaulfrynAccess = (membershipData.MyHaulfrynAccess !== null ? { value: membershipData.MyHaulfrynAccess, errors: [] } : { errors: [] });
        membership.membershipState = membershipData.MembershipState;
        membership.startDate = (membershipData.StartDate ? { value: new Date(membershipData.StartDate), errors: [] } : { errors: ["hasValue"] });
        membership.transferredMemberships = 0;
        membership.marketing = (membershipData.Marketing !== null ? { value: membershipData.Marketing, errors: [] } : { errors: [] });
        membership.firstName = (membershipData.FirstName ? { value: membershipData.FirstName, errors: [] } : { errors: ["hasValue"] });
        membership.lastName = (membershipData.LastName ? { value: membershipData.LastName, errors: [] } : { errors: ["hasValue"] });
        membership.dateOfBirth = (membershipData.DateOfBirth ? { value: new Date(membershipData.DateOfBirth), errors: [] } : { errors: ["hasValue"] });
        membership.email = (membershipData.Email ? { value: membershipData.Email, errors: [] } : { errors: [] });
        membership.phonenumber = (membershipData.PhoneNumber ? { value: membershipData.PhoneNumber, errors: [] } : { errors: [] });
        if (membershipData.Vehicles) {
            membership.vehicles = { value: [], errors: []};
            for (let vehicle of membershipData.Vehicles) {
                membership.vehicles!.value = membership.vehicles!.value.concat([{ id: vehicle.Id, registration: vehicle.Registration, description: vehicle.Description }])
            }
        }
        if (membershipData.ContentfulAgeBracketPriceId) {
            membership.contentfulAgeBracketPriceId = membershipData.ContentfulAgeBracketPriceId;
            let duration = await GetEntryCall(membershipData.ContentfulAgeBracketPriceId);
            membership.termFormula = { value: { label: duration.fields.termDescription, value: duration.fields.termFormula } as DropdownListOption, errors: []};
        }
        membership.ageVerificationSent = membershipData.AgeVerificationSent && new Date(membershipData.AgeVerificationSent);
        return membership;
    }

    constructor() {
        this.contentfulMembershipTypeId = { errors: [] };
        this.payment = { errors: [] };
        this.photoURL = { errors: [] };
        this.myHaulfrynAccess = { errors: [] };
        this.startDate = { value: new Date(), errors: []};
        this.marketing = { errors: [] };
        this.firstName = this.hasValueErrorForm;
        this.lastName = this.hasValueErrorForm;
        this.dateOfBirth = this.hasValueErrorForm;
        this.email = { errors: [] };
        this.phonenumber = { errors: [] };
        this.vehicles = { errors: [] };
        this.termFormula = { errors: [] };
    }
  }