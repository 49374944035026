import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import MembershipStates from '../../../../Constants/MembershipStates';
import { Entry } from 'contentful';
import ExpiredMembershipCard from './ExpiredMembershipCard';

interface ExpiredMembershipContainerProps extends WithTranslation {
  memberships: MembershipDTO[];
  contentfulMembershipTypes: Entry<ContentfulMembershipType>[];
  deleteFunction?: (isOpen: boolean, membershipId: string) => void;
}

const ExpiredMembershipContainer: React.FC<ExpiredMembershipContainerProps> = (props: ExpiredMembershipContainerProps) => {
  const { t } = props;

  return (
    <div style={{ margin: "25px", marginTop: "15px", marginBottom: "15px" }}>
      <h2 className='container-title'>{t("myMemberships." + MembershipStates.Expired + ".title")}</h2>

      <div className='gridDisplay'>
        {props.memberships.map(m => 
          <ExpiredMembershipCard
            membership={m}
            contentfulMembershipTypes={props.contentfulMembershipTypes}
            deleteFunction={props.deleteFunction}
          />
        )}
      </div>
    </div>
  )
}

export default withTranslation()(ExpiredMembershipContainer);
