import React, { Component } from 'react';
import './Layout.scss';
import { Desktop, Mobile } from './ResponsiveViews';
import history from '../../../History';
import HeaderContainer from '../Container/HeaderContainer';
import UserRoles from '../../../Constants/UserRoles';
import MemberHeader from '../Container/MemberHeader';

interface LayoutProps {
  lodges: LodgeDTO[];
  lodgeOptions: [DropdownListOption];
  selectedLodge: DropdownListOption;
  setSelectedLodge: (lodge: SelectedLodgeDTO) => void;
  setSelectedPark: (parkCode: string, parkName: string, parkFeatures: string[]) => void;
  logoutClick: () => void;
  contractEndDate: string;
  myContactDetails: MyContactDetailsDTO;
  openMyContactDetailsModal: () => void;
  hasSublettings: boolean;
  ownerId: number;
  showContactDetailsModal: boolean;
  userRoles: UserRoleDTO[];
  useMemberships: boolean;
}

export default class Layout extends Component<LayoutProps> {
  render() {
    if (!UserRoles.IsOwner(this.props.userRoles)) {
      return (
        <>
          <Desktop>
            <MemberHeader
              logoutClick={this.props.logoutClick}
            />
            <div className="layout-container">
              <div className="layout-container-children">
                {this.props && this.props.children ? this.props.children : <></>}
              </div>
            </div>
          </Desktop>
          <Mobile>
            <MemberHeader
              logoutClick={this.props.logoutClick}
              backgroundContainerClassName="mobile-background"
            />
            <div className="layout-container">
              <div className="layout-container-children">
                {this.props && this.props.children ? this.props.children : <></>}
              </div>
            </div>
          </Mobile>
        </>
      )
    } else {
      return (
        <>
          <Desktop>
            <HeaderContainer
              contractEndDate={this.props.contractEndDate}
              selectedOption={this.props.selectedLodge}
              options={this.props.lodgeOptions}
              onSelectionChanged={this.onSelectedLodgeChanged.bind(this)}
              logoutClick={this.props.logoutClick}
              showMyDetailsLink={this.props.showContactDetailsModal}
              openMyContactDetailsModal={this.props.openMyContactDetailsModal}
              hasSublettings={this.props.hasSublettings}
              useMemberships={this.props.useMemberships}
            />
            <div className="layout-container">
              <div className="layout-container-children">
                {this.props && this.props.children ? this.props.children : <></>}
              </div>
            </div>
          </Desktop>
          <Mobile>
            <HeaderContainer
              contractEndDate={this.props.contractEndDate}
              selectedOption={this.props.selectedLodge}
              options={this.props.lodgeOptions}
              onSelectionChanged={this.onSelectedLodgeChanged.bind(this)}
              logoutClick={this.props.logoutClick}
              backgroundContainerClassName="mobile-background"
              showMyDetailsLink={this.props.showContactDetailsModal}
              openMyContactDetailsModal={this.props.openMyContactDetailsModal}
              hasSublettings={this.props.hasSublettings}
              useMemberships={this.props.useMemberships}
            />
            <div className="layout-container">
              <div className="layout-container-children">
                {this.props && this.props.children ? this.props.children : <></>}
              </div>
            </div>
          </Mobile>
        </>
      );
    }
  }

  private onSelectedLodgeChanged(newValue: DropdownListOption) {
    if (newValue) {
      const selectedLodge = this.props.lodges.find((l) => l.LodgeId.toString() === newValue.value);

      if (selectedLodge) {
        this.props.setSelectedPark(selectedLodge.ParkCode, selectedLodge.ParkName, selectedLodge.ParkFeatures);
        this.props.setSelectedLodge({
          LodgeId: selectedLodge.LodgeId,
          LodgeName: selectedLodge.Name,
          ContractEndDate: selectedLodge.ContractEndDate,
          HasSublettings: selectedLodge.HasSublettings,
          AccountId: selectedLodge.AccountId,
          AccountNo: selectedLodge.AccountNo,
          AccountBalance: selectedLodge.RecentTransactions[0]
            ? selectedLodge.RecentTransactions[0].Balance
            : undefined,
        });
      }
      history.push('/');
    }
  }
}
