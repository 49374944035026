import React, { useEffect, useImperativeHandle, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GetMemberPersonalDetailsCall } from '../../../../Api/Calls/Member/GetMemberPersonalDetailsCall';
import { Get, Post } from '../../../../Api/ApiService';
import Spinner from '../../../../Shared/Components/Presentational/Spinner';
import PersonalDetailsForm from '../ManageActiveMemberSubPages/PersonalDetailsForm';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import '../../Container/AddMembershipStyles.scss';
import { ICallSave } from './ICallSave';
import { hasValue } from '../../../../Shared/Utilities/Validation';
import { UpdateMemberPersonalDetailsCall } from '../../../../Api/Calls/Member/UpdateMemberPersonalDetailsCall';
import { Desktop, Mobile } from '../../../../Shared/Components/Presentational/ResponsiveViews';

interface UpdateMemberPersonalDetailsProps {
  memberId: string;
  onSaveComplete: () => void;
  onSaveError: () => void;
  onSaveCancelled: () => void;
}

const UpdateMemberPersonalDetails = React.forwardRef<ICallSave, UpdateMemberPersonalDetailsProps>((props, ref) => {
  const [personalDetails, setPersonalDetails] = useState<MemberPersonalDetailsDTO>();
  const [isFormErrors, setIsFormErrors] = useState<boolean>(false);
  const [errorGettingDetails, setErrorGettingDetails] = useState<boolean>(false);
  const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
  const [detailsChanged, setDetailsChanged] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>();

  useEffect(() => {
    Get<MemberPersonalDetailsDTO>(new GetMemberPersonalDetailsCall(props.memberId))
      .then(r => {
        setPersonalDetails(r.data);
      })
      .catch(e => { setErrorGettingDetails(true) });
  }, [])

  const updatePersonalDetails = () => {
    if (personalDetails) {
      Post(new UpdateMemberPersonalDetailsCall(personalDetails, props.memberId))
        .then(r => props.onSaveComplete())
        .catch(e => {
          props.onSaveError();
          setShowFormErrors(true);
          setValidationError(e.response.data);
        });
    }
  }

  const onFormInput = (value: MemberPersonalDetailsDTO) => {
    setPersonalDetails(value);
    setDetailsChanged(true);
  }

  useImperativeHandle(ref, () => ({
    save() {
      console.log('save called in update member personal details');
      if (detailsChanged) {
        if (!isFormErrors) {
          updatePersonalDetails();
        } else {
          setShowFormErrors(true);
          props.onSaveCancelled();
        }
      } else {
        props.onSaveComplete();
      }
    }
  }));

  return (
    <>
      {!personalDetails && <Spinner />}

      {personalDetails &&
        <div>
          <Desktop>
            <div className='row' style={{ marginBottom: "25px" }}>
              <div className='float-left'>
                {/* <h2 id="pdTitle" style={{ margin: "0px" }}>{t("addMembership.personalDetails.title")}</h2> */}
                <h2 id="pdTitle" style={{ margin: "0px" }}>Personal Details</h2>
              </div>
            </div>
          </Desktop>

          <Mobile>
            <div className='row' style={{ marginBottom: "25px", marginTop: "50px" }}>
              <div className='title-header'>
                {/* <h2 id="pdTitle" style={{ margin: "0px" }}>{t("addMembership.personalDetails.title")}</h2> */}
                <h2 id="pdTitle" style={{ margin: "0px" }}>Personal Details</h2>
              </div>
            </div>
          </Mobile>

          <PersonalDetailsForm
            existingDetails={personalDetails}
            showErrors={showFormErrors}
            returnValue={onFormInput}
            returnErrorState={(value) => setIsFormErrors(value)}
            validationError={validationError}
          />
        </div>
      }
    </>
  )
});

export default UpdateMemberPersonalDetails;