import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as Routes from '../../../Constants/Routes';
import { formatDateString } from '../../../Shared/Utilities/DateUtils';
import { Entry } from 'contentful';
import './NewsCard.scss';

interface NewsCardProps {
    news: Entry<NewsArticleHeader>;
    setSelectedNewsItem: (selectedNewsItemId: string) => void;
}

const NewsCard: React.FC<NewsCardProps> = ({ news, setSelectedNewsItem }) => {
    const [navigateTo, setNavigateTo] = useState('');

    const navigateToNewsPage = (): void => {
        if (news && news.sys.id) {
            setSelectedNewsItem(news.sys.id);
            setNavigateTo(Routes.ParkNews);
        }
    };

    return (
        <>
            {navigateTo && <Redirect to={navigateTo} />}
            <div className="news-card" onClick={navigateToNewsPage}>
                <div className="news-image">
                    <img src={news.fields.image ? news.fields.image.fields.file.url : '/images/default-restaurant-image.png'} alt="news" />
                </div>
                <div className="description-container">
                    <p className="title">{news.fields.title}</p>
                    <p className="publish-date">{formatDateString(news.fields.published)}</p>
                </div>
            </div>
        </>
    );
};

export default NewsCard;
