import { PostApiCall } from "./ApiCall";

export class PostEmailLoginApiCall extends PostApiCall {

    constructor(loginDetails: LoginDTO) {
        let url: string = "login";
        super(url);

        this.body = loginDetails;
        this.logoutOn401 = false;
    }
}

export class PostGoogleLoginApiCall extends PostApiCall {

    constructor(loginDetails: LoginDTO) {
        let url: string = "googlelogin";
        super(url);

        this.body = loginDetails;
        this.logoutOn401 = false;
    }
}

export class PostFacebookLoginApiCall extends PostApiCall {

    constructor(loginDetails: LoginDTO) {
        let url: string = "facebooklogin";
        super(url);

        this.body = loginDetails;
        this.logoutOn401 = false;
    }
}

