import * as ActionTypes from './ActionTypes';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../RootReducer';
import { Action } from 'redux';
import { Get, Post, Delete, Put } from '../../Api/ApiService';
import { PostSetParQInformationCall } from '../../Api/Calls/PostSetParQInformation';

export const setParQInforation = 
    (parQForm: ParQFormDTO): ThunkAction<void, AppState, null, Action> =>
        async (dispatch, getState) => {
            Post<any>(new PostSetParQInformationCall(parQForm))
                .then((response) => {
                    dispatch({ type: ActionTypes.SET_PAR_Q_INFORMATION_SUCCESS});
                })
                .catch((error) => {
                    dispatch({ type: ActionTypes.SET_PAR_Q_INFORMATION_FAILURE});
                });
        };

export const updateParQForm = 
    (fieldName: any, value: any, errors: string[]): ThunkAction<void, AppState, null, Action> =>
        async (dispatch, getState) => {
            const form = { ...getState().parQForm.parQFormInputs as FormInput[] };
            form[fieldName] = { value: value, errors: errors };
            dispatch({ type: ActionTypes.UPDATE_PAR_Q_FORM, payload: form as IParQFormInputs });
        }