import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageCropper from '../../../Shared/Components/Presentational/ImageCropper';
import AddPassPhotoFooter from './AddPassPhotoFooter';
import AddPassPhotoHeader from './AddPassPhotoHeader';
import './AddPassPhoto.scss';
import { vehicleValidation } from '../../../Shared/Utilities/Validation';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface AddPassPhotoModalProps {
  onBackButtonClick?: () => void;
  onPhotoUpload: (photoFile: File) => void;
  value?: string;
  headerText?: string;
  submitErrorText?: string;
  submittedOption?: ParkPassTypeDTO;
  isAnalyzingPassPhoto?: boolean;
  analyzingPassPhotoError?: any;
  isAnalyzingPassPhotoSuccessful?: boolean;
  PassPhotoSizeLimitInKb: number;
  analyzePassPhotoRequest?: (passPhotoFile: File) => void;
  instructions: ContentfulContentObject;
  fileRestrictions: ContentfulContentObject;
  exception: ContentfulContentObject;
}

const AddPassPhoto: React.FC<AddPassPhotoModalProps> = props => {
  const [srcPhotoFile, setPhotoFile] = useState<File | undefined>();
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [isNewPhoto, setIsNewPhoto] = useState<boolean>(false);
  const [hasSizeError, setHasSizeError] = useState<boolean>(false);

  const { t } = useTranslation();



  const handleOnBackButtonClick = () => {
    setPhotoFile(undefined);
    props.onBackButtonClick && props.onBackButtonClick();
  }

  const handleSubmit = () => {
    setHasSubmitted(true);
    srcPhotoFile && props.analyzePassPhotoRequest && props.analyzePassPhotoRequest(srcPhotoFile);
  }



  const onAnalyzingSuccess = () => {
    srcPhotoFile && props.onPhotoUpload(srcPhotoFile);
    props.onBackButtonClick && props.onBackButtonClick();
  }

  if (hasSubmitted && (props.isAnalyzingPassPhotoSuccessful || props.analyzePassPhotoRequest == undefined)) {
    setHasSubmitted(false);
    setIsNewPhoto(false);
    onAnalyzingSuccess();
  }

  const onSizeError = () => setHasSizeError(true);

  const handlePhotoCrop = (photoFile: File | undefined) => {
    setPhotoFile(photoFile);
    setIsNewPhoto(photoFile != undefined);
    photoFile && setHasSizeError(false);
  }

  return (
    <div className="add-pass-photo-modal">
      <div className='desktopVersion'>
        <AddPassPhotoHeader
          headerText={props.submittedOption ? t('myGuests.passes.add.header', {
            passName: t(props.submittedOption.NameTranslationKey),
            interpolation: { escapeValue: false }
          }) : props.headerText ? props.headerText : ''}
          instructions={props.instructions}
        />
      </div>
      <div className='mobileVersion'>
        <div style={{ marginTop: "50px" }}>
          <AddPassPhotoHeader
            headerText={props.submittedOption ? t('myGuests.passes.add.header', {
              passName: t(props.submittedOption.NameTranslationKey),
              interpolation: { escapeValue: false }
            }) : props.headerText ? props.headerText : ''}
            instructions={props.instructions}
          />
        </div>
      </div>
      <div style={{ display: "block", justifyContent: "center" }}>
        {hasSizeError || props.submitErrorText && (
          <div className='error-message show contentful-text'>
            {documentToReactComponents(props.exception.content)}
          </div>
        )}
        {/* {hasSizeError && <p className="error-message show">The file's size has been exceeded</p>} */}
        {/* {props.submitErrorText && <p className="error-message show">{props.submitErrorText}</p>} */}
        {hasSubmitted && props.analyzingPassPhotoError && <p className="error-message show">{props.analyzingPassPhotoError.Message}</p>}
        <ImageCropper
          photoUrl={props.value ? props.value : ''}
          PassPhotoSizeLimitInKb={props.PassPhotoSizeLimitInKb}
          onSizeError={onSizeError}
          onPhotoCrop={handlePhotoCrop} />
        <AddPassPhotoFooter
          submitDisabled={srcPhotoFile == undefined || !isNewPhoto}
          primaryButtonText={t("addMembership.photoUpload.uploadButton")}
          onSubmit={handleSubmit}
          isLoading={props.isAnalyzingPassPhoto}
          onBackButtonClick={props.onBackButtonClick && handleOnBackButtonClick}
          fileRestrictions={props.fileRestrictions} />
      </div>
    </div>
  );
}

export default AddPassPhoto;