import React, { Component, RefObject } from "react";
import { connect } from "react-redux";
import { addMembership, updateMembershipFormBasket, updateMembershipBasketField, updateMembershipForm, addAgeVerificationFile, createMembershipBasket, getContentfulMembershipTypes } from "../../Redux/ActionCreators";
import { CHANGE_MEMBERSHIP_FORM_PAGE } from "../../Redux/ActionTypes";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "redux";
import * as ActionTypes from '../../Redux/ActionTypes';
import { Widget } from "../../../Shared/Components/Presentational/Widget";
import PersonalDetails from "../Presentational/AddMembershipSubPages/PersonalDetails";
import Breadcrumb from "../../../Shared/Components/Presentational/Breadcrumb";
import { PrimaryButton, SecondaryButton } from "../../../Shared/Components/Presentational/Buttons";
import { Redirect } from "react-router-dom";
import * as Routes from "../../../Constants/Routes";
import "./AddMembershipStyles.scss";
import SelectMembershipType from "../Presentational/AddMembershipSubPages/SelectMembershipType";
import AgeVerification from "../Presentational/AddMembershipSubPages/AgeVerification";
import Vehicles from "../Presentational/AddMembershipSubPages/Vehicles";
import SummaryPage from "../Presentational/AddMembershipSubPages/Summary";
import { getOwnerMembershipAvailability } from "../../../User/Redux/ActionCreators";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { ClickableIcon } from "../../../Shared/Components/Presentational/Icons";
import { SET_EXIT_PROMPT_OPEN, SET_MEMBERSHIPS_CONTACT_PROMPT_NEXT_STEP, SET_MEMBERSHIPS_FLOW_CONTACT_PROMPT_OPEN } from "../../../Modal/Redux/ActionTypes";
import MembershipFlowContactPrompt from "../../../Modal/Components/Container/MembershipFlowContactPrompt";
import { GetMembershipPaymentSummaryCall } from "../../../Api/Calls/GetMembershipPaymentSummaryCall";
import { Get } from "../../../Api/ApiService";
import FinalisePage from "../Presentational/AddMembershipSubPages/FinalisePage";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { Entry } from "contentful";
import { GetEntryCall } from "../../../Contentful/Calls/GetEntryCall";
import ExitPrompt from "../../../Modal/Components/Container/ExitPrompt";
import { GetHTMlContentBlockCall } from "../../../Contentful/Calls/GetHTMLContentBlockCall";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Spinner from "../../../Shared/Components/Presentational/Spinner";
import UserRoles from "../../../Constants/UserRoles";
import { ICallSave } from "../Presentational/AddMembershipSubPages/ICallSave";
import PhotoUpload from "../Presentational/AddMembershipSubPages/PhotoUpload";

interface AddMembershipProps extends WithTranslation {
  isHeaderOpen: boolean;
  isExitPromptOpen: boolean;
  isValidated: boolean;
  promptNextStep: boolean;
  membershipFormPage: number;
  newMembership: NewMembershipDTO;
  membershipForm: FormInput[];
  membershipBasket: IMembershipForm[];
  addMembershipRequest: (membership: NewMembershipDTO) => Promise<boolean>;
  setPromptNextStep: (nextStep: boolean) => void;
  saveAndExit: () => void;
  updateMembershipFormPage: (pageNum: number) => void;
  setExitModalOpen: (isOpen: boolean) => void;
  setMembershipContactWarningModalOpen: (isOpen: boolean, type: string) => void;
  setValidateAndUploadPhotoFlag: (validate: boolean) => void;
  startNewMembership: () => void;
  updateMembershipForm: (fieldName: any, value: any, errors: string[]) => void;
  updateMembershipFormBasket: (membershipForm: IMembershipForm | undefined, membershipBasket: IMembershipForm[] | undefined) => void;
  getOwnerMembershipAvailability: () => void;
  ownerMembershipsAvailability: OwnerMembershipAvailabilityDTO;
  uploadFile: (file: any, membershipId: string, firstName: string, lastName: string, dateOfBirth: Date) => void;
  basketId: string;
  isMembershipContactWarningPromptOpen: boolean;
  paymentDetails: PaymentBreakdownDTO;
  contentfulMembershipTypes: Entry<ContentfulMembershipType>[];
  isAddingAgeVerificationImage: boolean;
  addingAgeVerificationImageSuccess: boolean;
  addingAgeVerificationImageError: boolean;
  addingAgeVerificationImageErrorMessage: string;
  setContentfulMembershipTypes: (withTerms?: boolean) => void;
  addingMembershipErrorMessage: string | undefined;
  hasOwnMembership: boolean;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPhoneNumber: string;
  userRoles: UserRoleDTO[];
  membershipContainers: MembershipContainer[];
  termDurations: DropdownListOption[][] | undefined;
}

interface AddMembershipState {
  showErrors: boolean;
  flowFinished: boolean;
  isLoading: boolean;
  descriptionText: ContentfulContentObject | undefined;
  memberDetailsDescriptionText: ContentfulContentObject | undefined,
  ownerResponsibilities: ContentfulContentObject | undefined;
  photoUploadInstructions: ContentfulContentObject | undefined;
  photoUploadFileRestrictions: ContentfulContentObject | undefined;
  photoUploadException: ContentfulContentObject | undefined;
  ageVerificationDescription: ContentfulContentObject | undefined;
  marketingPreferencesText: ContentfulContentObject | undefined;
  vehicleDescription: ContentfulContentObject | undefined;
  proceedDescription: ContentfulContentObject | undefined;
  contentLoaded: boolean;
  disableSaveButton: boolean;
}

type Section = {
  key: number;
  title: string;
  name: string;
}

class AddMembership extends Component<AddMembershipProps, AddMembershipState> {
  private ageVerificationPage: number;
  private vehiclePage: number;
  private summaryPage: number;
  private finalisePage: number;
  private photoUploadRef: RefObject<ICallSave>;
  private membershipExist: boolean;

  constructor(props: any) {
    super(props);

    this.photoUploadRef = React.createRef<ICallSave>();

    this.state = {
      showErrors: false,
      flowFinished: false,
      isLoading: false,
      descriptionText: undefined,
      memberDetailsDescriptionText: undefined,
      ownerResponsibilities: undefined,
      photoUploadInstructions: undefined,
      photoUploadFileRestrictions: undefined,
      photoUploadException: undefined,
      ageVerificationDescription: undefined,
      marketingPreferencesText: undefined,
      vehicleDescription: undefined,
      proceedDescription: undefined,
      contentLoaded: false,
      disableSaveButton: false,
    }

    this.ageVerificationPage = -1;
    this.vehiclePage = -1;
    this.summaryPage = -1;
    this.finalisePage = -1;
    this.membershipExist = false;
    this.changeSectionsOnBasisOfBirthDate();

    Promise.all([
      this.getContentBlock("Add Member Step 1: Member Details", "memberDetailsDescriptionText"),
      this.getContentBlock("My Haulfryn - Add New Membership", "descriptionText"),
      this.getContentBlock("My Haulfryn  - Membership Type - Owners Responsibiliies", "ownerResponsibilities"),
      this.getContentBlock("My Haulfryn - Photo Upload - Set- by- Step Guide", "photoUploadInstructions"),
      this.getContentBlock("My Haulfryn - Photo Upload - File Restrictions", "photoUploadFileRestrictions"),
      this.getContentBlock("My Haulfryn - Photo Upload - Exception", "photoUploadException"),
      this.getContentBlock("My Haulfryn  - Age Verification - Description", "ageVerificationDescription"),
      this.getContentBlock("My Haulfryn - Memberships - Preferences", "marketingPreferencesText"),
      this.getContentBlock("My Haulfryn - Memberships - Vehicles", "vehicleDescription"),
      this.getContentBlock("My Haulfryn - Membership - Review", "proceedDescription"),
    ]).then(r => {
      r.forEach(c => this.setState({ ...this.state, [c.name]: c }));
      this.setState({ contentLoaded: true });
    })

    if (this.props.addingAgeVerificationImageSuccess) {
      this.setState({ disableSaveButton: false });
    }

    if (!UserRoles.IsOwner(this.props.userRoles) && this.props.hasOwnMembership === false) {
      const form = this.props.membershipForm as IMembershipForm;

      let membershipExist: boolean = false;
      if (this.props.membershipContainers) {
        this.props.membershipContainers.forEach((membership: MembershipContainer) => {
          if (membership.membership.email === this.props.userEmail) {
            this.membershipExist = true;
          }
        })
      }

      if (!this.membershipExist) {
        if (this.props.userEmail !== null && (!form.email || form.email && !form.email.value)) {
          this.props.updateMembershipForm(this.props.userEmail, "email", []);
        }
        if (this.props.userFirstName !== null && (!form.firstName || form.firstName && !form.firstName.value)) {
          this.props.updateMembershipForm(this.props.userFirstName, "firstName", []);
        }
        if (this.props.userLastName !== null && (!form.lastName || form.lastName && !form.lastName.value)) {
          this.props.updateMembershipForm(this.props.userLastName, "lastName", []);
        }
        if (this.props.userPhoneNumber !== null && (!form.phonenumber || form.phonenumber && !form.phonenumber.value)) {
          this.props.updateMembershipForm(this.props.userPhoneNumber, "phonenumber", []);
        }
      }
    }
  }

  async moveToNextStep(membershipForm: any) {
    const form = await this.createMembershipDTO(membershipForm);
    let apiSucceeded = await this.props.addMembershipRequest(form);

    if (!apiSucceeded) {
      if (this.props.addingMembershipErrorMessage && this.props.addingMembershipErrorMessage === "The email address provided is already in use, please enter another") {
        this.updateValue(form.email!, "email", ["duplicateEmail"]);
      } else if (this.props.addingMembershipErrorMessage && this.props.addingMembershipErrorMessage === "The mobile number provided is already in use, please enter another") {
        this.updateValue(form.phonenumber!, "phonenumber", ["duplicateMobile"]);
      }
      this.setState({ showErrors: true, isLoading: false });
      return;
    }

    if (this.props.membershipFormPage + 1 < this.sections.length) {
      this.props.updateMembershipFormPage(this.props.membershipFormPage + 1);
    }
  }

  calculateAge = (dateOfBirth: Date): number => {
    let today = new Date();
    let birthDate = new Date(dateOfBirth);
    let ageNow = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      ageNow--;
    }
    return ageNow;
  }

  async changeSectionsOnBasisOfBirthDate() {
    let localSection: Section[] = [];
    let ageVerificationOperation: string = ""; // add, remove, index change.
    let vehiclesOperation: string = ""; // add, remove, index change.

    if ((this.props.membershipForm as IMembershipForm).dateOfBirth!.value !== undefined) {
      let memberAgeInYears: number = this.calculateAge(new Date((this.props.membershipForm as IMembershipForm).dateOfBirth!.value));
      if (memberAgeInYears >= 18) {
        // See index no 3 for ageVerfication, index no 4 for preferences and index no 5 for vehicles
        // if there is no object for these index add it in sections.
        ageVerificationOperation = "remove";
        vehiclesOperation = "add";
      } else {

        if (memberAgeInYears === 17) {
          // age is 17
          // check for the index 
          vehiclesOperation = "add";
          // show them vehicles index no 5
        } else {
          // age is < 17
          // do not show them vehicles
          vehiclesOperation = "remove";
        }

        //Plan - Check chosen membership age bracket price, if cost > 0 add, else remove
        if ((this.props.membershipForm as IMembershipForm).contentfulAgeBracketPriceId) {
          let price: Entry<ContentfulMembershipTypeAgeBracketPrice> = await GetEntryCall((this.props.membershipForm as IMembershipForm).contentfulAgeBracketPriceId!);
          if (price.fields.price > 0) {
            ageVerificationOperation = "remove"; //Should be add here not remove normally but page is being hidden temporarily until a better solution for age verification is found
          } else {
            ageVerificationOperation = "remove";
          }
        }

      }

      if ((this.props.membershipForm as IMembershipForm).contentfulMembershipTypeId) {
        let membershipType = this.props.contentfulMembershipTypes.find(m => m.sys.id === (this.props.membershipForm as IMembershipForm).contentfulMembershipTypeId!.value);
        if (membershipType && membershipType.fields.debitsQuota) {
          ageVerificationOperation = "remove";
        }
      }

      // iterate over sections again and make a new section array again with proper data and reassigned it to sections.
      localSection[0] = this.sections[0];
      localSection[1] = this.sections[1];
      localSection[2] = this.sections[2];
      if (ageVerificationOperation === "add") {
        this.ageVerificationPage = localSection.length;
        localSection[localSection.length] = {
          key: localSection.length,
          title: "addMembership.ageVerification.title",
          name: 'ageVerification'
        }

      } else {
        this.ageVerificationPage = -1;
      }

      if (vehiclesOperation === "add") {
        this.vehiclePage = localSection.length;
        localSection[localSection.length] = {
          key: localSection.length,
          title: "addMembership.vehicles.title",
          name: 'vehicles'
        }
      } else {
        this.vehiclePage = -1;
      }

      this.summaryPage = localSection.length;
      localSection[localSection.length] = {
        key: localSection.length,
        title: "addMembership.summary.title",
        name: 'summary'
      }
      this.sections = localSection;
    }
  }

  getContentBlock = async (name: string, label: string) => {
    const text = await GetHTMlContentBlockCall(name, label);
    return text;
  }

  componentDidMount() {
    this.props.setContentfulMembershipTypes(true);
    this.props.getOwnerMembershipAvailability();
  }

  componentDidUpdate(prevProps: Readonly<AddMembershipProps>, prevState: Readonly<AddMembershipState>, snapshot?: any): void {
    if ((prevProps && prevProps.basketId) !== (this.props.basketId && this.props.basketId)) {
      this.props.basketId && this.getPaymentBreakdownsDetails(this.props.basketId);
    }

    // Will disable the Save and Continue button 
    // if the next page is photo upload
    // and enable save and continue if other page
    if (prevProps.membershipFormPage !== this.props.membershipFormPage) {
      if (this.sections[this.props.membershipFormPage].name == 'photoUpload') {
        this.setState({ disableSaveButton: true });
      } else {
        this.setState({ disableSaveButton: false });
      }
    }

    if (!prevProps.addingAgeVerificationImageSuccess && this.props.addingAgeVerificationImageSuccess) {
      this.setState({ disableSaveButton: false });
      this.setState({ isLoading: false });
    }

    if (!prevProps.addingAgeVerificationImageError && this.props.addingAgeVerificationImageError) {
      this.setState({ disableSaveButton: false, isLoading: false });
    }
  }

  updateValue(fieldName: any, value: any, errors: string[]) {
    this.props.updateMembershipForm(fieldName, value, errors);
  }

  getPaymentBreakdownsDetails(membershipBasketId: string) {
    Get<any>(new GetMembershipPaymentSummaryCall(membershipBasketId))
      .then((response) => {
        dispatch({ type: ActionTypes.SET_PAYMENT_SUMMARY_DTO, payload: response.data });
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  onBack() {
    this.setState({ showErrors: false });
    if (this.props.membershipFormPage - 1 >= 0) {
      switch (this.props.membershipFormPage) {
        case 1:
          if (!(this.props.membershipForm as IMembershipForm).contentfulMembershipTypeId!.value) {
            this.updateValue('', "contentfulMembershipTypeId", []);
          }
          break;
        case 4:
          if (!(this.props.membershipForm as IMembershipForm).myHaulfrynAccess!.value) {
            this.updateValue('', "myHaulfrynAccess", []);
          }
          if (!(this.props.membershipForm as IMembershipForm).marketing!.value) {
            this.updateValue('', "marketing", []);
          }
          break;
        default:
          break;
      }
      this.props.updateMembershipFormPage(this.props.membershipFormPage - 1);
    }
  }

  onTypeNextStep() {
    if (this.props.membershipFormPage + 1 < this.sections.length) {
      this.props.updateMembershipFormPage(this.props.membershipFormPage + 1);
    }
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  onSaveAndContinue = async () => {
    if (this.sections[this.props.membershipFormPage].name == 'photoUpload') {
      if (this.photoUploadRef.current !== null) {
        this.photoUploadRef.current.save();
      }
      return;
    }

    this.setState({ isLoading: true });
    this.changeSectionsOnBasisOfBirthDate();
    let invalid = false;
    let email: string = "";
    let phonenumber: string = "";
    let scrollPosition: number = 0;

    if (this.props.membershipFormPage === this.ageVerificationPage) {
      if (!this.props.addingAgeVerificationImageSuccess && !(this.props.membershipForm as IMembershipForm).ageVerificationSent) {
        invalid = true;
      }
    }

    for (let formObject in this.props.membershipForm) {
      if (formObject !== "hasValueErrorForm" &&
        formObject !== "id" &&
        formObject !== "membershipState" && formObject !== "contentfulMembershipTypeId" && formObject !== "contentfulAgeBracketPriceId" && formObject != "ageVerificationSent" &&
        (this.props.membershipForm[formObject] && this.props.membershipForm[formObject].errors.length > 0)) {
        invalid = true;

        if (formObject === "email" || formObject === "phonenumber") {
          scrollPosition = 500;
        }

        break;
      }
      if (formObject === "email") {
        if (this.props.membershipForm[formObject].value != undefined) {
          email = this.props.membershipForm[formObject].value;
        }
      }
      if (formObject === "phonenumber") {
        if (this.props.membershipForm[formObject].value != undefined) {
          phonenumber = this.props.membershipForm[formObject].value;
        }
      }
    }

    if (invalid) {
      if (this.props.membershipFormPage === 0) {
        const membershipForm = await this.createMembershipDTO(this.props.membershipForm);
        const apiSucceeded = await this.props.addMembershipRequest(membershipForm);
        this.setState({ isLoading: false });

        if (this.props.membershipFormPage + 1 < this.sections.length) {
          this.props.updateMembershipFormPage(this.props.membershipFormPage + 1);
        }
        this.setState({ isLoading: false });
      } else {
        this.setState({ showErrors: true, isLoading: false });
        window.scrollTo(500, scrollPosition);
        return;
      }
    } else {
      this.setState({ showErrors: false });
    }

    if (this.props.membershipFormPage === 1) {
      if (email === "") {
        this.props.setMembershipContactWarningModalOpen(true, "email");
        this.setState({ isLoading: false });
      } else {
        const membershipForm = await this.createMembershipDTO(this.props.membershipForm);
        const apiSucceeded = await this.props.addMembershipRequest(membershipForm);
        if (!apiSucceeded) {
          if (this.props.addingMembershipErrorMessage && this.props.addingMembershipErrorMessage === "This email address is already in use, please use another") {
            this.updateValue(membershipForm.email!, "email", ["duplicateEmail"]);
            scrollPosition = 500;
          } else if (this.props.addingMembershipErrorMessage && this.props.addingMembershipErrorMessage === "This mobile number is already in use, please use another") {
            this.updateValue(membershipForm.phonenumber!, "phonenumber", ["duplicateMobile"]);
            scrollPosition = 500;
          }
          this.setState({ showErrors: true, isLoading: false });
          window.scrollTo(500, scrollPosition);
          return;
        }
        this.setState({ isLoading: false });

        if (this.props.membershipFormPage + 1 < this.sections.length) {
          this.props.updateMembershipFormPage(this.props.membershipFormPage + 1);
        }
      }
    } else {
      const membershipForm = await this.createMembershipDTO(this.props.membershipForm);
      const apiSucceeded = await this.props.addMembershipRequest(membershipForm);
      this.setState({ isLoading: false });

      if (this.props.membershipFormPage === this.summaryPage) {
        this.setState({ flowFinished: true });
      }

      if (this.props.membershipFormPage + 1 < this.sections.length) {
        this.props.updateMembershipFormPage(this.props.membershipFormPage + 1);
      }
    }

    this.changeSectionsOnBasisOfBirthDate();
  }

  async createMembershipDTO(form: FormInput[]): Promise<NewMembershipDTO> {
    let membershipState = this.props.newMembership.membershipState;
    if (this.props.membershipFormPage === this.finalisePage || this.props.membershipFormPage === this.summaryPage) {
      if (membershipState!.includes("TakeOver")) {
        membershipState = "TakeOverPendingAgreement";
      } else {
        membershipState = "PendingAgreement";
      }
    }
    let newMembership: NewMembershipDTO =
    {
      id: this.props.newMembership.id,
      membershipState: membershipState,
      ageVerificationSent: this.props.newMembership.ageVerificationSent,
      parkId: this.props.newMembership.parkId,
      memberId: this.props.newMembership.memberId,
    };
    for (let formObject in form) {
      if (formObject !== "id" && formObject !== "membershipState" && formObject !== "ageVerificationSent") {
        if (form[formObject]) {
          if (formObject === "termFormula") {
            let priceId = await this.getAgeBracketPriceId(form);
            if (priceId && priceId !== "") {
              newMembership = { ...newMembership, ["contentfulAgeBracketPriceId"]: priceId };
            }
          } else {
            newMembership = { ...newMembership, [formObject]: form[formObject].value };
          }
        }
      }
    }
    if (newMembership && newMembership.vehicles) {
      for (let i = 0; i < newMembership.vehicles!.length; i++) {
        if (newMembership.vehicles![i].registration === "" && newMembership.vehicles![i].description === "") {
          if (newMembership.vehicles![i].id) {
            newMembership.vehicles![i].registration = undefined;
            newMembership.vehicles![i].description = undefined;
          } else {
            newMembership.vehicles!.splice(i, 1);
            i--;
          }
        }

      }
    }
    return newMembership;
  }

  async getAgeBracketPriceId(form: any): Promise<string> {
    let contentfulAgeBracketPriceId: string = "";
    const type = this.props.contentfulMembershipTypes.filter(type => type.sys.id === form.contentfulMembershipTypeId.value)[0];
    let ageGroup: Entry<ContentfulMembershipTypeAgeBracket> | undefined = undefined;
    for (ageGroup of type.fields.ageGroups) {
      let age = this.calculateAge(form.dateOfBirth.value);
      if (ageGroup.fields.minimumAge <= age && age <= ageGroup.fields.maximumAge) {
        break;
      }
    }
    if (ageGroup) {
      ageGroup = await GetEntryCall(ageGroup.sys.id);
      if (ageGroup.fields.prices) {
        if (form.termFormula.value) {
          contentfulAgeBracketPriceId = ageGroup.fields.prices.filter(price => price.fields.termFormula === form.termFormula.value.value)[0].sys.id;
        }
      }
    }
    return contentfulAgeBracketPriceId;
  }

  onSaveAndExit() {
    this.props.setExitModalOpen(true);
  }

  onAddNewMember = async () => {
    const membershipForm = await this.createMembershipDTO(this.props.membershipForm);
    const apiSucceeded = await this.props.addMembershipRequest(membershipForm);
    this.setState({ isLoading: false });
    this.props.saveAndExit();
    this.props.startNewMembership();
  }

  enableSaveAndContinue() {
    this.setState({ disableSaveButton: false });
  }

  disableSaveAndContinue() {
    this.setState({ disableSaveButton: true });
  }

  triggerNextPage() {
    this.onTypeNextStep();
  }

  setIsLoading(isLoading: boolean) {
    this.setState({ isLoading: isLoading });
  }

  sections: Section[] =
    [
      {
        key: 0,
        title: "addMembership.membership.title",
        name: 'membershipType'
      },
      {
        key: 1,
        title: "addMembership.personalDetails.title",
        name: 'personalDetails'
      },
      {
        key: 2,
        title: "addMembership.photoUpload.title",
        name: 'photoUpload'
      },
      {
        key: 3,
        title: "addMembership.vehicles.title",
        name: 'vehicles'
      },
      {
        key: 4,
        title: "addMembership.summary.title",
        name: 'summary'
      }
    ]



  disableSaveButtonFunc(disable: boolean) {
    this.setState({ disableSaveButton: disable });
  }

  uploadAgeVerificationFile(file: any, membershipId: string, firstName: string, lastName: string, dateOfBirth: Date) {
    this.setState({ isLoading: true });
    this.props.uploadFile(file, membershipId, firstName, lastName, dateOfBirth);
  }

  public render(): JSX.Element {
    const { t } = this.props;

    if (this.state.flowFinished) {
      return (
        <Redirect
          to={Routes.MyGuests}
        />
      )
    }

    return (
      <>
        {(this.state.contentLoaded && this.props.termDurations && this.props.termDurations.length > 0) ? (
          <div className="widget-container">
            <Desktop>
              {!this.props.isHeaderOpen && (
                <>
                  <h1 className="widget-layout-title">{t("addMembership.title")}</h1>
                  <div className="widget-layout-message">
                    {documentToReactComponents(this.state.descriptionText!.content)}
                  </div>
                </>
              )}
            </Desktop>

            <Widget
              position={{
                columnIndex: 1,
                columnSpan: 12,
                rowIndex: 3,
                rowSpan: 9,
              }}
            >
              {(this.props.membershipFormPage > 0) && (
                <ClickableIcon
                  icon={faArrowAltCircleLeft}
                  clickAction={this.onBack.bind(this)}
                  className="back-button"
                  size={"2x"}
                />
              )}

              <div className="breadcrumb">
                {this.sections.map((s) =>
                  <Breadcrumb title={s.title} key={s.key} length={this.sections.length} selected={s.key == this.props.membershipFormPage} section={s} />
                )}
              </div>

              <div className="form-container">
                {this.props.membershipFormPage === 0 &&
                  <SelectMembershipType
                    onInput={this.updateValue.bind(this)}
                    name="contentfulMembershipTypeId"
                    form={this.props.membershipForm}
                    showErrors={this.state.showErrors}
                    nextStep={this.onTypeNextStep.bind(this)}
                    ownerMembershipsLeft={this.props.ownerMembershipsAvailability && (this.props.ownerMembershipsAvailability.numberUsed < this.props.ownerMembershipsAvailability.allocated)}
                    ownerMembershipsAvailability={this.props.ownerMembershipsAvailability}
                    contentfulMembershipTypes={this.props.contentfulMembershipTypes}
                    termDurations={this.props.termDurations}
                    userRoles={this.props.userRoles}
                  />
                }

                {this.props.membershipFormPage === 1 &&
                  <PersonalDetails
                    onInput={this.updateValue.bind(this)}
                    membershipForm={this.props.membershipForm}
                    showErrors={this.state.showErrors}
                    marketingPreferencesText={this.state.marketingPreferencesText!}
                    hasOwnMembership={this.props.hasOwnMembership}
                    membershipExist={this.membershipExist}
                    userRoles={this.props.userRoles}
                    detailsDescription={this.state.memberDetailsDescriptionText!.content}
                  />
                }

                {this.props.membershipFormPage === 2 &&
                  <PhotoUpload
                    ref={this.photoUploadRef}
                    enableSaveAndContinue={this.enableSaveAndContinue.bind(this)}
                    memberId={this.props.newMembership.memberId as string}
                    instructions={this.state.photoUploadInstructions!}
                    fileRestrictions={this.state.photoUploadFileRestrictions!}
                    exception={this.state.photoUploadException!}
                    triggerNextPage={this.triggerNextPage.bind(this)}
                    disableSaveAndContinue={this.disableSaveAndContinue.bind(this)}
                    setIsLoading={this.setIsLoading.bind(this)}
                    isLoading={this.state.isLoading}
                    onSaveError={() => {}}
                  />
                }

                {this.props.membershipFormPage === this.ageVerificationPage &&
                  <AgeVerification
                    form={this.props.membershipForm as IMembershipForm}
                    uploadFile={this.uploadAgeVerificationFile.bind(this)}
                    showErrors={this.state.showErrors}
                    description={this.state.ageVerificationDescription!}
                    uploadSuccess={this.props.addingAgeVerificationImageSuccess}
                    disableSaveFunc={this.disableSaveButtonFunc.bind(this)}
                    isLoading={this.state.isLoading}
                    addingAgeVerificationImageError={this.props.addingAgeVerificationImageError}
                    addingAgeVerificationImageErrorMessage={this.props.addingAgeVerificationImageErrorMessage}
                  />
                }

                {this.props.membershipFormPage === this.vehiclePage &&
                  <Vehicles
                    vehicleName="vehicles"
                    membershipForm={this.props.membershipForm}
                    onInput={this.updateValue.bind(this)}
                    showErrors={this.state.showErrors}
                    description={this.state.vehicleDescription!}
                  />
                }

                {this.props.membershipFormPage === this.summaryPage &&
                  <SummaryPage
                    membershipForm={this.props.membershipForm as IMembershipForm}
                    startNewMembership={this.onAddNewMember.bind(this)}
                    ownerMembershipsAvailability={this.props.ownerMembershipsAvailability}
                    contentfulMembershipTypes={this.props.contentfulMembershipTypes}
                    proceedDescription={this.state.proceedDescription!}
                  />
                }

                {this.props.membershipFormPage === this.finalisePage &&
                  <FinalisePage
                    membershipForm={this.props.membershipForm as IMembershipForm}
                  />
                }

              </div>

              <div className="widget-footer">
                <Desktop>
                  {this.props.membershipFormPage !== this.summaryPage && (
                    <SecondaryButton
                      id="exitBtn"
                      text={t("general.exit")}
                      type="submit"
                      onClick={this.onSaveAndExit.bind(this)}
                      className="membership-btn exit-btn"
                    />
                  )}

                  {this.props.membershipFormPage != 0 &&

                    <PrimaryButton
                      text={this.props.membershipFormPage === this.summaryPage ? "Proceed" : t("addMembership.saveContinue")}
                      id="saveAndContinue"
                      type="submit"
                      disabled={this.state.isLoading || this.state.disableSaveButton}
                      onClick={this.onSaveAndContinue.bind(this)}
                      className="save-cnt-btn membership-btn"
                      isLoading={this.state.isLoading}
                    />
                  }
                </Desktop>
                <Mobile>
                  {this.props.membershipFormPage != 0 &&
                    <PrimaryButton
                      text={this.props.membershipFormPage === this.summaryPage ? "Proceed" : t("addMembership.saveContinue")}
                      id="saveAndContinue"
                      type="submit"
                      disabled={this.state.isLoading || this.state.disableSaveButton}
                      onClick={this.onSaveAndContinue.bind(this)}
                      className="membership-btn"
                      isLoading={this.state.isLoading}
                    />
                  }

                  {this.props.membershipFormPage !== this.summaryPage && (
                    <SecondaryButton
                      id="exitBtn"
                      text={t("general.exit")}
                      type="submit"
                      onClick={this.onSaveAndExit.bind(this)}
                      className="membership-btn"
                    />
                  )}
                </Mobile>
              </div>
            </Widget>
            {this.props.isMembershipContactWarningPromptOpen && (<MembershipFlowContactPrompt onNextStep={this.moveToNextStep.bind(this)} form={this.props.membershipForm} />)
            }
            {
              this.props.isExitPromptOpen && (<ExitPrompt />)
            }
          </div>
        ) : (
          <div className="widget-container">
            <Widget
              position={{
                columnIndex: 1,
                columnSpan: 12,
                rowIndex: 3,
                rowSpan: 9,
              }}
            >
              <div className="memberships-spinner">
                <Spinner />
              </div>
            </Widget>
          </div>
        )}
      </>
    );
  };
};

const mapStateToProps = (state: any) => {
  return {
    isHeaderOpen: state.application.isHeaderOpen,
    membershipFormPage: state.guests.membershipFormPage,
    newMembership: state.guests.newMembership,
    membershipForm: state.guests.membershipForm,
    membershipBasket: state.guests.membershipBasket,
    ownerMembershipsAvailability: state.user.ownerMembershipsAvailability,
    basketId: state.guests.basketId,
    isMembershipContactWarningPromptOpen: state.modal.membershipflowContactprompt.isOpen,
    promptNextStep: state.modal.membershipContactPromptNextStep.nextStep,
    isValidated: state.guests.validateAndUploadPhoto,
    contentfulMembershipTypes: state.guests.contentfulMembershipTypes,
    isAddingAgeVerificationImage: state.guests.isAddingAgeVerificationImage,
    addingAgeVerificationImageSuccess: state.guests.addingAgeVerificationImageSuccess,
    isExitPromptOpen: state.modal.exit.isOpen,
    addingMembershipErrorMessage: state.guests.addingMembershipErrorMessage,
    hasOwnMembership: state.user.hasOwnMembership,
    userFirstName: state.user.firstname,
    userLastName: state.user.lastName,
    userEmail: state.user.loginEmail,
    userPhoneNumber: state.user.phoneNumber,
    userRoles: state.user.userRoles,
    membershipContainers: state.guests.membershipContainers,
    addingAgeVerificationImageError: state.guests.addingAgeVerificationImageError,
    addingAgeVerificationImageErrorMessage: state.guests.addingAgeVerificationImageErrorMessage,
    termDurations: state.guests.termDurations,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateMembershipFormPage: (pageNo: number) => {
      dispatch({ type: CHANGE_MEMBERSHIP_FORM_PAGE, payload: pageNo })
    },
    addMembershipRequest: async (membership: NewMembershipDTO) => {
      let result = dispatch(addMembership(membership));
      return result;
    },
    setExitModalOpen: (isOpen: boolean) => {
      dispatch({ type: SET_EXIT_PROMPT_OPEN, payload: isOpen })
    },
    saveAndExit: () => {
      dispatch({ type: ActionTypes.SAVE_AND_EXIT })
    },
    updateMembershipForm: ((fieldName: any, value: any, errors: string[]) => {
      dispatch(updateMembershipForm(fieldName, value, errors));
    }),
    updateMembershipFormBasket: ((membershipForm?: IMembershipForm | undefined, membershipBasket?: IMembershipForm[] | undefined) => {
      dispatch(updateMembershipFormBasket(membershipForm, membershipBasket));
    }),
    updateMembershipBasketField: ((index: number, fieldName: any, value: any) => {
      dispatch(updateMembershipBasketField(index, fieldName, value));
    }),
    startNewMembership: () => {
      dispatch({ type: ActionTypes.START_NEW_MEMBERSHIP });
    },
    getOwnerMembershipAvailability: () => {
      dispatch(getOwnerMembershipAvailability());
    },
    uploadFile: (file: any, membershipId: string, firstName: string, lastName: string, dateOfBirth: Date) => {
      dispatch(addAgeVerificationFile(file, membershipId, firstName, lastName, dateOfBirth));
    },
    setMembershipContactWarningModalOpen: (isOpen: boolean, type: string) => {
      dispatch({ type: SET_MEMBERSHIPS_FLOW_CONTACT_PROMPT_OPEN, payload: { isOpen: isOpen, type: type } })
    },
    setPromptNextStep: (nextStep: boolean) => {
      dispatch({ type: SET_MEMBERSHIPS_CONTACT_PROMPT_NEXT_STEP, payload: nextStep })
    },
    setContentfulMembershipTypes: (withTerms?: boolean) => {
      dispatch(getContentfulMembershipTypes(withTerms));
    },
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AddMembership);
function dispatch(arg0: { type: string; payload: any; }) {
  throw new Error("Function not implemented.");
}

