import FrequencyFormula from "../../Constants/FrequencyFormula";
import { GetApiCall } from "./ApiCall";

export class GetStripeCheckoutUrlsCall extends GetApiCall {

  constructor(membershipBasketId: string, frequencyFormula: FrequencyFormula) {
    let url: string = "Stripe/MembershipCheckoutUrls";

    let params: any = { membershipBasketId };

    super(url, params);
  }
}