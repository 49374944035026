import React, { Component, ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ModalFooter, ModalHeader } from '../../../Shared/Components/Presentational/Modal';
import './MakeAPaymentModal.scss';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import MakeAPaymentModalCardAddress from './MakeAPaymentModalCardAddress';
import MakeAPaymentModalCardAddressContainer from '../Container/MakeAPaymentModalCardAddressContainer';

interface MakeAPaymentModalCardProps extends WithTranslation {
    onBackButtonClick: () => void;
    currentBalance: number;
    accountNumber: string;
}

interface MakeAPaymentModalCardState {
    amount: number;
    addressModalOpen: boolean;
}

class MakeAPaymentModalCard extends Component<MakeAPaymentModalCardProps> {
    constructor(props: MakeAPaymentModalCardProps) {
        super(props);

        this.handleAmountInputChange = this.handleAmountInputChange.bind(this);
        this.handleAddressBackButton = this.handleAddressBackButton.bind(this);

        this.formatInput = this.formatInput.bind(this);
        this.proceed = this.proceed.bind(this);
    }

    public state: MakeAPaymentModalCardState = {
        amount: 0,
        addressModalOpen: false,
    };

    componentDidMount() {
        this.setState({ amount: this.props.currentBalance.toFixed(2) });
    }

    handleAmountInputChange(e: ChangeEvent<HTMLInputElement>) {
        const newAmount = e.target.value;
        this.setState({ amount: newAmount });
    }

    handleAddressBackButton() {
        this.setState({ addressModalOpen: false });
    }

    formatInput() {
        const amountFromState = this.state.amount;
        this.setState({ amount: parseFloat(amountFromState.toString()).toFixed(2) });
    }

    proceed() {
        this.setState({ addressModalOpen: true });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {!this.state.addressModalOpen && (
                    <>
                        <ModalHeader text={t('myAccount.payment.howMuch')} />
                        <section className="payment-details-section">
                            <div className="payment-details card">
                                <label className="title" htmlFor="amount">
                                    {t('myAccount.payment.amount')}
                                </label>
                                <input
                                    name="amount"
                                    type="number"
                                    className="form-input"
                                    required
                                    placeholder="0.00"
                                    value={this.state.amount}
                                    onChange={this.handleAmountInputChange}
                                    onBlur={this.formatInput}
                                />
                            </div>
                        </section>
                        <ModalFooter onBackButtonClick={this.props.onBackButtonClick}>
                            <PrimaryButton
                                onClick={this.proceed}
                                className="footer-button"
                                disabled={!this.state.amount || this.state.amount <= 0}
                                text={t('general.proceed')}
                            ></PrimaryButton>
                        </ModalFooter>
                    </>
                )}
                {this.state.addressModalOpen && (
                    <MakeAPaymentModalCardAddressContainer
                        paymentAmount={this.state.amount}
                        onBackButtonClick={this.handleAddressBackButton}
                    />
                )}
            </>
        );
    }
}

export default withTranslation()(MakeAPaymentModalCard);
