import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SET_REFER_MEMBERSHIPS_PROMPT_OPEN } from '../../../Modal/Redux/ActionTypes';
import Fab from '../../../Shared/Components/Presentational/Fab';
import Spinner from '../../../Shared/Components/Presentational/Spinner';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import {
  getAddMembershipById,
  getEditMemberDetailsById,
  getMembershipsThenMapToContainers,
  deleteMembership,
  createMembershipBasket,
  getReferredMembershipsByOwner,
  getContentfulMembershipTypes
}
  from '../../Redux/ActionCreators';
import * as ActionTypes from '../../Redux/ActionTypes';
import FinaliseContainer from '../Presentational/MyMembershipsContainers/FinaliseContainer';
import MembershipDashboardCard from '../Presentational/MyMembershipsContainers/MembershipDashboardCard';
import GenericMembershipCardContainer from '../Presentational/MyMembershipsContainers/GenericDisplayContainer';
import "./MyMemberships.scss";
import * as Routes from "../../../Constants/Routes";
import FinaliseDisplayContainer from '../Presentational/MyMembershipsContainers/FinaliseDisplayContainer';
import ReferMembershipsPrompt from '../../../Modal/Components/Container/ReferMembershipsPrompt';
import DeleteMembershipPromptModal from '../../../Modal/Components/Container/DeleteMembershipPromptModal';
import MembershipStates from '../../../Constants/MembershipStates';
import { Get } from '../../../Api/ApiService';
import { GetMembershipPaymentSummaryCall } from '../../../Api/Calls/GetMembershipPaymentSummaryCall';
import { Desktop } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { Entry } from 'contentful';
import RemainingMemberships from '../../../Shared/Components/Presentational/RemainingMemberships';
import { GetHTMlContentBlockCall } from '../../../Contentful/Calls/GetHTMLContentBlockCall';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Redirect } from 'react-router-dom';
import { GetEntryCall } from '../../../Contentful/Calls/GetEntryCall';
import { CheckFriendMembershipQuotaCall } from '../../../Api/Calls/CheckFriendMembershipQuotaCall';
import FriendMembershipModal from '../../../Modal/Components/Container/FriendMembershipQuotaModal';
import ExpiredMembershipContainer from '../Presentational/MyMembershipsContainers/ExpiredMembershipContainer';
import { isWithinInterval, endOfToday, subWeeks, subDays } from 'date-fns';
import MembershipTypes from '../../../Constants/MembershipTypes';

interface MyMembershipsDashboardProps extends WithTranslation {
  isHeaderOpen: boolean;
  userRoles: UserRoleDTO[];
  memberships: MembershipDTO[];
  getMembershipsThenMapToContainers: () => void;
  getReferredMembershipsByOwner: () => void;
  getAddMembershipById: (membershipId: string) => void;
  isGettingMemberships: boolean;
  gettingMembershipsSuccess: boolean;
  gettingMembershipsFailure: boolean;
  isGettingAddMembershipById: boolean;
  gettingAddMembershipByIdSuccess: boolean;
  isReferPromptOpen: boolean;
  setReferMembershipModalOpen: (isOpen: boolean) => void;
  clearMembershipForm: () => void;
  updateMembershipBasket: (membershipBasket: MembershipDTO[]) => Promise<void>;
  updateMembershipFormPage: (page: number) => void;
  isGettingEditMemberDetailsById: boolean;
  gettingEditMemberDetailsByIdSuccess: boolean;
  getEditMemberDetailsById: (membershipId: string) => void;
  membershipContainers: MembershipContainer[];
  finaliseNowContainers: MembershipContainer[];
  finaliseLaterContainers: MembershipContainer[];
  referredMemberships: ReferralDTO[];
  setFinaliseNowContainers: (containers: MembershipContainer[]) => void;
  setFinaliseLaterContainers: (containers: MembershipContainer[]) => void;
  deleteMembership: (membershipId: string) => void;
  createMembershipBasket: (membershipIds: string[]) => Promise<string>;
  basketId: string;
  setPaymentDetailsDTO: (paymentDetails: PaymentBreakdownDTO) => Promise<void>;
  setPaymentRequired: (isPaymentRequired: boolean) => void;
  contentfulMembershipTypes: Entry<ContentfulMembershipType>[];
  setContentfulMembershipTypes: () => void;
  canRefer: boolean;
}

interface MyMembershipsDashboardState {
  isLoading: boolean;
  isDraft: boolean;
  isDeleteModalOpen: boolean;
  membershipToDelete: string;
  changePage: boolean;
  pageToChangeTo: string;
  headerText: ContentfulContentObject | undefined;
  descriptionText: ContentfulContentObject | undefined;
  noMembershipText: ContentfulContentObject | undefined;
  contentLoaded: boolean;
  datesExceededFriendQuota: FriendQuotaExceededDTO[] | undefined;
  isFriendMembershipQuotaModalOpen: boolean;
  showAddButton: boolean | undefined;
}

class MyMembershipsDashboard extends Component<MyMembershipsDashboardProps, MyMembershipsDashboardState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      isDraft: false,
      isDeleteModalOpen: false,
      membershipToDelete: "",
      changePage: false,
      pageToChangeTo: "",
      headerText: undefined,
      descriptionText: undefined,
      noMembershipText: undefined,
      contentLoaded: false,
      datesExceededFriendQuota: undefined,
      isFriendMembershipQuotaModalOpen: false,
      showAddButton: undefined,
    }

    this.props.getMembershipsThenMapToContainers();
    this.props.getReferredMembershipsByOwner();
    Promise.all([
      this.getContentBlock("My Haulfryn - Membership - My Members", "headerText"),
      this.getContentBlock("My Haulfryn- New Membership  - My Membership Summary.", "descriptionText"),
      this.getContentBlock("My Haulfryn - My Memberships - No Membership", "noMembershipText"),
    ]).then(r => {
      r.forEach(c => this.setState({ ...this.state, [c.name]: c }));
      this.setState({ contentLoaded: true })
    })
  }

  getContentBlock = async (name: string, label: string) => {
    const text = await GetHTMlContentBlockCall(name, label);
    return text;
  }

  componentDidMount() {
    if (!this.props.contentfulMembershipTypes) {
      this.props.setContentfulMembershipTypes();
    }
    this.setState({ isLoading: true });
    this.showAddButton();
  }

  showAddButton() {
    if (this.props.contentfulMembershipTypes) {
      this.setState({ showAddButton: this.props.canRefer || this.props.contentfulMembershipTypes.length > 0 });
    }
  }

  componentDidUpdate(prevProps: Readonly<MyMembershipsDashboardProps>, prevState: Readonly<MyMembershipsDashboardState>, snapshot?: any): void {
    // What do all these conditions amount to?
    if ((prevProps.isGettingAddMembershipById || prevProps.isGettingEditMemberDetailsById) &&
      (
        (!this.props.isGettingAddMembershipById && this.props.gettingAddMembershipByIdSuccess) ||
        (!this.props.isGettingEditMemberDetailsById && this.props.gettingEditMemberDetailsByIdSuccess)
      )) {
      this.state.isDraft ? this.setState({ pageToChangeTo: Routes.AddMembership, changePage: true }) : this.setState({ pageToChangeTo: Routes.ManageActiveMemberDetails, changePage: true })
    }

    if (this.state.isLoading && this.props.contentfulMembershipTypes) {
      this.setState({ isLoading: false });
    }

    if (this.props.membershipContainers && (!this.props.finaliseNowContainers || prevProps.membershipContainers !== this.props.membershipContainers)) {
      this.props.setFinaliseNowContainers(this.props.membershipContainers.filter(membership => membership.state === MembershipStates.Finalise));
      this.props.setFinaliseLaterContainers([]);
    };

    if (this.state.showAddButton === undefined
      && this.props.contentfulMembershipTypes) {
      this.showAddButton();
    }
  }

  // What does nowLaterOnClick mean?
  nowLaterOnClick(membership: MembershipDTO, current: boolean) {
    if (current) {
      this.props.setFinaliseNowContainers(this.props.finaliseNowContainers.filter(m => m.membership.id !== membership.id));

      this.props.setFinaliseLaterContainers(
        this.props.finaliseLaterContainers ?
          this.props.finaliseLaterContainers.concat({ state: MembershipStates.Finalise, membership: membership }) :
          [{ state: MembershipStates.Finalise, membership: membership }]
      );
    } else {
      this.props.setFinaliseLaterContainers(this.props.finaliseLaterContainers.filter(m => m.membership.id !== membership.id));

      this.props.setFinaliseNowContainers(
        this.props.finaliseNowContainers ?
          this.props.finaliseNowContainers.concat({ state: MembershipStates.Finalise, membership: membership }) :
          [{ state: MembershipStates.Finalise, membership: membership }]
      );
    }
  }

  async willbasketRequirePayment(membershipContainers: MembershipContainer[]): Promise<boolean> {
    let requirePayment = false;
    for (let container of membershipContainers) {
      let ageBracketPrice: Entry<ContentfulMembershipTypeAgeBracketPrice> = await GetEntryCall(container.membership.contentfulAgeBracketPriceId);
      if (ageBracketPrice.fields.price > 0) {
        requirePayment = true;
        break;
      }
    }
    this.props.setPaymentRequired(requirePayment);
    return requirePayment;
  }

  async signAndPay() {
    let membershipIds: string[] = [];
    let membershipBasket: MembershipDTO[] = [];

    for (let container of this.props.finaliseNowContainers) {
      membershipIds = membershipIds.concat(container.membership && container.membership.id!);
      membershipBasket = membershipBasket.concat(container.membership && container.membership);
    }

    this.setState({ isLoading: true });

    try {
      const basketId = await this.props.createMembershipBasket(membershipIds);
      await this.props.updateMembershipBasket(membershipBasket);
      const datesExceedingFriendQuota = (await Get<FriendQuotaExceededDTO[]>(new CheckFriendMembershipQuotaCall(basketId))).data;
      if (datesExceedingFriendQuota.length > 0) {
        datesExceedingFriendQuota.map(quota => { quota.dateExceeded = new Date(quota.dateExceeded) });
        this.setState({ datesExceededFriendQuota: datesExceedingFriendQuota, isFriendMembershipQuotaModalOpen: true });
        throw new DOMException("Basket Exceeds Friend Membership Quota");
      }
      const paymentSummary = await Get<any>(new GetMembershipPaymentSummaryCall(basketId));
      await this.props.setPaymentDetailsDTO(paymentSummary.data);
      this.checkRouting(paymentSummary);
    } catch (e) {
      console.log(e);
    }
  }

  checkRouting(paymentSummary: any) {
    if (paymentSummary.status === 200) {
      this.setState({ pageToChangeTo: Routes.SelectPayment, changePage: true })
    }
  }

  setDeleteMembershipModalOpen(isOpen: boolean, membershipId: string | undefined) {
    this.setState({ isDeleteModalOpen: isOpen, membershipToDelete: membershipId ? membershipId : "" });
  }

  getMembershipText(type: string) {
    let text = "";
    let membershipType = this.props.contentfulMembershipTypes.find(mt => mt.sys.id === type);
    if (membershipType) {
      text = membershipType.fields.publicName;
    }
    return text;
  }

  isCurrentDateWithinFourWeeksOfTargetDate(targetDate: Date): boolean {
    targetDate = new Date(targetDate);
    let currentDate = endOfToday();
    let endMinusFourDate = subWeeks(targetDate, 4);
    return isWithinInterval(currentDate, { start: endMinusFourDate, end: targetDate }) || currentDate.toDateString() === targetDate.toDateString();
  }

  canRenew(membership: MembershipDTO): boolean {
    return this.isCurrentDateWithinFourWeeksOfTargetDate(membership.endDate) 
      && membership.contentfulMembershipTypeId == MembershipTypes.Club;
  }

  public render(): JSX.Element {
    const { t } = this.props;

    const renderComponent = (
      membership: MembershipContainer | undefined,
      isNow: boolean | undefined,
      referredMembership: ReferralDTO | undefined
    ) => {
      if (membership) {
        let membershipText = this.getMembershipText(membership.membership.contentfulMembershipTypeId);
        if (membership.state === MembershipStates.Finalise) {
          return (
            <div className='container-box' style={{ backgroundColor: isNow ? "#ffe49c" : "#ffcc9c" }}>
              <FinaliseContainer
                membership={membership.membership}
                editButtonFunction={getAddMembership.bind(this)}
                state={membership.state}
                isNow={isNow || false}
                nowlaterButtonFunction={this.nowLaterOnClick.bind(this)}
                deleteFunction={this.setDeleteMembershipModalOpen.bind(this)}
                membershipText={membershipText}
              />
            </div>
          )
        } else {
          return (
            <div
              className='container-box'
              style={{ backgroundColor: membership.state === MembershipStates.Draft ? "#f0ecec" : membership.state === MembershipStates.Active ? "#b8d4ac" : undefined }}
            >
              <MembershipDashboardCard
                membership={membership.membership}
                buttonFunction={membership.state !== MembershipStates.Expired ? getAddMembership.bind(this) : undefined}
                state={membership.state}
                deleteFunction={this.setDeleteMembershipModalOpen.bind(this)}
                membershipText={membershipText}
                canRenew={this.canRenew(membership.membership)}
              />
            </div>
          )
        }
      } else {
        // render Referred component
        if (referredMembership) {
          return (
            <div className='container-box' style={{ backgroundColor: "#ffffff" }}>
              <MembershipDashboardCard
                referredMembership={referredMembership}
                state={MembershipStates.Referrals}
                buttonFunction={(v) => {
                  this.setState({ changePage: true, pageToChangeTo: Routes.ReferMembership + "?id=" + referredMembership.id })
                }}
              />
            </div>
          )
        }
      }
    }

    const getAddMembership = (membership: MembershipContainer) => {
      if (membership && membership.membership.id) {
        if (membership.state === MembershipStates.Draft || membership.state === MembershipStates.Finalise) {
          this.props.getAddMembershipById(membership.membership.id);
          this.props.updateMembershipFormPage(0);
          this.setState({ isLoading: true, isDraft: true });
        } else if (membership.state === MembershipStates.Active) {
          this.props.getEditMemberDetailsById(membership.membership.id);
          this.setState({ isDraft: false });
        }
      }
    }

    if (this.state.changePage) {
      return (
        <Redirect to={this.state.pageToChangeTo} />
      )
    } else {
      return (
        <>
          {(this.state.contentLoaded && this.props.contentfulMembershipTypes) ? (
            <div className='widget-container'>
              <Desktop>
                {!this.props.isHeaderOpen && (
                  <>
                    <h1 className='widget-layout-title'>{this.state.headerText!.title}</h1>
                    <p className='widget-layout-message'>{documentToReactComponents(this.state.headerText!.content)}</p>
                  </>
                )}
              </Desktop>
              <Widget
                position={{
                  columnIndex: 1,
                  columnSpan: 12,
                  rowIndex: 3,
                  rowSpan: 9,
                }}
              >
                {this.state.showAddButton && (
                  <Fab
                    icon={faPlus}
                    clickAction={() => {
                      this.props.setReferMembershipModalOpen(true);
                    }}
                    className='add-pass'
                    id={"addMembership"}
                  />
                )}
                {(this.state.isLoading || this.props.isGettingMemberships) ? (
                  <div className='memberships-spinner'>
                    <Spinner />
                  </div>
                ) : (this.props.gettingMembershipsSuccess && (
                  <>
                    <div className="row" style={{ marginBottom: "15px" }}>
                      <div className="float-left">
                        <h1 style={{ margin: "20px", color: "#184f6d" }}>{this.state.descriptionText!.title}</h1>
                      </div>
                    </div>
                    <div className='row' style={{ marginLeft: "30px", marginTop: "10px", marginRight: "10px" }}>
                      {documentToReactComponents(this.state.descriptionText!.content)}
                    </div>

                    {!this.props.membershipContainers || this.props.membershipContainers.filter(membership => membership.state === MembershipStates.Finalise).length === 0 && (
                      <div className='dashboard-owner-memberships'>
                        <RemainingMemberships />
                      </div>
                    )}

                    {(this.props.memberships && this.props.membershipContainers && this.props.membershipContainers.length > 0) ? (
                      <>
                        {/* *** Finalise Memberships *** */}
                        {this.props.membershipContainers.filter(membership => membership.state === MembershipStates.Finalise).length > 0 && (
                          <FinaliseDisplayContainer
                            finaliseNowContainers={this.props.finaliseNowContainers}
                            finaliseLaterContainers={this.props.finaliseLaterContainers}
                            state={MembershipStates.Finalise}
                            renderComponent={renderComponent.bind(this)}
                            willRequirePayment={this.willbasketRequirePayment.bind(this)}
                            signAndPay={this.signAndPay.bind(this)}
                          />
                        )}

                        {/* *** Draft Memberships *** */}
                        {this.props.membershipContainers.filter((membership) => membership.state === MembershipStates.Draft).length > 0 && (
                          <GenericMembershipCardContainer
                            membershipContainers={this.props.membershipContainers.filter((membership) => membership.state === MembershipStates.Draft)}
                            state={MembershipStates.Draft}
                            renderComponent={renderComponent.bind(this)}
                          />
                        )}
                        {/* *** Active Memberships *** */}
                        {this.props.membershipContainers.filter(membership => membership.state === MembershipStates.Active).length > 0 && (
                          <GenericMembershipCardContainer
                            membershipContainers={this.props.membershipContainers.filter((membership) => membership.state === MembershipStates.Active)}
                            state={MembershipStates.Active}
                            renderComponent={renderComponent.bind(this)}
                          />
                        )}

                        {/* *** Expired Memberships *** */}
                        {this.props.membershipContainers.filter(membership => membership.state === MembershipStates.Expired).length > 0 && (
                          <ExpiredMembershipContainer
                            memberships={
                              this.props.membershipContainers
                                .filter((membership) => membership.state === MembershipStates.Expired)
                                .map(container => container.membership)
                            }
                            contentfulMembershipTypes={this.props.contentfulMembershipTypes}
                            deleteFunction={this.setDeleteMembershipModalOpen.bind(this)}
                          />
                        )}
                      </>
                    ) : (
                      // *** No Memberships ***
                      <>
                        <div className='row no-membership-text' style={{ marginLeft: "30px", marginTop: "20%", marginRight: "30px" }}>
                          {documentToReactComponents(this.state.noMembershipText!.content)}
                        </div>
                      </>
                    )}

                    {/* *** Referrals *** */}
                    {(this.props.referredMemberships && this.props.referredMemberships.length > 0) && (
                      <GenericMembershipCardContainer
                        referredContainer={this.props.referredMemberships}
                        state={MembershipStates.Referrals}
                        renderComponent={renderComponent.bind(this)}
                      />
                    )}
                  </>
                ))}
              </Widget>

            </div>
          ) : (
            <div className='memberships-spinner'>
              <Spinner />
            </div>
          )}

          {this.props.isReferPromptOpen && (<ReferMembershipsPrompt />)}

          <DeleteMembershipPromptModal
            isOpen={this.state.isDeleteModalOpen}
            membershipToDelete={this.state.membershipToDelete}
            closeModal={this.setDeleteMembershipModalOpen.bind(this)}
            deleteMembership={this.props.deleteMembership.bind(this)}
          />

          {this.state.datesExceededFriendQuota && (
            <FriendMembershipModal
              isOpen={this.state.isFriendMembershipQuotaModalOpen}
              closeModal={() => this.setState({ isFriendMembershipQuotaModalOpen: false })}
              datesExceededFriendQuota={this.state.datesExceededFriendQuota}
              isFinalisePage={false}
            />
          )}
        </>
      )
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    memberships: state.guests.memberships,
    isGettingMemberships: state.guests.isGettingMemberships,
    gettingMembershipsSuccess: state.guests.gettingMembershipsSuccess,
    gettingMembershipsFailure: state.guests.gettingMembershipsFailure,
    isGettingAddMembershipById: state.guests.isGettingAddMembershipById,
    gettingAddMembershipByIdSuccess: state.guests.gettingAddMembershipByIdSuccess,
    isGettingEditMemberDetailsById: state.guests.isGettingEditMemberDetailsById,
    gettingEditMemberDetailsByIdSuccess: state.guests.gettingEditMemberDetailsByIdSuccess,
    isReferPromptOpen: state.modal.referMembership.isOpen,
    membershipContainers: state.guests.membershipContainers,
    referredMemberships: state.guests.referredMemberships,
    finaliseNowContainers: state.guests.finaliseNowContainers,
    finaliseLaterContainers: state.guests.finaliseLaterContainers,
    basketId: state.guests.basketId,
    contentfulMembershipTypes: state.guests.contentfulMembershipTypes,
    userRoles: state.user.userRoles,
    canRefer: state.user.canRefer,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getReferredMembershipsByOwner: () => { dispatch(getReferredMembershipsByOwner()) },
    getMembershipsThenMapToContainers: () => { dispatch(getMembershipsThenMapToContainers()) },
    getAddMembershipById: (membershipId: string) => dispatch(getAddMembershipById(membershipId)),
    clearMembershipForm: () => {
      dispatch({ type: ActionTypes.SAVE_AND_EXIT })
    },
    updateMembershipFormPage: (page: number) => {
      dispatch({ type: ActionTypes.UPDATE_MEMBERSHIP_FORM_PAGE, payload: page })
    },
    setReferMembershipModalOpen: (isOpen: boolean) => {
      dispatch({ type: SET_REFER_MEMBERSHIPS_PROMPT_OPEN, payload: isOpen })
    },
    getEditMemberDetailsById: (membershipId: string) => {
      dispatch(getEditMemberDetailsById(membershipId));
    },
    setFinaliseNowContainers: (finaliseNowContainers: MembershipContainer[]) => {
      dispatch({ type: ActionTypes.SET_FINALISE_NOW_CONTAINERS, payload: finaliseNowContainers });
    },
    setFinaliseLaterContainers: (finaliseLaterContainers: MembershipContainer[]) => {
      dispatch({ type: ActionTypes.SET_FINALISE_LATER_CONTAINERS, payload: finaliseLaterContainers });
    },
    deleteMembership: (membershipId: string) => {
      dispatch(deleteMembership(membershipId));
    },
    createMembershipBasket: async (membershipIds: string[]) => {
      let basketId = await dispatch(createMembershipBasket(membershipIds));
      return Promise.resolve(basketId);
    },
    updateMembershipBasket: (membershipBasket: MembershipDTO[]) => {
      dispatch({ type: ActionTypes.UPDATE_MEMBERSHIP_BASKET, payload: membershipBasket });
      return Promise.resolve();
    },
    setPaymentDetailsDTO: (paymentDetails: PaymentBreakdownDTO) => {
      dispatch({ type: ActionTypes.SET_PAYMENT_SUMMARY_DTO, payload: paymentDetails });
      return Promise.resolve();
    },
    setPaymentRequired: (isPaymentRequired: boolean) => {
      dispatch({ type: ActionTypes.SET_PAYMENT_REQUIRED, payload: isPaymentRequired });
    },
    setContentfulMembershipTypes: () => {
      dispatch(getContentfulMembershipTypes());
    },
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(MyMembershipsDashboard);
