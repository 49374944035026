import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GetNewsArticlesCall } from '../../../Contentful/Calls/GetNewsArticlesCall'
import { GetParkCall } from '../../../Contentful/Calls/GetParkCall'
import Spinner from '../../../Shared/Components/Presentational/Spinner';
import NewsCard from '../Presentational/NewsCard';
import * as Routes from '../../../Constants/Routes';
import { Entry } from 'contentful';

interface NewsCardContainerProps {
    park: any;
    userRoles: UserRoleDTO[];
    setSelectedNewsItem: (selectedNewsItemId: string) => void;
}

const NewsCardContainer: React.FC<NewsCardContainerProps> = ({ park, setSelectedNewsItem, userRoles }) => {
    const [news, setNews] = useState<Entry<NewsArticleHeader>[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    

    const { t } = useTranslation();
    
    useEffect(() => {
       
        if (park && park.parkCode) {
            setIsLoading(true);
            setHasError(false);
            GetParkCall(park && park.parkCode).then((park)=>{
                GetNewsArticlesCall(park.sys.id,userRoles).then(
                    (result) => {
                        setNews(result.items);
                        setIsLoading(false);
                    },
                    (err) => {
                        setIsLoading(false);
                        setHasError(true);
                    }
                );
            }).catch(() => {
                setIsLoading(false);
                setHasError(true);
                });
            
        }
    }, [park && park.parkCode]);

    return (
        <>
            {isLoading && <Spinner />}
            {!isLoading && hasError && <p className="news-error">{t('myParkAndHome.newsError')}</p>}
            {!isLoading &&
                !hasError &&
                news.map((n, i) => {
                    return <NewsCard news={n} setSelectedNewsItem={setSelectedNewsItem} key={i} />;
                })}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        park: state.user.park,
        userRoles: state.user.userRoles,
    };
};

export default connect(mapStateToProps)(NewsCardContainer);
