import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../RootReducer';
import { Action } from 'redux';
import { Get, Post } from '../../Api/ApiService';
import {
    SEARCH_OFFERS_REQUEST,
    SEARCH_OFFERS_SUCCESS,
    SEARCH_OFFERS_FAILURE,
    SetSelectedCalendarWidgetAction,
    SelectedCalendarWidgetOptions,
    SEARCH_EVENTS_REQUEST,
    SEARCH_EVENTS_SUCCESS,
    SEARCH_EVENTS_FAILURE,
    SetPageNumberAction,
    ADD_BOOKING_REQUEST,
    ADD_BOOKING_SUCCESS,
    ADD_BOOKING_FAILURE,
} from './ActionTypes';
import { PostAddBookingApiCall } from '../../Api/Calls/PostAddBookingApiCall';
import { GetParkEventsCall } from '../../Contentful/Calls/GetParkEventsCall';
import { GetParkOffersCall } from '../../Contentful/Calls/GetParkOffersCall';

export const getEvents =
    (pageSize: number,userRoles: UserRoleDTO[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
        const park = getState().user.park;
        const pageNumber = getState().calendar.pageNumber - 1;

        if (park) {
            dispatch({ type: SEARCH_EVENTS_REQUEST, payload: true });
            GetParkEventsCall(park, pageNumber, pageSize,userRoles)
            .then((response) => {
                dispatch({ type: SEARCH_EVENTS_SUCCESS, payload: response });
            })
            .catch((error) => {
                dispatch({ type: SEARCH_EVENTS_FAILURE, payload: error });
            });
        }
    };

export const getOffers =
    (pageSize: number): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
        const park = getState().user.park;
        const pageNumber = getState().calendar.pageNumber - 1;

        if (park) {
            dispatch({ type: SEARCH_OFFERS_REQUEST, payload: true });
            GetParkOffersCall(park, pageNumber, pageSize)
            .then((response) => {
                dispatch({ type: SEARCH_OFFERS_SUCCESS, payload: response });
            })
            .catch((error) => {
                dispatch({ type: SEARCH_OFFERS_FAILURE, payload: error });
            });
        }
    };

export const setSelectedCalendarWidget =
    (selected: SelectedCalendarWidgetOptions, pageSize: number, userRoles: UserRoleDTO[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
        const currentPage = getState().calendar.pageNumber;

        const setSelected: SetSelectedCalendarWidgetAction = {
            type: 'SET_SELECTED_CALENDAR_WIDGET',
            payload: selected,
        };
        dispatch(setSelected);

        const setPageNumber: SetPageNumberAction = {
            type: 'SET_PAGE_NUMBER',
            payload: 1,
        };
        dispatch(setPageNumber);

        if (currentPage > 1) {
            // fetch the first page again for the filter you're changing from
            // prevents bug where you're on page 2, change filter to first, then change filter again - you still only have 2nd page of items
            if (selected === 'offers') {
                dispatch(getEvents(pageSize,userRoles));
            } else {
                dispatch(getOffers(pageSize));
            }
        }
    };

export const addBooking =
    (booking: BookingDTO): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
        const lodge = getState().user.lodge;

        if (lodge) {
            dispatch({ type: ADD_BOOKING_REQUEST, payload: true });
            Post<{}>(new PostAddBookingApiCall(lodge.LodgeId, { ...booking, AccountId: lodge.AccountId }))
                .then(() => {
                    dispatch({ type: ADD_BOOKING_SUCCESS, payload: true });
                })
                .catch((error) => {
                    dispatch({ type: ADD_BOOKING_FAILURE, payload: error.response.data });
                });
        }
    };
