import React, { useState, useEffect, ChangeEvent } from "react";
import DateInputField from "./FormInputs/DateInputField";
import InputField from "./FormInputs/InputField";
import "./MembershipPageStyles.scss";
import { hasValue, isValidEmail, isValidUKPhoneNumber, isNotFutureDate, isTextOnly, isDateNotInPast, CreateXDaysValidator } from "../../../../Shared/Utilities/Validation";
import { withTranslation, WithTranslation } from "react-i18next";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { Desktop, Mobile } from "../../../../Shared/Components/Presentational/ResponsiveViews";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getDate, getYear, isLeapYear } from "date-fns";
import { Get } from "../../../../Api/ApiService";
import { GetMembershipEndDateCall } from "../../../../Api/Calls/GetMembershipEndDateCall";
import UserRoles from "../../../../Constants/UserRoles";
import { formatDate } from "../../../../Shared/Utilities/DateUtils";

interface PersonalDetailsProps extends WithTranslation {
  onInput: (value: any, name: any, errors: string[]) => void;
  membershipForm: any;
  showErrors: boolean;
  marketingPreferencesText: ContentfulContentObject;
  hasOwnMembership: boolean;
  userRoles: UserRoleDTO[];
  detailsDescription: any;
  membershipExist: boolean;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = (props: PersonalDetailsProps) => {
  const { t } = props;

  let age: number = 0;
  const [input, setInput] = useState(null);
  const [scrollPosition, setscrollPosition] = useState('');
  const [endDate, setEndDate] = useState("");
  const [todayPlus30, settodayPlus30] = useState(new Date());

  useEffect(() => {
    if (todayPlus30) {
      todayPlus30.setDate(todayPlus30.getDate() + 30);
    }
  }, []);

  const getEndDate = async (startDate: Date, termFormula: string): Promise<void> => {
    let endDate = await Get<any>(new GetMembershipEndDateCall(startDate, termFormula))
      .then(response => {
        return response.data
      }
      ).catch(() => {
        return;
      })

    if (endDate) {
      let displayEndDate = formatDate(endDate);
      setEndDate(displayEndDate);
    }

  }

  useEffect(() => {
    if (age && age > 18) {
      if (props.membershipForm && props.membershipForm.marketing && !props.membershipForm.marketing.value) {
        props.onInput(false, "marketing", []);
      }
    } else if (age && age < 18) {
      props.onInput(undefined, "marketing", []);
    }
  }, [props.membershipForm.dateOfBirth.value]);

  useEffect(() => {
    if (props.membershipForm && props.membershipForm.startDate && props.membershipForm.termFormula) {
      getEndDate(props.membershipForm.startDate.value, props.membershipForm.termFormula.value.value)
    }
  }, [props.membershipForm.startDate.value]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const calculateAge = (dateOfBirth: Date): number => {
    let today = new Date();
    let birthDate = new Date(dateOfBirth);
    let ageNow = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      ageNow--;
    }
    return ageNow;
  }

  if (props.membershipForm.dateOfBirth && props.membershipForm.dateOfBirth.value) {
    age = calculateAge(props.membershipForm.dateOfBirth.value);
  } else {
    age = 0;
  }

  function updateValue(e: ChangeEvent<HTMLInputElement>) {
    const valueToBool = e.target.value && e.target.value === "1" ? true : false;
    props.onInput(valueToBool, e.target.name, []);
  }

  function onDOBChange(value: any, name: any, errors: string[]) {
    age = value ? calculateAge(value) : 0
    props.onInput(value, name, errors)
  }

  return (
    <>
      <Desktop>
        <div className="desktopVersion">
          <div className='row' style={{ marginBottom: "25px" }}>
            <div className='float-left'>
              <h2 id="pdTitle" style={{ margin: "0px" }}>{t("addMembership.personalDetails.title")}</h2>
            </div>
          </div>

          <div className="row contentful-text">
            {documentToReactComponents(props.detailsDescription)}
          </div>

          <div className="row">
            <div className="column-md3">
              <InputField
                name="firstName"
                formInput={props.membershipForm.firstName}
                onInput={props.onInput}
                labeltranslationKey="addMembership.personalDetails.fields.firstname"
                containerClass="input"
                showErrors={props.showErrors}
                tabindex={1}
                validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
              />

              {(props.membershipExist || props.hasOwnMembership || UserRoles.IsOwner(props.userRoles)) ? (
                <InputField
                  name="email"
                  formInput={props.membershipForm.email}
                  onInput={props.onInput}
                  labeltranslationKey="addMembership.contactDetails.fields.emailAddress"
                  containerClass="input"
                  tabindex={4}
                  showErrors={props.showErrors}
                  validators={[{ name: "isEmail", validator: isValidEmail }]}
                />
              ) : (
                <div className="end-date-label-desktop">
                  <label className="temp-text">
                    {t("addMembership.contactDetails.fields.emailAddress")}
                  </label>
                  <p className="end-date-text-desktop">
                    {props.membershipForm.email.value}
                  </p>
                </div>
              )}

              <DateInputField
                name="startDate"
                formInput={props.membershipForm.startDate}
                onInput={props.onInput}
                labeltranslationKey="addMembership.personalDetails.fields.membershipStartDate"
                containerClass="input"
                tabindex={6}
                showErrors={props.showErrors}
                validators={[{ name: "isDateNotInPast", validator: isDateNotInPast }, { name: "isDateWithin30Days", validator: CreateXDaysValidator(30) }]}
                minDate={new Date()}
                maxDate={todayPlus30}
              />

            </div>

            <div className="column-md3">

              <InputField
                name="lastName"
                formInput={props.membershipForm.lastName}
                onInput={props.onInput}
                labeltranslationKey="addMembership.personalDetails.fields.lastname"
                containerClass="input"
                tabindex={2}
                showErrors={props.showErrors}
                validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
              />

              <InputField
                name="phonenumber"
                formInput={props.membershipForm.phonenumber}
                onInput={props.onInput}
                labeltranslationKey="addMembership.contactDetails.fields.telephoneNumber"
                containerClass="input"
                tabindex={5}
                showErrors={props.showErrors}
                validators={[{ name: "isPhoneNo", validator: isValidUKPhoneNumber }]}
              />

              <div className="end-date-label-desktop">
                <label className="temp-text">
                  Membership End Date
                </label>
                <p className="end-date-text-desktop">
                  {endDate}
                </p>
              </div>

            </div>

            <div className="column-md3">
              <DateInputField
                name="dateOfBirth"
                formInput={props.membershipForm.dateOfBirth}
                onInput={onDOBChange}
                labeltranslationKey="addMembership.personalDetails.fields.dateOfBirth"
                containerClass="input"
                tabindex={3}
                showErrors={props.showErrors}
                validators={[{ name: "isNotFutureDate", validator: isNotFutureDate }]}
                maxDate={new Date()}
              />
            </div>

          </div>
          <br />
          <div className="row">
            {age >= 18 && (
              <div className="column">
                <div className="pad-preferences">
                  <label className="temp-text">
                    {t("addMembership.preferences.fields.marketingPreferences.label")}
                  </label>
                  <br />
                  <br />
                  <div>
                    {documentToReactComponents(props.marketingPreferencesText.content)}
                  </div>
                  {/* <p>{t("addMembership.preferences.fields.marketingPreferences.description")}</p> */}

                  <div>
                    <input
                      id="marketingTrue"
                      type="radio"
                      value={1}
                      name={"marketing"}
                      defaultChecked={props.membershipForm.marketing && props.membershipForm.marketing.value === true}
                      onChange={updateValue}
                    />
                    <span className="select-text">Yes</span>
                    <input
                      id="marketingFalse"
                      type="radio"
                      value={0}
                      name={"marketing"}
                      defaultChecked={props.membershipForm.marketing.value === undefined || props.membershipForm.marketing && props.membershipForm.marketing.value === false}
                      onChange={updateValue} /><span className="select-text">No</span>
                  </div>
                </div>
                {(props.showErrors && (props.membershipForm.marketing.errors && props.membershipForm.marketing.errors.length > 0)) &&
                  <p>Errors: {props.membershipForm.marketing && props.membershipForm.marketing.errors}</p>
                }
              </div>
            )}
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="mobileVersion">
          <div className='row' style={{ marginBottom: "25px", marginTop: "50px" }}>
            <div className='title-header'>
              <h2 style={{ margin: "0px" }}>{t("addMembership.personalDetails.title")}</h2>
            </div>
          </div>

          <div className="row contentful-text">
            {documentToReactComponents(props.detailsDescription)}
          </div>

          <div className="details">
            <InputField
              name="firstName"
              formInput={props.membershipForm.firstName}
              onInput={props.onInput}
              labeltranslationKey="addMembership.personalDetails.fields.firstname"
              containerClass="input"
              showErrors={props.showErrors}
              validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
            />

            <InputField
              name="lastName"
              formInput={props.membershipForm.lastName}
              onInput={props.onInput}
              labeltranslationKey="addMembership.personalDetails.fields.lastname"
              containerClass="input"
              showErrors={props.showErrors}
              validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
            />

            <DateInputField
              name="dateOfBirth"
              formInput={props.membershipForm.dateOfBirth}
              onInput={props.onInput}
              labeltranslationKey="addMembership.personalDetails.fields.dateOfBirth"
              containerClass="input"
              showErrors={props.showErrors}
              validators={[{ name: "isNotFutureDate", validator: isNotFutureDate }]}
              maxDate={new Date()}
            />

            {(props.hasOwnMembership || UserRoles.IsOwner(props.userRoles)) ? (
              <InputField
                name="email"
                formInput={props.membershipForm.email}
                onInput={props.onInput}
                labeltranslationKey="addMembership.contactDetails.fields.emailAddress"
                containerClass="input"
                showErrors={props.showErrors}
                validators={[{ name: "isEmail", validator: isValidEmail }]}
              />
            ) : (
              <div className="end-date-label">
                <label className="temp-text">
                  {t("addMembership.contactDetails.fields.emailAddress")}
                </label>
                <p className="end-date-text-desktop" style={{ fontSize: "16px" }}>
                  {props.membershipForm.email.value}
                </p>
              </div>
            )}

            <InputField
              name="phonenumber"
              formInput={props.membershipForm.phonenumber}
              onInput={props.onInput}
              labeltranslationKey="addMembership.contactDetails.fields.telephoneNumber"
              containerClass="input"
              showErrors={props.showErrors}
              validators={[{ name: "isPhoneNo", validator: isValidUKPhoneNumber }]}
            />

            <DateInputField
              name="startDate"
              formInput={props.membershipForm.startDate}
              onInput={props.onInput}
              labeltranslationKey="addMembership.personalDetails.fields.membershipStartDate"
              containerClass="input"
              showErrors={props.showErrors}
              validators={[{ name: "isDateNotInPast", validator: isDateNotInPast }, { name: "isDateWithin30Days", validator: CreateXDaysValidator(30) }]}
              minDate={new Date()}
              maxDate={todayPlus30}
            />

            <div className="end-date-label">
              <label className="temp-text">
                Membership End Date
              </label>
              <p className="end-date-text">
                {endDate}
              </p>
            </div>

            {age >= 18 && (
              <div style={{ marginTop: "15px" }}>
                <div>
                  <label className="temp-text">
                    {t("addMembership.preferences.fields.marketingPreferences.label")}
                  </label>
                  <br />
                  <br />
                  <div>
                    {documentToReactComponents(props.marketingPreferencesText.content)}
                  </div>
                  {/* <p>{t("addMembership.preferences.fields.marketingPreferences.description")}</p> */}

                  <div>
                    <input
                      id="marketingTrue"
                      type="radio"
                      value={1}
                      name={"marketing"}
                      defaultChecked={props.membershipForm.marketing && props.membershipForm.marketing.value === true}
                      onChange={updateValue}
                    />
                    <span className="select-text">Yes</span>
                    <input
                      id="marketingFalse"
                      type="radio"
                      value={0}
                      name={"marketing"}
                      defaultChecked={props.membershipForm.marketing.value === undefined || props.membershipForm.marketing && props.membershipForm.marketing.value === false}
                      onChange={updateValue} /><span className="select-text">No</span>
                  </div>

                </div>
                {(props.showErrors && (props.membershipForm.marketing.errors && props.membershipForm.marketing.errors.length > 0)) &&
                  <p>Errors: {props.membershipForm.marketing && props.membershipForm.marketing.errors}</p>
                }
              </div>
            )}

          </div>
        </div>
      </Mobile>
    </>
  );
}

export default withTranslation()(PersonalDetails);