import React, { useEffect, useState } from 'react';
import { hasValue, isTextOnly, isValidEmail, isNotFutureDate, isValidUKPhoneNumber } from '../../../../Shared/Utilities/Validation';
import InputField from '../AddMembershipSubPages/FormInputs/InputField';
import DateInputField from '../AddMembershipSubPages/FormInputs/DateInputField';
import { Desktop, Mobile } from '../../../../Shared/Components/Presentational/ResponsiveViews';

interface PersonalDetailsFormProps {
  showErrors?: boolean;
  existingDetails: MemberPersonalDetailsDTO;
  returnValue: (value: MemberPersonalDetailsDTO) => void;
  returnErrorState: (hasError: boolean) => void;
  validationError: string | undefined;
}

interface PersonalDetailsFormFields {
  FirstName: FormInput;
  LastName: FormInput;
  Email: FormInput;
  PhoneNumber: FormInput;
  DateOfBirth: FormInput;
}

const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = (props: PersonalDetailsFormProps) => {
  const [form, setForm] = useState<PersonalDetailsFormFields>();

  useEffect(() => {
    if (props.existingDetails) {
      setForm(dtoToForm(props.existingDetails));
    }
  }, [])

  useEffect(() => {
    if (form) {
      props.returnValue(formToDto(form));
      let isFormError = false;
      let k: keyof typeof form;
      for (k in form) {
        if (form[k].errors.length > 0)
          isFormError = true;
      }
      props.returnErrorState(isFormError);
    }
  }, [form])

  useEffect(() => {
    if (props.validationError) {
      if (props.validationError === "duplicateEmail") {
        let email: FormInput = form!.Email;
        email.errors.push(props.validationError);
        setForm({ ...form!, Email: email});
      }
      else if (props.validationError === "duplicateMobile") {
        let phoneNumber: FormInput = form!.PhoneNumber;
        phoneNumber.errors.push(props.validationError);
        setForm({ ...form!, PhoneNumber: phoneNumber});
      }
    }
  }, [props.validationError]);

  const dtoToForm = (dto: MemberPersonalDetailsDTO) => {
    const form = {} as PersonalDetailsFormFields;
    form.FirstName = { errors: [], value: dto.FirstName };
    form.LastName = { errors: [], value: dto.LastName };
    form.Email = { errors: [], value: dto.Email };
    form.PhoneNumber = { errors: [], value: dto.PhoneNumber };
    form.DateOfBirth = { errors: [], value: dto.DateOfBirth };
    return form;
  }

  const formToDto = (form: PersonalDetailsFormFields) => {
    const dto = {} as MemberPersonalDetailsDTO;
    dto.FirstName = form.FirstName.value;
    dto.LastName = form.LastName.value;
    dto.Email = form.Email.value;
    dto.PhoneNumber = form.PhoneNumber.value;
    dto.DateOfBirth = form.DateOfBirth.value;
    return dto;
  }

  return (
    <>
      {form &&
        <div>
          <Desktop>
            <div className="row">
              <div className="column-md3">
                <InputField
                  name="FirstName"
                  formInput={form.FirstName}
                  onInput={(value: any, fieldName: string, errors: string[]) => setForm({ ...form, FirstName: { value: value, errors: errors } })}
                  labeltranslationKey="addMembership.personalDetails.fields.firstname"
                  containerClass="input"
                  showErrors={props.showErrors}
                  validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
                />

                <InputField
                  name="LastName"
                  formInput={form.LastName}
                  onInput={(value: any, fieldName: string, errors: string[]) => setForm({ ...form, LastName: { value: value, errors: errors } })}
                  labeltranslationKey="addMembership.personalDetails.fields.lastname"
                  containerClass="input"
                  showErrors={props.showErrors}
                  validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
                />
              </div>

              <div className="column-md3">
                <InputField
                  name="Email"
                  formInput={form.Email}
                  onInput={(value: any, fieldName: string, errors: string[]) => setForm({ ...form, Email: { value: value, errors: errors } })}
                  labeltranslationKey="addMembership.contactDetails.fields.emailAddress"
                  containerClass="input"
                  showErrors={props.showErrors}
                  validators={[{ name: "hasValue", validator: hasValue }, { name: "isEmail", validator: isValidEmail }]}
                />

                <InputField
                  name="Phonenumber"
                  formInput={form.PhoneNumber}
                  onInput={(value: any, fieldName: string, errors: string[]) => setForm({ ...form, PhoneNumber: { value: value, errors: errors } })}
                  labeltranslationKey="addMembership.contactDetails.fields.telephoneNumber"
                  containerClass="input"
                  showErrors={props.showErrors}
                  validators={[{ name: "isPhoneNo", validator: isValidUKPhoneNumber }]}
                />
              </div>

              <div className="column-md3">
                <DateInputField
                  name="DateOfBirth"
                  formInput={form.DateOfBirth}
                  onInput={(value: any, name: any, errors: string[]) => setForm({ ...form, DateOfBirth: { value: value, errors: errors } })}
                  labeltranslationKey="addMembership.personalDetails.fields.dateOfBirth"
                  containerClass="input"
                  tabindex={3}
                  showErrors={props.showErrors}
                  validators={[{ name: "isNotFutureDate", validator: isNotFutureDate }]}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </Desktop>

          <Mobile>
            <div className="details">
              <InputField
                name="FirstName"
                formInput={form.FirstName}
                onInput={(value: any, fieldName: string, errors: string[]) => setForm({ ...form, FirstName: { value: value, errors: errors } })}
                labeltranslationKey="addMembership.personalDetails.fields.firstname"
                containerClass="input"
                showErrors={props.showErrors}
                validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
              />

              <InputField
                name="LastName"
                formInput={form.LastName}
                onInput={(value: any, fieldName: string, errors: string[]) => setForm({ ...form, LastName: { value: value, errors: errors } })}
                labeltranslationKey="addMembership.personalDetails.fields.lastname"
                containerClass="input"
                showErrors={props.showErrors}
                validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
              />

              <InputField
                name="Email"
                formInput={form.Email}
                onInput={(value: any, fieldName: string, errors: string[]) => setForm({ ...form, Email: { value: value, errors: errors } })}
                labeltranslationKey="addMembership.contactDetails.fields.emailAddress"
                containerClass="input"
                showErrors={props.showErrors}
                validators={[{ name: "hasValue", validator: hasValue }, { name: "isEmail", validator: isValidEmail }]}
              />

              <InputField
                name="Phonenumber"
                formInput={form.PhoneNumber}
                onInput={(value: any, fieldName: string, errors: string[]) => setForm({ ...form, PhoneNumber: { value: value, errors: errors } })}
                labeltranslationKey="addMembership.contactDetails.fields.telephoneNumber"
                containerClass="input"
                showErrors={props.showErrors}
                validators={[{ name: "isPhoneNo", validator: isValidUKPhoneNumber }]}
              />

              <DateInputField
                name="DateOfBirth"
                formInput={form.DateOfBirth}
                onInput={(value: any, name: any, errors: string[]) => setForm({ ...form, DateOfBirth: { value: value, errors: errors } })}
                labeltranslationKey="addMembership.personalDetails.fields.dateOfBirth"
                containerClass="input"
                tabindex={3}
                showErrors={props.showErrors}
                validators={[{ name: "isNotFutureDate", validator: isNotFutureDate }]}
                maxDate={new Date()}
              />
            </div>
          </Mobile>
        </div>



      }
    </>
  );
};

export default (PersonalDetailsForm);
