import React from 'react';
import { CustomColumnCellRendererProps } from '../../../Shared/Components/Presentational/Table';
import './TransactionTypeMobileCellRenderer.scss';
import TransactionItem from '../../../Home/Components/Presentational/TransactionItem';

const TransactionTypeMobileCellRenderer: React.FC<CustomColumnCellRendererProps> = ({ rowValue }) => {
    return <TransactionItem transaction={rowValue} showDownload />;
};

export default TransactionTypeMobileCellRenderer;
