import React, { Component } from 'react';
import './DropdownList.scss';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';

interface DropdownListProps extends WithTranslation {
    options: DropdownListOption[];
    selectedOption?: DropdownListOption;
    className?: string;
    classNamePrefix?: string;
    onSelectionChanged?: (newValue: DropdownListOption) => void;
    placeholder?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
}

const styles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? '1px solid #45417B' : '1px solid #E0E7EE',
        boxShadow: state.isFocused ? '0 2px 4px 0 #C2BFDE' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? '#45417B' : '#E0E7EE'
        }
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: '#a0a0a0'
    }),
    option: (provided: any) => ({
        ...provided,
        backgroundColor: 'none',
        '&:focus': {
            backgroundColor: '#F5F6FB'
        },
        '&:hover': {
            backgroundColor: '#F5F6FB'
        }
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: '#184f6d'
    })
};

class DropdownList extends Component<DropdownListProps> {
    constructor(props: DropdownListProps) {
        super(props);

        this.selectionChanged = this.selectionChanged.bind(this);
    }

    public render(): JSX.Element {
        const { t } = this.props;

        const translateOptions = (options: DropdownListOption[]): DropdownListOption[] => {
            options.forEach((o) => (o.label = t(o.label)));
            return options;
        }

        return (
            <div className={classNames('select-container', this.props.className)}>
                <Select
                    classNamePrefix={this.props.classNamePrefix ? this.props.classNamePrefix : ''}
                    defaultValue={this.props.selectedOption && this.props.selectedOption}
                    key={
                        this.props.selectedOption
                            ? this.props.selectedOption.value
                            : this.props.options[0]
                            ? this.props.options[0].value
                            : 'dropdown'
                    }
                    options={translateOptions(this.props.options)}
                    onChange={this.selectionChanged}
                    isSearchable={false}
                    hideSelectedOptions={true}
                    placeholder={this.props.placeholder}
                    styles={styles}
                    isDisabled={this.props.isDisabled}
                    isLoading={this.props.isLoading}
                />
            </div>
        );
    }

    private selectionChanged(newValue: ValueType<{ value: string; label: string }>, actionMeta: any) {
        if (this.props.onSelectionChanged) {
            this.props.onSelectionChanged(newValue as DropdownListOption);
        }
    }
}

export default withTranslation()(DropdownList);