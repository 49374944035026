import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../../Shared/Components/Presentational/Buttons";
import { Modal, ModalHeader, ModalProps } from "../../../Shared/Components/Presentational/Modal";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import * as Routes from "../../../Constants/Routes";

interface ErrorPromptModalProps extends ModalProps {
    isOpen: boolean;
    setErrorModalOpen: (isOpen: boolean) => void;
}

const ErrorPromptModal: React.FC<ErrorPromptModalProps> = (props) => {
    const { t } = useTranslation();
    const open = props.isOpen;
    const setErrorModalOpen = props.setErrorModalOpen;
    const handleClick1 = async () => {
        setErrorModalOpen(false);
    }
  
    const formContents = (
      <div className='button-container-modal'>
        <div className='container' style={{textAlign: "center"}}>
          <ModalHeader text={"Referral request failed"} />
          <div>
            <p>It looks like something has gone wrong, please try again later.</p>
          </div>
            <Link to={Routes.MyGuests}>
              <SecondaryButton
                  id="home"
                  text={"Home"}
                  className="footer-button"
                  onClick={handleClick1}
              />
            </Link>
              <PrimaryButton
                  id="try"
                  text={"Try Again"}
                  className="footer-button"
                  onClick={handleClick1}
              />
        </div>
      </div>
    )
  
    return (
      <>
        
          <Desktop>
            
            <Modal
              isOpen={open}
              className={classNames("owner-contact-details-modal", props.className)}
            >
              <form>{formContents}</form>
            </Modal>
          </Desktop>
          <Mobile>
            <Modal
              isOpen={open}
              className={classNames("owner-contact-details-modal-mobile-form", props.className)}
            >
              {formContents}
            </Modal>
          </Mobile>
      </>
    );
}

export default ErrorPromptModal;