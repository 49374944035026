import React from 'react';
import { CustomColumnCellRendererProps } from '../../../Shared/Components/Presentational/Table';
import './PassNameMobileColumnCellRenderer.scss';
import PassFunctionalityTypes from '../../../Constants/PassFunctionalityTypes';
import PassStatus from '../../../Constants/PassStatus';

export default function PassNameMobileColumnCellRenderer(props: CustomColumnCellRendererProps) {
    const filter = props.filter as PassFilterDTO;

    const { clickAction, rowValue } = props;
    const isSeasonPass =
        filter.FunctionalityCode === PassFunctionalityTypes.SeasonOwner ||
        filter.FunctionalityCode === PassFunctionalityTypes.SeasonResort;

    const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        // we need the check for status code here again because the checkbox is still able to be checked if the
        // opacity is set to 0. We need to set opacity to make sure the text is always aligned for rows with or
        // without checkboxes
        if (rowValue.StatusCode === PassStatus.Expired && clickAction) {
            clickAction(rowValue);
        }
    };

    return (
        <div className="pass-cell">
            {isSeasonPass && (
                <input
                    className="renew-checkbox"
                    name="renewPass"
                    id={`renewPass-${rowValue.Id}`}
                    type="checkbox"
                    onChange={onCheck}
                    style={{ opacity: rowValue.StatusCode === PassStatus.Expired ? 1 : 0 }}
                />
            )}

            <div>
                <p className="pass-date">
                    {filter.FunctionalityCode === PassFunctionalityTypes.Car
                        ? props.rowValue.RegistrationNumber
                        : props.rowValue.PassDates}
                </p>
                <p className="pass-name">{props.rowValue.Name}</p>
            </div>
        </div>
    );
}
