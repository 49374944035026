import {
  SEARCH_PASSES_REQUEST,
  SEARCH_PASSES_SUCCESS,
  SEARCH_PASSES_FAILURE,
  SET_PASSES_FILTER,
  GET_PARK_PASS_TYPE_REQUEST,
  GET_PARK_PASS_TYPE_SUCCESS,
  GET_PARK_PASS_TYPE_FAILURE,
  SET_SELECTED_PARK_PASS_TYPE,
  SET_IS_ADD_PASS_MODAL_OPEN,
  SET_IS_ADD_PASS_PHOTO_MODAL_OPEN,
  SET_ADD_PASS_SUCCESSFUL_MODAL_OPEN,
  SET_ADD_PASS_ERROR_MODAL_OPEN,
  SET_IS_RENEW_PASS_MODAL_OPEN,
  SET_RENEW_PASS_SUCCESSFUL_MODAL_OPEN,
  SET_RENEW_PASS_ERROR_MODAL_OPEN,
  ADD_SEASON_PASS_REQUEST,
  ADD_SEASON_PASS_SUCCESS,
  ADD_SEASON_PASS_FAILURE,
  ADD_SHORT_STAY_PASS_REQUEST,
  ADD_SHORT_STAY_PASS_SUCCESS,
  ADD_SHORT_STAY_PASS_FAILURE,
  SET_DELETE_PASS_CONFIRMATION_MODAL_OPEN,
  RENEW_SEASON_PASS_REQUEST,
  RENEW_SEASON_PASS_SUCCESS,
  RENEW_SEASON_PASS_FAILURE,
  ANALYZE_PASS_PHOTO_REQUEST,
  ANALYZE_PASS_PHOTO_SUCCESS,
  ANALYZE_PASS_PHOTO_FAILURE,
  GET_PASS_PHOTO_SIZE_LIMIT_REQUEST,
  GET_PASS_PHOTO_SIZE_LIMIT_FAILURE,
  GET_PASS_PHOTO_SIZE_LIMIT_SUCCESS,
  ADD_MEMBERSHIP_REQUEST,
  ADD_MEMBERSHIP_SUCCESS,
  ADD_MEMBERSHIP_FAILURE,
  CHANGE_MEMBERSHIP_FORM_PAGE as CHANGE_MEMBERSHIP_FORM_PAGE,
  UPDATE_REFER_MEMBERSHIP_FORM_PAGE,
  SAVE_AND_EXIT,
  UPDATE_MEMBERSHIP_FORM,
  UPDATE_NEW_MEMBERSHIP,
  GET_MY_MEMBERSHIPS_FAILURE,
  GET_MY_MEMBERSHIPS_REQUEST,
  GET_MY_MEMBERSHIPS_SUCCESS,
  GET_ADD_MEMBERSHIP_BY_ID_REQUEST,
  GET_ADD_MEMBERSHIP_BY_ID_SUCCESS,
  GET_ADD_MEMBERSHIP_BY_ID_FAILURE,
  UPDATE_MEMBERSHIP_BASKET,
  UPDATE_MEMBERSHIP_FORM_PAGE,
  START_NEW_MEMBERSHIP,
  TOGGLE_SHOW_DRAFT_MEMBERSHIPS,
  TOGGLE_SHOW_EXPIRED_MEMBERSHIPS,
  UPDATE_FILTERED_MEMBERSHIPS,
  ADD_AGE_VERIFICATION_ERROR,
  ADD_AGE_VERIFICATION_REQUEST,
  ADD_AGE_VERIFICATION_SUCCESS,
  GET_EDIT_MEMBERSHIP_BY_ID_FAILURE,
  GET_EDIT_MEMBERSHIP_BY_ID_REQUEST,
  GET_EDIT_MEMBERSHIP_BY_ID_SUCCESS,
  UPDATE_EDIT_MEMBERSHIP,
  UPDATE_EDIT_MEMBERSHIP_FORM,
  UPDATE_EDIT_MEMBERSHIP_REQUEST,
  UPDATE_EDIT_MEMBERSHIP_SUCCESS,
  UPDATE_EDIT_MEMBERSHIP_FAILURE,
  CREATE_MEMBERSHIP_BASKET_REQUEST,
  CREATE_MEMBERSHIP_BASKET_SUCCESS,
  CREATE_MEMBERSHIP_BASKET_FAILURE,
  SET_SESSION_ID,
  SET_DOCU_SIGN_MESSAGE,
  SET_PAYMENT_SUMMARY_DTO,
  SET_MEMBERSHIP_COMPONENTS,
  SET_REFERRED_MEMBERSHIP_COMPONENTS,
  SET_FINALISE_LATER_CONTAINERS,
  SET_FINALISE_NOW_CONTAINERS,
  SET_MEMBERSHIP_TO_DELETE,
  SET_DELETE_MEMBERSHIP_MODAL_OPEN,
  SET_CHECKOUT_SESSION,
  SET_PAYMENT_REQUIRED,
  SET_CONTENTFUL_MEMBERSHIP_TYPES,
  GET_MY_REFERRAL_FAILURE,
  GET_MY_REFERRAL_REQUEST,
  GET_MY_REFERRAL_SUCCESS,
  SET_TERM_DURATIONS,
} from './ActionTypes';
import { SET_SELECTED_LODGE } from '../../User/Redux/ActionTypes';
import { ColumnDefinition } from '../../Shared/Components/Presentational/Table';
import { MembershipForm } from '../../MembershipForm';
import { faLeaf, fas, faSearch } from '@fortawesome/free-solid-svg-icons';
import { EditMemberDetailsForm } from '../../EditMemberDetailsForm';
import { ReferMembershipForm } from '../../ReferMembershipForm';
import { CheckoutStates } from '../../Constants/CheckoutStates';
import { Entry } from 'contentful';

const initialState = {
  passes: {
    Dtos: [],
    Total: 0,
  },
  passesFilter: {
    Page: 1,
    PageSize: 20,
    SortField: 'Name',
    SortDirection: 'asc',
  } as PassFilterDTO,
  isLoadingParkPassTypes: undefined as any,
  selectedParkPassType: {
    Id: undefined,
  },
  parkPassTypes: [],
  PassPhotoSizeLimitInKb: undefined,
  analyzingPassPhotoError: undefined,
  parkPassTypeError: undefined,
  passesSearchError: undefined,
  isSearchingPasses: undefined as any,
  successfulPasses: [],
  isAddPassModalOpen: false,
  isAddPassPhotoModalOpen: false,
  isSavingPass: false,
  isFetchingPassPhotoSizeLimit: false,
  isAnalyzingPassPhoto: false,
  isAddPassSuccessfulModalOpen: false,
  isAddPassErrorModalOpen: false,
  isRenewPassModalOpen: false,
  isRenewPassSuccessfulModalOpen: false,
  isRenewPassErrorModalOpen: false,
  isDeletePassModalOpen: false,
  isAnalyzingPassPhotoSuccessful: false,
  // ** MEMBERSHIP **
  isAddingMembership: false,
  addingMembershipError: false,
  addingMembershipErrorMessage: undefined as string | undefined,
  membershipFormPage: 0,
  referMembershipFormPage: 0,
  newMembership: {
    membershipState: "Initial",
    transferredMemberships: 0,
  } as NewMembershipDTO,
  referMembership: {
    transferredMemberships: 0
  } as ReferMembershipDTO,
  membershipForm: new MembershipForm() as IMembershipForm,
  membershipColumns: [
    {
      fieldName: "firstName",
      displayName: "myMemberships.firstName",
    },
    {
      fieldName: "lastName",
      displayName: "myMemberships.lastName",
    },
    {
      fieldName: "contentfulMembershipTypeId",
      displayName: "myMemberships.membershipType",
    },
    {
      fieldName: "membershipState",
      displayName: "myMemberships.membershipState",
    },
    {
      fieldName: "endDate",
      displayName: "myMemberships.endDate",
    },
    {
      fieldName: "editIcon",
      displayName: "",      
    }
  ] as ColumnDefinition[],
  //My Memberships Page
  isGettingMemberships: false,
  gettingMembershipsFailure: false,
  memberships: [] as MembershipDTO[],
  isGettingReferredMemberships: false,
  gettingReferredMembershipsFailure: false,
  filteredMemberships: [] as MembershipDTO[],
  isGettingAddMembershipById: false,
  gettingAddMembershipByIdError: false,
  gettingAddMembershipByIdSuccess: false,
  membershipBasket: [] as MembershipDTO[],
  basketId: "",
  showDraftMemberships: true,
  showExpiredMemberships: false,
  ageVerificationFile: File,
  isAddingAgeVerificationImage: false,
  addingAgeVerificationImageSuccess: false,
  addingAgeVerificationImageError: false,
  addingAgeVerificationImageErrorMessage: "",
  editMemberDetails: {} as EditMemberDetailsDTO,
  editMemberDetailsForm: new EditMemberDetailsForm() as IEditMemberDetailsForm,
  isGettingEditMemberDetailsById: false,
  gettingEditMemberDetailsByIdSuccess: false,
  gettingEditMemberDetailsByIdError: false,
  isUpdatingEditMemberDetails: false,
  updateEditMemberDetailsSuccess: false,
  updateEditMemberDetailsFailure: false,
  isCreatingMembershipBasket: false,
  createMembershipBasketSuccess: false,
  createMembershipBasketFailure: false,
  validateAndUploadPhoto: false,
  sessionId: "",
  docuSignMessage: "",
  membershipContainers: [] as MembershipContainer[],
  finaliseNowContainers: [] as MembershipContainer[],
  finaliseLaterContainers: [] as MembershipContainer[],
  referredMemberships:[] as ReferralDTO[],
  paymentSummary: undefined,
  checkoutSession: { sessionId: "", state: CheckoutStates.Expired } as CheckoutSession,
  isPaymentRequired: false,
  contentfulMembershipTypes: undefined,
  useMemberships: false,
  termDurations: undefined as DropdownListOption[][] | undefined,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SEARCH_PASSES_REQUEST:
      return { ...state, isSearchingPasses: action.payload };
    case SEARCH_PASSES_SUCCESS:
      return { ...state, passes: action.payload, isSearchingPasses: false };
    case SEARCH_PASSES_FAILURE:
      return { ...state, passesSearchError: action.payload, isSearchingPasses: false };
    case SET_PASSES_FILTER:
      return { ...state, passesFilter: action.payload, isSearchingPasses: undefined as any };
    case GET_PARK_PASS_TYPE_REQUEST:
      return { ...state, isLoadingParkPassTypes: action.payload };
    case GET_PARK_PASS_TYPE_SUCCESS:
      return { ...state, parkPassTypes: action.payload, isLoadingParkPassTypes: false };
    case GET_PARK_PASS_TYPE_FAILURE:
      return { ...state, parkPassTypeError: action.payload, isLoadingParkPassTypes: false };
    case SET_SELECTED_PARK_PASS_TYPE:
      return {
        ...state,
        selectedParkPassType: action.payload,
        passesFilter: {
          ...state.passesFilter,
          ParkPassTypeId: action.payload ? action.payload.Id : undefined,
          FunctionalityCode: action.payload ? action.payload.FunctionalityCode : undefined,
          Page: 1,
        },
        isSearchingPasses: undefined as any,
      };
    case SET_IS_ADD_PASS_MODAL_OPEN:
      return { ...state, isAddPassModalOpen: action.payload };
    case SET_IS_ADD_PASS_PHOTO_MODAL_OPEN:
      return { ...state, isAddPassPhotoModalOpen: action.payload };
    case SET_IS_RENEW_PASS_MODAL_OPEN:
      return { ...state, isRenewPassModalOpen: action.payload };
    case ANALYZE_PASS_PHOTO_REQUEST:
      return { ...state, isAnalyzingPassPhoto: true };
    case GET_PASS_PHOTO_SIZE_LIMIT_REQUEST:
      return { ...state, isFetchingPassPhotoSizeLimit: true };
    case ADD_SEASON_PASS_REQUEST:
    case ADD_SHORT_STAY_PASS_REQUEST:
    case RENEW_SEASON_PASS_REQUEST:
      return { ...state, isSavingPass: true };
    case ANALYZE_PASS_PHOTO_SUCCESS:
      return {
        ...state,
        isAnalyzingPassPhoto: false,
        isAnalyzingPassPhotoSuccessful: true,
        analyzingPassPhotoError: undefined,
      };
    case GET_PASS_PHOTO_SIZE_LIMIT_SUCCESS:
      return {
        ...state,
        PassPhotoSizeLimitInKb: action.payload,
        isFetchingPassPhotoSizeLimit: false
      };
    case GET_PASS_PHOTO_SIZE_LIMIT_FAILURE:
      return {
        ...state,
        isFetchingPassPhotoSizeLimit: false
      };
    case ANALYZE_PASS_PHOTO_FAILURE:
      return {
        ...state,
        isAnalyzingPassPhoto: false,
        isAnalyzingPassPhotoSuccessful: false,
        analyzingPassPhotoError: action.payload,
      };
    case ADD_SEASON_PASS_SUCCESS:
    case ADD_SHORT_STAY_PASS_SUCCESS:
    case RENEW_SEASON_PASS_SUCCESS:
      return {
        ...state,
        isSavingPass: false,
        successfulPasses: action.payload,
        isSearchingPasses: undefined as any,
        isLoadingParkPassTypes: undefined as any,
      };
    case ADD_SEASON_PASS_FAILURE:
    case ADD_SHORT_STAY_PASS_FAILURE:
    case RENEW_SEASON_PASS_FAILURE:
      return { ...state, isSavingPass: false };
    case SET_ADD_PASS_SUCCESSFUL_MODAL_OPEN:
      return {
        ...state,
        isAddPassSuccessfulModalOpen: action.payload,
        successfulPasses: action.payload ? state.successfulPasses : []
      };
    case SET_ADD_PASS_ERROR_MODAL_OPEN:
      return { ...state, isAddPassErrorModalOpen: action.payload };
    case SET_RENEW_PASS_SUCCESSFUL_MODAL_OPEN:
      return { ...state, isRenewPassSuccessfulModalOpen: action.payload };
    case SET_RENEW_PASS_ERROR_MODAL_OPEN:
      return { ...state, isRenewPassErrorModalOpen: action.payload };
    case SET_DELETE_PASS_CONFIRMATION_MODAL_OPEN:
      return { ...state, isDeletePassModalOpen: action.payload };
    case SET_SELECTED_LODGE:
      return {
        ...state,
        passesFilter: {
          ...state.passesFilter,
          LodgeId: action.payload.LodgeId,
          AccountId: action.payload.AccountId,
          Page: 1,
        },
      };
    // ** MEMBERSHIP **
    case ADD_MEMBERSHIP_REQUEST:
      return {
        ...state,
        isAddingMembership: action.payload,
        addingMembershipError: false,
      }
    case ADD_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        isAddingMembership: false,
        newMembership: action.payload,
        addingMembershipError: false,
      }
    case ADD_MEMBERSHIP_FAILURE:
      return {
        ...state,
        isAddingMembership: true,
        addingMembershipError: true,
        addingMembershipErrorMessage: action.payload,
      }
    case CHANGE_MEMBERSHIP_FORM_PAGE:
      return {
        ...state,
        membershipFormPage: action.payload,
      }
    case UPDATE_REFER_MEMBERSHIP_FORM_PAGE:
      return {
        ...state,
        referMembershipFormPage: action.payload,
      }
    case SAVE_AND_EXIT:
      return {
        ...state,
        newMembership: {
          membershipState: "Initial",
          transferredMemberships: 0,
        } as NewMembershipDTO,
        referMembership: {
          transferredMemberships: 0
        } as ReferMembershipDTO,
        membershipForm: new MembershipForm(),
        ReferMembershipForm: new ReferMembershipForm(),
        membershipBasket: [],
        membershipFormPage: 0,
        referMembershipFormPage: 0,
        addingAgeVerificationImageSuccess: false,
      }
    case UPDATE_NEW_MEMBERSHIP:
      return {
        ...state,
        newMembership: action.payload
      }
    case UPDATE_MEMBERSHIP_FORM:
      return {
        ...state,
        membershipForm: action.payload
      }
    case ADD_AGE_VERIFICATION_REQUEST:
      return {
        ...state,
        isAddingAgeVerifcationImage: true,
        addingAgeVerificationImageSuccess: false,
        addingAgeVerificationImageError: false,
      }
    case ADD_AGE_VERIFICATION_SUCCESS:
      return {
        ...state,
        isAddingAgeVerificationImage: false,
        addingAgeVerificationImageSuccess: true,
        addingAgeVerificationImageError: false,
        ageVerificationFile: action.payload,
      }
    case ADD_AGE_VERIFICATION_ERROR:
      return {
        ...state,
        isAddingAgeVerificationImage: false,
        addingAgeVerificationImageSuccess: false,
        addingAgeVerificationImageError: true,
        addingAgeVerificationImageErrorMessage: action.payload,
      }
    //My Memberships Page
    case GET_MY_MEMBERSHIPS_REQUEST:
      return {
        ...state,
        gettingMembershipsError: false,
        isGettingMemberships: true,
        gettingMembershipsSuccess: false,
      }
    case GET_MY_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        memberships: action.payload as MembershipDTO[],
        gettingMembershipsSuccess: true,
      }
    case GET_MY_MEMBERSHIPS_FAILURE:
      return {
        ...state,
        gettingMembershipsError: true,
        isGettingMemberships: false,
        gettingMembershipsSuccess: false,
      }
    case GET_MY_REFERRAL_REQUEST:
      return {
        ...state,
        gettingReferredMembershipsError: false,
        isGettingReferredMemberships: true,
        gettingReferredMembershipsSuccess: false,
      }
    case GET_MY_REFERRAL_SUCCESS:
      return {
        ...state,
        referredMemberships: action.payload as ReferralDTO[],
        gettingReferredMembershipsSuccess: true,
      }
    case GET_MY_REFERRAL_FAILURE:
      return {
        ...state,
        gettingReferredMembershipsError: true,
        isGettingReferredMemberships: false,
        gettingReferredMembershipsSuccess: false,
      } 
    case GET_ADD_MEMBERSHIP_BY_ID_REQUEST:
      return {
        ...state,
        isGettingAddMembershipById: true,
        gettingAddMembershipByIdError: false,
        gettingAddMembershipByIdSuccess: false,
      }
    case GET_ADD_MEMBERSHIP_BY_ID_SUCCESS:

      return {
        ...state,
        isGettingAddMembershipById: false,
        newMembership: action.payload.newMembership as NewMembershipDTO,
        membershipForm: action.payload.membershipForm,
        gettingAddMembershipByIdSuccess: true,
      }
    case GET_ADD_MEMBERSHIP_BY_ID_FAILURE:
      return {
        ...state,
        isGettingAddMembershipById: false,
        gettingAddMembershipByIdError: true,
        gettingAddMembershipByIdSuccess: false,
      }
    case UPDATE_MEMBERSHIP_BASKET:
      return {
        ...state,
        membershipBasket: action.payload,
      }
    case UPDATE_MEMBERSHIP_FORM_PAGE:
      return {
        ...state,
        membershipFormPage: action.payload,
      }
    case START_NEW_MEMBERSHIP:
      return {
        ...state,
        membershipForm: new MembershipForm(),
        newMembership: {
          membershipState: "Initial",
          transferredMemberships: 0,
        } as NewMembershipDTO,
        membershipFormPage: 0,
      }
    case TOGGLE_SHOW_DRAFT_MEMBERSHIPS:
      return {
        ...state,
        showDraftMemberships: action.payload,
      }
    case TOGGLE_SHOW_EXPIRED_MEMBERSHIPS:
      return {
        ...state,
        showExpiredMemberships: action.payload,
      }
    case UPDATE_FILTERED_MEMBERSHIPS:
      return {
        ...state,
        filteredMemberships: action.payload,
      }
    case GET_EDIT_MEMBERSHIP_BY_ID_REQUEST:
      return {
        ...state,
        isGettingEditMemberDetailsById: true,
        gettingEditMemberDetailsByIdError: false,
        gettingEditMemberDetailsByIdSuccess: false,
      }
    case GET_EDIT_MEMBERSHIP_BY_ID_SUCCESS:
      return {
        ...state,
        isGettingEditMemberDetailsById: false,
        editMemberDetails: action.payload.editMemberDetails as NewMembershipDTO,
        editMemberDetailsForm: action.payload.editMemberDetailsForm,
        gettingEditMemberDetailsByIdSuccess: true,
      }
    case GET_EDIT_MEMBERSHIP_BY_ID_FAILURE:
      return {
        ...state,
        isGettingEditMemberDetailsById: false,
        gettingEditMemberDetailsByIdError: true,
        gettingEditMemberDetailsByIdSuccess: false,
      }
    case UPDATE_EDIT_MEMBERSHIP:
      return {
        ...state,
        editMemberDetails: action.payload
      }
    case UPDATE_EDIT_MEMBERSHIP_FORM:
      return {
        ...state,
        editMemberDetailsForm: action.payload,
      }
    case UPDATE_EDIT_MEMBERSHIP_REQUEST:
      return {
        ...state,
        isUpdatingEditMemberDetails: true,
        updateEditMemberDetailsSuccess: false,
        updateEditMemberDetailsFailure: false,
      }
    case UPDATE_EDIT_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        isUdpatingEditMemberDetails: false,
        updateEditMemberDetailssuccess: true,
      }
    case UPDATE_EDIT_MEMBERSHIP_FAILURE:
      return {
        ...state,
        isUpdatingEditMemberDetails: false,
        updateEditMemberDetailsFailure: true,
      }
    case CREATE_MEMBERSHIP_BASKET_REQUEST:
      return {
        ...state,
        isCreatingMembershipBasket: true,
        createMembershipBasketSuccess: false,
        createMembershipBasketFailure: false,
      }
    case CREATE_MEMBERSHIP_BASKET_SUCCESS:
      return {
        ...state,
        isCreatingMembershipBasket: false,
        createMembershipBasketSuccess: true,
        createMembershipBasketFailure: false,
        basketId: action.payload,
        checkoutSession: { sessionId: action.payload, state: CheckoutStates.Active } as CheckoutSession,
      }
    case CREATE_MEMBERSHIP_BASKET_FAILURE:
      return {
        ...state,
        isCreatingMembershipBasket: false,
        createMembershipBasketFailure: true,
      }
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload,
      }
    case SET_DOCU_SIGN_MESSAGE:
      return {
        ...state,
        docuSignMessage: action.payload,
      }
    case SET_PAYMENT_SUMMARY_DTO:
      return {
        ...state,
        paymentSummary: action.payload,
      }
    case SET_MEMBERSHIP_COMPONENTS:
      return {
        ...state,
        membershipContainers: action.payload,
        isGettingMemberships: false,
      }
    case SET_REFERRED_MEMBERSHIP_COMPONENTS:
      return {
        ...state,
        referredMemberships: action.payload,
      }
    case SET_FINALISE_NOW_CONTAINERS:
      return {
        ...state,
        finaliseNowContainers: action.payload,
      }
    case SET_FINALISE_LATER_CONTAINERS:
      return {
        ...state,
        finaliseLaterContainers: action.payload,
      }
    case SET_PAYMENT_REQUIRED:
      return {
        ...state,
        isPaymentRequired: action.payload,
      }
    case SET_CONTENTFUL_MEMBERSHIP_TYPES:
      return {
        ...state,
        contentfulMembershipTypes: action.payload.filteredTypes,
        useMemberships: action.payload.useMemberships,
      }
    case SET_TERM_DURATIONS:
      return {
        ...state,
        termDurations: action.payload,
      }
    default:
      return state;
  }
};
