import React, { Component } from "react";
import "./Widget.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";

export interface WidgetPosition {
  columnIndex: number;
  columnSpan: number;
  rowIndex: number;
  rowSpan: number;
}

interface WidgetProps {
  containerClassName?: string;
  position?: WidgetPosition;
  contentClassName?: string;
  contentsStyle?: any;
}

export class Widget extends Component<WidgetProps> {
  private style: any = {
    gridColumnStart: this.props.position ? this.props.position.columnIndex : 0,
    msGridColumn: this.props.position ? this.props.position.columnIndex : 0,
    gridColumnEnd:
      "span " + (this.props.position ? this.props.position.columnSpan : 0),
    msGridColumnSpan: this.props.position ? this.props.position.columnSpan : 0,
    gridRowStart: this.props.position ? this.props.position.rowIndex : 0,
    msGridRow: this.props.position ? this.props.position.rowIndex : 0,
    gridRowEnd:
      "span " + (this.props.position ? this.props.position.rowSpan : 0),
    msGridRowSpan: this.props.position ? this.props.position.rowSpan : 0,
  };

  render() {
    return (
      <div
        className={classNames("widget", this.props.containerClassName)}
        style={this.style}
      >
        <div
          className={classNames("widget-contents", this.props.contentClassName)}
          style={this.props.contentsStyle}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

interface WidgetListProps {
  containerClassName?: string;
  position: WidgetPosition;
}

export class WidgetList extends Component<WidgetListProps> {
  private style: any = {
    gridColumnStart: this.props.position.columnIndex,
    msGridColumn: this.props.position.columnIndex,
    gridColumnEnd: "span " + this.props.position.columnSpan,
    msGridColumnSpan: this.props.position.columnSpan,
    gridRowStart: this.props.position.rowIndex,
    msGridRow: this.props.position.rowIndex,
    gridRowEnd: "span " + this.props.position.rowSpan,
    msGridRowSpan: this.props.position.rowSpan,
  };

  render() {
    return (
      <div
        className={classNames("widget-list", this.props.containerClassName)}
        style={this.style}
      >
        {this.props.children}
      </div>
    );
  }
}

interface WidgetListItemProps {}

export class WidgetListItem extends Component {
  render() {
    return <div className="widget-list-item">{this.props.children}</div>;
  }
}

interface WidgetHeaderProps {
  title: string;
}

export class WidgetHeader extends Component<WidgetHeaderProps> {
  render() {
    return <div className="widget-header">{this.props.title}</div>;
  }
}

interface WidgetFooterProps {
  title: string;
}

export class WidgetFooter extends Component<WidgetFooterProps> {
  render() {
    return <div className="widget-footer">{this.props.title}</div>;
  }
}

interface LinkWidgetProps extends WidgetProps {
  containerClassName?: string;
  position?: WidgetPosition;
  to: string;
  id?: string;
}

export class LinkWidget extends Component<LinkWidgetProps> {
  render() {
    return (
      <Widget {...this.props}>
        <Link className="link-widget" to={this.props.to} id={this.props.id}>
          {this.props.children}
        </Link>
      </Widget>
    );
  }
}

interface ClickableWidgetProps extends WidgetProps {
  containerClassName?: string;
  position?: WidgetPosition;
  onClick: () => void;
}

export class ClickableWidget extends Component<ClickableWidgetProps> {
  render() {
    return (
      <Widget {...this.props}>
        <div className="link-widget" onClick={this.props.onClick}>
          {this.props.children}
        </div>
      </Widget>
    );
  }
}
