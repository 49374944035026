import React, { useEffect } from 'react';
import './NewsCard.scss';
import { useTranslation } from 'react-i18next';
import ImportantInformationWidget from './ImportantInformationWidget';
import { Widget, WidgetList } from '../../../Shared/Components/Presentational/Widget';
import './ImportantInformationPage.scss';
import { ImportantInformationPageProps } from './ImportantInformationPage';
import { ModalBackButton } from '../../../Shared/Components/Presentational/Buttons';
import * as Routes from '../../../Constants/Routes';
import { Link } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';

const ImportantInformationPageMobile: React.FC<ImportantInformationPageProps> = ({
    selectedId,
    importantInformation,
    setSelectedImportantInformation
}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const selectedItem = importantInformation.find(a => a.sys.id == selectedId);

    const { t } = useTranslation();
    const options = {
        renderNode: {
          [INLINES. HYPERLINK]: ({ data }: any, children: any) => {
            return <a  href={data.uri} target={data.uri.startsWith(window.origin) ? '' : '_blank'}>{children}</a>;
          }
        }
    }
    return (
        <div className="mobile-widget-container">
            <h1 className="widget-layout-title">{t('myParkAndHome.title')}</h1>
            <p className="widget-layout-message">{t('myParkAndHome.message')}</p>
            <Widget position={{ columnIndex: 1, columnSpan: 12, rowIndex: 3, rowSpan: 10 }}>
                <div className="important-info-body">
                    {selectedItem && <h1 className="title">{selectedItem.fields.title}</h1>}
                    {selectedItem && documentToReactComponents(selectedItem.fields.content, options)}
                </div>
                <div className="info-back-button">
                    <Link to={Routes.MyParkAndHome}>
                        <ModalBackButton />
                    </Link>
                </div>
            </Widget>
            <WidgetList position={{ columnIndex: 1, columnSpan: 12, rowIndex: 10, rowSpan: 2 }}>
                {importantInformation
                    .filter(x => x.sys.id !== selectedId)
                    .map((information, i) => (
                        <ImportantInformationWidget
                            key={i}
                            importantInformation={information}
                            onClick={() => setSelectedImportantInformation(information.sys.id)}
                            containerClassName="sidebar"
                        />
                    ))}
            </WidgetList>
        </div>
    );
};

export default ImportantInformationPageMobile;
