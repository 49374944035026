import React from 'react';
import { useTranslation } from 'react-i18next';
import useDownloadInvoice from '../../../Account/Hooks/useDownloadInvoice';
import { LinkButton } from '../../../Shared/Components/Presentational/Buttons';
import { TransactionIcon } from '../../../Shared/Components/Presentational/Icons';
import { formatDateString } from '../../../Shared/Utilities/DateUtils';
import { getAmount } from '../../../Shared/Utilities/TransactionUtils';
import './TransactionItem.scss';

interface TransactionItemProps {
    transaction: TransactionDTO;
    showDownload?: boolean;
}

export default function TransactionItem({ transaction, showDownload }: TransactionItemProps) {
    const { t } = useTranslation();
    const download = useDownloadInvoice(transaction.InvoiceNo);

    return (
        <div className="transaction-item">
            <div className="transaction-date-row">
                <p className="transaction-date">{formatDateString(transaction.TransactionDate)}</p>
                {showDownload && transaction.HasInvoice && (
                    <LinkButton text="Download invoice" onClick={download} className="invoice-link" />
                )}
            </div>
            <div className="transaction-details">
                <TransactionIcon icon={transaction.Icon} />
                <div className="transaction-type-container">
                    <p className="transaction-type">{t(transaction.CategoryKey)}</p>
                    <p className="transaction-no">{transaction.InvoiceNo}</p>
                </div>
                <p className="transaction-amount">{getAmount(transaction.Amount)}</p>
            </div>
        </div>
    );
}
