import {
    SET_LODGE_DETAILS,
    SET_LODGE_DETAILS_LIST,
    SET_IS_CONTACT_PARK_MODAL_OPEN,
    GET_FAQS_REQUEST,
    GET_FAQS_FAILURE,
    GET_FAQS_SUCCESS,
    GET_IMPORTANT_INFORMATION_REQUEST,
    GET_IMPORTANT_INFORMATION_SUCCESS,
    GET_IMPORTANT_INFORMATION_FAILURE,
    SET_SELECTED_IMPORTANT_INFORMATION,
    GET_NEWS_REQUEST,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAILURE,
    SET_SELECTED_NEWS_ITEM,
    GET_PARK_INFORMATION_REQUEST,
    GET_PARK_INFORMATION_SUCCESS,
    GET_PARK_INFORMATION_FAILURE

} from './ActionTypes';

const initialState = {
    lodgeDetails: {
        lodgeId: undefined,
        descrption: undefined,
        details: []
    },
    lodgeDetailsList: [],
    isContactParkModalOpen: false,
    faqs: [],
    isLoadingFaqs: false,
    gettingFaqsError: undefined,
    importantInformation: [],
    gettingInformationError: undefined,
    isLoadingInformation: false,
    selectedImportantInformationId: undefined,
    news: [],
    gettingNewsError: undefined,
    isLoadingNews: false,
    selectedNewsItemId: undefined,
    isLoadingParkInformation: false,
    parkInformation: undefined,
    parkInformationError: undefined
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case SET_LODGE_DETAILS:
            return { ...state, lodgeDetails: action.payload };
        case SET_LODGE_DETAILS_LIST:
            return { ...state, lodgeDetailsList: action.payload };
        case SET_IS_CONTACT_PARK_MODAL_OPEN:
            return { ...state, isContactParkModalOpen: action.payload };
        case GET_FAQS_REQUEST:
            return { ...state, isLoadingFaqs: true, faqs: [] };
        case GET_FAQS_SUCCESS:
            return { ...state, faqs: action.payload, isLoadingFaqs: false };
        case GET_FAQS_FAILURE:
            return { ...state, gettingFaqsError: action.payload, isLoadingFaqs: false };
        case GET_IMPORTANT_INFORMATION_REQUEST:
            return { ...state, isLoadingInformation: true, importantInformation: [] };
        case GET_IMPORTANT_INFORMATION_SUCCESS:
            return { ...state, importantInformation: action.payload, isLoadingInformation: false };
        case GET_IMPORTANT_INFORMATION_FAILURE:
            return { ...state, gettingInformationError: action.payload, isLoadingInformation: false };
        case SET_SELECTED_IMPORTANT_INFORMATION:
            return { ...state, selectedImportantInformationId: action.payload };
        case GET_PARK_INFORMATION_REQUEST:
            return { ...state, isLoadingParkInformation: true, parkInformation: undefined, parkInformationError: undefined };
        case GET_PARK_INFORMATION_SUCCESS:
            return { ...state, isLoadingParkInformation: false, parkInformation: action.payload };
        case GET_PARK_INFORMATION_FAILURE:
            return { ...state, isLoadingParkInformation: false, parkInformationError: action.payload};
        case GET_NEWS_REQUEST:
            return { ...state, isLoadingNews: true, news: [] };
        case GET_NEWS_SUCCESS:
            return { ...state, news: action.payload.Dtos, isLoadingNews: false };
        case GET_NEWS_FAILURE:
            return { ...state, gettingNewsError: action.payload, isLoadingNews: false };
        case SET_SELECTED_NEWS_ITEM:
            return { ...state, selectedNewsItemId: action.payload };
        default:
            return state;
    }
};
