import React, { ChangeEvent, useRef, useState } from 'react';
import { Translation } from 'react-i18next';
import { FormContext } from './AddPassRow';
import DropdownList from '../../../Shared/Components/Presentational/DropdownList';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Mobile, Desktop } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { NativeDatePicker } from '../../../Shared/Components/Presentational/NativeDatePicker';
import { ClickableIcon } from '../../../Shared/Components/Presentational/Icons';
import { faArrowLeft, faArrowUp, faCamera, faCameraRetro, faUpload } from '@fortawesome/free-solid-svg-icons';
import AddPassPhoto from './AddPassPhoto';
import { fileToUrl, urlToFile } from '../../../Shared/Utilities/FileUtils';
import { Toggle } from '../../../Shared/Components/Presentational/Toggle';
import classNames from 'classnames';
import { Modal } from '../../../Shared/Components/Presentational/Modal';

interface AddPassFieldProps {
    analyzingPassPhotoError?: any;
    isAnalyzingPassPhoto?: boolean;
    isAnalyzingPassPhotoSuccessful?: boolean;
    field: FormField<AddPassDTO>;
    submittedOption: ParkPassTypeDTO;
    isAddPassPhotoModalOpen: boolean;
    openAddPassPhotoModal: () => void;
    closeAddPassPhotoModal: () => void;
    PassPhotoSizeLimitInKb: number;
    analyzePassPhotoRequest: (passPhotoFile: File) => void;
}

interface AddPassFieldState {
    uploadLater: boolean;
}

class AddPassField extends React.Component<AddPassFieldProps, AddPassFieldState> {
    constructor(props: any) {
        super(props);

        this.state = { uploadLater : false };

    }
    handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.value;
        this.handleChange(newValue);
    }

    handleDropdownChange(newValue: DropdownListOption) {
        this.handleChange(newValue.value);
    }

    async handlePhotoUploadChange(file: File | undefined) {
        var newValue = fileToUrl(file);
        const isValid = this.props.field.validator(newValue);

        this.context.formState[this.props.field.id] = { isValid, isDirty: true };

        this.props.field.setter(this.context.form.pass, newValue);
        await new Promise(resolve => setTimeout(resolve, 500));
        this.context.updateFormState(this.context);
        this.forceUpdate();

        this.props.closeAddPassPhotoModal();
    }

    handleDatepickerChange(newValue: Date) {
        this.handleChange(moment(newValue).format('DD/MM/YYYY'));
    }

    handleChange(newValue: string) {
        const isValid = this.props.field.validator(newValue);

        this.context.formState[this.props.field.id] = { isValid, isDirty: true };

        this.props.field.setter(this.context.form.pass, newValue);
        this.context.updateFormState(this.context);
        this.forceUpdate();
    }

    isValid(): boolean {
        return this.fieldState && this.fieldState.isValid;
    }

    isDirty(): boolean {
        return this.fieldState && this.fieldState.isDirty;
    }

    convertToDate(value: string): Date | undefined {
        if (value) {
            return moment(value, 'DD/MM/YYYY').toDate();
        }
        return undefined;
    }

    get fieldState() {
        return this.context.formState[this.props.field.id];
    }

    photo = (t: (key: string) => string, field: FormField<AddPassDTO>) => {
        var clickableIconClassName = "";
        if(this.state.uploadLater) {
            clickableIconClassName = "disabled";
        }

        var fieldValue = this.props.field.getter(this.context.form.pass);
        var className = "add-pass-photo";
        if (fieldValue == null || fieldValue == undefined || fieldValue == '') {
            className += " no-photo";
        }

        const handleOpenAddPassPhotoModal = () => !this.state.uploadLater && this.props.openAddPassPhotoModal();
        const handleUploadLaterChange = async () => {
            var newValue = !this.state.uploadLater;
            this.setState({ uploadLater: newValue });
            if(newValue) {
                this.props.field.setter(this.context.form.pass, '');
                await new Promise(resolve => setTimeout(resolve, 500));
            }
            this.context.formState[this.props.field.id] = { isValid: newValue, isDirty: true };
            this.context.updateFormState(this.context);
        }
        
        return (
            <>
                <div className={className}>
                    <img src={fieldValue}/>
                    <ClickableIcon
                        className={classNames('upload-button', clickableIconClassName)}
                        icon={faUpload}
                        clickAction={handleOpenAddPassPhotoModal}
                    />
                </div>
                <div className='upload-check-box'>
                    <input 
                        type="checkbox" id="uploadLater"
                        checked={this.state.uploadLater}
                        onChange={handleUploadLaterChange}/>
                    <label htmlFor="uploadLater">Upload Later</label>
                </div>
                <Modal isOpen={this.props.isAddPassPhotoModalOpen}>
                    <AddPassPhoto 
                        submittedOption={this.props.submittedOption}
                        isAnalyzingPassPhoto={this.props.isAnalyzingPassPhoto} 
                        analyzingPassPhotoError={this.props.analyzingPassPhotoError}
                        isAnalyzingPassPhotoSuccessful={this.props.isAnalyzingPassPhotoSuccessful} 
                        onPhotoUpload={this.handlePhotoUploadChange.bind(this)}
                        onBackButtonClick={this.props.closeAddPassPhotoModal}
                        value={this.props.field.getter(this.context.form.pass)}
                        PassPhotoSizeLimitInKb={this.props.PassPhotoSizeLimitInKb}
                        analyzePassPhotoRequest={(file: File) => this.props.analyzePassPhotoRequest(file)}
                        instructions={{ name: "", title: "", content: ""}}
                        fileRestrictions={{ name: "", title: "", content: ""}}
                        exception={{ name: "", title: "", content: ""}}/>
                </Modal>

            </>

    )};
    input = (t: (key: string) => string, field: FormField<AddPassDTO>) => (
        <input
            name={this.props.field.id}
            id={this.props.field.id}
            className={!this.isValid() && this.isDirty() ? 'form-input invalid' : 'form-input'}
            type={this.props.field.type}
            required
            maxLength={this.props.field.maxLength}
            placeholder={t(this.props.field.placeholderTranslationKey)}
            onChange={this.handleInputChange.bind(this)}
            value={this.props.field.getter(this.context.form.pass)}
        />
    );
    dropdown = (t: (key: string) => string, field: FormField<AddPassDTO>) => (
        <DropdownList
            options={field.options as DropdownListOption[]}
            classNamePrefix="pass-length"
            onSelectionChanged={this.handleDropdownChange.bind(this)}
            selectedOption={
                field.options && field.options.filter((o) => o.value === field.getter(this.context.form.pass))[0]
            }
        />
    );
    date = (t: (key: string) => string, field: FormField<AddPassDTO>) => (
        <>
            <Desktop>
                <DatePicker
                    onChange={this.handleDatepickerChange.bind(this)}
                    className={!this.isValid() && this.isDirty() ? 'form-input invalid' : 'form-input'}
                    dateFormat="dd/MM/yyyy"
                    placeholderText={t(this.props.field.placeholderTranslationKey)}
                    selected={this.convertToDate(this.props.field.getter(this.context.form.pass))}
                />
            </Desktop>
            <Mobile>
                <NativeDatePicker
                    name="theDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleDatepickerChange.bind(this)}
                    selected={this.convertToDate(this.props.field.getter(this.context.form.pass))}
                    invalid={!this.isValid() && this.isDirty()}
                />
            </Mobile>
        </>
    );
    render() {
        return (
            <FormContext.Consumer>
                {(context) => (
                    <Translation>
                        {(t: (key: string) => string) => {
                            this.context = context;
                            return (
                                <div
                                    className="add-pass-field"
                                    style={{
                                        flexBasis: this.props.field.flexBasis,
                                        marginLeft: this.props.field.marginLeft,
                                        marginRight: this.props.field.marginRight,
                                    }}
                                >
                                    <label htmlFor={this.props.field.id}>
                                        {t(this.props.field.labelTranslationKey)}
                                    </label>
                                    {this.props.field.type === 'dropdown' && this.dropdown(t, this.props.field)}
                                    {this.props.field.type === 'text' && this.input(t, this.props.field)}
                                    {this.props.field.type === 'email' && this.input(t, this.props.field)}
                                    {this.props.field.type === 'date' && this.date(t, this.props.field)}
                                    {this.props.field.type === 'photo' && this.photo(t, this.props.field)}
                                </div>
                            );
                        }}
                    </Translation>
                )}
            </FormContext.Consumer>
        );
    }
}

export default AddPassField;
