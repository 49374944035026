import React from 'react';
import classNames from 'classnames';

interface GridProps {
    rowCount: number;
    columnCount: number;
    className?: string;
}

const Grid: React.FC<GridProps> = props => {
    const style: React.CSSProperties = {
        display: 'grid',
        gridTemplateColumns: `repeat(${props.columnCount}, minmax(auto, 1fr))`,
        gridTemplateRows: `repeat(${props.rowCount}, minmax(75px, auto))`,
        msGridColumns: `#{"minmax(max-content, 1fr))[${props.columnCount}]}`,
        msGridRows: `#{"(minmax(75px, max-content))[${props.rowCount}]"}`
    };
    return (
        <div style={style} className={classNames(props.className)}>
            {props.children}
        </div>
    );
};

export default Grid;
