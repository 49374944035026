import React, { Component } from 'react';
import { VerticalBackground } from '../../../Shared/Components/Presentational/Backgrounds';
import { withTranslation, WithTranslation } from 'react-i18next';
import RegisterPopup from './RegisterPopup';

interface RegisterPageProps extends WithTranslation {
    loginClick: (email: string, password: string, type: string) => void;
    isLoggingIn: boolean;
    loginFailureMessage: string;
}

class RegisterPage extends Component<RegisterPageProps> {
    render() {
        const { t } = this.props;
        return (
            <div className="login-page-container">
                <VerticalBackground
                    src="/images/desktop-login-image.svg"
                    containerClassName="login-page-vertical-background"
                >
                    <h1>{t('register.title')}</h1>
                </VerticalBackground>
                <RegisterPopup isLoggingIn={this.props.isLoggingIn} loginClick={this.props.loginClick} loginFailureMessage={this.props.loginFailureMessage} isMobile={false} />
            </div>
        );
    }
}

export default withTranslation()(RegisterPage);
