import React from "react";
import "./SelectableChip.scss";

interface SelectableChipProps {
  isSelected?: boolean;
  itemKey: string;
  onSelect?: (key: string) => void;
}

const SelectableChip: React.FC<SelectableChipProps> = (props) => {
  return (
    <div
      className={
        props.isSelected ? "selectable-chip selected" : "selectable-chip"
      }
      onClick={() => {
        props.onSelect && props.onSelect(props.itemKey);
      }}
    >
      <div>{props.children}</div>
    </div>
  );
};

export default SelectableChip;
