import { connect } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SET_HEADER_MENU_OPEN } from '../../Redux/ActionTypes';
import { Desktop, Mobile } from '../Presentational/ResponsiveViews';
import classNames from 'classnames';
import "../Presentational/Header.scss";
//@ts-ignore
import Fade from "react-reveal/Fade";
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { ClickableIcon } from '../Presentational/Icons';
import history from "../../../History";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import HomeButton from '../Presentational/HomeButton';
import { ClickableLabel } from '../Presentational/Labels';
import NavOption from '../Presentational/NavOption';
import { LinkWidget } from '../Presentational/Widget';
import * as Routes from "../../../Constants/Routes";
import Features from '../../../Constants/Features';
import FeatureToggle from '../Presentational/FeatureToggle';
import MobileSideNav from '../Presentational/MobileSideNav';
import MemberMobileSideNav from '../Presentational/MemberMobileSideNav';

interface MemberHeaderProps extends RouteComponentProps {
  logoutClick: () => void;
  isHeaderOpen: boolean;
  setHeaderOpen: (isOpen: boolean) => void;
  backgroundImgSrc?: string;
  backgroundContainerClassName?: string;
}

const MemberHeader: React.FC<MemberHeaderProps> = (props) => {
  const { location, isHeaderOpen, setHeaderOpen } = props;
  const { t } = useTranslation();

  history.listen(() => {
    if (setHeaderOpen) {
      setHeaderOpen(false);
    }
  });

  return (
    <>
      <Desktop>
        <div className='header'>
          <div
            className={classNames(
              "horizontal-background",
              props.backgroundContainerClassName,
              {
                "header-open": isHeaderOpen,
              }
            )}
          >
            {props.backgroundImgSrc ? (
              <img
                className="horizontal-background-image"
                src={props.backgroundImgSrc}
                alt=""
              />
            ) : null}
          </div>
          <Fade top>
            <div className='header-container'>
              {location && location.pathname !== "/" && (
                <div className="header-bars-container">
                  <ClickableIcon
                    icon={faBars}
                    clickAction={() => setHeaderOpen(!isHeaderOpen)}
                    className="header-bars"
                  />
                </div>
              )}
              <HomeButton />
              <div className='header-item-container'>
                <ClickableLabel
                  className='header-item clickable-label-header'
                  text={t("general.signOut")}
                  clickAction={props.logoutClick}
                />
              </div>
            </div>

            <Fade
              top
              collapse
              when={isHeaderOpen}
              timeout={500}
              distance="150px"
            >
              <div className={classNames("header-menu", { open: isHeaderOpen })}>
                <LinkWidget containerClassName="nav-option" to={Routes.Home}>
                  <NavOption
                    text={t("navigation.backToHome")}
                    icon="back-to-home.svg"
                    iconAlt="Back to home"
                  />
                </LinkWidget>
                <LinkWidget
                  containerClassName="nav-option"
                  to={Routes.MyParkAndHome}
                >
                  <NavOption
                    text={t("navigation.myPark")}
                    icon="my-park-and-home.svg"
                    iconAlt="My park and home"
                  />
                </LinkWidget>
                <FeatureToggle features={[Features.Memberships]}>
                  <LinkWidget
                    containerClassName="nav-option"
                    to={Routes.MyGuests}
                  >
                    <NavOption
                      text={t("navigation.myMemberships")}
                      icon="my-guests.svg"
                      iconAlt="My Memberships"
                    />
                  </LinkWidget>
                </FeatureToggle>
                <LinkWidget
                  containerClassName="nav-option"
                  to={Routes.MyRequestsAndQueries}
                >
                  <NavOption
                    text={t("navigation.myRequests")}
                    icon="my-requests.svg"
                    iconAlt="My Requests"
                  />
                </LinkWidget>
              </div>
            </Fade>
          </Fade>
        </div>
      </Desktop>
      <Mobile>
        <div
          className={classNames(
            "horizontal-background",
            props.backgroundContainerClassName
          )}
        ></div>
        <div className="mobile-header-container">
          <div>
            {location && location.pathname !== "/" && (
              <ClickableIcon
                className="mobile-clickable-header-item"
                icon={faBars}
                clickAction={() => setHeaderOpen(!isHeaderOpen)}
              />
            )}
          </div>
          <ClickableIcon
            className="mobile-clickable-header-item"
            icon={faSignOutAlt}
            clickAction={props.logoutClick}
          />
          <MemberMobileSideNav
            onClose={() => setHeaderOpen(false)}
            isHeaderOpen={isHeaderOpen}
          />
        </div>
      </Mobile>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isHeaderOpen: state.application.isHeaderOpen,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHeaderOpen: (isOpen: boolean) => dispatch({ type: SET_HEADER_MENU_OPEN, payload: isOpen }),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberHeader));