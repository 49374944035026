import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Get } from '../../../Api/ApiService';
import * as Routes from '../../../Constants/Routes';
import { ModalBackButton } from '../../../Shared/Components/Presentational/Buttons';
import Spinner from '../../../Shared/Components/Presentational/Spinner';
import { Widget, WidgetList } from '../../../Shared/Components/Presentational/Widget';
import NewsCardContainer from '../Container/NewsCardContainer';
import './ParkNewsPage.scss';
import { formatDateString } from '../../../Shared/Utilities/DateUtils';
import { GetNewsArticleCall } from '../../../Contentful/Calls/GetNewsArticleCall';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Entry } from 'contentful';
import RichTextRenderOptions from '../../../Contentful/RichTextOptions';

export interface ParkNewsPageProps {
    selectedId: string;
    newsItem: Entry<NewsArticleHeader>;
    setSelectedNewsItem: (setSelectedNewsItem: string) => void;
}

const ParkNewsPage: React.FC<ParkNewsPageProps> = ({ selectedId, newsItem, setSelectedNewsItem }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [news, setNews] = useState<Entry<NewsArticle>>(Object);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedId) {
            GetNewsArticleCall(selectedId).then(
                result => {
                    setNews(result);
                    setIsLoading(false);
                },
                err => {
                    setIsLoading(false);
                }
            );
        }else{
            setIsLoading(false);
        }
    }, [selectedId]);

    return (
        <div className="widget-container">
            <h1 className="widget-layout-title">{t('myParkAndHome.title')}</h1>
            <p className="widget-layout-message">{t('myParkAndHome.message')}</p>
            <Widget
                position={{ columnIndex: 1, columnSpan: 9, rowIndex: 3, rowSpan: 12 }}
                containerClassName="widget-ie-fix"
            >
            {!isLoading && 
                <div className="park-news-body">
                    <div className="park-news-img">
                        <img src={news.fields.image ? news.fields.image.fields.file.url : '/images/default-restaurant-image.png'} alt="news" />
                    </div>
                    <div className="park-news-content-wrapper">
                        <div className="title">{news.fields.title}</div>
                        <div className="park-news-date">Posted {formatDateString(news.fields.published)}</div>
                        <div className="park-news-content">
                            {documentToReactComponents(news.fields.content, RichTextRenderOptions)}
                        </div>
                    </div>
                </div>
            }
            {isLoading && 
                <div className="park-news-body park-spinner-container">
                    <Spinner />
                </div>
            }
                   
                <div className="news-back-button">
                    <Link to={Routes.MyParkAndHome}>
                        <ModalBackButton />
                    </Link>
                </div>
            </Widget>
            <WidgetList position={{ columnIndex: 10, columnSpan: 3, rowIndex: 3, rowSpan: 12 }}>
                <p className="park-news-title">{t('myParkAndHome.parkNewsFeed')}</p>
                <NewsCardContainer setSelectedNewsItem={setSelectedNewsItem} />
            </WidgetList>
        </div>
    );
};

export default ParkNewsPage;
