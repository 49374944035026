import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Widget } from "../../../Shared/Components/Presentational/Widget";
import * as Routes from "../../../Constants/Routes";
import { PrimaryButton, SecondaryButton } from "../../../Shared/Components/Presentational/Buttons";
import { Desktop } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { GetHTMlContentBlockCall } from "../../../Contentful/Calls/GetHTMLContentBlockCall";
import Spinner from "../../../Shared/Components/Presentational/Spinner";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";


interface PaymentCancelledProps extends WithTranslation {
  isHeaderOpen: boolean;
}

const useQuery = () => new URLSearchParams(useLocation().search);

const PaymentCancelled: React.FC<PaymentCancelledProps> = (props) => {
  const { t } = props;

  const [contentLoaded, setContentLoaded] = useState(false);
  const [paymentCancelled, setPaymentCancelled] = useState<ContentfulContentObject | undefined>(undefined);

  useEffect(() => {
    Promise.all([
      getContentBlock("My Haulfryn - Membership - Payment Outcomes - Failure", "paymentCancelled"),
    ]).then(r => {
      setPaymentCancelled(r[0]);
      setContentLoaded(true);
    })
  }, []);

  const getContentBlock = async (name: string, label: string) => {
    const text = await GetHTMlContentBlockCall(name, label);
    return text;
  }

  return (
    <>
      {contentLoaded ? (
        <div className="widget-container">
          <Desktop>
            {!props.isHeaderOpen && (
              <>
                <h1 className="widget-layout-title">{t("addMembership.payment.title")}</h1>
              </>
            )}
          </Desktop>

          <Widget
            position={{
              columnIndex: 1,
              columnSpan: 12,
              rowIndex: 3,
              rowSpan: 9,
            }}
          >
            <div className='row'>
              <div className='float-left'>
                <h1 style={{ margin: "20px", color: "#184f6d" }}>{paymentCancelled!.title}</h1>
              </div>
            </div>
            <div className='row contentful-text-payment' style={{ marginLeft: "20px", marginRight: "20px" }}>
              {/* <p>{t("addMembership.paymentCancelled.message")}</p> */}
              {documentToReactComponents(paymentCancelled!.content)}
            </div>
            <div style={{ marginTop: "15px" }}>
              <Link to={Routes.SelectPayment}>
                <PrimaryButton
                  text={t("addMembership.paymentCancelled.toPaymentScreen")}
                />
              </Link>
              <Link to={Routes.MyGuests}>
                <SecondaryButton
                  text={t("addMembership.paymentSuccess.toMyMemberships")}
                />
              </Link>
            </div>

          </Widget>

        </div>
      ) : (
        <div className='memberships-spinner'>
          <Spinner />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isHeaderOpen: state.application.isHeaderOpen,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {

  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PaymentCancelled));