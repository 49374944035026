import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface GenericDisplayContainerProps extends WithTranslation {
  membershipContainers?: MembershipContainer[];
  referredContainer?: ReferralDTO[];
  state: string;
  renderComponent: (container: MembershipContainer | undefined, isNow: boolean | undefined, referredMembership?: ReferralDTO | undefined) => any;
}

const GenericMembershipCardContainer: React.FC<GenericDisplayContainerProps> = (props: GenericDisplayContainerProps) => {
  const { t } = props;

  return (
    <div style={{ margin: "25px", marginTop: "15px", marginBottom: "15px" }}>
      <h2 className='container-title'>{t("myMemberships." + props.state + ".title")}</h2>

      <div className='gridDisplay'>
        {props.membershipContainers && props.membershipContainers.map(membership => props.renderComponent(membership, undefined))}
        {props.referredContainer && props.referredContainer.map(referredMembership => props.renderComponent(undefined, undefined, referredMembership))}
      </div>
    </div>
  )
}

export default withTranslation()(GenericMembershipCardContainer);
