export class ApiCall {
    constructor(url: string, isExternal: boolean) {
        this.isExternal = isExternal;
        this.url = this.isExternal ? url : '/api/' + url;
        this.headers = {};

        if (!this.isExternal) {
            const token = sessionStorage.getItem('access_token');

            if (token) {
                this.headers['Authorization'] = 'Bearer '.concat(token);
            }
        }
    }

    public url: string;
    public headers: any;
    public logoutOn401: boolean = true;
    public isExternal: boolean;
}

export class GetApiCall extends ApiCall {
    constructor(url: string, params: { [propName: string]: string | number | boolean | Date } = {}, isExternal: boolean = false) {
        super(url, isExternal);

        if (params) {
            this.url += `?${this.objToQueryString(params)}`;
        }
    }

    private objToQueryString(obj: { [propName: string]: string | number | boolean | Date }): string {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key] ? obj[key].toString() : ''));
        }
        return keyValuePairs.join('&');
    }
}

export class PostApiCall extends ApiCall {
    constructor(url: string, isExternal: boolean = false) {
        super(url, isExternal);

        this.headers['Accept'] = 'application/json';
        this.headers['Content-Type'] = 'application/json';
    }

    public body: any;

    public get bodyString(): any {
        return JSON.stringify(this.body);
    }
}

export class PutApiCall extends ApiCall {
    constructor(url: string, isExternal: boolean = false) {
        super(url, isExternal);

        this.headers['Accept'] = 'application/json';
        this.headers['Content-Type'] = 'application/json';
    }

    public body: any;

    public get bodyString(): string {
        return JSON.stringify(this.body);
    }
}

export class DeleteApiCall extends ApiCall {}

export class DeleteApiCall2 extends ApiCall {
    constructor(url: string, params: { [propName: string]: string | number | boolean | Date } = {}, isExternal: boolean = false) {
        super(url, isExternal);

        if (params) {
            this.url += `?${this.objToQueryString(params)}`;
        }
    }

    private objToQueryString(obj: { [propName: string]: string | number | boolean | Date }): string {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key] ? obj[key].toString() : ''));
        }
        return keyValuePairs.join('&');
    }
}
