export const SET_LODGE_DETAILS = 'SET_LODGE_DETAILS';
export const SET_LODGE_DETAILS_LIST = 'SET_LODGE_DETAILS_LIST';
export const SET_IS_CONTACT_PARK_MODAL_OPEN = 'SET_IS_CONTACT_PARK_MODAL_OPEN';
export const GET_FAQS_REQUEST = 'GET_FAQS_REQUEST';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_FAILURE = 'GET_FAQS_FAILURE';
export const GET_IMPORTANT_INFORMATION_REQUEST = 'GET_IMPORTANT_INFORMATION_REQUEST';
export const GET_IMPORTANT_INFORMATION_SUCCESS = 'GET_IMPORTANT_INFORMATION_SUCCESS';
export const GET_IMPORTANT_INFORMATION_FAILURE = 'GET_IMPORTANT_INFORMATION_FAILURE';
export const GET_PARK_INFORMATION_REQUEST = 'GET_PARK_INFORMATION_REQUEST';
export const GET_PARK_INFORMATION_SUCCESS = 'GET_PARK_INFORMATION_SUCCESS';
export const GET_PARK_INFORMATION_FAILURE = 'GET_PARK_INFORMATION_FAILURE';
export const SET_SELECTED_IMPORTANT_INFORMATION = 'SET_SELECTED_IMPORTANT_INFORMATION';
export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE';
export const SET_SELECTED_NEWS_ITEM = 'SET_SELECTED_NEWS_ITEM';
export const RELOAD_TRANSLATIONS = 'RELOAD_TRANSLATIONS';
