import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputContainer, ButtonContainer } from '../../../Shared/Components/Presentational/Containers';
import { PrimaryButton, SecondaryButton } from '../../../Shared/Components/Presentational/Buttons';
import Version from '../../../Shared/Components/Presentational/Version'
import classNames from 'classnames';
import { isValidEmail } from '../../../Shared/Utilities/Validation';

interface RegisterDetailsProps {
    onSubmit: (email: string, firstName: string, lastName: string) => void;
    firstName: string;
    lastName: string;
    email: string;
    isMobile: boolean;
    isLoggingIn: boolean;
}

export default function RegisterDetails(props: RegisterDetailsProps) {
    const [firstName, setFirstName] = useState(props.firstName);
    const [lastName, setLastName] = useState(props.lastName);
    const [email, setEmail] = useState(props.email);
    const [isEmailValid, setIsEmailValid] = useState(true);

    const ContinueButton = props.isMobile ? SecondaryButton : PrimaryButton;

    const { t } = useTranslation();

    const onEmailChange = (e: any) => {
        setEmail(e.target.value);

        setIsEmailValid(isValidEmail(e.target.value));
    };

    const onContinue = (e: any) => {
        e.preventDefault();
        props.onSubmit(email, firstName, lastName);
    };

    return (
        <form name="form" onSubmit={onContinue}>
            <p className="register-step-title">{t('register.yourDetails')}</p>

            <InputContainer className="login-input-container">
                <input
                    name="firstName"
                    className={props.isMobile ? 'form-input-alternative' : 'form-input'}
                    type="text"
                    placeholder={t('register.firstNamePlaceholder')}
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    maxLength={50}
                />
                <input
                    name="lastName"
                    className={props.isMobile ? 'form-input-alternative' : 'form-input'}
                    type="text"
                    placeholder={t('register.lastNamePlaceholder')}
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    maxLength={50}
                />
                <input
                    name="emailAddress"
                    className={classNames(props.isMobile ? 'form-input-alternative' : 'form-input', {
                        invalid: !isEmailValid
                    })}
                    type="email"
                    placeholder={t('register.emailAddressPlaceholder')}
                    value={email}
                    onChange={e => onEmailChange(e)}
                    maxLength={256}
                />
            </InputContainer>
            <p
                className={classNames(props.isMobile ? 'error-message-mobile login-failure-message' : 'error-message', {
                    show: !isEmailValid
                })}
            >
                {t('register.passwordRequirements.emailInvalid')}
            </p>

            <ButtonContainer className="register-button">
                <ContinueButton
                    type="submit"
                    disabled={!email || !firstName || !lastName || !isEmailValid || props.isLoggingIn}
                    text={t('register.continueButton')}
                    isLoading={props.isLoggingIn}
                />
            </ButtonContainer>
            <div className="heading-center">
                <Version></Version>
            </div>
        </form>
    );
}
