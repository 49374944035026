import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { setTimeout } from 'timers';
import { Post } from './Api/ApiService';
import { PostConfirmEmailCall } from './Api/Calls/PostConfirmEmailCall';
import { PostResendConfirmEmailCall } from './Api/Calls/PostResendConfirmEmailCall';
import './Confirm.scss';
import * as Routes from './Constants/Routes';
import { ImageBackground, VerticalBackground } from './Shared/Components/Presentational/Backgrounds';
import { SecondaryButton, PrimaryButton } from './Shared/Components/Presentational/Buttons';
import { Desktop, Mobile } from './Shared/Components/Presentational/ResponsiveViews';

interface ConfirmEmailProps extends WithTranslation {}
interface ConfirmEmailState {
    codeConfirmed: boolean;
    readyToRedirect: boolean;
    requestFailed: boolean;
    emailResent: boolean;
    userId: string;
    code: string;
}

class ConfirmEmail extends Component<ConfirmEmailProps, ConfirmEmailState> {
    constructor(props: any) {
        super(props);

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const userId = params.get('userId') as string;
        const code = params.get('code') as string;

        this.state = {
            codeConfirmed: false,
            readyToRedirect: false,
            requestFailed: false,
            emailResent: false,
            userId: userId,
            code: code
        };

        Post<{}>(new PostConfirmEmailCall({ UserId: userId, Code: code }))
            .then(() => {
                this.setState({ codeConfirmed: true });

                setTimeout(() => {
                    this.setState({ readyToRedirect: true });
                }, 5000);
            })
            .catch(() => {
                this.setState({ requestFailed: true });
            });
    }

    private resendConfirmationEmail() {
        this.setState({ emailResent: false });

        const code = this.state.code;
        const userId = this.state.userId;

        Post<{}>(new PostResendConfirmEmailCall({ UserId: userId, Code: code })).then(() => {
            this.setState({ emailResent: true });
        });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Desktop>
                    <div className="confirm-page-container">
                        <VerticalBackground
                            src="/images/desktop-login-image.svg"
                            containerClassName="confirm-page-vertical-background"
                        >
                            <h1>{t('login.welcomeTitle')}</h1>
                            <p>{t('login.welcomeMessage')}</p>
                        </VerticalBackground>
                        <div className="confirm-page-message-container">
                            {this.state.codeConfirmed && (
                                <div>
                                    <h1>{t('login.emailConfirmationComplete')}</h1>
                                    <h3 className="waiting">{t('login.redirectToLogin')}</h3>
                                </div>
                            )}
                            {!this.state.codeConfirmed && !this.state.requestFailed && (
                                <div>
                                    <h1>{t('login.validateCode')}</h1>
                                    <h3 className="waiting">{t('login.checkingCode')}</h3>
                                </div>
                            )}
                            {!this.state.codeConfirmed && this.state.requestFailed && (
                                <div>
                                    <h1 className="request-failed">{t('login.emailConfirmationFailed')}</h1>
                                    <h3 className="request-failed">{t('login.emailLinkExpired')}</h3>
                                    <PrimaryButton
                                        className="request-link"
                                        onClick={() => this.resendConfirmationEmail()}
                                        text={t('login.requestNewLink')}
                                        disabled={this.state.emailResent}
                                    />
                                    {this.state.emailResent && <h3>{t('login.emailConfirmationResent')}</h3>}
                                </div>
                            )}
                            {this.state.readyToRedirect && <Redirect to={Routes.Login} />}
                        </div>
                    </div>
                </Desktop>
                <Mobile>
                    <ImageBackground
                        src="/images/mobile-login-image.svg"
                        containerClassName="confirm-mobile-background"
                    >
                        <div className="confirm-page-message-mobile-container">
                            {this.state.codeConfirmed && (
                                <div>
                                    <h1>{t('login.emailConfirmationComplete')}</h1>
                                    <h3 className="waiting">{t('login.redirectToLogin')}</h3>
                                </div>
                            )}
                            {!this.state.codeConfirmed && !this.state.requestFailed && (
                                <div>
                                    <h1>{t('login.validateCode')}</h1>
                                    <h3 className="waiting">{t('login.checkingCode')}</h3>
                                </div>
                            )}
                            {!this.state.codeConfirmed && this.state.requestFailed && (
                                <div>
                                    <h1 className="request-failed">{t('login.emailConfirmationFailed')}</h1>
                                    <h3 className="request-failed">{t('login.emailLinkExpired')}</h3>
                                    <SecondaryButton
                                        className="request-link-mobile"
                                        onClick={() => this.resendConfirmationEmail()}
                                        text={t('login.requestNewLink')}
                                        disabled={this.state.emailResent}
                                    ></SecondaryButton>
                                    {this.state.emailResent && <h3>{t('login.emailConfirmationResent')}</h3>}
                                </div>
                            )}
                            {this.state.readyToRedirect && <Redirect to={Routes.Login} />}
                        </div>
                    </ImageBackground>
                </Mobile>
            </>
        );
    }
}

export default withTranslation()(ConfirmEmail);
