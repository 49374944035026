import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { formatDate } from '../../../../Shared/Utilities/DateUtils';
import { Link } from 'react-router-dom';
import * as Routes from "../../../../Constants/Routes";
import { Entry } from 'contentful';
import { SecondaryButton } from '../../../../Shared/Components/Presentational/Buttons';
import MembershipStates from '../../../../Constants/MembershipStates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

interface ExpiredMembershipCardProps extends WithTranslation {
  membership: MembershipDTO;
  contentfulMembershipTypes: Entry<ContentfulMembershipType>[];
  deleteFunction?: (isOpen: boolean, membershipId: string) => void;
}

const ExpiredMembershipCard: React.FC<ExpiredMembershipCardProps> = (props: ExpiredMembershipCardProps) => {
  const { t } = props;

  const handleDelete = () => {
    props.membership && props.deleteFunction && props.deleteFunction(true, props.membership.id!);
  }

  const getMembershipText = (type: string) => {
    let text = "";
    let membershipType = props.contentfulMembershipTypes.find(mt => mt.sys.id === type);
    if (membershipType) {
      text = membershipType.fields.publicName;
    }
    return text;
  }

  return (
    <div
      className='container-box'
      style={{backgroundColor: "#f0ecec"}}
    >
      <div >
        <h4 style={{ fontWeight: "bold"}}>
          {props.membership.firstName + " " + props.membership.lastName}
          <div
            style={{ float: "right", cursor: "pointer", marginRight: "10px", marginTop: "5px" }}
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </h4>

        <div style={{ position: "absolute", bottom: "0", right: "0" }}>
          <Link to={Routes.RenewMembership + "?member-id=" + props.membership.memberId}>
            <SecondaryButton
              text={props.membership.hasRenewed ? "Renewed" : t("myMemberships." + MembershipStates.Expired + ".buttonText")}
              height={"30px"}
              fontSize={"14px"}
              disabled={props.membership.hasRenewed}
            />
          </Link>
          </div>
      </div>

      <p style={{ wordWrap: "break-word", clear: "left" }}>{props.membership && props.membership.email}</p>
      <p>{props.membership.membershipType}</p>
      <p>{`${formatDate(props.membership.startDate)} to ${formatDate(props.membership.endDate)}`}</p>
      <p style={{ float: "left", marginBottom: "0px" }}>{getMembershipText(props.membership.contentfulMembershipTypeId)}</p>
    </div>
  )
}

export default withTranslation()(ExpiredMembershipCard);
