import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Post } from '../../Api/ApiService';
import { PostConfirmPaymentCall } from '../../Api/Calls/PostConfirmPaymentCall';
import { MyAccountPageProps } from '../Components/Presentational/MyAccountPage';
import { MyAccountPageMobileProps } from '../Components/Presentational/MyAccountPage.mobile';

export default function useMyAccountPage(props: MyAccountPageProps | MyAccountPageMobileProps) {
    const [paymentResultModalOpen, setPaymentResultModalOpen] = useState(false);
    const [paymentResultModalText, setPaymentResultModalText] = useState('');
    const [refreshPage, setRefreshPage] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        props.onFilterChange(props.filter);

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const paymentResult = params.get('paymentSuccess') as string;

        if (paymentResult) {
            props.setPaymentWindowOpen(false);
            const paymentSuccess = paymentResult === 'true';
            if (paymentSuccess && props.paymentDetails) {
                const confirmPaymentDetails: ConfirmPaymentDTO = {
                    AccountId: props.ownerAccountId,
                    Amount: props.paymentDetails.amount,
                    ThirdPartyPaymentReference: props.paymentDetails.thirdPartyPaymentRef,
                };
                props.paymentConfirming();
                Post<ConfirmPaymentResultDTO>(new PostConfirmPaymentCall(confirmPaymentDetails)).then((result) => {
                    props.paymentConfirmed(result.data.CurrentRentLedgerBalance);
                    props.onFilterChange(props.filter);
                });
            }
            setPaymentResultModalOpen(true);
            setPaymentResultModalText(
                paymentSuccess ? t('myAccount.payment.paymentSuccess') : t('myAccount.payment.paymentFailure')
            );
        }
    }, []);

    const closePaymentResultModal = () => {
        setPaymentResultModalOpen(false);
        setPaymentResultModalText('');
        setRefreshPage(true);
    };

    return {
        closePaymentResultModal,
        paymentResultModalOpen,
        paymentResultModalText,
        refreshPage,
    };
}
