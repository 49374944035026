import { PutApiCall } from './ApiCall';

export class PutRenewSeasonPassesCall extends PutApiCall {
    constructor(lodgeId: string, passes: AddSeasonPassDTO[]) {
        let url: string = 'passes/' + lodgeId + '/renewSeasonPass';

        super(url);

        this.body = passes;
    }
}
