import React, { useState, useEffect } from 'react';


export const useIsDesktop = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [mounted, setMounted] = useState(true);

    useEffect(() => {
        //Credits to https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/
        /* Inside of a "useEffect" hook add an event listener that updates
           the "width" state variable when the window size changes */
        const listener = () => {
            if (mounted) {
                setWidth(window.innerWidth)
            }
        };

        window.addEventListener('resize', listener);
        /* passing an empty array as the dependencies of the effect will cause this
           effect to only run when the component mounts, and not each time it updates.
           We only want the listener to be added once */

        return () => {
            window.removeEventListener('resize', listener);
            setMounted(false);
        };

    }, []);

    return width >= 992;
}
export const Mobile = ({ children }: any) => {
    const isDesktop = useIsDesktop();
    return (<>{isDesktop ? null : children}</>);
}

export const Desktop = ({ children }: any) => {
    const isDesktop = useIsDesktop();
    return (<>{isDesktop ? children : null}</>);
}
