import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SecondaryButton } from '../../../Shared/Components/Presentational/Buttons';
import './PassesFilter.scss';
import classNames from 'classnames';

interface PassesFilterProps extends WithTranslation {
    selectedFilter: ParkPassTypeDTO;
    filters: ParkPassTypeDTO[];
    onFilterSelected: (filter: ParkPassTypeDTO) => void;
}

class PassesFilter extends Component<PassesFilterProps> {
    public render(): JSX.Element {
        const { t } = this.props;
        return (
            <div className="button-group passes-filter-buttons">
                {this.props.filters.map((filter, i) => (
                    <SecondaryButton
                        key={i}
                        type="button"
                        text={t(filter.NameTranslationKey)}
                        className={classNames({
                            active: this.props.selectedFilter && this.props.selectedFilter.Id === filter.Id
                        })}
                        onClick={this.props.onFilterSelected.bind(this, filter)}
                        icon={filter.Icon}
                        alternateText={filter.CurrentNumberOfPasses + '/' + filter.MaxNumberOfPasses}
                    />
                ))}
            </div>
        );
    }
}

export default withTranslation()(PassesFilter);
