import React from 'react';
import './Spinner.scss';

const Spinner: React.FC = props => {
    return (
        <div className="spinner">
            <div></div>
            <div></div>
        </div>
    );
};

export default Spinner;
