import React, { Component } from "react";
import { VerticalBackground } from "../../../Shared/Components/Presentational/Backgrounds";
import LoginPopup from "./LoginPopup";
import { Translation } from "react-i18next";
import "./LoginPage.scss";
import { GetHTMlContentBlockCall } from "../../../Contentful/Calls/GetHTMLContentBlockCall";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Spinner from "../../../Shared/Components/Presentational/Spinner";

interface Props {
  loginClick: (email: string, password: string, type: string) => void;
  isLoggingIn: boolean;
  loginFailureMessage: string;
  sessionHasExpired: boolean;
  parksLoading: boolean;
  availableParks: DropdownListOption[];
  selectedParkCode: string | undefined;
  parkSelected: (parkOption: DropdownListOption) => void;
  useParkSelection: boolean;
}

interface State {
  welcomeText: ContentfulContentObject | undefined;
  contentLoaded: boolean;
}

class LoginPage extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      welcomeText: undefined,
      contentLoaded: false,
    }
    Promise.all([
      this.getWelcomeText("My Haulfryn - Membership - Pre login", "welcomeText")
    ]).then(r => {
      r.forEach(c => this.setState({ ...this.state, [c.name]: c, contentLoaded: true }));
    })
  }

  getWelcomeText = async (name: string, label: string): Promise<ContentfulContentObject> => {
    const welcomeText = await GetHTMlContentBlockCall(name, label);
    return welcomeText;
  }

  render() {
    return (
      <>
        {this.state.contentLoaded ? (
          <Translation>
            {(t: (key: string) => string) => {
              return (
                <div className="login-page-container">
                  <VerticalBackground
                    src="/images/desktop-login-image.svg"
                    containerClassName="login-page-vertical-background"
                  >
                    <h1>{t("login.welcomeTitle")}</h1>
                    <div className="login-welcome-message">
                      {documentToReactComponents(this.state.welcomeText!.content)}
                    </div>

                  </VerticalBackground>
                  <LoginPopup
                    loginClick={this.props.loginClick}
                    isLoggingIn={this.props.isLoggingIn}
                    loginFailureMessage={this.props.loginFailureMessage}
                    sessionHasExpired={this.props.sessionHasExpired}
                    parksLoading={this.props.parksLoading}
                    availableParks={this.props.availableParks}
                    selectedParkCode={this.props.selectedParkCode}
                    parkSelected={this.props.parkSelected}
                    useParkSelection={this.props.useParkSelection}
                  />
                </div>
              );
            }}
          </Translation>
        ) : (
          <div className='memberships-spinner'>
            <Spinner />
          </div>
        )}
      </>
    );
  }
}

export default LoginPage;
