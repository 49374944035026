import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { ClickableIcon } from '../../../Shared/Components/Presentational/Icons';
import { CustomColumnCellRendererProps } from '../../../Shared/Components/Presentational/Table';
import useDownloadInvoice from '../../Hooks/useDownloadInvoice';
import './TransactionInvoiceCellRenderer.scss';

const TransactionInvoiceCellRenderer: React.FC<CustomColumnCellRendererProps> = ({ rowValue }) => {
    const download = useDownloadInvoice(rowValue.InvoiceNo);

    if (!rowValue.HasInvoice) {
        return null;
    }

    return (
        <div style={{ textAlign: 'right' }}>
            <ClickableIcon icon={faFileDownload} clickAction={download} className="download-invoice" />
        </div>
    );
};

export default TransactionInvoiceCellRenderer;
