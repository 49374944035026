import React, { ReactNode } from 'react';
import './Alert.scss';

interface AlertProps {
    title?: string;
    content?: ReactNode;
}

export default function Alert({ title, content }: AlertProps) {
    return (
        <div className="error-message show alert-container">
            {title && <p dangerouslySetInnerHTML={{ __html: title }}></p>}
            {content && content}
        </div>
    );
}
