import * as ActionTypes from './ActionTypes';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../RootReducer';
import { Action } from 'redux';
import { Get, Post, Delete, Put } from '../../Api/ApiService';
import { PostPassesSearchCall } from '../../Api/Calls/PostPassesSearchCall';
import { PostAddCarPassesCall } from '../../Api/Calls/PostAddCarPassesCall';
import { GetParkPassTypesCall } from '../../Api/Calls/GetParkPassTypesCall';
import { PostAddSeasonPassesCall } from '../../Api/Calls/PostAddSeasonPassesCall';
import { PostAddShortStayPassesCall } from '../../Api/Calls/PostAddShortStayPassesCall';
import { DeleteCarPassCall } from '../../Api/Calls/DeleteCarPassCall';
import { DeleteShortStayPassCall } from '../../Api/Calls/DeleteShortStayPassCall';
import { PutRenewSeasonPassesCall } from '../../Api/Calls/PutRenewSeasonPassesCall';
import { DeleteSeasonPassCall } from '../../Api/Calls/DeleteSeasonPassCall';
import { PostAnalyzeFaceCall } from '../../Api/Calls/PostAnalyzeFaceCall';
import { GetPassPhotoSizeLimit } from '../../Api/Calls/GetPassPhotoSizeLimit';
import { PostAddMembershipCall } from '../../Api/Calls/PostAddMembershipCall';
import { GetMembershipsCall } from '../../Api/Calls/GetMembershipsCall';
import { GetAddMembershipByIdCall } from '../../Api/Calls/GetAddMembershipByIdCall';
import { MembershipForm } from '../../MembershipForm';
import { Console } from 'console';
import { PostAgeVerificationFileCall } from '../../Api/Calls/PostAgeVerificationFileCall';
import { disableIncrementalParsing, isConstructorDeclaration } from 'typescript';
import { GetEditMemberDetailsByIdCall } from '../../Api/Calls/GetEditMemberDetailsByIdCall';
import { EditMemberDetailsForm } from '../../EditMemberDetailsForm';
import { PostUpdateEditMemberDetailsCall } from '../../Api/Calls/PostUpdateEditMemberDetailsCall';
import { PostCreateMembershipBasket } from '../../Api/Calls/PostCreateMembershipBasket';
import * as MembershipStates from '../../Constants/MembershipMappedStates';
import * as StatesOrder from '../../Constants/MembershipMappingStatesOrder';
import { DeleteMembershipCall } from '../../Api/Calls/DeleteMembershipCall';
import imageCompression from 'browser-image-compression';
import { GetMembershipTypesCall } from '../../Contentful/Calls/GetMembershipTypes';
import { Entry } from 'contentful';
import { GetReferralCall } from '../../Api/Calls/GetReferralCall';
import { GetEntryCall } from '../../Contentful/Calls/GetEntryCall';
import { PostAssociateMembershipWithReferredUserCall } from '../../Api/Calls/PostAssociateMembershipWithReferredUserCall';
import { SET_IS_REFERRED_USER } from '../../User/Redux/ActionTypes';
import { FilterMembershipTermTimesForOwnersFromDecommissionedParksCall } from '../../Api/Calls/FilterMembershipTermTimesForAberafonOwnersCall';

export const setSelectedPassType =
  (
    selectedPassType: ParkPassTypeDTO | undefined,
    callback: (() => ThunkAction<void, AppState, null, Action>) | undefined = undefined
  ): ThunkAction<void, AppState, null, Action> =>
    async (dispatch) => {
      dispatch({ type: ActionTypes.SET_SELECTED_PARK_PASS_TYPE, payload: selectedPassType });

      if (callback) {
        dispatch(callback());
      }
    };

export const updateFilter =
  (newFilter: PassFilterDTO): ThunkAction<void, AppState, null, Action> =>
    async (dispatch) => {
      dispatch({ type: ActionTypes.SET_PASSES_FILTER, payload: newFilter });
      dispatch(getPasses());
    };

export const getPasses = (): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
  const filter: PassFilterDTO = getState().guests.passesFilter;

  if (filter.ParkPassTypeId) {
    dispatch({ type: ActionTypes.SEARCH_PASSES_REQUEST, payload: true });
    Post<PassHeaderDTO>(new PostPassesSearchCall(filter))
      .then((response) => {
        dispatch({ type: ActionTypes.SEARCH_PASSES_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SEARCH_PASSES_FAILURE, payload: error });
      });
  }
};

export const getPassPhotoSizeLimit = (): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
  dispatch({ type: ActionTypes.GET_PASS_PHOTO_SIZE_LIMIT_REQUEST, payload: true });
  Get<any>(new GetPassPhotoSizeLimit())
    .then((response) => {
      dispatch({ type: ActionTypes.GET_PASS_PHOTO_SIZE_LIMIT_SUCCESS, payload: response.data.PhotoPassSizeLimit });
    })
    .catch((error) => {
      dispatch({ type: ActionTypes.GET_PASS_PHOTO_SIZE_LIMIT_FAILURE, payload: error });
    });
}

export const getParkPassTypes = (): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
  const park = getState().user.park;
  const lodge: SelectedLodgeDTO = getState().user.lodge;

  dispatch({ type: ActionTypes.GET_PARK_PASS_TYPE_REQUEST, payload: true });
  Get<ParkPassTypeDTO[]>(new GetParkPassTypesCall(park.parkCode, lodge.LodgeId.toString(), lodge.AccountId))
    .then((response) => {
      const selectedPassType = getState().guests.selectedParkPassType;

      if (
        !selectedPassType ||
        (selectedPassType &&
          !selectedPassType.Id &&
          response.data.filter((pt) => pt.Id === selectedPassType.Id).length === 0)
      ) {
        if (response.data && response.data.length > 0) {
          dispatch(setSelectedPassType(response.data[0], getPasses));
        }
      } else {
        dispatch(
          setSelectedPassType(
            response.data.find((pt) => pt.Id === selectedPassType.Id),
            getPasses
          )
        );
        dispatch(getPasses());
      }

      dispatch({ type: ActionTypes.GET_PARK_PASS_TYPE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: ActionTypes.GET_PARK_PASS_TYPE_FAILURE, payload: error });
    });
};

export const addCarPasses =
  (passes: AddCarPassDTO[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      const { LodgeId, AccountId } = getState().user.lodge;
      const parkPassType = getState().guests.selectedParkPassType;

      passes = passes.map((pass) => {
        return { ...pass, LodgeId, AccountId, ParkPassTypeId: parkPassType.Id };
      });

      dispatch({ type: ActionTypes.ADD_CAR_PASS_REQUEST, payload: true });
      Post<AddCarPassDTO[]>(new PostAddCarPassesCall(LodgeId, passes))
        .then((response) => {
          dispatch({ type: ActionTypes.SET_IS_ADD_PASS_MODAL_OPEN, payload: false });
          dispatch({ type: ActionTypes.ADD_CAR_PASS_SUCCESS, payload: response.data });
          dispatch({ type: ActionTypes.SET_ADD_PASS_SUCCESSFUL_MODAL_OPEN, payload: true });
          dispatch(getParkPassTypes());
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.ADD_CAR_PASS_FAILURE, payload: error });
        });
    };

export const addSeasonPasses =
  (passes: AddSeasonPassDTO[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      const { LodgeId, AccountId } = getState().user.lodge;
      const parkPassType = getState().guests.selectedParkPassType;

      passes = passes.map((pass) => {
        return { ...pass, LodgeId, AccountId, ParkPassTypeId: parkPassType.Id };
      });

      passes.forEach((pass) => {
        var dob = pass.DateOfBirth.split("/");
        pass.DOBDay = parseInt(dob[0]);
        pass.DOBMonth = parseInt(dob[1]);
        pass.DOBYear = parseInt(dob[2]);
        pass.DateOfBirth = "";
      })

      dispatch({ type: ActionTypes.ADD_SEASON_PASS_REQUEST, payload: true });
      Post<AddSeasonPassDTO[]>(new PostAddSeasonPassesCall(LodgeId, passes))
        .then((response) => {
          dispatch({ type: ActionTypes.SET_IS_ADD_PASS_MODAL_OPEN, payload: false });
          dispatch({ type: ActionTypes.ADD_SEASON_PASS_SUCCESS, payload: response.data });
          dispatch({ type: ActionTypes.SET_ADD_PASS_SUCCESSFUL_MODAL_OPEN, payload: true });
          dispatch(getParkPassTypes());
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.ADD_SEASON_PASS_FAILURE, payload: error });
        });
    };

export const addShortStayPasses =
  (passes: AddShortStayPassDTO[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      const { LodgeId, AccountId } = getState().user.lodge;
      const parkPassType = getState().guests.selectedParkPassType;

      passes = passes.map((pass) => {
        return { ...pass, LodgeId, AccountId, ParkPassTypeId: parkPassType.Id };
      });

      passes.forEach((pass) => {
        var dob = pass.DateOfBirth.split("/");
        pass.DOBDay = parseInt(dob[0]);
        pass.DOBMonth = parseInt(dob[1]);
        pass.DOBYear = parseInt(dob[2]);
        pass.DateOfBirth = "";
        var start = pass.StartDate.split("/");
        pass.StartDay = parseInt(start[0]);
        pass.StartMonth = parseInt(start[1]);
        pass.StartYear = parseInt(start[2]);
        pass.StartDate = "";
      })

      dispatch({ type: ActionTypes.ADD_SHORT_STAY_PASS_REQUEST, payload: true });
      Post<AddShortStayPassDTO[]>(new PostAddShortStayPassesCall(LodgeId, passes))
        .then((response) => {
          dispatch({ type: ActionTypes.SET_IS_ADD_PASS_MODAL_OPEN, payload: false });
          dispatch({ type: ActionTypes.ADD_SHORT_STAY_PASS_SUCCESS, payload: response.data });

          dispatch({ type: ActionTypes.SET_ADD_PASS_SUCCESSFUL_MODAL_OPEN, payload: true });
          dispatch(getParkPassTypes());
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.ADD_SHORT_STAY_PASS_FAILURE, payload: error });
        });
    };

export const removeCarPass =
  (passId: string): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({ type: ActionTypes.SET_DELETE_PASS_CONFIRMATION_MODAL_OPEN, payload: false });
      dispatch({ type: ActionTypes.DELETE_PASS_REQUEST });
      Delete(new DeleteCarPassCall(getState().user.lodge.LodgeId, passId))
        .then(() => {
          dispatch({ type: ActionTypes.DELETE_PASS_SUCCESS });
          dispatch(getParkPassTypes());
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.DELETE_PASS_FAILURE, payload: error });
        });
    };

export const removeSeasonPass =
  (passId: string): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({ type: ActionTypes.SET_DELETE_PASS_CONFIRMATION_MODAL_OPEN, payload: false });
      dispatch({ type: ActionTypes.DELETE_PASS_REQUEST });
      Delete(new DeleteSeasonPassCall(getState().user.lodge.LodgeId, passId))
        .then(() => {
          dispatch({ type: ActionTypes.DELETE_PASS_SUCCESS });
          dispatch(getParkPassTypes());
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.DELETE_PASS_FAILURE, payload: error });
        });
    };

export const removeShortStayPass =
  (passId: string, passFriendlyName: string): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({ type: ActionTypes.SET_DELETE_PASS_CONFIRMATION_MODAL_OPEN, payload: false });
      dispatch({ type: ActionTypes.DELETE_PASS_REQUEST });
      Delete(new DeleteShortStayPassCall(getState().user.lodge.LodgeId, passId, passFriendlyName))
        .then(() => {
          dispatch({ type: ActionTypes.DELETE_PASS_SUCCESS });
          dispatch(getParkPassTypes());
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.DELETE_PASS_FAILURE, payload: error });
        });
    };

export const renewSeasonPasses =
  (passes: AddSeasonPassDTO[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      const lodgeId = getState().user.lodge.LodgeId;
      const parkPassType = getState().guests.selectedParkPassType;

      passes = passes.map((pass) => {
        return { ...pass, LodgeId: lodgeId, ParkPassTypeId: parkPassType.Id };
      });

      dispatch({ type: ActionTypes.RENEW_SEASON_PASS_REQUEST, payload: true });
      Put<AddSeasonPassDTO[]>(new PutRenewSeasonPassesCall(lodgeId, passes))
        .then((response) => {
          dispatch({ type: ActionTypes.SET_IS_RENEW_PASS_MODAL_OPEN, payload: false });
          dispatch({ type: ActionTypes.RENEW_SEASON_PASS_SUCCESS, payload: response.data });
          dispatch({ type: ActionTypes.SET_RENEW_PASS_SUCCESSFUL_MODAL_OPEN, payload: true });
          dispatch(getParkPassTypes());
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.RENEW_SEASON_PASS_FAILURE, payload: error });
        });
    };

export const analyzePassPhoto =
  (passPhotoFile: File): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({ type: ActionTypes.ANALYZE_PASS_PHOTO_REQUEST, payload: true });
      Post<any>(new PostAnalyzeFaceCall(passPhotoFile))
        .then((response) => {
          dispatch({ type: ActionTypes.ANALYZE_PASS_PHOTO_SUCCESS, payload: response.data });
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.ANALYZE_PASS_PHOTO_FAILURE, payload: error.response.data });
        });
    };


export const addMembership =
  (membership: NewMembershipDTO): ThunkAction<Promise<boolean>, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({ type: ActionTypes.ADD_MEMBERSHIP_REQUEST, payload: true });
      if (membership.dateOfBirth) {
        if (membership.dateOfBirth.getTimezoneOffset() === -60 && membership.dateOfBirth!.getHours() <= 0) {
          membership.dateOfBirth!.setMinutes(membership.dateOfBirth!.getMinutes() + 60);
        }
      }

      if (membership.startDate) {
        if (membership.startDate.getTimezoneOffset() === -60 && membership.startDate.getHours() <= 0) {
          membership.startDate!.setMinutes(membership.startDate!.getMinutes() + 60);
        }
      }

      let newReferredMembership = !getState().user.hasOwnMembership && membership.membershipState === "Initial";

      let apiSucceeded = await Post<any>(new PostAddMembershipCall(membership))
        .then((response) => {
          membership.membershipState = response.data.Data.MembershipState;
          membership.id = response.data.Data.Id;
          membership.memberId = response.data.Data.MemberId;
          membership.ageVerificationSent = new Date(response.data.Data.AgeVerificationSent);
          membership.contentfulAgeBracketPriceId = response.data.Data.ContentfulAgeBracketPriceId;
          membership.parkId = response.data.Data.ParkId;
          membership.memberId = response.data.Data.MemberId;
          let form = { ...getState().guests.membershipForm };
          form.id = response.data.Data.Id;
          form.membershipState = response.data.Data.MembershipState;
          form.contentfulAgeBracketPriceId = response.data.Data.ContentfulAgeBracketPriceId;
          if (response.data.Data.Vehicles) {
            form.vehicles = { value: [], errors: [] };
            for (let vehicle of response.data.Data.Vehicles) {
              form.vehicles!.value = form.vehicles!.value.concat([{ id: vehicle.Id, registration: vehicle.Registration, description: vehicle.Description }])
            }
          }
          if (newReferredMembership) {
            if (getState().user.loginEmail === membership.email) {
              dispatch(associateMembershipWithReferredUser(membership));
            }
          }
          dispatch({ type: ActionTypes.UPDATE_MEMBERSHIP_FORM, payload: form });
          dispatch({ type: ActionTypes.ADD_MEMBERSHIP_SUCCESS, payload: membership });
          return true;
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.ADD_MEMBERSHIP_FAILURE, payload: error.response.data.Message });
          return false;
        });
      return apiSucceeded;
    };

export const associateMembershipWithReferredUser =
  (membership: NewMembershipDTO): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      Post<any>(new PostAssociateMembershipWithReferredUserCall(membership))
        .then((response) => {
          dispatch({ type: SET_IS_REFERRED_USER, payload: true });
        })
    }

export const updateNewMembership =
  (fieldName: any, value: any): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      const form = getState().guests.newMembership;
      dispatch({ type: ActionTypes.UPDATE_NEW_MEMBERSHIP, payload: { ...form, [value]: fieldName } })
    }

export const updateMembershipForm =
  (fieldName: any, value: any, errors: string[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      const form = { ...getState().guests.membershipForm };
      form[value] = { value: fieldName, errors: errors };
      dispatch({ type: ActionTypes.UPDATE_MEMBERSHIP_FORM, payload: form })
    }

export const updateMembershipFormBasket =
  (membershipForm?: IMembershipForm, membershipBasket?: IMembershipForm[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      let basket = getState().guests.membershipBasket;
      if (membershipForm) {
        basket = [...basket, membershipForm];
      } else if (membershipBasket) {
        basket = membershipBasket;
      }
      dispatch({ type: ActionTypes.UPDATE_MEMBERSHIP_BASKET, payload: basket });
    }

export const updateMembershipBasketField =
  (index: number, fieldName: any, value: any): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      let basket = getState().guests.membershipBasket;
      let form = basket[index];
      form[fieldName].value = value;
      basket[index] = form;
      dispatch({ type: ActionTypes.UPDATE_MEMBERSHIP_BASKET, payload: basket });
    }

export const createMembershipBasket =
  (membershipIds: string[]): ThunkAction<Promise<string>, AppState, null, Action> =>
    async (dispatch, getState): Promise<string> => {
      let membershipBasket = { membershipIds: membershipIds } as MembershipBasketDTO;

      dispatch({ type: ActionTypes.CREATE_MEMBERSHIP_BASKET_REQUEST });
      let basketId = await Post<BasketDTO>(new PostCreateMembershipBasket(membershipBasket))
        .then((response) => {
          dispatch({ type: ActionTypes.CREATE_MEMBERSHIP_BASKET_SUCCESS, payload: response.data.BasketId });
          return response.data.BasketId;
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.CREATE_MEMBERSHIP_BASKET_FAILURE });
          return "";
        })
      return basketId;
    }

export const getMyMemberships =
  (): ThunkAction<Promise<MembershipDTO[]>, AppState, null, Action> => {
    return async (dispatch, getState): Promise<MembershipDTO[]> => {
      dispatch({
        type: ActionTypes.GET_MY_MEMBERSHIPS_REQUEST,
        payload: true,
      });
      let memberships: MembershipDTO[] = await Get<MembershipDTO[]>(new GetMembershipsCall())
        .then((response) => {
          let tempMemberships: MembershipDTO[] = [];
          response.data.forEach((membership: any) => {
            tempMemberships.push({
              id: membership.Id,
              memberId: membership.MemberId,
              contentfulMembershipTypeId: membership.ContentfulMembershipTypeId,
              contentfulAgeBracketPriceId: membership.ContentfulAgeBracketPriceId,
              membershipState: membership.MembershipState,
              firstName: membership.FirstName,
              lastName: membership.LastName,
              createdDate: membership.CreatedDate,
              endDate: membership.EndDate,
              email: membership.Email,
              membershipType: membership.MembershipType,
              startDate: membership.StartDate,
              hasRenewed: membership.HasRenewed,
            } as MembershipDTO);
            memberships = tempMemberships;
          });
          dispatch({
            type: ActionTypes.GET_MY_MEMBERSHIPS_SUCCESS,
            payload: memberships as MembershipDTO[],
          });
          return memberships;
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.GET_MY_MEMBERSHIPS_FAILURE,
            payload: error,
          });
          return [];
        });
      return memberships;
    };
  }

export const getMyReferrals =
  (): ThunkAction<Promise<ReferralDTO[]>, AppState, null, Action> => {

    // API Integration is needed once datt is done with backend work.
    return async (dispatch, getState): Promise<ReferralDTO[]> => {

      dispatch({
        type: ActionTypes.GET_MY_REFERRAL_REQUEST,
        payload: true,
      });
      let referred1Memberships: ReferralDTO[] = await Get<ReferralDTO[]>(new GetReferralCall())
        .then((response) => {
          let tempReferrals: ReferralDTO[] = [];

          response.data.forEach((membership: any) => {
            tempReferrals.push({
              id: membership.referralId,
              firstName: membership.firstName,
              lastName: membership.lastName,
              email: membership.email,
              phoneNumber: membership.phoneNumber,
              transferredMemberships: membership.transferredMembershipsCount,
              allowFurtherReferrals: membership.allowFurtherReferrals,
              usedMembership: membership.usedMembership,
              availableMemberships: membership.availableMemberships,
              memberships: membership.memberships,
            } as ReferralDTO);
            referred1Memberships = tempReferrals;
          });
          dispatch({
            type: ActionTypes.GET_MY_REFERRAL_SUCCESS,
            payload: referred1Memberships as ReferralDTO[],
          });
          return referred1Memberships;
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.GET_MY_REFERRAL_FAILURE,
            payload: error,
          });
          return [];
        });
      return referred1Memberships;
    }
  }

export const mapMyMembershipsToComponents =
  (memberships: MembershipDTO[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      let membershipContainers: MembershipContainer[] | undefined = [];
      if (memberships && memberships.length > 0) {
        for (let membership of memberships) {
          if (MembershipStates.FinaliseStates.includes(membership.membershipState) || MembershipStates.FinaliseStates.indexOf(membership.membershipState) !== -1) {
            membershipContainers = membershipContainers.concat({ state: "Finalise", membership: membership });
          } else if (MembershipStates.DraftStates.includes(membership.membershipState) || MembershipStates.DraftStates.indexOf(membership.membershipState) !== -1) {
            membershipContainers = membershipContainers.concat([{ state: "Draft", membership: membership }]);
          } else if (MembershipStates.ActiveStates.includes(membership.membershipState) || MembershipStates.ActiveStates.indexOf(membership.membershipState) !== -1) {
            membershipContainers = membershipContainers.concat([{ state: "Active", membership: membership }]);
          } else if (MembershipStates.ReferredStates.includes(membership.membershipState) || MembershipStates.ReferredStates.indexOf(membership.membershipState) !== -1) {
            membershipContainers = membershipContainers.concat([{ state: "Referred", membership: membership }])
          } else if (MembershipStates.ExpiredStates.includes(membership.membershipState) || MembershipStates.ExpiredStates.indexOf(membership.membershipState) !== -1) {
            membershipContainers = membershipContainers.concat([{ state: "Expired", membership: membership }])
          }
        }
        membershipContainers.sort((a: MembershipContainer, b: MembershipContainer) => StatesOrder.StatesOrder.indexOf(a.state) - StatesOrder.StatesOrder.indexOf(b.state));
      }
      if (membershipContainers.length < 1) {
        membershipContainers = undefined;
      }
      dispatch({
        type: ActionTypes.SET_MEMBERSHIP_COMPONENTS,
        payload: membershipContainers
      });
    }

export const mapMyReferralsToComponents =
  (referredMemberships: ReferralDTO[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({
        type: ActionTypes.SET_REFERRED_MEMBERSHIP_COMPONENTS,
        payload: referredMemberships
      });
    }

export const getMembershipsThenMapToContainers =
  (): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      await dispatch(getMyMemberships())
        .then((r) => {
          dispatch(mapMyMembershipsToComponents(r));
        });
    }

export const getReferredMembershipsByOwner =
  (): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      await dispatch(getMyReferrals()) // getMyReferrals
        .then((r) => {
          dispatch(mapMyReferralsToComponents(r)); //mapReferredMembershipToComponents
        });
    }

export const deleteMembership =
  (membershipId: string): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      let membershipContainers = getState().guests.membershipContainers;
      if (membershipContainers.find((m: MembershipContainer) => m.membership.id === membershipId) && membershipContainers.find((m: MembershipContainer) => m.membership.id === membershipId)!.state === "Finalise") {
        let finaliseNowContainers = getState().guests.finaliseNowContainers;
        let finaliseLaterContainers = getState().guests.finaliseLaterContainers;
        if (finaliseNowContainers.find((m: MembershipContainer) => m.membership.id === membershipId)) {
          finaliseNowContainers.splice(finaliseNowContainers.findIndex((m: MembershipContainer) => m.membership.id === membershipId), 1);
          dispatch({
            type: ActionTypes.SET_FINALISE_NOW_CONTAINERS,
            payload: finaliseNowContainers,
          });
        } else if (finaliseLaterContainers.find((m: MembershipContainer) => m.membership.id === membershipId)) {
          finaliseLaterContainers.splice(finaliseLaterContainers.findIndex((m: MembershipContainer) => m.membership.id === membershipId), 1);
          dispatch({
            type: ActionTypes.SET_FINALISE_LATER_CONTAINERS,
            payload: finaliseLaterContainers,
          });
        }
      }
      membershipContainers.splice(membershipContainers.findIndex((m: MembershipContainer) => m.membership.id === membershipId), 1);
      dispatch({
        type: ActionTypes.SET_MEMBERSHIP_COMPONENTS,
        payload: membershipContainers,
      });
      let memberships = getState().guests.memberships;
      memberships.splice(memberships.findIndex((m: MembershipDTO) => m.id === membershipId), 1);
      dispatch({
        type: ActionTypes.GET_MY_MEMBERSHIPS_SUCCESS,
        payload: memberships,
      })
      Delete<any>(new DeleteMembershipCall(membershipId));
    }

export const getAddMembershipById =
  (membershipId: string): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({
        type: ActionTypes.GET_ADD_MEMBERSHIP_BY_ID_REQUEST,
        payload: true,
      });
      Get<any>(
        new GetAddMembershipByIdCall(membershipId)
      )
        .then(async (response) => {
          let membershipForm = await MembershipForm.fromAddMembership(response.data);
          let newMembership: NewMembershipDTO = {
            id: response.data.Id,
            contentfulMembershipTypeId: response.data.ContentfulMembershipTypeId,
            myHaulfrynAccess: response.data.MyHaulfrynAccess,
            membershipState: response.data.MembershipState,
            startDate: response.data.StartDate,
            marketing: response.data.Marketing,
            firstName: response.data.FirstName,
            lastName: response.data.LastName,
            dateOfBirth: response.data.DateOfBirth,
            email: response.data.Email,
            phonenumber: response.data.PhoneNumber,
            contentfulAgeBracketPriceId: response.data.ContentfulAgeBracketPriceId,
            ageVerificationSent: new Date(response.data.ageVerificationSent),
            memberId: response.data.MemberId
          }
          if (response.data.Vehicles) {
            newMembership.vehicles = [];
            for (let vehicle of response.data.Vehicles) {
              newMembership.vehicles = newMembership.vehicles!.concat([{ registration: vehicle.Registration as string, description: vehicle.Description as string }])
            }
          }
          dispatch({
            type: ActionTypes.GET_ADD_MEMBERSHIP_BY_ID_SUCCESS,
            payload: { newMembership: newMembership, membershipForm: membershipForm },
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.GET_ADD_MEMBERSHIP_BY_ID_FAILURE,
            payload: error,
          });
        });
    };

export const getEditMemberDetailsById =
  (membershipId: string): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({
        type: ActionTypes.GET_EDIT_MEMBERSHIP_BY_ID_REQUEST,
        payload: true,
      });
      Get<any>(
        new GetEditMemberDetailsByIdCall(membershipId)
      )
        .then((response) => {
          let editMemberDetailsForm = EditMemberDetailsForm.fromEditMemberDetailsDTOBack(response.data);
          let editMemberDetails: EditMemberDetailsDTO = {
            id: response.data.Id,
            firstName: response.data.FirstName,
            lastName: response.data.LastName,
            email: response.data.Email,
            phonenumber: response.data.PhoneNumber,
            dateOfBirth: new Date(response.data.DateOfBirth),
            cardNumber: response.data.CardNumber,
          }
          if (response.data.Vehicles) {
            editMemberDetails.vehicles = [];
            for (let vehicle of response.data.Vehicles) {
              editMemberDetails.vehicles = editMemberDetails.vehicles!.concat([{ registration: vehicle.Registration as string, description: vehicle.Description as string }])
            }
          }
          dispatch({
            type: ActionTypes.GET_EDIT_MEMBERSHIP_BY_ID_SUCCESS,
            payload: { editMemberDetails: editMemberDetails, editMemberDetailsForm: editMemberDetailsForm },
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.GET_EDIT_MEMBERSHIP_BY_ID_FAILURE,
            payload: error,
          });
        });
    };

export const updateEditMemberDetails =
  (fieldName: any, value: any): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      const form = getState().guests.editMemberDetails;
      dispatch({ type: ActionTypes.UPDATE_EDIT_MEMBERSHIP, payload: { ...form, [value]: fieldName } })
    }

export const updateEditMemberDetailsForm =
  (fieldName: any, value: any, errors: string[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      const form = getState().guests.editMemberDetailsForm;
      form[value] = { value: fieldName, errors: errors };
      dispatch({ type: ActionTypes.UPDATE_EDIT_MEMBERSHIP_FORM, payload: form })
    }

export const updateEditMemberDetailsRequest =
  (editMemberDetails: EditMemberDetailsDTO): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({ type: ActionTypes.UPDATE_EDIT_MEMBERSHIP_REQUEST })

      Post<any>(new PostUpdateEditMemberDetailsCall(editMemberDetails))
        .then(() => {
          dispatch({ type: ActionTypes.UPDATE_EDIT_MEMBERSHIP_SUCCESS });
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.UPDATE_EDIT_MEMBERSHIP_FAILURE, payload: error })
        })
    }

export const addAgeVerificationFile =
  (file: any, membershipId: string, firstName: string, lastName: string, dateOfBirth: Date): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({ type: ActionTypes.ADD_AGE_VERIFICATION_REQUEST });
      let name = firstName + " " + lastName;

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 700,
        useWebWorker: true,
      }

      const compressedFile = await imageCompression(file, options);

      Post<any>(new PostAgeVerificationFileCall(compressedFile, membershipId, name, dateOfBirth))
        .then(() => {
          dispatch({ type: ActionTypes.ADD_AGE_VERIFICATION_SUCCESS, payload: compressedFile });
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.ADD_AGE_VERIFICATION_ERROR, payload: error.response.data.Message })
        })

    }

export const getContentfulMembershipTypes =
  (withTerms?: boolean): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      const parkCode = getState().user.park && getState().user.park.parkCode;
      const membershipTypes = await GetMembershipTypesCall();
      let filteredTypes: Entry<ContentfulMembershipType>[] = [];
      if (membershipTypes && membershipTypes.length > 0) {
        if (parkCode) {
          filteredTypes = membershipTypes.filter(m => m.fields.parks.find(p => p.fields.code === parkCode) !== undefined);
        } else {
          filteredTypes = membershipTypes;
        }
      }
      let useMemberships = filteredTypes.length > 0;
      filteredTypes = await dispatch(filterMembershipTypesFromUserTypes(filteredTypes));
      if (withTerms) {
        dispatch(getTermDurations(filteredTypes));
      }
      dispatch({ type: ActionTypes.SET_CONTENTFUL_MEMBERSHIP_TYPES, payload: { filteredTypes: filteredTypes, useMemberships: useMemberships } });
    }

export const filterMembershipTypesFromUserTypes =
  (membershipTypes: Entry<ContentfulMembershipType>[]): ThunkAction<Promise<Entry<ContentfulMembershipType>[]>, AppState, null, Action> =>
    async (dispatch, getState): Promise<Entry<ContentfulMembershipType>[]> => {
      const userRoles: UserRoleDTO[] = getState().user.userRoles;
      const types: string[] = [];
      let filteredMembershipTypes: Entry<ContentfulMembershipType>[] = [];

      for (let role of userRoles) {
        let userType = await GetEntryCall(role.contentfulUserTypeId) as unknown as Entry<ContentfulUserType>;
        if (userType.fields.availableMembershipTypes) {
          userType.fields.availableMembershipTypes.map(type => types.push(type.sys.id));
        }
      }
      const distinctTypeIds = types.filter((type, index) => types.indexOf(type) === index);

      filteredMembershipTypes = membershipTypes.filter(t => distinctTypeIds.findIndex(id => t.sys.id === id) >= 0);

      let ownerMembershipsAllocated = getState().user.ownerMembershipsAvailability ? getState().user.ownerMembershipsAvailability.allocated : 1;
      if (ownerMembershipsAllocated === 0) {
        filteredMembershipTypes = filteredMembershipTypes.filter(t => !t.fields.debitsQuota);
      }

      return filteredMembershipTypes;
    }

export const getTermDurations =
  (membershipTypes: Entry<ContentfulMembershipType>[]): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      let termDurations: DropdownListOption[][] = [];
      try {
        for (let type = 0; type < membershipTypes.length; type++) {
          for (let ageGroup = 0; ageGroup < membershipTypes[type].fields.ageGroups.length; ageGroup++) {
            if (membershipTypes[type].fields.ageGroups[ageGroup].fields.prices) {
              for (let price = 0; price < membershipTypes[type].fields.ageGroups[ageGroup].fields.prices.length; price++) {
                let priceDuration = await GetEntryCall(membershipTypes[type].fields.ageGroups[ageGroup].fields.prices[price].sys.id);
                if (termDurations[type]) {
                  if (termDurations[type].filter(term => term.value === priceDuration.fields.termFormula).length < 1) {
                    termDurations[type] = termDurations[type].concat({ label: priceDuration.fields.termDescription, value: priceDuration.fields.termFormula });
                  }
                } else {
                  termDurations[type] = [{ label: priceDuration.fields.termDescription, value: priceDuration.fields.termFormula }];
                }
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }

      if (getState().user.decommissionedParks.find(c => c == getState().user.park.parkCode)) {
        let dto: MembershipTermTimesDTO = {
          membershipTerms: termDurations,
          contractEndDate: getState().user.lodge.ContractEndDate
        }
        await Post<any>(new FilterMembershipTermTimesForOwnersFromDecommissionedParksCall(dto))
          .then((terms) => {
            console.log(terms);
            termDurations = terms.data as unknown as DropdownListOption[][]
          })
          .catch((error) => {
            console.log(error);
          })
      }

      dispatch({ type: ActionTypes.SET_TERM_DURATIONS, payload: termDurations });
    }