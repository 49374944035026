import React, { Component } from "react";
import {
  Desktop,
  Mobile,
} from "../../../Shared/Components/Presentational/ResponsiveViews";
import { connect } from "react-redux";
import { ColumnDefinition } from "../../../Shared/Components/Presentational/Table";
import { compose } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { Entry } from "contentful";
import MyRequestsAndQueriesPage from "../Presentational/MyRequestsAndQueriesPage";
import MyRequestsAndQueriesPageMobile from "../Presentational/MyRequestsAndQueriesPage.mobile";
import { Post } from "../../../Api/ApiService";
import { PostFreshDeskTicketsSearchCall } from "../../../Api/Calls/PostFreshDeskTicketsSearchCall";
import { PostFreshDeskConversationSearchCall } from "../../../Api/Calls/PostFreshDeskConversationSearchCall";
import { PostFreshDeskTicketReplyCall } from "../../../Api/Calls/PostFreshDeskTicketReplyCall";
import { GetParkCall } from "../../../Contentful/Calls/GetParkCall";
import DateColumnCellRenderer from "../../../Shared/Components/Presentational/DateColumnCellRenderer";
import ShortenedTextCellRenderer from "../../../Shared/Components/Presentational/ShortenedTextCellRenderer";
import QuickReplyModal from "../Presentational/QuickReplyModal";
import "./MyRequestsAndQueries.scss";

interface MyRequestsAndQueriesProps extends WithTranslation {
  dispatch: any;
  selectedPark: any;
  loginEmail: string;
}

interface MyRequestAndQueryState {
  columns: ColumnDefinition[];
  rowToDelete: any;
  filter: OrderedFilterDTO;
  conversationFilter: OrderedFilterDTO;
  pagedResults: PagedResultDTO;
  loadingResults: boolean;
  contentfulPark?: Entry<ContentfulPark>;
  selectedTicket?: FreshDeskTicketDTO;
  conversations?: PagedResultDTO;
  conversationLoading: boolean;
  postReplyLoading: boolean;
  showQuickReplyModal: boolean;
}

class MyRequestsAndQueries extends Component<
  MyRequestsAndQueriesProps,
  MyRequestAndQueryState
> {
  public getTickets() {
    this.setState({ loadingResults: true });
    Post<PagedResultDTO>(new PostFreshDeskTicketsSearchCall(this.state.filter))
      .then((success) => {
        this.setState({ pagedResults: success.data });
      })
      .finally(() => {
        this.setState({ loadingResults: false });
      });
  }

  getConversations = (ticketId: string) => {
    Post<PagedResultDTO>(
      new PostFreshDeskConversationSearchCall(
        ticketId,
        this.state.conversationFilter
      )
    )
      .then((success) => {
        this.setState({ conversations: success.data });
      })
      .finally(() => {
        this.setState({ conversationLoading: false });
      });
  };

  postQuickReply = (message: string) => {
    if (this.state.selectedTicket == null) {
      return;
    }

    this.setState({ postReplyLoading: true });
    Post(
      new PostFreshDeskTicketReplyCall(this.state.selectedTicket.Id, message)
    )
      .then((_) => {
        this.getConversations(this.state.selectedTicket!.Id);
      })
      .finally(() => {
        this.setState({ postReplyLoading: false, showQuickReplyModal: false });
      });
  };

  onFilterChange = (filter: OrderedFilterDTO) => {
    this.setState({ filter });
    this.getTickets();
  };

  public componentDidMount() {
    GetParkCall(this.props.selectedPark.parkCode).then((result) => {
      this.setState({
        contentfulPark: result,
      });
    });
    this.getTickets();
  }

  onTicketSelect = (ticket: FreshDeskTicketDTO) => {
    this.setState({ selectedTicket: ticket, conversationLoading: true });
    this.getConversations(ticket.Id);
  };

  loadMoreConvesations = () => {
    if (
      !this.state.selectedTicket ||
      !this.state.conversations ||
      this.state.conversationLoading
    ) {
      return;
    }

    const totalPages = Math.max(
      1,
      Math.ceil(
        this.state.conversations.Total / this.state.conversationFilter.PageSize
      )
    );

    if (this.state.conversationFilter.Page < totalPages) {
      const filter = { ...this.state.conversationFilter };

      filter.Page = 1;
      if (filter.PageSize < 20) {
        filter.PageSize = 20;
      } else {
        filter.PageSize += 20;
      }

      this.setState({ conversationFilter: filter });
      this.getConversations(this.state.selectedTicket.Id);
    }
  };

  public state: MyRequestAndQueryState = {
    columns: [
      {
        fieldName: "Id",
        displayName: "requestsAndQueries.tickets.ticketNumber",
        canShowColumn: (_) => true,
        bold: true,
      },
      {
        fieldName: "Subject",
        displayName: "requestsAndQueries.tickets.subject",
        canShowColumn: (_) => true,
        customCellRenderer: ShortenedTextCellRenderer,
        bold: true,
      },
      {
        fieldName: "GroupName",
        displayName: "requestsAndQueries.tickets.group",
        canShowColumn: (_) => true,
      },
      {
        fieldName: "StatusCode",
        displayName: "requestsAndQueries.tickets.status",
        canShowColumn: (_) => true,
      },
      {
        fieldName: "GetCreatedAt",
        displayName: "requestsAndQueries.tickets.dateSubmitted",
        customCellRenderer: DateColumnCellRenderer,
        canShowColumn: (_) => true,
      },
      {
        fieldName: "GetUpdatedAt",
        displayName: "requestsAndQueries.tickets.lastUpdated",
        customCellRenderer: DateColumnCellRenderer,
        canShowColumn: (_) => true,
      },
    ],
    rowToDelete: undefined,
    contentfulPark: undefined,
    filter: { PageSize: 20, Page: 1 },
    conversationFilter: { PageSize: 20, Page: 1 },
    pagedResults: { Total: 0, PageSize: 20, Dtos: [] },
    loadingResults: true,
    selectedTicket: undefined,
    conversations: undefined,
    conversationLoading: false,
    postReplyLoading: false,
    showQuickReplyModal: false,
  };

  public render(): JSX.Element {
    return (
      <>
        <Desktop>
          <MyRequestsAndQueriesPage
            openQuickReplyModal={() => {
              this.setState({ showQuickReplyModal: true });
            }}
            setSelectedTicket={(ticket) =>
              this.setState({ selectedTicket: ticket })
            }
            loadMoreConvesations={this.loadMoreConvesations}
            columns={this.state.columns}
            selectedTicket={this.state.selectedTicket}
            pagedResults={this.state.pagedResults}
            filter={this.state.filter}
            onFilterChange={this.onFilterChange}
            pageSize={20}
            ticketsLoading={this.state.loadingResults}
            conversationLoading={this.state.conversationLoading}
            conversations={this.state.conversations}
            isHeaderOpen={false}
            onTicketClicked={this.onTicketSelect}
            loginEmail={this.props.loginEmail}
            contactEmail={
              this.state.contentfulPark
                ? this.state.contentfulPark!.fields.emailAddress
                : undefined
            }
          />
        </Desktop>
        <Mobile>
          <MyRequestsAndQueriesPageMobile
            openQuickReplyModal={() => {
              this.setState({ showQuickReplyModal: true });
            }}
            setSelectedTicket={(ticket) =>
              this.setState({ selectedTicket: ticket })
            }
            loadMoreConvesations={this.loadMoreConvesations}
            columns={this.state.columns.filter(
              (c) => !["StatusCode", "GetCreatedAt"].includes(c.fieldName)
            )}
            selectedTicket={this.state.selectedTicket}
            pagedResults={this.state.pagedResults}
            filter={this.state.filter}
            onFilterChange={this.onFilterChange}
            pageSize={20}
            ticketsLoading={this.state.loadingResults}
            conversationLoading={this.state.conversationLoading}
            conversations={this.state.conversations}
            isHeaderOpen={false}
            onTicketClicked={this.onTicketSelect}
            loginEmail={this.props.loginEmail}
            contactEmail={
              this.state.contentfulPark
                ? this.state.contentfulPark!.fields.emailAddress
                : undefined
            }
          />
        </Mobile>
        <QuickReplyModal
          isOpen={this.state.showQuickReplyModal}
          onBackButtonClick={() => {
            this.setState({ showQuickReplyModal: false });
          }}
          sendReply={this.postQuickReply}
          isLoading={this.state.postReplyLoading}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { selectedPark: state.user.park, loginEmail: state.user.loginEmail };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(MyRequestsAndQueries);
function getFieldList(FunctionalityCode: any): FormField<AddPassDTO>[] {
  throw new Error("Function not implemented.");
}
