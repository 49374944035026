import React from 'react';
import { useTranslation } from 'react-i18next';
import './ParkRestaurantButton.scss';

interface ParkRestaurantButtonProps {
    restaurant: PromotedRestaurant;
    onClick: () => void;
}

const ParkRestaurantButton: React.FC<ParkRestaurantButtonProps> = ({ restaurant, onClick }) => {
    const { t } = useTranslation();
    return (
        <div className="restaurant-button" onClick={onClick}>
            <div className="restaurant-image">
                <img src={restaurant.fields.image ? restaurant.fields.image.fields.file.url : '/images/default-restaurant-image.png'} alt="Restaurant" />
            </div>
            <p className="restaurant-name">{ restaurant.fields.name }</p>
        </div>
    );
};

export default ParkRestaurantButton;
