import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import classNames from "classnames";
import { Entry } from "contentful";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../../Shared/Components/Presentational/Buttons";
import { Modal, ModalHeader, ModalProps } from "../../../Shared/Components/Presentational/Modal";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";

interface MembershipFlowContactPromptModalProps extends ModalProps {
  isOpen: boolean;
  promptType: string;
  setMembershipContactWarningModalOpen: (isOpen: boolean) => void;
  onNextStep: (form: any) => void;
  form: any;
  modalTextWOutEmail: ContentfulContentObject;
  modalTextWOutMobile: ContentfulContentObject;
  modalTextWOutBoth: ContentfulContentObject;
}

const MembershipFlowContactPromptModal: React.FC<MembershipFlowContactPromptModalProps> = (props) => {
  const { t } = useTranslation();
  const open = props.isOpen;
  const setMembershipContactWarningModalOpen = props.setMembershipContactWarningModalOpen;
  const handleClick1 = async () => {
    setMembershipContactWarningModalOpen(false);
  }

  const handleClick2 = async () => {
    setMembershipContactWarningModalOpen(false);
    props.onNextStep(props.form);
  }

  const formContents = (
    <div className='button-container-modal'>
      <div className='container' style={{ textAlign: "center" }}>
        {
          props.promptType == "email" &&
          <ModalHeader text={props.modalTextWOutEmail.title} />

        }
        {
          props.promptType == "email" && documentToReactComponents(props.modalTextWOutEmail.content)
        }
        {
          props.promptType == "phone" &&
          <ModalHeader text={props.modalTextWOutMobile.title} />
        }
        {
          props.promptType == "phone" && documentToReactComponents(props.modalTextWOutMobile.content)
        }
        {
          props.promptType == "emailandphone" &&
          <ModalHeader text={props.modalTextWOutBoth.title} />
        }
        {
          props.promptType == "emailandphone" && documentToReactComponents(props.modalTextWOutBoth.content)
        }


        <PrimaryButton
          id="continue"
          text={t("general.continue")}
          className="footer-button"
          onClick={handleClick2}
        />
        <SecondaryButton
          id="close"
          text={t("general.close")}
          className="footer-button"
          onClick={handleClick1}
        />
      </div>
    </div>
  )

  return (
    <>
      <Modal
        isOpen={open}
        className={classNames("owner-contact-details-modal refermodal", props.className)}
      >
        <Desktop>
          <form>{formContents}</form>
        </Desktop>
        <Mobile>
          <form className='owner-contact-details-modal-mobile-form'>
            {formContents}
          </form>
        </Mobile>
      </Modal>
    </>
  );
}

export default MembershipFlowContactPromptModal;
