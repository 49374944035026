import React, { ChangeEvent, useEffect, useState } from 'react';
import { Desktop, Mobile } from '../../../../Shared/Components/Presentational/ResponsiveViews';
import { WithTranslation, withTranslation } from 'react-i18next';

interface VehiclesFormProps extends WithTranslation {
  allowVehicles: boolean;
  vehicle1: VehicleDTOBack;
  vehicle2: VehicleDTOBack;
  showErrors: boolean;
  onVehicle1Update: (vehicle: VehicleDTOBack) => void;
  onVehicle2Update: (vehicle: VehicleDTOBack) => void;
}

const VehiclesForm: React.FC<VehiclesFormProps> = (props) => {
  const { t } = props;

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const key = e.target.name as keyof VehicleDTOBack;
    switch (index) {
      case 0:
        props.onVehicle1Update({ ...props.vehicle1, [key]: e.target.value})
        break;
      case 1:
        props.onVehicle2Update({ ...props.vehicle1, [key]: e.target.value})
        break;
    }
  }

  return (
    <>
    { 
      props.allowVehicles
      ? (<>
        <Desktop>
          {true && (
            // <div className="contentful-text">
            //   {documentToReactComponents(props.description.content)}
            // </div>
            <div>Please provide the registration number of this member's vehicles that you would like to enter the park.</div>
          )}
          {/* <p>{t("addMembership.vehicles.message")}</p> */}
          <h3>Vehicle 1:</h3>
          <table className="vehicle-table">
            <tbody>
              <tr>
                <th>Registration Number</th>
                <th>Description (optional)</th>
              </tr>
              <tr>
                <td style={{ width: "25%" }}>
                  <input
                    name="Registration"
                    onChange={event => handleFormChange(event, 0)}
                    value={props.vehicle1.Registration}
                    className="add-form-input form-input"
                  />
                </td>

                <td style={{ width: "60%" }}>
                  <input
                    name="Description"
                    onChange={event => handleFormChange(event, 0)}
                    value={props.vehicle1.Description}
                    className="add-form-input form-input"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <h3>Vehicle 2:</h3>
          <table className="vehicle-table">
            <tbody>
              <tr>
                <th>Registration Number</th>
                <th>Description (optional)</th>
              </tr>
              <tr>
                <td style={{ width: "25%" }}>
                  <input
                    name="Registration"
                    onChange={event => handleFormChange(event, 1)}
                    value={props.vehicle2.Registration}
                    className="add-form-input form-input"
                  />
                </td>

                <td style={{ width: "60%" }}>
                  <input
                    name="Description"
                    onChange={event => handleFormChange(event, 1)}
                    value={props.vehicle2.Description}
                    className="add-form-input form-input"
                  />
                </td>
              </tr>
            </tbody>

            <br></br>

            {/*
            *** There is no validation currently ***
            {
              (props.showErrors && (props.membershipForm.vehicles.errors && props.membershipForm.vehicles.errors.length > 0)) &&
              <p style={{ textAlign: "left", color: "red" }}>{props.membershipForm && t("addMembership.errors." + trimmedErrors(props.membershipForm.vehicles.errors))}</p>
            } */}
          </table>
        </Desktop>
        <Mobile>
          {true && (
            // <div className="contentful-text">
            //   {documentToReactComponents(props.description.content)}
            // </div>
            <div>Please provide the registration number of this member's vehicles that you would like to enter the park.</div>
          )}
          <h3>Vehicle 1:</h3>
          <table className="vehicle-table">
            <tbody>
              <tr>
                <th>Registration Number</th>
              </tr>
              <tr>
                <td>
                  <input
                    name="Registration"
                    onChange={event => handleFormChange(event, 0)}
                    value={props.vehicle1.Registration}
                    className="add-form-input form-input"
                  />
                </td>
              </tr>
              <tr>
                <th>Description (optional)</th>
              </tr>
              <tr>
                <td>
                  <input
                    name="Description"
                    onChange={event => handleFormChange(event, 0)}
                    value={props.vehicle1.Description}
                    className="add-form-input form-input"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <h3>Vehicle 2:</h3>
          <table className="vehicle-table">
            <tbody>
              <tr>
                <th>Registration Number</th>
              </tr>
              <tr>
                <td>
                  <input
                    name="Registration"
                    onChange={event => handleFormChange(event, 1)}
                    value={props.vehicle2.Registration}
                    className="add-form-input form-input"
                  />
                </td>
              </tr>
              <tr>
                <th>Description (optional)</th>
              </tr>
              <tr>
                <td>
                  <input
                    name="Description"
                    onChange={event => handleFormChange(event, 1)}
                    value={props.vehicle2.Description}
                    className="add-form-input form-input"
                  />
                </td>
              </tr>
            </tbody>
            <br></br>
            {/* 
            *** There is no validation currently *** 
            {
              (props.showErrors && (props.membershipForm.vehicles.errors && props.membershipForm.vehicles.errors.length > 0)) &&
              <p style={{ textAlign: "left", color: "red" }}>{props.membershipForm && t("addMembership.errors." + trimmedErrors(props.membershipForm.vehicles.errors))}</p>
            } */}
          </table>
        </Mobile>
      </>
    ) : (
      <>
        <p>This member is under 16 and is therefore not able to add vehicles</p>
        <p>Please continue to the next section</p>
      </>
    )}
  </>
  )
}

export default withTranslation()(VehiclesForm);