import { GetApiCall } from "./ApiCall";

export class GetMembershipEndDateCall extends GetApiCall {
    constructor(date: Date, termFormula: string) {
        let url: string = "MembershipTerm/GetMembershipEndDate";
        let startDate = date !== null ? date.toDateString() : null;
        let params: any = {startDate, termFormula};

        super(url, params);
    }
}