import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faQuestionCircle,
    IconDefinition,
    faExclamationCircle,
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { CustomColumnCellRendererProps } from '../../../Shared/Components/Presentational/Table';
import './PassStatusColumnCellRenderer.scss';
import PassStatus from '../../../Constants/PassStatus';

const getIconByStatusCode = (code: string): IconDefinition => {
    switch (code) {
        case PassStatus.AwaitingApproval:
        case PassStatus.AwaitingPhoto:
            return faQuestionCircle;
        case PassStatus.Expired:
            return faExclamationCircle;
        case PassStatus.Approved:
            return faCheckCircle;
        default:
            return faQuestionCircle;
    }
};

const getClassNameStatusCode = (code: string): string => {
    switch (code) {
        case PassStatus.AwaitingApproval:
        case PassStatus.AwaitingPhoto:
            return 'awaiting-approval';
        case PassStatus.Expired:
            return 'expired';
        case PassStatus.Approved:
            return 'approved';
        default:
            return '';
    }
};

const PassStatusColumnCellRenderer: React.FC<CustomColumnCellRendererProps> = props => {
    return (
        <div className={'pass-status-cell ' + getClassNameStatusCode(props.rowValue.StatusCode)}>
            <FontAwesomeIcon icon={getIconByStatusCode(props.rowValue.StatusCode)}></FontAwesomeIcon>
            <div className="pass-status-cell-value">{props.rowValue.Status}</div>
        </div>
    );
};

export { getIconByStatusCode, getClassNameStatusCode, PassStatusColumnCellRenderer as default };
