import React from 'react';
import { Link } from 'react-router-dom';
import * as Routes from "../../../Constants/Routes";

const HomeButton: React.FC = () => (
    <div className='header-logo-container'>
        <Link to={Routes.Home}>
            <img src='/images/MyHaulfryn-Logo.png' alt='' />
        </Link>
    </div>
)


export default HomeButton;