import * as ActionTypes from "./ActionTypes";
import { SET_IS_UPDATE_MY_PASSWORD_SUCCES_MODAL_OPEN } from "../../Modal/Redux/ActionTypes";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../../RootReducer";
import { Action } from "redux";
import { Get, Post } from "../../Api/ApiService";
import { GetMyContactDetailsCall } from "../../Api/Calls/GetMyContactDetailsCall";
import { PostMyContactDetailsCall } from "../../Api/Calls/PostMyContactDetailsCall";
import { PostSetPasswordCall } from "../../Api/Calls/PostSetPasswordCall";
import { GetMarketingPreferencesCall } from "../../Api/Calls/GetMarketingPreferencesCall";
import { PostMarketingPreferencesCall } from "../../Api/Calls/PostMarketingPreferencesCall";
import { GetShowMembershipPromptCall } from "../../Api/Calls/GetShowMembershipPromptCall";
import { PostDontShowAgainMembershipModalCall } from "../../Api/Calls/PostDontShowAgainMembershipModalCall";
import { GetOwnerMembershipAvailabilityCall } from "../../Api/Calls/GetOwnerMembershipAvailabilityCall";
import { getPermissionsFromUserRoles } from "../../Login/Redux/ActionCreators";

export const setUserLodge =
  (lodge: SelectedLodgeDTO): ThunkAction<void, AppState, null, Action> =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.SET_SELECTED_LODGE,
      payload: lodge,
    });
  };

export const setUserPark =
  (park: {
    parkCode: string;
    parkName: string;
    parkFeatures: string[];
  }): ThunkAction<void, AppState, null, Action> =>
  async (dispatch) => {
    dispatch({ type: ActionTypes.SET_SELECTED_PARK, payload: park });
  };

export const getMyContactDetails =
  (): ThunkAction<void, AppState, null, Action> =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.GET_MY_CONTACT_DETAILS_REQUEST,
      payload: true,
    });
    Get<MyContactDetailsDTO>(
      new GetMyContactDetailsCall()
    )
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_MY_CONTACT_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.GET_MY_CONTACT_DETAILS_FAILURE,
          payload: error,
        });
      });
  };

export const updateMyContactDetails =
  (
    newContactDetails: UpdateContactDetailsDTO
  ): ThunkAction<void, AppState, null, Action> =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_MY_CONTACT_DETAILS_REQUEST,
      payload: true,
    });
    Post<MyContactDetailsDTO>(new PostMyContactDetailsCall(newContactDetails))
      .then((response) => {
        dispatch({
          type: ActionTypes.UPDATE_MY_CONTACT_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.UPDATE_MY_CONTACT_DETAILS_FAILURE,
          payload: error,
        });
      });
  };

export const getMarketingPreferences = (): ThunkAction<void, AppState, null, Action> =>
  async (dispatch, getState) => {
    Get<MarketingPreferencesDTO>(new GetMarketingPreferencesCall(getState().user.ownerId))
      .then((success) => {
        dispatch({
          type: ActionTypes.GET_MARKETING_PREFERENCES_SUCCESS,
          payload: success.data,
        });
      })
      .catch((error) => {
        console.log("Error getting marketing preferences", error);
      });
  };

export const updateMarketingPreferences = 
  (newPreferences: MarketingPreferencesDTO): ThunkAction<void, AppState, null, Action> =>
  async (dispatch, getState) => {
    Post<MarketingPreferencesDTO>(new PostMarketingPreferencesCall(getState().user.ownerId, newPreferences))
      .then((result) => {
        dispatch({
          type: ActionTypes.SET_MARKETING_PREFERENCES_SUCCESS,
          payload: result.data,
        });
        dispatch({
          type: SET_IS_UPDATE_MY_PASSWORD_SUCCES_MODAL_OPEN,
          payload: true,
        });
      })
      .catch((error) => {
        console.log("Error saving marketing preferences", error);
      });
  };

export const updateMyPassword =
  (newPassword: SetPasswordDto): ThunkAction<void, AppState, null, Action> =>
  async (dispatch, getState) => {
    dispatch({ type: ActionTypes.SET_MY_PASSWORD_REQUEST, payload: true });
    Post<SetPasswordDto>(new PostSetPasswordCall(newPassword))
      .then((response) => {
        dispatch({ type: ActionTypes.SET_MY_PASSWORD_SUCCESS });
        dispatch({
          type: SET_IS_UPDATE_MY_PASSWORD_SUCCES_MODAL_OPEN,
          payload: true,
        });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SET_MY_PASSWORD_FAILURE, payload: error });
      });
  };

export const getShowMembershipPrompt =
  (): ThunkAction<void, AppState, null, Action> =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.GET_SHOW_MEMBERSHIP_PROMPT_REQUEST,
      payload: true,
    });
    Get<boolean>(
      new GetShowMembershipPromptCall()
    )
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_SHOW_MEMBERSHIP_PROMPT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.GET_SHOW_MEMBERSHIP_PROMPT_FAILURE,
          payload: error,
        });
      });
  };

export const setDontShowAgainMembershipModal = 
  (): ThunkAction<void, AppState, null, Action> =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_REQUEST,
      payload: true,
    });
    Get<boolean>(new PostDontShowAgainMembershipModalCall())
      .then((response) => {
        dispatch({
          type: ActionTypes.SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.SET_DONT_SHOW_AGAIN_MEMBERSHIP_MODAL_FAILURE,
          payload: error,
        })
      })
  }

export const getOwnerMembershipAvailability =
  (): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({ type: ActionTypes.GET_OWNER_MEMBERSHIP_AVAILABILITY_REQUEST });
      Get<any>(
        new GetOwnerMembershipAvailabilityCall()
      )
        .then((response) => {
          const ownerAvailability = { allocated: response.data.Allocated, numberUsed: response.data.NumberUsed, available: response.data.Available } as OwnerMembershipAvailabilityDTO;
          dispatch({
            type: ActionTypes.GET_OWNER_MEMBERSHIP_AVAILABILITY_SUCCESS,
            payload: ownerAvailability,
          })
        })
        .then(r => {
          dispatch(getPermissionsFromUserRoles());
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.GET_OWNER_MEMBERSHIP_AVAILABILITY_FAILURE,
            payload: error,
          })
        })
    }