import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Widget } from "../../../Shared/Components/Presentational/Widget";
import Fab from "../../../Shared/Components/Presentational/Fab";
import "./MyRequestsAndQueriesPage.scss";
import Table, {
  ColumnDefinition,
} from "../../../Shared/Components/Presentational/Table";
import { PrimaryButton } from "../../../Shared/Components/Presentational/Buttons";
import TicketList from "./TicketList";
import ConversationList from "./ConversationList";

interface MyRequestsAndQueriesPageProps extends WithTranslation {
  columns: ColumnDefinition[];
  pagedResults: PagedResultDTO;
  filter: OrderedFilterDTO;
  onFilterChange: (newFilter: any) => void;
  loadMoreConvesations: () => void;
  pageSize: number;
  isHeaderOpen: boolean;
  contactEmail?: string;
  onTicketClicked: (ticket: FreshDeskTicketDTO) => void;
  setSelectedTicket: (ticket?: FreshDeskTicketDTO) => void;
  selectedTicket?: FreshDeskTicketDTO;
  conversations?: PagedResultDTO;
  conversationLoading: boolean;
  ticketsLoading: boolean;
  openQuickReplyModal: () => void;
  loginEmail: string;
}

class MyRequestsAndQueriesPage extends Component<MyRequestsAndQueriesPageProps> {
  public render(): JSX.Element {
    const { t, contactEmail, selectedTicket, loginEmail } = this.props;

    return (
      <div className="widget-container requests-and-queries">
        {!this.props.isHeaderOpen && (
          <>
            <h1 className="widget-layout-title">
              {t("requestsAndQueries.title")}
            </h1>
            <p className="widget-layout-message">
              {t("requestsAndQueries.message", { loginEmail })}
            </p>
          </>
        )}
        {!selectedTicket && (
          <TicketList
            contactEmail={contactEmail}
            onTicketClicked={this.props.onTicketClicked}
            isLoading={this.props.ticketsLoading}
            columns={this.props.columns}
            pagedResults={this.props.pagedResults}
            filter={this.props.filter}
            onFilterChange={this.props.onFilterChange}
          />
        )}
        {selectedTicket && (
          <ConversationList
            openQuickReplyModal={this.props.openQuickReplyModal}
            onBackPress={() => this.props.setSelectedTicket()}
            selectdTicket={this.props.selectedTicket!}
            isLoading={this.props.conversationLoading}
            conversations={this.props.conversations!}
            filter={this.props.filter}
            loadMoreResults={this.props.loadMoreConvesations}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(MyRequestsAndQueriesPage);
