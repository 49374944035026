import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import React, { ChangeEvent } from 'react';
import "./FormStyles.scss";

interface InputFieldProps {
  name: string;
  formInput: FormInput;
  labeltranslationKey: string;
  onInput: (value: any, name: any, errors: string[]) => void;
  containerClass?: string;
  showErrors?: boolean;
  validators: Validator[];
  type?: string;
  disabled?: boolean;
  tabindex?: number;
}



const InputField: React.FC<InputFieldProps> = (props: InputFieldProps) => {
  const [input, setInput] = useState(props.formInput ? props.formInput.value : '');

  useEffect(() => {
    if (props.formInput && props.formInput.value !== input) {
      setInput(props.formInput.value);
    }
  }, [props.formInput]);

  function updateValue(e: ChangeEvent<HTMLInputElement>) {
    const errors: string[] = [];
    for (let validator of props.validators) {
      if (!validator.validator(e.target.value)) {
        errors.push(validator.name);
      }
    }
    setInput(e.target.value);
    props.onInput(e.target.value, props.name, errors);
  }

  const { t } = useTranslation();

  return (
    <div className={props.containerClass}>
      <label htmlFor={props.name} className="temp-text">
        {t(props.labeltranslationKey)}
      </label>
      <br />
      {props.disabled ? (
        <p style={{ marginTop: "10px", marginLeft: "10px", wordWrap: "break-word" }}>{input}</p>
      ) : (
        <input
          id={props.name}
          name={props.name}
          type={props.type ? props.type : "string"}
          value={input}
          onChange={updateValue}
          className="add-form-input form-input"
          disabled={props.disabled}
          tabIndex={props.tabindex}
        />
      )}
      {(props.showErrors && (props.formInput.errors && props.formInput.errors.length > 0)) &&
        <p style={{ textAlign: "left", color: "red" }}>{props.formInput && t("addMembership.errors." + props.formInput.errors[0])}</p>
      }
    </div>
  );
}

export default InputField;
