import ContentfulClient from  "../ContentfulClient";
import { TagsFilter } from "../TagsFilter";
import { GetParkCall } from "./GetParkCall";

export async function GetParkEventsCall(park: { parkCode: string }, page: number, pageSize: number,userRoles: UserRoleDTO[]) { 
    const parkDetail = await GetParkCall(park.parkCode);
    let filter = {
        content_type: 'event',
        links_to_entry: parkDetail.sys.id, 
        skip: page * pageSize,
        limit: pageSize
    }
    
    const result =  await ContentfulClient.getEntries<ContentfulEvent>(filter);

    if (result.errors) {
        throw {message: "GetParkEventsCall returned error/s", errors: result.errors} 
    }

    let indexToBeRemoved: Array<string> = [];
    if(userRoles.length > 0){
        result.items.forEach((content)=>{
            if(new TagsFilter().removeContent(content,userRoles)){
                indexToBeRemoved.push(content.sys.id);
            }
        });
    }
    

    if (indexToBeRemoved.length !== 0){
        indexToBeRemoved.forEach((val)=>{
            result.items.forEach((content,index)=>{
                if(content.sys.id === val){
                    result.items.splice(index,1);        
                }
            });
        })
    }

    return result;
}