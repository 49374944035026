import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader } from '../../../Shared/Components/Presentational/Modal';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { Calendar, CalendarEvent } from '../../../Shared/Components/Presentational/Calendar';
import { Get, Post } from '../../../Api/ApiService';
import { PostSearchLodgeBookingsCall } from '../../../Api/Calls/PostSearchLodgeBookingsCall';
import { GetHotTubServiceTypesCall } from '../../../Api/Calls/GetHotTubServiceTypesCall';
import { GetBookingTypesCall } from '../../../Api/Calls/GetBookingTypesCall';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { NativeDatePicker } from '../../../Shared/Components/Presentational/NativeDatePicker';
import DropdownList from '../../../Shared/Components/Presentational/DropdownList';
import { isAfterDate } from '../../../Shared/Utilities/Validation';


//@ts-ignore
import Chips from 'react-chips';
import Chip from '../../../Shared/Components/Presentational/Chip';
import { SIGFPE } from 'constants';

interface AddBookingModalBookingFormProps {
    className?: string;
    lodgeId: number;
    isOpen: boolean;
    startDate: string;
    startDateChange: (date: string) => void;
    endDate: string;
    endDateChange: (date: string) => void;
    hotTubServiceTypeId: string;
    hotTubServiceTypeChange: (type: DropdownListOption) => void,
    bookingTypeId: string;
    bookingTypeChange: (type: DropdownListOption) => void,
    addBookingErrorMessage: string | undefined;
    datesValid: boolean;
    setDatesValid: (datesValid: boolean) => void;
    calendarEvents: CalendarEvent[];
    setBookingFilter: (bookingFilter : any) => any;
    selectedDate?: Date;
}

const AddBookingModalBookingForm: React.FC<AddBookingModalBookingFormProps> = (props) => {
    const { t } = useTranslation();

    const [hotTubServiceTypes, setHotTubServiceTypes] = useState<DropdownListOption[]>([]);
    const [bookingTypes, setBookingTypes] = useState<DropdownListOption[]>([]);

    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [modalCalendarDate, setModalCalendarDate] = useState<Date | undefined>(props.selectedDate);

    const [startDateValid, setStartDateValid] = useState<boolean>(true);
    const [endDateValid, setEndDateValid] = useState<boolean>(true);
    

    useEffect(() => {
        async function popaulateInitialData() {

            if (props.startDate)
                setStartDate(moment(props.startDate, 'DD/MM/YYYY').toDate());

            if (props.endDate)
                setEndDate(moment(props.endDate, 'DD/MM/YYYY').toDate());

            const hotTubServiceTypes = await Get<ForeignKeyItem[]>(new GetHotTubServiceTypesCall());
            const bookingTypes = await Get<ForeignKeyItem[]>(new GetBookingTypesCall());

            setHotTubServiceTypes(
                hotTubServiceTypes.data.map((htst) => {
                    return { label: htst.Name, value: htst.Id };
                })
            );
            setBookingTypes(
                bookingTypes.data.map((bt) => {
                    return { label: bt.Name, value: bt.Id };
                })
            );
        }
        if (props.isOpen) {
            popaulateInitialData();
        }
    }, [props.isOpen]);

    const onRangeChange = (range: { start: any; end: any }) => {
        props.setBookingFilter((oldFilter: BookingFilterDTO) => ({
            ...oldFilter,
            FromDate: moment(range.start).format('DD/MM/YYYY'),
            ToDate: moment(range.end).format('DD/MM/YYYY'),
        }));
    };

    const hasBookingConflict = (): boolean => {
        return props.addBookingErrorMessage !== undefined && props.addBookingErrorMessage == 'EXISTING_BOOKING';
    };

    const handleStartDateInputChange = (date: Date | null) => {
        let dateString = '';
        let date2 = undefined;
        if (date !== null) {
            dateString = moment(date).format('DD/MM/YYYY');
            date2 = date;
        }

        props.startDateChange(dateString);

        setStartDate(date2);

        const validResult =
            date2 !== undefined &&
            isAfterDate(moment(date2).format('DD/MM/YYYY'), moment().add(2, 'days').format('DD/MM/YYYY'));
        setStartDateValid(validResult);
        props.setDatesValid(endDateValid || validResult);
    };

    const handleEndDateInputChange = (date: Date | null) => {
        let dateString = '';
        let date2 = undefined;
        if (date !== null) {
            dateString = moment(date).format('DD/MM/YYYY');
            date2 = date;
        }

        props.endDateChange(dateString);

        setEndDate(date2);

        const validResult =
            date2 !== undefined && isAfterDate(moment(date2).format('DD/MM/YYYY'), props.startDate);
        setEndDateValid(validResult);
        props.setDatesValid(startDateValid && validResult);


    };

    const minDate = moment().startOf('month').toDate();
    const maxDate = moment().add(12, 'month').endOf('month').toDate();

    return (
        <form className={props.className}>
            <ModalHeader text={t('myCalendar.addBooking.mainTitle')} />

            <Desktop>
                <div className="calendar-widget add-booking-calendar-widget">
                    <Calendar
                        className="add-booking-calendar"
                        minDate={minDate}
                        maxDate={maxDate}
                        events={props.calendarEvents}
                        resources={[]}
                        onRangeChange={onRangeChange}
                        onNavigate={(date: Date) => setModalCalendarDate(date)}
                        defaultDate={modalCalendarDate}
                    />
                </div>
            </Desktop>

            <Mobile>
                <div className="calendar-widget add-booking-calendar-widget mobile">
                    <Calendar
                        className="add-booking-calendar mobile-calendar"
                        minDate={minDate}
                        maxDate={maxDate}
                        events={props.calendarEvents}
                        resources={[]}
                        onRangeChange={onRangeChange}
                        onNavigate={(date: Date) => setModalCalendarDate(date)}
                        defaultDate={modalCalendarDate}
                    />
                </div>
            </Mobile>

            <ModalHeader text={t('myCalendar.addBooking.title')} />

            <p
                className={classNames('info-message show')}
                dangerouslySetInnerHTML={{ __html: t('myCalendar.addBooking.onlineBookings') }}
            ></p>

            <div className="add-booking-field">
                <label htmlFor="startDate">{t('general.startDate')}</label>
                <Desktop>
                    <DatePicker
                        selected={startDate}
                        required={true}
                        onChange={handleStartDateInputChange}
                        placeholderText="dd/mm/yyyy"
                        className={!startDateValid || hasBookingConflict() ? 'form-input invalid' : 'form-input'}
                        dateFormat="dd/MM/yyyy"    
                        openToDate ={modalCalendarDate}                    
                    />
                </Desktop>
                <Mobile>
                    <NativeDatePicker
                        name="startDate"
                        placeholder="DD/MM/YYYY"
                        onChange={handleStartDateInputChange}
                        selected={startDate}
                        invalid={!startDateValid || hasBookingConflict()}
                        openToDate ={modalCalendarDate}   
                    />
                </Mobile>
            </div>
            <div className="add-booking-field">
                <label htmlFor="endDate">{t('general.endDate')}</label>
                <Desktop>
                    <DatePicker
                        selected={endDate}
                        required={true}
                        onChange={handleEndDateInputChange}
                        placeholderText="dd/mm/yyyy"
                        className={!endDateValid || hasBookingConflict() ? 'form-input invalid' : 'form-input'}
                        dateFormat="dd/MM/yyyy"
                        openToDate ={startDate || modalCalendarDate} 
                        
                    />
                </Desktop>
                <Mobile>
                    <NativeDatePicker
                        name="endDate"
                        placeholder="DD/MM/YYYY"
                        onChange={handleEndDateInputChange}
                        selected={endDate}
                        invalid={!endDateValid || hasBookingConflict()}
                        openToDate ={startDate || modalCalendarDate}   
                    />
                </Mobile>
            </div>

            <div className="add-booking-field">
                <label htmlFor="bookingtype">{t('myCalendar.addBooking.whoIsBookingFor')}</label>
                <DropdownList
                    options={bookingTypes}
                    classNamePrefix=""
                    onSelectionChanged={props.bookingTypeChange}
                    selectedOption={
                        bookingTypes && bookingTypes.find((o) => o.value === props.bookingTypeId)
                    }
                />
            </div>
            <div className="add-booking-field">
                <label htmlFor="hottubservice">{t('myCalendar.addBooking.hotTubService')}</label>
                <DropdownList
                    options={hotTubServiceTypes}
                    classNamePrefix=""
                    onSelectionChanged={props.hotTubServiceTypeChange}
                    selectedOption={
                        hotTubServiceTypes &&
                        hotTubServiceTypes.find((o) => o.value === props.hotTubServiceTypeId)
                    }
                />
            </div>

            <p className={props.addBookingErrorMessage ? 'error-message show' : 'error-message'}>
                {hasBookingConflict()
                    ? t('myCalendar.addBooking.existingBooking')
                    : props.addBookingErrorMessage
                        ? t('myCalendar.addBooking.generalError')
                        : ''}
            </p>
        </form>)

};

export default AddBookingModalBookingForm;