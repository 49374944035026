import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from 'react-accessible-accordion';
import { WithTranslation, withTranslation } from 'react-i18next';
//@ts-ignore
import Zoom from 'react-reveal/Zoom';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import ContactModal from '../../../Shared/Components/Presentational/ContactModal';
import Description from '../../../Shared/Components/Presentational/Description';
import Details from '../../../Shared/Components/Presentational/Details';
import { Widget, WidgetList } from '../../../Shared/Components/Presentational/Widget';
import NewsCardContainer from '../Container/NewsCardContainer';
import ImportantInformationWidget from './ImportantInformationWidget';
import './MyParkAndHomePage.scss';
import * as Routes from '../../../Constants/Routes';
import { Redirect } from 'react-router-dom';
import { Entry } from 'contentful';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import UserRoles from '../../../Constants/UserRoles';

interface MyParkAndHomePageProps extends WithTranslation {
    lodgeDetails: Map<string, string>;
    isHeaderOpen: boolean;
    parkName: string;
    closeMyContactParkModal: () => void;
    openMyContactParkModal: () => void;
    isContactParkModalOpen: boolean;
    faqs: Entry<ContentfulFAQ>[];
    importantInformation: Entry<ContentfulHTMLContentBlock>[];
    parkInformation: Entry<ContentfulParkInformation>;
    setSelectedImportantInformation: (selectedImportantInformationId: string) => void;
    setSelectedNewsItem: (selectedNewsItemId: string) => void;
    userRoles: UserRoleDTO[];
}

interface MyParkAndHomePageState {
    navigateTo: string | undefined;
}

class MyParkAndHomePage extends Component<MyParkAndHomePageProps, MyParkAndHomePageState> {
    public state: MyParkAndHomePageState = {
        navigateTo: undefined
    };

    navigateToImportantInfo(information: Entry<ContentfulHTMLContentBlock>): void {
        if (information && information.sys.id) {
            this.props.setSelectedImportantInformation(information.sys.id);
            this.setState({ navigateTo: Routes.ImportantInformation });
        }
    }

    public render(): JSX.Element {
        const { t } = this.props;
        const options = {
            renderNode: {
                [INLINES.HYPERLINK]: ({ data }: any, children: any) => {
                    return <a href={data.uri} target={data.uri.startsWith(window.origin) ? '' : '_blank'}>{children}</a>;
                }
            }
        }

        return (
            <>
                {this.state.navigateTo && <Redirect to={this.state.navigateTo} />}
                {!this.state.navigateTo && (
                    <div className="widget-container">
                        {!this.props.isHeaderOpen && (!UserRoles.IsOwner(this.props.userRoles) ? (
                            <>
                                <h1 className="widget-layout-title">{t('myParkAndHome.memberTitle')}</h1>
                                <p className="widget-layout-message">{t('myParkAndHome.memberMessage')}</p>
                            </>
                        ) : (
                            <>
                                <h1 className="widget-layout-title">{t('myParkAndHome.title')}</h1>
                                <p className="widget-layout-message">{t('myParkAndHome.message')}</p>
                            </>
                        ))}
                        <Widget
                            position={{ columnIndex: 1, columnSpan: 9, rowIndex: 3, rowSpan: 12 }}
                            containerClassName="widget-ie-fix"
                        >
                            <div className="information-row">
                                <div className="contact-row">
                                    {this.props.parkInformation && <Description
                                        descriptionHeader={this.props.parkName}
                                        description={this.props.parkInformation.fields.aboutThePark}
                                        descriptionHeaderClassName="park-home-title"
                                        descriptionClassName="park-home-description"
                                        descriptionContainerClassName="description-container"
                                        descriptionSubHeader={t('myParkAndHome.aboutThePark')}
                                        descriptionSubHeaderClassName="park-home-sub-title"
                                    ></Description>}
                                    <PrimaryButton
                                        className="park-home-contact-park"
                                        onClick={this.props.openMyContactParkModal}
                                        text={t('myParkAndHome.contactPark')}
                                    ></PrimaryButton>
                                </div>

                                <div className="park-home-park-image-container">
                                    {this.props.parkInformation && this.props.parkInformation.fields.image && <Zoom>
                                        <img src={this.props.parkInformation.fields.image.fields.file.url} alt="image of the park"></img>
                                    </Zoom>}
                                </div>
                            </div>
                            <div>
                                {this.props.importantInformation && this.props.importantInformation.length > 0 ? (
                                    <>
                                        <div className="important-info-header">
                                            {t('myParkAndHome.importantInfo.header')}
                                        </div>
                                        <div className="information-row">
                                            {this.props.importantInformation.map((information, i) => (
                                                <ImportantInformationWidget
                                                    key={i}
                                                    importantInformation={information}
                                                    onClick={() => this.navigateToImportantInfo(information)}
                                                />
                                            ))}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            {UserRoles.IsOwner(this.props.userRoles) &&
                                <div className="information-row details">
                                    <Details
                                        details={this.props.lodgeDetails}
                                        detailsContainerClassName="park-home-lodge-details"
                                    ></Details>
                                </div>}
                            {this.props.faqs && this.props.faqs.length > 0 ? (
                                <>
                                    <div className="faq-title">{t('myParkAndHome.faqTitle')}</div>
                                    <Accordion
                                        className="faq-accordion"
                                        allowMultipleExpanded={true}
                                        allowZeroExpanded={true}
                                    >
                                        {this.props.faqs.map(f => (
                                            <AccordionItem key={f.sys.id}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <img src="images/faq-arrow.svg" alt="arrow" />
                                                        <span>{f.fields.question}</span>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    {documentToReactComponents(f.fields.answer, options)}
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </>
                            ) : null}
                        </Widget>
                        <WidgetList position={{ columnIndex: 10, columnSpan: 3, rowIndex: 3, rowSpan: 12 }}>
                            <p className="park-news-title">{t('myParkAndHome.parkNewsFeed')}</p>
                            <NewsCardContainer setSelectedNewsItem={this.props.setSelectedNewsItem} />
                        </WidgetList>
                        {this.props.parkInformation && <ContactModal
                            isOpen={this.props.isContactParkModalOpen}
                            onBackButtonClick={() => this.props.closeMyContactParkModal()}
                            contactDetails={{
                                Title: t('myParkAndHome.contactPark'),
                                Address: this.props.parkInformation.fields.postalAddress,
                                PhoneNumber: this.props.parkInformation.fields.phoneNumber,
                                Email: this.props.parkInformation.fields.emailAddress
                            }}
                        />}
                    </div>
                )}
            </>
        );
    }
}

export default withTranslation()(MyParkAndHomePage);
