import React, { Component } from 'react';
import './Description.scss';
import { Desktop, Mobile } from './ResponsiveViews';
import classNames from 'classnames';

interface DescriptionProps {
    description: string;
    descriptionHeader?: string;
    descriptionSubHeader?: string;
    descriptionClassName?: string;
    descriptionHeaderClassName?: string;
    descriptionSubHeaderClassName?: string;
    descriptionContainerClassName?: string;
    hasHtml?: boolean;
}

export default class Description extends Component<DescriptionProps> {
    render() {
        return (
            <>
                <Desktop>
                    <div className={classNames(this.props.descriptionContainerClassName)}>
                        <div className={classNames(this.props.descriptionHeaderClassName)}>
                            {this.props.descriptionHeader}
                        </div>
                        <div className={classNames(this.props.descriptionSubHeaderClassName)}>
                            {this.props.descriptionSubHeader}
                        </div>
                        {this.props.hasHtml ? (
                            <div
                                className={classNames(this.props.descriptionClassName)}
                                dangerouslySetInnerHTML={{ __html: this.props.description }}
                            ></div>
                        ) : (
                            <div className={classNames(this.props.descriptionClassName)}>{this.props.description}</div>
                        )}
                    </div>
                </Desktop>
                <Mobile>
                    <div className={classNames(this.props.descriptionContainerClassName)}>
                        <div className={classNames(this.props.descriptionHeaderClassName)}>
                            {this.props.descriptionHeader}
                        </div>
                        <div className={classNames(this.props.descriptionSubHeaderClassName)}>
                            {this.props.descriptionSubHeader}
                        </div>
                        {this.props.hasHtml ? (
                            <div
                                className={classNames(this.props.descriptionClassName)}
                                dangerouslySetInnerHTML={{ __html: this.props.description }}
                            ></div>
                        ) : (
                            <div className={classNames(this.props.descriptionClassName)}>{this.props.description}</div>
                        )}
                    </div>
                </Mobile>
            </>
        );
    }
}
