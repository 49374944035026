import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import InputField from '../../../Guests/Components/Presentational/AddMembershipSubPages/FormInputs/InputField';
import { VerticalBackground } from '../../../Shared/Components/Presentational/Backgrounds';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { Desktop } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { hasValue } from '../../../Shared/Utilities/Validation';
import { useLocation } from 'react-router';
import "./ConfirmMembership.scss";
import { PostConfirmMemberAccountCall } from '../../../Api/Calls/PostConfirmMemberAccountCall';
import { Post } from '../../../Api/ApiService';
import * as Routes from "../../../Constants/Routes";
import { GetHTMlContentBlockCall } from '../../../Contentful/Calls/GetHTMLContentBlockCall';
import Spinner from '../../../Shared/Components/Presentational/Spinner';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PasswordRequirements from '../../../Shared/Components/Presentational/PasswordRequirements';

interface ConfirmMembershipProps extends WithTranslation {
  isLoggedIn: boolean;
  location: any;
}

const useQuery = () => new URLSearchParams(useLocation().search);

const ConfirmMembership: React.FC<ConfirmMembershipProps> = (props) => {
  const { t } = props;
  const { location } = props;

  const [response, setResponse] = useState("");
  const [createPassword, setCreatePassword] = useState(undefined as ContentfulContentObject | undefined);
  const [passwordConfirmed, setPasswordConfirmed] = useState(undefined as ContentfulContentObject | undefined);
  const [contentLoaded, setContentLoaded] = useState(false);

  const query = useQuery();
  const userId = query.get("userId");
  const code = query.get("code");

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<any>();

  const validators = [{ name: "hasValue", validator: hasValue }] as Validator[];

  const [showErrors, setShowErrors] = useState(false);
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [form, setForm] = useState({
    password: { value: "", errors: ["hasValue"] } as FormInput,
    confirmPassword: { value: "", errors: ["hasValue"] } as FormInput,
  });

  const onInput = (value: any, name: any, errors: string[]) => {
    setForm({ ...form, [name]: { value: value, errors: errors } });
  }

  const getContentBlock = async (name: string, label: string) => {
    const text = await GetHTMlContentBlockCall(name, label);
    return text;
  }

  useEffect(() => {
    Promise.all([
      getContentBlock("My Haulfryn - Membership - Password - Create", "createPassword"),
      getContentBlock("My Haulfryn - Membership - Password - Confirmation", "passwordConfirmed"),
    ]).then(r => {
      setCreatePassword(r[0]);
      setPasswordConfirmed(r[1]);
      setContentLoaded(true);
    })
  }, [])

  const confirmMembership = async () => {
    let invalid = false;
    if (form.password.errors && form.password.errors.length > 0) {
      invalid = true;
    } else if (form.confirmPassword.errors && form.confirmPassword.errors.length > 0) {
      invalid = true;
    }
    if (form.password && form.confirmPassword) {
      if (form.password.value !== form.confirmPassword.value) {
        setForm({ ...form, confirmPassword: { value: form.confirmPassword.value, errors: ["confirmDoesNotMatchPass"] } });
        invalid = true;
      } else {
        setForm({ ...form, confirmPassword: { value: form.confirmPassword.value, errors: [] } });
        invalid = false;
      }
    }

    if (invalid) {
      setShowErrors(true);
      return;
    } else {
      setShowErrors(false);
    }

    let memberDetails = createMemberDetailsDTO(userId!, code!, form.password.value!);
    setIsLoading(true);
    let response = await postConfirmMembership(memberDetails);
    setResponse(response);
  }

  const createMemberDetailsDTO = (userId: string, code: string, password: string): ConfirmMemberDetailsDTO => {
    return {
      userId: userId,
      code: code,
      password: password,
    }
  }

  const postConfirmMembership = async (memberDetails: ConfirmMemberDetailsDTO): Promise<string> => {
    let response = await Post<any>(new PostConfirmMemberAccountCall(memberDetails))
      .then((r) => {
        setApiError("");
        return "200";
      })
      .catch((error) => {
        setApiError(error.response.data ? error.response.data.FriendlyMessage : "Password request failed, please ensure you follow the requirements stated above and try again");
        setIsLoading(false);
        return error.response.status;
      })
    return response;
  }

  if (props.isLoggedIn) {
    return (
      <Redirect
        to={{ pathname: location && location.state && location.state.prevLocation ? location.state.prevLocation : '/' }}
      />)
  } else if (response === "200") {
    return (
      <>
        {contentLoaded ? (
          <div className="login-page-container">
            <Desktop>
              <VerticalBackground
                src="/images/desktop-login-image.svg"
                containerClassName="login-page-vertical-background"
              >
                <h1>{t('confirmMembership.verticalBackground.title')}</h1>
              </VerticalBackground>
            </Desktop>
            <div className='register-popup-container'>
              <div className='align-div'>
                <div className="row" style={{ marginBottom: "25px" }}>
                  <div className="float-left">
                    <h1 style={{ margin: "0px", color: "#184f6d" }}>{passwordConfirmed!.title}</h1>
                  </div>
                </div>
                <div className="row contentful-text" style={{ margin: "30px" }}>
                  {documentToReactComponents(passwordConfirmed!.content)}
                </div>
                <div className='row'>
                  <Link to={Routes.Login}>
                    <PrimaryButton
                      type='submit'
                      text="Proceed to Log In"
                    />
                  </Link>
                </div>
                {apiError !== "" &&
                  <p style={{ textAlign: "left", color: "red" }}>{apiError}</p>
                }
              </div>
            </div>
          </div>
        ) : (
          <div className='memberships-spinner'>
            <Spinner />
          </div>
        )}
      </>
    )
  } else {
    return (
      <>
        {contentLoaded ? (
          <div className="login-page-container">
            <Desktop>
              <VerticalBackground
                src="/images/desktop-login-image.svg"
                containerClassName="login-page-vertical-background"
              >
                <h1>{t('confirmMembership.verticalBackground.title')}</h1>
              </VerticalBackground>
            </Desktop>
            <div className='register-popup-container'>
              <div className='align-div'>
                <div className="row" style={{ marginBottom: "25px" }}>
                  <div className="float-left">
                    <h1 style={{ margin: "0px", color: "#184f6d" }}>{createPassword!.title}</h1>
                  </div>
                </div>
                {/* <div className="row contentful-text" style={{ margin: "30px" }}>
                  {documentToReactComponents(createPassword!.content)}
                </div> */}
                <PasswordRequirements password={form.password.value} onPasswordChange={isValid => setPasswordValid(isValid)} />
                <div className='row' style={{ marginTop:"20px", marginBottom: "20px" }}>
                  <InputField
                    type='password'
                    name='password'
                    formInput={form.password}
                    labeltranslationKey="confirmMembership.password"
                    onInput={onInput}
                    validators={validators}
                    showErrors={showErrors}
                  />
                </div>
                <div className='row'>
                  <InputField
                    type='password'
                    name='confirmPassword'
                    formInput={form.confirmPassword}
                    labeltranslationKey="confirmMembership.confirmPassword"
                    onInput={onInput}
                    validators={validators}
                    showErrors={showErrors}
                  />
                </div>
                <div className='row'>
                  <PrimaryButton
                    type='submit'
                    width='50%'
                    className='confirmButton'
                    text={t("general.confirm")}
                    onClick={confirmMembership}
                    isLoading={isLoading}
                    disabled={
                      isLoading || 
                      !form.password.value || 
                      !isPasswordValid
                    }
                  />
                </div>
                {apiError !== "" &&
                  <p style={{ textAlign: "left", color: "red" }}>{apiError}</p>
                }
              </div>
            </div>
          </div>
        ) : (
          <div className='memberships-spinner'>
            <Spinner />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: state.login.loggedIn,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConfirmMembership));