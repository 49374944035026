import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import * as Routes from "../../../Constants/Routes";
import TableFilterPosition from "../../../Constants/TableFilterPosition";
import {
  PrimaryButton,
  TertiaryButton,
} from "../../../Shared/Components/Presentational/Buttons";
import {
  Modal,
  ModalHeader,
} from "../../../Shared/Components/Presentational/Modal";
import { Desktop } from "../../../Shared/Components/Presentational/ResponsiveViews";
import Table, {
  ColumnDefinition,
} from "../../../Shared/Components/Presentational/Table";
import { Widget } from "../../../Shared/Components/Presentational/Widget";
import useMyAccountPage from "../../Hooks/useMyAccountPage";
import MyBankDetails from "../Container/MyBankDetails";
import DateColumnCellRenderer from "../../../Shared/Components/Presentational/DateColumnCellRenderer";
import "./MyAccountPage.scss";
import TransactionAmountCellRenderer from "./TransactionAmountCellRenderer";
import TransactionBalance from "./TransactionBalance";
import TransactionFilter from "./TransactionFilter";
import TransactionInvoiceCellRenderer from "./TransactionInvoiceCellRenderer";
import TransactionTypeCellRenderer from "./TransactionTypeCellRenderer";

const columns: ColumnDefinition[] = [
  {
    fieldName: "Description",
    displayName: "myAccount.transactionTypeColumn",
    bold: true,
    canShowColumn: (_) => true,
    customCellRenderer: TransactionTypeCellRenderer,
  },
  {
    fieldName: "TransactionDate",
    displayName: "myAccount.transactionDateColumn",
    canShowColumn: (_) => true,
    customCellRenderer: DateColumnCellRenderer,
  },
  {
    fieldName: "InvoiceNo",
    displayName: "myAccount.transactionNumberColumn",
    canShowColumn: (_) => true,
  },
  {
    fieldName: "Amount",
    displayName: "myAccount.transactionAmountColumn",
    canShowColumn: (_) => true,
    customCellRenderer: TransactionAmountCellRenderer,
    headerAlignment: "right",
  },
  {
    fieldName: "HasInvoice",
    displayName: "Invoice",
    canShowColumn: (_) => true,
    customCellRenderer: TransactionInvoiceCellRenderer,
    headerAlignment: "right",
  },
];

export interface MyAccountPageProps {
  isHeaderOpen: boolean;
  pageSize: number;
  isSearchingTransactions: boolean;
  searchResults: PagedResultDTO;
  filter: TransactionFilterDTO;
  onFilterChange: (newFilter: any) => void;
  myBankDetails?: MyBankDetailsDTO;
  openMyBankDetailsModal: () => void;
  downloadStatement: () => void;
  downloadPdfStatement: () => void;
  bankTransferClick: () => void;
  setPaymentWindowOpen: (isOpen: boolean) => void;
  isMakeAPaymentModalOpen: boolean;
  haulfryBankDetails?: HaulfrynBankDetailsDTO;
  isDownloadingStatement: boolean;
  isDownloadingPdfStatement: boolean;
  ownerAccountNumber: string;
  ownerAccountId: number;
  accountBalance: undefined | number;
  paymentDetails?: PaymentDetails;
  paymentConfirmed: (newBalance: number) => void;
  paymentConfirming: () => void;
}

export default function MyAccountPage(props: MyAccountPageProps) {
  const {
    closePaymentResultModal,
    paymentResultModalOpen,
    paymentResultModalText,
    refreshPage,
  } = useMyAccountPage(props);

  const { t } = useTranslation();
  const { accountBalance } = props;

  return (
    <div className="widget-container">
      {!props.isHeaderOpen && (
        <>
          <h1 className="widget-layout-title">{t("myAccount.title")}</h1>
          <p className="widget-layout-message">{t("myAccount.message")}</p>
        </>
      )}
      <Widget
        position={{
          columnIndex: 1,
          columnSpan: 8,
          rowIndex: 3,
          rowSpan: 9,
        }}
        containerClassName="my-account-table widget-ie-fix"
        contentClassName="my-account-table-margin"
      >
        <Table
          title={t("myAccount.transactions")}
          columns={columns}
          pagedResults={props.searchResults}
          filter={props.filter}
          onFilterChange={props.onFilterChange}
          pageSize={props.pageSize}
          noResultsMessage={
            props.isSearchingTransactions === false
              ? t("myAccount.noResultsMessage")
              : ""
          }
          filterComponent={
            <TransactionFilter
              onFilterChange={props.onFilterChange}
              filter={props.filter}
            />
          }
          filterPosition={TableFilterPosition.Below}
          isLoading={props.isSearchingTransactions}
        />
      </Widget>
      <Widget
        position={{
          columnIndex: 9,
          columnSpan: 4,
          rowIndex: 3,
          rowSpan: 5,
        }}
      >
        <div className="owner-account-number">
          {t("myAccount.accountNumber") + " - " + props.ownerAccountNumber}
        </div>
        <TransactionBalance
          isPaymentWindowOpen={props.isMakeAPaymentModalOpen}
          downloadStatement={props.downloadStatement}
          isDownloadingStatement={props.isDownloadingStatement}
          downloadPdfStatement={props.downloadPdfStatement}
          isDownloadingPdfStatement={props.isDownloadingPdfStatement}
          balance={accountBalance}
          bankTransferClick={props.bankTransferClick}
          setPaymentWindowOpen={props.setPaymentWindowOpen}
          haulfryBankDetails={props.haulfryBankDetails}
          accountNumber={props.ownerAccountNumber}
        />
        {props.myBankDetails && (
          <TertiaryButton
            onClick={() => props.openMyBankDetailsModal()}
            text={t("myAccount.viewBankDetails")}
          />
        )}
      </Widget>
      {props.myBankDetails && (
        <MyBankDetails myBankDetails={props.myBankDetails} />
      )}

      <Desktop>
        <Modal isOpen={paymentResultModalOpen}>
          <ModalHeader text={t("myAccount.payment.paymentHeader")} />
          <div className="payment-result">
            <p>{paymentResultModalText}</p>
            <PrimaryButton
              text={t("general.ok")}
              onClick={closePaymentResultModal}
            />
          </div>
        </Modal>
      </Desktop>

      {refreshPage && <Redirect to={Routes.MyAccount} />}
    </div>
  );
}
