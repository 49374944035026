import { PostApiCall } from './ApiCall';

export class PostSetParQInformationCall extends PostApiCall {
    constructor(parQForm: ParQFormDTO) {
        let url: string = "Membership/AddParQInformation";

        super(url);

        this.body = parQForm;
    }
}