export const SET_HEADER_MENU_OPEN = "SET_HEADER_MENU_OPEN";
export const SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN =
  "SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN";
export const SET_CONTACT_HAULFRYN_MODAL_OPEN =
  "SET_CONTACT_HAULFRYN_MODAL_OPEN";
export const SET_IS_UPDATE_MY_PASSWORD_SUCCES_MODAL_OPEN =
  "SET_IS_UPDATE_MY_PASSWORD_SUCCES_MODAL_OPEN";
export const SET_IS_MARKETING_PREFERENCES_WARNING_MODAL_OPEN =
  "SET_IS_MARKETING_PREFERENCES_WARNING_MODAL_OPEN";
export const SET_REFER_MEMBERSHIPS_PROMPT_OPEN =
  "SET_REFER_MEMBERSHIPS_PROMPT_OPEN";
export const SET_REFER_SENT_PROMPT_OPEN =
  "SET_REFER_SENT_PROMPT_OPEN";
export const SET_MEMBERSHIPS_FLOW_CONTACT_PROMPT_OPEN =
  "SET_MEMBERSHIPS_FLOW_CONTACT_PROMPT_OPEN";
export const SET_MEMBERSHIPS_CONTACT_PROMPT_NEXT_STEP =
  "SET_MEMBERSHIPS_CONTACT_PROMPT_NEXT_STEP";
export const SET_EXIT_PROMPT_OPEN = "SET_EXIT_PROMPT_OPEN";  
export const SET_ERROR_PROMPT_OPEN = "SET_ERROR_PROMPT_OPEN";