import ContentfulClient from "../ContentfulClient";

export async function GetHTMlContentBlockCall(name: string, label: string) {
    let filter = {
        content_type: "htmlContentBlock",
        "fields.name": name
    };
    const result = await ContentfulClient.getEntries<any>(filter);
    if (!result) {
        throw { message: `GetHTMLContentBlockCall for block ${name} failed` };
    }
    if (result.errors) {
        throw { message: `GetHTMLContentBlockCall for block ${name} returned error(s)`, errors: result.errors };
    }
    if (!(result.items.length > 0)) {
        throw { message: `GetHTMLContentBlockCall for block ${name} returned no data` };
    }

    let item = result.items[0];
    const contentfulObject = {
        name: label,
        title: item.fields.title,
        content: item.fields.content
    } as ContentfulContentObject;

    return contentfulObject;
    
}