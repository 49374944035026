import React, { FormEvent } from "react";
import ReactModal from "react-modal";
import "./Modal.scss";
import { PrimaryButton } from "./Buttons";
import history from "../../../History";
import { Desktop, Mobile } from "./ResponsiveViews";
import classNames from "classnames";
import { ClickableIcon } from "./Icons";
import {
  faArrowLeft,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

ReactModal.setAppElement("#root");

export interface ModalProps {
  isOpen: boolean;
  onAfterOpen?: () => void;
  onRequestClose?: () => void;
  className?: string;
}

export const Modal: React.FC<ModalProps> = (props) => {
  history.listen((location) => {
    // close modal when route changes
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  });

  return (
    <>
      <Desktop>
        <ReactModal
          shouldCloseOnOverlayClick={true}
          isOpen={props.isOpen}
          onAfterOpen={props.onAfterOpen}
          onRequestClose={props.onRequestClose}
          className={classNames("modal", props.className)}
          overlayClassName="modal-overlay"
        >
          {props.children}
        </ReactModal>
      </Desktop>
      <Mobile>
        <ReactModal
          shouldCloseOnOverlayClick={true}
          isOpen={props.isOpen}
          onAfterOpen={props.onAfterOpen}
          onRequestClose={props.onRequestClose}
          className={classNames("modal", props.className)}
          overlayClassName="modal-overlay mobile-modal-overlay"
        >
          {props.children}
        </ReactModal>
      </Mobile>
    </>
  );
};

export interface ModalHeaderProps {
  text: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ text }) => {
  return <header className="modal-header">{text}</header>;
};

export interface ModalFooterProps {
  onBackButtonClick: () => void;
  className?: string;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  onBackButtonClick,
  className,
  children,
}) => {
  return (
    <footer className={classNames("modal-footer", className)}>
      <ClickableIcon
        className="back-button"
        icon={faArrowLeft}
        clickAction={onBackButtonClick}
      />
      {children}
    </footer>
  );
};

export interface SuccessModalProps extends ModalProps {
  importantInfo?: string;
  title: string;
  message: string;
  buttonText: string;
  closeModal: () => void;
}

export const SuccessModal: React.FC<SuccessModalProps> = (props) => {
  const closeModal = (event: FormEvent<any>) => {
    props.closeModal();
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      className={classNames("message-modal", props.className)}
      overlayClassName="modal-overlay"
    >
      <form onSubmit={closeModal}>
        {props.importantInfo && (
          <div className={classNames("important-info")}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <p>{props.importantInfo}</p>
          </div>
        )}
        <h1>{props.title}</h1>
        <p dangerouslySetInnerHTML={{ __html: props.message }}>{}</p>
        {props.children}
        <PrimaryButton type="submit" text={props.buttonText} />
      </form>
    </ReactModal>
  );
};

export interface ErrorModalProps extends ModalProps {
  title: string;
  message: string;
  buttonText: string;
  closeModal: () => void;
}

export const ErrorModal: React.FC<ErrorModalProps> = (props) => {
  const closeModal = (event: FormEvent<any>) => {
    props.closeModal();
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      className={classNames("message-modal", props.className)}
      overlayClassName="modal-overlay"
    >
      <form onSubmit={closeModal}>
        <h1>{props.title}</h1>
        <p>{props.message}</p>
        {props.children}
        <PrimaryButton type="submit" text={props.buttonText} />
      </form>
    </ReactModal>
  );
};

export interface ConfirmModalProps extends ModalProps {
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
  onClose: (submited: boolean, item?: any) => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const closeModal = (event: FormEvent<any>, submitted: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    props.onClose(submitted);
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      className={classNames("confirm-modal", props.className)}
      overlayClassName="modal-overlay"
    >
      <form onSubmit={(e) => closeModal(e, true)}>
        <h1>{props.title}</h1>
        <p>{props.message}</p>
        {props.children}
        <div className="confirm-button-container">
          <button
            className="btn btn-cancel"
            type="button"
            onClick={(e) => closeModal(e, false)}
          >
            {props.cancelButtonText}
          </button>
          <PrimaryButton type="submit" text={props.confirmButtonText} />
        </div>
      </form>
    </ReactModal>
  );
};
