import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import * as Routes from "../../../Constants/Routes";
import * as ActionTypes from '../../Redux/ActionTypes';
import { Get } from '../../../Api/ApiService';
import { GetStripeMembershipCheckoutUrlCall } from '../../../Api/Calls/GetStripeMembershipCheckoutUrlCall';
import { CheckoutStates } from '../../../Constants/CheckoutStates';
import { Desktop } from '../../../Shared/Components/Presentational/ResponsiveViews';

interface DocuSignSignedProps extends WithTranslation {
  isHeaderOpen: boolean;
  docuSignMessage: string | null;
  setDocuSignMessage: (message: string | null) => void;
  basketId: string;
  checkoutSession: CheckoutSession;
  isPaymentRequired: boolean;
}

const useQuery = () => new URLSearchParams(useLocation().search);

const DocuSignSigned: React.FC<DocuSignSignedProps> = (props) => {
  const { t } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [completeMembership, setCompleteMembership] = useState(false);

  const query = useQuery();
  const event = query.get("event");
  if (props.docuSignMessage !== event) {
    props.setDocuSignMessage(event);
  }
  const sessionId = query.get("basket_id");

  if (completeMembership) {
    console.log(`${Routes.PaymentSuccess} + "?basket_id=" + ${sessionId}`)
    return (
      <Redirect
        to={Routes.PaymentSuccess + "?basket_id=" + sessionId}
      />
    )
  }

  const onClick = () => {
    GetStripeUrl();
  }

  const GetStripeUrl = () => {
    setIsLoading(true);
    Get<any>(new GetStripeMembershipCheckoutUrlCall(props.basketId))
      .then(response => {
        window.location.assign(response.data.CheckoutUrl)
      })
      .catch(() => {
        setIsLoading(false);
      })
  }

  return (
    <div className="widget-container">
      <Desktop>
        {!props.isHeaderOpen && (
          <h1 className="widget-layout-title" style={{ color: "white" }}>{t("addMembership.membershipAgreement.title")}</h1>
        )}
      </Desktop>

      <Widget
        position={{
          columnIndex: 1,
          columnSpan: 12,
          rowIndex: 3,
          rowSpan: 9,
        }}
      >
        <div className="row" style={{ marginBottom: "5px" }}>
          <div className="float-left">
            <h1 style={{ margin: "20px", color: "#184f6d" }}>Agreement Signed</h1>
          </div>
        </div>
        <div className="row" style={{ margin: "30px" }}>
          <p>{props.isPaymentRequired ? t("addMembership.membershipAgreement.signed.messageToPay") : t("addMembership.membershipAgreement.signed.message")}</p>
        </div>
        <div className="row" style={{ marginBottom: "25px", marginLeft: "30px" }}>
          <PrimaryButton
            text="Continue"
            onClick={onClick}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Link to={Routes.MyGuests}>
            <SecondaryButton
              text={t("addMembership.paymentSuccess.toMyMemberships")}
            />
          </Link>
        </div>
      </Widget>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isHeaderOpen: state.application.isHeaderOpen,
    docuSignMessage: state.guests.docuSignMessage,
    basketId: state.guests.basketId,
    checkoutSession: state.guests.checkoutSession,
    isPaymentRequired: state.guests.isPaymentRequired,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setDocuSignMessage: (message: string | null) => {
      dispatch({ type: ActionTypes.SET_DOCU_SIGN_MESSAGE, payload: message })
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DocuSignSigned));