import { PostApiCall } from './ApiCall';

export class PostRegisterCall extends PostApiCall {
    constructor(details: UserDTO) {
        let url: string = 'account/register';

        super(url);

        this.body = details;
        this.logoutOn401 = false;
    }
}
