import React, { Component } from 'react';
import './Details.scss';
import { Desktop, Mobile } from './ResponsiveViews';
import { Translation } from 'react-i18next';
import classNames from 'classnames';

interface DetailsProps {
    details: Map<string, string>;
    detailsContainerClassName?: string;
    detailKeyClassName?: string;
    detailValueClassName?: string;
}

export default class Details extends Component<DetailsProps> {
    createDetails = (t: any) => {
        let detailrows: JSX.Element[] = [];

        this.props.details.forEach((detailValue, detailKey) => {
            if (detailValue) {
                detailrows.push(
                    <div className="detail-row" key={detailKey}>
                        <span className={classNames(this.props.detailKeyClassName)}>{t(detailKey)}</span>
                        <span className={classNames(this.props.detailValueClassName)}>{detailValue}</span>
                    </div>
                );
            }
        });

        return detailrows;
    };

    render() {
        return (
            <Translation>
                {(t: (key: string) => string) => {
                    return (
                        <>
                            <Desktop>
                                <div className={classNames('details-container', this.props.detailsContainerClassName)}>
                                    {this.createDetails(t)}
                                </div>
                            </Desktop>
                            <Mobile>
                                <div
                                    className={classNames(
                                        'details-container-mobile',
                                        this.props.detailsContainerClassName
                                    )}
                                >
                                    {this.createDetails(t)}
                                </div>
                            </Mobile>
                        </>
                    );
                }}
            </Translation>
        );
    }
}
