import { IconLookup, IconName, IconPrefix, IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, far } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft, faChevronRight, fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { Component } from 'react';
import './Icons.scss';

interface IconProps {
    icon: IconProp;
}

interface ClickableIconProps extends IconProps {
    clickAction: (item?: any) => void;
    className: string;
    size?: any;
    id?: string;
}

export class ClickableIcon extends Component<ClickableIconProps> {
    render() {
        return (
            <div id={this.props.id && this.props.id} className={this.props.className} onClick={this.props.clickAction}>
                <FontAwesomeIcon icon={this.props.icon} size={this.props.size}/>
            </div>
        );
    }
}

interface EditIconProps {
    clickAction: () => void;
    disabled?: boolean;
}

export class EditIcon extends Component<EditIconProps> {
    render() {
        return (
            <ClickableIcon
                className={classNames('icon-edit', { disabled: this.props.disabled })}
                icon={faEdit}
                clickAction={this.props.disabled ? () => {} : this.props.clickAction}
            />
        );
    }
}

interface PreviousIconProps {
    clickAction: () => void;
    disabled?: boolean;
}

export class PreviousIcon extends Component<PreviousIconProps> {
    render() {
        return (
            <ClickableIcon
                className={classNames('icon-previous', { disabled: this.props.disabled })}
                icon={faChevronLeft}
                clickAction={this.props.disabled ? () => {} : this.props.clickAction}
            />
        );
    }
}

interface NextIconProps {
    clickAction: () => void;
    disabled?: boolean;
}

export class NextIcon extends Component<NextIconProps> {
    render() {
        return (
            <ClickableIcon
                className={classNames('icon-next', { disabled: this.props.disabled })}
                icon={faChevronRight}
                clickAction={this.props.disabled ? () => {} : this.props.clickAction}
            />
        );
    }
}

interface TransactionIconProps {
    icon: TransactionIconDTO;
}

export class TransactionIcon extends Component<TransactionIconProps> {
    render() {
        let faIcon: IconLookup = { prefix: '' as IconPrefix, iconName: '' as IconName };

        if (this.props.icon && this.props.icon.Icon) {
            faIcon = {
                prefix: this.props.icon.Icon.split(' ')[0] as IconPrefix,
                iconName: this.props.icon.Icon.split(' ')[1].replace('fa-', '') as IconName
            };
            library.add(fas);
            library.add(far);
        }

        return (
            <div
                className="transaction-icon"
                style={{ color: '#fff', backgroundColor: this.props.icon.BackgroundColour }}
            >
                <FontAwesomeIcon icon={faIcon} />
            </div>
        );
    }
}
