import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../Shared/Components/Presentational/Buttons';
import { Desktop, Mobile } from '../../../../Shared/Components/Presentational/ResponsiveViews';
import './MembershipPageStyles.scss'
import DropdownList from '../../../../Shared/Components/Presentational/DropdownList';
import { Entry } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface SelectMembershipTypeProps extends WithTranslation {
  name: string;
  form: any;
  onInput: (value: any, name: any, errors: string[]) => void;
  nextStep: () => void;
  showErrors?: boolean;
  ownerMembershipsLeft: boolean;
  ownerMembershipsAvailability: OwnerMembershipAvailabilityDTO;
  contentfulMembershipTypes: Entry<ContentfulMembershipType>[];
  termDurations: DropdownListOption[][];
  userRoles: UserRoleDTO[];
}

const SelectMembershipType: React.FC<SelectMembershipTypeProps> = (props: SelectMembershipTypeProps) => {
  const { t } = props;
  const [selectedDurations, setSelectedDurations] = useState<any[]>([]);

  useEffect(() => {
    // Selects value in dropdown for the membership duration
    // Will use the selected duration from the form
    let array: any[] = [];
    for (let i = 0; i < props.contentfulMembershipTypes.length; i++) {
      if (props.termDurations[i].length === 1) {
        array[i] = props.termDurations[i][0];
      } else {
        if (props.form.contentfulMembershipTypeId && props.form.termFormula && props.form.contentfulMembershipTypeId.value === props.contentfulMembershipTypes[i].sys.id) {
          array[i] = props.form.termFormula.value;
        } else {
          array[i] = undefined;
        }
      }
    }
    setSelectedDurations(array);
  }, [props.termDurations]);

  useEffect(() => {
    if (props.form.contentfulMembershipTypeId.value === undefined || 
      props.form.contentfulMembershipTypeId.value === '' && 
      props.form.contentfulMembershipTypeId.errors[0] !== 'isMembershipType') {
      props.onInput('', props.name, ["isMembershipType"]);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleMembershipSelection = (type: number) => {
    if (props.contentfulMembershipTypes) {
      if (selectedDurations[type] !== undefined) {
        props.onInput(props.contentfulMembershipTypes[type].sys.id, props.name, []);
        props.onInput(selectedDurations[type], "termFormula", []);
        props.nextStep();
      } else {
        props.onInput(props.contentfulMembershipTypes[type].sys.id, props.name, ["noDuration"]);
      }
    }
  }

  const handleButtonColour = (type: number, screen: string): string => {
    if (props.contentfulMembershipTypes) {
      if (props.form.contentfulMembershipTypeId != undefined && props.form.contentfulMembershipTypeId.value === props.contentfulMembershipTypes[type].sys.id) {
        if (screen === "Desktop") {
          return "save-cnt-btn membership-btn selected-button";
        } else {
          return "membership-btn selected-button";
        }
      }
      else {
        if (screen === "Desktop") {
          return "save-cnt-btn membership-btn";
        } else {
          return "membership-btn";
        }
      }
    }
    return "membership-btn";
  }

  const handleButtonText = (type: number): string => {
    if (props.form.contentfulMembershipTypeId.value && props.contentfulMembershipTypes) {
      if (props.form.contentfulMembershipTypeId.value === props.contentfulMembershipTypes[type].sys.id) {
        return "Selected";
      }
    }
    return "Select";
  }

  const calcColumns = () => {
    let columnPercentages = "";
    if (props.contentfulMembershipTypes) {
      let columns = props.contentfulMembershipTypes.length;
      let percentage = 100 / columns;
      for (let i = 0; i < columns; i++) {
        columnPercentages += percentage.toString() + "% ";
      }
    }
    return columnPercentages;
  }

  return (
    <>
      {(props.contentfulMembershipTypes && props.termDurations) && (
        <>
          <Desktop>
            <div className='desktopVersion'>
              <div className='row' style={{ marginBottom: "25px" }}>
                <div className='float-left'>
                  <h2 id="smTitle" style={{ margin: "0px" }}>{t("addMembership.membership.title")}</h2>
                </div>
              </div>

              <div className="row membership-grid" style={{ height: "35%", gridTemplateColumns: calcColumns() }}>
                {props.contentfulMembershipTypes.map(type => (
                  <div className="column-md3-type content-column">
                    <h1>{type.fields.publicName}</h1>
                    {type.fields.description.content && (
                      <div className='contentful-text-membership-type'>
                        {documentToReactComponents(type.fields.description)}
                      </div>
                    )}
                  </div>
                ))}

                {props.contentfulMembershipTypes.map((type, key) => (
                  <div className='column-md3-type center-content'>
                    {(props.termDurations && props.termDurations[key] && props.termDurations[key].length > 1) ? (
                      <div style={{ marginTop: "12px", minWidth: "300px" }}>
                        <DropdownList
                          options={props.termDurations[key]}
                          placeholder={"Select Duration"}
                          onSelectionChanged={(term) => setSelectedDurations({ ...selectedDurations, [key]: term })}
                          selectedOption={selectedDurations[key]}
                        />
                      </div>
                    ) : (props.termDurations && props.termDurations[key] && props.termDurations[key].length === 1) ? (
                      <div style={{ marginTop: "12px", minWidth: "300px", textAlign: "center" }}>
                        <p style={{ margin: "0" }}>{props.termDurations[key][0].label}</p>
                      </div>
                    ) : (
                      <div style={{ marginTop: "12px", minWidth: "300px", textAlign: "center" }}>
                        <p style={{ margin: "0" }}>Term Unknown</p>
                      </div>
                    )}
                  </div>
                ))}

                {props.contentfulMembershipTypes.map((type, key) => (
                  <div className='column-md3-type center-content'>
                    <div>
                      {type.fields.debitsQuota ? (
                        <p style={{ margin: "0" }} >
                          {props.ownerMembershipsAvailability && props.ownerMembershipsAvailability.numberUsed} of {props.ownerMembershipsAvailability && props.ownerMembershipsAvailability.allocated} Club memberships used
                        </p>
                      ) : (
                        <p style={{ margin: "0" }}></p>
                      )}
                    </div>
                  </div>
                ))}

                {props.contentfulMembershipTypes.map((type, key) => (
                  <div className="column-md3-type button-column center-content">
                    <div>
                      {(!type.fields.debitsQuota || (props.ownerMembershipsLeft || (props.form.contentfulMembershipTypeId && props.form.contentfulMembershipTypeId.value === type.sys.id))) ? (
                        <div style={{ textAlign: "center", fontWeight: "bold" }}>
                          <div className='row'>
                            <PrimaryButton
                              text={handleButtonText(key)}
                              id="select"
                              type="submit"
                              onClick={() => {
                                handleMembershipSelection(key);
                              }}
                              className={handleButtonColour(key, "Desktop")}
                            />
                          </div>

                        </div>
                      ) : (
                        <p style={{ color: "red", marginTop: "80px" }}>No Club memberships available</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {(props.showErrors && (props.form.contentfulMembershipTypeId.errors && props.form.contentfulMembershipTypeId.errors.length > 0)) &&
                <>
                  <br />
                  <p style={{ textAlign: "center", color: "red" }}>{props.form && props.form.contentfulMembershipTypeId && t("addMembership.errors." + props.form.contentfulMembershipTypeId.errors)}</p>
                </>
              }
            </div>
          </Desktop>
          <Mobile>
            <div className='mobileVersion'>
              <div className='row' style={{ marginBottom: "25px", marginTop: "50px" }}>
                <div className='title-header'>
                  <h2 style={{ margin: "0px" }}>{t("addMembership.membership.title")}</h2>
                </div>
              </div>

              {props.contentfulMembershipTypes.map((type, key) => (
                <div className='column-mobile' style={key < (props.contentfulMembershipTypes.length -1) ? { marginBottom: "50px"} : undefined}>
                  <h2 style={{ textAlign: "center" }}>{type.fields.publicName}</h2>
                  {type.fields.description.content && (
                    <div className='contentful-text-membership-type'>
                      {documentToReactComponents(type.fields.description)}
                    </div>
                  )}

                  {(props.termDurations && props.termDurations[key] && props.termDurations[key].length > 1) ? (
                    <div style={{ marginTop: "12px" }}>
                      <DropdownList
                        options={props.termDurations[key]}
                        placeholder={"Select Duration"}
                        onSelectionChanged={(term) => setSelectedDurations({ ...selectedDurations, [key]: term })}
                        selectedOption={selectedDurations[key]}
                      />
                    </div>
                  ) : (props.termDurations && props.termDurations[key] && props.termDurations[key].length === 1) ? (
                    <div style={{ marginTop: "12px", minWidth: "300px", textAlign: "center" }}>
                      <p style={{ margin: "0" }}>{props.termDurations[key][0].label}</p>
                    </div>
                  ) : (
                    <div style={{ marginTop: "12px", minWidth: "300px", textAlign: "center" }}>
                      <p style={{ margin: "0" }}>Term Unknown</p>
                    </div>
                  )}

                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    {(!type.fields.debitsQuota || (props.ownerMembershipsLeft || (props.form.contentfulMembershipTypeId && props.form.contentfulMembershipTypeId.value === type.sys.id))) ? (
                      <div>
                        <PrimaryButton
                          text={handleButtonText(key)}
                          id="select"
                          type="submit"
                          onClick={() => {
                            handleMembershipSelection(key);
                          }}
                          className={handleButtonColour(key, "Mobile")}
                        />
                        <div>
                          {type.fields.debitsQuota ? (
                            <p style={{ margin: "0" }} >
                              {props.ownerMembershipsAvailability && props.ownerMembershipsAvailability.numberUsed} of {props.ownerMembershipsAvailability && props.ownerMembershipsAvailability.allocated} Club memberships used
                            </p>
                          ) : (
                            <p style={{ margin: "0" }}></p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p style={{ color: "red", marginTop: "80px" }}>No Club memberships available</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Mobile>
        </>
      )}
    </>
  );
}

export default withTranslation()(SelectMembershipType);