import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../../Shared/Components/Presentational/Buttons";
import { Modal, ModalHeader, ModalProps } from "../../../Shared/Components/Presentational/Modal";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import * as Routes from "../../../Constants/Routes";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

interface ReferralSentAlertModalProps extends ModalProps {
  isOpen: boolean;
  setReferralSentModalOpen: (isOpen: boolean) => void;
  modalText: ContentfulContentObject;
}


const ReferralSentAlertModal: React.FC<ReferralSentAlertModalProps> = (props) => {
  const { t } = useTranslation();
  const open = props.isOpen;
  const setReferralSentModalOpen = props.setReferralSentModalOpen;
  const handleClick1 = async () => {
    setReferralSentModalOpen(false);
  }

  const formContents = (
    <div className='button-container-modal'>
      <div className='container' style={{ textAlign: "center" }}>
        <ModalHeader text={props.modalText.title} />

        <div>
          {documentToReactComponents(props.modalText.content)}
        </div>

        <Link to={Routes.MyGuests}>
          <PrimaryButton
            id="close"
            text={t("general.close")}
            className="footer-button"
            onClick={handleClick1}
          />
        </Link>
      </div>
    </div>
  )

  return (
    <>

      <Desktop>

        <Modal
          isOpen={open}
          className={classNames("owner-contact-details-modal referAlertmodal", props.className)}
        >
          <form>{formContents}</form>
        </Modal>
      </Desktop>
      <Mobile>
        <Modal
          isOpen={open}
          className={classNames("owner-contact-details-modal-mobile-form referAlertMobilemodal", props.className)}
        >
          {formContents}
        </Modal>
      </Mobile>
    </>
  );
}

export default ReferralSentAlertModal;