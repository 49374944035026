import { useSelector } from 'react-redux';
import { Post } from '../../Api/ApiService';
import { PostDownloadInvoiceCall } from '../../Api/Calls/PostDownloadInvoiceCall';
import { AppState } from '../../RootReducer';

export default function useDownloadInvoice(invoiceNo: string) {
    const accountId = useSelector((state: AppState) => state.user.lodge.AccountId);

    const download = async () => {
        try {
            const invoice = await Post<TransactionInvoiceDTO>(
                new PostDownloadInvoiceCall({ AccountId: accountId, InvoiceNo: invoiceNo })
            );

            const fileName = `invoice-${invoiceNo}.${invoice.data.FileExtension}`;
            const byteCharacters = atob(invoice.data.Document);
            let byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: `application/${invoice.data.FileExtension}` });

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // this is for IE
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                // this is for Chromium/Firefox/Safari
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (e) {
            console.error(e);
        }
    };

    return download;
}
