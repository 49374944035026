import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Get } from '../../../Api/ApiService';
import { Modal, ModalFooter, ModalHeader, ModalProps } from '../../../Shared/Components/Presentational/Modal';
import Spinner from '../../../Shared/Components/Presentational/Spinner';
import RestaurantDetails from '../Presentational/RestaurantDetails';
import './ParkRestaurantsModal.scss';
import {Entry} from 'contentful';
import {GetContentfultMenusCall} from '../../../Contentful/Calls/GetOutletMenusCall'
import { props } from 'cypress/types/bluebird';

export interface RestaurantDetailModalProps extends ModalProps {
    restaurant: Entry<ContentfulOutlet>;
    isMemberWithOutMembershipNumber: boolean;
    openTableBookingModal: () => void,
}

const RestaurantDetailModal: React.FC<RestaurantDetailModalProps> = ({ restaurant, onRequestClose, isOpen, openTableBookingModal, isMemberWithOutMembershipNumber }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [restaurantMenus, setRestaurantMenus] = useState<Entry<ContentfulMenu>[]>([]);

    useEffect(() => {
        if(restaurant){
            if (restaurant.fields.menus) {
                setHasError(false);
                setIsLoading(true);
    
                GetContentfultMenusCall(restaurant.fields.menus.map(m=> m.sys.id)).then(
                    result => {
                        setIsLoading(false);
                        if(result.errors){
                            setIsLoading(false);
                            setHasError(true);
                        } else{
                            setRestaurantMenus(result.items);
                        }
                    },
                    err => {
                        setIsLoading(false);
                        setHasError(true);
                    }
                );
            }else{
                setRestaurantMenus([]);
                setHasError(false);
                setIsLoading(false);
            }
        }
       
    }, [restaurant]);

    const onBackButtonClick = () => {
        onRequestClose && onRequestClose();
    };

    return (
        <Modal isOpen={isOpen} className="restaurant-modal">
            <ModalHeader text={restaurant.fields.name} />
            {isLoading && <Spinner />}
            {!isLoading && !hasError && restaurant && <RestaurantDetails restaurant={restaurant} menus={restaurantMenus} openTableBookingModal={openTableBookingModal} isMemberWithOutMembershipNumber={isMemberWithOutMembershipNumber} />}
            {!isLoading && hasError && (
                <p className="restaurant-message">{t('myCalendar.restaurants.details.error')}</p>
            )}
            <ModalFooter onBackButtonClick={onBackButtonClick} />
        </Modal>
    );
};

export default RestaurantDetailModal;
