import { PostApiCall } from './ApiCall';

export class PostSetPasswordCall extends PostApiCall {
    constructor(setPasswordDto: SetPasswordDto) {
        const url = `account/setPassword`;

        super(url);

        this.body = setPasswordDto;
        this.logoutOn401 = false;
    }
}
