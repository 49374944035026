import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../../Shared/Components/Presentational/Buttons";
import { Modal, ModalHeader, ModalProps } from "../../../Shared/Components/Presentational/Modal";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import * as Routes from "../../../Constants/Routes";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

interface ExitPromptModalProps extends ModalProps {
  isOpen: boolean;
  setExitModalOpen: (isOpen: boolean) => void;
  saveAndExit: () => void;
  modalText: ContentfulContentObject;
}

const ExitPromptModal: React.FC<ExitPromptModalProps> = (props) => {
  const { t } = useTranslation();
  const open = props.isOpen;
  const setExitModalOpen = props.setExitModalOpen;
  const handleClick1 = async () => {
    setExitModalOpen(false);
    props.saveAndExit();
  }

  const handleClick3 = () => {
    setExitModalOpen(false);
  }

  const formContents = (
    <div className='button-container-modal'>
      <div className='container' style={{ textAlign: "center" }}>
        <ModalHeader text={props.modalText.title} />
        <div>
          {documentToReactComponents(props.modalText.content)}
        </div>
        <Link to={Routes.MyGuests}>
          <PrimaryButton
            id="exit"
            text={t("general.exit")}
            className="footer-button"
            onClick={handleClick1}
          />
        </Link>
        <SecondaryButton
          id="exit-close"
          text={"Close"}
          className="footer-button"
          onClick={handleClick3}
        />
      </div>
    </div >
  )

  return (
    <>
      <Modal
        isOpen={open}
        className={classNames("owner-contact-details-modal", props.className)}
      >
        <Desktop>
          <form>{formContents}</form>
        </Desktop>
        <Mobile>
          <form className='owner-contact-details-modal-mobile-form'>
            {formContents}
          </form>
        </Mobile>
      </Modal>
    </>
  );
}

export default ExitPromptModal;