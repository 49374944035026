import { PostApiCall } from "./ApiCall";

export class PostMarketingPreferencesCall extends PostApiCall {
  constructor(
    lodgeId: string,
    newMarketingPreferences: MarketingPreferencesDTO
  ) {
    let url: string = `account/${lodgeId}/marketingpreferences`;

    super(url);

    this.body = newMarketingPreferences;
  }
}
