import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ClickableIcon } from '../../../Shared/Components/Presentational/Icons';
import { CustomColumnCellRendererProps } from '../../../Shared/Components/Presentational/Table';
import { getClassNameStatusCode, getIconByStatusCode } from './PassStatusColumnCellRenderer';
import './PassStatusColumnCellRenderer.scss';
import PassFunctionalityTypes from '../../../Constants/PassFunctionalityTypes';
import PassStatus from '../../../Constants/PassStatus';

export function PassStatusMobileColumnCellRenderer(props: CustomColumnCellRendererProps) {
    const { rowValue, clickAction } = props;
    const filter = props.filter as PassFilterDTO;

    const deletePass = () => {
        if (clickAction) {
            clickAction(props.rowValue);
        }
    };

    const canDeletePass = () => {
        if (
            filter.FunctionalityCode === PassFunctionalityTypes.Car ||
            filter.FunctionalityCode === PassFunctionalityTypes.ShortStay
        ) {
            return true;
        }

        // only season resort/owner passes should show the delete button if they're expired
        return rowValue.StatusCode === PassStatus.Expired;
    };

    return (
        <div>
            <div className={'pass-status-cell-mobile ' + getClassNameStatusCode(rowValue.StatusCode)}>
                <FontAwesomeIcon icon={getIconByStatusCode(rowValue.StatusCode)} />
                <div className="pass-status-cell-value">{rowValue.Status}</div>
            </div>
            {canDeletePass() && <ClickableIcon icon={faTimes} className="delete-btn" clickAction={deletePass} />}
        </div>
    );
}
