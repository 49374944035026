import { ParQFormInputs } from '../../ParQFormInputs';
import {
  SET_MEMBERSHIP_KEY, SET_PAR_Q_INFORMATION_FAILURE, SET_PAR_Q_INFORMATION_REQUEST, SET_PAR_Q_INFORMATION_SUCCESS, UPDATE_PAR_Q_FORM, UPDATE_PAR_Q_FORM_DTO
} from './ActionTypes';

interface ParQState {
  membershipKey?: string;
  parQFormDTO: ParQFormDTO | undefined;
  parQFormInputs: IParQFormInputs;
  setParQInformationRequest: boolean;
  setParQInformationSuccess: boolean;
  setParQInformationFailure: boolean;
}

const initialState: ParQState = {
  membershipKey: '',
  parQFormDTO: undefined,
  parQFormInputs: new ParQFormInputs(),
  setParQInformationRequest: false,
  setParQInformationSuccess: false,
  setParQInformationFailure: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MEMBERSHIP_KEY:
      return {
        ...state,
        membershipKey: action.payload,
      }
    case SET_PAR_Q_INFORMATION_REQUEST:
      return {
        ...state,
        setParQInformationRequest: true,
        setParQInformationSuccess: false,
        setParQInformationFailure: false,
      }
    case SET_PAR_Q_INFORMATION_SUCCESS:
      return {
        ...state,
        setParQInformationRequest: false,
        setParQInformationSuccess: true,
      }
    case SET_PAR_Q_INFORMATION_FAILURE:
      return {
        ...state,
        setParQInformationRequest: false,
        setParQInformationFailure: true,
      }
    case UPDATE_PAR_Q_FORM:
      return {
        ...state,
        parQFormInputs: action.payload,
      }
    case UPDATE_PAR_Q_FORM_DTO:
      return {
        ...state,
        parQFormDTO: action.payload,
      }
    default:
      return state;
  }
}