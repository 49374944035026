import React from 'react';
import { useTranslation } from 'react-i18next';
import './Version.scss';

export default function Version() {
    const { t } = useTranslation();

    return (
        <p className="version-number" title={process.env.REACT_APP_BUILD_ID}>{t('login.versionNumber')} {process.env.REACT_APP_VERSION_NUMBER}</p>
    );
}
