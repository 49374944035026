import ContentfulClient from "../ContentfulClient";

export async function  GetParkInformationCall(parkCode: string) {
    let filter = {
        content_type: 'park',
        'fields.code': parkCode,
        select:[
            'fields.myHaulfrynAlert',
            'fields.myHaulfrynActivityBookingUrl',
            'fields.myHaulfrynActivityLabel',
            'fields.myHaulfrynSpaBookingUrl',
            'fields.emailAddress',
            'fields.phoneNumber',
            'fields.postalAddress',
            'fields.aboutThePark',
            'fields.image'
        ]
    }
    const result =  await ContentfulClient.getEntries<ContentfulParkInformation>(filter);
    
    if (result.errors) {
        throw {message: "GetParkInformationCall returned error/s", errors: result.errors} 
    }
    
    return result.items[0];
}