import React, { useState } from 'react';
import AddPassField from './AddPassField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface AddPassRowProps {
    passForm: AddPassForm<AddPassDTO>;
    onDeleteClick?: (pass: AddPassForm<AddPassDTO>) => void;
    showDeleteButton: boolean;
    updatePasses: (pass: AddPassForm<AddPassDTO>) => void;
    isRenew?: boolean;
    isAddPassPhotoModalOpen: boolean;
    submittedOption: ParkPassTypeDTO;
    isAnalyzingPassPhoto?: boolean;
    analyzingPassPhotoError?: any;
    isAnalyzingPassPhotoSuccessful?: boolean;
    PassPhotoSizeLimitInKb: number;
    requirePassPhoto?: boolean;
    openAddPassPhotoModal: () => void;
    closeAddPassPhotoModal: () => void;
    analyzePassPhotoRequest: (passPhotoFile: File) => void;
}

interface AddPassContext {
    form: AddPassForm<AddPassDTO>;
    formState: {
        [fieldId: string]: {
            isValid: boolean;
            isDirty: boolean;
        };
    };
}

export const FormContext = React.createContext({});

const AddPassRow: React.FC<AddPassRowProps> = props => {
    const fieldListReducer = (result: any, field: FormField<AddPassDTO>) => {
        if((props.requirePassPhoto == undefined || 
            props.requirePassPhoto == null || 
            props.requirePassPhoto == false) && field.id == "passPhoto") {
            return result;
        }
        result[field.id] = {};
        result[field.id].isValid = props.isRenew;
        return result;
    };
    const [formState, setFormState] = useState(props.passForm.fieldList.reduce(fieldListReducer, {}));
    const updateFormState = (context: AddPassContext) => {
        context.form.isValid = true;

        for (let fieldId in context.formState) {
            const fieldState = context.formState[fieldId];

            if (!fieldState.isValid) {
                context.form.isValid = false;
            }
        }
        props.updatePasses(context.form);
        setFormState(context.formState);
    };
    const onDelete = () => {
        if (props.onDeleteClick) {
            props.onDeleteClick(props.passForm);
        }
    };
    return (
        <FormContext.Provider value={{ form: props.passForm, formState, updateFormState }}>
            <div className="add-pass-row">
                {props.passForm.fieldList.map(field => (field.id != "passPhoto" || props.requirePassPhoto) && (
                    <AddPassField     
                        key={field.id} 
                        field={field}  
                        submittedOption={props.submittedOption} 
                        isAnalyzingPassPhoto={props.isAnalyzingPassPhoto} 
                        analyzingPassPhotoError={props.analyzingPassPhotoError}
                        isAnalyzingPassPhotoSuccessful={props.isAnalyzingPassPhotoSuccessful}              
                        isAddPassPhotoModalOpen={props.isAddPassPhotoModalOpen}
                        openAddPassPhotoModal={props.openAddPassPhotoModal}
                        closeAddPassPhotoModal={props.closeAddPassPhotoModal}
                        analyzePassPhotoRequest={(file: File) => props.analyzePassPhotoRequest(file)}
                        PassPhotoSizeLimitInKb={props.PassPhotoSizeLimitInKb}>
                    </AddPassField>
                ))}
                {props.showDeleteButton && (
                    <div className="delete-pass-row">
                        <div onClick={() => onDelete()}>
                            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                        </div>
                    </div>
                )}
            </div>
        </FormContext.Provider>
    );
};

export default AddPassRow;
