import { PostApiCall } from './ApiCall';

export class PostMyContactDetailsCall extends PostApiCall {
    constructor(newDetails: UpdateContactDetailsDTO) {
        let url: string = 'account/contactdetails';

        super(url);

        this.body = newDetails;
    }
}
