import React from "react";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalProps } from "../../../Shared/Components/Presentational/Modal";
import { PrimaryButton, SecondaryButton } from "../../../Shared/Components/Presentational/Buttons";
import classNames from "classnames";

interface DeleteMembershipPromptProps extends ModalProps {
    isOpen: boolean;
    closeModal: (isOpen: boolean, membershipId: string | undefined) => void;
    deleteMembership: (membershipId: string) => void;
    membershipToDelete: string;
}

const DeleteMembershipPrompt: React.FC<DeleteMembershipPromptProps> = (props) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        props.membershipToDelete && props.deleteMembership(props.membershipToDelete);
        props.closeModal(false, undefined);
    }

    const handleCancel = () => {
        props.closeModal(false, undefined);
    }

    const formContents = (
        <div className="button-container-modal" style={{ display: "block" }}>
            <div className="container">
                <ModalHeader text={t("myMemberships.deleteModal.header")} />
                <p style={{ fontSize: "20px" }}>{t("myMemberships.deleteModal.message")}</p>


                <PrimaryButton
                    text={t("general.confirm")}
                    onClick={handleConfirm}
                />
                <SecondaryButton
                    text={t("general.cancel")}
                    onClick={handleCancel}
                />
            </div>
        </div>
    )

    return (
        <>

            <Desktop>
                <Modal
                    isOpen={props.isOpen}
                    className={classNames("owner-contact-details-modal")}
                >
                    <form>{formContents}</form>
                </Modal>
            </Desktop>
            <Mobile>
                <Modal
                    isOpen={props.isOpen}
                    className={classNames("owner-contact-details-modal-mobile mobile-modal")}
                >
                    <form className="owner-contact-details-modal-mobile-form">
                        {formContents}
                    </form>
                </Modal>
            </Mobile>
        </>
    )
}

export default DeleteMembershipPrompt;