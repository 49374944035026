import React from 'react';
import PassStatus from '../../../Constants/PassStatus';
import { CustomColumnCellRendererProps } from '../../../Shared/Components/Presentational/Table';
import './PassRenewColumnCellRenderer.scss';

const PassRenewColumnCellRenderer: React.FC<CustomColumnCellRendererProps> = (props) => {
    const { clickAction, rowValue } = props;

    const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (rowValue.StatusCode === PassStatus.Expired && clickAction) {
            clickAction(rowValue);
        }
    };

    return (
        <div className="renew-checkbox" style={{ opacity: rowValue.StatusCode === PassStatus.Expired ? 1 : 0 }}>
            <input name="renewPass" id={`renewPass-${rowValue.Id}`} type="checkbox" onChange={onCheck} />
        </div>
    );
};

export default PassRenewColumnCellRenderer;
