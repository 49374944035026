import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddPassModal from '../Presentational/AddPassModal';
import { SuccessModal, ErrorModal } from '../../../Shared/Components/Presentational/Modal';
import { addCarPasses, addSeasonPasses, addShortStayPasses, analyzePassPhoto } from '../../Redux/ActionCreators';
import { Translation } from 'react-i18next';
import {
  SET_ADD_PASS_SUCCESSFUL_MODAL_OPEN,
  SET_ADD_PASS_ERROR_MODAL_OPEN,
  SET_IS_ADD_PASS_MODAL_OPEN,
  SET_IS_ADD_PASS_PHOTO_MODAL_OPEN,
  ANALYZE_PASS_PHOTO_SUCCESS,
  ANALYZE_PASS_PHOTO_FAILURE
} from '../../Redux/ActionTypes';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import PassFunctionalityTypes from '../../../Constants/PassFunctionalityTypes';

interface AddPassProps {
  isOpen: boolean;
  onBackButtonClick: () => void;
  addCarPassesRequest: (passes: AddCarPassDTO[]) => void;
  addSeasonPassesRequest: (passes: AddSeasonPassDTO[]) => void;
  addShortStayPassesRequest: (passes: AddShortStayPassDTO[]) => void;
  analyzePassPhotoRequest: (passPhotoFile: File) => void;
  isAddPassSuccessfulModalOpen: boolean;
  closeSuccessModal: () => void;
  isAddPassErrorModalOpen: boolean;
  isAddPassPhotoModalOpen: boolean;
  closeErrorModal: () => void;
  openErrorModal: () => void;
  openAddPassPhotoModal: () => void;
  closeAddPassPhotoModal: () => void;
  submittedOption: ParkPassTypeDTO;
  isSavingPass: boolean;
  isAnalyzingPassPhoto: boolean;
  isAnalyzingPassPhotoSuccessful: boolean;
  analyzingPassPhotoError: any;
  PassPhotoSizeLimitInKb: number;
  successfulPasses: any[];
  requirePassPhoto?: boolean;
}

class AddPass extends Component<AddPassProps> {
  private canAddPassForSelectedType = (selectedItem: ParkPassTypeDTO): boolean => {
    let result = selectedItem.CurrentNumberOfPasses < selectedItem.MaxNumberOfPasses;

    if (!result) {
      this.props.openErrorModal();
    }

    return result;
  };

  private listEmails = (emails: string[]) => emails.join(", ").replace(/,\s([^,]+)$/, ' and $1');

  private successfulMessage = (originalMessage: string): string => {
    var emailsToSend = this.props.successfulPasses
      .filter(a => a.Photo == null || a.Photo == undefined)
      .map(a => a.Email);

    if (emailsToSend.length == 0 || (!this.props.requirePassPhoto)) { return originalMessage; }

    return 'An email will be sent to ' + this.listEmails(emailsToSend) + ' with instructions to upload a photo.';
  }

  public render(): JSX.Element {
    return (
      <Translation>
        {(t: (key: string, args?: {}) => string) => {
          return (
            <>
              {this.props.isOpen && this.canAddPassForSelectedType(this.props.submittedOption) && (
                <>
                  <Desktop>
                    <AddPassModal
                      isOpen={true}
                      onBackButtonClick={this.props.onBackButtonClick}
                      addPassesRequest={passes =>
                        this.addPassesRequest(this.props.submittedOption, passes)
                      }
                      submittedOption={this.props.submittedOption}
                      isLoading={this.props.isSavingPass}
                      PassPhotoSizeLimitInKb={this.props.PassPhotoSizeLimitInKb}
                      isAnalyzingPassPhoto={this.props.isAnalyzingPassPhoto}
                      analyzingPassPhotoError={this.props.analyzingPassPhotoError}
                      isAnalyzingPassPhotoSuccessful={this.props.isAnalyzingPassPhotoSuccessful}
                      isAddPassPhotoModalOpen={this.props.isAddPassPhotoModalOpen}
                      openAddPassPhotoModal={this.props.openAddPassPhotoModal}
                      closeAddPassPhotoModal={this.props.closeAddPassPhotoModal}
                      analyzePassPhotoRequest={(file: File) => this.props.analyzePassPhotoRequest(file)}
                      requirePassPhoto={this.props.requirePassPhoto}
                    />
                  </Desktop>
                  <Mobile>
                    <AddPassModal
                      className="mobile-modal"
                      isOpen={true}
                      onBackButtonClick={this.props.onBackButtonClick}
                      addPassesRequest={passes =>
                        this.addPassesRequest(this.props.submittedOption, passes)
                      }
                      analyzePassPhotoRequest={(file: File) => this.props.analyzePassPhotoRequest(file)}
                      submittedOption={this.props.submittedOption}
                      isLoading={this.props.isSavingPass}
                      PassPhotoSizeLimitInKb={this.props.PassPhotoSizeLimitInKb}
                      isAnalyzingPassPhoto={this.props.isAnalyzingPassPhoto}
                      analyzingPassPhotoError={this.props.analyzingPassPhotoError}
                      isAnalyzingPassPhotoSuccessful={this.props.isAnalyzingPassPhotoSuccessful}
                      isAddPassPhotoModalOpen={this.props.isAddPassPhotoModalOpen}
                      openAddPassPhotoModal={this.props.openAddPassPhotoModal}
                      closeAddPassPhotoModal={this.props.closeAddPassPhotoModal}
                      requirePassPhoto={this.props.requirePassPhoto}
                    />
                  </Mobile>
                </>
              )}
              {this.props.isOpen && !this.canAddPassForSelectedType(this.props.submittedOption) && (
                <>
                  <Desktop>
                    <ErrorModal
                      isOpen={this.props.isAddPassErrorModalOpen}
                      onRequestClose={this.props.closeErrorModal}
                      closeModal={this.props.closeErrorModal}
                      title={t('myGuests.passes.add.errorTitle')}
                      message={t('myGuests.passes.add.maximumPassesAddedMessage', {
                        maximumNumberOfPasses: this.props.submittedOption.MaxNumberOfPasses
                      })}
                      buttonText={t('general.ok')}
                    />
                  </Desktop>
                  <Mobile>
                    <ErrorModal
                      className="mobile-error-modal"
                      isOpen={this.props.isAddPassErrorModalOpen}
                      onRequestClose={this.props.closeErrorModal}
                      closeModal={this.props.closeErrorModal}
                      title={t('myGuests.passes.add.errorTitle')}
                      message={t('myGuests.passes.add.maximumPassesAddedMessage', {
                        maximumNumberOfPasses: this.props.submittedOption.MaxNumberOfPasses
                      })}
                      buttonText={t('general.ok')}
                    />
                  </Mobile>
                </>
              )}
              {
                <>
                  <Desktop>
                    <SuccessModal
                      isOpen={this.props.isAddPassSuccessfulModalOpen}
                      onRequestClose={this.props.closeSuccessModal}
                      closeModal={this.props.closeSuccessModal}
                      title={t('myGuests.passes.add.successTitle')}
                      message={this.successfulMessage(t('myGuests.passes.add.successMessage'))}
                      buttonText={t('general.ok')}
                    />
                  </Desktop>
                  <Mobile>
                    <SuccessModal
                      className="mobile-success-modal"
                      isOpen={this.props.isAddPassSuccessfulModalOpen}
                      onRequestClose={this.props.closeSuccessModal}
                      closeModal={this.props.closeSuccessModal}
                      title={t('myGuests.passes.add.successTitle')}
                      message={this.successfulMessage(t('myGuests.passes.add.successMessage'))}
                      buttonText={t('general.ok')}
                    />
                  </Mobile>
                </>
              }
            </>
          );
        }}
      </Translation>
    );
  }

  public addPassesRequest(submittedOption: ParkPassTypeDTO, passes: AddPassDTO[]) {
    switch (submittedOption.FunctionalityCode) {
      case PassFunctionalityTypes.Car:
        this.props.addCarPassesRequest((passes as any[]) as AddCarPassDTO[]);
        break;
      case PassFunctionalityTypes.SeasonOwner:
      case PassFunctionalityTypes.SeasonResort:
        this.props.addSeasonPassesRequest((passes as any[]) as AddSeasonPassDTO[]);
        break;
      case PassFunctionalityTypes.ShortStay:
        this.props.addShortStayPassesRequest((passes as any[]) as AddShortStayPassDTO[]);
        break;
      default:
        break;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    isOpen: state.guests.isAddPassModalOpen,
    isAddPassErrorModalOpen: state.guests.isAddPassErrorModalOpen,
    isAddPassSuccessfulModalOpen: state.guests.isAddPassSuccessfulModalOpen,
    isAddPassPhotoModalOpen: state.guests.isAddPassPhotoModalOpen,
    isSavingPass: state.guests.isSavingPass,
    isAnalyzingPassPhoto: state.guests.isAnalyzingPassPhoto,
    isAnalyzingPassPhotoSuccessful: state.guests.isAnalyzingPassPhotoSuccessful,
    analyzingPassPhotoError: state.guests.analyzingPassPhotoError,
    PassPhotoSizeLimitInKb: state.guests.PassPhotoSizeLimitInKb,
    successfulPasses: state.guests.successfulPasses
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addCarPassesRequest: (passes: AddCarPassDTO[]) => {
      dispatch(addCarPasses(passes));
    },
    addSeasonPassesRequest: (passes: AddSeasonPassDTO[]) => {
      dispatch(addSeasonPasses(passes));
    },
    addShortStayPassesRequest: (passes: AddShortStayPassDTO[]) => {
      dispatch(addShortStayPasses(passes));
    },
    closeSuccessModal: () => {
      dispatch({ type: SET_ADD_PASS_SUCCESSFUL_MODAL_OPEN, payload: false });
    },
    closeErrorModal: () => {
      dispatch({ type: SET_ADD_PASS_ERROR_MODAL_OPEN, payload: false });
      dispatch({ type: SET_IS_ADD_PASS_MODAL_OPEN, payload: false });
    },
    openErrorModal: () => {
      dispatch({ type: SET_ADD_PASS_ERROR_MODAL_OPEN, payload: true });
    },
    openAddPassPhotoModal: () => {
      dispatch({ type: SET_IS_ADD_PASS_PHOTO_MODAL_OPEN, payload: true });
    },
    closeAddPassPhotoModal: () => {
      dispatch({ type: SET_IS_ADD_PASS_PHOTO_MODAL_OPEN, payload: false });
      dispatch({ type: ANALYZE_PASS_PHOTO_SUCCESS, payload: false });
      dispatch({ type: ANALYZE_PASS_PHOTO_FAILURE, payload: undefined });
    },
    analyzePassPhotoRequest: (passPhotoFile: File) => {
      dispatch(analyzePassPhoto(passPhotoFile));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPass);
