import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router';
import history from './History';
import { createStore, applyMiddleware, Middleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storageSession from 'redux-persist/lib/storage/session';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { Provider, ConnectedComponentClass } from 'react-redux';
import { rootReducer } from './RootReducer';
import i18nPromise from './i18n';
import './myHaulfryn.d';
import ErrorBoundary from './ErrorBoundary';
import RootApp from './App';
import i18n from 'i18next';
import axios, { AxiosResponse } from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import { RELOAD_TRANSLATIONS } from './Information/Redux/ActionTypes';
import { LOGIN_SUCCESS } from './Login/Redux/ActionTypes';
import { SET_SELECTED_PARK } from './User/Redux/ActionTypes';
declare const module: { hot: { accept: (path: string, func: () => void) => void } };

interface RootAppProps {
    restoreLogin: () => void;
    userRoles: UserRoleDTO[];
}

const persistConfig = {
    key: 'root',
    storage: storageSession,
};

export const LanguageChangeDetector: Middleware = (api) => (next) => (action) => {
    let parkCode: string | undefined = undefined;
    if (action.type === SET_SELECTED_PARK) {
        parkCode = action.payload.parkCode;
    }
    if (action.type === LOGIN_SUCCESS || action.type === RELOAD_TRANSLATIONS) {
        var state = api.getState();
        if (state && state.user && state.user.park) {
            parkCode = state.user.park.parkCode;
        }
    }

    if (action.type === RELOAD_TRANSLATIONS) {
        parkCode && i18n.removeResourceBundle(parkCode as string, 'translation');
    }

    if (parkCode) {
        if (i18n.hasResourceBundle(parkCode, 'translation')) {
            i18n.changeLanguage(parkCode);
        } else {
            axios.get('/api/translation/GetByLanguage/' + parkCode).then((result: AxiosResponse) => {
                i18n.addResourceBundle(parkCode as string, 'translation', result.data);
                i18n.changeLanguage(parkCode as string);
            });
        }
    }
    return next(action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, LanguageChangeDetector)));
const persistor = persistStore(store);

if (module.hot) {
    module.hot.accept('./RootReducer', () => {
        store.replaceReducer(persistedReducer);
    });
}

const render = (Component: ConnectedComponentClass<FunctionComponent<RootAppProps>, Pick<RootAppProps, never>>) => {
    return ReactDOM.render(
        <ErrorBoundary>
            <Provider store={store}>
                <Router history={history}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Component />
                    </PersistGate>
                </Router>
            </Provider>
        </ErrorBoundary>,
        document.getElementById('root')
    );
};

i18nPromise.then(
    () => {
        render(RootApp);
    },
    () => {
        render(RootApp);
    }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}
