import { PostApiCall } from "./ApiCall";

export class PostPassesSearchCall extends PostApiCall {
    constructor(filter: PassFilterDTO) {
        let url: string = "passes/" + filter.LodgeId + "/search";

        super(url);

        this.body = filter;
    }
}