import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { SET_MEMBERSHIPS_CONTACT_PROMPT_NEXT_STEP, SET_MEMBERSHIPS_FLOW_CONTACT_PROMPT_OPEN } from '../../Redux/ActionTypes';
import React from "react";
import MembershipFlowContactPromptModal from "../Presentational/MembershipFlowContactPromptModal";
import { GetHTMlContentBlockCall } from "../../../Contentful/Calls/GetHTMLContentBlockCall";

interface MembershipFlowContactPromptProps extends WithTranslation {
  showContactEmptyPrompt: boolean;
  promptType: string;
  setMembershipContactWarningModalOpen: (isOpen: boolean) => void;
  onNextStep: (form: any) => void;
  form: any;
}

interface MembershipFlowContactPromptState {
  modalTextWOutEmail: ContentfulContentObject | undefined;
  modalTextWOutMobile: ContentfulContentObject | undefined;
  modalTextWOutBoth: ContentfulContentObject | undefined;
  contentLoaded: boolean;
}

class MembershipFlowContactPrompt extends Component<MembershipFlowContactPromptProps, MembershipFlowContactPromptState> {
  constructor(props: any) {
    super(props);

    this.state = {
      modalTextWOutEmail: undefined,
      modalTextWOutMobile: undefined,
      modalTextWOutBoth: undefined,
      contentLoaded: false
    }

    Promise.all([
      this.getModalText("My Haulfryn - Email - Member Details", "modalTextWOutEmail"),
      this.getModalText("My Haulfryn - Member Details - Mobile Number", "modalTextWOutMobile"),
      this.getModalText("My Haulfryn - Member Details - Email & Mobile Number", "modalTextWOutBoth")
    ]).then(r => {
      r.forEach(c => this.setState({ ...this.state, [c.name]: c }));
      this.setState({ contentLoaded: true })
    });
  }

  getModalText = async (name: string, label: string): Promise<ContentfulContentObject> => {
    const modalText = await GetHTMlContentBlockCall(name, label);
    return modalText;
  }

  public render(): JSX.Element {
    const { t } = this.props;

    return (
      <>
        {this.state.contentLoaded && (
          <>
            <Desktop>
              <MembershipFlowContactPromptModal
                isOpen={this.props.showContactEmptyPrompt}
                promptType={this.props.promptType}
                setMembershipContactWarningModalOpen={this.props.setMembershipContactWarningModalOpen}
                onNextStep={this.props.onNextStep}
                form={this.props.form}
                modalTextWOutEmail={this.state.modalTextWOutEmail!}
                modalTextWOutMobile={this.state.modalTextWOutMobile!}
                modalTextWOutBoth={this.state.modalTextWOutBoth!}
              />
            </Desktop>
            <Mobile>
              <MembershipFlowContactPromptModal
                className="mobile-modal owner-contact-details-modal-mobile"
                isOpen={this.props.showContactEmptyPrompt}
                promptType={this.props.promptType}
                setMembershipContactWarningModalOpen={this.props.setMembershipContactWarningModalOpen}
                onNextStep={this.props.onNextStep}
                form={this.props.form}
                modalTextWOutEmail={this.state.modalTextWOutEmail!}
                modalTextWOutMobile={this.state.modalTextWOutMobile!}
                modalTextWOutBoth={this.state.modalTextWOutBoth!}
              />
            </Mobile>
          </>
        )}
      </>
    );
  };
};

const mapStateToProps = (state: any) => {
  return {
    showContactEmptyPrompt: state.modal.membershipflowContactprompt.isOpen,
    promptType: state.modal.membershipflowContactprompt.type,
  };
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    setMembershipContactWarningModalOpen: (isOpen: boolean) => {
      dispatch({ type: SET_MEMBERSHIPS_FLOW_CONTACT_PROMPT_OPEN, payload: { isOpen: isOpen, type: "" } })
    },
    setPromptNextStep: (nextStep: boolean) => {
      dispatch({ type: SET_MEMBERSHIPS_CONTACT_PROMPT_NEXT_STEP, payload: nextStep })
    }
  };
};


export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MembershipFlowContactPrompt)
)