import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { ErrorModal, SuccessModal } from '../../../Shared/Components/Presentational/Modal';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { analyzePassPhoto, renewSeasonPasses } from '../../Redux/ActionCreators';
import {
    ANALYZE_PASS_PHOTO_FAILURE,
    ANALYZE_PASS_PHOTO_SUCCESS,
    SET_IS_ADD_PASS_PHOTO_MODAL_OPEN,
    SET_IS_RENEW_PASS_MODAL_OPEN,
    SET_RENEW_PASS_ERROR_MODAL_OPEN,
    SET_RENEW_PASS_SUCCESSFUL_MODAL_OPEN
} from '../../Redux/ActionTypes';
import RenewPassModal from '../Presentational/RenewPassModal';
import PassFunctionalityTypes from '../../../Constants/PassFunctionalityTypes';

interface RenewPassProps {
    isOpen: boolean;
    onBackButtonClick: () => void;
    renewSeasonPassesRequest: (passes: AddSeasonPassDTO[]) => void;
    isRenewPassSuccessfulModalOpen: boolean;
    closeSuccessModal: () => void;
    isRenewPassErrorModalOpen: boolean;
    closeErrorModal: () => void;
    openErrorModal: () => void;
    submittedOption: ParkPassTypeDTO;
    passesToRenew: PassHeaderDTO[];
    onPassesRenewed: () => void;
    lodgeId: string;
    isSavingPass: boolean;
    isAnalyzingPassPhoto: boolean;
    isAnalyzingPassPhotoSuccessful: boolean;
    analyzingPassPhotoError: any;
    PassPhotoSizeLimitInKb: number;
    isAddPassPhotoModalOpen: boolean;
    openAddPassPhotoModal: () => void;
    closeAddPassPhotoModal: () => void;
    analyzePassPhotoRequest: (passPhotoFile: File) => void;
    requirePassPhoto?: boolean;
}

interface RenewPassState {
    hasRenewed: boolean;
}

class RenewPass extends Component<RenewPassProps, RenewPassState> {
    public state: RenewPassState = {
        hasRenewed: false
    };

    componentDidUpdate(prevProps: RenewPassProps) {
        if (this.props.isRenewPassSuccessfulModalOpen && !this.state.hasRenewed) {
            this.setState({ hasRenewed: true });
            this.props.onPassesRenewed();
        }

        if (prevProps.isRenewPassSuccessfulModalOpen && !this.props.isRenewPassSuccessfulModalOpen) {
            this.setState({ hasRenewed: false });
        }
    }

    private canRenewPassForSelectedType = (selectedItem: ParkPassTypeDTO): boolean => {
        const result = selectedItem.CurrentNumberOfPasses < selectedItem.MaxNumberOfPasses;

        if (!result) {
            this.props.openErrorModal();
        }

        return result;
    };

    public render(): JSX.Element {
        return (
            <Translation>
                {(t: (key: string, args?: {}) => string) => {
                    return (
                        <>
                            {this.props.isOpen && this.canRenewPassForSelectedType(this.props.submittedOption) && (
                                <>
                                    <Desktop>
                                        <RenewPassModal
                                            isOpen={true}
                                            onBackButtonClick={this.props.onBackButtonClick}
                                            renewPassesRequest={passes => this.renewPassesRequest(this.props.submittedOption, passes)}
                                            submittedOption={this.props.submittedOption}
                                            passesToRenew={this.props.passesToRenew}
                                            lodgeId={this.props.lodgeId}
                                            isLoading={this.props.isSavingPass}
                                            isAnalyzingPassPhoto={this.props.isAnalyzingPassPhoto}
                                            analyzingPassPhotoError={this.props.analyzingPassPhotoError}
                                            PassPhotoSizeLimitInKb={this.props.PassPhotoSizeLimitInKb}
                                            isAnalyzingPassPhotoSuccessful={this.props.isAnalyzingPassPhotoSuccessful}
                                            isAddPassPhotoModalOpen={this.props.isAddPassPhotoModalOpen}
                                            openAddPassPhotoModal={this.props.openAddPassPhotoModal}
                                            closeAddPassPhotoModal={this.props.closeAddPassPhotoModal}
                                            analyzePassPhotoRequest={(file: File) => this.props.analyzePassPhotoRequest(file)}
                                            requirePassPhoto={this.props.requirePassPhoto}/>
                                    </Desktop>
                                    <Mobile>
                                        <RenewPassModal
                                            className="mobile-modal"
                                            isOpen={true}
                                            onBackButtonClick={this.props.onBackButtonClick}
                                            renewPassesRequest={passes =>
                                                this.renewPassesRequest(this.props.submittedOption, passes)
                                            }
                                            submittedOption={this.props.submittedOption}
                                            passesToRenew={this.props.passesToRenew}
                                            lodgeId={this.props.lodgeId}
                                            isLoading={this.props.isSavingPass}
                                            PassPhotoSizeLimitInKb={this.props.PassPhotoSizeLimitInKb}
                                            isAnalyzingPassPhoto={this.props.isAnalyzingPassPhoto}
                                            analyzingPassPhotoError={this.props.analyzingPassPhotoError}
                                            isAnalyzingPassPhotoSuccessful={this.props.isAnalyzingPassPhotoSuccessful}
                                            isAddPassPhotoModalOpen={this.props.isAddPassPhotoModalOpen}
                                            openAddPassPhotoModal={this.props.openAddPassPhotoModal}
                                            closeAddPassPhotoModal={this.props.closeAddPassPhotoModal}
                                            analyzePassPhotoRequest={(file: File) => this.props.analyzePassPhotoRequest(file)}
                                            requirePassPhoto={this.props.requirePassPhoto}
                                        />
                                    </Mobile>
                                </>
                            )}
                            {this.props.isOpen && !this.canRenewPassForSelectedType(this.props.submittedOption) && (
                                <>
                                    <Desktop>
                                        <ErrorModal
                                            isOpen={this.props.isRenewPassErrorModalOpen}
                                            onRequestClose={this.props.closeErrorModal}
                                            closeModal={this.props.closeErrorModal}
                                            title={t('myGuests.passes.add.errorTitle')}
                                            message={t('myGuests.passes.add.maximumPassesAddedMessage', {
                                                maximumNumberOfPasses: this.props.submittedOption.MaxNumberOfPasses
                                            })}
                                            buttonText={t('general.ok')}
                                        />
                                    </Desktop>
                                    <Mobile>
                                        <ErrorModal
                                            className="mobile-error-modal"
                                            isOpen={this.props.isRenewPassErrorModalOpen}
                                            onRequestClose={this.props.closeErrorModal}
                                            closeModal={this.props.closeErrorModal}
                                            title={t('myGuests.passes.add.errorTitle')}
                                            message={t('myGuests.passes.add.maximumPassesAddedMessage', {
                                                maximumNumberOfPasses: this.props.submittedOption.MaxNumberOfPasses
                                            })}
                                            buttonText={t('general.ok')}
                                        />
                                    </Mobile>
                                </>
                            )}
                            {
                                <>
                                    <Desktop>
                                        <SuccessModal
                                            isOpen={this.props.isRenewPassSuccessfulModalOpen}
                                            onRequestClose={this.props.closeSuccessModal}
                                            closeModal={this.props.closeSuccessModal}
                                            title={t('myGuests.passes.renew.successTitle')}
                                            message={t('myGuests.passes.renew.successMessage')}
                                            buttonText={t('general.ok')}
                                        />
                                    </Desktop>
                                    <Mobile>
                                        <SuccessModal
                                            className="mobile-success-modal"
                                            isOpen={this.props.isRenewPassSuccessfulModalOpen}
                                            onRequestClose={this.props.closeSuccessModal}
                                            closeModal={this.props.closeSuccessModal}
                                            title={t('myGuests.passes.renew.successTitle')}
                                            message={t('myGuests.passes.renew.successMessage')}
                                            buttonText={t('general.ok')}
                                        />
                                    </Mobile>
                                </>
                            }
                        </>
                    );
                }}
            </Translation>
        );
    }

    public renewPassesRequest(submittedOption: ParkPassTypeDTO, passes: AddPassDTO[]) {
        switch (submittedOption.FunctionalityCode) {
            case PassFunctionalityTypes.SeasonOwner:
            case PassFunctionalityTypes.SeasonResort:
                this.props.renewSeasonPassesRequest((passes as any[]) as AddSeasonPassDTO[]);
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        isOpen: state.guests.isRenewPassModalOpen,
        isRenewPassErrorModalOpen: state.guests.isRenewPassErrorModalOpen,
        isRenewPassSuccessfulModalOpen: state.guests.isRenewPassSuccessfulModalOpen,
        lodgeId: state.user.lodge.LodgeId,
        isSavingPass: state.guests.isSavingPass,
        isAddPassPhotoModalOpen: state.guests.isAddPassPhotoModalOpen,
        isAnalyzingPassPhoto: state.guests.isAnalyzingPassPhoto,
        isAnalyzingPassPhotoSuccessful: state.guests.isAnalyzingPassPhotoSuccessful,
        analyzingPassPhotoError: state.guests.analyzingPassPhotoError,
        PassPhotoSizeLimitInKb: state.guests.PassPhotoSizeLimitInKb
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeSuccessModal: () => {
            dispatch({ type: SET_RENEW_PASS_SUCCESSFUL_MODAL_OPEN, payload: false });
        },
        closeErrorModal: () => {
            dispatch({ type: SET_RENEW_PASS_ERROR_MODAL_OPEN, payload: false });
            dispatch({ type: SET_IS_RENEW_PASS_MODAL_OPEN, payload: false });
        },
        openErrorModal: () => {
            dispatch({ type: SET_RENEW_PASS_ERROR_MODAL_OPEN, payload: true });
        },
        renewSeasonPassesRequest: (passes: AddSeasonPassDTO[]) => {
            dispatch(renewSeasonPasses(passes));
        },
        openAddPassPhotoModal: () => {
            dispatch({ type: SET_IS_ADD_PASS_PHOTO_MODAL_OPEN, payload: true });
        },
        closeAddPassPhotoModal: () => {
            dispatch({ type: SET_IS_ADD_PASS_PHOTO_MODAL_OPEN, payload: false });
            dispatch({ type: ANALYZE_PASS_PHOTO_SUCCESS, payload: false });
            dispatch({ type: ANALYZE_PASS_PHOTO_FAILURE, payload: undefined });
        },
        analyzePassPhotoRequest: (passPhotoFile: File) => {
            dispatch(analyzePassPhoto(passPhotoFile));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RenewPass);
