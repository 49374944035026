import { PostApiCall } from "./ApiCall";

export class PostSetPaymentMethodCall extends PostApiCall {
    constructor(paymentMethodDTO: PaymentMethodDTO) {
        let url: string = 'Membership/SetPaymentMethod';

        super(url);

        this.body = paymentMethodDTO;
    }
}