import React, { Component } from "react";
import {
  Desktop,
  Mobile,
} from "../../../Shared/Components/Presentational/ResponsiveViews";
import { connect } from "react-redux";
import MyGuestsPage from "../Presentational/MyGuestsPage";
import MyGuestsPageMobile from "../Presentational/MyGuestsPage.mobile";
import { ColumnDefinition } from "../../../Shared/Components/Presentational/Table";
import {
  getPasses,
  getParkPassTypes,
  setSelectedPassType,
  removeCarPass,
  removeShortStayPass,
  updateFilter,
  removeSeasonPass,
  getPassPhotoSizeLimit,
} from "../../Redux/ActionCreators";
import {
  SEARCH_PASSES_REQUEST,
  SET_IS_ADD_PASS_MODAL_OPEN,
  SET_DELETE_PASS_CONFIRMATION_MODAL_OPEN,
  SET_IS_RENEW_PASS_MODAL_OPEN,
} from "../../Redux/ActionTypes";
import PassStatusColumnCellRenderer from "../Presentational/PassStatusColumnCellRenderer";
import RemovePassColumnCellRenderer from "../Presentational/RemovePassColumnCellRenderer";
import CoOwnerAccessCellRenderer from "../Presentational/CoOwnerAccessCellRenderer";
import PassFunctionalityTypes from "../../../Constants/PassFunctionalityTypes";
import {
  ConfirmModal,
  SuccessModal,
} from "../../../Shared/Components/Presentational/Modal";
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { compose } from "redux";
import PassRenewColumnCellRenderer from "../Presentational/PassRenewColumnCellRenderer";
import PassNameMobileColumnCellRenderer from "../Presentational/PassNameMobileColumnCellRenderer";
import { PassStatusMobileColumnCellRenderer } from "../Presentational/PassStatusMobileColumnCellRenderer";
import { Get, Post, Put } from "../../../Api/ApiService";
import { PutUpdateSeasonPassCall } from "../../../Api/Calls/PutUpdateSeasonPassCall";
import { GetCoOwnerAccessTypesCall } from "../../../Api/Calls/GetCoOwnerAccessTypesCall";
import { JsxEmit } from "typescript";
import { PostGetSeasonPassesCall } from "../../../Api/Calls/PostGetSeasonPassesCall";
import { GetParkCall } from "../../../Contentful/Calls/GetParkCall";
import { Entry } from "contentful";

interface MyGuestsProps extends WithTranslation {
  dispatch: any;
  filter: PassFilterDTO;
  selectedFilter: ParkPassTypeDTO;
  filters: ParkPassTypeDTO[];
  searchResults: PagedResultDTO;
  selectedLodge: SelectedLodgeDTO;
  isSearchingPasses: boolean;
  selectedPark: any;
  isLoadingParkPassTypes: boolean;
  onFilterSelected: (filter: ParkPassTypeDTO) => void;
  onFilterChange(newFilter: PassFilterDTO): void;
  openAddPassesModal: () => void;
  closeAddPassesModal: () => void;
  isHeaderOpen: boolean;
  openDeletePassModal: () => void;
  isDeletePassModalOpen: boolean;
  closeDeletePassModal: () => void;
  removeCarPass: (passId: string) => void;
  removeSeasonPass: (passId: string) => void;
  removeShortStayPass: (passId: string, passFriendlyName: string) => void;
  openRenewPassesModal: () => void;
  closeRenewPassesModal: () => void;
  user: any;
}

interface MyGuestsState {
  columns: ColumnDefinition[];
  mobileColumns: ColumnDefinition[];
  rowToDelete: any;
  passesToRenew: PassHeaderDTO[];
  coOwnerAccessTypes: DropdownListOption[];
  contentfulPark?: Entry<ContentfulPark>;
  requirePassPhoto?: boolean;
  showCoOwnerAccessGrantedModal: boolean;
}

class MyGuests extends Component<MyGuestsProps, MyGuestsState> {
  public componentDidMount(): void {
    Get<CodeForeignKeyItem[]>(new GetCoOwnerAccessTypesCall()).then(
      (result) => {
        let accessTypes = result.data.map((c) => {
          return { label: c.Name, value: c.Code } as DropdownListOption;
        });

        this.setState({
          coOwnerAccessTypes: accessTypes,
        });
      },
      (err) => {}
    );

    GetParkCall(this.props.selectedPark.parkCode).then((result) => {
      this.setState({
        contentfulPark: result,
        requirePassPhoto: result.fields.myHaulfrynRequireGuestPhotos
      });
    });

    this.props.dispatch({
      type: SEARCH_PASSES_REQUEST,
      payload: undefined,
    });

    if (this.props.selectedPark) {
      this.props.dispatch(getParkPassTypes());
    }
    this.props.dispatch(getPassPhotoSizeLimit());
  }
  updateSeasonPassCoOwnerAccess = (rowVal: PassHeaderDTO): void => {
    Put(
      new PutUpdateSeasonPassCall(
        this.props.selectedLodge.LodgeId,
        rowVal.Id!,
        rowVal.CoOwnerAccessTypeCode
      )
    ).then(() => {
      if (rowVal.CoOwnerAccessTypeCode == "FullAccess") {
        this.setState({ showCoOwnerAccessGrantedModal: true });
      }
    });
  };

  onFilterSelected = (parkPassType: ParkPassTypeDTO) => {
    this.setState({ passesToRenew: [] });
    this.props.onFilterSelected(parkPassType);
  };

  openDeletePassModal = (rowVal: any): void => {
    this.setState({ rowToDelete: rowVal });
    this.props.openDeletePassModal();
  };

  toggleRenewPass = (rowVal: PassHeaderDTO): void => {
    if (this.state.passesToRenew.findIndex((p) => p.Id === rowVal.Id) > -1) {
      this.setState((prev) => {
        return {
          passesToRenew: prev.passesToRenew.filter((p) => p.Id !== rowVal.Id),
        };
      });
    } else {
      this.setState((prev) => {
        return { passesToRenew: [...prev.passesToRenew, rowVal] };
      });
    }
  };

  onPassesRenewed = (): void => {
    this.setState({ passesToRenew: [] });
  };

  public state: MyGuestsState = {
    columns: [
      {
        fieldName: "Renew",
        displayName: "",
        canShowColumn: (filter) =>
          [
            PassFunctionalityTypes.SeasonResort,
            PassFunctionalityTypes.SeasonOwner,
          ].includes((filter as PassFilterDTO).FunctionalityCode),
        customCellRenderer: PassRenewColumnCellRenderer,
        customCellClickAction: this.toggleRenewPass,
        fitContent: true,
        headerClassname: "renew-checkbox-header",
        cellClassname: "renew-checkbox-header",
      },
      {
        fieldName: "Name",
        displayName: "myGuests.passes.nameColumn",
        bold: true,
        canShowColumn: (_) => true,
      },
      {
        fieldName: "PassDates",
        displayName: "myGuests.passes.passDatesColumn",
        canShowColumn: (filter) =>
          [
            PassFunctionalityTypes.ShortStay,
            PassFunctionalityTypes.SeasonOwner,
            PassFunctionalityTypes.SeasonResort,
          ].includes((filter as PassFilterDTO).FunctionalityCode),
      },
      {
        fieldName: "RegistrationNumber",
        displayName: "myGuests.passes.registrationNumberColumn",
        canShowColumn: (filter) =>
          [PassFunctionalityTypes.Car].includes(
            (filter as PassFilterDTO).FunctionalityCode
          ),
      },
      {
        fieldName: "CoOwnerAccessType",
        displayName: "myGuests.passes.coOwnerAccessType",
        customCellRenderer: CoOwnerAccessCellRenderer,
        canShowColumn: (filter) =>
          [
            PassFunctionalityTypes.SeasonOwner,
            PassFunctionalityTypes.SeasonResort,
          ].includes((filter as PassFilterDTO).FunctionalityCode) &&
          !!this.props.user.ownerId &&
          !!this.state.contentfulPark &&
          !!this.state.contentfulPark!.fields
            .myHaulfrynAllowPermissionDelegation,
        customCellClickAction: this.updateSeasonPassCoOwnerAccess,
      },
      {
        fieldName: "Status",
        displayName: "myGuests.passes.statusColumn",
        translateField: false,
        customCellRenderer: PassStatusColumnCellRenderer,
        canShowColumn: (_) => true,
      },
      {
        fieldName: "Delete",
        displayName: "",
        canShowColumn: (_) => true,
        customCellRenderer: RemovePassColumnCellRenderer,
        customCellClickAction: this.openDeletePassModal,
        fitContent: true,
        headerClassname: "remove-pass-cell-header",
      },
    ],
    mobileColumns: [
      {
        fieldName: "Name",
        displayName: "myGuests.passes.nameColumn",
        canShowColumn: (_) => true,
        customCellRenderer: PassNameMobileColumnCellRenderer,
        customCellClickAction: this.toggleRenewPass,
      },
      {
        fieldName: "Status",
        displayName: "myGuests.passes.statusColumn",
        canShowColumn: (_) => true,
        customCellRenderer: PassStatusMobileColumnCellRenderer,
        customCellClickAction: this.openDeletePassModal,
        fitContent: true,
        cellClassname: "pass-status-cell-mobile-container",
      },
    ],
    rowToDelete: undefined,
    passesToRenew: [],
    coOwnerAccessTypes: [],
    contentfulPark: undefined,
    showCoOwnerAccessGrantedModal: false,
  };

  public onDeletePass(submitted: boolean): void {
    if (submitted && this.state.rowToDelete) {
      if (
        this.props.selectedFilter.FunctionalityCode ===
        PassFunctionalityTypes.Car
      ) {
        this.props.removeCarPass(this.state.rowToDelete.PassId);
      } else if (
        this.props.selectedFilter.FunctionalityCode ===
        PassFunctionalityTypes.ShortStay
      ) {
        const friendlyName = this.props.selectedFilter.NameTranslationKey;
        const translatedFriendlyName = this.props.t(friendlyName);
        this.props.removeShortStayPass(
          this.state.rowToDelete.PassId,
          translatedFriendlyName
        );
      } else {
        // season passes
        this.props.removeSeasonPass(this.state.rowToDelete.PassId);
      }
    }
    this.props.closeDeletePassModal();
  }

  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <>
        <ConfirmModal
          isOpen={this.props.isDeletePassModalOpen}
          title={t("myGuests.passes.remove.title")}
          message={t("myGuests.passes.remove.description")}
          cancelButtonText={t("general.cancel")}
          confirmButtonText={t("myGuests.passes.remove.remove")}
          onClose={(submitted) => this.onDeletePass(submitted)}
        />
        <Desktop>
          <MyGuestsPage
            columns={this.state.columns}
            pagedResults={this.props.searchResults}
            filter={this.props.filter}
            selectedFilter={this.props.selectedFilter}
            filters={this.props.filters}
            onFilterSelected={this.onFilterSelected}
            onFilterChange={this.props.onFilterChange}
            pageSize={20}
            isSearchingPasses={this.props.isSearchingPasses}
            openAddPassesModal={this.props.openAddPassesModal}
            closeAddPassesModal={this.props.closeAddPassesModal}
            isHeaderOpen={this.props.isHeaderOpen}
            passesToRenew={this.state.passesToRenew}
            openRenewPassesModal={this.props.openRenewPassesModal}
            closeRenewPassesModal={this.props.closeRenewPassesModal}
            onPassesRenewed={this.onPassesRenewed}
            coOwnerAccessTypes={this.state.coOwnerAccessTypes}
            requirePassPhoto={this.state.requirePassPhoto}
          />
        </Desktop>
        <Mobile>
          <MyGuestsPageMobile
            columns={this.state.mobileColumns}
            pagedResults={this.props.searchResults}
            filter={this.props.filter}
            selectedFilter={this.props.selectedFilter}
            filters={this.props.filters}
            onFilterSelected={this.onFilterSelected}
            onFilterChange={this.props.onFilterChange}
            pageSize={20}
            isSearchingPasses={this.props.isSearchingPasses}
            openAddPassesModal={this.props.openAddPassesModal}
            closeAddPassesModal={this.props.closeAddPassesModal}
            isHeaderOpen={this.props.isHeaderOpen}
            passesToRenew={this.state.passesToRenew}
            openRenewPassesModal={this.props.openRenewPassesModal}
            closeRenewPassesModal={this.props.closeRenewPassesModal}
            onPassesRenewed={this.onPassesRenewed}
            requirePassPhoto={this.state.requirePassPhoto}
          />
        </Mobile>
        <SuccessModal
          isOpen={this.state.showCoOwnerAccessGrantedModal}
          closeModal={() => {
            this.setState({ showCoOwnerAccessGrantedModal: false });
          }}
          title={t("myGuests.passes.coOwnerAccessSuccessModal.title")}
          message={t("myGuests.passes.coOwnerAccessSuccessModal.body")}
          buttonText={t("general.ok")}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    filter: state.guests.passesFilter,
    selectedFilter: state.guests.selectedParkPassType,
    filters: state.guests.parkPassTypes,
    searchResults: state.guests.passes,
    selectedLodge: state.user.lodge,
    selectedPark: state.user.park,
    user: state.user,
    isLoadingParkPassTypes: state.guests.isLoadingParkPassTypes,
    isSearchingPasses: state.guests.isSearchingPasses,
    isHeaderOpen: state.application.isHeaderOpen,
    isDeletePassModalOpen: state.guests.isDeletePassModalOpen
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getPasses,
    getPassPhotoSizeLimit,
    getParkPassTypes,
    onFilterSelected: (passType: ParkPassTypeDTO) => {
      dispatch(setSelectedPassType(passType, getPasses));
    },
    onFilterChange: (filter: PassFilterDTO) => {
      if (filter) {
        dispatch(updateFilter(filter));
      }
    },
    openAddPassesModal: () =>
      dispatch({ type: SET_IS_ADD_PASS_MODAL_OPEN, payload: true }),
    closeAddPassesModal: () =>
      dispatch({ type: SET_IS_ADD_PASS_MODAL_OPEN, payload: false }),
    openRenewPassesModal: () =>
      dispatch({ type: SET_IS_RENEW_PASS_MODAL_OPEN, payload: true }),
    closeRenewPassesModal: () =>
      dispatch({ type: SET_IS_RENEW_PASS_MODAL_OPEN, payload: false }),
    openDeletePassModal: () =>
      dispatch({
        type: SET_DELETE_PASS_CONFIRMATION_MODAL_OPEN,
        payload: true,
      }),
    closeDeletePassModal: () =>
      dispatch({
        type: SET_DELETE_PASS_CONFIRMATION_MODAL_OPEN,
        payload: false,
      }),
    removeCarPass: (passId: string) => dispatch(removeCarPass(passId)),
    removeSeasonPass: (passId: string) => dispatch(removeSeasonPass(passId)),
    removeShortStayPass: (passId: string, passFriendlyName: string) =>
      dispatch(removeShortStayPass(passId, passFriendlyName)),
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(MyGuests);
function getFieldList(FunctionalityCode: any): FormField<AddPassDTO>[] {
  throw new Error("Function not implemented.");
}
