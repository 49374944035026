import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './AddPassPhotoHeader.scss';

interface AddPassPhotoModalHeaderProps extends WithTranslation {
  headerText: string;
  instructions: ContentfulContentObject;
}

const AddPassPhotoHeader: React.FC<AddPassPhotoModalHeaderProps> = props => {
  const { t } = props;

  return (
    <>
      <div className='desktopVersion'>
        <div className="add-pass-photo-modal-header">
          <h1>{props.headerText}</h1>
          <div className="float-left">
            <h2 style={{ marginTop: "0px" }}>{t("addMembership.photoUpload.title")}</h2>
          </div>
          <br />
          <br />
          <br />
          <div className='contentful-text'>
            {documentToReactComponents(props.instructions.content)}
          </div>
          {/* <p style={{ textAlign: "center" }}>{t("addMembership.photoUpload.headerMessage")} {passPhotoSizeLimitInMB}MB:</p> */}
        </div>
      </div>

      <div className='mobileVersion'>
        <div className="add-pass-photo-modal-header">
          <h1>{props.headerText}</h1>
          <div>
            <h2 style={{ marginTop: "0px", textAlign: "center" }}>{t("addMembership.photoUpload.title")}</h2>
          </div>
          <br />
          <div className='contentful-text'>
            {documentToReactComponents(props.instructions.content)}
          </div>
          {/* <p style={{ textAlign: "center" }}>{t("addMembership.photoUpload.headerMessage")} {passPhotoSizeLimitInMB}MB:</p> */}
        </div>
      </div>
    </>
  );
};

export default withTranslation()(AddPassPhotoHeader);