import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface MemberBreakdownContainerProps extends WithTranslation {
  currencySymbol: string;
  details: MemberBreakdownDTO;
  isAnnually: boolean;
}

const MemberBreakdownContainer: React.FC<MemberBreakdownContainerProps> = (props: MemberBreakdownContainerProps) => {
  const { t } = props;

  return (
    <div className='member-breakdown-container'>
      <h4>{props.details.FirstName + " " + props.details.LastName}</h4>
      <h4>{props.details.MembershipName + " " + props.details.TermDescription}</h4>
      <ul>
        {props.details.StartDate && (
          <li><p>Membership Start Date: {(new Date(props.details.StartDate)).toDateString()}</p></li>
        )}
        {props.details.Notes.length > 0 && props.details.Notes.map(note => (
          <li><p>Note: {note}</p></li>
        ))}
      </ul>
    </div>
  )
}

export default withTranslation()(MemberBreakdownContainer);