import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { SET_IS_MY_BANK_DETAILS_MODAL_OPEN } from '../../Redux/ActionTypes';
import MyBankDetailsModal from '../Presentational/MyBankDetailsModal';

interface MyBankDetailsProps {
    isOpen: boolean;
    myBankDetails: MyBankDetailsDTO;
    onBackButtonClick: () => void;
}

class MyBankDetails extends Component<MyBankDetailsProps> {
    public render(): JSX.Element {
        return (
            <>
                {this.props.isOpen && (
                    <>
                        <Desktop>
                            <MyBankDetailsModal
                                isOpen={true}
                                myBankDetails={this.props.myBankDetails}
                                onBackButtonClick={this.props.onBackButtonClick}
                            />
                        </Desktop>
                        <Mobile>
                            <MyBankDetailsModal
                                className="mobile-modal my-bank-details-modal-mobile"
                                isOpen={true}
                                myBankDetails={this.props.myBankDetails}
                                onBackButtonClick={this.props.onBackButtonClick}
                            />
                        </Mobile>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isOpen: state.account.isMyBankDetailsModalOpen
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onBackButtonClick: () => dispatch({ type: SET_IS_MY_BANK_DETAILS_MODAL_OPEN, payload: false })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBankDetails);
