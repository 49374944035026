import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import FreshDeskSourceTypes from "../../../Constants/FreshDeskSourceTypes";
import { PrimaryButton } from "../../../Shared/Components/Presentational/Buttons";
import { ClickableIcon } from "../../../Shared/Components/Presentational/Icons";
import {
  Desktop,
  Mobile,
} from "../../../Shared/Components/Presentational/ResponsiveViews";
import SelectableChip from "../../../Shared/Components/Presentational/SelectableChip";
import Spinner from "../../../Shared/Components/Presentational/Spinner";
import { Widget } from "../../../Shared/Components/Presentational/Widget";
import SelectedTicketTable from "./SelectedTicketTable";

interface ConversationListProps {
  isLoading: boolean;
  selectdTicket: FreshDeskTicketDTO;
  onBackPress: () => void;
  conversations: PagedResultDTO;
  filter: OrderedFilterDTO;
  loadMoreResults: () => void;
  openQuickReplyModal: () => void;
}

const ConversationList: React.FC<ConversationListProps> = (props) => {
  const obtainStraplineText = (
    source?: string,
    isPrivate?: boolean
  ): string => {
    if (isPrivate) {
      return "Added a Private Note";
    }

    switch (source) {
      case FreshDeskSourceTypes.Reply:
        return "Replied to Conversation";
      case FreshDeskSourceTypes.Note:
        return "Added a Note";
      case FreshDeskSourceTypes.CreatedFromTweets:
        return "Added from Tweet";
      case FreshDeskSourceTypes.CreatedFromSurveyFeedback:
        return "Added from Survey Feedback";
      case FreshDeskSourceTypes.CreatedFromFaceBookPost:
        return "Added from Facebook post";
      case FreshDeskSourceTypes.CreatedFromForwardedEmail:
        return "Forwarded from Email";
      case FreshDeskSourceTypes.CreatedFromPhone:
        return "Added from Mobile";
      case FreshDeskSourceTypes.ECommerce:
        return "Added from EComerce";
      default:
        return "";
    }
  };

  return (
    <>
      <Widget
        position={{
          columnIndex: 1,
          columnSpan: 12,
          rowIndex: 4,
          rowSpan: 1,
        }}
        contentClassName="conversations-container"
        containerClassName="conversations-list"
      >
        <SelectedTicketTable selectedTicket={props.selectdTicket} />
        <section className="conversations">
          <InfiniteScroll
            loadMore={props.loadMoreResults}
            pageStart={1}
            loader={<Spinner />}
          >
            <PrimaryButton
              text="Quick Reply"
              className="quick-reply-button"
              onClick={props.openQuickReplyModal}
            />
            {!props.isLoading &&
              props.conversations &&
              (props.conversations.Dtos as FreshDeskConversationDTO[]).map(
                (con) => (
                  <article
                    className={"conversation" + (con.Private ? " private" : "")}
                  >
                    <Desktop>
                      <time className="time-desk">
                        {moment(con.GetCreatedAt).format(
                          "ddd DD MMM YYYY [at] LT"
                        )}
                      </time>
                    </Desktop>
                    <Mobile>
                      <div className="time-mob">
                        <time>
                          {moment(con.GetCreatedAt).format("DD/MM/YYYY LT")}
                        </time>
                      </div>
                    </Mobile>
                    <h3>{con.UserName || "User"}</h3>
                    <p className="strapline">
                      <b>{obtainStraplineText(con.Source, con.Private)}</b>
                    </p>
                    {con.Private && <p className="content">Private Note</p>}
                    {!con.Private && (
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: con.Body }}
                      />
                    )}

                    {con.Attachments.length > 0 && (
                      <div className="attachment-container">
                        {con.Attachments.map((attachment) => (
                          <SelectableChip
                            itemKey={attachment.Id}
                            onSelect={(_) =>
                              window.open(attachment.attachment_url)
                            }
                          >
                            {attachment.Name}
                          </SelectableChip>
                        ))}
                      </div>
                    )}
                  </article>
                )
              )}
          </InfiniteScroll>
        </section>
      </Widget>
      <ClickableIcon
        className="back-button"
        icon={faArrowLeft}
        clickAction={props.onBackPress}
      />
    </>
  );
};

export default ConversationList;
