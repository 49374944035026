import classNames from 'classnames';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Features from '../../../Constants/Features';
import { IconLinkContainer } from '../../../Shared/Components/Presentational/Containers';
import FeatureToggle from '../../../Shared/Components/Presentational/FeatureToggle';
import ParkRestaurantsModal from '../Container/ParkRestaurantsModal';
import './ReservationLinksWidget.scss';
import RestaurantDetailModal from '../Container/RestaurantDetailModal';
import BookTableModal from '../Container/BookTableModal';
import { RestaurantReservationModals } from '../../../Constants/Calendar/RestaurantReservationModals'
import { Entry } from 'contentful';

interface ReservationLinksWidgetProps {
    containerClassName?: string;
    showHeader: boolean;
    lodgeId?: number;
    accountId?: number;
    parkInformation: Entry<ContentfulParkInformation>;
    membershipNumber?: string;
    isMember: boolean;
}

const ReservationLinksWidget: React.FC<ReservationLinksWidgetProps> = props => {
    const [activeRestaurantReservationModal, setActiveRestaurantReservationModal] = useState<RestaurantReservationModals>(RestaurantReservationModals.None);
    const [hasRestaurants, setHasRestaurants] = useState(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState<PromotedRestaurant>();
    const { t } = useTranslation();

    // this prevents the component calling search multiple times because this function will never change
    const updateHasRestaurants = useCallback((result: boolean) => {
        setHasRestaurants(result);
    }, []);

    const onRestaurantSelected = (selectedRestaurant: PromotedRestaurant) => {
        setSelectedRestaurant(selectedRestaurant);
        setActiveRestaurantReservationModal(RestaurantReservationModals.RestaurantDetailModal)
    };

    return (
        <>
            <div className={classNames(props.containerClassName)}>
                {props.showHeader && <p className="my-calendar-reservation-header">{t('myCalendar.reservations.header')}</p>}
                <div className="reservations-container">
                    <FeatureToggle features={[Features.Restaurant]}>
                        <IconLinkContainer
                            label={t('myCalendar.reservations.dining')}
                            href="#"
                            className="reservation-link"
                            iconSrc="/images/dining.svg"
                            onClick={() => setActiveRestaurantReservationModal(RestaurantReservationModals.ParkRestaurantsModal)}
                            
                        />
                    </FeatureToggle>
                    {props.parkInformation && <FeatureToggle features={[Features.Activities]}>
                        <IconLinkContainer
                            label={props.parkInformation.fields.myHaulfrynActivityLabel}
                            href={props.parkInformation.fields.myHaulfrynActivityBookingUrl}
                            className="reservation-link"
                            iconSrc="/images/activities.svg"
                        />
                    </FeatureToggle>}
                    {props.parkInformation && <FeatureToggle features={[Features.Spa]}>
                        <IconLinkContainer
                            label={t('myCalendar.reservations.spa')}
                            href={props.parkInformation.fields.myHaulfrynSpaBookingUrl}
                            className="reservation-link"
                            iconSrc="/images/spa.svg"
                        />
                    </FeatureToggle>}
                </div>
            </div>
            <ParkRestaurantsModal
                isOpen={activeRestaurantReservationModal == RestaurantReservationModals.ParkRestaurantsModal}
                onRequestClose={() => setActiveRestaurantReservationModal(RestaurantReservationModals.None)}
                onRestaurantsFetched={updateHasRestaurants}
                onRestaurantSelected={onRestaurantSelected}
            />
            {selectedRestaurant && (
                <>
                    <RestaurantDetailModal
                        isOpen={activeRestaurantReservationModal == RestaurantReservationModals.RestaurantDetailModal}
                        onRequestClose={() => {
                            setActiveRestaurantReservationModal(RestaurantReservationModals.ParkRestaurantsModal)
                        }}
                        restaurant={selectedRestaurant}
                        openTableBookingModal={() => setActiveRestaurantReservationModal(RestaurantReservationModals.BookTableModal)}
                        isMemberWithOutMembershipNumber={props.isMember && props.membershipNumber === undefined}
                    />
                    <BookTableModal
                        isOpen={activeRestaurantReservationModal == RestaurantReservationModals.BookTableModal}
                        onRequestClose={() => {
                            setActiveRestaurantReservationModal(RestaurantReservationModals.RestaurantDetailModal)
                        }}
                        lodgeId={props.lodgeId}
                        accountId={props.accountId}
                        restaurant={selectedRestaurant}
                        membershipNumber={props.membershipNumber}
                    />
                </>
            )}
        </>
    );
};

export default ReservationLinksWidget;
