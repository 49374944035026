import React from 'react';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import RegisterPage from '../Presentational/RegisterPage';
import RegisterPageMobile from '../Presentational/RegisterPage.mobile';
import { login } from '../../Redux/ActionCreators';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

interface RegisterProps {
    loginClick: (email: string, password: string, type: string) => void;
    isLoggingIn: boolean;
    loginFailureMessage: string;
    location: any;
    isLoggedIn: boolean;
}

class Register extends React.Component<RegisterProps> {
    render() {

        const { location, loginClick } = this.props;

        if (this.props.isLoggedIn) {
            return (<Redirect
                to={{
                    pathname: location && location.state && location.state.prevLocation ? location.state.prevLocation : '/'
                }}
            />);
        } else {
            return (
                <>
                    <Desktop>
                        <RegisterPage isLoggingIn={this.props.isLoggingIn} loginClick={loginClick} loginFailureMessage={this.props.loginFailureMessage} />
                    </Desktop>
                    <Mobile>
                        <RegisterPageMobile isLoggingIn={this.props.isLoggingIn} loginClick={loginClick} loginFailureMessage={this.props.loginFailureMessage} />
                    </Mobile>
                </>
            );
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        isLoggingIn: state.login.loggingIn,
        loginFailureMessage: state.login.loginFailureMessage,
        isLoggedIn: state.login.loggedIn
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loginClick: (email: string, password: string, type: string) => {
            dispatch(login(email, password, type))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
