import React from 'react';
import { CustomColumnCellRendererProps } from '../../../Shared/Components/Presentational/Table';
import './RemovePassColumnCellRenderer.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ClickableIcon } from '../../../Shared/Components/Presentational/Icons';
import PassFunctionalityTypes from '../../../Constants/PassFunctionalityTypes';
import PassStatus from '../../../Constants/PassStatus';

const RemovePassColumnCellRenderer = (props: CustomColumnCellRendererProps) => {
    const { rowValue, clickAction } = props;
    const filter = props.filter as PassFilterDTO;

    const deletePass = () => {
        if (clickAction) {
            clickAction(rowValue);
        }
    };

    const canDeletePass = () => {
        if (
            filter.FunctionalityCode === PassFunctionalityTypes.Car ||
            filter.FunctionalityCode === PassFunctionalityTypes.ShortStay
        ) {
            return true;
        }

        // only season resort/owner passes should show the delete button if they're expired
        return rowValue.StatusCode === PassStatus.Expired;
    };

    return (
        <div className="remove-pass-cell" style={{ opacity: canDeletePass() ? 1 : 0 }}>
            <ClickableIcon icon={faTimes} className="delete-btn" clickAction={deletePass} />
        </div>
    );
};

export default RemovePassColumnCellRenderer;
