import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faCrop, faRedo, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React, { useState, useRef } from 'react';
import { ClickableIcon } from './Icons';
import './ImageCropper.scss';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';

interface ImageCropperProps {
  photoUrl: string;
  PassPhotoSizeLimitInKb: number;
  onSizeError: () => void;
  onPhotoCrop: (photoFile: File | undefined) => void;
}

const ImageCropper: React.FC<ImageCropperProps> = props => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [aspect, setAspect] = useState<number | undefined>(35 / 45);
  const imgRef = useRef<HTMLImageElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [srcImg, setSrcImg] = useState<string>();
  const [srcFileName, setSrcFileName] = useState<string>();
  const [isCropping, setIsCropping] = useState<boolean>(false);
  const [isInitial, setIsInitial] = useState<boolean>(true);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleEdit = (event: any) => setIsCropping(true);

  const handleUpload = (event: any) => hiddenFileInput && hiddenFileInput.current && hiddenFileInput.current.click();

  const handleRemove = (event: any) => {
    if (hiddenFileInput && hiddenFileInput.current && hiddenFileInput.current.value) {
      hiddenFileInput.current.value = '';
    }
    setSrcImg(undefined);
    props.onPhotoCrop(undefined);
  }

  const handleRotate = (event: any) => {
    var newAngleInDegrees = rotate + 90;
    if (newAngleInDegrees == 360) {
      newAngleInDegrees = 0;
    }
    setRotate(newAngleInDegrees);
  };

  const handleCrop = async (event: any) => {
    imgRef.current && previewCanvasRef.current && completedCrop && componentRef.current
      && handleCanvas(imgRef.current, previewCanvasRef.current, completedCrop, componentRef.current);

    if (srcFileName && previewCanvasRef.current) {
      var imageDataUrl = previewCanvasRef.current.toDataURL();
      resizeBlobImage(imageDataUrl);
    }
  }

  const resizeBlobImage = (imageDataUrl: any) => {
    const IMGWIDTH = 700;
    const IMGHEIGHT = 900;
    var img = document.createElement("img");
    img.onload = (event: Event) => {
      var canvas = document.createElement("canvas");
      canvas.width = IMGWIDTH;
      canvas.height = IMGHEIGHT;
      var ctx = canvas.getContext("2d");
      ctx && ctx.drawImage(img, 0, 0, IMGWIDTH, IMGHEIGHT);
      srcFileName && canvas.toBlob(function (blob: any) {
        let file = new File([blob], srcFileName, { type: blob.type });
        if (file.size > props.PassPhotoSizeLimitInKb * 1000) {
          props.onSizeError();
        } else {
          //setIsCropping(false); 
          props.onPhotoCrop(file);
          //setSrcImg(URL.createObjectURL(file));
        }
      });
    }
    img.src = imageDataUrl;
  }

  const handleImage = (event: any) => {
    if (event.target.files[0] == undefined) { return; }
    if (event.target.files[0].size > props.PassPhotoSizeLimitInKb * 1000) {
      props.onSizeError();
      handleUpload(null);
      return;
    }
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setSrcFileName(event.target.files[0].name);
    setIsCropping(true);
  }

  // Called on change event of <img> element
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      const offsetX = componentRef.current && imgRef.current && (componentRef.current.clientWidth - imgRef.current.width) / 2;
      offsetX && setCrop(centerAspectCrop(width, height, offsetX, aspect));
    }
  }

  const centerAspectCrop = (mediaWidth: number, mediaHeight: number, offsetX: number, aspect: number) => {
    let initialCrop = centerCrop(makeAspectCrop({ unit: 'px', width: mediaHeight * 0.778 }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
    initialCrop.x += offsetX;
    return initialCrop;
  }

  const handleCanvas = (imageElement: HTMLImageElement, canvasElement: HTMLCanvasElement, crop: PixelCrop, div: HTMLDivElement) => {
    const TO_RADIANS = Math.PI / 180;
    var offsetX = (div.clientWidth - imageElement.width) / 2;

    const context = canvasElement.getContext('2d');

    const scaleX = imageElement.naturalWidth / imageElement.width;
    const scaleY = imageElement.naturalHeight / imageElement.height;

    const pixelRatio = window.devicePixelRatio;

    canvasElement.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvasElement.height = Math.floor(crop.height * scaleY * pixelRatio);

    crop.x -= offsetX;

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = imageElement.naturalWidth / 2;
    const centerY = imageElement.naturalHeight / 2;

    if (context == null || context == undefined) { return; }

    context.scale(pixelRatio, pixelRatio);
    context.imageSmoothingQuality = 'high';

    context.save();
    context.translate(-cropX, -cropY);
    context.translate(centerX, centerY);
    context.rotate(rotateRads);
    context.scale(scale, scale);
    context.translate(-centerX, -centerY);
    context.drawImage(imageElement, 0, 0, imageElement.naturalWidth, imageElement.naturalHeight, 0, 0,
      imageElement.naturalWidth, imageElement.naturalHeight);
    context.restore();
  }

  if (props.photoUrl != '' && srcImg == undefined && isInitial) {
    setSrcImg(props.photoUrl);
    setSrcFileName(props.photoUrl);
    setIsInitial(false);
  }

  const hiddenElements = <>
    <input 
      id="photoUpload" 
      type="file" 
      accept="image/*" 
      ref={hiddenFileInput} 
      onChange={handleImage} 
      style={{ display: 'none' }}
    />
    <canvas ref={previewCanvasRef} style={{
      display: 'none',
      objectFit: 'contain',
      width: completedCrop && completedCrop!.width,
      height: completedCrop && completedCrop!.height
    }} />
  </>;

  var className = "add-pass-photo";
  if (srcImg == null || srcImg == undefined || srcImg == '') {
    className += " no-photo";
  }
  var minWidth = 140;
  var minHeight = 180;
  if (imgRef && imgRef.current &&
    imgRef.current.width != 0 &&
    imgRef.current.height != 0) {
    var scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    var scaleY = imgRef.current.naturalHeight / imgRef.current.height;

    minWidth = minWidth / scaleX;
    minHeight = minHeight / scaleY;
  }
  return (
    <div ref={componentRef} className={className}>
      {hiddenElements}
      <ReactCrop
        crop={crop}
        disabled={!isCropping}
        className="crop-container"
        minWidth={minWidth}
        minHeight={minHeight}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        onComplete={(c) => setCompletedCrop(c)}
        aspect={aspect}>
        <img
          id="photoUploadImage"
          ref={imgRef}
          src={srcImg}
          onLoad={onImageLoad}
          style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
        />
      </ReactCrop>
      <ClickableIcon
        id='photoUploadButton'
        className={classNames('upload-button', Boolean(srcImg) ? 'hide-button' : '')}
        icon={faUpload}
        clickAction={handleUpload}
      />
      <div className="action-area">
        <ClickableIcon
          className={classNames('action-button', 'remove-button')}
          icon={faTrash}
          clickAction={handleRemove}
        />
        <ClickableIcon
          className={classNames('action-button', isCropping ? '' : 'hide-button')}
          icon={faRedo}
          clickAction={handleRotate}
        />
        <ClickableIcon
          className={classNames('action-button', isCropping ? '' : 'hide-button')}
          icon={faCrop}
          clickAction={handleCrop}
        />
        <ClickableIcon
          className={classNames('action-button', isCropping ? 'hide-button' : '')}
          icon={faEdit}
          clickAction={handleEdit}
        />
        {
          /*
          <ClickableIcon
 
              className={classNames('action-button', isCropping ? '' : 'hide-button')}
              icon={faCrop}
              clickAction={handleCrop}
          />
          <ClickableIcon
              className={classNames('action-button', isCropping ? 'hide-button' : '')}
              icon={faEdit}
              clickAction={handleEdit}
          />
          */
        }
      </div>
    </div>
  );
};

export default ImageCropper;


