import React, { ChangeEvent, useRef, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { PrimaryButton } from "./Buttons";

interface FileUploadProps extends WithTranslation {
    handleFile: (file: any, membershipId: string) => void;
    membershipId?: string;
    id?: string;
    textOverride?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
    const { t } = props;

    const [isUploaded, setIsUploaded] = useState(false);

    const inputRef = useRef<any>(null);

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            props.membershipId && props.handleFile(event.target.files![0], props.membershipId);
        }
        setIsUploaded(true);
    };

    const fileUpload = () => {
        inputRef.current.click();
    };

    return (
        <div>
            <PrimaryButton text="Choose File" onClick={fileUpload} />
            <input
                id={props.id && props.id}
                hidden
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={onFileInputChange}
            />
            {isUploaded && !props.textOverride && (
                <p>File Uploaded</p>
            )}

        </div>
    );
}

export default withTranslation()(FileUpload);