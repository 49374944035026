import ContentfulClient from  "../ContentfulClient";

export async function GetMembershipTypesCall() {
    let filter ={
        content_type: 'membershipType',
        order: 'fields.displayOrder'
    }
    const result =  await ContentfulClient.getEntries<ContentfulMembershipType>(filter);

    if (result.errors){
        throw {message: "GetMembershipTypes returned error/s", errors: result.errors} 
    }
    return result.items;
}