import { PostApiCall } from "./ApiCall";

export class PostAgeVerificationFileCall extends PostApiCall {
    constructor(file: any, membershipId: string, name: string, dateOfBirth: Date) {
        let url: string = 'Membership/UploadAgeVerificationPhoto';
        super(url);
        
        let formData = new FormData();
        formData.set('membershipId', membershipId);
        formData.append('name', name);
        formData.append('dateOfBirth', dateOfBirth as unknown as string);
        formData.append('file', file, file.name);

        this.body = formData;
    }
}