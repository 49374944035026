import { PostApiCall } from './ApiCall';

export class PostDownloadStatementPdfCall extends PostApiCall {
    constructor(filter: TransactionFilterDTO) {
        let url: string = 'account/' + filter.AccountId + '/statementpdf/download';

        super(url);
        this.headers['Accept'] = 'application/pdf';
        this.body = filter;
    }
}
