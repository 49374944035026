import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Get } from "../../../Api/ApiService";
import { PrimaryButton } from "../../../Shared/Components/Presentational/Buttons";
import { Modal, ModalFooter, ModalHeader, ModalProps } from "../../../Shared/Components/Presentational/Modal";
import "./MyContactDetailsModal.scss";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { isValidEmail, hasValue } from "../../../Shared/Utilities/Validation";
import PasswordRequirements from "../../../Shared/Components/Presentational/PasswordRequirements";

interface MyContactDetailsModalProps extends ModalProps {
  onBackButtonClick: () => void;
  myContactDetails: MyContactDetailsDTO;
  onUpdateContactDetailsClick: (
    newContactDetails: UpdateContactDetailsDTO
  ) => void;
  onUpdatePasswordClick: (passwordDetails: SetPasswordDto) => void;
  isEmail: boolean;
  updatingPasswordError: string;
  isOwner: boolean;
  marketingPreferences: MarketingPreferencesDTO | undefined;
  updateMarketingPreferences: (preferences: any, showWarning: boolean) => void;
}

const MyContactDetailsModal: React.FC<MyContactDetailsModalProps> = (props) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [buttonText, setButtonText] = useState(t("myDetailsModal.update"));
  const [isContactDetailsFormValid, setIsContactDetailsFormValid] =
    useState(true);
  const [isPasswordFormValid, setIsPasswordFormValid] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [currentTab, setCurrentTab] = useState((!props.isOwner && props.isEmail) ? "password" : "contactDetails");

  const [pendingEmailAddress, setPendingEmailAddress] = useState(
    props.myContactDetails.PendingEmailAddress
  );

  const [newContacDetails, setnewContacDetails] = useState({
    dayPhone: props.myContactDetails.DaytimeTelephone,
    evePhone: props.myContactDetails.EveningTelephone,
    mobilePhone: props.myContactDetails.MobileTelephone,
    emailAddr: props.myContactDetails.CommunicationEmailAddress,
  });

  const [marketingPreferences, setMarketingPreferences] = useState(
    props.marketingPreferences
      ? {
          ContactMethodType: props.marketingPreferences.ContactMethodType,
          CanUseAddress: props.marketingPreferences.CanUseAddress,
          CanUseEmail: props.marketingPreferences.CanUseEmail,
          CanUseMobilePhone: props.marketingPreferences.CanUseMobilePhone,
          CanUseSMS: props.marketingPreferences.CanUseSMS,
          CanUSePhoneNumberDayTime:
            props.marketingPreferences.CanUSePhoneNumberDayTime,
          CanUsePhoneNumberEvening:
            props.marketingPreferences.CanUsePhoneNumberEvening,
        }
      : undefined
  );

  const [formValidDetails, setnewFormValidDetails] = useState({
    dayPhone: true,
    evePhone: true,
    mobilePhone: true,
    emailAddr: true,
  });

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [marketingPreferencesChanged, setMarketingPreferencesChanged] = useState(false);
  const [marketingPreferencesDeselected, setIsAnyMarketingPreferencesDeselected] = useState(false);

  useEffect(() => {
    setIsContactDetailsFormValid(
      formValidDetails.dayPhone &&
        formValidDetails.emailAddr &&
        formValidDetails.evePhone &&
        formValidDetails.mobilePhone
    );
  }, [formValidDetails]);

  useEffect(() => {
    setIsPasswordFormValid(
      isNewPasswordValid &&
        newPassword === confirmPassword &&
        currentPassword.length > 0
    );
  }, [isNewPasswordValid, newPassword, currentPassword, confirmPassword]);

  useEffect(() => { 
    if (marketingPreferences) {
      setMarketingPreferencesChanged(
        anyMarketingPreferencesChanged(props.marketingPreferences, marketingPreferences)
      );
      setIsAnyMarketingPreferencesDeselected(
        anyMarketingPreferencesDeselected(props.marketingPreferences, marketingPreferences)
      );
    }
  }, [marketingPreferences]);

  useEffect(() => {
    setPasswordsMatch(newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);

  // newPreferences/local, do not have the willingToRecieveMarketing property
  function anyMarketingPreferencesChanged(originalPreferences: any, newPreferences: any) {
    for (const [key, value] of Object.entries(newPreferences)) {
      if (originalPreferences[key] != value) {
        return true;
      }
    }
    return false;
  };

  // newPreferences/local, do not have the willingToRecieveMarketing property
  function anyMarketingPreferencesDeselected(originalPreferences: any, newPreferences: any) {
    for (const key of Object.keys(newPreferences)) {
      if (originalPreferences[key] && !newPreferences[key]) {
        return true;
      }
    }
    return false;
  };

  const handlePrimaryClick = async () => {
    switch (currentTab) {
      case "contactDetails":
        if (isEdit) {
          setIsEdit(false);
          setButtonText(t("myDetailsModal.update"));

          props.onUpdateContactDetailsClick({
            DaytimeTelephone: newContacDetails.dayPhone,
            EveningTelephone: newContacDetails.evePhone,
            MobileTelephone: newContacDetails.mobilePhone,
            EmailAddress: newContacDetails.emailAddr,
          });

          if (
            newContacDetails.emailAddr !==
            props.myContactDetails.CommunicationEmailAddress
          ) {
            setPendingEmailAddress(newContacDetails.emailAddr);
          }

          setnewContacDetails((prev: any) => {
            return {
              ...prev,
              emailAddr: props.myContactDetails.CommunicationEmailAddress,
            };
          });
        } else {
          setIsEdit(true);
          setButtonText(t("myDetailsModal.save"));
        }
        break;
      case "password":
        setButtonText(t("myDetailsModal.update"));
        props.onUpdatePasswordClick({
          NewPassword: newPassword,
          CurrentPassword: currentPassword,
        });
        break;
      case "address":
        try {
          window.open(process.env.REACT_APP_USER_ADDRESS_UPDATE_URL);
        } catch {}
        break;
      case "marketing":
        if (props.isOwner) {
          props.updateMarketingPreferences(marketingPreferences, marketingPreferencesDeselected);
        }
        break;
    }
  };

  const onTabSwitch = (index: number) => {
    if (index === 0 && props.isOwner) {
      setIsEdit(false);
      setCurrentTab("contactDetails");
      setButtonText(t("myDetailsModal.update"));
    } else if (index === 1 && props.isOwner) {
      setIsEdit(false);
      setCurrentTab("address");
      setButtonText(t("myDetailsModal.changeAddress"));
    } else if (index === 2 && props.isEmail) {
      setIsEdit(false);
      setCurrentTab("password");
      setButtonText(t("myDetailsModal.update"));
    } else if (index === 3 || (index === 2 && !props.isOwner)) {
      setIsEdit(false);
      setCurrentTab("marketing");
      setButtonText(t("myDetailsModal.update"));
    }
  };

  const getInput = (name: string, value: any, type: string) => {
    if (isEdit) {
      const classNames = "form-input owner-contact-details-input";
      return (
        <input
          name={name}
          type={type}
          className={classNames}
          required
          value={value}
          onChange={handleInputChange}
        />
      );
    } else {
      return <span>{value}</span>;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const inputName = event.target.name;

    setnewContacDetails((prev: any) => {
      return { ...prev, [inputName]: event.target.value };
    });

    let isValid =
      event.target.type === "email"
        ? hasValue(event.target.value) && isValidEmail(event.target.value)
        : hasValue(event.target.value);

    if (!isValid) {
      event.target.classList.add("invalid");
    } else {
      event.target.classList.remove("invalid");
    }

    setnewFormValidDetails((prev: any) => {
      return { ...prev, [inputName]: isValid };
    });
  };

  const handleMarketingPreferenceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const inputName = event.target.name;

    setMarketingPreferences((prev: any) => {
      return { ...prev, [inputName]: event.target.checked };
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "currPass") {
      setCurrentPassword(value);
    }
    if (name === "newPass") {
      setNewPassword(value);
    }
    if (name === "confirmPass") {
      setConfirmPassword(value);
    }
  };

  const handleBackButton = () => {
    if (isEdit) {
      setnewContacDetails({
        dayPhone: props.myContactDetails.DaytimeTelephone,
        evePhone: props.myContactDetails.EveningTelephone,
        mobilePhone: props.myContactDetails.MobileTelephone,
        emailAddr: props.myContactDetails.CommunicationEmailAddress,
      });
      setMarketingPreferences(
        props.marketingPreferences
          ? {
              ContactMethodType: props.marketingPreferences.ContactMethodType,
              CanUseAddress: props.marketingPreferences.CanUseAddress,
              CanUseEmail: props.marketingPreferences.CanUseEmail,
              CanUseMobilePhone: props.marketingPreferences.CanUseMobilePhone,
              CanUseSMS: props.marketingPreferences.CanUseSMS,
              CanUSePhoneNumberDayTime:
                props.marketingPreferences.CanUSePhoneNumberDayTime,
              CanUsePhoneNumberEvening:
                props.marketingPreferences.CanUsePhoneNumberEvening,
            }
          : undefined
      );
      setIsEdit(false);
      setButtonText(t("myDetailsModal.update"));
    } else {
      props.onBackButtonClick();
    }
  };

  const formContents = (
    <>
      <ModalHeader text={t("myDetailsModal.title")} />
      <Tabs
        onSelect={onTabSwitch}
        defaultIndex={currentTab === "address" ? 1 : 0}
      >
        <TabList>
          {props.isOwner && (
            <>
              <Tab>
                <Desktop>{t("myDetailsModal.contactDetailsHeader")}</Desktop>
                <Mobile>{t("myDetailsModal.contactDetailsHeaderMobile")}</Mobile>
              </Tab>
              <Tab>{t("general.address")}</Tab>
            </>
          )}
          {props.isEmail && (
            <Tab>{t("myDetailsModal.passwordHeader")}</Tab>
          )}
          {props.isOwner && (
            <Tab>
              <Desktop>
                {t("myDetailsModal.marketingPreferencesHeader")}
              </Desktop>
              <Mobile>
                {t("myDetailsModal.marketingPrefernecesHeaderMobile")}
              </Mobile>
            </Tab>
          )}
        </TabList>
        {props.isOwner && (
          <>
            <TabPanel>
              <div className="contact-details-modal-field email-field">
                <label htmlFor="emailAddr">{t("general.emailAddress")}</label>
                {getInput("emailAddr", newContacDetails.emailAddr, "email")}
              </div>
              <div className="contact-details-modal-field">
                <label htmlFor="dayPhone">{t("myDetailsModal.dayPhone")}</label>
                {getInput("dayPhone", newContacDetails.dayPhone, "text")}
              </div>
              <div className="contact-details-modal-field">
                <label htmlFor="evePhone">{t("myDetailsModal.eveningPhone")}</label>
                {getInput("evePhone", newContacDetails.evePhone, "text")}
              </div>
              <div className="contact-details-modal-field mobile-field">
                <label htmlFor="mobilePhone">
                  {t("myDetailsModal.mobilePhone")}
                </label>
                {getInput("mobilePhone", newContacDetails.mobilePhone, "text")}
              </div>
              {pendingEmailAddress && (
                <p
                  className={classNames("info-message", {
                    show: pendingEmailAddress,
                  })}
                >
                  {t("myDetailsModal.pendingEmailNotification") +
                  " " +
                  pendingEmailAddress.toLowerCase()}
                </p>
              )}
            </TabPanel>
        
            <TabPanel>
              <p>{t("myDetailsModal.requestAddressChangeDescription")}</p>
              <div className="contact-details-modal-field address1-field">
                <label htmlFor="addrOne">{t("myDetailsModal.address1")}</label>
                <span>{props.myContactDetails.AddressLine1}</span>
              </div>
              <div className="contact-details-modal-field address2-field">
                <label htmlFor="addrTwo">{t("myDetailsModal.address2")}</label>
                <span>{props.myContactDetails.AddressLine2}</span>
              </div>
              <div className="contact-details-modal-field city-field">
                <label htmlFor="addrThree">{t("myDetailsModal.address3")}</label>
                <span>{props.myContactDetails.AddressLine3}</span>
              </div>
              <div className="contact-details-modal-field">
                <label htmlFor="addrFour">{t("myDetailsModal.address4")}</label>
                <span>{props.myContactDetails.AddressLine4}</span>
              </div>
              <div className="contact-details-modal-field">
                <label htmlFor="postCode">{t("myDetailsModal.postcode")}</label>
                <span>{props.myContactDetails.PostCode}</span>
              </div>
            </TabPanel>
          </>
        )}
        {props.isEmail && (
          <TabPanel>
            <p className="update-password-title">
              {t("myDetailsModal.updatePasswordTitle")}
            </p>
            <div className="contact-details-modal-field password-field">
              <label htmlFor="currPass">
                {t("myDetailsModal.currentPassword")}
              </label>
              <input
                name="currPass"
                type="password"
                className="form-input owner-contact-details-password-input"
                required
                value={currentPassword}
                onChange={handlePasswordChange}
                autoComplete="new-password"
              />
            </div>

            {props.updatingPasswordError && (
              <p className="error-message show">
                {t("myDetailsModal.passwordUpdateError")}
              </p>
            )}
            <PasswordRequirements
              password={newPassword}
              onPasswordChange={(isValid) => setIsNewPasswordValid(isValid)}
            />
            <div className="contact-details-modal-field password-field">
              <label htmlFor="newPass">{t("myDetailsModal.newPassword")}</label>
              <input
                name="newPass"
                type="password"
                className="form-input owner-contact-details-password-input"
                required
                value={newPassword}
                onChange={handlePasswordChange}
                autoComplete="new-password"
              />
            </div>

            <div className="contact-details-modal-field password-field">
              <label htmlFor="confirmPass">
                {t("myDetailsModal.confirmPassword")}
              </label>
              <input
                name="confirmPass"
                type="password"
                className="form-input owner-contact-details-password-input"
                required
                value={confirmPassword}
                onChange={handlePasswordChange}
                autoComplete="new-password"
              />
            </div>
            {!passwordsMatch && (
              <p className="info-message show">
                {t("myDetailsModal.passwordsDoNotMatch")}
              </p>
            )}
          </TabPanel>
        )}
        {props.isOwner && (
          <TabPanel className="marketing-modal-field-container">
            <p>
              Adjust your preferences below by selecting the boxes for those you
              want to recieve.
            </p>
            <div className="marketing-preferences-container">
              <div className="contact-details-modal-field">
                <label htmlFor="CanUseAddress">
                  {t("myDetailsModal.marketingPreferences.letter")}
                </label>
                <input
                  disabled={props.myContactDetails!.AddressLine1 === null}
                  type="checkbox"
                  name="CanUseAddress"
                  required
                  checked={marketingPreferences!.CanUseAddress}
                  onChange={handleMarketingPreferenceInputChange}
                />
              </div>
              <div className="contact-details-modal-field">
                <label htmlFor="CanUSePhoneNumberDayTime">
                  {t("myDetailsModal.marketingPreferences.telephoneWork")}
                </label>
                <input
                  disabled={props.myContactDetails!.DaytimeTelephone === null}
                  name="CanUSePhoneNumberDayTime"
                  type="checkbox"
                  required
                  checked={marketingPreferences!.CanUSePhoneNumberDayTime}
                  onChange={handleMarketingPreferenceInputChange}
                />
              </div>
              <div className="contact-details-modal-field">
                <label htmlFor="CanUseSMS">
                  {t("myDetailsModal.marketingPreferences.sms")}
                </label>
                <input
                  disabled={props.myContactDetails!.MobileTelephone === null}
                  name="CanUseSMS"
                  type="checkbox"
                  required
                  checked={marketingPreferences!.CanUseSMS}
                  onChange={handleMarketingPreferenceInputChange}
                />
              </div>
              <div className="contact-details-modal-field">
                <label htmlFor="CanUseMobilePhone">
                  {t("myDetailsModal.marketingPreferences.telephoneMobile")}
                </label>
                <input
                  disabled={props.myContactDetails!.MobileTelephone === null}
                  name="CanUseMobilePhone"
                  type="checkbox"
                  required
                  checked={marketingPreferences!.CanUseMobilePhone}
                  onChange={handleMarketingPreferenceInputChange}
                />
              </div>
              <div className="contact-details-modal-field">
                <label htmlFor="CanUseEmail">
                  {t("myDetailsModal.marketingPreferences.email")}
                </label>
                <input
                  disabled={props.myContactDetails!.CommunicationEmailAddress === null}
                  name="CanUseEmail"
                  type="checkbox"
                  required
                  checked={marketingPreferences!.CanUseEmail}
                  onChange={handleMarketingPreferenceInputChange}
                />
              </div>
              <div className="contact-details-modal-field">
                <label htmlFor="CanUsePhoneNumberEvening">
                  {t("myDetailsModal.marketingPreferences.telephoneHome")}
                </label>
                <input
                  disabled={props.myContactDetails!.EveningTelephone === null}
                  name="CanUsePhoneNumberEvening"
                  type="checkbox"
                  required
                  checked={marketingPreferences!.CanUsePhoneNumberEvening}
                  onChange={handleMarketingPreferenceInputChange}
                />
              </div>
            </div>
          </TabPanel>
        )}
      </Tabs>
      <ModalFooter onBackButtonClick={handleBackButton}>
        {(props.isOwner || currentTab === "password") && (
          <PrimaryButton
            text={buttonText}
            className="footer-button"
            disabled={
              (currentTab === "contactDetails" && isEdit && !isContactDetailsFormValid) ||
              (currentTab === "password" && !isPasswordFormValid) ||
              (currentTab === "marketing" && !marketingPreferencesChanged)
            }
            onClick={handlePrimaryClick}
          />
        )}
      </ModalFooter>
    </>
  );

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        className={classNames("owner-contact-details-modal", props.className)}
      >
        <Desktop>
          <form>{formContents}</form>
        </Desktop>
        <Mobile>
          <form className="owner-contact-details-modal-mobile-form">
            {formContents}
          </form>
        </Mobile>
      </Modal>
    </>
  );
};

export default MyContactDetailsModal;
