import { PostApiCall } from "./ApiCall";

export class PostAnalyzeFaceCall extends PostApiCall {
    constructor(file: File) {
        let url: string = "face-recognition/analyze";
        
        var data = new FormData();
        data.append('file', file);

        super(url);

        this.body = data;
    }
}