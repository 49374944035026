import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Desktop, Mobile } from '../../../../Shared/Components/Presentational/ResponsiveViews';

interface FinalisePageProps extends WithTranslation {
    membershipForm: any;
}

const FinalisePage: React.FC<FinalisePageProps> = (props) => {
    const { t } = props;

    return (
        <>
            <div className='row'>
                <div className='title-header'>
                    <Desktop>
                        <h2 style={{ margin: "0px" }}>{t("addMembership.finalisePage.title")}</h2>
                    </Desktop>
                    <Mobile>
                        <h2 style={{ margin: "0px", marginTop: "50px" }}>{t("addMembership.finalisePage.title")}</h2>
                    </Mobile>
                </div>
            </div>

            <div className='row' style={{ marginTop: "20px" }}>
                <p>{t("addMembership.finalisePage.message")}</p>
            </div>

        </>
    )
}

export default withTranslation()(FinalisePage);