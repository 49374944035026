import React from 'react';
import { TransactionIcon } from '../../../Shared/Components/Presentational/Icons';
import { CustomColumnCellRendererProps } from '../../../Shared/Components/Presentational/Table';
import './TransactionTypeCellRenderer.scss';
import { useTranslation } from 'react-i18next';

interface TransactionTypeProps {
    icon: TransactionIconDTO;
    description: string;
}

export const TransactionType: React.FC<TransactionTypeProps> = ({ icon, description }) => {
    const { t } = useTranslation();
    return (
        <div className="type-container">
            <TransactionIcon icon={icon} />
            <div className="description">{t(description)}</div>
        </div>
    );
};

const TransactionTypeCellRenderer: React.FC<CustomColumnCellRendererProps> = ({ rowValue }) => {
    return <TransactionType icon={rowValue.Icon} description={rowValue.CategoryKey} />;
};

export default TransactionTypeCellRenderer;
