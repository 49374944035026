import {
    SET_LODGE_DETAILS,
    SET_LODGE_DETAILS_LIST,
    GET_FAQS_REQUEST,
    GET_FAQS_SUCCESS,
    GET_FAQS_FAILURE,
    GET_IMPORTANT_INFORMATION_REQUEST,
    GET_IMPORTANT_INFORMATION_SUCCESS,
    GET_IMPORTANT_INFORMATION_FAILURE,
    GET_PARK_INFORMATION_REQUEST,
    GET_PARK_INFORMATION_SUCCESS,
    GET_PARK_INFORMATION_FAILURE
} from './ActionTypes';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../RootReducer';
import { Action } from 'redux';
import { Get } from '../../Api/ApiService';
import { GetFaqsCall } from '../../Api/Calls/GetFaqsCall';
import { GetInformationCall } from '../../Api/Calls/GetInformationCall';
import { GetImportantInformationCall } from '../../Contentful/Calls/GetImportantInformationCall';
import { GetParkFAQsCall } from '../../Contentful/Calls/GetParkFAQsCall';
import { GetParkInformationCall } from '../../Contentful/Calls/GetParkInformationCall';
import { stringOrDate } from 'react-big-calendar';

export const setLodgeDetails = (lodgeId: string): ThunkAction<void, AppState, null, Action> => async (
    dispatch,
    getState
) => {
    const lodgeDetailsList: LodgeDetailDTO[] = getState().information.lodgeDetailsList;
    const lodgeDetails = lodgeDetailsList.find(ld => ld.LodgeId.toString() === lodgeId);

    if (lodgeDetails) {
        dispatch({ type: SET_LODGE_DETAILS, payload: lodgeDetails });
    }
};

export const setLodgeDetailsList = (
    lodgeDetailsList: LodgeDetailDTO[]
): ThunkAction<void, AppState, null, Action> => async dispatch => {
    dispatch({ type: SET_LODGE_DETAILS_LIST, payload: lodgeDetailsList });
};

export const getFaqs = (userRoles: UserRoleDTO[]): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
    const park = getState().user.park;

    dispatch({ type: GET_FAQS_REQUEST });
    park && GetParkFAQsCall(park.parkCode,userRoles)
        .then(response => {
            dispatch({ type: GET_FAQS_SUCCESS, payload: response });
        })
        .catch(error => {
            dispatch({ type: GET_FAQS_FAILURE, payload: error });
        });
};

export const getImportantInformation = (userRoles: UserRoleDTO[]): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
    const park = getState().user.park;
    
    dispatch({ type: GET_IMPORTANT_INFORMATION_REQUEST });
    park && GetImportantInformationCall(park.parkCode,userRoles)
        .then(response => {
            dispatch({ type: GET_IMPORTANT_INFORMATION_SUCCESS, payload: response });
        })
        .catch(error => {
            dispatch({ type: GET_IMPORTANT_INFORMATION_FAILURE, payload: error });
        });
};

export const getParkInformation = (): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
    const park = getState().user.park;

    dispatch({ type: GET_PARK_INFORMATION_REQUEST });
    park && GetParkInformationCall(park.parkCode)
        .then(response => {
            dispatch({ type: GET_PARK_INFORMATION_SUCCESS, payload: response });
        })
        .catch(error => {
            dispatch({ type: GET_PARK_INFORMATION_FAILURE, payload: error });
        });
}
