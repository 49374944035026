import { PostApiCall } from './ApiCall';

export class PostGetSeasonPassesCall extends PostApiCall {
    constructor(lodgeId: string, passIds: string[]) {
        let url: string = 'passes/' + lodgeId + '/getSeasonPasses';

        super(url);

        this.body = passIds;
    }
}
