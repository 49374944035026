import React, { useEffect, useImperativeHandle, useState } from 'react';
import { ICallSave } from './ICallSave';
import '../../Container/AddMembershipStyles.scss';
import { Get, Post } from '../../../../Api/ApiService';
import { GetMemberVehicles } from '../../../../Api/Calls/Member/GetMemberVehiclesCals';
import VehiclesForm from './VehiclesForm';
import { UpdateMemberVehiclesCall } from '../../../../Api/Calls/Member/UpdateMemberVehiclesCall';
import { Desktop, Mobile } from '../../../../Shared/Components/Presentational/ResponsiveViews';

interface UpdateMemberVehiclesProps {
  memberId: string;
  onSaveComplete: () => void;
  onSaveError: () => void;
  onSaveCancelled: () => void;
}

const UpdateMemberVehicles = React.forwardRef<ICallSave, UpdateMemberVehiclesProps>((props, ref) => {
  const [vehicle1, setVehicle1] = useState<VehicleDTOBack>({ Description: '', Registration: '' });
  const [vehicle2, setVehicle2] = useState<VehicleDTOBack>({ Description: '', Registration: '' });
  const [detailsChanged, setDetailsChanged] = useState<boolean>(false);

  useEffect(() => {
    Get<VehiclesDTO>(new GetMemberVehicles(props.memberId))
      .then(r => setVehicles(r.data))
      .catch(e => console.log(e));
  }, []);

  const setVehicles = (dto: VehiclesDTO) => {
    if (dto.Vehicles && dto.Vehicles.length > 0) {
      setVehicle1(dto.Vehicles[0]);
      if (dto.Vehicles.length > 1) {
        setVehicle2(dto.Vehicles[1]);
      }
    }
  }

  const updateVehicles = () => {
    const vehicleList = [] as VehicleDTOBack[];
    if (vehicle1.Registration.length > 0)
      vehicleList.push(vehicle1);

    if (vehicle2.Registration.length > 0)
      vehicleList.push(vehicle2);

    Post(new UpdateMemberVehiclesCall({ Vehicles: vehicleList }, props.memberId))
      .then(r => props.onSaveComplete())
      .catch(e => props.onSaveError())
  }

  useImperativeHandle(ref, () => ({
    save() {
      if (detailsChanged) {
        updateVehicles();
      } else {
        props.onSaveComplete();
      }
    }
  }));

  const vehicle1Update = (v: VehicleDTOBack) => {
    setVehicle1(v);
    setDetailsChanged(true);
  }

  const vehicle2Update = (v: VehicleDTOBack) => {
    setVehicle2(v);
    setDetailsChanged(true);
  }

  return (
    <>
      <div>
        <Desktop>
          <div className='row' style={{ marginBottom: "25px" }}>
            <div className='float-left'>
              {/* TODO - Add contentful text */}
              {/* <h2 id="pdTitle" style={{ margin: "0px" }}>{t("addMembership.personalDetails.title")}</h2> */}
              <h2 id="pdTitle" style={{ margin: "0px" }}>Vehicles</h2>
            </div>
          </div>
        </Desktop>

        <Mobile>
          <div className='row' style={{ marginBottom: "25px", marginTop: "50px" }}>
            <div className='title-header'>
              {/* TODO - Add contentful text */}
              {/* <h2 id="pdTitle" style={{ margin: "0px" }}>{t("addMembership.personalDetails.title")}</h2> */}
              <h2 id="pdTitle" style={{ margin: "0px" }}>Vehicles</h2>
            </div>
          </div>
        </Mobile>

        <div>
          <VehiclesForm
            allowVehicles={true}
            vehicle1={vehicle1}
            vehicle2={vehicle2}
            onVehicle1Update={vehicle1Update}
            onVehicle2Update={vehicle2Update}
            showErrors={false}
          />
        </div>
      </div>
    </>
  )
})

export default UpdateMemberVehicles;
