import React, { useState, useRef, useImperativeHandle, useEffect } from 'react';
import { faRedo, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  PercentCrop,
} from 'react-image-crop';
import './SimpleImageCropper.scss';
import 'react-image-crop/dist/ReactCrop.css';
import { ClickableIcon } from './Icons';
import classNames from 'classnames';
import imageCompression from 'browser-image-compression';

interface SimpleImageCropperProps {
  fileUrl: string;
  photoSizeLimitInKb: number;
  fileFromCrop: (photoFile: File) => void;
  onImageRemoved: () => void;
  imageLoaded: () => void;
  setNewImage: () => void;
  onSizeError: () => void;
}

export interface IFileFromCrop {
  getFile: () => void;
}

const SimpleImageCropper = React.forwardRef<IFileFromCrop, SimpleImageCropperProps>((props, cropperRef) => {
  const [crop, setCrop] = useState<Crop>();
  const [pixelCrop, setPixelCrop] = useState<PixelCrop>();
  const containerRef = useRef<HTMLDivElement>(null);
  const aspectRatio = 35 / 45;

  // Allows the button to trigger the hidden file input element
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const openFileInput = (event: any) => hiddenFileInput.current && hiddenFileInput.current.click();

  const imgRef = useRef<HTMLImageElement>(null);
  const [srcImg, setSrcImg] = useState<string>('');
  const [rotate, setRotate] = useState(0);
  const [checkedForExistingImage, setCheckedForExistingImage] = useState(false);

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  useImperativeHandle(cropperRef, () => ({
    getFile() {
      fileFromCrop();
    }
  }));

  useEffect(() => {
    if (!checkedForExistingImage && props.fileUrl !== '') {
      setSrcImg(props.fileUrl);
      setCheckedForExistingImage(true);
    }
  });

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > props.photoSizeLimitInKb * 1000) {
        props.onSizeError();
        return;
      }

      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const dataUrl: string = reader.result ? reader.result.toString() : '';
        setSrcImg(dataUrl);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleRemove = () => {
    if (hiddenFileInput && hiddenFileInput.current && hiddenFileInput.current.value) {
      hiddenFileInput.current.value = '';
    }
    setSrcImg('');
    props.onImageRemoved();
  }

  const handleRotate = () => {
    var newAngleInDegrees = rotate + 90;
    if (newAngleInDegrees == 360) {
      newAngleInDegrees = 0;
    }
    setRotate(newAngleInDegrees);
  }

  const onImageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;

    const partialCrop: Partial<Crop> = { unit: 'px' }
    width > height ? partialCrop.height = height : partialCrop.width = width;

    const offsetX =
      containerRef.current &&
      imgRef.current &&
      (containerRef.current.clientWidth - imgRef.current.width) / 2;

    setCrop(centerAspectCrop(width, height, aspectRatio, partialCrop, offsetX || 0));

    props.imageLoaded();
  }

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
    partialCrop: Partial<Crop>,
    offsetX: number
  ) {
    const initial = centerCrop(
      makeAspectCrop(
        partialCrop,
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )

    initial.x += offsetX;
    return initial;
  }

  const fileFromCrop = () => {
    const image: HTMLImageElement = imgRef.current as HTMLImageElement;
    const crop: PixelCrop = pixelCrop as PixelCrop;
    const canvas: HTMLCanvasElement = previewCanvasRef.current as HTMLCanvasElement;

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const scale = image.naturalWidth / image.width;
    canvas.width = Math.floor(crop.width * scale);
    canvas.height = Math.floor(crop.height * scale);

    ctx.imageSmoothingQuality = 'high';

    const clientWidth = containerRef.current && containerRef.current.clientWidth || 0;
    const offsetX = (clientWidth - image.width) / 2;
    crop.x -= offsetX;

    ctx.drawImage(image, (crop.x * scale), (crop.y * scale), (crop.width * scale), (crop.height * scale), 0, 0, canvas.width, canvas.height);
    ctx.restore();

    let url = canvas.toDataURL("image/jpeg", 0.8);
    let blob = dataURItoBlob(url);

    const filename: string = 'image' + new Date().getTime().toString();
    let file = new File([blob], filename, { type: blob.type });
    props.fileFromCrop(file);
  };

  const dataURItoBlob = (dataURI: string) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const hiddenFileInputElement = <>
    <input
      id="photoUpload"
      type="file"
      accept="image/*"
      ref={hiddenFileInput}
      onChange={onFileSelected}
      style={{ display: 'none' }}
    />
    {pixelCrop &&
      <canvas
        ref={previewCanvasRef}
        style={{
          border: '1px solid black',
          objectFit: 'contain',
          width: pixelCrop.width,
          height: pixelCrop.height,
          display: 'none'
        }}
      />
    }
  </>;

  var className = "add-pass-photo";
  if (srcImg == null || srcImg == undefined || srcImg == '') {
    className += " no-photo";
  }

  return (
    <div>
      <div className={className} ref={containerRef} onClick={() => {
        if (className === "add-pass-photo no-photo") {
          openFileInput(containerRef);
        }
      }}>
        {hiddenFileInputElement}
        <ReactCrop
          className='crop-container'
          crop={crop}
          onChange={(_, percentCrop) => {
            setCrop(percentCrop);
            props.setNewImage();
          }}
          onComplete={(c) => setPixelCrop(c)}
          minHeight={90}
          minWidth={70}
          aspect={aspectRatio}
        >
          <img
            id="photoUploadImage"
            ref={imgRef}
            src={srcImg}
            style={{ transform: `rotate(${rotate}deg)` }}
            onLoad={onImageLoaded}
          />
        </ReactCrop>

        <ClickableIcon
          id='photoUploadButton'
          className={classNames('upload-button', Boolean(srcImg) ? 'hide-button' : '')}
          icon={faUpload}
          // clickAction={openFileInput}
          clickAction={() => null}
        />

        <div className='action-area'>
          <ClickableIcon
            className={classNames('action-button', 'remove-button')}
            icon={faTrash}
            clickAction={handleRemove}
          />
          <ClickableIcon
            className={classNames('action-button')}
            icon={faRedo}
            clickAction={handleRotate}
          />
        </div>
      </div>
    </div>
  );
});

export default SimpleImageCropper