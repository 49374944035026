import { GetApiCall } from "./ApiCall";

export class GetEditMemberDetailsByIdCall extends GetApiCall {
    constructor(membershipId: string) {
        let url: string = "Membership/GetEditMemberDetailsByMembershipId/";

        let params: any = { membershipId }

        super(url, params);
    }
}