import React, { ChangeEvent, Component, FormEvent } from 'react';
import { Translation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Post } from './Api/ApiService';
import { PostForgotPasswordEmailCall } from './Api/Calls/PostForgotPasswordEmailCall';
import { PostResetPasswordCall } from './Api/Calls/PostResetPasswordCall';
import * as Routes from './Constants/Routes';
import './ForgottenPassword.scss';
import PasswordRequirements from './Shared/Components/Presentational/PasswordRequirements';
import { ImageBackground, VerticalBackground } from './Shared/Components/Presentational/Backgrounds';
import { BackLinkButton, PrimaryButton, SecondaryButton } from './Shared/Components/Presentational/Buttons';
import { ButtonContainer, InputContainer } from './Shared/Components/Presentational/Containers';
import { Desktop, Mobile } from './Shared/Components/Presentational/ResponsiveViews';

interface ForgottenPasswordState {
    passwordChanged: boolean;
    readyToRedirect: boolean;
    requestFailed: boolean;
    emailSent: boolean;
    newPassword: string;
    cPassword: string;
    passwordsMatchAndIsValid: boolean;
    code: string;
    isLoading: boolean;
    emailAddress: string;
}

class ForgottenPassword extends Component<{}, ForgottenPasswordState> {
    constructor(props: any) {
        super(props);

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const emailAddress = params.get('emailAddress') as string;
        const code = params.get('code') as string;

        this.state = {
            passwordChanged: false,
            readyToRedirect: false,
            requestFailed: false,
            emailSent: code ? true : false,
            code: code,
            isLoading: false,
            newPassword: '',
            cPassword: '',
            passwordsMatchAndIsValid: false,
            emailAddress: emailAddress ? emailAddress : ''
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.sendForgottenPasswordEmail = this.sendForgottenPasswordEmail.bind(this);
        this.resendForgottenPasswordEmail = this.resendForgottenPasswordEmail.bind(this);
        this.onEmailSubmit = this.onEmailSubmit.bind(this);
        this.onPasswordSubmit = this.onPasswordSubmit.bind(this);
        this.setPasswordMatch = this.setPasswordMatch.bind(this);
    }

    private handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        this.setState({ emailAddress: value });
    }

    private handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;
        if (name === 'password') {
            this.setState({ newPassword: value }, () => {
                this.setPasswordMatch();
            });
        }
        if (name === 'c_password') {
            this.setState({ cPassword: value }, () => {
                this.setPasswordMatch();
            });
        }
    }

    private setPasswordMatch() {
        if (this.state.newPassword !== this.state.cPassword && this.state.newPassword.length > 0) {
            this.setState({ passwordsMatchAndIsValid: false });
        } else {
            this.setState({ passwordsMatchAndIsValid: true });
        }
    }

    private sendForgottenPasswordEmail() {
        this.setState({ emailSent: false });
        this.setState({ requestFailed: false });

        const resetPasswordDto: SendPasswordResetEmailDto = {
            EmailAddress: this.state.emailAddress
        };

        Post<{}>(new PostForgotPasswordEmailCall(resetPasswordDto))
            .then(() => {
                this.setState({ emailSent: true });
            })
            .catch(() => {});
    }

    private resendForgottenPasswordEmail() {
        const resetPasswordDto: SendPasswordResetEmailDto = {
            EmailAddress: this.state.emailAddress
        };

        Post<{}>(new PostForgotPasswordEmailCall(resetPasswordDto))
            .then(() => {
                this.setState({ emailSent: true });
            })
            .catch(() => {});
    }

    private onEmailSubmit = (e: FormEvent) => {
        e.preventDefault();
        this.setState({ emailSent: false });
        this.setState({ requestFailed: false });

        const resetPasswordDto: SendPasswordResetEmailDto = {
            EmailAddress: this.state.emailAddress
        };

        Post<{}>(new PostForgotPasswordEmailCall(resetPasswordDto))
            .then(() => {
                this.setState({ emailSent: true });
            })
            .catch(() => {});
    };

    private onPasswordSubmit = (e: FormEvent) => {
        e.preventDefault();
        this.setState({ requestFailed: false });

        const resetPasswordDto: SendPasswordResetEmailDto = {
            EmailAddress: this.state.emailAddress,
            NewPassword: this.state.newPassword,
            ConfirmedNewPassword: this.state.cPassword,
            ResetPasswordToken: this.state.code
        };
        Post<{}>(new PostResetPasswordCall(resetPasswordDto))
            .then(() => {
                this.setState({ passwordChanged: true, emailSent: false });
                setTimeout(() => {
                    this.setState({ readyToRedirect: true });
                }, 3000);
            })
            .catch(() => {
                this.setState({ requestFailed: true });
                this.setState({ passwordChanged: false, readyToRedirect: false });
            });
    };

    render() {
        return (
            <Translation>
                {(t: (key: string) => string) => {
                    return (
                        <>
                            <Desktop>
                                <div className="forgot-password-page-container">
                                    <VerticalBackground
                                        src="/images/desktop-login-image.svg"
                                        containerClassName="forgot-password-page-vertical-background"
                                    >
                                        <h1>{t('login.welcomeTitle')}</h1>
                                        <p className="login-welcome-message">{t('login.welcomeMessage')}</p>
                                    </VerticalBackground>
                                    <div className="forgot-password-container">
                                        {!this.state.emailSent && !(this.state.code && this.state.emailAddress) && (
                                            <div className="">
                                                <BackLinkButton
                                                    href="/login"
                                                    text={t('login.back')}
                                                    className="login-back"
                                                ></BackLinkButton>
                                                <form name="form" onSubmit={this.onEmailSubmit}>
                                                    <InputContainer className="forgot-password-container">
                                                        <h1>{t('login.forgottenPassword.forgotYourPassword')}</h1>
                                                        <h3>
                                                            {t('login.forgottenPassword.forgotYourPasswordDescription')}
                                                        </h3>
                                                        <input
                                                            name="emailAddress"
                                                            className="form-input"
                                                            type="email"
                                                            placeholder={t('login.emailAddressPlaceholder')}
                                                            value={this.state.emailAddress}
                                                            onChange={this.handleEmailChange}
                                                        />
                                                    </InputContainer>
                                                    <ButtonContainer>
                                                        <PrimaryButton
                                                            type="submit"
                                                            disabled={!this.state.emailAddress}
                                                            text={t('login.forgottenPassword.sendLink')}
                                                            isLoading={this.state.isLoading}
                                                        ></PrimaryButton>
                                                    </ButtonContainer>
                                                </form>
                                            </div>
                                        )}
                                        {this.state.emailSent && !this.state.code && (
                                            <div className="">
                                                <BackLinkButton
                                                    href="/login"
                                                    text={t('login.back')}
                                                    className="login-back"
                                                ></BackLinkButton>
                                                <form name="form" onSubmit={this.onEmailSubmit}>
                                                    <InputContainer className="forgot-password-container">
                                                        <h1>
                                                            {t('login.forgottenPassword.passwordResetIfEmailExist')}
                                                        </h1>
                                                        <p className="resendEmail">
                                                            {t('login.forgottenPassword.didNotGetEmail')}
                                                            <a onClick={this.resendForgottenPasswordEmail}>
                                                                {' '}
                                                                {t('login.forgottenPassword.resendLink')}
                                                            </a>
                                                        </p>
                                                    </InputContainer>
                                                </form>
                                            </div>
                                        )}
                                        {this.state.code && this.state.emailAddress && !this.state.passwordChanged && (
                                            <div className="">
                                                <BackLinkButton
                                                    href="/login"
                                                    text={t('login.back')}
                                                    className="login-back"
                                                ></BackLinkButton>
                                                <form name="form" onSubmit={this.onPasswordSubmit}>
                                                    <InputContainer className="forgot-password-container">
                                                        <h1>{t('login.forgottenPassword.resetPassword')}</h1>
                                                        <h3>{t('login.forgottenPassword.pleaseEnterNewPassword')}</h3>
                                                        <PasswordRequirements
                                                            password={this.state.newPassword}
                                                            onPasswordChange={isValid => isValid}
                                                        />
                                                        <input
                                                            name="password"
                                                            className="form-input"
                                                            type="password"
                                                            placeholder={t('login.passwordPlaceholder')}
                                                            onChange={this.handlePasswordChange}
                                                        />
                                                        <input
                                                            name="c_password"
                                                            className="form-input"
                                                            type="password"
                                                            placeholder={t('login.confirmPassword')}
                                                            value={this.state.cPassword}
                                                            onChange={this.handlePasswordChange}
                                                        />
                                                        <h3 className="error">
                                                            {this.state.requestFailed
                                                                ? t('login.forgottenPassword.error')
                                                                : ''}
                                                        </h3>
                                                    </InputContainer>
                                                    <ButtonContainer>
                                                        <PrimaryButton
                                                            type="submit"
                                                            disabled={!this.state.passwordsMatchAndIsValid}
                                                            text={t(
                                                                'login.forgottenPassword.resetPassword'
                                                            ).toUpperCase()}
                                                            isLoading={this.state.isLoading}
                                                        ></PrimaryButton>
                                                    </ButtonContainer>
                                                </form>
                                            </div>
                                        )}
                                        {this.state.code && this.state.emailAddress && this.state.passwordChanged && (
                                            <div className="">
                                                <BackLinkButton
                                                    href="/login"
                                                    text={t('login.back')}
                                                    className="login-back"
                                                ></BackLinkButton>
                                                <form name="form" onSubmit={this.onEmailSubmit}>
                                                    <InputContainer className="forgot-password-container">
                                                        <h1>{t('login.forgottenPassword.forgotYourPassword')}</h1>
                                                        <h3 className="waiting">{t('login.redirectToLogin')}</h3>
                                                    </InputContainer>
                                                </form>
                                            </div>
                                        )}
                                        {this.state.readyToRedirect && <Redirect to={Routes.Login} />}
                                    </div>
                                </div>
                            </Desktop>
                            <Mobile>
                                <ImageBackground
                                    src="/images/mobile-login-image.svg"
                                    containerClassName="login-background"
                                >
                                    <div className="forgot-password-container">
                                        {!this.state.emailSent && !(this.state.code && this.state.emailAddress) && (
                                            <div className="forgot-pw-mobile">
                                                <form name="form" onSubmit={this.onEmailSubmit}>
                                                    <InputContainer className="forgot-password-container">
                                                        <h1>{t('login.forgottenPassword.forgotYourPassword')}</h1>
                                                        <h3>
                                                            {t('login.forgottenPassword.forgotYourPasswordDescription')}
                                                        </h3>
                                                        <input
                                                            name="emailAddress"
                                                            className="form-input-alternative"
                                                            type="email"
                                                            placeholder={t('login.emailAddressPlaceholder')}
                                                            value={this.state.emailAddress}
                                                            onChange={this.handleEmailChange}
                                                        />
                                                    </InputContainer>
                                                    <ButtonContainer>
                                                        <SecondaryButton
                                                            type="submit"
                                                            disabled={!this.state.emailAddress}
                                                            text={t('login.forgottenPassword.sendLink')}
                                                            isLoading={this.state.isLoading}
                                                        ></SecondaryButton>
                                                    </ButtonContainer>
                                                </form>
                                            </div>
                                        )}
                                        {this.state.emailSent && !this.state.code && (
                                            <div className="">
                                                <form name="form" onSubmit={this.onEmailSubmit}>
                                                    <InputContainer className="forgot-password-container">
                                                        <h1>
                                                            {t('login.forgottenPassword.passwordResetIfEmailExist')}
                                                        </h1>
                                                        <p className="resendEmail">
                                                            {t('login.forgottenPassword.didNotGetEmail')}
                                                            <a onClick={this.resendForgottenPasswordEmail}>
                                                                {t('login.forgottenPassword.resendLink')}
                                                            </a>
                                                        </p>
                                                    </InputContainer>
                                                </form>
                                            </div>
                                        )}
                                        {this.state.code && this.state.emailAddress && !this.state.passwordChanged && (
                                            <div className="">
                                                <form name="form" onSubmit={this.onPasswordSubmit}>
                                                    <InputContainer className="forgot-password-container">
                                                        <h1>{t('login.forgottenPassword.resetPassword')}</h1>
                                                        <h3>{t('login.forgottenPassword.pleaseEnterNewPassword')}</h3>
                                                        <PasswordRequirements
                                                            password={this.state.newPassword}
                                                            onPasswordChange={isValid => isValid}
                                                        />
                                                        <input
                                                            name="password"
                                                            className="form-input-alternative"
                                                            type="password"
                                                            placeholder={t('login.passwordPlaceholder')}
                                                            onChange={this.handlePasswordChange}
                                                        />
                                                        <input
                                                            name="c_password"
                                                            className="form-input-alternative"
                                                            type="password"
                                                            placeholder={t('login.confirmPassword')}
                                                            value={this.state.cPassword}
                                                            onChange={this.handlePasswordChange}
                                                        />
                                                        <h3 className="error">
                                                            {this.state.requestFailed
                                                                ? t('login.forgottenPassword.error')
                                                                : ''}
                                                        </h3>
                                                    </InputContainer>
                                                    <ButtonContainer>
                                                        <SecondaryButton
                                                            type="submit"
                                                            disabled={!this.state.passwordsMatchAndIsValid}
                                                            text={t(
                                                                'login.forgottenPassword.resetPassword'
                                                            ).toUpperCase()}
                                                            isLoading={this.state.isLoading}
                                                        ></SecondaryButton>
                                                    </ButtonContainer>
                                                </form>
                                            </div>
                                        )}
                                        {this.state.code && this.state.emailAddress && this.state.passwordChanged && (
                                            <div className="">
                                                <form name="form" onSubmit={this.onEmailSubmit}>
                                                    <InputContainer className="forgot-password-container">
                                                        <h1>{t('login.forgottenPassword.forgotYourPassword')}</h1>
                                                        <h3 className="waiting">{t('login.redirectToLogin')}</h3>
                                                    </InputContainer>
                                                </form>
                                            </div>
                                        )}
                                        {this.state.readyToRedirect && <Redirect to={Routes.Login} />}
                                    </div>
                                </ImageBackground>
                            </Mobile>
                        </>
                    );
                }}
            </Translation>
        );
    }
}

export default ForgottenPassword;
