import { Component, ReactNode } from 'react';
import * as React from 'react';
import { WidgetList, WidgetPosition, WidgetHeader, WidgetListItem } from '../../../Shared/Components/Presentational/Widget';
import { Translation } from 'react-i18next';
import './CalendarList.scss';
import { PreviousIcon, NextIcon } from '../../../Shared/Components/Presentational/Icons';
import CalendarItemDetails from '../Container/CalendarItemDetails';
import { SelectedCalendarWidgetOptions } from '../../Redux/ActionTypes';
import feature from '../../../Shared/Decorators/Feature';
import Features from '../../../Constants/Features';
import { Entry } from 'contentful';

interface CalendarListProps {
    position: WidgetPosition;
    park: any;
    eventsOrOffers: Entry<ContentfulEvent | ContentfulOffer>[];
    currentPage: number;
    lastPage: number;
    canLoadMore: boolean;
    goNext?: () => void;
    goPrevious?: () => void;
    components?: CalendarListComponents;
    openEventDetailsModal: () => void;
    closeEventDetailsModal: () => void;
    selectedFilter: SelectedCalendarWidgetOptions;
}

export interface CalendarListComponents {
    widgetHeader: ReactNode;
}

interface CalendarListState {
    selectedItem: Entry<ContentfulEvent | ContentfulOffer> | undefined;
}

@feature({ features: [Features.Events, Features.Offers]})
class CalendarList extends Component<CalendarListProps, CalendarListState> {

    constructor(props: CalendarListProps) {
        super(props);

        this.state = {
            selectedItem: undefined
        };
    }

    openEventDetailsModal(item: Entry<ContentfulEvent | ContentfulOffer>) {
        this.setState({ selectedItem: item });
        this.props.openEventDetailsModal();
    }

    onImageError(errorEvent: React.SyntheticEvent<HTMLImageElement, Event>) {
        if (this.props.selectedFilter === 'events') {
            (errorEvent.target as any).src = '/images/default-event-image.png';
        } else {
            (errorEvent.target as any).src = '/images/default-offer-image.png';
        }
    }

    getImageSrc(item: Entry<ContentfulEvent | ContentfulOffer>): string {
        if (this.props.selectedFilter === 'events') {
            return item.fields.image ? item.fields.image.fields.file.url : '/images/default-event-image.png';
        } else {
            return item.fields.image ? item.fields.image.fields.file.url : '/images/default-offer-image.png';
        }
    }
    render() {
        return (
            <Translation>
                {
                    (t: (key: string, args?: {}) => string) => {
                        return (
                            <>
                                <WidgetHeader title={t('homePage.eventsAndOffersAt', { parkName: this.props.park && this.props.park.parkName ? this.props.park.parkName : t('homePage.myPark') })} />
                                <WidgetList position={this.props.position}>
                                    {this.props.components && this.props.components.widgetHeader}
                                    {this.props.canLoadMore && this.props.goPrevious && this.props.goNext && <div className="pagination-icons">
                                        <PreviousIcon clickAction={this.props.goPrevious} disabled={this.props.currentPage === 1} />
                                        <NextIcon clickAction={this.props.goNext} disabled={this.props.currentPage === this.props.lastPage} />
                                    </div>}
                                    {
                                        !this.props.eventsOrOffers.length && <WidgetListItem>
                                            <div className="events-widget-item">
                                                <div className="item-details">
                                                    <div className="item-title">
                                                        {this.props.selectedFilter === 'events' ? t('myCalendar.events.noEvents') : t('myCalendar.offers.noOffers')}
                                                    </div>
                                                </div>
                                            </div>
                                        </WidgetListItem>
                                    }
                                    {
                                        this.props.eventsOrOffers.map((x, i) => {
                                            return (
                                                <WidgetListItem key={i}>
                                                    <div className="events-widget-item" onClick={() => this.openEventDetailsModal(x)} >
                                                        <div className="event-image-container">
                                                            <img src={this.getImageSrc(x)} alt="" onError={errorEvent => this.onImageError(errorEvent)} />
                                                        </div>
                                                        <div className="item-details">
                                                            <div className="item-title">{x.fields.publicName}</div>
                                                            <div className="item-description">{x.fields.description}</div>
                                                            {this.props.selectedFilter === 'events' && <div className="item-title">{(x.fields as ContentfulEvent).date}</div>}
                                                        </div>
                                                    </div>
                                                </WidgetListItem>
                                            )
                                        })}
                                    <CalendarItemDetails onBackButtonClick={() => this.props.closeEventDetailsModal()} item={this.state.selectedItem!} itemType={this.props.selectedFilter} />
                                </WidgetList>
                            </>
                        );
                    }
                }
            </Translation>
        )
    }
}

export default CalendarList;