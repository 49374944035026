import moment from "moment";
import { useTranslation } from "react-i18next";
import React from "react";
import {
  Desktop,
  Mobile,
} from "../../../Shared/Components/Presentational/ResponsiveViews";

interface SelectedTicketTableProps {
  selectedTicket: FreshDeskTicketDTO;
}

const SelectedTicketTable: React.FC<SelectedTicketTableProps> = ({
  selectedTicket,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Desktop>
        <table className="ticket-info">
          <thead>
            <tr className="header-row">
              <th>{t("requestsAndQueries.tickets.ticketNumber")}</th>
              <th>{t("requestsAndQueries.tickets.subject")}</th>
              <th>{t("requestsAndQueries.tickets.group")}</th>
              <th>{t("requestsAndQueries.tickets.status")}</th>
              <th>{t("requestsAndQueries.tickets.dateSubmitted")}</th>
              <th>{t("requestsAndQueries.tickets.lastUpdated")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>{selectedTicket.Id}</b>
              </td>
              <td>
                <b>{selectedTicket.Subject}</b>
              </td>
              <td>{selectedTicket.GroupName}</td>
              <td>{selectedTicket.StatusCode}</td>
              <td>
                {moment(selectedTicket.GetCreatedAt).format("DD/MM/YYYY")}
              </td>
              <td>
                {moment(selectedTicket.GetUpdatedAt).format("DD/MM/YYYY")}
              </td>
            </tr>
          </tbody>
        </table>
      </Desktop>
      <Mobile>
        <table className="ticket-info">
          <tr>
            <th>{t("requestsAndQueries.tickets.ticketNumber")}</th>
            <td>
              <b>{selectedTicket.Id}</b>
            </td>
          </tr>
          <tr>
            <th>{t("requestsAndQueries.tickets.subject")}</th>
            <td>
              <b>{selectedTicket.Subject}</b>
            </td>
          </tr>
          <tr>
            <th>{t("requestsAndQueries.tickets.group")}</th>
            <td>{selectedTicket.GroupName}</td>
          </tr>
          <tr>
            <th>{t("requestsAndQueries.tickets.status")}</th>
            <td>{selectedTicket.StatusCode}</td>
          </tr>
          <tr>
            <th>{t("requestsAndQueries.tickets.dateSubmitted")}</th>
            <td>{moment(selectedTicket.GetCreatedAt).format("DD/MM/YYYY")}</td>
          </tr>
          <tr>
            <th>{t("requestsAndQueries.tickets.lastUpdated")}</th>
            <td>{moment(selectedTicket.GetUpdatedAt).format("DD/MM/YYYY")}</td>
          </tr>
        </table>
      </Mobile>
    </>
  );
};

export default SelectedTicketTable;
