import { GetApiCall } from "./ApiCall";

export class GetMemberByIdCall extends GetApiCall {
  constructor(memberId: string) {
    let url: string = "Membership/GetByMemberId";

    let params: any = { memberId }

    super(url, params);
  }
}