export const SEARCH_PASSES_REQUEST = 'SEARCH_PASSES_REQUEST';
export const SEARCH_PASSES_SUCCESS = 'SEARCH_PASSES_SUCCESS';
export const SEARCH_PASSES_FAILURE = 'SEARCH_PASSES_FAILURE';
export const SET_PASSES_FILTER = 'SET_PASSES_FILTER';
export const GET_PARK_PASS_TYPE_REQUEST = 'GET_PARK_PASS_TYPE_REQUEST';
export const GET_PARK_PASS_TYPE_SUCCESS = 'GET_PARK_PASS_TYPE_SUCCESS';
export const GET_PARK_PASS_TYPE_FAILURE = 'GET_PARK_PASS_TYPE_FAILURE';
export const SET_SELECTED_PARK_PASS_TYPE = 'SET_SELECTED_PARK_PASS_TYPE';
export const ADD_CAR_PASS_REQUEST = 'ADD_CAR_PASS_REQUEST';
export const ADD_CAR_PASS_SUCCESS = 'ADD_CAR_PASS_SUCCESS';
export const ADD_CAR_PASS_FAILURE = 'ADD_CAR_PASS_FAILURE';
export const ADD_SEASON_PASS_REQUEST = 'ADD_SEASON_PASS_REQUEST';
export const ADD_SEASON_PASS_SUCCESS = 'ADD_SEASON_PASS_SUCCESS';
export const ADD_SEASON_PASS_FAILURE = 'ADD_SEASON_PASS_FAILURE';
export const ADD_SHORT_STAY_PASS_REQUEST = 'ADD_SHORT_STAY_PASS_REQUEST';
export const ADD_SHORT_STAY_PASS_SUCCESS = 'ADD_SHORT_STAY_PASS_SUCCESS';
export const ADD_SHORT_STAY_PASS_FAILURE = 'ADD_SHORT_STAY_PASS_FAILURE';
export const SET_IS_ADD_PASS_MODAL_OPEN = 'SET_IS_ADD_PASS_MODAL_OPEN';
export const SET_IS_ADD_PASS_PHOTO_MODAL_OPEN = 'SET_IS_ADD_PASS_PHOTO_MODAL_OPEN';
export const SET_ADD_PASS_SUCCESSFUL_MODAL_OPEN = 'SET_ADD_PASS_SUCCESSFUL_MODAL_OPEN';
export const SET_ADD_PASS_ERROR_MODAL_OPEN = 'SET_ADD_PASS_ERROR_MODAL_OPEN';
export const SET_IS_RENEW_PASS_MODAL_OPEN = 'SET_IS_RENEW_PASS_MODAL_OPEN';
export const SET_RENEW_PASS_SUCCESSFUL_MODAL_OPEN = 'SET_RENEW_PASS_SUCCESSFUL_MODAL_OPEN';
export const SET_RENEW_PASS_ERROR_MODAL_OPEN = 'SET_RENEW_PASS_ERROR_MODAL_OPEN';
export const DELETE_PASS_REQUEST = 'DELETE_PASS_REQUEST';
export const DELETE_PASS_SUCCESS = 'DELETE_PASS_SUCCESS';
export const DELETE_PASS_FAILURE = 'DELETE_PASS_FAILURE';
export const SET_DELETE_PASS_CONFIRMATION_MODAL_OPEN = 'SET_DELETE_PASS_CONFIRMATION_MODAL_OPEN';
export const SET_DELETE_PASS_ERROR_MODAL_OPEN = 'SET_DELETE_PASS_ERROR_MODAL_OPEN';
export const RENEW_SEASON_PASS_REQUEST = 'RENEW_SEASON_PASS_REQUEST';
export const RENEW_SEASON_PASS_SUCCESS = 'RENEW_SEASON_PASS_SUCCESS';
export const RENEW_SEASON_PASS_FAILURE = 'RENEW_SEASON_PASS_FAILURE';
export const ANALYZE_PASS_PHOTO_REQUEST = 'ANALYZE_PASS_PHOTO_REQUEST';
export const ANALYZE_PASS_PHOTO_SUCCESS = 'ANALYZE_PASS_PHOTO_SUCCESS';
export const ANALYZE_PASS_PHOTO_FAILURE = 'ANALYZE_PASS_PHOTO_FAILURE';
export const GET_PASS_PHOTO_SIZE_LIMIT_REQUEST = 'GET_PASS_PHOTO_SIZE_LIMIT_REQUEST';
export const GET_PASS_PHOTO_SIZE_LIMIT_SUCCESS = 'GET_PASS_PHOTO_SIZE_LIMIT_SUCCESS';
export const GET_PASS_PHOTO_SIZE_LIMIT_FAILURE = 'GET_PASS_PHOTO_SIZE_LIMIT_FAILURE';
export const ADD_MEMBERSHIP_REQUEST = 'ADD_MEMBERSHIP_REQUEST';
export const ADD_MEMBERSHIP_SUCCESS = 'ADD_MEMBERSHIP_SUCCESS';
export const ADD_MEMBERSHIP_FAILURE = 'ADD_MEMBERSHIP_FAILURE';
export const SAVE_AND_EXIT = 'SAVE_AND_EXIT';
export const CHANGE_MEMBERSHIP_FORM_PAGE = 'UPDATE_MEMBERSHIP_FORM_PAGE';
export const UPDATE_MEMBERSHIP_FORM = 'UPDATE_MEMBERSHIP_FORM';
export const UPDATE_NEW_MEMBERSHIP = 'UPDATE_NEW_MEMBERSHIP';
export const GET_MY_MEMBERSHIPS_REQUEST = 'GET_MY_MEMBERSHIPS_REQUEST';
export const GET_MY_MEMBERSHIPS_SUCCESS = 'GET_MY_MEMBERSHIPS_SUCCESS';
export const GET_MY_MEMBERSHIPS_FAILURE = 'GET_MY_MEMBERSHIPS_FAILURE';
export const GET_ADD_MEMBERSHIP_BY_ID_REQUEST = 'GET_ADD_MEMBERSHIP_BY_ID_REQUEST';
export const GET_ADD_MEMBERSHIP_BY_ID_SUCCESS = 'GET_ADD_MEMBERSHIP_BY_ID_SUCCESS';
export const GET_ADD_MEMBERSHIP_BY_ID_FAILURE = 'GET_ADD_MEMBERSHIP_BY_ID_FAILURE';
export const UPDATE_MEMBERSHIP_BASKET = 'UPDATE_MEMBERSHIP_BASKET';
export const UPDATE_MEMBERSHIP_FORM_PAGE = 'UPDATE_MEMBERSHIP_FORM_PAGE';
export const UPDATE_REFER_MEMBERSHIP_FORM_PAGE = 'UPDATE_REFER_MEMBERSHIP_FORM_PAGE';
export const START_NEW_MEMBERSHIP  = 'START_NEW_MEMBERSHIP';
export const TOGGLE_SHOW_DRAFT_MEMBERSHIPS = 'TOGGLE_SHOW_DRAFT_MEMBERSHIPS';
export const TOGGLE_SHOW_EXPIRED_MEMBERSHIPS = 'TOGGLE_SHOW_EXPIRED_MEMBERSHIPS';
export const UPDATE_FILTERED_MEMBERSHIPS = 'UPDATE_FILTERED_MEMBERSHIPS';
export const ADD_AGE_VERIFICATION_REQUEST = 'ADD_AGE_VERIFICATION_REQUEST';
export const ADD_AGE_VERIFICATION_SUCCESS = 'ADD_AGE_VERIFICATION_SUCCESS';
export const ADD_AGE_VERIFICATION_ERROR = 'ADD_AGE_VERIFICATION_ERROR';
export const GET_EDIT_MEMBERSHIP_BY_ID_REQUEST = 'GET_EDIT_MEMBERSHIP_BY_ID_REQUEST';
export const GET_EDIT_MEMBERSHIP_BY_ID_SUCCESS = 'GET_EDIT_MEMBERSHIP_BY_ID_SUCCESS';
export const GET_EDIT_MEMBERSHIP_BY_ID_FAILURE = 'GET_EDIT_MEMBERSHIP_BY_ID_FAILURE';
export const UPDATE_EDIT_MEMBERSHIP = 'UPDATE_EDIT_MEMBERSHIP';
export const UPDATE_EDIT_MEMBERSHIP_FORM = 'UPDATE_EDIT_MEMBERSHIP_FORM';
export const UPDATE_EDIT_MEMBERSHIP_REQUEST = 'UPDATE_EDIT_MEMBERSHIP_FORM';
export const UPDATE_EDIT_MEMBERSHIP_SUCCESS = 'UPDATE_EDIT_MEMBERSHIP_FORM';
export const UPDATE_EDIT_MEMBERSHIP_FAILURE = 'UPDATE_EDIT_MEMBERSHIP_FAILURE';
export const CREATE_MEMBERSHIP_BASKET_REQUEST = 'CREATE_MEMBERSHIP_BASKET_REQUEST';
export const CREATE_MEMBERSHIP_BASKET_SUCCESS = 'CREATE_MEMBERSHIP_BASKET_SUCCESS';
export const CREATE_MEMBERSHIP_BASKET_FAILURE = 'CREATE_MEMBERSHIP_BASKET_FAILURE';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_DOCU_SIGN_MESSAGE = 'SET_DOCU_SIGN_MESSAGE';
export const SET_PAYMENT_SUMMARY_DTO = 'SET_PAYMENT_SUMMARY_DTO';
export const SET_MEMBERSHIP_COMPONENTS = 'SET_MEMBERSHIP_COMPONENTS';
export const SET_FINALISE_NOW_CONTAINERS = 'SET_FINALISE_NOW_CONTAINERS';
export const SET_FINALISE_LATER_CONTAINERS = 'SET_FINALISE_LATER_CONTAINERS';
export const SET_MEMBERSHIP_TO_DELETE = 'SET_MEMBERSHIP_TO_DELETE';
export const SET_DELETE_MEMBERSHIP_MODAL_OPEN = 'SET_DELETE_MEMBERSHIP_MODAL_OPEN';
export const SET_CHECKOUT_SESSION = 'SET_CHECKOUT_SESSION';
export const SET_PAYMENT_REQUIRED = 'SET_PAYMENT_REQUIRED';
export const SET_CONTENTFUL_MEMBERSHIP_TYPES = 'SET_CONTENTFUL_MEMBERSHIP_TYPES';
export const SET_REFERRED_MEMBERSHIP_COMPONENTS = 'SET_REFERRED_MEMBERSHIP_COMPONENTS';
export const GET_MY_REFERRAL_REQUEST = 'GET_MY_REFERRAL_REQUEST';
export const GET_MY_REFERRAL_SUCCESS = 'GET_MY_REFERRAL_SUCCESS';
export const GET_MY_REFERRAL_FAILURE = 'GET_MY_REFERRAL_FAILURE';
export const SET_TERM_DURATIONS = 'SET_TERM_DURATIONS';