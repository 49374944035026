import { PostApiCall } from './ApiCall';

export class PostSearchLodgeBookingsCall extends PostApiCall {
    constructor(filter: BookingFilterDTO) {
        let url: string = 'account/' + filter.LodgeId + '/SearchLodgeBookings';

        super(url);

        this.body = filter;
    }
}
