import React, { Component } from 'react';
import './Backgrounds.scss';
//@ts-ignore
import Zoom from 'react-reveal/Zoom';
import classNames from 'classnames';

interface BackgroundProps {
    src?: string;
    containerClassName?: string;
}

export class VerticalBackground extends Component<BackgroundProps> {
    render() {
        return (
            <div className={classNames('vertical-background-container', this.props.containerClassName)}>
                <Zoom>
                    <img className="vertical-background-image" src={this.props.src} alt="" />
                </Zoom>
                <div className="vertical-background-children">{this.props.children}</div>
            </div>
        );
    }
}

export class ImageBackground extends Component<BackgroundProps> {
    render() {
        return (
            <div className={classNames('image-background-container', this.props.containerClassName)}>
                <img className="image-background" src={this.props.src} alt="" />
                <div className="image-background-children">{this.props.children}</div>
            </div>
        );
    }
}
