import { GetApiCall } from "./ApiCall";

export class PostDontShowAgainMembershipModalCall extends GetApiCall {
    constructor() {
        let url: string = 'Membership/SetMembershipPromptToOff';

        super(url);

        // this.body = body;
        // this.logoutOn401 = false;
    }
}