import moment from 'moment';

export function isValidDate(date: string): boolean {
    return date.length > 0 && moment(date, 'DD/MM/YYYY', true).isValid();
}

export function isUnder10Characters(value: any): boolean {
    return value && value.registration.length <= 10;
}

export function vehicleValidation(value: any): boolean {
    return (value.description && value.registration) || (!value.description && !value.registration) || (value.registration && !value.description);
}

export function isMembershipType(value: any): boolean {
    return value.toString().length > 0;
}

export function hasValue(value: any): boolean {
    return value.toString().length > 0;
}

export function isTextOnly(value: any): boolean {
    return !/\d/.test(value);
}

export function numberisValid(value: number): boolean {
    return value >= 0 && value <= 10;
}

export function isValidEmail(email: string): boolean {
    if(email === ""){
        return true;
    }
    return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) != null;
}

export function isFutureDate(date: string): boolean {
    return isValidDate(date) && moment(date, 'DD/MM/YYYY', true).isSameOrAfter(moment(), 'day'); 
}

export function isNotFutureDate(date: Date|undefined|null): boolean {
    if (!date) {
        return false;
    }
    if (moment(date).diff(moment(), 'day') < 0 || (moment(date).diff(moment(), 'day') == 0 && moment(date).diff(moment(), 'hour') <= 0)){
        return true;
    }
    return false;
}


export function isDateNotInPast(date: Date|undefined|null): boolean {
    if (!date) {
        return false;
    }
    if (moment(date).diff(moment(), 'day') >= 0){
        return true;
    }
    return false;
}

export function isAfterDate(date: string, dateAfter: string): boolean {
    return isValidDate(date) && isValidDate(dateAfter) && moment(date, 'DD/MM/YYYY', true).isAfter(moment(dateAfter, 'DD/MM/YYYY', true), 'day'); 
}

export function CreateXDaysValidator(days: number): any{
    return function isDateAfterWithinXDays(date: Date|undefined|null): boolean {
        if (!date) {
            return false;
        }
        if (moment(date).diff(moment(), 'day') <= (days-1)){
            return true;
        }
        return false;
    }
}

export function isInternationalPhoneNumber(phoneNumber: string): boolean {
    const regex = /^\+(\(?)([0-9]){1,3}(\)?)(\ ?[0-9]\ ?){3,20}$/;
    return regex.test(phoneNumber) && isValidUKPhoneNumber(phoneNumber);
}

export function isValidUKPhoneNumber(phoneNumber: string): boolean {

    var result = false;
    while(phoneNumber.search(" ") != -1)
    {
        phoneNumber = phoneNumber.replace(" ","");
    }   
    if (phoneNumber.startsWith('+44') || phoneNumber.startsWith('+(44)')) {
        if (phoneNumber.startsWith('+(44)')) {
            phoneNumber = phoneNumber.replace('(', '').replace(')', '');
        }
        const number = parseFloat(phoneNumber);
        result = number.toString().length === 12;
    }
    if (phoneNumber.startsWith('0')) {
        result = phoneNumber.length === 11;
    }
    if (phoneNumber === "") {
        result = true;
    }

    return result;
}