import { Component } from 'react';
import Feature from '../../Decorators/Feature';

export interface FeatureToggleProps {
    features: string[];
    replacement?: React.ReactNode;
}

@Feature<FeatureToggleProps>(props => ({ features: props.features, replacement: props.replacement }))
export default class FeatureToggle extends Component<FeatureToggleProps> {
    public render() {
        return this.props.children;
    };
}