import { GetApiCall } from './ApiCall';

export class GetRestaurantAvailabilityCall extends GetApiCall {
    constructor(restaurantCode: string, session: string, date: string, numOfAdults: number, numOfChildren?: number) {
        let url: string = 'external/checkRestaurantAvailability';

        let params: any = { restaurantCode, session, date, numOfAdults}

        if (numOfChildren){
            params = {...params, numOfChildren}
        }

        super(url, params);
    }
}
