import React, { useEffect, useRef, useState } from 'react';
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import UpdateMemberPersonalDetails from '../Presentational/AddMembershipSubPages/UpdateMemberPersonalDetails';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import { ClickableIcon } from '../../../Shared/Components/Presentational/Icons';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import Breadcrumb from '../../../Shared/Components/Presentational/Breadcrumb';
import '../Container/AddMembershipStyles.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import FormFooter from '../Presentational/AddMembershipSubPages/FormFooter';
import { ICallSave } from '../Presentational/AddMembershipSubPages/ICallSave';
import UpdateMemberVehicles from '../Presentational/AddMembershipSubPages/UpdateMemberVehicles';
import * as Routes from "../../../Constants/Routes";
import PhotoUpload from '../Presentational/AddMembershipSubPages/PhotoUpload';
import { GetHTMlContentBlockCall } from '../../../Contentful/Calls/GetHTMLContentBlockCall';

interface EditMemberProps extends RouteComponentProps, WithTranslation {
}

const sections =
  [
    {
      key: 0,
      title: "addMembership.personalDetails.title",
      name: 'personalDetails'
    },
    {
      key: 1,
      title: "addMembership.vehicles.title",
      name: 'vehicles'
    },
    {
      key: 2,
      title: "addMembership.photoUpload.title",
      name: 'photoUpload'
    }
  ]

const EditMember: React.FC<EditMemberProps> = (props: EditMemberProps) => {
  const [pageNo, setPageNo] = useState<number>(0);
  const [saveSpinnerOn, setSaveSpinnerOn] = useState<boolean>(false);
  const { t } = props;
  const childPageRef = useRef<ICallSave>(null);
  const [redirectToDashboard, setRedirectToDashboard] = useState<boolean>(false);
  const [photoUploadInstructions, setphotoUploadInstructions] = useState<ContentfulContentObject>();
  const [photoUploadFileRestrictions, setphotoUploadFileRestrictions] = useState<ContentfulContentObject>();
  const [photoUploadException, setPhotoUploadException] = useState<ContentfulContentObject>();


  useEffect(() => {
    getContentBlock("My Haulfryn - Photo Upload - Set- by- Step Guide", "photoUploadInstructions")
      .then(r => setphotoUploadInstructions(r));
    getContentBlock("My Haulfryn - Photo Upload - File Restrictions", "photoUploadFileRestrictions")
      .then(r => setphotoUploadFileRestrictions(r));
    getContentBlock("My Haulfryn - Photo Upload - Exception", "photoUploadException")
      .then(r => setPhotoUploadException(r));
  }, [])

  const getContentBlock = async (name: string, label: string) => {
    try {
      const text = await GetHTMlContentBlockCall(name, label);
      return text;
    } catch (e) {
      console.log(e);
    }
  }

  const onBackButton = () => {
    if (pageNo - 1 >= 0) {
      setPageNo(pageNo - 1);
    }
  }

  const onSaveError = () => {
    setSaveSpinnerOn(false);
  }

  const onSaveCancelled = () => {
    setSaveSpinnerOn(false);
  }

  const onExitButton = () => {
    setRedirectToDashboard(true);
  }

  const onContinueButton = () => {
    setSaveSpinnerOn(true);
    if (childPageRef.current !== null) {
      console.log('save called');
      childPageRef.current.save();
    } else {
      setSaveSpinnerOn(false);
    }
  }

  const onSaveComplete = () => {
    if (pageNo + 1 >= sections.length) {
      setRedirectToDashboard(true);
    } else {
      setSaveSpinnerOn(false);
      setPageNo(pageNo + 1);
    }
  }

  const enableSaveAndContinue = () => {
  }

  const disableSaveAndContinue = () => {

  }

  return (
    <>
      {redirectToDashboard &&
        <Redirect
          to={Routes.MyGuests}
        />
      }
      <div className="widget-container">
        <h1 className="widget-layout-title">{t("editMember.title")}</h1>
        <div className="widget-layout-message">
          {/* TODO - Add contentful text */}
          Update any of your member details that may have changed.
        </div>

        <Widget
          position={{
            columnIndex: 1,
            columnSpan: 12,
            rowIndex: 3,
            rowSpan: 9,
          }}
        >
          {pageNo > 0 &&
            <ClickableIcon
              icon={faArrowAltCircleLeft}
              clickAction={onBackButton}
              className="back-button"
              size={"2x"}
            />
          }

          <div className="breadcrumb">
            {sections.map((s) =>
              <Breadcrumb
                title={s.title}
                key={s.key}
                length={sections.length}
                selected={s.key === pageNo} section={s} />
            )}
          </div>

          {props.location.state.memberId &&
            <div className='form-container'>
              {pageNo === 0 &&
                <UpdateMemberPersonalDetails
                  memberId={props.location.state.memberId}
                  ref={childPageRef}
                  onSaveComplete={onSaveComplete}
                  onSaveError={onSaveError}
                  onSaveCancelled={onSaveCancelled}
                />
              }
              {pageNo === 1 &&
                <div>
                  <UpdateMemberVehicles
                    memberId={props.location.state.memberId}
                    ref={childPageRef}
                    onSaveComplete={onSaveComplete}
                    onSaveError={onSaveError}
                    onSaveCancelled={onSaveCancelled}
                  />
                </div>
              }
              {
                pageNo === 2 &&
                photoUploadInstructions &&
                photoUploadFileRestrictions &&
                photoUploadException &&
                <PhotoUpload
                  ref={childPageRef}
                  enableSaveAndContinue={enableSaveAndContinue}
                  disableSaveAndContinue={disableSaveAndContinue}
                  memberId={props.location.state.memberId}
                  instructions={photoUploadInstructions}
                  fileRestrictions={photoUploadFileRestrictions}
                  exception={photoUploadException}
                  triggerNextPage={onSaveComplete}
                  setIsLoading={(_) => {}}
                  isLoading={saveSpinnerOn}
                  onSaveError={onSaveError}
                />
              }
            </div>
          }

          <div className='widget-footer'>
            <FormFooter
              exitButtonText='Exit'
              continueButtonText='Save And Continue'
              onContinueClick={onContinueButton}
              onExitClick={onExitButton}
              showContinueButton={true}
              showExitButton={true}
              showContinueButtonLoading={saveSpinnerOn}
              disableContinueButton={saveSpinnerOn}
            />
          </div>
        </Widget>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => { }

const mapDispatchToProps = (dispatch: any) => { }

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps))
  (EditMember);
