import React, { Component } from 'react';
import { Widget, WidgetHeader, WidgetPosition } from '../../../Shared/Components/Presentational/Widget';
import { Calendar, CalendarEvent, CalendarResource } from '../../../Shared/Components/Presentational/Calendar';
import { Link } from 'react-router-dom';
import * as Routes from '../../../Constants/Routes';
import { Translation } from 'react-i18next';
import './CalendarWidget.scss';
import moment from 'moment';

interface CalendarWidgetProps {
    position: WidgetPosition;
    events: CalendarEvent[];
    resources: CalendarResource[];
}

class CalendarWidget extends Component<CalendarWidgetProps> {
    render() {
        return (
            <Translation>
                {
                    (t: (key: string, args?: {}) => string) => {
                        return (
                            <Widget position={this.props.position}>
                                <WidgetHeader title={t('homePage.calendar.title', { currentMonth: moment().format('MMMM YYYY') })} />
                                <div className="calendar-widget">
                                    <Calendar events={this.props.events} resources={this.props.resources} />
                                    <Link to={Routes.MyCalendar}>{t('homePage.calendar.viewCalendar')}</Link>
                                </div>
                            </Widget>
                        );
                    }
                }
            </Translation>
        )
    };
}

export default CalendarWidget;