import React from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton, PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import './RestaurantDetails.scss';
import { Entry } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import RichTextRenderOptions from '../../../Contentful/RichTextOptions';
import { props } from 'cypress/types/bluebird';

interface ParkRestaurantDetailsProps {
    restaurant: Entry<ContentfulOutlet>;
    isMemberWithOutMembershipNumber: boolean;
    menus: Entry<ContentfulMenu>[],
    openTableBookingModal: () => void,
}

const RestaurantDetails: React.FC<ParkRestaurantDetailsProps> = ({ restaurant, menus, openTableBookingModal, isMemberWithOutMembershipNumber }: ParkRestaurantDetailsProps) => {
    const { t } = useTranslation();

    const openMenu = (menu: Entry<ContentfulMenu>) => {
        const windowReference = window.open();
        if (menu) {
            windowReference!.location.href = menu.fields.pdf.fields.file.url;
        }
    };

    const bookOnline = () => {
        window.open(restaurant.fields.onlineBookingLink, "_blank");
    };

    return (
        <>
            <div className="restaurant-details">
                <div className="details">
                    <div className="image">
                        <img src={restaurant.fields.image? restaurant.fields.image.fields.file.url : '/images/default-restaurant-image.png'} alt="Restaurant" />
                    </div>
                    <div className="side-details">
                        <div>
                            <p className="title">{t('myCalendar.restaurants.details.type')}</p>
                            <p className="value">{restaurant.fields.outletType}</p>
                        </div>
                        <div>
                            <p className="title">{t('myCalendar.restaurants.details.phoneNumber')}</p>
                            <a href={`tel:${restaurant.fields.phoneNumber}`} className="value">
                                {restaurant.fields.phoneNumber}
                            </a>
                        </div>
                        {restaurant.fields.onlineBookingLink && !restaurant.fields.zonalCode &&
                            <div>
                                <PrimaryButton
                                    className="book-a-table"
                                    text={t('myCalendar.restaurants.details.bookOnline')}
                                    onClick={() => bookOnline()}>
                                </PrimaryButton>
                                
                            </div> 
                        }
                        {restaurant.fields.zonalCode &&
                            <div>
                                <PrimaryButton
                                    className="book-a-table"
                                    text={t('myCalendar.restaurants.details.bookTable')}
                                    onClick={() => openTableBookingModal()}
                                    disabled={isMemberWithOutMembershipNumber} >
                                </PrimaryButton>
                                {isMemberWithOutMembershipNumber && (
                                    <p>Membership Needed to book</p>
                                )}
                            </div> 
                        }
                        
                    </div>
                </div>
                <div className="detail-margin">
                    <p className="title">{t('myCalendar.restaurants.details.description')}</p>
                    <p className="description"> {documentToReactComponents(restaurant.fields.description, RichTextRenderOptions)}</p>
                </div>
                {menus.length > 0 && (
                    <div className="detail-margin">
                        <p className="title">{t('myCalendar.restaurants.details.viewTheMenu')}</p>
                        <div className="menu-list">
                            {menus.map((menu, i) => {
                                return (
                                    <SecondaryButton
                                        key={i}
                                        type="button"
                                        text={menu.fields.publicName}
                                        onClick={() => openMenu(menu)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default RestaurantDetails;
