import React, { Component } from 'react';
import { WidgetPosition, Widget, WidgetHeader } from '../../../Shared/Components/Presentational/Widget';
import TransactionItem from './TransactionItem';
import './TransactionWidget.scss';
import { Link } from 'react-router-dom';
import * as Routes from '../../../Constants/Routes';
import { withTranslation, WithTranslation } from 'react-i18next';
import Spinner from '../../../Shared/Components/Presentational/Spinner';
import { Post } from '../../../Api/ApiService';
import moment from 'moment';
import { PostTransactionSearchCall } from '../../../Api/Calls/PostTransactionSearchCall';

interface TransactionWidgetProps extends WithTranslation {
    position: WidgetPosition;
    accountId: number;
    accountNumber: string;
    setAccountBalance: (newBalance: number) => void;
}

interface TransactionWidgetState {
    isLoading: boolean;
    accountBalance: undefined | number;
    transactions: TransactionDTO[];
}

class TransactionWidget extends Component<TransactionWidgetProps, TransactionWidgetState> {
    state: TransactionWidgetState = {
        isLoading: true,
        accountBalance: undefined,
        transactions: [],
    };

    componentDidMount() {
        this.fetchTransactions();
    }

    componentDidUpdate(prevProps: TransactionWidgetProps) {
        if (prevProps.accountId !== this.props.accountId) {
            this.fetchTransactions();
        }
    }

    private async fetchTransactions() {
        this.setState({ isLoading: true });
        const filter: TransactionFilterDTO = {
            Page: 1,
            PageSize: 5,
            AccountId: this.props.accountId,
            AccountNumber: this.props.accountNumber,
            FromDate: moment().subtract(6, 'months').format('DD/MM/YYYY'),
            ToDate: moment().format('DD/MM/YYYY'),
            TransactionCategory: '',
        };

        try {
            const results = await Post<PagedResultDTO>(new PostTransactionSearchCall(filter));
            if (results.data.Dtos.length > 0) {
                const balance = results.data.Dtos[0].Balance;
                this.setState({ transactions: results.data.Dtos, accountBalance: balance });
                this.props.setAccountBalance(balance);
            }
        } catch {
            this.setState({ transactions: [] });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { t, position } = this.props;
        const { transactions, accountBalance } = this.state;

        return (
            <Widget position={position}>
                <WidgetHeader title={t('homePage.transaction.recent')} />
                {this.state.isLoading ? (
                    <div className="transaction-spinner">
                        <Spinner />
                    </div>
                ) : transactions.length > 0 ? (
                    <>
                        {transactions.map((transaction, i) => (
                            <TransactionItem key={`transaction-${i}`} transaction={transaction} />
                        ))}
                        {accountBalance !== undefined && (
                            <div className="transaction-balance">
                                {t('homePage.transaction.balance')} £{accountBalance.toFixed(2)}
                            </div>
                        )}
                    </>
                ) : (
                    <div className="no-transactions">{t('homePage.transaction.noTransactions')}</div>
                )}

                <div className="view-account-container">
                    <Link to={Routes.MyAccount} className="view-account">
                        {t('homePage.transaction.viewMyAccount')}
                    </Link>
                </div>
            </Widget>
        );
    }
}

export default withTranslation()(TransactionWidget);
