import React from 'react';
import { WidgetPosition } from '../../../Shared/Components/Presentational/Widget';
import './MenuWidget.scss';
//@ts-ignore
import Fade from 'react-reveal/Fade';
import useAlert from '../../../Shared/Hooks/useAlert';
import Alert from '../../../Shared/Components/Presentational/Alert';

interface MenuWidgetProps {
  position: WidgetPosition;
  children?: React.ReactNode;
}

export default function MenuWidget(props: MenuWidgetProps) {
  const { alertTitle, alertContent, canShowAlert } = useAlert();

  const style = {
    gridColumnStart: props.position.columnIndex,
    msGridColumn: props.position.columnIndex,
    gridColumnEnd: 'span ' + props.position.columnSpan,
    msGridColumnSpan: props.position.columnSpan,
    gridRowStart: props.position.rowIndex,
    msGridRow: props.position.rowIndex,
    gridRowEnd: 'span ' + props.position.rowSpan,
    msGridRowSpan: props.position.rowSpan,
  };

  return (
    <div className="menu-widget-container" style={style}>
      <div>
        <Fade right>
          <div className="menu-contents">{props.children}</div>
        </Fade>
        {canShowAlert && <Alert title={alertTitle} content={alertContent} />}
      </div>
    </div>
  );
}
