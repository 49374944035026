import Table, {
  ColumnDefinition,
} from "../../../Shared/Components/Presentational/Table";
import React from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../Shared/Components/Presentational/Buttons";
import { Widget } from "../../../Shared/Components/Presentational/Widget";
import { useIsDesktop } from "../../../Shared/Components/Presentational/ResponsiveViews";

interface TicketListProps {
  contactEmail?: string;
  onTicketClicked: (ticket: FreshDeskTicketDTO) => void;
  isLoading: boolean;
  columns: ColumnDefinition[];
  pagedResults: PagedResultDTO;
  filter: OrderedFilterDTO;
  onFilterChange: (newFilter: any) => void;
}

const TicketList: React.FC<TicketListProps> = (props) => {
  const { contactEmail } = props;
  const { t } = useTranslation();

  return (
    <>
      {(!!contactEmail && (
        <>
          <Widget
            position={{
              columnIndex: 9,
              columnSpan: 4,
              rowIndex: 3,
              rowSpan: 5,
            }}
          >
            <div className="request-and-queries-ticket">Raise a New Ticket</div>
            <p className="request-and-queries-details">
              {t("requestsAndQueries.tickets.add.helpText", { contactEmail })}
            </p>
            <p className="request-and-queries-request-details">
              {t("requestsAndQueries.tickets.add.responseHelpText")}
            </p>

            <PrimaryButton
              text={t("requestsAndQueries.tickets.add.sendEmail")}
              onClick={() => window.open(`mailto:${contactEmail}`)}
              className="request-and-queries-btn"
            />
          </Widget>
          <Widget
            position={{
              columnIndex: 1,
              columnSpan: 8,
              rowIndex: 3,
              rowSpan: 9,
            }}
          >
            <Table
              columns={props.columns}
              pagedResults={props.pagedResults}
              filter={props.filter}
              onFilterChange={props.onFilterChange}
              pageSize={props.filter.PageSize}
              noResultsMessage={
                props.isLoading === false
                  ? t("requestsAndQueries.tickets.noResultsMessage")
                  : ""
              }
              isLoading={props.isLoading}
              onRowClick={(rowValue) =>
                props.onTicketClicked(rowValue as FreshDeskTicketDTO)
              }
            />
          </Widget>
        </>
      )) || (
        <Widget
          position={{
            columnIndex: 1,
            columnSpan: 16,
            rowIndex: 3,
            rowSpan: 9,
          }}
        >
          <Table
            columns={props.columns}
            pagedResults={props.pagedResults}
            filter={props.filter}
            onFilterChange={props.onFilterChange}
            pageSize={props.filter.PageSize}
            noResultsMessage={
              props.isLoading === false
                ? t("requestsAndQueries.tickets.noResultsMessage")
                : ""
            }
            isLoading={props.isLoading}
            onRowClick={(rowValue) =>
              props.onTicketClicked(rowValue as FreshDeskTicketDTO)
            }
          />
        </Widget>
      )}
    </>
  );
};

export default TicketList;
