import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Entry } from 'contentful';
import { useSelector } from 'react-redux';

export default function useAlert() {
  const parkInformation = useSelector((state: any) => state.information.parkInformation) as Entry<ContentfulParkInformation>;
  const alertTitle = parkInformation && parkInformation.fields && parkInformation.fields.myHaulfrynAlert && parkInformation.fields.myHaulfrynAlert.fields.title;
  const alertContent = parkInformation && parkInformation.fields && parkInformation.fields.myHaulfrynAlert && documentToReactComponents(parkInformation.fields.myHaulfrynAlert.fields.content);
  const canShowAlert = alertTitle || alertContent;

  return { alertTitle, alertContent, canShowAlert };
}
