import ContentfulClient from  "../ContentfulClient";

export async function GetParkCall(parkCode : string) {
    let filter ={
        content_type: 'park',
        'fields.code': parkCode,
        
    }
    const result =  await ContentfulClient.getEntries<ContentfulPark>(filter);

    if (result.errors){
        throw {message: "GetParkCall returned error/s", errors: result.errors} 
    }
    return result.items[0];
}