import React, { Component } from 'react';
import { Calendar, CalendarEvent, CalendarResource } from '../../../Shared/Components/Presentational/Calendar';
import { Translation } from 'react-i18next';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import CalendarListWidget from '../Container/CalendarListWidget';
import MyCalendarListHeader from '../Container/MyCalendarListHeader';
import ReservationLinks from '../Container/ReservationLinks';
import Fab from '../../../Shared/Components/Presentational/Fab';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SuccessModal } from '../../../Shared/Components/Presentational/Modal';
import AddBookingModal from './AddBookingModal';
import SubletBookingInfoModal from './SubletBookingInfoModal';

interface MyCalendarPageMobileProps {
    minDate: Date;
    maxDate: Date;
    events: CalendarEvent[];
    resources: CalendarResource[];
    isHeaderOpen: boolean;
    toggleBookingModal: (isOpen: boolean) => void;
    isAddBookingModalOpen: boolean;
    addBooking: (booking: BookingDTO) => void;
    toggleBookingSuccessModal: (isOpen: boolean) => void;
    isAddBookingSuccessModalOpen: boolean;
    onRangeChange?: (range: { start: any; end: any }) => void;
    onNavigate?: (date: Date) => void;
    lodgeId: number;
    accountId: number;
    addBookingErrorMessage: undefined | string;
    setAddingBookingErrorMessage: (message: any) => void;
    hasANPRFeature: boolean;
    calendarDate?: Date;
    selectedCalendarEvent?: CalendarEvent;
    setSelectedCalendarEvent: (event: CalendarEvent | undefined) => any;
    clickableEvents: boolean;
}

class MyCalendarPageMobile extends Component<MyCalendarPageMobileProps> {
    public render(): JSX.Element {
        return (
            <Translation>
                {(t: (key: string) => string) => {
                    return (
                        <>
                            <div className="mobile-widget-container widget-container">
                                <h1 className="widget-layout-title">{t('myCalendar.title')}</h1>
                                <p className="widget-layout-message">{t('myCalendar.message')}</p>
                                <Fab
                                    icon={faPlus}
                                    clickAction={() => this.props.toggleBookingModal(true)}
                                    className="add-booking"
                                />
                                <Widget
                                    position={{
                                        columnIndex: 1,
                                        columnSpan: 12,
                                        rowIndex: 3,
                                        rowSpan: 5,
                                    }}
                                >
                                    <Calendar
                                        className="my-calendar mobile-calendar"
                                        minDate={this.props.minDate}
                                        maxDate={this.props.maxDate}
                                        events={this.props.events}
                                        resources={this.props.resources}
                                        onNavigate={this.props.onNavigate}
                                        onRangeChange={this.props.onRangeChange}
                                        onClickEvent={this.props.setSelectedCalendarEvent}
                                        clickableEvents={this.props.clickableEvents}
                                    />
                                    <p className="guarantee">{t('myCalendar.guarantee')}</p>
                                </Widget>
                                <Widget
                                    containerClassName="reservation-links-widget-container"
                                    position={{
                                        columnIndex: 1,
                                        columnSpan: 12,
                                        rowIndex: 9,
                                        rowSpan: 2,
                                    }}
                                >
                                    <ReservationLinks showHeader={true} accountId={this.props.accountId} lodgeId={this.props.lodgeId}/>
                                </Widget>
                                <CalendarListWidget
                                    position={{
                                        columnIndex: 1,
                                        columnSpan: 12,
                                        rowIndex: 11,
                                        rowSpan: 4,
                                    }}
                                    pageSize={5}
                                    canLoadMore={true}
                                    widgetComponents={{
                                        widgetHeader: (
                                            <MyCalendarListHeader className="mobile-events-header" pageSize={5} />
                                        ),
                                    }}
                                />
                            </div>

                            <AddBookingModal
                                isOpen={this.props.isAddBookingModalOpen}
                                toggleBookingModal={this.props.toggleBookingModal}
                                addBooking={this.props.addBooking}
                                toggleBookingSuccessModal={this.props.toggleBookingSuccessModal}
                                lodgeId={this.props.lodgeId}
                                addBookingErrorMessage={this.props.addBookingErrorMessage}
                                setAddingBookingErrorMessage={this.props.setAddingBookingErrorMessage}
                                hasANPRFeature={this.props.hasANPRFeature}
                                accountId={this.props.accountId}
                                selectedDate={this.props.calendarDate}
                            />

                            <SuccessModal
                                className="mobile-success-modal"
                                isOpen={this.props.isAddBookingSuccessModalOpen}
                                closeModal={() => {
                                    this.props.toggleBookingSuccessModal(false);
                                    this.props.toggleBookingModal(false);
                                }}
                                importantInfo={t('myCalendar.addBooking.bookingSuccessImportantInfo')}
                                title={t('myCalendar.addBooking.bookingSuccessTitle')}
                                message={t('myCalendar.addBooking.bookingSuccessMessage')}
                                buttonText={t('general.ok')}
                            />
                            <SubletBookingInfoModal
                                isOpen={!!this.props.selectedCalendarEvent}
                                setSelectedCalendarEvent={this.props.setSelectedCalendarEvent}
                                selectedCalendarEvent={this.props.selectedCalendarEvent!}
                            />
                        </>
                    );
                }}
            </Translation>
        );
    }
}

export default MyCalendarPageMobile;
