import moment from 'moment';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Get, Post } from '../../Api/ApiService';
import { PostDownloadStatementCall } from '../../Api/Calls/PostDownloadStatementCall';
import { PostDownloadStatementPdfCall } from '../../Api/Calls/PostDownloadStatementPdfCall';
import { GetMyBankDetailsCall } from '../../Api/Calls/GetMyBankDetailsCall';
import { GetHaulfrynBankDetailsCall } from '../../Api/Calls/GetHaulfrynBankDetailsCall';
import { PostTransactionSearchCall } from '../../Api/Calls/PostTransactionSearchCall';
import { AppState } from '../../RootReducer';
import * as ActionTypes from './ActionTypes';

export const getTransactions = (): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
    var filter = getState().account.transactionFilter;
    dispatch({ type: ActionTypes.SEARCH_TRANSACTIONS_REQUEST, payload: true });

    Post<TransactionDTO>(new PostTransactionSearchCall(filter))
        .then((response) => {
            dispatch({ type: ActionTypes.SEARCH_TRANSACTIONS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ActionTypes.SEARCH_TRANSACTIONS_FAILURE, payload: error });
        });
};

export const updateTransactionFilter = (
    newFilter: TransactionFilterDTO
): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
    const selectedAccountId = getState().user.lodge.AccountId;
    const selectedAccountnumber = getState().user.lodge.AccountNo;
    newFilter.AccountId = selectedAccountId;
    newFilter.AccountNumber = selectedAccountnumber;
    dispatch({ type: ActionTypes.SET_TRANSACTION_FILTER, payload: newFilter });
    dispatch(getTransactions());
};

export const getMyBankDetails = (): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
    const lodge: SelectedLodgeDTO = getState().user.lodge;

    dispatch({ type: ActionTypes.GET_MY_BANK_DETAILS_REQUEST, payload: true });
    Get<MyBankDetailsDTO>(new GetMyBankDetailsCall(lodge.LodgeId.toString()))
        .then((response) => {
            dispatch({ type: ActionTypes.GET_MY_BANK_DETAILS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ActionTypes.GET_MY_BANK_DETAILS_FAILURE, payload: error });
        });
};

export const getHaulfrynBankDetails = (): ThunkAction<void, AppState, null, Action> => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_HAULFRYN_BANK_DETAILS_REQUEST, payload: true });
    Get<HaulfrynBankDetailsDTO>(new GetHaulfrynBankDetailsCall())
        .then((response) => {
            dispatch({ type: ActionTypes.GET_HAULFRYN_BANK_DETAILS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ActionTypes.GET_HAULFRYN_BANK_DETAILS_FAILURE, payload: error });
        });
};

export const downloadStatement = (): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
    const filter = getState().account.transactionFilter;
    const lodgeName = getState().user.lodge.LodgeName;
    const now = moment().format('DD MM YYYY');

    dispatch({ type: ActionTypes.DOWNLOAND_STATEMENT_REQUEST, payload: true });
    Post<ArrayBuffer>(new PostDownloadStatementCall(filter))
        .then((response) => {
            dispatch({ type: ActionTypes.DOWNLOAND_STATEMENT_SUCCESS, payload: false });

            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            const fileName = `${lodgeName + ' ' + now}.csv`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch((error) => {
            dispatch({ type: ActionTypes.DOWNLOAND_STATEMENT_FAILURE, payload: error });
        });
};

export const downloadPdfStatement = (): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {
    const filter = getState().account.transactionFilter;
    const lodgeName = getState().user.lodge.LodgeName;
    const now = moment().format('DD MM YYYY');

    dispatch({ type: ActionTypes.DOWNLOAND_PDF_STATEMENT_REQUEST, payload: true });
    Post<ArrayBuffer>(new PostDownloadStatementPdfCall(filter), {responseType: 'blob'})
        .then((response) => {
            dispatch({ type: ActionTypes.DOWNLOAND_PDF_STATEMENT_SUCCESS, payload: false });
            
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            const fileName = `${lodgeName + ' ' + now}.pdf`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch((error) => {
            dispatch({ type: ActionTypes.DOWNLOAND_PDF_STATEMENT_FAILURE, payload: error });
        });
};
