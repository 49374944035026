export function getAmount(amount: number | undefined): string {
    if (amount === undefined) {
        return '';
    }

    if (amount < 0) {
        return `-£${Math.abs(amount).toFixed(2)}`;
    } else {
        return `£${amount.toFixed(2)}`;
    }
}
