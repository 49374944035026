import { PostApiCall } from './ApiCall';

export class PostForgotPasswordEmailCall extends PostApiCall {
    constructor(userName: SendPasswordResetEmailDto) {
        const url = `account/sendForgottenPassword`;

        super(url);

        this.body = userName;
    }
}
