import { fileToUrl, urlToFile } from "../../../../Shared/Utilities/FileUtils";
import { hasValue, isValidDate } from "../../../../Shared/Utilities/Validation";

const SeasonPassFields: FormField<AddSeasonPassDTO>[] = [
    {
        id: 'firstname',
        type: 'text',
        maxLength: 50,
        labelTranslationKey: 'myGuests.passes.add.fields.firstname',
        placeholderTranslationKey: 'myGuests.passes.add.fields.firstname',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.FirstName,
        setter: (pass, value) => pass.FirstName = value,
        flexBasis: '33%'
    },
    {
        id: 'lastname',
        type: 'text',
        maxLength: 50,
        labelTranslationKey: 'myGuests.passes.add.fields.lastname',
        placeholderTranslationKey: 'myGuests.passes.add.fields.lastname',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.LastName,
        setter: (pass, value) => pass.LastName = value,
        flexBasis: '33%'
    },
    {
        id: 'dateofbirth',
        type: 'date',
        maxLength: 10,
        labelTranslationKey: 'myGuests.passes.add.fields.dateOfBirth',
        placeholderTranslationKey: 'myGuests.passes.add.fields.dateFormat',
        validator: (value) => isValidDate(value),
        getter: (pass) => pass.DateOfBirth,
        setter: (pass, value) => pass.DateOfBirth = value,
        flexBasis: '33%'
    },
    {
        id: 'email',
        type: 'email',
        maxLength: 255,
        labelTranslationKey: 'myGuests.passes.add.fields.email',
        placeholderTranslationKey: 'myGuests.passes.add.fields.email',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.Email,
        setter: (pass, value) => pass.Email = value,
        flexBasis: '66%',
        marginRight: '33%'
    },
    {
        id: 'phonenuumber',
        type: 'text',
        maxLength: 100,
        labelTranslationKey: 'myGuests.passes.add.fields.phoneNumber',
        placeholderTranslationKey: 'myGuests.passes.add.fields.phoneNumber',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.PhoneNumber,
        setter: (pass, value) => pass.PhoneNumber = value,
        flexBasis: '33%'
    },
    {
        id: 'passPhoto',
        type: 'photo',
        labelTranslationKey: 'Photo Upload',
        placeholderTranslationKey: 'Photo Upload',
        validator: (value) => hasValue(value),
        getter: (pass) => fileToUrl(pass.Photo),
        setter: (pass, value) => urlToFile(value).then(photo => pass.Photo = photo),
        flexBasis: '33%',
        marginLeft: '33%'
    }
];

export default SeasonPassFields;