import { GetApiCall } from "./ApiCall";

export class GetStripeMembershipCheckoutUrlCall extends GetApiCall {
    constructor(membershipBasketId: string) {
        let url: string = "Stripe/MembershipCheckoutUrl";
        
        let params: any = { membershipBasketId };

        super(url, params);
    }
}