import React, { useEffect, useState } from "react";
import "./MembershipPageStyles.scss";
import { hasValue, isValidEmail, isValidUKPhoneNumber, isTextOnly } from "../../../../Shared/Utilities/Validation";
import { withTranslation, WithTranslation } from "react-i18next";
import InputField from "../AddMembershipSubPages/FormInputs/InputField";
import { Entry } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import DropdownList from "../../../../Shared/Components/Presentational/DropdownList";
import { relative } from "path";
import { props } from "cypress/types/bluebird";
import Checkbox from "../../../../Shared/Components/Presentational/Checkbox";

interface MemberDetailsProps extends WithTranslation {
  onInput: (value: any, name: any, errors: string[]) => void;
  membershipForm: any;
  showErrors: boolean;
  isReferred: boolean;
  remainingMembership: number;
  transferMemberText: ContentfulContentObject;
  reallocateMemberText: ContentfulContentObject;
  memberDetailText: ContentfulContentObject;
  manageMemberText: ContentfulContentObject;
  ownerMembershipAvailability: OwnerMembershipAvailabilityDTO;
  usedMembership: number;
  availableMemberships: number;
  transferredMemberships: number;
  referWMemberships: boolean;
  referWOutMemberships: boolean;
}

const MemberDetails: React.FC<MemberDetailsProps> = (props: MemberDetailsProps) => {
  const { t } = props;
  const [input, setInput] = useState(null);

  const getReferredDropdownList = (remainingMemberships: number): Array<DropdownListOption> => {
    let minValue = props.isReferred ? (props.transferredMemberships - props.availableMemberships) : (props.referWOutMemberships ? 0 : 1);
    let list: Array<DropdownListOption> = new Array();
    for (let i = minValue; i < (remainingMemberships + 1); i++) {
      list.push({ value: i, label: i.toString() });
    }
    return list;
  }

  const getSelectedOption = (): DropdownListOption => {
    let selectedOption: DropdownListOption | null = null;
    if (props.isReferred) {
      selectedOption = { value: props.membershipForm.transferredMemberships.value, label: props.membershipForm.transferredMemberships.value.toString() }
    } else {
      if (props.referWOutMemberships) {
        selectedOption = { value: "0", label: "0" }
      } else {
        selectedOption = { value: "1", label: "1" }
      }
    }

    if (selectedOption != null) {
      props.onInput(selectedOption.value, "transferredMemberships", []);
      return selectedOption;
    } else {
      return { value: "0", label: "0" }
    }
  }

  return (
    <>
      <div className="desktopVersion">
        <div className='row' style={{ marginBottom: "25px", marginTop: "50px" }}>
          <div className='float-left'>
            <h2 id="pdTitle" style={{ margin: "0px" }}>{props.isReferred ? props.manageMemberText.title : props.memberDetailText.title}</h2>
            <br />
            <div>
              {props.isReferred ? documentToReactComponents(props.manageMemberText.content) : documentToReactComponents(props.memberDetailText.content)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column" >
            <InputField
              name="firstName"
              formInput={props.membershipForm.firstName}
              onInput={props.onInput}
              disabled={props.isReferred}
              labeltranslationKey="referMembership.memberDetails.fields.firstname"
              containerClass="input"
              showErrors={props.showErrors}
              tabindex={1}
              validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
            />

            <InputField
              name="email"
              formInput={props.membershipForm.email}
              onInput={props.onInput}
              disabled={props.isReferred}
              labeltranslationKey="referMembership.memberDetails.fields.emailAddress"
              containerClass="input"
              showErrors={props.showErrors}
              tabindex={3}
              validators={[{ name: "hasValue", validator: hasValue }, { name: "isEmail", validator: isValidEmail }]}
            />
          </div>

          <div className="column">
            <InputField
              name="lastName"
              formInput={props.membershipForm.lastName}
              onInput={props.onInput}
              disabled={props.isReferred}
              labeltranslationKey="referMembership.memberDetails.fields.lastname"
              containerClass="input"
              showErrors={props.showErrors}
              tabindex={2}
              validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
            />

            <InputField
              name="phoneNumber"
              formInput={props.membershipForm.phoneNumber}
              onInput={props.onInput}
              disabled={props.isReferred}
              labeltranslationKey="referMembership.memberDetails.fields.phoneNumber"
              containerClass="input"
              showErrors={props.showErrors}
              tabindex={4}
              validators={[{ name: "hasValue", validator: hasValue }, { name: "isPhoneNo", validator: isValidUKPhoneNumber }]}
            />
          </div>
        </div>
        {props.referWMemberships && (
          <div className='row' style={{ marginBottom: "25px" }}>
            <div className='float-left'>
              <h2 id="pdTitle" style={{ margin: "0px", marginTop: "25px" }}>{props.isReferred ? props.reallocateMemberText.title : props.transferMemberText.title}</h2>
              <br />
              <div>
                {props.isReferred ? documentToReactComponents(props.reallocateMemberText.content) : documentToReactComponents(props.transferMemberText.content)}
              </div>

              <div>
                {(props.transferredMemberships && props.transferredMemberships > 0) ? (
                  props.transferredMemberships === 1 ? (
                    <p>You have previously allocated {props.transferredMemberships} club membership to {props.membershipForm.firstName.value + " " + props.membershipForm.lastName.value}.</p>
                  ) : (
                    <p>You have previously allocated {props.transferredMemberships} club memberships to {props.membershipForm.firstName.value + " " + props.membershipForm.lastName.value} and you have {props.isReferred ? props.remainingMembership : props.ownerMembershipAvailability && props.ownerMembershipAvailability.available} club memberships remaining.</p>
                  )
                ) : undefined}
              </div>

              <div style={{ marginTop: "0", width: "100%", height: "10%", display: "flex" }}>
                <p style={{ marginTop: '15px' }}>{props.isReferred ? "Change allocation to " : "Allocate"}</p>&nbsp;&nbsp;
                {props.membershipForm && props.membershipForm.transferredMemberships && (
                  <DropdownList
                    className="referred-drop-down"
                    options={getReferredDropdownList(props.isReferred ? props.remainingMembership : props.ownerMembershipAvailability.available)}
                    selectedOption={getSelectedOption()}
                    onSelectionChanged={(newValue) => {
                      props.onInput(newValue.value, "transferredMemberships", [])
                    }}
                  />
                )}
                &nbsp;&nbsp;
                <p style={{ marginTop: '15px' }}>of my <b>{props.isReferred ? props.remainingMembership : props.ownerMembershipAvailability && props.ownerMembershipAvailability.available}</b> remaining Club memberships.</p>
              </div>
              <div style={{ float: "left", marginTop: "-15px" }}>
                <br />
                <div>
                  {(props.showErrors && (props.membershipForm.transferredMemberships.errors && props.membershipForm.transferredMemberships.errors.length > 0)) &&
                    <p style={{ textAlign: "left", color: "red" }}>{props.membershipForm.transferredMemberships && t("addMembership.errors." + props.membershipForm.transferredMemberships.errors[0])}</p>
                  }
                </div>
                <div>
                  {props.availableMemberships < props.transferredMemberships && (
                    props.availableMemberships > 0 ? (
                      <p><b>Note:</b> As some of the club memberships that you have allocated might be in use by the user, you can only reduce you allocation by the amount still available.</p>
                    ) : (
                      props.transferredMemberships > 0 ? (
                        <p><b>Note:</b> As all of the club memberships that you have allocated them are in use, you cannot reduce the allocation until they become available</p>
                      ) : (
                        <p><b>Note:</b> As the club membership that you have allocated them is in use, you cannot reduce the allocation until it becomes available</p>
                      )
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mobileVersion">
        <div className='row' style={{ marginBottom: "25px", marginTop: "50px" }}>
          <div className='float-left'>
            <h2 id="pdTitle" style={{ margin: "0px" }}>{props.isReferred ? props.manageMemberText.title : props.memberDetailText.title}</h2>
          </div>
          <br />
          <div style={{ marginTop: "25px" }}>
            {props.isReferred ? documentToReactComponents(props.manageMemberText.content) : documentToReactComponents(props.memberDetailText.content)}
          </div>
        </div>
        <div className="details">
          <InputField
            name="firstName"
            formInput={props.membershipForm.firstName}
            onInput={props.onInput}
            disabled={props.isReferred}
            labeltranslationKey="referMembership.memberDetails.fields.firstname"
            containerClass="input"
            showErrors={props.showErrors}
            validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
          />

          <InputField
            name="lastName"
            formInput={props.membershipForm.lastName}
            onInput={props.onInput}
            disabled={props.isReferred}
            labeltranslationKey="referMembership.memberDetails.fields.lastname"
            containerClass="input"
            showErrors={props.showErrors}
            validators={[{ name: "hasValue", validator: hasValue }, { name: "isTextOnly", validator: isTextOnly }]}
          />

          <InputField
            name="email"
            formInput={props.membershipForm.email}
            onInput={props.onInput}
            disabled={props.isReferred}
            labeltranslationKey="referMembership.memberDetails.fields.emailAddress"
            containerClass="input"
            showErrors={props.showErrors}
            validators={[{ name: "hasValue", validator: hasValue }, { name: "isEmail", validator: isValidEmail }]}
          />

          <InputField
            name="phoneNumber"
            formInput={props.membershipForm.phoneNumber}
            onInput={props.onInput}
            disabled={props.isReferred}
            labeltranslationKey="referMembership.memberDetails.fields.phoneNumber"
            containerClass="input"
            showErrors={props.showErrors}
            validators={[{ name: "isPhoneNo", validator: isValidUKPhoneNumber }]}
          />

          {props.referWMemberships && (
            <div className='float-left'>
              <h2 id="pdTitle" style={{ margin: "0px", marginTop: "25px" }}>{props.isReferred ? props.reallocateMemberText.title : props.transferMemberText.title}</h2>
              <div style={{ marginTop: "10px" }}>
                {props.isReferred ? documentToReactComponents(props.reallocateMemberText.content) : documentToReactComponents(props.transferMemberText.content)}
              </div>

              <div style={{ marginBottom: "-10px" }}>
                {(props.transferredMemberships && props.transferredMemberships > 0) ? (
                  props.transferredMemberships === 1 ? (
                    <p>You have previously allocated {props.transferredMemberships} club memberships to {props.membershipForm.firstName.value + " " + props.membershipForm.lastName.value} and you have {props.isReferred ? props.remainingMembership : props.ownerMembershipAvailability && props.ownerMembershipAvailability.available} club memberships remaining.</p>
                  ) : (
                    <p>You have previously allocated {props.transferredMemberships} club memberships to {props.membershipForm.firstName.value + " " + props.membershipForm.lastName.value} and you have {props.isReferred ? props.remainingMembership : props.ownerMembershipAvailability && props.ownerMembershipAvailability.available} club memberships remaining.</p>
                  )
                ) : undefined}
              </div>

              <p style={{ display: 'inline' }}>{props.isReferred ? "Change allocation to " : "Allocate"}</p>
              {props.membershipForm && props.membershipForm.transferredMemberships && (
                <DropdownList
                  className="referred-drop-down"
                  options={getReferredDropdownList(props.isReferred ? props.remainingMembership : props.ownerMembershipAvailability.available)}
                  selectedOption={getSelectedOption()}
                  onSelectionChanged={(newValue) => {
                    props.onInput(newValue.value, "transferredMemberships", [])
                  }}
                />
              )}
              <p style={{ position: "relative", display: "inline" }}>of my <b>{props.isReferred ? props.remainingMembership : props.ownerMembershipAvailability && props.ownerMembershipAvailability.available}</b> remaining club memberships.</p>

              <div style={{ clear: "both", marginTop: "15px" }}>
                {(props.showErrors && (props.membershipForm.transferredMemberships.errors && props.membershipForm.transferredMemberships.errors.length > 0)) &&
                  <p style={{ textAlign: "left", color: "red" }}>{props.membershipForm.transferredMemberships && t("addMembership.errors." + props.membershipForm.transferredMemberships.errors[0])}</p>
                }
                <div>
                  {props.availableMemberships < props.transferredMemberships && (
                    props.availableMemberships > 0 ? (
                      <p><b>Note:</b> As some of the club memberships that you have allocated might be in use by the user, you can only reduce you allocation by the amount still available.</p>
                    ) : (
                      props.transferredMemberships > 0 ? (
                        <p><b>Note:</b> As all of the club memberships that you have allocated them are in use, you cannot reduce the allocation until they become available</p>
                      ) : (
                        <p><b>Note:</b> As the club membership that you have allocated them is in use, you cannot reduce the allocation until it becomes available</p>
                      )
                    )
                  )}
                </div>
              </div>

            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withTranslation()(MemberDetails);