import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Get } from '../../../Api/ApiService';
import { PrimaryButton, SecondaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { Modal, ModalFooter, ModalHeader, ModalProps } from '../../../Shared/Components/Presentational/Modal';
import './MyMembershipsCreatePromptModal.scss';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import * as Routes from "../../../Constants/Routes";
import { Link, Redirect } from 'react-router-dom';
import { Entry } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface MyMembershipsCreatePromptModalProps extends ModalProps {
  isOpen: boolean;
  setDontShowAgainMembershipModal: () => void;
  updateMembershipFormPage: (page: number) => void;
  clearMembershipForm: () => void;
  modalText: ContentfulContentObject;
}

const MyMembershipsCreatePromptModal: React.FC<MyMembershipsCreatePromptModalProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(props.isOpen);

  const handleClick1 = async () => {
    props.clearMembershipForm();
    props.updateMembershipFormPage(0);
  }

  const handleClick2 = async () => {
    setOpen(false);
  }

  const handleClick3 = async () => {
    setOpen(false);
    props.setDontShowAgainMembershipModal();
  }

  const formContents = (
    <div className='button-container'>
      <div className='container'>
        <ModalHeader text={t("myMembershipsModal.title")} />
        <div>
          {documentToReactComponents(props.modalText.content)}
        </div>
        <div className='button-center-mobile'>
          <Link to={Routes.AddMembership}>
            <PrimaryButton
              id="yes"
              text={t("general.yes")}
              className="footer-button"
              onClick={handleClick1}
              margin={"10px"}
            />
          </Link>
          <SecondaryButton
            id="no"
            text={t("general.no")}
            className="footer-button"
            onClick={handleClick2}
            margin={"10px"}
          />
          <SecondaryButton
            id="dontShowAgain"
            text={t("myMembershipsModal.dontShowAgain")}
            className="footer-button"
            onClick={handleClick3}
            margin={"10px"}
          />
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Modal
        isOpen={open}
        className={classNames("owner-contact-details-modal", props.className)}
      >
        <Desktop>
          <form>{formContents}</form>
        </Desktop>
        <Mobile>
          <form className='owner-contact-details-modal-mobile-form'>
            {formContents}
          </form>
        </Mobile>
      </Modal>
    </>
  );
};


export default MyMembershipsCreatePromptModal;