import UserRoles from "../../Constants/UserRoles";
import ContentfulClient from  "../ContentfulClient";
import { TagsFilter } from "../TagsFilter";

export async function  GetImportantInformationCall(parkCode: string,userRoles: UserRoleDTO[]) {
    let filter = {
        content_type: 'park',
        'fields.code': parkCode,
        select:[
            'fields.htmlContentBlocks',
        ]
    }
    const result =  await ContentfulClient.getEntries<ContentfulPark>(filter);

    if (result.errors) {
        throw {message: "GetImportantInformationCall returned error/s", errors: result.errors} 
    }

    let indexToBeRemoved: Array<string> = [];
    if(userRoles.length > 0) {
        result.items[0].fields.htmlContentBlocks.forEach((content)=>{
            if(new TagsFilter().removeContent(content,userRoles)){
                indexToBeRemoved.push(content.sys.id);
            }
        });
    }

    if (indexToBeRemoved.length !== 0) {
        indexToBeRemoved.forEach((val)=>{
            result.items[0].fields.htmlContentBlocks.forEach((content,index)=>{
                if(content.sys.id === val){
                    result.items[0].fields.htmlContentBlocks.splice(index,1);        
                }
            });
        })
    }

    return result.items[0].fields.htmlContentBlocks;
}