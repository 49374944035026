import React, { Component } from "react";
import './Labels.scss';

interface LabelProps {
    text: string;
    className: string;
}

interface ClickableLabelProps extends LabelProps {
    clickAction: () => void;
}

export class ClickableLabel extends Component<ClickableLabelProps> {
    
    render() {
        return (
            <div className={this.props.className} onClick={this.props.clickAction}>
                {this.props.children}
                <label>{this.props.text}</label>
            </div>
        )
    };
}