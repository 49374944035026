import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import RadioButtonGroup from '../../../Shared/Components/Presentational/RadioButtonGroup';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import { hasValue } from '../../../Shared/Utilities/Validation';
import { setParQInforation, updateParQForm } from '../../Redux/ActionCreators';
import * as ActionTypes from '../../Redux/ActionTypes';
import * as Routes from "../../../Constants/Routes";
import { withTranslation, WithTranslation } from 'react-i18next';

interface ParQFormProps extends WithTranslation {
  setMembershipKey: (membershipKey: string | null) => void;
  membershipKey: string;
  parQFormDTO: ParQFormDTO;
  parQFormInputs: IParQFormInputs;
  updateParQFormInputs: (fieldName: any, value: any, errors: string[]) => void;
  updateParQFormDTO: (dto: ParQFormDTO) => void;
  setParQInformation: (dto: ParQFormDTO) => void;
  setParQInformationSuccess: boolean;
  setParQInformationFailure: boolean;
}

const useQuery = () => new URLSearchParams(useLocation().search);

const ParQForm: React.FC<ParQFormProps> = (props) => {
  const { t } = props;
  const [showErrors, setShowErrors] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [membershipKey, setMembershipKey] = useState<FormInput>({ value: "", errors: ["hasValue"] });
  const query = useQuery();
  const key = query.get("key");
  props.setMembershipKey(key);

  const createParQFormDTO = (form: FormInput[]) => {
    let formDTO = {} as ParQFormDTO;
    if (key) {
      formDTO.membershipKey = props.membershipKey;
    } else {
      formDTO.membershipNumber = membershipKey.value;
    }
    for (let input in form) {
      if (input !== "membershipKey") {
        formDTO = { ...formDTO, [input]: form[input].value };
      }
    }
    return formDTO;
  }

  const handleClick = () => {
    const form = props.parQFormInputs as FormInput[];
    let invalid = false;
    for (let formObject in form) {
      if (form[formObject] && form[formObject].errors.length > 0) {
        invalid = true;
        break;
      }
    }
    if (membershipKey.value === "" && !key) {
      invalid = true;
    }
    if (invalid) {
      setShowErrors(true);
      return;
    } else {
      setShowErrors(false);
      setIsSubmitted(true);
    }
    const dto = createParQFormDTO(props.parQFormInputs as FormInput[]);
    props.updateParQFormDTO(dto);
    props.setParQInformation(dto);
  }

  const updateValue = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    let value = null;
    if (e.target.value == "true") {
      value = true;
    } else if (e.target.value == "false") {
      value = false;
    } else {
      value = e.target.value;
    }

    if (e.target.name !== "membershipKey") {
      const errors: string[] = [];
      if (e.target.name != "loseBalance") {
        if (!hasValue(e.target.value)) {
          errors.push("hasValue");
        }
      }
      props.updateParQFormInputs(e.target.name, value, errors);
    } else {
      const errors: string[] = [];
      if (!hasValue(e.target.value)) {
        errors.push("hasValue");
      }
      setMembershipKey({ value: e.target.value, errors: errors });
    }
  }

  return (
    <div className='parQ-form'>
      <Widget
        position={{
          columnIndex: 1,
          columnSpan: 12,
          rowIndex: 3,
          rowSpan: 9,
        }}
      >
        <div style={{ margin: "25px" }}>
          <div className='row' style={{ marginBottom: "25px" }}>
            <div className='float-left'>
              <h1 style={{ margin: "20px", color: "black" }}>{t("parQForm.title")}</h1>
            </div>
          </div>
          {!isSubmitted && !props.setParQInformationSuccess ? (
            <>
              {!key && (
                <div className='row' style={{ marginBottom: "25px" }}>
                  <label htmlFor='membershipKey'>Membership Number </label>
                  <input
                    type='number'
                    name='membershipKey'
                    id='membershipKey'
                    onChange={updateValue}
                    className="add-form-input form-input"
                  />
                  <br />
                  {showErrors && membershipKey && membershipKey.errors.length > 0 && (
                    <p style={{ color: "red" }}>{t("addMembership.errors.hasValue")}</p>
                  )}
                </div>
              )}
              <div className='row' style={{ marginBottom: "25px" }}>
                <p>{t("parQForm.initialWarning")}</p>
              </div>
              <div className='row' style={{ marginBottom: "25px" }}>
                <div className='parq-column'>
                  <label htmlFor='heartConditionHistory'>{t("parQForm.heartConditionQuestion")}</label>
                  <br />
                </div>
                <div className='parq-column'>
                  <RadioButtonGroup
                    name="heartConditionHistory"
                    options={[{ value: true, label: "general.yes" }, { value: false, label: "general.no" }]}
                    handleInputChange={updateValue}
                    selectedOption={props.parQFormInputs["heartConditionHistory"] && props.parQFormInputs["heartConditionHistory"].value}
                  />
                  <br />
                  {showErrors && props.parQFormInputs["heartConditionHistory"] && props.parQFormInputs["heartConditionHistory"].errors.length > 0 && (
                    <p style={{ color: "red" }}>{t("addMembership.errors.hasValue")}</p>
                  )}
                </div>
              </div>
              <div className='row' style={{ marginBottom: "25px" }}>
                <div className='parq-column'>
                  <label htmlFor='chestPainActivity'>{t("parQForm.chestPainActivityQuestion")}</label>
                  <br />
                </div>
                <div className='parq-column'>
                  <RadioButtonGroup
                    name="chestPainActivity"
                    options={[{ value: true, label: "general.yes" }, { value: false, label: "general.no" }]}
                    handleInputChange={updateValue}
                    selectedOption={props.parQFormInputs["chestPainActivity"] && props.parQFormInputs["chestPainActivity"].value}
                  />
                  <br />
                  {showErrors && props.parQFormInputs["chestPainActivity"] && props.parQFormInputs["chestPainActivity"].errors.length > 0 && (
                    <p style={{ color: "red" }}>{t("addMembership.errors.hasValue")}</p>
                  )}
                </div>
              </div>
              <div className='row' style={{ marginBottom: "25px" }}>
                <div className='parq-column'>
                  <label htmlFor='chestPainNoActivity' >{t("parQForm.chestPainNoActivityQuestion")}</label>
                  <br />
                </div>
                <div className='parq-column'>
                  <RadioButtonGroup
                    name="chestPainNoActivity"
                    options={[{ value: true, label: "general.yes" }, { value: false, label: "general.no" }]}
                    handleInputChange={updateValue}
                    selectedOption={props.parQFormInputs["chestPainNoActivity"] && props.parQFormInputs["chestPainNoActivity"].value}
                  />
                  <br />
                  {showErrors && props.parQFormInputs["chestPainNoActivity"] && props.parQFormInputs["chestPainNoActivity"].errors.length > 0 && (
                    <p style={{ color: "red" }}>{t("addMembership.errors.hasValue")}</p>
                  )}
                </div>
              </div>
              <div className='row' style={{ marginBottom: "25px" }}>
                <div className='parq-column'>
                  <label htmlFor='loseBalance' >{t("parQForm.loseBalanceQuestion")}</label>
                  <br />
                </div>
                <div className='parq-column'>
                  <RadioButtonGroup
                    name="loseBalance"
                    options={[{ value: true, label: "general.yes" }, { value: false, label: "general.no" }]}
                    handleInputChange={updateValue}
                    selectedOption={props.parQFormInputs["loseBalance"] && props.parQFormInputs["loseBalance"].value}
                  />
                  <br />
                  {showErrors && props.parQFormInputs["loseBalance"] && props.parQFormInputs["loseBalance"].errors.length > 0 && (
                    <p style={{ color: "red" }}>{t("addMembership.errors.hasValue")}</p>
                  )}
                </div>
              </div>
              <div className='row' style={{ marginBottom: "25px" }}>
                <div className='parq-column'>
                  <label htmlFor='boneJointProblem' >{t("parQForm.boneJointProblemQuestion")}</label>
                  <br />
                </div>
                <div className='parq-column'>
                  <RadioButtonGroup
                    name="boneJointProblem"
                    options={[{ value: true, label: "general.yes" }, { value: false, label: "general.no" }]}
                    handleInputChange={updateValue}
                    selectedOption={props.parQFormInputs["boneJointProblem"] && props.parQFormInputs["boneJointProblem"].value}
                  />
                  <br />
                  {showErrors && props.parQFormInputs["boneJointProblem"] && props.parQFormInputs["boneJointProblem"].errors.length > 0 && (
                    <p style={{ color: "red" }}>{t("addMembership.errors.hasValue")}</p>
                  )}
                </div>
              </div>
              <div className='row' style={{ marginBottom: "25px" }}>
                <div className='parq-column'>
                  <label htmlFor='bloodPressureHeartMedication' >{t("parQForm.bloodPressureHeartMedication")}</label>
                  <br />
                </div>
                <div className='parq-column'>
                  <RadioButtonGroup
                    name="bloodPressureHeartMedication"
                    options={[{ value: true, label: "general.yes" }, { value: false, label: "general.no" }]}
                    handleInputChange={updateValue}
                    selectedOption={props.parQFormInputs["bloodPressureHeartMedication"] && props.parQFormInputs["bloodPressureHeartMedication"].value}
                  />
                  <br />
                  {showErrors && props.parQFormInputs["bloodPressureHeartMedication"] && props.parQFormInputs["bloodPressureHeartMedication"].errors.length > 0 && (
                    <p style={{ color: "red" }}>{t("addMembership.errors.hasValue")}</p>
                  )}
                </div>
              </div>
              <div className='row' style={{ marginBottom: "25px" }}>
                <div className='parq-column'>
                  <label htmlFor='otherReason' >{t("parQForm.otherReasonQuestion")}</label>
                  <br />
                </div>
                <div className='parq-column'>
                  <RadioButtonGroup
                    name="otherReason"
                    options={[{ value: true, label: "general.yes" }, { value: false, label: "general.no" }]}
                    handleInputChange={updateValue}
                    selectedOption={props.parQFormInputs["otherReason"] && props.parQFormInputs["otherReason"].value}
                  />
                  <br />
                  {showErrors && props.parQFormInputs["otherReason"] && props.parQFormInputs["otherReason"].errors.length > 0 && (
                    <p style={{ color: "red" }}>{t("addMembership.errors.hasValue")}</p>
                  )}
                </div>
              </div>
              <div className='row' style={{ marginBottom: "25px" }}>
                <div className='parq-column'>
                  <label htmlFor='otherReasonComment' >{t("parQForm.otherReasonComment")}</label>
                  <br />
                </div>
                <div className='parq-column'>
                  <textarea
                    id='otherReasonComment'
                    rows={6}
                    onChange={updateValue}
                    name="otherReasonComment"
                    value={props.parQFormInputs["otherReasonComment"] && props.parQFormInputs["otherReasonComment"].value}
                  ></textarea>
                  <br />
                </div>
              </div>
              <div className='row' style={{ marginBottom: "25px" }}>
                <p><b>{t("parQForm.answerYesBold")}</b>{t("parQForm.answerYesText")}</p>
                <p><b>{t("parQForm.answerNoBold")}</b>{t("parQForm.answerNoText")}</p>
                <p>{t("parQForm.readUnderstoodQuestionnaire")}</p>
              </div>
              <div className='float-right'>
                <PrimaryButton
                  text={t("parQForm.confirmSave")}
                  onClick={handleClick}
                />
                {props.setParQInformationFailure && (
                  <p style={{ color: "red" }} >{t("parQForm.failedToSend")}</p>
                )}
              </div>
            </>
          ) : (
            <>
              <p>{t("parQForm.onceSubmittedMessage")}</p>
              <Link to={Routes.Login}>
                <PrimaryButton
                  text={t("parQForm.toLogin")}
                />
              </Link>
            </>
          )}
        </div>
      </Widget >
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    membershipKey: state.parQForm.membershipKey,
    parQFormDTO: state.parQForm.parQFormDTO,
    parQFormInputs: state.parQForm.parQFormInputs,
    setParQInformationSuccess: state.parQForm.setParQInformationSuccess,
    setParQInformationFailure: state.parQForm.setParQInformationFailure,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setMembershipKey: (membershipKey: string | null) => {
      dispatch({ type: ActionTypes.SET_MEMBERSHIP_KEY, payload: membershipKey });
    },
    updateParQFormInputs: (fieldName: any, value: any, errors: string[]) => {
      dispatch(updateParQForm(fieldName, value, errors));
    },
    updateParQFormDTO: (dto: ParQFormDTO) => {
      dispatch({ type: ActionTypes.UPDATE_PAR_Q_FORM_DTO, payload: dto });
    },
    setParQInformation: (dto: ParQFormDTO) => {
      dispatch(setParQInforation(dto));
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ParQForm));