import { PostApiCall } from "./ApiCall";

export class PostCreateMembershipBasket extends PostApiCall {
    constructor(membershipBasket: MembershipBasketDTO) {
        let url: string = "Membership/CreateMembershipBasket";
    
        super(url);

        this.body = membershipBasket;
    }
}