import { PostApiCall } from "./ApiCall";

export class PostFreshDeskTicketReplyCall extends PostApiCall {
  constructor(ticketId: string, message: string) {
    let url: string = `requestsAndQueries/tickets/${ticketId}/reply`;

    super(url);

    this.body = { MessageContent: message };
  }
}
