import { EntryCollection } from "contentful";
import { Action } from "redux";

export const SEARCH_EVENTS_REQUEST = 'SEARCH_EVENTS_REQUEST';
export const SEARCH_EVENTS_SUCCESS = 'SEARCH_EVENTS_SUCCESS';
export const SEARCH_EVENTS_FAILURE = 'SEARCH_EVENTS_FAILURE';
export const SET_IS_EVENT_DETAILS_MODAL_OPEN = 'SET_IS_EVENT_DETAILS_MODAL_OPEN';
export const SEARCH_OFFERS_REQUEST = 'SEARCH_OFFERS_REQUEST';
export const SEARCH_OFFERS_SUCCESS = 'SEARCH_OFFERS_SUCCESS';
export const SEARCH_OFFERS_FAILURE = 'SEARCH_OFFERS_FAILURE';
export const UPDATE_CALENDAR_WIDGET_ITEMS = 'UPDATE_CALENDAR_WIDGET_ITEMS';
export const SET_SELECTED_CALENDAR_WIDGET = 'SET_SELECTED_CALENDAR_WIDGET';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_IS_ADD_BOOKING_MODAL_OPEN = 'SET_IS_ADD_BOOKING_MODAL_OPEN';
export const ADD_BOOKING_REQUEST = 'ADD_BOOKING_REQUEST';
export const ADD_BOOKING_SUCCESS = 'ADD_BOOKING_SUCCESS';
export const ADD_BOOKING_FAILURE = 'ADD_BOOKING_FAILURE';
export const SET_IS_ADD_BOOKING_SUCCES_MODAL_OPEN = 'SET_IS_ADD_BOOKING_SUCCESS_MODAL_OPEN';
export const SET_ADD_BOOKING_ERROR_MESSAGE = 'SET_ADD_BOOKING_ERROR_MESSAGE';

export interface SearchEventsAction extends Action<typeof SEARCH_EVENTS_REQUEST> {
    payload: boolean;
}

export interface SearchEventsSuccessAction extends Action<typeof SEARCH_EVENTS_SUCCESS> {
    payload: EntryCollection<ContentfulEvent>;
}

export interface SearchEventsFailureAction extends Action<typeof SEARCH_EVENTS_FAILURE> {
    payload: any;
}

interface SetIsEventDetailsModalOpenAction extends Action<typeof SET_IS_EVENT_DETAILS_MODAL_OPEN> {
    payload: boolean;
}

interface SearchOffersRequestAction extends Action<typeof SEARCH_OFFERS_REQUEST> {
    payload: boolean;
}

interface SearchOffersSuccessAction extends Action<typeof SEARCH_OFFERS_SUCCESS> {
    payload: EntryCollection<ContentfulOffer>;
}

interface SearchOffersFailureAction extends Action<typeof SEARCH_OFFERS_FAILURE> {
    payload: any;
}

export interface SetSelectedCalendarWidgetAction extends Action<typeof SET_SELECTED_CALENDAR_WIDGET> {
    payload: SelectedCalendarWidgetOptions;
}

export interface SetPageNumberAction extends Action<typeof SET_PAGE_NUMBER> {
    payload: number;
}

interface SetIsAddBookingModalOpenAction extends Action<typeof SET_IS_ADD_BOOKING_MODAL_OPEN> {
    payload: boolean;
}

interface AddBookingRequestAction extends Action<typeof ADD_BOOKING_REQUEST> {
    payload: boolean;
}

interface AddBookingSuccessAction extends Action<typeof ADD_BOOKING_SUCCESS> {
    payload: boolean;
}

interface AddBookingFailureAction extends Action<typeof ADD_BOOKING_FAILURE> {
    payload: any;
}

interface SetIsAddBookingSuccessModalOpenAction extends Action<typeof SET_IS_ADD_BOOKING_SUCCES_MODAL_OPEN> {
    payload: boolean;
}

interface SetAddBookingErrorMessage extends Action<typeof SET_ADD_BOOKING_ERROR_MESSAGE> {
    payload: any
}

export type CalendarActionTypes = SearchEventsAction | SearchEventsSuccessAction | SearchEventsFailureAction
    | SetIsEventDetailsModalOpenAction | SearchOffersRequestAction | SearchOffersSuccessAction | SearchOffersFailureAction | SetSelectedCalendarWidgetAction
    | SetPageNumberAction | SetIsAddBookingModalOpenAction | AddBookingRequestAction | AddBookingSuccessAction | AddBookingFailureAction | SetIsAddBookingSuccessModalOpenAction | SetAddBookingErrorMessage;

export type SelectedCalendarWidgetOptions = 'events' | 'offers';