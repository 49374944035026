import React, { Component, FormEvent } from 'react';
import { ImageBackground } from '../../../Shared/Components/Presentational/Backgrounds';
import { SecondaryButton, OutlineButton, BackLinkButton } from '../../../Shared/Components/Presentational/Buttons';
import { ButtonContainer } from '../../../Shared/Components/Presentational/Containers';
import { WithTranslation, withTranslation } from 'react-i18next';
import './Login.scss';
import { Redirect } from 'react-router-dom';
import * as Routes from '../../../Constants/Routes';

interface WelcomePageProps extends WithTranslation {
    goToLoginClick: () => void;
}

interface WelcomePageState {
    isRegister: boolean;
}

class WelcomePageMobile extends Component<WelcomePageProps> {
    constructor(props: WelcomePageProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public state: WelcomePageState = {
        isRegister: false
    };

    private handleSubmit(e: FormEvent) {
        e.preventDefault();

        this.props.goToLoginClick();
    }

    render() {
        const { t } = this.props;

        if (this.state.isRegister) {
            return <Redirect to={Routes.Register} />;
        }
        return (
            <ImageBackground src="/images/mobile-login-image.svg" containerClassName="welcome-background">
                <BackLinkButton
                    href={t('login.backUrl')}
                    text={t('login.back')}
                    className="login-back-mobile"
                ></BackLinkButton>
                <form onSubmit={this.handleSubmit}>
                    <h1>{t('login.welcomeTitle')}</h1>
                    <p className="login-welcome-message">{t('login.welcomeMessage')}</p>
                    <ButtonContainer>
                        <SecondaryButton type="submit" text={t('login.loginButton')} />
                        <OutlineButton
                            text={t('login.registerButton')}
                            onClick={() => this.setState({ isRegister: true })}
                        />
                    </ButtonContainer>
                </form>
            </ImageBackground>
        );
    }
}

export default withTranslation()(WelcomePageMobile);
