import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Entry } from 'contentful';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Get } from '../../../../Api/ApiService';
import { PrimaryButton, SecondaryButton } from '../../../../Shared/Components/Presentational/Buttons';
import { Desktop, Mobile } from '../../../../Shared/Components/Presentational/ResponsiveViews';
import { NativeDatePicker } from '../../../../Shared/Components/Presentational/NativeDatePicker';
import "./RenewMembershipStyles.scss";
import { ClickableIcon } from '../../../../Shared/Components/Presentational/Icons';
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { GetPhotoByMemberIdCall } from '../../../../Api/Calls/GetPhotoByMemberIdCall';
import Checkbox from '../../../../Shared/Components/Presentational/Checkbox';

interface RenewalSummaryProps extends WithTranslation {
  memberId: string;
  memberName: string;
  memberEmail: string;
  membershipTypeName: string;
  termDuration: string;
  startDate: Date;
  termFormula: string;
  onStartDateUpdate: (date: Date) => void;
  onBackButton: () => void;
  onConfirm: () => void;
  onEditMemberCheckChange: (value: boolean) => void;
  fileUrl: string | undefined;
  minDatePickerDate: Date;
}

const RewewalSummary: React.FC<RenewalSummaryProps> = (props) => {
  const { t } = props;
  const [editMemberChecked, setEditMemberChecked] = useState<boolean>(false);

  const updateValueEditMemberCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setEditMemberChecked(true);
      props.onEditMemberCheckChange(true)
    } else {
      setEditMemberChecked(false);
      props.onEditMemberCheckChange(false);
    }
  }

  const dayPlus30 = (date: Date) => {
    let newDate = new Date(date);
    newDate.setDate(date.getDate() + 30);
    return newDate;
  }

  return (
    <>
      <div style={{ display: "block", height: "75px" }}>
        <ClickableIcon
          icon={faArrowAltCircleLeft}
          clickAction={props.onBackButton}
          className="back-button"
          size={"2x"}
        />
      </div>

      <div>
        <Desktop>
          <div className='desktopVersion'>
            <div className='row' style={{ marginBottom: "25px" }}>
              <div className='float-left'>
                <h2 style={{ margin: "0px" }}>{"Renew Membership Summary"}</h2>
              </div>
            </div>

            <div>
              <div id="membership-summary">
                <div id="personal-details">
                  <table className='summary-table'>
                    <tr className='summary-table-row'>
                      <td className='summary-table-column'>
                        <table>
                          <tr>
                            <td><b>Name:</b></td>
                            <td>{props.memberName}</td>
                          </tr>

                          <tr>
                            <td><b>Email:</b></td>
                            <td>{props.memberEmail}</td>
                          </tr>

                          <tr>
                            <td><b>Membership Type:</b></td>
                            <td>{props.membershipTypeName}</td>
                          </tr>

                          <tr>
                            <td><b>Term Duration:</b></td>
                            <td>{props.termFormula}</td>
                          </tr>

                          <tr>
                            <td><b>Start Date</b></td>
                            <td>
                              <NativeDatePicker
                                name="Start Date"
                                onChange={props.onStartDateUpdate}
                                placeholder="DD/MM/YYYY"
                                selected={props.startDate}
                                invalid={false}
                                minDate={props.minDatePickerDate}
                                maxDate={dayPlus30(props.minDatePickerDate)}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td><b>Update Member Details</b></td>
                            <td>
                              <input
                                id="editMemberCheckbox"
                                name="editMemberCheckbox"
                                type="checkbox"
                                defaultChecked={false}
                                onChange={updateValueEditMemberCheck}
                              />
                            </td>
                          </tr>
                        </table>
                      </td>

                      <td className='summary-table-column'>
                        {props.fileUrl !== '' &&
                          <img src={props.fileUrl} alt="Member Picture" className='summary-image' height="250px" />
                        }
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <PrimaryButton
                  id="summaryAdd"
                  onClick={props.onConfirm}
                  text={"Confirm"}
                  width='150px'
                  className="renew-membership-confirm-button"
                />
              </div>
            </div>
          </div>
        </Desktop>

        <Mobile>
          <div className='mobileVersion'>
            <div className='row' style={{ marginBottom: "25px" }}>
              <div className='title-header'>
                <h2 style={{ margin: "0px" }}>{"Renew Membership Summary"}</h2>
              </div>
            </div>

            <div style={{ textAlign: "center" }}>
              {props.fileUrl !== '' &&
                <img src={props.fileUrl} alt="Member Picture" className='summary-image' height="200px" />
              }
            </div>

            <div style={{ marginTop: "25px" }}>
              <table className='mobile-table'>

                <div>
                  <div className='membership-review-details-header'><b>Name:</b></div>
                  <div className='membership-review-personal-details'>{props.memberName}</div>
                </div>

                <div>
                  <div className='membership-review-details-header'><b>Email:</b></div>
                  <div className='membership-review-personal-details'>{props.memberEmail}</div>
                </div>

                <div>
                  <div className='membership-review-details-header'><b>Membership Type:</b></div>
                  <div className='membership-review-personal-details'>{props.membershipTypeName}</div>
                </div>

                <div>
                  <div className='membership-review-details-header'><b>Term Duration:</b></div>
                  <div className='membership-review-personal-details'>{props.termFormula}</div>
                </div>

                <div>
                  <div className='membership-review-details-header'><b>Start Date:</b></div>
                  <div className='membership-review-personal-details'>
                    <NativeDatePicker
                      name="Start Date"
                      onChange={props.onStartDateUpdate}
                      placeholder="DD/MM/YYYY"
                      selected={props.startDate}
                      invalid={false}
                      minDate={props.minDatePickerDate}
                      maxDate={dayPlus30(props.minDatePickerDate)}
                    />
                  </div>
                </div>

                <div>
                  <div className='membership-review-details-header'><b>Update Member Details:</b></div>
                  <div className='membership-review-personal-details'>
                    <input
                      id="editMemberCheckbox"
                      name="editMemberCheckbox"
                      type="checkbox"
                      defaultChecked={false}
                      onChange={updateValueEditMemberCheck}
                    />
                  </div>
                </div>
              </table>
            </div>

            <div style={{ textAlign: "center", marginTop: "25px" }}>
              <SecondaryButton
                id="summaryAdd"
                onClick={props.onConfirm}
                text={"Confirm"}
                className="add-membership-button"
                width='100%'
              />
            </div>
          </div>
        </Mobile>
      </div>
    </>
  )
}

export default withTranslation()(RewewalSummary);