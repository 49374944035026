import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from "../../../Shared/Components/Presentational/Modal";
import {
  Desktop,
  Mobile,
} from "../../../Shared/Components/Presentational/ResponsiveViews";
import classNames from "classnames";
import { PrimaryButton } from "../../../Shared/Components/Presentational/Buttons";

interface QuickReplyModalProps extends ModalProps {
  onBackButtonClick: () => void;
  sendReply: (message: string) => void;
  isLoading?: boolean;
}

const QuickReplyModal: React.FC<QuickReplyModalProps> = (props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>("");

  const handleBackButton = () => {
    props.onBackButtonClick();
    setMessage("");
  };

  const reply = () => {
    props.sendReply(message);
    setMessage("");
  };

  const formContents = (
    <>
      <ModalHeader text={t("requestsAndQueries.tickets.quickReply.title")} />

      <div className="quick-reply-modal-group">
        <div className="quick-reply-modal-field message-body">
          <label htmlFor="body">
            {t("requestsAndQueries.tickets.quickReply.message")}
          </label>
          <textarea
            name="body"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value as string);
            }}
            className="form-input"
            required
            maxLength={500}
          />
        </div>
      </div>

      <ModalFooter onBackButtonClick={handleBackButton}>
        <PrimaryButton
          onClick={reply}
          className="footer-button"
          disabled={!message || message.length < 1 || props.isLoading}
          text={t("general.proceed")}
          isLoading={props.isLoading}
        ></PrimaryButton>
      </ModalFooter>
    </>
  );

  return (
    <>
      <Desktop>
        <Modal
          isOpen={props.isOpen}
          className={classNames("quick-reply-modal", props.className)}
        >
          <Desktop>
            <form>{formContents}</form>
          </Desktop>
        </Modal>
      </Desktop>
      <Mobile>
        <Modal
          isOpen={props.isOpen}
          className={classNames("quick-reply-modal-mobile", props.className)}
        >
          <Mobile>
            <form>{formContents}</form>
          </Mobile>
        </Modal>
      </Mobile>
    </>
  );
};

export default QuickReplyModal;
