import { PostApiCall } from './ApiCall';

export class PostDownloadInvoiceCall extends PostApiCall {
    constructor(filter: TransactionInvoiceFilterDTO) {
        let url: string = 'account/' + filter.AccountId + '/invoice/download';

        super(url);

        this.body = filter;
    }
}
