import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    ModalFooter,
    ModalHeader,
    ModalProps
} from '../../../Shared/Components/Presentational/Modal';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import './MyBankDetailsModal.scss';
import classNames from 'classnames';

interface MyBankDetailsModalProps extends ModalProps {
    onBackButtonClick: () => void;
    myBankDetails: MyBankDetailsDTO;
}

const MyBankDetailsModal: React.FC<MyBankDetailsModalProps> = props => {
    const { t } = useTranslation();
    const [bankDetails] = useState({
        accountNumber: props.myBankDetails.AccountNumber,
        sortCode: props.myBankDetails.SortCode
    });

    const getInput = (name: string, value: string) => {
        return <span>{value}</span>;
    };

    const handleBackButton = () => {
       props.onBackButtonClick();
    };

    const formContents = (
        <>
            <ModalHeader text={t('myAccount.myBankDetails.title')} />

            <div className="bank-details-modal-group">
                <div className="bank-details-modal-field">
                    <label htmlFor="accountNumber">{t('myAccount.myBankDetails.accountNumber')}</label>
                    {getInput('accountNumber', bankDetails.accountNumber)}
                </div>
                <div className="bank-details-modal-field">
                    <label htmlFor="sortCode">{t('myAccount.myBankDetails.sortCode')}</label>
                    {getInput('sortCode', bankDetails.sortCode)}
                </div>
            </div>

            <ModalFooter onBackButtonClick={handleBackButton}>
            </ModalFooter>
        </>
    );

    return (
        <>
            <Modal isOpen={props.isOpen} className={classNames('my-bank-details-modal', props.className)}>
                <Desktop>
                    <form>{formContents}</form>
                </Desktop>
                <Mobile>
                    <form className="my-bank-details-modal-mobile-form">{formContents}</form>
                </Mobile>
            </Modal>
        </>
    );
};

export default MyBankDetailsModal;
