import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal } from '../../../Shared/Components/Presentational/Modal';
import MakeAPaymentModalDefault from './MakeAPaymentModalDefault';
import MakeAPaymentModalCard from './MakeAPaymentModalCard';
import MakeAPaymentModalBank from './MakeAPaymentModalBank';
import './MakeAPaymentModal.scss';

interface MakeAPaymentProps extends WithTranslation {
    isOpen: boolean;
    onBackButtonClick: () => void;
    bankTransferClick: () => void;
    haulfryBankDetails?: HaulfrynBankDetailsDTO;
    currentBalance: undefined | number;
    accountNumber: string;
}

interface MakeAPaymentState {
    paymentType: 'card' | 'bank' | undefined;
}

class MakeAPaymentModal extends Component<MakeAPaymentProps> {
    public state: MakeAPaymentState = {
        paymentType: undefined,
    };

    onBackBtnClick = () => {
        this.setState({ paymentType: undefined });
    };

    setPaymentType = (paymentType: 'card' | 'bank' | undefined) => {
        if (paymentType === 'bank') {
            this.props.bankTransferClick();
        }
        this.setState({ paymentType });
    };

    render() {
        return (
            <Modal isOpen={this.props.isOpen} className="payment-modal">
                {!this.state.paymentType && (
                    <MakeAPaymentModalDefault
                        paymentTypeClickEvent={this.setPaymentType}
                        onBackButtonClick={this.props.onBackButtonClick}
                    />
                )}
                {this.state.paymentType === 'bank' && (
                    <MakeAPaymentModalBank
                        onBackButtonClick={this.onBackBtnClick}
                        bankAccountDetails={this.props.haulfryBankDetails}
                    />
                )}
                {this.state.paymentType === 'card' && (
                    <MakeAPaymentModalCard
                        accountNumber={this.props.accountNumber}
                        currentBalance={this.props.currentBalance ? this.props.currentBalance : 0}
                        onBackButtonClick={this.onBackBtnClick}
                    />
                )}
            </Modal>
        );
    }
}

export default withTranslation()(MakeAPaymentModal);
