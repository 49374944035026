import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { Modal, ModalFooter, ModalProps } from '../../../Shared/Components/Presentational/Modal';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import './AddBookingModal.scss';
import AddBookingModalBookingForm from './AddBookingModalBookingForm';
import AddBookingModalMobileNumbersForm from './AddBookingModalMobileNumbersForm';
import AddBookingModalVehicleAccessForm from './AddBookingModalVehicleAccessForm';
import AddBookingsModalForms from '../../../Constants/Calendar/AddBookingsModalForms';
import { CalendarEvent } from '../../../Shared/Components/Presentational/Calendar';
import { Get, Post } from '../../../Api/ApiService';
import { PostSearchLodgeBookingsCall } from '../../../Api/Calls/PostSearchLodgeBookingsCall';
import moment from 'moment';
import { ConfirmModal } from '../../../Shared/Components/Presentational/Modal';

//@ts-ignore
import Chips from 'react-chips';
import Chip from '../../../Shared/Components/Presentational/Chip';

interface AddBookingModalProps extends ModalProps {
    toggleBookingModal: (isOpen: boolean) => void;
    addBooking: (booking: BookingDTO) => void;
    toggleBookingSuccessModal: (isOpen: boolean) => void;
    lodgeId: number;
    addBookingErrorMessage: undefined | string;
    setAddingBookingErrorMessage: (message: any) => void;
    hasANPRFeature: boolean;
    selectedDate?: Date;
    accountId: number;
}

const AddBookingModal: React.FC<AddBookingModalProps> = (props) => {
    const { t } = useTranslation();

    const [newBookingDetails, setnewBookingDetails] = useState<BookingDTO>({
        StartDate: '',
        EndDate: '',
        HotTubServiceTypeId: '',
        BookingTypeId: '',
        PhoneNumbers: [],
        CarRegistrationNumber1: '',
        CarRegistrationNumber2: '',
        AccountId: props.accountId,
    });

    const [isNoVehiclesModalOpen, setIsNoVehiclesModalOpen] = useState<boolean>(false);
    const [isNoPhoneNumbersModalOpen, setIsNoPhoneNumbersModalOpen] = useState<boolean>(false);
    const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
    const [datesValid, setDatesValid] = useState<boolean>(true);
    const [modalCurrentForm, setModalCurrentForm] = useState<number>(AddBookingsModalForms.BookingForm);
    const [mobileNumbers, setMobileNumbers] = useState<string[]>([]);
    const [invalidMobileNumbers, setInvalidMobileNumbers] = useState<string[]>([]);
    const [hasPendingMobileNumber, setHasPendingMobileNumber] = useState<boolean>(false);
    const [showMobileNumbersError, setShowMobileNumbersError] = useState<boolean>(false);
    const [bookingFilter, setBookingFilter] = useState<BookingFilterDTO>({
        LodgeId: props.lodgeId,
        FromDate: moment().startOf('month').startOf('week').format('DD/MM/YYYY'),
        ToDate: moment().endOf('month').endOf('week').format('DD/MM/YYYY'),
    });

    useEffect(() => {
        if (props.isOpen) {
            setnewBookingDetails({
                StartDate: '',
                EndDate: '',
                HotTubServiceTypeId: '',
                BookingTypeId: '',
                PhoneNumbers: [],
                CarRegistrationNumber1: '',
                CarRegistrationNumber2: '',
                AccountId: props.accountId,
            });
        }
    }, [props.isOpen]);

    useEffect(() => {
        setShowMobileNumbersError(invalidMobileNumbers.length > 0);
    }, [invalidMobileNumbers]);

    useEffect(() => {
        const currentInvalidNumbers = invalidMobileNumbers.filter((num) => {
            return mobileNumbers.indexOf(num) > -1;
        });

        setInvalidMobileNumbers(currentInvalidNumbers);
    }, [mobileNumbers]);

    useEffect(() => {
        async function searchBookings() {
            const bookings = await Post<CalendarBookingDTO[]>(new PostSearchLodgeBookingsCall(bookingFilter));

            const calendarBookings: CalendarEvent[] = bookings.data.map((booking) => {
                return {
                    allDay: true,
                    start: booking.FromDate,
                    end: booking.ToDate,
                    resourceId: booking.IsOwnerBooking ? 2 : 1,
                    className: booking.IsOwnerBooking ? 'my-holidays' : 'sublet-bookings',
                };
            });

            setCalendarEvents(calendarBookings);
        }

        if (props.isOpen) {
            searchBookings();
        }
    }, [bookingFilter, props.isOpen]);

    const handleProceed = async () => {
        switch (modalCurrentForm) {
            case AddBookingsModalForms.VehicleAccessForm:
                if (newBookingDetails.CarRegistrationNumber1 || newBookingDetails.CarRegistrationNumber1) {
                    addBooking();
                } else {
                    setIsNoVehiclesModalOpen(true);
                }
                break;
            case AddBookingsModalForms.MobileNumbersForm:
                if (mobileNumbers.length > 0) {
                    if (!props.hasANPRFeature) {
                        addBooking();
                    } else {
                        setModalCurrentForm(modalCurrentForm + 1);
                    }
                } else {
                    setIsNoPhoneNumbersModalOpen(true);
                }
                break;
            default:
                setModalCurrentForm(modalCurrentForm + 1);
                break;
        }
    };
    const addBooking = () => {
        newBookingDetails.PhoneNumbers = mobileNumbers;
        props.addBooking(newBookingDetails);
        setMobileNumbers([]);
        setInvalidMobileNumbers([]);
        setModalCurrentForm(AddBookingsModalForms.BookingForm);
    };

    const noVehiclesModalClose = (confirmed: boolean) => {
        setIsNoVehiclesModalOpen(false);
        if (confirmed) {
            addBooking();
        }
    };

    const noPhoneNumbersModalClose = (confirmed: boolean) => {
        setIsNoPhoneNumbersModalOpen(false);
        if (confirmed) {
            if (!props.hasANPRFeature) {
                addBooking();
            } else {
                setModalCurrentForm(modalCurrentForm + 1);
            }
        }
    };

    const handleStartDateChange = (dateString: string) => {
        setnewBookingDetails((prev: any) => {
            return { ...prev, StartDate: dateString };
        });
        props.setAddingBookingErrorMessage(undefined);
    };

    const handleEndDateChange = (dateString: string) => {
        setnewBookingDetails((prev: any) => {
            return { ...prev, EndDate: dateString };
        });
        props.setAddingBookingErrorMessage(undefined);
    };

    const handleHotTubServiceChange = (selectedOption: DropdownListOption) => {
        setnewBookingDetails((prev: any) => {
            return { ...prev, HotTubServiceTypeId: selectedOption.value };
        });
    };

    const handleBookingTypeChange = (selectedOption: DropdownListOption) => {
        setnewBookingDetails((prev: any) => {
            return { ...prev, BookingTypeId: selectedOption.value };
        });
    };

    const handleCarRegistrationNumber1Change = (val: string) => {
        setnewBookingDetails((prev: any) => {
            return { ...prev, CarRegistrationNumber1: val };
        });
    };

    const handleCarRegistrationNumber2Change = (val: string) => {
        setnewBookingDetails((prev: any) => {
            return { ...prev, CarRegistrationNumber2: val };
        });
    };

    const handleBackButton = () => {
        if (modalCurrentForm === AddBookingsModalForms.BookingForm) {
            setMobileNumbers([]);
            setInvalidMobileNumbers([]);
            props.toggleBookingModal(false);
        } else {
            setModalCurrentForm(modalCurrentForm - 1);
        }
    };

    const formIsValid = (): boolean => {
        switch (modalCurrentForm) {
            case AddBookingsModalForms.BookingForm:
                return (
                    newBookingDetails.StartDate.length > 0 &&
                    newBookingDetails.EndDate.length > 0 &&
                    newBookingDetails.BookingTypeId.length > 0 &&
                    newBookingDetails.HotTubServiceTypeId.length > 0 &&
                    datesValid
                );
            case AddBookingsModalForms.MobileNumbersForm:
                return invalidMobileNumbers.length == 0 && !hasPendingMobileNumber;
            case AddBookingsModalForms.VehicleAccessForm:
                return true;
            default:
                return false;
        }
    };

    return (
        <>
            <Desktop>
                <Modal isOpen={props.isOpen} className={classNames('add-booking-modal', props.className)}>
                    {modalCurrentForm == AddBookingsModalForms.BookingForm && (
                        <AddBookingModalBookingForm
                            lodgeId={props.lodgeId}
                            isOpen={props.isOpen}
                            startDate={newBookingDetails.StartDate}
                            startDateChange={handleStartDateChange}
                            endDate={newBookingDetails.EndDate}
                            endDateChange={handleEndDateChange}
                            hotTubServiceTypeId={newBookingDetails.HotTubServiceTypeId}
                            hotTubServiceTypeChange={handleHotTubServiceChange}
                            bookingTypeId={newBookingDetails.BookingTypeId}
                            bookingTypeChange={handleBookingTypeChange}
                            addBookingErrorMessage={props.addBookingErrorMessage}
                            datesValid={datesValid}
                            setDatesValid={setDatesValid}
                            setBookingFilter={setBookingFilter}
                            calendarEvents={calendarEvents}
                            selectedDate={props.selectedDate}
                        />
                    )}
                    {modalCurrentForm == AddBookingsModalForms.MobileNumbersForm && (
                        <AddBookingModalMobileNumbersForm
                            mobileNumbers={mobileNumbers}
                            setMobileNumbers={setMobileNumbers}
                            invalidMobileNumbers={invalidMobileNumbers}
                            setInvalidMobileNumbers={setInvalidMobileNumbers}
                            showMobileNumbersError={showMobileNumbersError}
                            setHasPendingMobileNumber={setHasPendingMobileNumber}
                            hasPendingMobileNumber={hasPendingMobileNumber}
                        />
                    )}
                    {modalCurrentForm == AddBookingsModalForms.VehicleAccessForm && (
                        <AddBookingModalVehicleAccessForm
                            carRegistrationNumber1={newBookingDetails.CarRegistrationNumber1}
                            carRegistrationNumber1Change={handleCarRegistrationNumber1Change}
                            carRegistrationNumber2={newBookingDetails.CarRegistrationNumber2}
                            carRegistrationNumber2Change={handleCarRegistrationNumber2Change}
                        />
                    )}
                    <ModalFooter onBackButtonClick={handleBackButton}>
                        <PrimaryButton
                            text={t('general.proceed')}
                            className="footer-button tooltip"
                            disabled={!formIsValid()}
                            onClick={handleProceed}
                        />
                    </ModalFooter>
                </Modal>
            </Desktop>

            <Mobile>
                <Modal isOpen={props.isOpen} className={classNames('add-booking-modal-mobile', props.className)}>
                    {modalCurrentForm == AddBookingsModalForms.BookingForm && (
                        <AddBookingModalBookingForm
                            className="add-booking-modal-mobile-form"
                            lodgeId={props.lodgeId}
                            isOpen={props.isOpen}
                            startDate={newBookingDetails.StartDate}
                            startDateChange={handleStartDateChange}
                            endDate={newBookingDetails.EndDate}
                            endDateChange={handleEndDateChange}
                            hotTubServiceTypeId={newBookingDetails.HotTubServiceTypeId}
                            hotTubServiceTypeChange={handleHotTubServiceChange}
                            bookingTypeId={newBookingDetails.BookingTypeId}
                            bookingTypeChange={handleBookingTypeChange}
                            addBookingErrorMessage={props.addBookingErrorMessage}
                            datesValid={datesValid}
                            setDatesValid={setDatesValid}
                            setBookingFilter={setBookingFilter}
                            calendarEvents={calendarEvents}
                            selectedDate={props.selectedDate}
                        />
                    )}
                    {modalCurrentForm == AddBookingsModalForms.MobileNumbersForm && (
                        <AddBookingModalMobileNumbersForm
                            className="add-booking-modal-mobile-form"
                            mobileNumbers={mobileNumbers}
                            setMobileNumbers={setMobileNumbers}
                            invalidMobileNumbers={invalidMobileNumbers}
                            setInvalidMobileNumbers={setInvalidMobileNumbers}
                            showMobileNumbersError={showMobileNumbersError}
                            setHasPendingMobileNumber={setHasPendingMobileNumber}
                            hasPendingMobileNumber={hasPendingMobileNumber}
                        />
                    )}
                    {modalCurrentForm == AddBookingsModalForms.VehicleAccessForm && (
                        <AddBookingModalVehicleAccessForm
                            className="add-booking-modal-mobile-form"
                            carRegistrationNumber1={newBookingDetails.CarRegistrationNumber1}
                            carRegistrationNumber1Change={handleCarRegistrationNumber1Change}
                            carRegistrationNumber2={newBookingDetails.CarRegistrationNumber2}
                            carRegistrationNumber2Change={handleCarRegistrationNumber2Change}
                        />
                    )}
                    <ModalFooter onBackButtonClick={handleBackButton}>
                        <PrimaryButton
                            text={t('general.proceed')}
                            className="footer-button"
                            disabled={!formIsValid()}
                            onClick={handleProceed}
                        />
                    </ModalFooter>
                </Modal>
            </Mobile>
            <ConfirmModal
                isOpen={isNoVehiclesModalOpen}
                title={t('myCalendar.addBooking.noVehiclesConfirmationTitle')}
                message={t('myCalendar.addBooking.noVehiclesConfirmationMessage')}
                confirmButtonText={t('general.confirm')}
                cancelButtonText={t('general.cancel')}
                onClose={noVehiclesModalClose}
            />
            <ConfirmModal
                isOpen={isNoPhoneNumbersModalOpen}
                title={t('myCalendar.addBooking.noPhoneNumbersConfirmationTitle')}
                message={t('myCalendar.addBooking.noPhoneNumbersConfirmationMessage')}
                confirmButtonText={t('general.yes')}
                cancelButtonText={t('general.no')}
                onClose={noPhoneNumbersModalClose}
            />
        </>
    );
};

export default AddBookingModal;
