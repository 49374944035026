import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

type NativeDatePickerProps = {
    name: string;
    placeholder: string;
    selected?: Date;
    invalid: boolean;
    openToDate?: Date;
    onChange: (date: Date) => void;
    minDate?: Date;
    maxDate?: Date;
    tabIndex?: number;
};

export function NativeDatePicker({ name, placeholder, selected, invalid, onChange, openToDate, minDate, maxDate, tabIndex }: NativeDatePickerProps) {
    const convertDate = function (date: Date | undefined): string {
        if (!date || isNaN(date.getTime())) {
            return '';
        }
        const dateString = moment(date).format('YYYY-MM-DD');
        return dateString;
    };

    const valueChanged = function (event: React.FormEvent<HTMLInputElement>) {
        const date: Date = moment(event.currentTarget.value, 'YYYY-MM-DD').toDate();
        onChange(date);
    };

    const calendarOpen = ()=>{
        if(!selected && openToDate){
            onChange(openToDate)
        }
    }

    return (
        <input
            name={name}
            placeholder={placeholder}
            value={convertDate(selected)}
            type="date"
            onChange={(e) => valueChanged(e)}
            className={classNames('form-input', { invalid: invalid })}
            defaultValue={moment(new Date()).format('YYYY-MM-DD')}
            onFocus={() => calendarOpen()}
            min={minDate ? moment(minDate).format('YYYY-MM-DD') : undefined}
            max={maxDate ? moment(maxDate).format('YYYY-MM-DD') : undefined}
            tabIndex={tabIndex}
        />
    );
}
