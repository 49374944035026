import moment from "moment";
import React from "react";
import { CustomColumnCellRendererProps } from "./Table";

const ShortenedTextCellRenderer: React.FC<CustomColumnCellRendererProps> = ({
  rowValue,
}) => {
  return (
    <span title={rowValue.Subject}>
      {rowValue.Subject.length > 20
        ? rowValue.Subject.substring(0, 20) + "..."
        : rowValue.Subject}
    </span>
  );
};

export default ShortenedTextCellRenderer;
