import { PostApiCall } from './ApiCall';

export class PostConfirmEmailCall extends PostApiCall {
    constructor(body: ConfirmDTO) {
        let url: string = `account/confirmEmail`;

        super(url);

        this.body = body;
        this.logoutOn401 = false;
    }
}
