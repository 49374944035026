import ContentfulClient from  "../ContentfulClient";

export async function GetTransactionCategoriesCall() {
    let filter ={
        content_type: 'parcVuTransactionCategory',
        select: 'fields.categoryName'
    }
    const result =  await ContentfulClient.getEntries<any>(filter);

    if (result.errors){
        throw {message: "GetTransactionCategoriesCall returned error/s", errors: result.errors} 
    }
    const finalResult = result.items.map(x=>x.fields.categoryName as string).filter((x, i, a) => a.indexOf(x) == i); 

    return finalResult;
}