import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { SecondaryButton } from './Buttons';
import { Translation } from 'react-i18next';

const AcceptButton = (props: any) => {
    return (
        <Translation>
            {(t: (key: string) => string) => {
                return (
                    <SecondaryButton {...props} className="cookie-button" text={t('general.dismiss')} />
                );
            }}
    </Translation>);
};

export default function CookiePopup() {
    return (
        <Translation>
            {(t: (key: string) => string) => {
                return (
                    <CookieConsent
                        cookieValue={'dismiss'}
                        cookieName={'cookieconsent_status'}
                        disableButtonStyles
                        sameSite="lax"
                        ButtonComponent={AcceptButton}>{t('general.cookies')}
                    </CookieConsent>
                );
            }}
        </Translation>
    );
}
