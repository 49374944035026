import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SecondaryButton } from '../../../../Shared/Components/Presentational/Buttons';
import { formatDate } from '../../../../Shared/Utilities/DateUtils';

interface FinaliseContainerProps extends WithTranslation {
    membership: MembershipDTO;
    editButtonFunction?: (value: any) => void;
    nowlaterButtonFunction: (membership: MembershipDTO,current: boolean) => void;
    state: string;
    isNow: boolean;
    deleteFunction: (isOpen: boolean, membershipId: string) => void;
    membershipText: string;
}

const FinaliseContainer: React.FC<FinaliseContainerProps> = (props: FinaliseContainerProps) => {
    const { t } = props;

    const handleNowLaterClick = () => {
        props.nowlaterButtonFunction(props.membership, props.isNow);
    }

    const handleEditClick = () => {
        props.editButtonFunction && props.editButtonFunction({ state: props.state, membership: props.membership } as MembershipContainer);
    }

    const handleDelete = () => {
        props.membership && props.deleteFunction && props.deleteFunction(true, props.membership.id!);
    }

    const showDate = () => {
        let startDate: string = "";
        let endDate: string = "";
        if(props.membership && props.membership.startDate){
            startDate = formatDate(props.membership.startDate);
        }

        if (props.membership && props.membership.endDate){
            endDate = formatDate(props.membership.endDate);
        }

        if (startDate !== "" && endDate !== ""){
            return startDate + " to " + endDate;
        }
        return "";
    }


    return (
        <>
            <h4 style={{ fontWeight: "bold" }}>{props.membership.firstName} {props.membership.lastName} <div style={{ float: "right", cursor: "pointer", marginRight: "10px", marginTop: "5px" }} onClick={handleDelete}><FontAwesomeIcon icon={faTrash}/></div></h4>
            <p style={{ wordWrap: "break-word" }}>{props.membership && props.membership.email}</p>
            <p>{props.membership && props.membership.membershipType}</p>
            <p>{props.membership && showDate()}</p>
            <p style={{ float: "left", marginBottom: "0px" }}>{props.membershipText}</p>
            {props.editButtonFunction && (
                <div style={{ position: "absolute", bottom: "0", right: "0" }}>
                    <SecondaryButton
                        text={t("myMemberships." + props.state + (props.isNow ? ".laterButton" : ".nowButton"))}
                        onClick={handleNowLaterClick}
                        className={"boxButtonSize"}
                        height={"30px"}
                        fontSize={"14px"}
                    />
                    <SecondaryButton
                        text={t("myMemberships." + props.state + ".editButtonText")}
                        onClick={handleEditClick}
                        height={"30px"}
                        fontSize={"14px"}
                    />
                </div>
            )}
        </>
    );
};

export default withTranslation()(FinaliseContainer);