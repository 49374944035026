import React, { Component } from 'react';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { connect } from 'react-redux';
import MyParkAndHomePage from '../Presentational/MyParkAndHomePage';
import MyParkAndHomePageMobile from '../Presentational/MyParkAndHomePage.mobile';
import {
    SET_IS_CONTACT_PARK_MODAL_OPEN,
    SET_SELECTED_IMPORTANT_INFORMATION,
    SET_SELECTED_NEWS_ITEM,
    RELOAD_TRANSLATIONS
} from '../../Redux/ActionTypes';
import { getFaqs, getImportantInformation, getParkInformation } from '../../Redux/ActionCreators';
import { Entry } from 'contentful';

interface MyParkAndHomeProps {
    dispatch: any;
    lodgeDetails: Map<string, string>;
    isHeaderOpen: boolean;
    parkCode: string;
    parkName: string;
    closeMyContactParkModal: () => void;
    openMyContactParkModal: () => void;
    isContactParkModalOpen: boolean;
    faqs: Entry<ContentfulFAQ>[];
    importantInformation: Entry<ContentfulHTMLContentBlock>[];
    parkInformation: Entry<ContentfulParkInformation>;
    setSelectedImportantInformation: (selectedImportantInformationId: string) => void;
    setSelectedNewsItem: (selectedNewsItemId: string) => void;
    userRoles: UserRoleDTO[];
}

class MyParkAndHome extends Component<MyParkAndHomeProps> {
    public componentDidMount(): void {
        this.props.dispatch(getFaqs(this.props.userRoles));
        this.props.dispatch(getImportantInformation(this.props.userRoles));
        this.props.dispatch(getParkInformation());
        // reload translation file
        this.props.dispatch({ type: RELOAD_TRANSLATIONS });
    }
    public render(): JSX.Element {
        return (
            <>
                <Desktop>
                    <MyParkAndHomePage
                        isContactParkModalOpen={this.props.isContactParkModalOpen}
                        closeMyContactParkModal={this.props.closeMyContactParkModal}
                        openMyContactParkModal={this.props.openMyContactParkModal}
                        parkName={this.props.parkName}
                        lodgeDetails={this.props.lodgeDetails}
                        isHeaderOpen={this.props.isHeaderOpen}
                        faqs={this.props.faqs}
                        importantInformation={this.props.importantInformation}
                        parkInformation={this.props.parkInformation}
                        setSelectedImportantInformation={this.props.setSelectedImportantInformation}
                        setSelectedNewsItem={this.props.setSelectedNewsItem}
                        userRoles={this.props.userRoles}
                    />
                </Desktop>
                <Mobile>
                    <MyParkAndHomePageMobile
                        isContactParkModalOpen={this.props.isContactParkModalOpen}
                        closeMyContactParkModal={this.props.closeMyContactParkModal}
                        openMyContactParkModal={this.props.openMyContactParkModal}
                        parkName={this.props.parkName}
                        lodgeDetails={this.props.lodgeDetails}
                        isHeaderOpen={this.props.isHeaderOpen}
                        faqs={this.props.faqs}
                        importantInformation={this.props.importantInformation}
                        parkInformation={this.props.parkInformation}
                        setSelectedImportantInformation={this.props.setSelectedImportantInformation}
                        setSelectedNewsItem={this.props.setSelectedNewsItem}
                        userRoles={this.props.userRoles}
                    />
                </Mobile>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    let details = new Map();

    if (state.information.lodgeDetails && state.information.lodgeDetails.Details) {
        state.information.lodgeDetails.Details.forEach((detail: any) => {
            details.set('myParkAndHome.' + detail.Key, detail.Value);
        });
    }

    return {
        lodgeDetails: details,
        isHeaderOpen: state.application.isHeaderOpen,
        parkCode: state.user.park ? state.user.park.ParkCode : undefined,
        parkName: (state.user.park && state.user.park.parkName) || 'your park',
        isContactParkModalOpen: state.information.isContactParkModalOpen,
        faqs: state.information.faqs,
        importantInformation: state.information.importantInformation,
        parkInformation: state.information.parkInformation,
        news: state.information.news,
        userRoles: state.user.userRoles,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openMyContactParkModal: () => dispatch({ type: SET_IS_CONTACT_PARK_MODAL_OPEN, payload: true }),
        closeMyContactParkModal: () => dispatch({ type: SET_IS_CONTACT_PARK_MODAL_OPEN, payload: false }),
        setSelectedImportantInformation: (selectedImportantInformationId: string) =>
            dispatch({ type: SET_SELECTED_IMPORTANT_INFORMATION, payload: selectedImportantInformationId }),
        setSelectedNewsItem: (selectedNewsItemId: string) =>
            dispatch({ type: SET_SELECTED_NEWS_ITEM, payload: selectedNewsItemId })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyParkAndHome);
