import ContentfulClient from  "../ContentfulClient";

export function GetContentfultMenusCall(ContentfulMenuIds : string[]) {

    let filter ={
        content_type: 'fbMenu',
        'sys.id[in]': ContentfulMenuIds.join(',')

    }
    return ContentfulClient.getEntries<ContentfulMenu>(filter);
}