import { PostApiCall } from "./ApiCall";

export class PostFreshDeskConversationSearchCall extends PostApiCall {
  constructor(ticketId: string, filter: OrderedFilterDTO) {
    let url: string = `requestsAndQueries/tickets/${ticketId}/conversations`;

    super(url);

    this.body = filter;
  }
}
