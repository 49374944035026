import React from 'react';
import './NavOption.scss';

interface NavOptionProps {
    text: string;
    icon: string;
    iconAlt: string;
}

const NavOption: React.FC<NavOptionProps> = ({ text, icon, iconAlt }) => {
    return (
        <div className="nav-option-container">
            <div className="icon-container">
                <div className="icon">
                    <img src={`/images/${icon}`} alt={iconAlt} />
                </div>
            </div>
            <p>{text}</p>
        </div>
    )
};

export default NavOption;