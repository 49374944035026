import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PasswordRequirements.scss';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

interface PasswordRequirementsProps {
    password: string;
    onPasswordChange: (isValid: boolean) => void;
}

export default function PasswordRequirements(props: PasswordRequirementsProps) {
    const { t } = useTranslation();

    const requirements = [
        { isValid: /[a-z]/.test(props.password), text: t('register.passwordRequirements.lowerCaseLetter') },
        { isValid: /[A-Z]/.test(props.password), text: t('register.passwordRequirements.upperCaseLetter') },
        { isValid: /\d/.test(props.password), text: t('register.passwordRequirements.number') },
        { isValid: props.password.length >= 8, text: t('register.passwordRequirements.minimumLength') }
    ];

    props.onPasswordChange(requirements.findIndex(x => !x.isValid) === -1);

    return (
        <div className="requirements-container">
            <p className="requirements-title">{t('register.passwordRequirements.title')}</p>
            {requirements.map((requirement, i) => (
                <PasswordRequirement isValid={requirement.isValid} text={requirement.text} key={i} />
            ))}
        </div>
    );
}

interface PasswordRequirementProps {
    isValid: boolean;
    text: string;
}

function PasswordRequirement(props: PasswordRequirementProps) {
    const { isValid, text } = props;
    return (
        <div className="requirement">
            <FontAwesomeIcon icon={faCheckCircle} color={isValid ? 'rgba(28, 77, 107, 1)' : 'rgba(28, 77, 107, 0.2)'} />
            <p>{text}</p>
        </div>
    );
}
