import ContentfulClient from "../ContentfulClient";

export async function GetParksCall() {
  let filter = {
      content_type: "park",
      order: 'fields.name',
      select:[
        'fields.name',
        'fields.shortName',
        'fields.code',
        'fields.myHaulfrynShowSubletBookingValue',
        'fields.myHaulfrynAllowPermissionDelegation',
        'fields.emailAddress',
        'fields.customerPortalUrl'
    ]
  };
  const result = await ContentfulClient.getEntries<ContentfulPark>(filter);

  if (result.errors) {
    throw { message: "GetParksCall returned error/s", errors: result.errors };
  }
  return result.items;
}
