import moment from 'moment';

export function formatDateString(input: string, format = 'DD/MM/YYYY'): string {
  const date = moment(input);
  return date.format(format);
}

export function formatDate(input: Date, format = 'DD/MM/YYYY'): string {
  const date = moment(input);
  return date.format(format);
}

export function calculateAge(dateOfBirth: Date): number {
  let today = new Date();
  let birthDate = new Date(dateOfBirth);
  let ageNow = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    ageNow--;
  }
  return ageNow;
}
