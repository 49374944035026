import React, { Component } from "react";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";

interface ProtectedRouteProps {
  protectedComponent: any;
  loggedIn: boolean;
  path: string;
  exact?: boolean;
}

class ProtectedRoute extends Component<ProtectedRouteProps> {
  render() {
    return (
      <Route
        path={this.props.path}
        {...this.props}
        render={(props) => {
          return this.props.loggedIn ? (
            <this.props.protectedComponent {...this.props} />
          ) : (
            <Redirect
              to={{
                pathname: `/Login?r=${encodeURI(this.props.path)}`,

                state: {
                  error: "You need to login first!",
                },
              }}
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loggedIn: state.login.loggedIn,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
