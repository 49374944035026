import React from "react";
import { connect } from "react-redux";
import { FeatureToggleProps } from "../Components/Presentational/FeatureToggle";

interface WrapperComponentProps {
    parkFeatures: string[];
}

interface WrapperComponentState {
    featureNames: string[];
    replacementComponent?: React.ReactNode;
}

export default function feature<TProps = {}>(featureProps: ((props: TProps) => FeatureToggleProps) | FeatureToggleProps) {
    return (WrappedComponent: any) => {
        const mapStateToProps = (state: any) => {
            return {
                parkFeatures: state.user && state.user.park ? state.user.park.parkFeatures : []
            };
        };
        
        class WrapperComponent extends React.Component<WrapperComponentProps, WrapperComponentState> {

            public render(): JSX.Element {

                let replacementComponent;
                let featureNames: string[] = [];

                if ((typeof featureProps === "object") && featureProps && Array.isArray(featureProps.features)) {
                    featureNames = featureProps.features as string[];
                    replacementComponent = featureProps.replacement;
                } else if (typeof featureProps === "function") {
                    featureNames = featureProps(this.props as any).features;
                    replacementComponent = featureProps(this.props as any).replacement;
                }

                let featuresFound = featureNames.map(fn => this.props.parkFeatures.includes(fn));
                
                if (featuresFound.length > 0 && featuresFound.includes(true)) {
                    return <WrappedComponent {...this.props} />;
                } else if (replacementComponent) {
                    return replacementComponent as any;
                } else {
                    return <></>;
                }
            }
        }

        return connect(mapStateToProps)(WrapperComponent) as typeof WrappedComponent;
    };
};