import { useTranslation } from "react-i18next"
import React from 'react';
import DatePicker from 'react-datepicker';
import "./FormStyles.scss";
import { Desktop, Mobile } from "../../../../../Shared/Components/Presentational/ResponsiveViews";
import { NativeDatePicker } from "../../../../../Shared/Components/Presentational/NativeDatePicker";

interface DateInputFieldProps {
  name: string;
  formInput: FormInput;
  labeltranslationKey: string;
  onInput: (value: Date, name: any, errors: string[]) => void;
  containerClass?: string;
  showErrors?: boolean;
  validators: Validator[];
  tabindex?: number;
  minDate?: Date;
  maxDate?: Date;
}

const DateInputField: React.FC<DateInputFieldProps> = (props: DateInputFieldProps) => {

  function updateValue(date: Date) {
    const errors: string[] = [];
    for (let validator of props.validators) {
      if (!validator.validator(date)) {
        errors.push(validator.name);
      }
    }
    props.onInput(date, props.name, errors);
  }

  const { t } = useTranslation()

  return (
    <div className={props.containerClass}>
      <label htmlFor={props.name} className="temp-text">
        {t(props.labeltranslationKey)}
      </label>
      <br/>
      <Desktop>
        <NativeDatePicker
          name={props.name}
          onChange={updateValue}
          placeholder="DD/MM/YYYY"
          selected={props.formInput.value && new Date(props.formInput.value)}
          tabIndex={props.tabindex}
          invalid={false}
          minDate={props.minDate}
          maxDate={props.maxDate}
        />
      </Desktop>

      <Mobile>
        <NativeDatePicker
          name={props.name}
          placeholder="DD/MM/YYYY"
          onChange={updateValue}
          selected={props.formInput.value && new Date(props.formInput.value)}
          invalid={false}
          minDate={props.minDate}
          maxDate={props.maxDate}
        />
      </Mobile>
      { (props.showErrors && (props.formInput.errors && props.formInput.errors.length > 0)) && <p style={{ textAlign: "left", color: "red" }}>{props.formInput && t("addMembership.errors." + props.formInput.errors[0])}</p>}
    </div>
  )
}

export default DateInputField