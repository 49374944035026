import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Get } from '../../../Api/ApiService';
import { GetRestaurantAvailabilityCall } from '../../../Api/Calls/GetRestaurantAvailabilityCall';
import { GetRestaurantSessionsCall } from '../../../Api/Calls/GetRestaurantSessionsCall';
import Spinner from '../../../Shared/Components/Presentational/Spinner';
import { Entry } from 'contentful';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { NativeDatePicker } from '../../../Shared/Components/Presentational/NativeDatePicker';
import { isValidEmail } from '../../../Shared/Utilities/Validation';
import {Honorifics} from '../../../Constants/Honorifics';

//@ts-ignore
import SelectableChip from '../../../Shared/Components/Presentational/SelectableChip';
import DropdownList from '../../../Shared/Components/Presentational/DropdownList';

interface BookTableFormProps  {
    className?: string;
    bookingDetails: BookTableDTO;
    setBookingDetails: (prevState: BookTableDTO) => any;
    availableTimeSlots: string[];
    setAvailableTimeSlots: (prevState: string[]) => any;
    availableSessions: RestaurantSessionDTO[];
    setAvailableSessions: (prevState: RestaurantSessionDTO[]) => any;
    minBookingDate: Date;
    maxBookingDate?: Date;
}

const BookTableForm: React.FC<BookTableFormProps> = (
    {className, bookingDetails, setBookingDetails, minBookingDate, maxBookingDate, availableTimeSlots, setAvailableTimeSlots, setAvailableSessions, availableSessions }) => {

    const [selectedDate, setSelectedDate] = useState<Date | null>();
    const [dateValid, setDateValid] = useState<boolean>(true);

    const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>();

    const [timeSlotMessage, setTimeSlotMessage] = useState<string | null>();
    const [isTimeSlotsLoading, setIsTimeSlotsLoading] = useState<boolean>(false);

    const [isSessiosnLoading, setIsSessionsLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const numberRange = (min: number, max: number) : number[] =>{
        return Array.from(Array(max-min + 1), (_, i) => i+min)
    }

    
    const handleInputChangeEvent = (event: any, validate?: (val: any) => boolean) => {
       
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (!value) {
            if (event.target.dataset.fallback) {
                value = target.dataset.fallback;
            } else if (target.required) {
                target.classList.add('invalid');
            }
        } else if (validate != undefined && !validate(value)) {
            target.classList.add('invalid');
        } else {
            target.classList.remove('invalid');
        }

        setBookingDetails({
            ...bookingDetails,
            [name]: value
        });
    }

    const handleBookingDateChange = (date: Date | null) => {
        setSelectedDate(date);
        setDateValid(date !== null);
    }

    const handleTimeSlotChange = (timeslot?: string) => {
        setSelectedTimeSlot(timeslot);

        let bookingDateString = "";

        if (timeslot && selectedDate) {
            bookingDateString = `${moment(selectedDate!).format('YYYY-MM-DD')} ${timeslot}`;
        }

        setBookingDetails({
            ...bookingDetails,
            BookingDate: bookingDateString
        });
    }

    useEffect(() => {
        const getAvailableSessions = async () => {
            if (selectedDate) {
                setIsSessionsLoading(true);
                try {
                    const result = await Get<RestaurantSessionDTO[]>(new GetRestaurantSessionsCall(
                        bookingDetails.RestaurantCode, moment(selectedDate!).format('YYYY-MM-DD')));

                    setAvailableSessions(result.data);
                } catch (e) {
                    console.log(e);
                    setAvailableSessions([]);
                }
                setIsSessionsLoading(false);
            } else {
                // FORM INCOMPLETE
            }

        }
        getAvailableSessions();
    }, [selectedDate])

    useEffect(() => {
        const getAvailableTimeSlots = async () => {
            if (selectedDate && selectedDate.getDate() && bookingDetails.NumOfAdults >= 1 && bookingDetails.SessionName) {
                setIsTimeSlotsLoading(true);
                handleTimeSlotChange(undefined);
                try {
                    const result = await Get<string[]>(new GetRestaurantAvailabilityCall(
                        bookingDetails.RestaurantCode,
                        bookingDetails.SessionName,
                        moment(selectedDate!).format('YYYY-MM-DD'),
                        bookingDetails.NumOfAdults,
                        bookingDetails.NumOfChildren));

                    setAvailableTimeSlots(result.data);

                    if (result.data.length < 1) {
                        setTimeSlotMessage(t('myCalendar.restaurants.bookTable.noTablesAvailable'));
                    } else {
                        setTimeSlotMessage(null);
                    }

                } catch (e) {
                    setTimeSlotMessage(t('myCalendar.restaurants.bookTable.errorOptainingTimeSlots'));
                }
                setIsTimeSlotsLoading(false);
            } else {
                setTimeSlotMessage(t('myCalendar.restaurants.bookTable.errorFormIncomplete'));
            }

        }
        getAvailableTimeSlots();
    }, [selectedDate, bookingDetails.NumOfAdults, bookingDetails.NumOfChildren,  bookingDetails.SessionName])



    return (
        <form className={className}>
                <div className="add-booking-field">
                    <label htmlFor="NumOfAdults">{t('myCalendar.restaurants.bookTable.numberOfAdults')}</label>
                     <DropdownList
                        options={numberRange(1,12).map(n=> {return {label: n.toString(), value: n.toString()}})}
                        classNamePrefix=""
                        onSelectionChanged={(opt)=> setBookingDetails({ ...bookingDetails,NumOfAdults: parseInt(opt.value)})}
                        selectedOption={{label: bookingDetails.NumOfAdults.toString(), value: bookingDetails.NumOfAdults.toString()}}
                    />
                </div>
                <div className="add-booking-field">
                    <label htmlFor="NumOfChildren">{t('myCalendar.restaurants.bookTable.numberOfChildren')}</label>
                    <DropdownList
                        options={numberRange(0,10).map(n=> {return {label: n.toString(), value: n.toString()}})}
                        classNamePrefix=""
                        onSelectionChanged={(opt)=> setBookingDetails({ ...bookingDetails,NumOfChildren: parseInt(opt.value)})}
                        selectedOption={{label: bookingDetails.NumOfChildren.toString(), value: bookingDetails.NumOfChildren.toString()}}
                    />
                </div>
                <div className="add-booking-field">
                    <label htmlFor="BookingDate">{t('myCalendar.restaurants.bookTable.selectDate')}</label>
                    <Desktop>
                        <DatePicker
                            name="BookingDate"
                            className={dateValid ? "form-input" : "form-input invalid"}
                            required
                            selected={selectedDate}
                            onChange={(date) => handleBookingDateChange(date)}
                            placeholderText="dd/mm/yyyy"
                            dateFormat="dd/MM/yyyy"
                            minDate={minBookingDate}
                            maxDate={maxBookingDate}
                        />
                    </Desktop>
                    <Mobile>
                        <NativeDatePicker
                            name="BookingDate"
                            placeholder="dd/mm/yyy"
                            selected={selectedDate ? selectedDate : undefined}
                            onChange={(date) => handleBookingDateChange(date)}
                            invalid={!dateValid}
                            minDate={minBookingDate}
                            maxDate={maxBookingDate}
                        />
                    </Mobile>
                </div>
                <div className="add-booking-field">
                    <label htmlFor="Title">{t('myCalendar.restaurants.bookTable.session')}</label>
                    <DropdownList
                        options={availableSessions.map(as=> {return {label: as.Name, value: as.Name}})}
                        classNamePrefix=""
                        onSelectionChanged={(opt)=> setBookingDetails({ ...bookingDetails,SessionName: opt.value})}
                        selectedOption={{label:  bookingDetails.SessionName, value: bookingDetails.SessionName}}
                        isDisabled={!selectedDate}
                        isLoading={isSessiosnLoading}
                    />
                </div>
                <div className="add-booking-field-full">
                    <label htmlFor="startDate">{t('myCalendar.restaurants.bookTable.time')}</label>
                    <div className="timeslot-container">
                        {isTimeSlotsLoading && <Spinner />
                            || (!timeSlotMessage &&
                                availableTimeSlots.map((value) => {
                                    return (
                                        <SelectableChip
                                            isSelected={selectedTimeSlot == value}
                                            itemKey={value}
                                            onSelect={handleTimeSlotChange}
                                        >
                                            {value}
                                        </ SelectableChip>)
                                })
                                || <p className="timeslot-message">{timeSlotMessage}</p>)
                        }
                    </div>

                </div>
                <div className="add-booking-field">
                    <label htmlFor="Title">{t('myCalendar.restaurants.bookTable.title')}</label>
                    <DropdownList
                        options={Object.values(Honorifics).map(h=> {return {label: h, value: h}})}
                        classNamePrefix=""
                        onSelectionChanged={(opt)=> setBookingDetails({ ...bookingDetails,Title: opt.value})}
                        selectedOption={{label:  bookingDetails.Title, value: bookingDetails.Title}}
                    />
                </div>
                <div className="add-booking-field">
                    <label htmlFor="FirstName">{t('myCalendar.restaurants.bookTable.firstName')}</label>
                    <input
                        name="FirstName"
                        type="text"
                        className="form-input"
                        required
                        value={bookingDetails.FirstName}
                        onChange={handleInputChangeEvent}
                        maxLength={20}
                    />
                </div>
                <div className="add-booking-field">
                    <label htmlFor="Surname">{t('myCalendar.restaurants.bookTable.lastName')}</label>
                    <input
                        name="Surname"
                        type="text"
                        className="form-input"
                        required
                        value={bookingDetails.Surname}
                        onChange={handleInputChangeEvent}
                        maxLength={20}
                    />
                </div>
                <div className="add-booking-field">
                    <label htmlFor="Email">{t('myCalendar.restaurants.bookTable.email')}</label>
                    <input
                        name="Email"
                        type="email"
                        className="form-input"
                        value={bookingDetails.Email}
                        onChange={(e) => handleInputChangeEvent(e, (v) => isValidEmail(v))}
                    />
                </div>
                <div className="add-booking-field">
                    <label htmlFor="PhoneNumber">{t('myCalendar.restaurants.bookTable.telephone')}</label>
                    <input
                        name="PhoneNumber"
                        type="tel"
                        className="form-input"
                        required
                        value={bookingDetails.PhoneNumber}
                        onChange={handleInputChangeEvent}
                    />
                </div>
            </form>);
};

export default BookTableForm;
