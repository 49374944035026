import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { Modal, ModalHeader, ModalProps } from '../../../Shared/Components/Presentational/Modal';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import * as Routes from "../../../Constants/Routes";
import { GetHTMlContentBlockCall } from '../../../Contentful/Calls/GetHTMLContentBlockCall';
import Spinner from '../../../Shared/Components/Presentational/Spinner';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface ReferredCreateMembershipModalProps extends ModalProps {
  isOpen: boolean;
  closeModal: (isOpen: boolean) => void;
  clearMembershipForm: () => void;
  updateMembershipFormPage: (page: number) => void;
}

const ReferredCreateMembershipModal: React.FC<ReferredCreateMembershipModalProps> = (props: ReferredCreateMembershipModalProps) => {
  const { t } = useTranslation();

  const [modalText, setModalText] = useState<ContentfulContentObject | undefined>();
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    Promise.all([
      getModalText("My Haulfryn - Membership - Referee with no membership", "modalText"),
    ]).then(r => {
      setModalText(r[0]);
      setContentLoaded(true);
    })
  }, []);

  const getModalText = async (name: string, label: string) => {
    const modalText = await GetHTMlContentBlockCall(name, label);
    return modalText;
  }

  const handleClick = () => {
    props.closeModal(false);
    props.clearMembershipForm();
    props.updateMembershipFormPage(0);
  }

  const formContents = (
    <div className='button-container-modal' style={{ display: "block" }}>
      {contentLoaded ? (
        <div className='container'>
          <ModalHeader text={modalText ? modalText.title : ""} />
          <div className='contentful-text'>
            {documentToReactComponents(modalText!.content)}
          </div>

          <Link to={Routes.AddMembership}>
            <PrimaryButton
              text={t("general.continue")}
              onClick={handleClick}
            />
          </Link>
        </div>
      ) : (
        <div className='memberships-spinner'>
          <Spinner />
        </div>
      )}
    </div>
  );

  return (
    <>
      <Desktop>
        <Modal
          isOpen={props.isOpen}
          className={classNames("owner-contact-details-modal")}
        >
          {formContents}
        </Modal>
      </Desktop>
      <Mobile>
        <Modal
          isOpen={props.isOpen}
          className={classNames("owner-contact-details-modal-mobile mobile-modal")}
        >
          {formContents}
        </Modal>
      </Mobile>
    </>
  )
}

export default ReferredCreateMembershipModal;