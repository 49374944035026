import React from "react";
import { Desktop, Mobile } from "../../../../Shared/Components/Presentational/ResponsiveViews";
import { PrimaryButton, SecondaryButton } from "../../../../Shared/Components/Presentational/Buttons";
import "../../Container/AddMembershipStyles.scss";

interface FormFooterProps {
  exitButtonText: string;
  continueButtonText: string;
  onExitClick: () => void;
  onContinueClick: () => void;
  showExitButton: boolean;
  showContinueButton: boolean;
  disableContinueButton: boolean;
  showContinueButtonLoading: boolean;
}

const FormFooter: React.FC<FormFooterProps> = (props) => {
  return (
    <>
      <Desktop>
        {props.showExitButton &&
          <SecondaryButton
            id="exitBtn"
            text={props.exitButtonText}
            type="submit"
            onClick={props.onExitClick}
            className="membership-btn exit-btn"
          />
        }

        {props.showContinueButton &&
          <PrimaryButton
            text={props.continueButtonText}
            id="saveAndContinue"
            type="submit"
            disabled={props.disableContinueButton}
            onClick={props.onContinueClick}
            className="save-cnt-btn membership-btn"
            isLoading={props.showContinueButtonLoading}
          />
        }
      </Desktop>
      
      <Mobile>
        {props.showContinueButton &&
          <PrimaryButton
            text={props.continueButtonText}
            id="saveAndContinue"
            type="submit"
            disabled={props.disableContinueButton}
            onClick={props.onContinueClick}
            className="membership-btn"
            isLoading={props.showContinueButtonLoading}
          />
        }

        {props.showExitButton && 
          <SecondaryButton
            id="exitBtn"
            text={props.exitButtonText}
            type="submit"
            onClick={props.onExitClick}
            className="membership-btn"
          />
        }
      </Mobile>
    </>
  )
}

export default FormFooter;