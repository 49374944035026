import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Widget } from "../../../Shared/Components/Presentational/Widget";
import Fab from "../../../Shared/Components/Presentational/Fab";
import "./MyGuestsPage.scss";
import Table, {
  ColumnDefinition,
} from "../../../Shared/Components/Presentational/Table";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PassesFilter from "./PassesFilter";
import AddPass from "../Container/AddPass";
import Description from "../../../Shared/Components/Presentational/Description";
import { PrimaryButton } from "../../../Shared/Components/Presentational/Buttons";
import RenewPass from "../Container/RenewPass";

interface MyGuestsPageProps extends WithTranslation {
  columns: ColumnDefinition[];
  pagedResults: PagedResultDTO;
  filter: PassFilterDTO;
  selectedFilter: ParkPassTypeDTO;
  filters: ParkPassTypeDTO[];
  onFilterChange: (newFilter: any) => void;
  onFilterSelected: (filter: ParkPassTypeDTO) => void;
  pageSize: number;
  isSearchingPasses: boolean;
  openAddPassesModal: () => void;
  closeAddPassesModal: () => void;
  isHeaderOpen: boolean;
  passesToRenew: PassHeaderDTO[];
  openRenewPassesModal: () => void;
  closeRenewPassesModal: () => void;
  onPassesRenewed: () => void;
  coOwnerAccessTypes: DropdownListOption[];
  requirePassPhoto?: boolean;
}

class MyGuestsPage extends Component<MyGuestsPageProps> {
  public render(): JSX.Element {
    const { t } = this.props;
    const isRenewable =
      this.props.selectedFilter &&
      this.props.passesToRenew.length +
        this.props.selectedFilter.CurrentNumberOfPasses >
        this.props.selectedFilter.MaxNumberOfPasses;
    return (
      <div className="widget-container">
        {!this.props.isHeaderOpen && (
          <>
            <h1 className="widget-layout-title">{t("myGuests.title")}</h1>
            <p className="widget-layout-message">{t("myGuests.message")}</p>
          </>
        )}
        <Widget
          position={{
            columnIndex: 1,
            columnSpan: 12,
            rowIndex: 3,
            rowSpan: 9,
          }}
        >
          <Fab
            icon={faPlus}
            clickAction={() => this.props.openAddPassesModal()}
            className="add-pass"
          />
          <Table
            title={t(
              this.props.selectedFilter
                ? this.props.selectedFilter.NameTranslationKey
                : ""
            )}
            columns={this.props.columns}
            pagedResults={this.props.pagedResults}
            filter={this.props.filter}
            onFilterChange={this.props.onFilterChange}
            pageSize={this.props.pageSize}
            globalCellProps={{
              coOwnerAccessTypes: this.props.coOwnerAccessTypes,
            }}
            noResultsMessage={
              this.props.isSearchingPasses === false
                ? t("myGuests.passes.noResultsMessage")
                : ""
            }
            filterComponent={
              <PassesFilter
                selectedFilter={this.props.selectedFilter}
                filters={this.props.filters}
                onFilterSelected={this.props.onFilterSelected}
              />
            }
            descriptionComponent={
              <Description
                descriptionHeader={t("myGuests.passes.descriptionHeader")}
                description={
                  this.props.selectedFilter
                    ? t(this.props.selectedFilter.DescriptionTranslationKey, {
                        interpolation: { escapeValue: false },
                      })
                    : ""
                }
                hasHtml={true}
                descriptionClassName="passes-description"
                descriptionHeaderClassName="passes-description-header"
                descriptionContainerClassName="passes-description-container"
              />
            }
            isLoading={this.props.isSearchingPasses}
          />
          {this.props.passesToRenew &&
            this.props.passesToRenew.length > 0 &&
            (!isRenewable ? (
              <div className="renew-pass">
                <PrimaryButton
                  text={t("myGuests.passes.renewSelected")}
                  type="button"
                  className="renew-pass-btn"
                  onClick={() => this.props.openRenewPassesModal()}
                  disabled={
                    this.props.passesToRenew.length +
                      this.props.selectedFilter.CurrentNumberOfPasses >
                    this.props.selectedFilter.MaxNumberOfPasses
                  }
                />
              </div>
            ) : (
              <div className="renew-pass-notallowed">
                The selected passes will exceed the maximum active number of{" "}
                {this.props.selectedFilter.MaxNumberOfPasses} for this pass type
              </div>
            ))}
          <AddPass
            requirePassPhoto={this.props.requirePassPhoto}
            submittedOption={this.props.selectedFilter}
            onBackButtonClick={() => this.props.closeAddPassesModal()}
          />
          <RenewPass
            requirePassPhoto={this.props.requirePassPhoto}
            passesToRenew={this.props.passesToRenew}
            submittedOption={this.props.selectedFilter}
            onBackButtonClick={() => this.props.closeRenewPassesModal()}
            onPassesRenewed={this.props.onPassesRenewed}
          />
        </Widget>
      </div>
    );
  }
}

export default withTranslation()(MyGuestsPage);
