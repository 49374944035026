import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as Routes from '../../../Constants/Routes';
import ParkNewsPage from '../Presentational/ParkNewsPage';
import { SET_SELECTED_NEWS_ITEM } from '../../Redux/ActionTypes';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import ParkNewsPageMobile from '../Presentational/ParkNewsPage.mobile';
import { Entry } from 'contentful';

interface ParkNewsProps extends RouteComponentProps {
    selectedId: string;
    news:Entry<NewsArticleHeader>;
    setSelectedNewsItem: (setSelectedNewsItem: string) => void;
}

const ParkNews: React.FC<ParkNewsProps> = ({ selectedId, news, history, setSelectedNewsItem }) => {
    useEffect(() => {
        if (!selectedId) {
            history.push(Routes.MyParkAndHome);
        }
    }, [selectedId, history]);
    return (
        <>
            <Desktop>
                <ParkNewsPage newsItem={news} selectedId={selectedId} setSelectedNewsItem={setSelectedNewsItem} />
            </Desktop>
            <Mobile>
                <ParkNewsPageMobile newsItem={news} selectedId={selectedId} setSelectedNewsItem={setSelectedNewsItem} />
            </Mobile>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSelectedNewsItem: (selectedNewsItemId: string) => {
            dispatch({ type: SET_SELECTED_NEWS_ITEM, payload: selectedNewsItemId });
            window.scrollTo(0, 0);
        },
    };
};

const mapStateToProps = (state: any) => {
    return {
        selectedId: state.information.selectedNewsItemId,
        news: state.information.news,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ParkNews));
