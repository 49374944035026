import { PostApiCall } from "./ApiCall";

export class PostAddBookingApiCall extends PostApiCall {

    constructor(lodgeId: string, booking: BookingDTO) {
        let url: string = "account/" + lodgeId + "/newBooking";
        super(url);

        this.body = booking;
    }
}

