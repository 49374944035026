import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, ModalFooter, ModalHeader } from './Modal';
import './ContactModal.scss';

interface ContactModalProps extends WithTranslation {
    isOpen: boolean;
    onBackButtonClick: () => void;
    contactDetails: ContactModalDTO
}

class ContactModal extends Component<ContactModalProps> {
    render() {
        const { t } = this.props;
        return (
            <Modal isOpen={this.props.isOpen} className="contact-modal">
                <ModalHeader text={this.props.contactDetails.Title} />
                <section className="contact-details-section">
                    {
                        this.props.contactDetails.Description ? <p className="description">{this.props.contactDetails.Description}</p> : <></>
                    }
                    <div className="contact-details">
                        <div>
                            <p className="title">{t('general.phoneNumber')}</p>
                            <a href={`tel:${this.props.contactDetails.PhoneNumber}`} className="value">
                                {this.props.contactDetails.PhoneNumber}
                            </a>
                        </div>
                        <div>
                            <p className="title">{t('general.address')}</p>
                            <p className="value address">{this.props.contactDetails.Address}</p>
                        </div>
                        <div>
                            <p className="title">{t('general.emailAddress')}</p>
                            <a href={`mailto:${this.props.contactDetails.Email}`} className="value">
                                {this.props.contactDetails.Email}
                            </a>
                        </div>
                    </div>
                </section>
                <ModalFooter onBackButtonClick={this.props.onBackButtonClick} />
            </Modal>
        );
    }
}

export default withTranslation()(ContactModal);
