import ContentfulClient from "../ContentfulClient";
import { GetParkCall } from "./GetParkCall";

export async function GetParkOffersCall(park: { parkCode: string }, page: number, pageSize: number) { 
    const parkDetail = await GetParkCall(park.parkCode);
    let filter = {
        content_type: 'offer',
        links_to_entry: parkDetail.sys.id, 
        skip: page * pageSize,
        limit: pageSize
    }

    const result =  await ContentfulClient.getEntries<ContentfulOffer>(filter);

    if (result.errors) {
        throw {message: "GetParkOffersCall returned error/s", errors: result.errors} 
    }

    return result;
}