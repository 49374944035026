import { PostApiCall } from './ApiCall';

export class PostMakeAPaymentCall extends PostApiCall {
    constructor(accountNo: string, paymentDetails: SagePayPaymentDetailsDTO) {
        let url: string = 'payment/' + accountNo + '/makeapayment';

        super(url);

        this.body = paymentDetails;
    }
}
