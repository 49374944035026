import React, { Component, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Widget } from "./Widget";
import Fab from "./Fab";
import "./Breadcrumb.scss";
import { faPlus, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { PrimaryButton } from "./Buttons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface BreadcrumbProps extends WithTranslation {
  title: string;
  section: any;
  selected: boolean;
  length: number;
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const { t } = props;

  return (
    <h1
      className={`breadcrumb-child ${props.selected ? "temp-text" : "text-grey"}`}
    >
      {t(props.title)} &nbsp;&nbsp;
      {props.section.key != (props.length-1) && <FontAwesomeIcon icon={faChevronRight} />}
    </h1>
  );
};

export default withTranslation()(Breadcrumb);