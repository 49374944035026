import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Calendar, CalendarEvent, CalendarResource } from '../../../Shared/Components/Presentational/Calendar';
import './MyCalendarPage.scss';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import CalendarListWidget from '../Container/CalendarListWidget';
import MyCalendarListHeader from '../Container/MyCalendarListHeader';
import { SelectedCalendarWidgetOptions } from '../../Redux/ActionTypes';
import ReservationLinks from '../Container/ReservationLinks';
import Fab from '../../../Shared/Components/Presentational/Fab';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddBookingModal from './AddBookingModal';
import { SuccessModal } from '../../../Shared/Components/Presentational/Modal';
import SubletBookingInfoModal from './SubletBookingInfoModal';

interface MyCalendarPageProps extends WithTranslation {
    minDate: Date;
    maxDate: Date;
    calendarDate?: Date | undefined;
    events: CalendarEvent[];
    resources: CalendarResource[];
    selectedWidget: SelectedCalendarWidgetOptions;
    isHeaderOpen: boolean;
    toogleBookingModal: (isOpen: boolean) => void;
    isAddBookingModalOpen: boolean;
    addBooking: (booking: BookingDTO) => void;
    toggleBookingSuccessModal: (isOpen: boolean) => void;
    isAddBookingSuccessModalOpen: boolean;
    onRangeChange?: (range: { start: any; end: any }) => void;
    onNavigate?: (date: Date) => void;
    lodgeId: number;
    addBookingErrorMessage: undefined | string;
    setAddingBookingErrorMessage: (message: any) => void;
    hasANPRFeature: boolean;
    accountId: number;
    selectedCalendarEvent?: CalendarEvent;
    setSelectedCalendarEvent: (event: CalendarEvent | undefined) => any
    clickableEvents: boolean;
}

class MyCalendarPage extends Component<MyCalendarPageProps> {
    public render(): JSX.Element {
        const { t } = this.props;
       
        return (
            <>
                <div className="widget-container">
                    {!this.props.isHeaderOpen && (
                        <>
                            <h1 className="widget-layout-title">{t('myCalendar.title')}</h1>
                            <p className="widget-layout-message">{t('myCalendar.message')}</p>
                        </>
                    )}
                    <Widget
                        position={{
                            columnIndex: 1,
                            columnSpan: 8,
                            rowIndex: 3,
                            rowSpan: 10,
                        }}
                    >
                        <Fab
                            icon={faPlus}
                            clickAction={() => this.props.toogleBookingModal(true)}
                            className="add-booking"
                        />
                        <Calendar
                            className="my-calendar"
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            events={this.props.events}
                            resources={this.props.resources}
                            onRangeChange={this.props.onRangeChange}
                            onNavigate={this.props.onNavigate}
                            onClickEvent={this.props.setSelectedCalendarEvent}
                            clickableEvents={this.props.clickableEvents}
                        />
                        <p className="guarantee">{t('myCalendar.guarantee')}</p>
                    </Widget>
                    <Widget
                        containerClassName="reservation-links-widget-container"
                        position={{
                            columnIndex: 9,
                            columnSpan: 4,
                            rowIndex: 3,
                            rowSpan: 2,
                        }}
                    >
                        <ReservationLinks showHeader={true}  lodgeId={this.props.lodgeId}  accountId={this.props.accountId} />
                    </Widget>
                    <CalendarListWidget
                        position={{
                            columnIndex: 9,
                            columnSpan: 4,
                            rowIndex: 6,
                            rowSpan: 8,
                        }}
                        pageSize={5}
                        canLoadMore={true}
                        widgetComponents={{ widgetHeader: <MyCalendarListHeader pageSize={5} /> }}
                    />
                </div>

                <AddBookingModal
                    isOpen={this.props.isAddBookingModalOpen}
                    toggleBookingModal={this.props.toogleBookingModal}
                    addBooking={this.props.addBooking}
                    toggleBookingSuccessModal={this.props.toggleBookingSuccessModal}
                    lodgeId={this.props.lodgeId}
                    addBookingErrorMessage={this.props.addBookingErrorMessage}
                    setAddingBookingErrorMessage={this.props.setAddingBookingErrorMessage}
                    hasANPRFeature={this.props.hasANPRFeature}
                    selectedDate={this.props.calendarDate}
                    accountId={this.props.accountId}
                />

                <SuccessModal
                    isOpen={this.props.isAddBookingSuccessModalOpen}
                    closeModal={() => {
                        this.props.toogleBookingModal(false);
                        this.props.toggleBookingSuccessModal(false);
                    }}
                    importantInfo={t('myCalendar.addBooking.bookingSuccessImportantInfo')}
                    title={t('myCalendar.addBooking.bookingSuccessTitle')}
                    message={t('myCalendar.addBooking.bookingSuccessMessage')}
                    buttonText={t('general.ok')}
                />
                <SubletBookingInfoModal
                 isOpen={!!this.props.selectedCalendarEvent}
                 setSelectedCalendarEvent={this.props.setSelectedCalendarEvent}
                 selectedCalendarEvent={this.props.selectedCalendarEvent!}
                 />
               
            </>
        );
    }
}

export default withTranslation()(MyCalendarPage);
