import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { GetApiCall, PostApiCall, DeleteApiCall, PutApiCall } from './Calls/ApiCall';
import { store } from '../index';
import { logout } from '../Login/Redux/ActionCreators';

export function Get<TResponse>(request: GetApiCall): Promise<AxiosResponse<TResponse>> {

    let promise = axios.get<TResponse>(request.url, {
        headers: request.headers
    } as AxiosRequestConfig);

    promise.catch((err: AxiosError) => {
        if (err && err.response && err.response.status === 401 && request.logoutOn401) {
            store.dispatch(logout(true) as any);
        }
    });

    return promise;
}

export function Post<TResponse>(request: PostApiCall, properties?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>> {

    let promise = axios.post(request.url, request.body, {
        headers: request.headers,
        ...properties
        
    } as AxiosRequestConfig);

    promise.catch((err: AxiosError) => {
        if (err && err.response && err.response.status === 401 && request.logoutOn401) {
            store.dispatch(logout(true) as any);
        }
    });

    return promise;
}

export function Put<TResponse>(request: PutApiCall): Promise<AxiosResponse<TResponse>> {

    let promise = axios.put(request.url, request.body, {
        headers: request.headers
    } as AxiosRequestConfig);

    promise.catch((err: AxiosError) => {
        if (err && err.response && err.response.status === 401 && request.logoutOn401) {
            store.dispatch(logout(true) as any);
        }
    });

    return promise;
}

export function Delete<TResponse>(request: DeleteApiCall): Promise<AxiosResponse<TResponse>> {

    let promise = axios.delete(request.url, {
        headers: request.headers
    } as AxiosRequestConfig);

    promise.catch((err: AxiosError) => {
        if (err && err.response && err.response.status === 401 && request.logoutOn401) {
            store.dispatch(logout(true) as any);
        }
    });

    return promise;
}
