import {
    SET_HEADER_MENU_OPEN
} from './ActionTypes';

const initialState = {
    isHeaderOpen: false
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case SET_HEADER_MENU_OPEN:
            return { ...state, isHeaderOpen: action.payload };
        default:
            return state;
    }
};
