import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    GO_TO_LOGIN,
    LOGOUT,
    SET_LOGIN_METHOD
} from './ActionTypes';

interface LoginState {
    loggingIn: boolean;
    loggedIn: boolean;
    loginFailureMessage?: string;
    onWelcomePage: boolean;
    loginDetails?: JWTDTO;
    sessionHasExpired: boolean;
    loginMethod?: string;
}

const initialState: LoginState = {
    loggingIn: false,
    loggedIn: false,
    loginFailureMessage: undefined,
    onWelcomePage: true,
    sessionHasExpired: false,
    loginMethod: undefined
};

export default (state = initialState, action: any)  => {
	switch (action.type) {
        case LOGIN_REQUEST:
            return { ...state, loggingIn: action.payload, loggedIn: false, loginDetails: undefined, loginFailureMessage: undefined, sessionHasExpired: false };
        case LOGIN_SUCCESS:
            return { ...state, loggingIn: false, loggedIn: action.payload, loginFailureMessaage: undefined, sessionHasExpired: false };
        case LOGIN_FAILURE:
            return { ...state, loggingIn: false, loggedIn: false, loginDetails: undefined, loginFailureMessage: action.payload, sessionHasExpired: false };
        case GO_TO_LOGIN:
            return { ...state, onWelcomePage: false };
        case LOGOUT:
            return { ...state, loggingIn: false, loggedIn: false, loginDetails: undefined, loginFailureMessaage: undefined, onWelcomePage: true, sessionHasExpired: action.payload, loginMethod: undefined }; 
        case SET_LOGIN_METHOD:
            return { ...state, loginMethod: action.payload }
		default:
			return state;
	}
}