import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { ClickableIcon } from '../../../Shared/Components/Presentational/Icons';
import './AddPassPhotoFooter.scss';

interface AddPassPhotoModalFooterProps extends WithTranslation {
  // footerText: string;
  submitDisabled: boolean;
  isLoading?: boolean;
  primaryButtonText: string;
  onBackButtonClick?: () => void;
  onSubmit: () => void;
  fileRestrictions: ContentfulContentObject;
}

const AddPassPhotoFooter: React.FC<AddPassPhotoModalFooterProps> = props => {
  const { t } = props;

  return (
    <div className="add-pass-photo-modal-footer">
      {/* <h3>{t("addMembership.photoUpload.guidelinesTitle")}</h3> */}
      <div className='contentful-text'>
        {documentToReactComponents(props.fileRestrictions.content)}
      </div>
      {/* <p>{t("addMembership.photoUpload.guidelines")}</p> */}
      <div className="action-area">
        {props.onBackButtonClick && <ClickableIcon
          className="back-button"
          icon={faArrowLeft}
          clickAction={props.onBackButtonClick}
        />}
        <PrimaryButton
          className='submit-button'
          type="submit"
          text={props.primaryButtonText}
          disabled={props.submitDisabled || props.isLoading}
          isLoading={props.isLoading}
          onClick={props.onSubmit}
        />
      </div>
    </div>
  );
};

export default withTranslation()(AddPassPhotoFooter);