import { PostApiCall } from "./ApiCall";

export class PostFreshDeskTicketsSearchCall extends PostApiCall {
  constructor(filter: OrderedFilterDTO) {
    let url: string = "requestsAndQueries/tickets";

    super(url);

    this.body = filter;
  }
}
