import classNames from 'classnames';
import React, { Component, FormEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SecondaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { ButtonContainer } from '../../../Shared/Components/Presentational/Containers';
import { isNotFutureDate, isValidDate } from '../../../Shared/Utilities/Validation';
import './TransactionFilter.scss';
import DropdownList from '../../../Shared/Components/Presentational/DropdownList';
import { GetTransactionCategoriesCall } from '../../../Contentful/Calls/GetTransactionCategoriesCall';
import DatePicker from 'react-datepicker';
import { NativeDatePicker } from '../../../Shared/Components/Presentational/NativeDatePicker';
import moment from 'moment';
import { Mobile, Desktop } from '../../../Shared/Components/Presentational/ResponsiveViews';

interface TransactionFilterProps extends WithTranslation {
    onFilterChange: (newFilter: any) => void;
    filter: TransactionFilterDTO;
}

interface TransactionFilterState {
    fromDate: Date | undefined;
    toDate: Date | undefined;
    fromDateValid: boolean;
    toDateValid: boolean;
    selectedFilter: string;
    transactionCategories: DropdownListOption[];
}

class TransactionFilter extends Component<TransactionFilterProps, TransactionFilterState> {
    public state: TransactionFilterState = {
        fromDate: moment(this.props.filter.FromDate, 'DD/MM/YYYY').toDate(),
        toDate: moment(this.props.filter.ToDate, 'DD/MM/YYYY').toDate(),
        fromDateValid: true,
        toDateValid: true,
        selectedFilter: '',
        transactionCategories: [],
    };

    componentDidMount() {
        //get transaction types
       GetTransactionCategoriesCall().then((results) => {
            const transactionCategories = results.map((t) => {
                return {
                    label: t,
                    value: t,
                } as DropdownListOption;
            });

            transactionCategories.unshift({ label: '', value: '' });

            this.setState({ transactionCategories });
        });
    }

    handleDropdownChange = (newValue: DropdownListOption) => {
        this.setState({ selectedFilter: newValue.value }, () => this.search());
    };

    setDate(date: Date | null, type: 'from' | 'to') {
        const isValid = isNotFutureDate(date);
        let date2 = undefined;
        if (date !== null) {
            date2 = date;
        }
        if (type === 'from') {
            this.setState({ fromDate: date2, fromDateValid: isValid });
        } else {
            this.setState({ toDate: date2, toDateValid: isValid });
        }
    }

    searchButtonClicked(event: FormEvent) {
        event.preventDefault();
        this.search();
    }

    search = () => {
        const newFilter: TransactionFilterDTO = {
            ...this.props.filter,
            FromDate: moment(this.state.fromDate).format('DD/MM/YYYY'),
            ToDate: moment(this.state.toDate).format('DD/MM/YYYY'),
            TransactionCategory: this.state.selectedFilter,
            PageSize: 10,
        };
        this.props.onFilterChange(newFilter);
    };

    render() {
        return (
            <form onSubmit={(event) => this.searchButtonClicked(event)}>
                <div className="filter-container">
                    <div className="filter-date">
                        <label htmlFor="fromDate">{this.props.t('myAccount.filter.fromDate')}</label>
                        <Desktop>
                            <DatePicker
                                selected={this.state.fromDate}
                                onChange={(e) => this.setDate(e, 'from')}
                                className={classNames('form-input', 'date-filter', {
                                    invalid: !this.state.fromDateValid,
                                })}
                                dateFormat="dd/MM/yyyy"
                            />
                        </Desktop>
                        <Mobile>
                            <NativeDatePicker
                                name="fromDate"
                                placeholder="DD/MM/YYYY"
                                onChange={(e) => this.setDate(e, 'from')}
                                selected={this.state.fromDate}
                                invalid={!this.state.fromDateValid}
                            />
                        </Mobile>
                    </div>
                    <div className="filter-date">
                        <label htmlFor="toDate">{this.props.t('myAccount.filter.toDate')}</label>
                        <Desktop>
                            <DatePicker
                                selected={this.state.toDate}
                                onChange={(e) => this.setDate(e, 'to')}
                                className={classNames('form-input', 'date-filter', {
                                    invalid: !this.state.toDateValid,
                                })}
                                dateFormat="dd/MM/yyyy"
                            />
                        </Desktop>
                        <Mobile>
                            <NativeDatePicker
                                name="toDate"
                                placeholder="DD/MM/YYYY"
                                onChange={(e) => this.setDate(e, 'to')}
                                selected={this.state.toDate}
                                invalid={!this.state.toDateValid}
                            />
                        </Mobile>
                    </div>
                    <div className="search-button">
                        <ButtonContainer>
                            <SecondaryButton
                                text={this.props.t('myAccount.filter.search')}
                                disabled={!this.state.fromDateValid || !this.state.toDateValid}
                                type="submit"
                            ></SecondaryButton>
                        </ButtonContainer>
                    </div>
                    <div className="filter-type">
                        <label htmlFor="transactionType">{this.props.t('myAccount.filter.byTransactionType')}</label>
                        <DropdownList
                            classNamePrefix="dropdown"
                            selectedOption={this.props.t('myAccount.filter.transactionCategory')}
                            options={this.state.transactionCategories}
                            onSelectionChanged={this.handleDropdownChange}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

export default withTranslation()(TransactionFilter);
