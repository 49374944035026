import React, { Component, ChangeEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  ModalFooter,
  ModalHeader,
} from "../../../Shared/Components/Presentational/Modal";
import "./MakeAPaymentModal.scss";
import { PrimaryButton } from "../../../Shared/Components/Presentational/Buttons";
import DropdownList from "../../../Shared/Components/Presentational/DropdownList";
import { Get, Post } from "../../../Api/ApiService";
import { GetCountriesCall } from "../../../Api/Calls/GetCountriesCall";
import { PostMakeAPaymentCall } from "../../../Api/Calls/PostMakeAPaymentCall";
import { sagepayUrl } from "../../../Shared/Environment";

interface MakeAPaymentModalCardAddressProps extends WithTranslation {
  onBackButtonClick: () => void;
  paymentAmount: number;
  accountNumber: string;
  makePayment: (paymentDetails: PaymentDetails) => void;
}

interface MakeAPaymentModalCardAddressState {
  address: {
    firstname: string;
    surname: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    postCode: string;
    country: string;
  };
  countries: DropdownListOption[];
  paymentRequestDetails: SagePayRequestDTO;
  hasValidAddress: boolean;
}

class MakeAPaymentModalCardAddress extends Component<MakeAPaymentModalCardAddressProps> {
  constructor(props: MakeAPaymentModalCardAddressProps) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.proceed = this.proceed.bind(this);
  }

  private form = React.createRef<HTMLFormElement>();

  public state: MakeAPaymentModalCardAddressState = {
    address: {
      firstname: "",
      surname: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postCode: "",
      country: "",
    },
    countries: [],
    paymentRequestDetails: {
      VPSProtocol: "",
      TxType: "",
      Vendor: "",
      Crypt: "",
      ThirdPartyPaymentReference: "",
    },
    hasValidAddress: false,
  };

  componentDidMount() {
    Get<CodeForeignKeyItem[]>(new GetCountriesCall()).then(
      (result) => {
        let address = { ...this.state.address };
        address.country = "GB";

        this.setState({
          countries: result.data.map((c) => {
            return { label: c.Name, value: c.Code } as DropdownListOption;
          }),
          address: address,
        });
      },
      (err) => {}
    );
  }

  handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    let address = { ...this.state.address } as any;
    address[name] = value;
    this.setState({ address });
  }

  handleDropdownChange(ddlo: DropdownListOption) {
    if (ddlo) {
      let address = { ...this.state.address };
      address.country = ddlo.value;

      this.setState({ address });
    }
  }

  proceed() {
    const paymentDetails = {
      Amount: this.props.paymentAmount,
      FirstName: this.state.address.firstname,
      Surname: this.state.address.surname,
      AddressLine1: this.state.address.addressLine1,
      AddressLine2: this.state.address.addressLine2,
      City: this.state.address.city,
      PostCode: this.state.address.postCode,
      Country: this.state.address.country,
    } as SagePayPaymentDetailsDTO;

    Post<SagePayRequestDTO>(
      new PostMakeAPaymentCall(this.props.accountNumber, paymentDetails)
    ).then((res) => {
      // dispatch action to set
      this.setState({ paymentRequestDetails: res.data }, () => {
        if (this.state.paymentRequestDetails.VPSProtocol) {
          this.props.makePayment({
            amount: this.props.paymentAmount,
            thirdPartyPaymentRef: res.data.ThirdPartyPaymentReference,
          });
          (this.form as any).current.submit();
        }
      });
    });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <ModalHeader text={t("myAccount.payment.cardHolder")} />
        <section className="payment-details-section">
          <div className="payment-details card-address">
            <form action={sagepayUrl} autoComplete="on" ref={this.form}>
              <input
                type="hidden"
                name="VPSProtocol"
                value={this.state.paymentRequestDetails.VPSProtocol}
              />
              <input
                type="hidden"
                name="TxType"
                value={this.state.paymentRequestDetails.TxType}
              />
              <input
                type="hidden"
                name="Vendor"
                value={this.state.paymentRequestDetails.Vendor}
              />
              <input
                type="hidden"
                name="Crypt"
                value={this.state.paymentRequestDetails.Crypt}
              />

              <div className="payment-details-card-address-field firstname">
                <label htmlFor="firstname">
                  {t("myDetailsModal.firstname")}*
                </label>
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  className="form-input"
                  autoComplete="payment firstname"
                  required
                  value={this.state.address.firstname}
                  onChange={this.handleInputChange}
                />
                {!this.state.address.firstname && (
                  <label className="error">
                    {t("myDetailsModal.firstname")} is required
                  </label>
                )}
              </div>
              <div className="payment-details-card-address-field surname">
                <label htmlFor="surname">{t("myDetailsModal.surname")}*</label>
                <input
                  id="surname"
                  name="surname"
                  type="text"
                  className="form-input"
                  autoComplete="payment surname"
                  required
                  value={this.state.address.surname}
                  onChange={this.handleInputChange}
                />
                {!this.state.address.surname && (
                  <label className="error">
                    {t("myDetailsModal.surname")} is required
                  </label>
                )}
              </div>
              <div className="payment-details-card-address-field address1-field">
                <label htmlFor="addressLine1">
                  {t("myDetailsModal.address1")}*
                </label>
                <input
                  id="addressLine1"
                  name="addressLine1"
                  type="text"
                  className="form-input"
                  autoComplete="payment address1"
                  required
                  value={this.state.address.addressLine1}
                  onChange={this.handleInputChange}
                />
                {!this.state.address.addressLine1 && (
                  <label className="error">
                    {t("myDetailsModal.address1")} is required
                  </label>
                )}
              </div>
              <div className="payment-details-card-address-field address2-field">
                <label htmlFor="addressLine2">
                  {t("myDetailsModal.address2")}
                </label>
                <input
                  id="addressLine2"
                  name="addressLine2"
                  type="text"
                  className="form-input"
                  autoComplete="payment address2"
                  required
                  value={this.state.address.addressLine2}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="payment-details-card-address-field city-field">
                <label htmlFor="city">{t("myDetailsModal.address3")}*</label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  className="form-input"
                  autoComplete="payment city"
                  required
                  value={this.state.address.city}
                  onChange={this.handleInputChange}
                />
                {!this.state.address.city && (
                  <label className="error">
                    {t("myDetailsModal.address3")} is required
                  </label>
                )}
              </div>
              <div className="payment-details-card-address-field">
                <label htmlFor="postCode">
                  {t("myDetailsModal.postcode")}*
                </label>
                <input
                  id="postCode"
                  name="postCode"
                  type="text"
                  className="form-input"
                  autoComplete="payment postcode"
                  required
                  value={this.state.address.postCode}
                  onChange={this.handleInputChange}
                />
                {!this.state.address.postCode && (
                  <label className="error">
                    {t("myDetailsModal.postcode")} is required
                  </label>
                )}
              </div>
              <div className="payment-details-card-address-field country">
                <label htmlFor="country">{t("myDetailsModal.country")}*</label>
                <DropdownList
                  options={this.state.countries}
                  onSelectionChanged={this.handleDropdownChange}
                  selectedOption={
                    this.state.countries &&
                    this.state.countries.filter(
                      (c) => c.value === this.state.address.country
                    )[0]
                  }
                />
                {!this.state.address.country && (
                  <label className="error">
                    {t("myDetailsModal.country")} is required
                  </label>
                )}
              </div>
            </form>
          </div>
        </section>
        <ModalFooter onBackButtonClick={this.props.onBackButtonClick}>
          <PrimaryButton
            onClick={this.proceed}
            className="footer-button"
            disabled={
              !this.state.address.firstname ||
              !this.state.address.surname ||
              !this.state.address.addressLine1 ||
              !this.state.address.city ||
              !this.state.address.postCode ||
              !this.state.address.country
            }
            text={t("general.proceed")}
          ></PrimaryButton>
        </ModalFooter>
      </>
    );
  }
}

export default withTranslation()(MakeAPaymentModalCardAddress);
