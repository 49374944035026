import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../RootReducer';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { updateTransactionFilter } from '../../Redux/ActionCreators';
import MyAccountPage from '../Presentational/MyAccountPage';
import MyAccountPageMobile from '../Presentational/MyAccountPage.mobile';
import {
    SET_IS_MY_BANK_DETAILS_MODAL_OPEN,
    SET_IS_HAULFRYN_BANK_DETAILS_MODAL_OPEN,
    PAYMENT_CONFIRMED,
    PAYMENT_CONFIRMING,
} from '../../Redux/ActionTypes';
import { getMyBankDetails, downloadStatement, downloadPdfStatement, getHaulfrynBankDetails } from '../../Redux/ActionCreators';

interface MyAccountProps {
    dispatch: any;
    selectedLodge: SelectedLodgeDTO;
    isHeaderOpen: boolean;
    filter: TransactionFilterDTO;
    searchResults: PagedResultDTO;
    isSearchingTransactions: boolean;
    onFilterChange: (newFilter: TransactionFilterDTO) => void;
    myBankDetails?: MyBankDetailsDTO;
    openMyBankDetailsModal: () => void;
    getMyBankDetails: any;
    downloadStatement: () => void;
    downloadPdfStatement: () => void;
    bankTransferClick: () => void;
    openMakeAPaymentModal: () => void;
    setPaymentWindowOpen: (isOpen: boolean) => void;
    haulfryBankDetails?: HaulfrynBankDetailsDTO;
    isDownloadingStatement: boolean;
    isDownloadingPdfStatement: boolean;
    isMakeAPaymentModalOpen: boolean;
    paymentDetails?: PaymentDetails;
    paymentConfirmed: (newBalance: number) => void;
    paymentConfirming: () => void;
}

class MyAccount extends Component<MyAccountProps> {
    componentDidMount() {
        this.props.getMyBankDetails();
    }
    render() {
        return (
            <>
                <Desktop>
                    <MyAccountPage
                        isHeaderOpen={this.props.isHeaderOpen}
                        pageSize={this.props.filter.PageSize}
                        isSearchingTransactions={this.props.isSearchingTransactions}
                        filter={this.props.filter}
                        onFilterChange={this.props.onFilterChange}
                        searchResults={this.props.searchResults}
                        myBankDetails={this.props.myBankDetails}
                        openMyBankDetailsModal={this.props.openMyBankDetailsModal}
                        downloadStatement={this.props.downloadStatement}
                        isDownloadingStatement={this.props.isDownloadingStatement}
                        downloadPdfStatement={this.props.downloadPdfStatement}
                        isDownloadingPdfStatement={this.props.isDownloadingPdfStatement}
                        ownerAccountNumber={this.props.selectedLodge.AccountNo}
                        ownerAccountId={this.props.selectedLodge.AccountId}
                        accountBalance={this.props.selectedLodge.AccountBalance}
                        bankTransferClick={this.props.bankTransferClick}
                        setPaymentWindowOpen={this.props.setPaymentWindowOpen}
                        isMakeAPaymentModalOpen={this.props.isMakeAPaymentModalOpen}
                        haulfryBankDetails={this.props.haulfryBankDetails}
                        paymentDetails={this.props.paymentDetails}
                        paymentConfirmed={this.props.paymentConfirmed}
                        paymentConfirming={this.props.paymentConfirming}
                    />
                </Desktop>
                <Mobile>
                    <MyAccountPageMobile
                        pageSize={this.props.filter.PageSize}
                        isSearchingTransactions={this.props.isSearchingTransactions}
                        filter={this.props.filter}
                        onFilterChange={this.props.onFilterChange}
                        searchResults={this.props.searchResults}
                        myBankDetails={this.props.myBankDetails}
                        openMyBankDetailsModal={this.props.openMyBankDetailsModal}
                        downloadStatement={this.props.downloadStatement}
                        isDownloadingStatement={false}
                        downloadPdfStatement={this.props.downloadPdfStatement}
                        isDownloadingPdfStatement={this.props.isDownloadingPdfStatement}
                        ownerAccountNumber={this.props.selectedLodge.AccountNo}
                        ownerAccountId={this.props.selectedLodge.AccountId}
                        accountBalance={this.props.selectedLodge.AccountBalance}
                        bankTransferClick={this.props.bankTransferClick}
                        setPaymentWindowOpen={this.props.setPaymentWindowOpen}
                        isMakeAPaymentModalOpen={this.props.isMakeAPaymentModalOpen}
                        haulfryBankDetails={this.props.haulfryBankDetails}
                        paymentDetails={this.props.paymentDetails}
                        paymentConfirmed={this.props.paymentConfirmed}
                        paymentConfirming={this.props.paymentConfirming}
                    />
                </Mobile>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        selectedLodge: state.user.lodge,
        isHeaderOpen: state.application.isHeaderOpen,
        filter: state.account.transactionFilter,
        searchResults: state.account.transactions,
        isSearchingTransactions: state.account.isSearchingTransactions,
        myBankDetails: state.account.myBankDetails,
        isDownloadingStatement: state.account.isDownloadingStatement,
        isDownloadingPdfStatement: state.account.isDownloadingPdfStatement,
        isMakeAPaymentModalOpen: state.account.isMakeAPaymentModalOpen,
        haulfryBankDetails: state.account.haulfrynBankDetails,
        paymentDetails: state.account.paymentDetails,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        downloadStatement: () => dispatch(downloadStatement()),
        downloadPdfStatement: () => dispatch(downloadPdfStatement()),
        getMyBankDetails: () => dispatch(getMyBankDetails()),
        openMyBankDetailsModal: () => dispatch({ type: SET_IS_MY_BANK_DETAILS_MODAL_OPEN, payload: true }),
        bankTransferClick: () => dispatch(getHaulfrynBankDetails()),
        onFilterChange: (newFilter: TransactionFilterDTO) => {
            dispatch(updateTransactionFilter(newFilter));
        },
        setPaymentWindowOpen: (isOpen: boolean) => {
            dispatch({ type: SET_IS_HAULFRYN_BANK_DETAILS_MODAL_OPEN, payload: isOpen });
        },
        paymentConfirmed: (newBalance: number) => dispatch({ type: PAYMENT_CONFIRMED, payload: newBalance }),
        paymentConfirming: () => dispatch({ type: PAYMENT_CONFIRMING }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
