import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../Shared/Components/Presentational/Buttons';
import { Desktop, Mobile } from '../../../../Shared/Components/Presentational/ResponsiveViews';
import './MembershipPageStyles.scss';
import './RenewMembershipStyles.scss';
import DropdownList from '../../../../Shared/Components/Presentational/DropdownList';
import { Entry } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface SelectMembershipTypeFormProps extends WithTranslation {
  contentfulMembershipTypes: Entry<ContentfulMembershipType>[];
  termDurations: DropdownListOption[][];
  ownerMembershipsAvailability: OwnerMembershipAvailabilityDTO;
  onValueSelected: (membershipType: string, termFormula: string) => void;
}

const SelectMembershipTypeForm: React.FC<SelectMembershipTypeFormProps> = (props: SelectMembershipTypeFormProps) => {
  const { t } = props;
  const [selectedDurations, setSelectedDurations] = useState<any[]>([]);
  const [noSelectionError, setNoSelectionError] = useState<boolean>(false);

  // TODO - check if we can just use ownerMembershipsAvailability.available
  const ownerMembershipsLeft = () => {
    return !!props.ownerMembershipsAvailability &&
      props.ownerMembershipsAvailability.numberUsed < props.ownerMembershipsAvailability.allocated
  }

  const calcColumns = () => {
    let columnPercentages = "";
    if (props.contentfulMembershipTypes) {
      let columns = props.contentfulMembershipTypes.length;
      let percentage = 100 / columns;
      for (let i = 0; i < columns; i++) {
        columnPercentages += percentage.toString() + "% ";
      }
    }
    return columnPercentages;
  }

  useEffect(() => {
    // The annual membership option only has one term length so there is no drop down for it
    // So, this bit of code preselects the term duration for the annual membership to 'Annual'
    let array: any[] = [];
    for (let i = 0; i < props.contentfulMembershipTypes.length; i++) {
      if (props.termDurations[i].length === 1) {
        array[i] = props.termDurations[i][0];
      }
    }
    setSelectedDurations(array);
  }, [props.termDurations]);

  const handleMembershipSelection = (type: number) => {
    if (selectedDurations[type] !== undefined) {
      const membershipType = props.contentfulMembershipTypes[type].sys.id;
      const termFormula = selectedDurations[type];
      props.onValueSelected(membershipType, termFormula);
    } else {
      setNoSelectionError(true);
    }
  }

  return (
    <>
      <Desktop>
        <div className="row membership-grid" style={{ height: "35%", marginTop: "15px", gridTemplateColumns: calcColumns() }}>
          {props.contentfulMembershipTypes.map(type => (
            <div className="column-md3-type content-column">
              <h1>{type.fields.publicName}</h1>
              {type.fields.description.content && (
                <div className='contentful-text-membership-type'>
                  {documentToReactComponents(type.fields.description)}
                </div>
              )}
            </div>
          ))}

          {props.contentfulMembershipTypes.map((type, key) => (
            <div className='column-md3-type center-content'>
              {(props.termDurations && props.termDurations[key] && props.termDurations[key].length > 1) ? (
                <div style={{ marginTop: "12px", minWidth: "300px" }}>
                  <DropdownList
                    options={props.termDurations[key]}
                    placeholder={"Select Duration"}
                    onSelectionChanged={(term) => setSelectedDurations({ ...selectedDurations, [key]: term })}
                    selectedOption={selectedDurations[key]}
                  />
                </div>
              ) : (props.termDurations && props.termDurations[key] && props.termDurations[key].length === 1) ? (
                <div style={{ marginTop: "12px", minWidth: "300px", textAlign: "center" }}>
                  <p style={{ margin: "0" }}>{props.termDurations[key][0].label}</p>
                </div>
              ) : (
                <div style={{ marginTop: "12px", minWidth: "300px", textAlign: "center" }}>
                  <p style={{ margin: "0" }}>Term Unknown</p>
                </div>
              )}
            </div>
          ))}

          {props.contentfulMembershipTypes.map((type, key) => (
            <div className='column-md3-type center-content'>
              <div>
                {type.fields.debitsQuota ? (
                  <p style={{ margin: "0" }} >
                    {props.ownerMembershipsAvailability.numberUsed} of {props.ownerMembershipsAvailability.allocated} Club memberships used
                  </p>
                ) : (
                  <p style={{ margin: "0" }}></p>
                )}
              </div>
            </div>
          ))}

          {props.contentfulMembershipTypes.map((type, key) => (
            <div className="column-md3-type button-column center-content">
              <div>
                {(!type.fields.debitsQuota || ownerMembershipsLeft()) ? (
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    <div className='row'>
                      <PrimaryButton
                        text={"Select"}
                        id="select"
                        type="submit"
                        onClick={() => {
                          handleMembershipSelection(key);
                        }}
                        className={"membership-btn"}
                      />
                    </div>

                  </div>
                ) : (
                  <p style={{ color: "red", marginTop: "80px" }}>No Club memberships available</p>
                )}
              </div>
            </div>
          ))}
        </div>
        {noSelectionError &&
          <>
            <br />
            <p style={{ textAlign: "center", color: "red" }}>
              {
                // props.selectedContentfulMembershipTypeId && 
                t("addMembership.errors.isMembershipType")}
            </p>
          </>
        }

      </Desktop>

      <Mobile>
        <div style={{ marginTop: "15px" }}>
          {props.contentfulMembershipTypes.map((type, key) => (
            <div className='column-mobile' style={key < (props.contentfulMembershipTypes.length - 1) ? { marginBottom: "50px" } : undefined}>
              <h2 style={{ textAlign: "center" }}>{type.fields.publicName}</h2>
              {type.fields.description.content && (
                <div className='contentful-text-membership-type'>
                  {documentToReactComponents(type.fields.description)}
                </div>
              )}

              {(props.termDurations && props.termDurations[key] && props.termDurations[key].length > 1) ? (
                <div style={{ marginTop: "12px" }}>
                  <DropdownList
                    options={props.termDurations[key]}
                    placeholder={"Select Duration"}
                    onSelectionChanged={(term) => setSelectedDurations({ ...selectedDurations, [key]: term })}
                    selectedOption={selectedDurations[key]}
                  />
                </div>
              ) : (props.termDurations && props.termDurations[key] && props.termDurations[key].length === 1) ? (
                <div style={{ marginTop: "12px", minWidth: "300px", textAlign: "center" }}>
                  <p style={{ margin: "0" }}>{props.termDurations[key][0].label}</p>
                </div>
              ) : (
                <div style={{ marginTop: "12px", minWidth: "300px", textAlign: "center" }}>
                  <p style={{ margin: "0" }}>Term Unknown</p>
                </div>
              )}

              <div style={{ textAlign: "center", fontWeight: "bold" }}>
                {(!type.fields.debitsQuota ||
                  props.ownerMembershipsAvailability.numberUsed < props.ownerMembershipsAvailability.allocated) ? (
                  <div>
                    <PrimaryButton
                      text={"Select"}
                      id="select"
                      type="submit"
                      onClick={() => {
                        handleMembershipSelection(key);
                      }}
                      className={"membership-btn"}
                    />
                    <div>
                      {type.fields.debitsQuota ? (
                        <p style={{ margin: "0" }} >
                          {props.ownerMembershipsAvailability && props.ownerMembershipsAvailability.numberUsed} of {props.ownerMembershipsAvailability && props.ownerMembershipsAvailability.allocated} Club memberships used
                        </p>
                      ) : (
                        <p style={{ margin: "0" }}></p>
                      )}
                    </div>
                  </div>
                ) : (
                  <p style={{ color: "red", marginTop: "80px" }}>No Club memberships available</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </Mobile>
    </>
  )
}

export default withTranslation()(SelectMembershipTypeForm);