import React, { useEffect } from "react";
import Home from "./Home/Components/Container/Home";
import MemberHomePage from "./Home/Components/Container/MemberHomePage";
import Login from "./Login/Components/Container/Login";
import ProtectedRoute from "./ProtectedRoute";
import * as Routes from "./Constants/Routes";
import MyCalendar from "./Calendar/Components/Container/MyCalendar";
import MyGuests from "./Guests/Components/Container/MyGuests";
import MyMembershipsDashboard from "./Guests/Components/Container/MyMembershipsDashboard";
import MyParkAndHome from "./Information/Components/Container/MyParkAndHome";
import MyAccount from "./Account/Components/Container/MyAccount";
import MyRequestsAndQueries from "./RequestsAndQueries/Components/Container/MyRequestsAndQueries";
import DefaultLayoutRoute from "./DefaultLayoutRoute";
import { Switch, Route } from "react-router";
import { LOGIN_SUCCESS } from "./Login/Redux/ActionTypes";
import { connect } from "react-redux";
import ConfirmEmail from "./ConfirmEmail";
import ForgottenPassword from "./ForgottenPassword";
import ImportantInformation from "./Information/Components/Container/ImportantInformation";
import ParkNews from "./Information/Components/Container/ParkNews";
import Register from "./Login/Components/Container/Register";
import ConfirmAccount from "./ConfirmAccount";
import CookiePopup from "./Shared/Components/Presentational/CookiePopup";
import AddMembership from './Guests/Components/Container/AddMembership';
import ManageActiveMember from './Guests/Components/Container/ManageActiveMember';
import ReferMembership from "./Guests/Components/Container/ReferMembership";
import ParQForm from "./ParQForm/Components/Container/ParQForm";
import PaymentSuccess from './Guests/Components/LandingPages/PaymentSuccess';
import PaymentCancelled from './Guests/Components/LandingPages/PaymentCancelled';
import DocuSignSigned from "./Guests/Components/LandingPages/DocuSignSigned";
import SelectPayment from "./Guests/Components/Presentational/AddMembershipSubPages/SelectPayment";
import MembershipAgreement from "./Guests/Components/LandingPages/MembershipAgreement";
import InvalidSessionPage from "./Guests/Components/LandingPages/InvalidSessionPage";
import ConfirmMembership from "./Login/Components/Container/ConfirmMembership";
import UserRoles from "./Constants/UserRoles";
import RenewMembership from "./Guests/Components/Container/RenewMembership";
import EditMember from "./Guests/Components/Container/EditMember";

interface RootAppProps {
  restoreLogin: () => void;
  userRoles: UserRoleDTO[];
}

const RootApp: React.FC<RootAppProps> = (props) => {
  const { restoreLogin } = props;
  useEffect(() => {
    if (sessionStorage.getItem("access_token")) {
      restoreLogin();
    }
  }, [restoreLogin]);

  return (
    <>
      <Switch>
        {(props.userRoles && !UserRoles.IsOwner(props.userRoles)) ? (
          <ProtectedRoute
            path={Routes.Home}
            exact={true}
            protectedComponent={MemberHomePage}
          />
        ) : (
          <ProtectedRoute
            path={Routes.Home}
            exact={true}
            protectedComponent={Home}
          />
        )}

        <Route path={Routes.Login} component={Login} />
        <Route path={Routes.Login + "?:params"} component={Login} />
        <Route path={Routes.ForgottenPassword} component={ForgottenPassword} />
        <Route path={Routes.ConfirmEmail} component={ConfirmEmail} />
        <Route path={Routes.ConfirmAccount} component={ConfirmAccount} />
        <Route path={Routes.Register} component={Register} />
        <Route path={Routes.ParQForm} component={ParQForm} />
        <Route path={Routes.ParQForm + "?:params"} component={ParQForm} />
        <Route path={Routes.ConfirmMembership} component={ConfirmMembership} />
        <Route path={Routes.ConfirmMembership + "?:params"} component={ConfirmMembership} />
        <DefaultLayoutRoute>
          <ProtectedRoute
            path={Routes.MyCalendar}
            protectedComponent={MyCalendar}
          />
          <ProtectedRoute
            path={Routes.MyParkAndHome}
            protectedComponent={MyParkAndHome}
          />
          <ProtectedRoute
            path={Routes.MyGuests}
            protectedComponent={MyMembershipsDashboard}
          />
          <ProtectedRoute
            path={Routes.MyGuests2}
            protectedComponent={MyGuests}
          />
          <ProtectedRoute
            path={Routes.MyAccount}
            protectedComponent={MyAccount}
          />
          <ProtectedRoute
            path={Routes.ImportantInformation}
            protectedComponent={ImportantInformation}
          />
          <ProtectedRoute
            path={Routes.ParkNews}
            protectedComponent={ParkNews}
          />
          <ProtectedRoute
            path={Routes.MyRequestsAndQueries}
            protectedComponent={MyRequestsAndQueries}
          />
          <ProtectedRoute
            path={Routes.AddMembership}
            protectedComponent={AddMembership}
          />
          <ProtectedRoute
            path={Routes.ReferMembership}
            protectedComponent={ReferMembership}
          />
          <ProtectedRoute
            path={Routes.ManageActiveMemberDetails}
            protectedComponent={ManageActiveMember}
          />
          <ProtectedRoute
            path={Routes.EditMember}
            protectedComponent={EditMember}
          />
          <ProtectedRoute
            path={Routes.RenewMembership}
            protectedComponent={RenewMembership}
          />
          <ProtectedRoute
            path={Routes.PaymentSuccess}
            protectedComponent={PaymentSuccess}
          />
          <ProtectedRoute
            path={Routes.PaymentSuccess + "?:params"}
            protectedComponent={PaymentSuccess}
          />
          <ProtectedRoute
            path={Routes.PaymentCancelled}
            protectedComponent={PaymentCancelled}
          />
          <ProtectedRoute
            path={Routes.SelectPayment}
            protectedComponent={SelectPayment}
          />
          <ProtectedRoute
            path={Routes.MembershipAgreement}
            protectedComponent={MembershipAgreement}
          />
          <ProtectedRoute
            path={Routes.DocuSignSigned}
            protectedComponent={DocuSignSigned}
          />
          <ProtectedRoute
            path={Routes.DocuSignSigned + "?:params"}
            protectedComponent={DocuSignSigned}
          />
          <ProtectedRoute
            path={Routes.InvalidSessionPage}
            protectedComponent={InvalidSessionPage}
          />
        </DefaultLayoutRoute>
      </Switch>
      <CookiePopup></CookiePopup>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userRoles: state.user.userRoles,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    restoreLogin: () => dispatch({ type: LOGIN_SUCCESS, payload: true }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RootApp);
