import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as Routes from '../../../Constants/Routes';
import ImportantInformationPage from '../Presentational/ImportantInformationPage';
import { SET_SELECTED_IMPORTANT_INFORMATION } from '../../Redux/ActionTypes';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import ImportantInformationPageMobile from '../Presentational/ImportantInformationPage.mobile';
import { Entry } from 'contentful';

interface ImportantInformationProps extends RouteComponentProps {
    selectedId: string;
    importantInformation: Entry<ContentfulHTMLContentBlock>[];
    isHeaderOpen: boolean;
    setSelectedImportantInformation: (selectedImportantInformationId: string) => void;
}

const ImportantInformation: React.FC<ImportantInformationProps> = ({
    selectedId,
    importantInformation,
    history,
    isHeaderOpen,
    setSelectedImportantInformation,
}) => {
    useEffect(() => {
        if (!selectedId) {
            history.push(Routes.MyParkAndHome);
        }
    }, [selectedId, history]);

    return (
        <>
            <Desktop>
                <ImportantInformationPage
                    isHeaderOpen={isHeaderOpen}
                    importantInformation={importantInformation}
                    selectedId={selectedId}
                    setSelectedImportantInformation={setSelectedImportantInformation}
                />
            </Desktop>
            <Mobile>
                <ImportantInformationPageMobile
                    isHeaderOpen={isHeaderOpen}
                    importantInformation={importantInformation}
                    selectedId={selectedId}
                    setSelectedImportantInformation={setSelectedImportantInformation}
                />
            </Mobile>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSelectedImportantInformation: (selectedImportantInformationId: string) => {
            dispatch({ type: SET_SELECTED_IMPORTANT_INFORMATION, payload: selectedImportantInformationId });
            window.scrollTo(0, 0);
        },
    };
};

const mapStateToProps = (state: any) => {
    return {
        selectedId: state.information.selectedImportantInformationId,
        importantInformation: state.information.importantInformation,
        isHeaderOpen: state.application.isHeaderOpen,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ImportantInformation));
