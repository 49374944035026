import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader } from '../../../Shared/Components/Presentational/Modal';
import { isInternationalPhoneNumber } from '../../../Shared/Utilities/Validation';

interface AddBookingModalMobileNumbersFormProps {
    className?: string
    carRegistrationNumber1: string;
    carRegistrationNumber1Change: (val: string) => void;
    carRegistrationNumber2: string;
    carRegistrationNumber2Change: (val: string) => void;
}

const AddBookingModalMobileNumbersForm: React.FC<AddBookingModalMobileNumbersFormProps> = (props) => {
    const { t } = useTranslation();

    return (
        <form className={props.className}>
            <ModalHeader text={t('myCalendar.addBooking.vehicleAccessTitle')} />
            <p
                className={classNames('info-message show')}
                dangerouslySetInnerHTML={{ __html: t('myCalendar.addBooking.vehicleAccessInfo') }}
            ></p>
            <div className="add-booking-field-full">
                <label htmlFor="car-registration-number-1">
                    {t('myCalendar.addBooking.vehicleNumber1')}
                </label>
                <input
                    name="car-registration-number-1"
                    type="text"
                    className="form-input"
                    maxLength={10}
                    value={props.carRegistrationNumber1}
                    onChange={(e)=> props.carRegistrationNumber1Change(e.target.value.toUpperCase())}
                    placeholder={t('myCalendar.addBooking.vehicleNumber1Placeholder')}
                />
            </div>
            <div className="add-booking-field-full">
                <label htmlFor="car-registration-number-2">
                    {t('myCalendar.addBooking.vehicleNumber2')}
                </label>
                <input
                    name="car-registration-number-2"
                    type="text"
                    className="form-input"
                    maxLength={10}
                    value={props.carRegistrationNumber2}
                    onChange={(e)=> props.carRegistrationNumber2Change(e.target.value.toUpperCase())}
                    placeholder={t('myCalendar.addBooking.vehicleNumber2Placeholder')}
                />
            </div>
        </form>)

};

export default AddBookingModalMobileNumbersForm;