import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ModalFooter, ModalHeader } from '../../../Shared/Components/Presentational/Modal';
import './MakeAPaymentModal.scss';

interface MakeAPaymentModalBankProps extends WithTranslation {
    onBackButtonClick: () => void;
    bankAccountDetails?: HaulfrynBankDetailsDTO;
}

class MakeAPaymentModalBank extends Component<MakeAPaymentModalBankProps> {
    render() {
        const { t } = this.props;
        return (
            <>
                <ModalHeader text={t('myAccount.payment.bankHeader')} />
                <p>{t('myAccount.payment.bankTransfer')}</p>
                <section className="payment-details-section">
                    {this.props.bankAccountDetails && (
                        <div className="payment-details">
                            <div>
                                <p className="title">{t('myAccount.accountName')}</p>
                                <p className="value">{this.props.bankAccountDetails.AccountName}</p>
                            </div>
                            <div>
                                <p className="title">{t('myAccount.myBankDetails.sortCode')}</p>
                                <p className="value">{this.props.bankAccountDetails.SortCode}</p>
                            </div>
                            <div>
                                <p className="title">{t('myAccount.myBankDetails.accountNumber')}</p>
                                <p className="value">{this.props.bankAccountDetails.AccountNumber}</p>
                            </div>
                        </div>
                    )}
                </section>
                <ModalFooter onBackButtonClick={this.props.onBackButtonClick} />
            </>
        );
    }
}

export default withTranslation()(MakeAPaymentModalBank);
