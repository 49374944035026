import i18n from "i18next";
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';

const i18nPromise = i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
        interpolation: {
            escapeValue: false
        },
        lng: 'en-GB',
        whitelist: false,
        debug: false,
        react: {
            wait: false
        },
        fallbackLng: ['en-GB'],
        load: "currentOnly",
        backend: {
            backends: [LocalStorageBackend, XHR],
            backendOptions: [
                {
                    prefix: 'i18next_',
                    expirationTime: 7 * 24 * 60 * 60 * 1000,
                    versions: {},
                    store: window.sessionStorage
                },
                {
                    loadPath: '/api/translation/GetByLanguage/{{lng}}'
                }
            ]
        }
    });

i18n.changeLanguage('en-GB');

registerLocale('enGB', enGB);
setDefaultLocale('enGB');

export default i18nPromise;