import React, { useState } from 'react';
import FrequencyFormula from './../../../../Constants/FrequencyFormula';

interface MemberPaymentContainerProps {
  currencySymbol: string;
  membership: MemberBreakdownDTO;
  frequencyFormula: string;
}

const MemberPaymentContainer: React.FC<MemberPaymentContainerProps> = (props: MemberPaymentContainerProps) => {
  const [paymentPlan, setPaymentPlan] = useState(props.membership.PaymentPlans.find(plan => plan.FrequencyFormula === props.frequencyFormula));

  if (paymentPlan && (paymentPlan.InitialFee > 0 || paymentPlan.InitialPayment > 0 || paymentPlan.RecurringFee > 0)) {
    return (
      <div className='member-breakdown-container'>
        {props.frequencyFormula === FrequencyFormula.OneOff ? (
          <p>{props.membership.FirstName + " " + props.membership.LastName + ": " + props.currencySymbol + paymentPlan.InitialPayment}</p>
        ) : (
          <>
            <h4>{props.membership.FirstName + " " + props.membership.LastName}</h4>
            <ul>
              {(paymentPlan && paymentPlan.InitialFee > 0) &&
                (paymentPlan.RecurringFee > 0 ? (
                  <li><p>Initial fee: {props.currencySymbol + paymentPlan.InitialPayment}</p></li>
                ) : (
                  <li><p>One off fee: {props.currencySymbol + paymentPlan.InitialPayment}</p></li>
                ))}
              {paymentPlan && paymentPlan.RecurringFee > 0 && (
                <>
                  {paymentPlan.FrequencyFormula === FrequencyFormula.Monthly && (
                    <li><p>Monthly fee: {props.currencySymbol + paymentPlan.RecurringFee}</p></li>
                  )}
                  {paymentPlan.FrequencyFormula === FrequencyFormula.Annually && (
                    <li><p>Yearly fee: {props.currencySymbol + paymentPlan.RecurringFee}</p></li>
                  )}
                </>
              )}
            </ul>
          </>
        )}
      </div >
    )
  } else {
    return null
  }
}

export default MemberPaymentContainer;