import React, { ChangeEvent, Component, MouseEventHandler } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { compose } from 'redux';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { Widget } from '../../../Shared/Components/Presentational/Widget';
import "./AddMembershipStyles.scss";
import * as Routes from "../../../Constants/Routes";
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';
import ManageDigitalPass from '../Presentational/ManageActiveMemberSubPages/ManageDigitalPass';
import Vehicles from '../Presentational/AddMembershipSubPages/Vehicles';
import { updateEditMemberDetailsForm, updateEditMemberDetailsRequest } from '../../Redux/ActionCreators';
import { GetHTMlContentBlockCall } from '../../../Contentful/Calls/GetHTMLContentBlockCall';
import Spinner from '../../../Shared/Components/Presentational/Spinner';

interface EditMemberDetailsProps extends WithTranslation {
  isHeaderOpen: boolean;
  editMemberDetails: EditMemberDetailsDTO;
  editMemberDetailsForm: FormInput[];
  updateEditMemberDetailsRequest: (editMemberDetails: EditMemberDetailsDTO) => void;
  saveAndExit: () => void;
  updateEditMemberDetailsForm: (fieldName: any, value: any, errors: string[]) => void;
  decommissionedParks: string[];
  parkCode: string;
}

interface EditMemberDetailsState {
  currentTab: string;
  isEditting: boolean;
  showErrors: boolean;
  titleDescription: ContentfulContentObject | undefined;
  vehicleTitleDescription: ContentfulContentObject | undefined;
  passTitleDescription: ContentfulContentObject | undefined;
  contentLoaded: boolean;
}

class ManageActiveMember extends Component<EditMemberDetailsProps, EditMemberDetailsState> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentTab: "digitalPass",
      isEditting: false,
      showErrors: false,
      titleDescription: undefined,
      vehicleTitleDescription: undefined,
      passTitleDescription: undefined,
      contentLoaded: false,
    }

    Promise.all([
      this.getContentBlock("My Haulfryn - Memberships - Manage Membership - Vehicles", "vehicleTitleDescription"),
      this.getContentBlock("My Haulfryn - Memberships - Manage Membership - Digital Pass", "passTitleDescription"),
    ]).then(r => {
      r.forEach(c => this.setState({ ...this.state, [c.name]: c }));
      this.setState({ contentLoaded: true });
    })
  }

  getContentBlock = async (name: string, label: string) => {
    const text = await GetHTMlContentBlockCall(name, label);
    return text;
  }

  componentDidUpdate(prevProps: Readonly<EditMemberDetailsProps>, prevState: Readonly<EditMemberDetailsState>, snapshot?: any): void {

  }

  onTabSwitch(index: number) {
    switch (index) {
      case 0:
        this.setState({ currentTab: "digitalPass" });
        break;
      case 1:
        this.setState({ currentTab: "vehicles" });
        break;
      default:
        break;
    }
  }

  onSave() {
    // This is needed to provide a required parameter to the Primary button
  }

  onSaveAndExit(e: React.MouseEvent<HTMLAnchorElement>) {
    let invalid = false;

    for (let formObject in this.props.editMemberDetailsForm) {
      if (formObject === "vehicles" && (this.props.editMemberDetailsForm[formObject] && this.props.editMemberDetailsForm[formObject].errors.length > 0)) {
        invalid = true;
        break;
      }
    }

    if (invalid) {
      this.setState({ showErrors: true });
      e.preventDefault();
      return;
    } else {
      this.setState({ showErrors: false });
    }
    const editmembership = this.createEditMemberDetailsDTO(this.props.editMemberDetailsForm);

    this.props.updateEditMemberDetailsRequest(editmembership);
  }

  createEditMemberDetailsDTO(form: FormInput[]): EditMemberDetailsDTO {
    let editMemberDetails: EditMemberDetailsDTO =
    {
      id: this.props.editMemberDetails && this.props.editMemberDetails.id,
    };
    for (let formObject in form) {
      if (formObject !== "id" && formObject !== "autoRenew") {
        editMemberDetails = { ...editMemberDetails, [formObject]: form[formObject].value };
      }
    }

    if (editMemberDetails && editMemberDetails.vehicles) {
      for (let i = 0; i < editMemberDetails.vehicles!.length; i++) {
        if (editMemberDetails.vehicles![i].registration === "" && editMemberDetails.vehicles![i].description === "") {
          if (editMemberDetails.vehicles![i].id) {
            editMemberDetails.vehicles![i].registration = undefined;
            editMemberDetails.vehicles![i].description = undefined;
          } else {
            editMemberDetails.vehicles!.splice(i, 1);
            i--;
          }
        }
      }
    }

    return editMemberDetails;
  }

  updateValue(fieldName: string, value: any, errors: string[]) {
    this.props.updateEditMemberDetailsForm(fieldName, value, errors);
  }

  calculateAge = (dateOfBirth: Date): number => {
    let today = new Date();
    let birthDate = new Date(dateOfBirth);
    let ageNow = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      ageNow--;
    }
    return ageNow;
  }


  public render(): JSX.Element {
    const { t } = this.props;

    return (
      <>
        {this.state.contentLoaded ? (
          <div className='widget-container'>
            <Desktop>
              {!this.props.isHeaderOpen && (
                <>
                  <h1 className='widget-layout-title'>{t("editMembership.title")}</h1>
                  <p className='widget-layout-message'>{t("editMembership.message")}</p>
                </>
              )}
            </Desktop>
            <Widget
              position={{
                columnIndex: 1,
                columnSpan: 12,
                rowIndex: 3,
                rowSpan: 9,
              }}
            >
              <Tabs
                onSelect={this.onTabSwitch.bind(this)}
                defaultIndex={0}
              >
                <TabList style={{ marginLeft: "-40px", marginRight: "0px" }}>
                  <Tab>
                    <Desktop>{t("editMembership.digitalPass.title")}</Desktop>
                    <Mobile>{t("editMembership.digitalPass.titleMobile")}</Mobile>
                  </Tab>
                  {this.props.editMemberDetails && this.props.editMemberDetails.dateOfBirth && this.calculateAge(this.props.editMemberDetails.dateOfBirth) >= 17 && (
                    <Tab>{t("editMembership.vehicles.title")}</Tab>
                  )}
                </TabList>

                <TabPanel>
                  <ManageDigitalPass
                    memberId={this.props.editMemberDetails.id}
                    description={this.state.passTitleDescription}
                    cardNumber={this.props.editMemberDetails.cardNumber}
                    isDecommissionedPark={this.props.decommissionedParks.filter(p => p == this.props.parkCode).length > 0}
                  />
                </TabPanel>
                {this.props.editMemberDetails && this.props.editMemberDetails.dateOfBirth && this.calculateAge(this.props.editMemberDetails.dateOfBirth) >= 17 && (
                  <TabPanel>
                    <div style={{ margin: "30px" }}>
                      <Vehicles
                        membershipForm={this.props.editMemberDetailsForm}
                        onInput={this.updateValue.bind(this)}
                        vehicleName="vehicles"
                        bypassAge={true}
                        showErrors={this.state.showErrors}
                        description={this.state.vehicleTitleDescription}
                      />
                    </div>
                  </TabPanel>
                )}
              </Tabs>

              <div className='widget-footer'>
                <Link to={Routes.MyGuests} onClick={this.onSaveAndExit.bind(this)}>
                  <PrimaryButton
                    text={t("editMembership.saveAndExit")}
                    type="submit"
                    onClick={this.onSave.bind(this)}
                    className="save-cnt-btn membership-btn"
                  />
                </Link>
              </div>
            </Widget>
          </div>
        ) : (
          <div className='memberships-spinner'>
            <Spinner />
          </div>
        )}
      </>
    );
  };

};

const mapStateToProps = (state: any) => {
  return {
    isHeaderOpen: state.application.isHeaderOpen,
    editMemberDetails: state.guests.editMemberDetails,
    editMemberDetailsForm: state.guests.editMemberDetailsForm,
    decommissionedParks: state.user.decommissionedParks,
    parkCode: state.user.park.parkCode,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateEditMemberDetailsRequest: (editMemberDetails: EditMemberDetailsDTO) => {
      dispatch(updateEditMemberDetailsRequest(editMemberDetails));
    },
    saveAndExit: () => {
      //Add dispatch to reducer to clear edit membership Dto and form here
    },
    updateEditMemberDetailsForm: (fieldName: any, value: any, errors: string[]) => {
      dispatch(updateEditMemberDetailsForm(fieldName, value, errors));
    },
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ManageActiveMember);