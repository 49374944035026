import React, { Component, ReactNode, FC } from "react";
import "./Table.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Desktop, Mobile } from "./ResponsiveViews";
import InfiniteScroll from "react-infinite-scroller";
import TableFilterPosition from "../../../Constants/TableFilterPosition";
import classNames from "classnames";
import { TextAlignProperty } from "csstype";
import { EditIcon } from "./Icons";


export interface CustomColumnCellRendererProps {
  rowValue: any;
  clickAction?: (rowValue?: any) => void;
  t: any;
  filter: OrderedFilterDTO;
  globalCellProps?: any;
}

export interface ColumnDefinition {
  fieldName: string;
  displayName: string;
  bold?: boolean;
  translateField?: boolean;
  customCellRenderer?: FC<CustomColumnCellRendererProps>;
  canShowColumn?: (filter: OrderedFilterDTO) => boolean;
  customCellClickAction?: (item?: any) => any;
  fitContent?: boolean;
  headerAlignment?: TextAlignProperty;
  cellClassname?: string;
  headerClassname?: string;
}

interface TableProps extends WithTranslation {
  title?: string;
  columns: ColumnDefinition[];
  pagedResults: PagedResultDTO;
  filter: OrderedFilterDTO;
  onFilterChange: (newFilter: OrderedFilterDTO) => void;
  pageSize: number;
  noResultsMessage?: string;
  filterComponent?: ReactNode;
  descriptionComponent?: ReactNode;
  filterPosition?: string;
  isLoading: boolean;
  onRowClick?: (rowValue: any) => void;
  globalCellProps?: any;
}

class Table extends Component<TableProps> {
  public renderFilter(): JSX.Element {
    if (
      this.props.filterComponent &&
      (!this.props.filterPosition ||
        this.props.filterPosition === TableFilterPosition.Inline)
    ) {
      return <div className="filter">{this.props.filterComponent}</div>;
    } else if (
      this.props.descriptionComponent &&
      this.props.filterPosition &&
      this.props.filterPosition === TableFilterPosition.Below
    ) {
      return <div className="filter">{this.props.descriptionComponent}</div>;
    } else {
      return <div></div>;
    }
  }

  public renderDescription() {
    if (
      this.props.descriptionComponent &&
      (!this.props.filterPosition ||
        this.props.filterPosition === TableFilterPosition.Inline)
    ) {
      return (
        <div className="description">{this.props.descriptionComponent}</div>
      );
    } else if (
      this.props.filterComponent &&
      this.props.filterPosition &&
      this.props.filterPosition === TableFilterPosition.Below
    ) {
      return <div className="description">{this.props.filterComponent}</div>;
    } else {
      return <div></div>;
    }
  }

  public handleRowClick(rowValue: any) {
    if (this.props.onRowClick) {
      this.props.onRowClick(rowValue);
    }
  }

  public render(): JSX.Element {
    const currentPage = this.props.filter.Page;
    const totalPages = this.getTotalPages();

    return (
      <div className="table">
        <Desktop>
          {this.props.title && (
            <div className="header">
              <div className="header-filter">
                <div className="title">{this.props.title}</div>
                {this.renderFilter()}
              </div>
              {this.renderDescription()}
            </div>
          )}
          <div className="table-header">
            {this.props.columns
              .filter((c) => (c.canShowColumn ? c.canShowColumn(this.props.filter): true))
              .map((column, i) => {
                return (
                  <div
                    key={"column-" + i}
                    className={classNames(
                      "column-header",
                      column.headerClassname
                    )}
                    style={{
                      flex: column.fitContent ? "0" : "1 1",
                      textAlign: column.headerAlignment
                        ? column.headerAlignment
                        : "initial",
                    }}
                    id={column.fieldName + "Col"}
                  >
                    {this.props.t(column.displayName)}
                  </div>
                );
              })}
          </div>
          {this.props &&
          this.props.pagedResults &&
          this.props.pagedResults.Dtos.length ? (
            <InfiniteScroll
              pageStart={1}
              loadMore={this.appendNextPage.bind(this)}
              hasMore={currentPage !== totalPages}
              loader={
                <div className="loader" key={0}>
                  {this.props.t("general.loading")}
                </div>
              }
            >
              {this.props.pagedResults.Dtos.map((row, i) =>
                this.renderRows(this.props.t, row, i)
              )}
            </InfiniteScroll>
          ) : (
            <div className="no-results-message">
              {this.props.noResultsMessage}
            </div>
          )}
        </Desktop>
        <Mobile>
          {this.props.title && (
            <div className="mobile-title">{this.props.title}</div>
          )}
          {this.props.filterComponent ? (
            <div className="filter mobile-filter">
              {this.props.filterComponent}
            </div>
          ) : (
            <div></div>
          )}
          {this.props.descriptionComponent ? (
            <div className="description">{this.props.descriptionComponent}</div>
          ) : (
            <div></div>
          )}
          {this.props &&
          this.props.pagedResults &&
          this.props.pagedResults.Dtos.length ? (
            <InfiniteScroll
              pageStart={1}
              loadMore={this.appendNextPage.bind(this)}
              hasMore={currentPage !== totalPages}
              loader={
                <div className="loader" key={0}>
                  {this.props.t("general.loading")}
                </div>
              }
            >
              {this.props.pagedResults.Dtos.map((row, i) =>
                this.renderRows(this.props.t, row, i)
              )}
            </InfiniteScroll>
          ) : (
            <div className="no-results-message">
              {this.props.noResultsMessage}
            </div>
          )}
        </Mobile>
      </div>
    );
  }

  private renderRows(
    t: (key: string) => string,
    row: any,
    i: number
  ): JSX.Element {
    return (
      <React.Fragment key={`row-${i}`}>
        <Desktop>
          <div
            className={classNames("row", { clickable: this.props.onRowClick })}
            onClick={() => this.handleRowClick(row)}
          >
            {this.props.columns
              .filter((c) => (c.canShowColumn ? c.canShowColumn(this.props.filter): true))
              .map((column, x) => {
                return (
                  <div
                    key={"cell-" + x}
                    className={classNames(
                      "cell",
                      { bold: column.bold },
                      column.cellClassname
                    )}
                    style={{ flex: column.fitContent ? "0" : "1 1" }}
                    id={column.fieldName + "Row"}
                  >
                    {column.fieldName === "editIcon" && (<EditIcon
                      clickAction={()=>{}}
                    />)}
                    {column.fieldName != "editIcon" && column.customCellRenderer
                      ? React.createElement(column.customCellRenderer, {
                          rowValue: row,
                          clickAction: column.customCellClickAction,
                          t,
                          filter: this.props.filter,
                          globalCellProps: this.props.globalCellProps,
                        })
                      : column.translateField
                      ? t(row[column.fieldName])
                      : row[column.fieldName]}
                  </div>
                );
              })}
          </div>
        </Desktop>
        <Mobile>
          <div
            className={classNames("mobile-row", "row", {
              clickable: this.props.onRowClick,
            })}
            onClick={() => this.handleRowClick(row)}
          >
            {this.props.columns
              .filter((c) => (c.canShowColumn ? c.canShowColumn(this.props.filter): true))
              .map((column, x) => {
                return (
                  <div
                    key={"cell-" + x}
                    className={classNames(
                      "mobile-cell",
                      "cell",
                      { bold: column.bold },
                      column.cellClassname
                    )}
                    style={{ flex: column.fitContent ? "0" : "1 1" }}
                  >
                    {column.customCellRenderer
                      ? React.createElement(column.customCellRenderer, {
                          rowValue: row,
                          clickAction: column.customCellClickAction,
                          t,
                          filter: this.props.filter,
                        })
                      : column.translateField
                      ? t(row[column.fieldName])
                      : row[column.fieldName]}
                  </div>
                );
              })}
          </div>
        </Mobile>
      </React.Fragment>
    );
  }

  public goToPreviousPage(): void {
    if (this.props.filter.Page > 1) {
      const filter = { ...this.props.filter };

      filter.Page--;

      this.props.onFilterChange(filter);
    }
  }

  public goToNextPage(): void {
    const totalPages = this.getTotalPages();

    if (this.props.filter.Page < totalPages) {
      const filter = { ...this.props.filter };

      filter.Page++;
      filter.PageSize = this.props.pageSize;

      this.props.onFilterChange(filter);
    }
  }

  public appendNextPage(): void {
    if (!this.props || !this.props.pagedResults || this.props.isLoading) {
      return;
    }

    const totalPages = this.getTotalPages();

    if (this.props.filter.Page < totalPages) {
      const filter = { ...this.props.filter };

      filter.Page = 1;
      if (filter.PageSize < this.props.pageSize) {
        filter.PageSize = this.props.pageSize;
      } else {
        filter.PageSize += this.props.pageSize;
      }

      this.props.onFilterChange(filter);
    }
  }

  private getTotalPages(): number {
    return Math.max(
      1,
      Math.ceil(this.props.pagedResults.Total / this.props.filter.PageSize)
    );
  }
}

export default withTranslation()(Table);
