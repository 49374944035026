import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { SET_ERROR_PROMPT_OPEN, SET_REFER_SENT_PROMPT_OPEN } from '../../../Modal/Redux/ActionTypes';
import React from "react";
import ReferralSentAlertModal from "../Presentational/ReferralSentAlertModal";
import ErrorPromptModal from "../Presentational/ErrorPromptModal";

interface ErrorProps extends WithTranslation {
    showErrorPrompt: boolean;
    setErrorModalOpen: (isOpen: boolean) => void;
}

class ErrorPrompt extends Component<ErrorProps> {
  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <>
        <>
          <Desktop>
            <ErrorPromptModal
              isOpen={this.props.showErrorPrompt}
              setErrorModalOpen = {this.props.setErrorModalOpen}
            />
          </Desktop>
          <Mobile>
            <ErrorPromptModal
              className="mobile-modal owner-contact-details-modal-mobile"
              isOpen={this.props.showErrorPrompt}
              setErrorModalOpen = {this.props.setErrorModalOpen}
            />
          </Mobile>
        </>
      </>
    );
  };
};

const mapStateToProps = (state: any) => {
    
    return {
        showErrorPrompt: state.modal.error.isOpen,
    };
  };
  
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
        setErrorModalOpen: (isOpen: boolean) => {
            dispatch({ type: SET_ERROR_PROMPT_OPEN, payload: isOpen })
        } 
    };
  };


export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ErrorPrompt)
)