import { PostApiCall } from './ApiCall';

export class PostDownloadStatementCall extends PostApiCall {
    constructor(filter: TransactionFilterDTO) {
        let url: string = 'account/' + filter.AccountId + '/statement/download';

        super(url);

        this.body = filter;
    }
}
