import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalFooter, ModalHeader, ModalProps, SuccessModal } from '../../../Shared/Components/Presentational/Modal';
import { Desktop, Mobile, useIsDesktop } from '../../../Shared/Components/Presentational/ResponsiveViews';
import moment from 'moment';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { isValidEmail } from '../../../Shared/Utilities/Validation';
import BookTableForm from '../Presentational/BookTableForm';
import { Post } from '../../../Api/ApiService';
import { PostBookTableCall } from '../../../Api/Calls/PostBookTableCall'
import './BookTableModal.scss';

//@ts-ignore
import { PrimaryButton } from '../../../Shared/Components/Presentational/Buttons';

interface BookTableModalProps extends ModalProps {
    restaurant: PromotedRestaurant;
    className?: string;
    lodgeId?: number;
    accountId?: number;
    membershipNumber?: string;
}

const BookTableModal: React.FC<BookTableModalProps> = ({ restaurant, onRequestClose, isOpen, className, lodgeId, accountId, membershipNumber }) => {


    const defaultBookingDetals: BookTableDTO = {
        Title: "",
        FirstName: "",
        Surname: "",
        PhoneNumber: "",
        Email: "",
        RestaurantCode: restaurant.fields.zonalCode!,
        SessionName: "",
        BookingDate: "",
        NumOfAdults: 2,
        NumOfChildren: 0,
        LodgeId: lodgeId || 0,
        AccountId: accountId || 0,
        MembershipNumber: membershipNumber,
    }

    const [bookingDetails, setBookingDetails] = useState<BookTableDTO>(defaultBookingDetals);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [bookingRefNum, setBookingRefNum] = useState<string>("");

    const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([])
    const [availableSessions, setAvailableSessions] = useState<RestaurantSessionDTO[]>([])

    const isDesktop = useIsDesktop();

    const { t } = useTranslation();

    const minBookingDate = moment().toDate();
    const maxBookingDate = restaurant.bookAheadDays ? moment().add(restaurant.bookAheadDays - 1, "days").toDate() : undefined;

    const goBack = () => {
        onRequestClose && onRequestClose();
    };

    const isFormValid = () => {
        if (bookingDetails.AccountId === 0 && bookingDetails.LodgeId === 0) {
            return Object.values(bookingDetails).every(v => v != null && v !== "")
                && isValidEmail(bookingDetails.Email)
                && bookingDetails.NumOfAdults >= 1
        } else {
            let details = bookingDetails;
            details.MembershipNumber = "0";
            return Object.values(details).every(v => v != null && v !== "")
            && isValidEmail(details.Email)
            && details.NumOfAdults >= 1
        }
    }

    const onSubmit = () => {
        if (isFormValid()) {
            setIsSubmitting(true);
            Post<string>(new PostBookTableCall(bookingDetails))
                .then((success) => {
                    setHasError(false);
                    setBookingRefNum(success.data)
                    setIsSuccessModalOpen(true);
                }, (error) => {
                    console.log(error);
                    setHasError(true);
                }).finally(() => setIsSubmitting(false))
        }
    }

    useEffect(() => {
        if (isOpen) {
            setBookingDetails(defaultBookingDetals);
            setHasError(false);
        }
    }, [isOpen]);


    return (
        <>
            <Desktop>
                <Modal isOpen={isOpen} className={"book-table-modal"}>
                    <ModalHeader text={restaurant.fields.name} />
                    {restaurant.bookingHelp &&
                        <div className="info-message show">
                            {documentToReactComponents(restaurant.bookingHelp!)}
                        </div>
                    }
                    <BookTableForm
                        bookingDetails={bookingDetails}
                        setBookingDetails={setBookingDetails}
                        minBookingDate={minBookingDate}
                        maxBookingDate={maxBookingDate}
                        availableTimeSlots={availableTimeSlots}
                        setAvailableTimeSlots={setAvailableTimeSlots}
                        availableSessions={availableSessions}
                        setAvailableSessions={setAvailableSessions}
                    />
                    <p className={hasError ? 'error-message show' : 'error-message'}>
                        {t('myCalendar.restaurants.bookTable.generalErrorMessage')}
                    </p>
                    <ModalFooter onBackButtonClick={goBack}>
                        <PrimaryButton
                            text={t('myCalendar.restaurants.bookTable.bookNow')}
                            className="footer-button"
                            disabled={!isFormValid()}
                            onClick={onSubmit}
                            isLoading={isSubmitting}
                        />
                    </ModalFooter>
                </Modal>
            </Desktop>
            <Mobile>
                <Modal isOpen={isOpen} className={"book-table-modal-mobile"}>
                    <ModalHeader text={restaurant.fields.name} />
                    {restaurant.bookingHelp &&
                        <div className="info-message show">
                            {documentToReactComponents(restaurant.bookingHelp!)}
                        </div>
                    }
                    <BookTableForm
                        className="add-booking-modal-mobile-form"
                        bookingDetails={bookingDetails}
                        setBookingDetails={setBookingDetails}
                        minBookingDate={minBookingDate}
                        maxBookingDate={maxBookingDate}
                        availableTimeSlots={availableTimeSlots}
                        setAvailableTimeSlots={setAvailableTimeSlots}
                        availableSessions={availableSessions}
                        setAvailableSessions={setAvailableSessions}
                    />
                    <p className={hasError ? 'error-message show' : 'error-message'}>
                        {t('myCalendar.restaurants.bookTable.generalErrorMessage')}
                    </p>
                    <ModalFooter onBackButtonClick={goBack}>
                        <PrimaryButton
                            text={t('myCalendar.restaurants.bookTable.bookNow')}
                            className="footer-button"
                            disabled={!isFormValid()}
                            onClick={onSubmit}
                            isLoading={isSubmitting}
                        />
                    </ModalFooter>
                </Modal>
            </Mobile>
            <SuccessModal
                isOpen={isSuccessModalOpen}
                closeModal={() => {
                    setIsSuccessModalOpen(false);
                    goBack();
                }}
                className={'success-modal'}
                title={t('myCalendar.restaurants.bookTable.successModalTitle')}
                message={t('myCalendar.restaurants.bookTable.successModalBody', { bookingRefNum })}
                buttonText={t('general.ok')}
            />
        </>
    );
};

export default BookTableModal;
