import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ClickableIcon } from '../../../Shared/Components/Presentational/Icons';
import { PrimaryButton, SecondaryButton } from '../../../Shared/Components/Presentational/Buttons';
import { Translation } from 'react-i18next';
import './AddPassModalFooter.scss';

interface AddPassModalFooterProps {
    onBackButtonClick: () => void;
    submitDisabled: boolean;
    addAnotherDisabled: boolean;
    addAnotherClick?: () => void;
    helperText?: string;
    isRenew?: boolean;
    isLoading?:boolean;
    primaryButtonText: string;
}

const AddPassModalFooter: React.FC<AddPassModalFooterProps> = props => {
    return (
        <Translation>
            {(t: (key: string) => string) => {
                return (
                    <>
                        <div className="add-pass-modal-footer">
                            {!props.isRenew && (
                                <div className="add-pass-modal-footer-alternate">
                                    <SecondaryButton
                                        text={t('myGuests.passes.add.another')}
                                        disabled={props.addAnotherDisabled}
                                        onClick={props.addAnotherClick}
                                    />
                                </div>
                            )}

                            <div className="add-pass-modal-footer-main">
                                <ClickableIcon
                                    className="back-button"
                                    icon={faArrowLeft}
                                    clickAction={props.onBackButtonClick}
                                />
                                <PrimaryButton
                                    type="submit"
                                    text={props.primaryButtonText}
                                    disabled={props.submitDisabled || props.isLoading}
                                    isLoading={props.isLoading}
                                />
                            </div>

                            {props.helperText && <p>{props.helperText}</p>}
                        </div>
                    </>
                );
            }}
        </Translation>
    );
};

export default AddPassModalFooter;
