import React, { Component } from 'react';
import './ErrorBoundary.scss';
import { PrimaryButton } from './Shared/Components/Presentational/Buttons';
import history from './History';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {}

    render() {
        if (this.state.hasError) {
            return (
                <form
                    onClick={() => {
                        history.push('/');
                    }}
                    className="error-boundary"
                >
                    <FontAwesomeIcon icon={faExclamationCircle} color="#1e2171" size="10x" />
                    <h1>Something went wrong</h1>
                    <p>
                        We're having some technical issues. Please click below to go back home or refresh the page and
                        try again.
                    </p>
                    <PrimaryButton type="submit" text="Back To Home" />
                </form>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
