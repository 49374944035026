import { PostApiCall } from './ApiCall';

export class FilterMembershipTermTimesForOwnersFromDecommissionedParksCall extends PostApiCall {
    constructor(body: MembershipTermTimesDTO) {
        let url: string = `MembershipTerm/FilterValidTermsFromList`;

        super(url);

        this.body = body;
        this.logoutOn401 = false;
    }
}
