import { PostApiCall } from "./ApiCall";

export class PostAddSeasonPassesCall extends PostApiCall {
    constructor(lodgeId: string, passes: AddSeasonPassDTO[]) {
        let url: string = "season-pass/" + lodgeId;

        super(url);

        const formData = new FormData();

        passes.forEach((item, index) => {
            for ( var key in item) {
                var itemObject = item as any;
                formData.append('[' + index + '].' + key, itemObject[key]);
            }
        });

        this.body = formData;
    }
}