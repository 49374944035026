import { Component } from 'react';
import DropdownList from '../../../Shared/Components/Presentational/DropdownList';
import './DropdownListWidget.scss';
import * as React from 'react';
import { Widget, WidgetPosition } from '../../../Shared/Components/Presentational/Widget';
import classNames from 'classnames';

interface DropdownListWidgetProps {
    position: WidgetPosition;
    label: string;
    defaultItem: DropdownListOption;
    options: [DropdownListOption];
    className?: string;
    classNamePrefix?: string;
    onSelectionChanged: (newValue: DropdownListOption) => void;
}

class DropdownListWidget extends Component<DropdownListWidgetProps> {
    render() {
        const { label, defaultItem, options, className, classNamePrefix } = this.props;

        return (
            <Widget containerClassName={'dropdown-list-widget'} position={this.props.position}>
                <p>{label}</p>
                <DropdownList
                    className={classNames(className)}
                    selectedOption={defaultItem}
                    classNamePrefix={classNamePrefix ? classNamePrefix : ''}
                    options={options}
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                />
            </Widget>
        );
    }

    private onSelectionChanged(newValue: DropdownListOption): void {
        if (this.props.onSelectionChanged) {
            this.props.onSelectionChanged(newValue as DropdownListOption);
        }
    }
}

export default DropdownListWidget;
