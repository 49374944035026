import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Desktop,
  Mobile,
} from "../../../Shared/Components/Presentational/ResponsiveViews";
import { getPermissionsFromUserRoles, logout } from "../../../Login/Redux/ActionCreators";
import {
  CalendarEvent,
  CalendarResource,
} from "../../../Shared/Components/Presentational/Calendar";
import HomePage from "../Presentational/HomePage";
import HomePageMobile from "../Presentational/HomePage.mobile";
import {
  getShowMembershipPrompt,
  getMarketingPreferences,
  setUserLodge,
  setUserPark,
} from "../../../User/Redux/ActionCreators";
import history from "../../../History";
import {
  SET_SELECTED_CALENDAR_WIDGET,
  SelectedCalendarWidgetOptions,
} from "../../../Calendar/Redux/ActionTypes";
import { getParkInformation, setLodgeDetails } from "../../../Information/Redux/ActionCreators";
import { getContentfulMembershipTypes, getParkPassTypes } from "../../../Guests/Redux/ActionCreators";
import HeaderContainer from "../../../Shared/Components/Container/HeaderContainer";
import MyContactDetails from "../../../Modal/Components/Container/MyContactDetails";
import { getMyContactDetails } from "../../../User/Redux/ActionCreators";
import {
  SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN,
  SET_CONTACT_HAULFRYN_MODAL_OPEN,
} from "../../../Modal/Redux/ActionTypes";
import { Post } from "../../../Api/ApiService";
import { PostSearchLodgeBookingsCall } from "../../../Api/Calls/PostSearchLodgeBookingsCall";
import moment from "moment";
import { SET_ACCOUNT_BALANCE, SET_USER_ROLE_CLAIMS } from "../../../User/Redux/ActionTypes";
import { RELOAD_TRANSLATIONS, SET_SELECTED_NEWS_ITEM } from "../../../Information/Redux/ActionTypes";
import MyMembershipsCreatePrompt from "../../../Modal/Components/Container/MyMembershipsCreatePrompt";
import { Entry } from "contentful";
import { GetMembershipTypesCall } from "../../../Contentful/Calls/GetMembershipTypes";
import { SET_CONTENTFUL_MEMBERSHIP_TYPES } from "../../../Guests/Redux/ActionTypes";
import Spinner from "../../../Shared/Components/Presentational/Spinner";

interface HomeProps {
  logoutClick: () => void;
  lodges: LodgeDTO[];
  lodgeOptions: [DropdownListOption];
  dispatch: any;
  userFirstName: string;
  setSelectedLodge: (lodge: SelectedLodgeDTO) => void;
  setSelectedPark: (
    parkCode: string,
    parkName: string,
    parkFeatures: string[]
  ) => void;
  selectedLodge: DropdownListOption;
  setCalendarFilter: (selected: SelectedCalendarWidgetOptions) => void;
  contractEndDate: string;
  getMyContactDetails: any;
  myContactDetails: MyContactDetailsDTO;
  getMarketingPreferences: any;
  marketingPreferences: MarketingPreferencesDTO | undefined;
  openMyContactDetailsModal: () => void;
  closeMyContactDetailsModal: () => void;
  setContactHaulfrynModalOpen: (isOpen: boolean) => void;
  hasSublettings: boolean;
  accountId: number;
  accountNumber: string;
  setAccountBalance: (newBalance: number) => void;
  ownerId: number;
  loginMethod: string;
  showMembershipPrompt: boolean;
  getShowMembershipPrompt: any;
  setSelectedNewsItem: (selectedNewsItemId: string) => void;
  contentfulMembershipTypes: Entry<ContentfulMembershipType>[];
  setContentfulMembershipTypes: () => void;
  useMemberships: boolean;
  getPermissionsFromUserRoles: () => void;
}

interface HomeState {
  calendarEvents: CalendarEvent[];
  resources: CalendarResource[];
}

class Home extends Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);

    this.onSelectedLodgeChanged = this.onSelectedLodgeChanged.bind(this);
  }

  componentDidMount() {
    this.props.setContentfulMembershipTypes();
    this.props.setCalendarFilter("events");
    this.props.getMyContactDetails();
    this.props.getShowMembershipPrompt();

    if (this.props.ownerId > 0) {
      this.props.getMarketingPreferences();
    }

    this.searchBookings();
    // reload translation file
    this.props.dispatch({ type: RELOAD_TRANSLATIONS });
    this.props.dispatch(getParkInformation());
  }

  componentDidUpdate(prevProps: HomeProps) {
    if (prevProps.selectedLodge !== this.props.selectedLodge) {
      this.searchBookings();
    }
  }

  public state: HomeState = {
    calendarEvents: [],
    resources: [
      {
        id: 1,
        title: "homePage.calendar.key.subletbookings",
        className: "sublet-bookings",
      },
      {
        id: 2,
        title: "homePage.calendar.key.myHolidays",
        className: "my-holidays",
      },
      {
        id: 3,
        title: "homePage.calendar.key.myPendingHolidays",
        className: "my-holidays pending",
      },
    ],
  };

  searchBookings = async () => {
    try {
      const filter: BookingFilterDTO = {
        LodgeId: Number(this.props.selectedLodge.value),
        FromDate: moment()
          .startOf("month")
          .startOf("week")
          .format("DD/MM/YYYY"),
        ToDate: moment().endOf("month").endOf("week").format("DD/MM/YYYY"),
      };
      const bookings = await Post<CalendarBookingDTO[]>(
        new PostSearchLodgeBookingsCall(filter)
      );

      const calendarBookings: CalendarEvent[] = bookings.data.map((booking) => {
        return {
          allDay: true,
          start: booking.FromDate,
          end: booking.ToDate,
          resourceId: booking.IsOwnerBooking
            ? booking.IsPendingBooking
              ? 3
              : 2
            : 1,
          className: booking.IsOwnerBooking
            ? booking.IsPendingBooking
              ? "my-holidays pending"
              : "my-holidays"
            : "sublet-bookings",
        };
      });

      this.setState({ calendarEvents: calendarBookings });
    } catch { }
  };

  public isEmptyOrUndefined(obj: {} | undefined) {
    if (obj !== undefined) {
      return Object.keys(obj).length === 0;
    } else {
      return true;
    }
  }

  public showContactDetailsModal() {
    return ((this.props.loginMethod === "email" && this.props.ownerId < 1) || (this.props.ownerId > 0 && !this.isEmptyOrUndefined(this.props.marketingPreferences) && !this.isEmptyOrUndefined(this.props.myContactDetails)));
  }

  showCreateMembershipsPrompt() {
    return this.props.showMembershipPrompt && this.props.contentfulMembershipTypes && this.props.contentfulMembershipTypes.length > 0;
  }

  public render(): JSX.Element {
    const { logoutClick, userFirstName, lodgeOptions, selectedLodge } = this.props;

    return (
      <>
        {this.props.contentfulMembershipTypes ? (
          <>
            <Desktop>
              <HeaderContainer
                contractEndDate={this.props.contractEndDate}
                selectedOption={selectedLodge}
                options={lodgeOptions}
                onSelectionChanged={this.onSelectedLodgeChanged}
                logoutClick={logoutClick}
                backgroundContainerClassName="home-page-background"
                showMyDetailsLink={this.showContactDetailsModal()}
                openMyContactDetailsModal={this.props.openMyContactDetailsModal}
                hasSublettings={this.props.hasSublettings}
                useMemberships={this.props.useMemberships}
              />
              <HomePage
                calendarEvents={this.state.calendarEvents}
                resources={this.state.resources}
                userFirstName={userFirstName}
                selectedLodge={selectedLodge}
                hasSublettings={this.props.hasSublettings}
                accountId={this.props.accountId}
                accountNumber={this.props.accountNumber}
                setAccountBalance={this.props.setAccountBalance}
                setSelectedNewsItem={this.props.setSelectedNewsItem}
                useMemberships={this.props.useMemberships}
              />
            </Desktop>
            <Mobile>
              <HeaderContainer
                contractEndDate={this.props.contractEndDate}
                selectedOption={selectedLodge}
                options={lodgeOptions}
                onSelectionChanged={this.onSelectedLodgeChanged}
                logoutClick={logoutClick}
                backgroundContainerClassName="home-page-background-mobile"
                showMyDetailsLink={this.showContactDetailsModal()}
                openMyContactDetailsModal={this.props.openMyContactDetailsModal}
                hasSublettings={this.props.hasSublettings}
                useMemberships={this.props.useMemberships}
              />
              <HomePageMobile
                setSelectedNewsItem={this.props.setSelectedNewsItem}
                calendarEvents={this.state.calendarEvents}
                resources={this.state.resources}
                userFirstName={userFirstName}
                selectedLodge={selectedLodge}
                options={lodgeOptions}
                onSelectionChanged={this.onSelectedLodgeChanged}
                contractEndDate={this.props.contractEndDate}
                showMyDetailsLink={this.showContactDetailsModal()}
                openMyContactDetailsModal={this.props.openMyContactDetailsModal}
                onContactHaulfrynClick={this.props.setContactHaulfrynModalOpen}
                hasSublettings={this.props.hasSublettings}
                accountId={this.props.accountId}
                accountNumber={this.props.accountNumber}
                setAccountBalance={this.props.setAccountBalance}
                useMemberships={this.props.useMemberships}
              />
            </Mobile>
            {(this.showContactDetailsModal()) && (
              <MyContactDetails
                myContactDetails={this.props.myContactDetails}
                onBackButtonClick={this.props.closeMyContactDetailsModal}
                marketingPreferences={this.props.marketingPreferences}
              />
            )}
            {this.showCreateMembershipsPrompt() && (
              <MyMembershipsCreatePrompt />
            )}
          </>
        ) : (
          <div className='memberships-spinner'>
            <Spinner />
          </div>
        )}
      </>
    );
  }

  private onSelectedLodgeChanged(newValue: DropdownListOption) {
    if (newValue) {
      const selectedLodge = this.props.lodges.find(
        (l) => l.LodgeId.toString() === newValue.value
      );

      if (selectedLodge) {
        this.props.setSelectedPark(
          selectedLodge.ParkCode,
          selectedLodge.ParkName,
          selectedLodge.ParkFeatures
        );
        this.props.setSelectedLodge({
          LodgeId: selectedLodge.LodgeId,
          LodgeName: selectedLodge.Name,
          ContractEndDate: selectedLodge.ContractEndDate,
          HasSublettings: selectedLodge.HasSublettings,
          AccountId: selectedLodge.AccountId,
          AccountNo: selectedLodge.AccountNo,
          AccountBalance: selectedLodge.RecentTransactions[0]
            ? selectedLodge.RecentTransactions[0].Balance
            : undefined,
        });
      }

      history.push("/");
    }
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutClick: () => {
      dispatch(logout(false));
    },
    setSelectedLodge: (lodge: SelectedLodgeDTO) => {
      dispatch(setUserLodge(lodge));
      dispatch(setLodgeDetails(lodge.LodgeId.toString()));
      dispatch(getParkPassTypes());
    },
    setSelectedPark: (
      parkCode: string,
      parkName: string,
      parkFeatures: string[]
    ) => {
      dispatch(setUserPark({ parkCode, parkName, parkFeatures }));
    },
    setCalendarFilter: (selected: SelectedCalendarWidgetOptions) =>
      dispatch({ type: SET_SELECTED_CALENDAR_WIDGET, payload: selected }),
    getMyContactDetails: () => dispatch(getMyContactDetails()),
    getMarketingPreferences: () => dispatch(getMarketingPreferences()),
    openMyContactDetailsModal: () =>
      dispatch({ type: SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN, payload: true }),
    closeMyContactDetailsModal: () =>
      dispatch({ type: SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN, payload: false }),
    setContactHaulfrynModalOpen: (isOpen: boolean) =>
      dispatch({ type: SET_CONTACT_HAULFRYN_MODAL_OPEN, payload: isOpen }),
    setAccountBalance: (newBalance: number) =>
      dispatch({ type: SET_ACCOUNT_BALANCE, payload: newBalance }),
    getShowMembershipPrompt: () => dispatch(getShowMembershipPrompt()),
    setSelectedNewsItem: (selectedNewsItemId: string) =>
      dispatch({ type: SET_SELECTED_NEWS_ITEM, payload: selectedNewsItemId }),
    setContentfulMembershipTypes: () => {
      dispatch(getContentfulMembershipTypes());
    },
    getPermissionsFromUserRoles: () =>
      dispatch(getPermissionsFromUserRoles()),
  };
};

const mapStateToProps = (state: any) => {
  let userFirstName: string = "";
  let lodges: LodgeDTO[] = [];
  let selectedLodge: DropdownListOption = { value: "", label: "" };
  let lodgeOptions: [DropdownListOption] = [{ value: "", label: "" }];
  let contractEndDate = "";
  let hasSublettings = false;
  let accountId = 0;
  let accountNumber = "";

  if (state.user) {
    userFirstName = state.user.firstname ? state.user.firstname : "";
    lodges = state.user.lodges ? state.user.lodges : [];

    for (let i = 0; i < lodges.length; i++) {
      lodgeOptions[i] = {
        value: lodges[i].LodgeId.toString(),
        label: lodges[i].Name,
      };

      if (state.user.lodge && state.user.lodge.LodgeId === lodges[i].LodgeId) {
        selectedLodge = lodgeOptions[i];
      }
    }
  }

  if (state.user.lodge) {
    contractEndDate = state.user.lodge.ContractEndDate;
    const selectedLodge: LodgeDTO = state.user.lodges.find(
      (x: LodgeDTO) => x.LodgeId === state.user.lodge.LodgeId
    );
    if (selectedLodge) {
      accountId = selectedLodge.AccountId;
      accountNumber = selectedLodge.AccountNo;
      hasSublettings = selectedLodge.HasSublettings;
    }
  }

  return {
    userFirstName,
    lodges,
    selectedLodge,
    lodgeOptions,
    contractEndDate,
    myContactDetails: state.user.myContactDetails || {},
    marketingPreferences: state.user.marketingPreferences,
    ownerId: state.user.ownerId,
    hasSublettings,
    accountId,
    accountNumber,
    loginMethod: state.login.loginMethod,
    showMembershipPrompt: state.user.showMembershipPrompt,
    contentfulMembershipTypes: state.guests.contentfulMembershipTypes,
    useMemberships: state.guests.useMemberships,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
