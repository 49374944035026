import React, { Component } from 'react';
import './Buttons.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, far } from '@fortawesome/free-regular-svg-icons';
import { library, icon, IconPrefix, IconName, IconLookup } from '@fortawesome/fontawesome-svg-core';
import { fas, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { ClickableIcon } from './Icons';

interface ButtonProps {
    id?: string;
    text: string;
    type?: 'submit' | 'reset' | 'button' | undefined;
    className: string;
    disabled?: boolean;
    isLoading?: boolean;
    onClick?: () => void;
    icon?: string;
    alternateText?: string;
    height?: string;
    fontSize?: string;
    width?: string;
    margin?: string;
}

export class Button extends Component<ButtonProps> {
    render() {
        let faIcon: IconLookup = { prefix: '' as IconPrefix, iconName: '' as IconName };

        if (this.props.icon) {
            faIcon = {
                prefix: this.props.icon.split(' ')[0] as IconPrefix,
                iconName: this.props.icon.split(' ')[1].replace('fa-', '') as IconName,
            };
            library.add(fas);
            library.add(far);
        }

        return (
            <button
                id={this.props.id && this.props.id}
                type={this.props.type ? this.props.type : 'button'}
                className={classNames('btn', this.props.className, { 'btn-ripple': this.props.isLoading })}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
                style={{ height: this.props.height, fontSize: this.props.fontSize, width: this.props.width, margin: this.props.margin }}
            >
                {this.props.icon && (
                    <div className="btn-icon">
                        <FontAwesomeIcon icon={icon(faIcon)} />
                    </div>
                )}
                {this.props.text}
                <div className="btn-alternate-text">{this.props.alternateText ? this.props.alternateText : ''}</div>
                <div></div>
            </button>
        );
    }
}

interface PrimaryButtonProps {
    id?: string;
    text: string;
    disabled?: boolean;
    isLoading?: boolean;
    className?: string;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onClick?: () => void;
    width?: string;
    margin?: string;
}

export class PrimaryButton extends Component<PrimaryButtonProps> {
    render() {
        return (
            <Button
                id={this.props.id && this.props.id}
                className={classNames('btn-primary', this.props.className)}
                type={this.props.type}
                text={this.props.text}
                disabled={this.props.disabled}
                isLoading={this.props.isLoading}
                onClick={this.props.onClick}
                width={this.props.width}
                margin={this.props.margin}
            />
        );
    }
}

interface SecondaryButtonProps {
    id?: string;
    text: string;
    disabled?: boolean;
    isLoading?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    className?: string;
    onClick?: () => void;
    icon?: string;
    alternateText?: string;
    height?: string;
    fontSize?: string;
    width?: string;
    margin?: string;
}

export class SecondaryButton extends Component<SecondaryButtonProps> {
    render() {
        return (
            <Button
                id={this.props.id && this.props.id}
                icon={this.props.icon}
                className={classNames('btn-secondary', this.props.className)}
                type={this.props.type}
                text={this.props.text}
                alternateText={this.props.alternateText}
                disabled={this.props.disabled}
                isLoading={this.props.isLoading}
                onClick={this.props.onClick}
                height={this.props.height}
                fontSize={this.props.fontSize}
                width={this.props.width}
                margin={this.props.margin}
            >
                {this.props.children}
            </Button>
        );
    }
}


interface TertiaryButtonProps {
    text: string;
    disabled?: boolean;
    isLoading?: boolean;
    className?: string;
    onClick?: () => void;
}

export class TertiaryButton extends Component<TertiaryButtonProps> {
    render() {
        return (
            <div className={classNames('btn-tertiary', this.props.className, { 'btn-ripple': this.props.isLoading }, { 'disabled': this.props.disabled })}  onClick={this.props.onClick}>
                {this.props.text}
                <div></div>
            </div>
        );
    }
}

interface OutlineButtonProps {
    text: string;
    disabled?: boolean;
    isLoading?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onClick?: () => void;
    className?: string;
}

export class OutlineButton extends Component<OutlineButtonProps> {
    render() {
        return (
            <Button
                className={classNames('btn-outline', this.props.className)}
                type={this.props.type}
                text={this.props.text}
                disabled={this.props.disabled}
                isLoading={this.props.isLoading}
                onClick={this.props.onClick}
            />
        );
    }
}

interface BackLinkButtonProps {
    href: string;
    text: string;
    className: string;
}

export class BackLinkButton extends Component<BackLinkButtonProps> {
    render() {
        return (
            <div className={classNames('back-button-container', this.props.className)}>
                <a href={this.props.href}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} className="back-button-arrow" />
                    {this.props.text}
                </a>
            </div>
        );
    }
}

interface BackButtonProps {
    onClick: () => void;
    text: string;
    className: string;
}

export class BackButton extends Component<BackButtonProps> {
    render() {
        return (
            <div className={classNames('back-button-container', this.props.className)}>
                <button type="button" onClick={this.props.onClick}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} className="back-button-arrow" />
                    {this.props.text}
                </button>
            </div>
        );
    }
}

interface LinkButtonProps {
    onClick: () => void;
    text: string;
    className?: string;
}

export class LinkButton extends Component<LinkButtonProps> {
    render() {
        const className = this.props.className || '';
        return (
            <button type="button" onClick={this.props.onClick} className={`btn-link ${className}`}>
                {this.props.text}
            </button>
        );
    }
}

interface ModalBackButtonProps {
    onBackButtonClick?: () => void;
    className?: string;
}

export class ModalBackButton extends Component<ModalBackButtonProps> {
    clickAction = () => {
        if (this.props.onBackButtonClick) {
            this.props.onBackButtonClick();
        }
    };
    render() {
        return (
            <ClickableIcon
                className={classNames('modal-back-button', this.props.className)}
                icon={faArrowLeft}
                clickAction={this.clickAction}
            />
        );
    }
}
