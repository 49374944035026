import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ModalFooter, ModalHeader } from '../../../Shared/Components/Presentational/Modal';
import { PrimaryButton, SecondaryButton } from '../../../Shared/Components/Presentational/Buttons';
import './MakeAPaymentModal.scss';

interface MakeAPaymentModalProps extends WithTranslation {
    onBackButtonClick: () => void;
    paymentTypeClickEvent: (paymentType: any) => void;
}

class MakeAPaymentModalDefault extends Component<MakeAPaymentModalProps> {
    render() {
        const { t } = this.props;
        return (
            <>
                <ModalHeader text={t('myAccount.payment.how')} />
                <section className="payment-details-section">
                    <div className="payment-details" style={{ justifyContent: 'center' }}>
                        <div className="btnContainer">
                            <PrimaryButton
                                text={t('myAccount.payment.typeCard')}
                                className="full-width"
                                onClick={() => this.props.paymentTypeClickEvent('card')}
                            />
                            <SecondaryButton
                                text={t('myAccount.payment.typeBank')}
                                className="full-width"
                                onClick={() => this.props.paymentTypeClickEvent('bank')}
                            />
                        </div>
                    </div>
                </section>
                <ModalFooter onBackButtonClick={this.props.onBackButtonClick} />
            </>
        );
    }
}

export default withTranslation()(MakeAPaymentModalDefault);
