import React from 'react';
import { AppState } from '../../../RootReducer';
import { connect } from 'react-redux';
import { SET_HEADER_MENU_OPEN } from '../../Redux/ActionTypes';
import { SET_CONTACT_HAULFRYN_MODAL_OPEN } from '../../../Modal/Redux/ActionTypes';
import Header from '../Presentational/Header';

interface HeaderContainerProps {
    logoutClick: () => void;
    options?: [DropdownListOption];
    selectedOption?: DropdownListOption;
    onSelectionChanged?: (newValue: DropdownListOption) => void;
    backgroundImgSrc?: string;
    backgroundContainerClassName?: string;
    isHeaderOpen: boolean;
    setHeaderOpen: (isOpen: boolean) => void;
    contractEndDate: string;
    showMyDetailsLink: boolean;
    openMyContactDetailsModal: () => void;
    setContactHaulfrynModalOpen: (isOpen: boolean) => void;
    isContactHaulfrynModalOpen: boolean;
    hasSublettings: boolean;
    useMemberships: boolean;
}

const HeaderContainer: React.FC<HeaderContainerProps> = (props) => (
    <Header
        {...props}
        isHeaderOpen={props.isHeaderOpen}
        setHeaderOpen={props.setHeaderOpen}
        openMyContactDetailsModal={props.openMyContactDetailsModal}
        setContactHaulfrynModalOpen={props.setContactHaulfrynModalOpen}
        useMemberships={props.useMemberships}
    />
);

const mapDispatchToProps = (dispatch: any) => {
    return {
        setHeaderOpen: (isOpen: boolean) => dispatch({ type: SET_HEADER_MENU_OPEN, payload: isOpen }),
        setContactHaulfrynModalOpen: (isOpen: boolean) =>
            dispatch({ type: SET_CONTACT_HAULFRYN_MODAL_OPEN, payload: isOpen }),
    };
};

const mapStateToProps = (state: AppState) => ({
    isHeaderOpen: state.application.isHeaderOpen,
    isContactHaulfrynModalOpen: state.modal.contactHaulfryn.isOpen,
    userRoles: state.user.userRoles,
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
