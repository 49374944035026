import ContentfulClient from  "../ContentfulClient";

export  async function  GetOutletPromotionsCall(ParkContentfulId : string) {
    let filter ={
        content_type: 'fbOutletPromotionToPark',
        links_to_entry: ParkContentfulId,
        include: 1
        
    }
    const result = await ContentfulClient.getEntries<ContentfulOutletPromotion>(filter);

    if (result.errors){
        throw { message: "GetOutletPromotionsCall returned error/s", errors: result.errors } 
    }
    return result;
}
