import { EntryCollection } from "contentful";
import {
    CalendarActionTypes,
    SEARCH_EVENTS_REQUEST,
    SEARCH_EVENTS_SUCCESS,
    SEARCH_EVENTS_FAILURE,
    SET_IS_EVENT_DETAILS_MODAL_OPEN,
    SET_SELECTED_CALENDAR_WIDGET,
    SelectedCalendarWidgetOptions,
    SEARCH_OFFERS_REQUEST,
    SEARCH_OFFERS_SUCCESS,
    SEARCH_OFFERS_FAILURE,
    SET_PAGE_NUMBER,
    SET_IS_ADD_BOOKING_MODAL_OPEN,
    ADD_BOOKING_REQUEST,
    ADD_BOOKING_FAILURE,
    ADD_BOOKING_SUCCESS,
    SET_IS_ADD_BOOKING_SUCCES_MODAL_OPEN,
    SET_ADD_BOOKING_ERROR_MESSAGE
} from "./ActionTypes";

export interface CalendarState {
    events: undefined | EntryCollection<ContentfulEvent>;
    offers: undefined | EntryCollection<ContentfulOffer>;
    eventsLoading: boolean;
    offersLoading: boolean;
    selectedCalendarWidget: SelectedCalendarWidgetOptions;
    pageNumber: number;
    isEventDetailsModalOpen: boolean;
    isAddBookingModalOpen: boolean;
    isAddingBooking: boolean;
    isAddBookingSuccessModalOpen: boolean;
    addBookingErrorMessage: undefined | string;
}

const initialState: CalendarState = {
    events: undefined,
    offers: undefined,
    eventsLoading: false,
    offersLoading: false,
    selectedCalendarWidget: 'events',
    pageNumber: 1,
    isEventDetailsModalOpen: false,
    isAddBookingModalOpen: false,
    isAddingBooking: false,
    isAddBookingSuccessModalOpen: false,
    addBookingErrorMessage: undefined
}

export default (state = initialState, action: CalendarActionTypes): CalendarState => {
    switch (action.type) {
        case SEARCH_EVENTS_REQUEST:
            return { ...state, eventsLoading: action.payload };
        case SEARCH_OFFERS_REQUEST:
            return { ...state, offersLoading: action.payload };
        case SEARCH_EVENTS_SUCCESS:
            return { ...state, eventsLoading: false, events: action.payload };
        case SEARCH_OFFERS_SUCCESS:
            return { ...state, offersLoading: false, offers: action.payload };
        case SEARCH_EVENTS_FAILURE:
        case SEARCH_OFFERS_FAILURE:
            return { ...state };
        case SET_IS_EVENT_DETAILS_MODAL_OPEN:
            return { ...state, isEventDetailsModalOpen: action.payload };
        case SET_SELECTED_CALENDAR_WIDGET:
            return { ...state, selectedCalendarWidget: action.payload };
        case SET_PAGE_NUMBER:
            return { ...state, pageNumber: action.payload };
        case SET_IS_ADD_BOOKING_MODAL_OPEN:
            return { ...state, isAddBookingModalOpen: action.payload, addBookingErrorMessage: undefined };
        case ADD_BOOKING_REQUEST:
            return { ...state, isAddingBooking: action.payload };
        case ADD_BOOKING_SUCCESS:
            return { ...state, isAddingBooking: false, isAddBookingSuccessModalOpen: action.payload };
        case ADD_BOOKING_FAILURE:
            return { ...state, isAddingBooking: false, isAddBookingSuccessModalOpen: false, addBookingErrorMessage: action.payload };
        case SET_IS_ADD_BOOKING_SUCCES_MODAL_OPEN:
            return { ...state, isAddBookingSuccessModalOpen: action.payload };
        case SET_ADD_BOOKING_ERROR_MESSAGE:
            return { ...state, addBookingErrorMessage: action.payload };
        default:
            return state;
    }
}