import UserRoles from "../../Constants/UserRoles";
import ContentfulClient from  "../ContentfulClient";
import { TagsFilter } from "../TagsFilter";

export async function  GetNewsArticlesCall(ParkContentfulId : string,userRoles: UserRoleDTO[]) {
    let filter ={
        content_type: 'newsArticle',
        links_to_entry: ParkContentfulId,
        order:'-fields.published'
    }

    let result = await ContentfulClient.getEntries<NewsArticleHeader>(filter);

    let indexToBeRemoved: Array<string> = [];
    if(userRoles.length > 0){
        result.items.forEach((content)=>{
            if(new TagsFilter().removeContent(content,userRoles)){
                indexToBeRemoved.push(content.sys.id);
            }
        });
    }

    if (indexToBeRemoved.length !== 0){
        indexToBeRemoved.forEach((val)=>{
            result.items.forEach((content,index)=>{
                if(content.sys.id === val){
                    result.items.splice(index,1);        
                }
            });
        })
    }

    return  result;
}