import moment from "moment";
import React from "react";
import { CustomColumnCellRendererProps } from "./Table";

const DateColumnCellRenderer: React.FC<CustomColumnCellRendererProps> = ({
  rowValue,
}) => {
  const getDate = () => {
    const date = moment(rowValue.TransactionDate);
    return date.format("DD/MM/YYYY");
  };

  return <div>{getDate()}</div>;
};

export default DateColumnCellRenderer;
