import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Entry } from 'contentful';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Get } from '../../../../Api/ApiService';
import { GetPhotoPassCall } from '../../../../Api/Calls/GetPhotoPassCall';
import { SecondaryButton } from '../../../../Shared/Components/Presentational/Buttons';
import { Desktop, Mobile } from '../../../../Shared/Components/Presentational/ResponsiveViews';

interface SummaryProps extends WithTranslation {
  membershipForm: any;
  startNewMembership: (membershipForm: FormInput[]) => void;
  ownerMembershipsAvailability: OwnerMembershipAvailabilityDTO;
  contentfulMembershipTypes: Entry<ContentfulMembershipType>[];
  proceedDescription: ContentfulContentObject;
}

const SummaryPage: React.FC<SummaryProps> = (props) => {
  const { t } = props;
  const [scrollPosition, setscrollPosition] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
    getPhotoPassCall();
  }, []);
  const addMembership = () => {
    props.startNewMembership(props.membershipForm as FormInput[]);
  }

  let summary: any = {};

  for (const prop in props.membershipForm) {
    if (prop !== 'id' && prop !== 'membershipState' && prop !== 'transferredMemberships' && prop !== 'ageVerificationSent') {
      summary[prop] = props.membershipForm[prop] !== undefined ? props.membershipForm[prop].value : '';
    }
  }

  const getPhotoPassCall = () => Get<PhotoPassDTO>(new GetPhotoPassCall(props.membershipForm.id))
    .then(result => {
      setFileUrl(result.data.PhotoUrl);
    })
    .catch(() => {
    });

  return (
    <>
      <Desktop>
        <div className='desktopVersion'>
          <div className='row' style={{ marginBottom: "25px" }}>
            <div className='float-left'>
              <h2 style={{ margin: "0px" }}>{t("addMembership.summary.title")}</h2>
            </div>
          </div>

          <div id="membership-summary">
            <div id="personal-details">
              <table className='summary-table'>
                <tr className='summary-table-row'>
                  <td className='summary-table-column'>
                    <table>
                      <tr>
                        <td><b>Name:</b></td>
                        <td>
                          {props.membershipForm.firstName && props.membershipForm.firstName.value} {props.membershipForm.lastName && props.membershipForm.lastName.value}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Email:</b></td>
                        <td>
                          {props.membershipForm.email && props.membershipForm.email.value}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Membership Type: </b></td>
                        <td>
                          {props.membershipForm.contentfulMembershipTypeId && props.contentfulMembershipTypes.filter(type => type.sys.id === props.membershipForm.contentfulMembershipTypeId.value)[0].fields.publicName}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Term Duration:</b></td>
                        <td>
                          {props.membershipForm.termFormula && props.membershipForm.termFormula.value && props.membershipForm.termFormula.value.label}
                        </td>

                      </tr>
                      <tr>
                        <td><b>Marketing Preferences:</b></td>
                        <td>
                          {props.membershipForm.marketing && props.membershipForm.marketing.value == true ? 'Yes' : 'No'}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td className='summary-table-column'>
                    { fileUrl !== '' &&
                      <img src={fileUrl} alt="Member Picture" className='summary-image' height="250px" />
                    }
                  </td>
                </tr>
              </table>
              <div className='row' style={{ marginTop: "15px" }}>
                <div>
                  {documentToReactComponents(props.proceedDescription.content)}
                </div>
              </div>
            </div>
          </div>

          <div style={{ float: "right" }}>
            <SecondaryButton
              id="summaryAdd"
              onClick={addMembership}
              text={t("addMembership.summary.add")}
              className="add-membership-button"
              width='100%'
            />
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className='mobileVersion'>
          <div className='row' style={{ marginBottom: "25px", marginTop: "50px" }}>
            <div className='title-header'>
              <h2 style={{ margin: "0px" }}>{t("addMembership.summary.title")}</h2>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            { fileUrl !== '' && 
              <img src={fileUrl} alt="Member Picture" className='summary-image' height="200px" />
            }
          </div>

          <div style={{ marginTop: "25px" }}>
            <table className='mobile-table'>

              <div>
                <div className='membership-review-details-header'><b>Name:</b></div>
                <div className='membership-review-personal-details'>{props.membershipForm.firstName && props.membershipForm.firstName.value} {props.membershipForm.lastName && props.membershipForm.lastName.value}</div>
              </div>

              <div>
                <div className='membership-review-details-header'><b>Email:</b></div>
                <div className='membership-review-personal-details'>{props.membershipForm.email && props.membershipForm.email.value}</div>
              </div>

              <div>
                <div className='membership-review-details-header'><b>Membership Type:</b></div>
                <div className='membership-review-personal-details'>{props.membershipForm.contentfulMembershipTypeId && props.contentfulMembershipTypes.filter(type => type.sys.id === props.membershipForm.contentfulMembershipTypeId.value)[0].fields.publicName}</div>
              </div>

              <div>
                <div className='membership-review-details-header'><b>Term Duration:</b></div>
                <div className='membership-review-personal-details'>{props.membershipForm.termFormula && props.membershipForm.termFormula.value && props.membershipForm.termFormula.value.label}</div>
              </div>

              <div>
                <div className='membership-review-details-header'><b>Marketing Preferences:</b></div>
                <div className='membership-review-personal-details'>{props.membershipForm.marketing && props.membershipForm.marketing.value == true ? 'Yes' : 'No'}</div>
              </div>

            </table>
            <div className='row' style={{ marginTop: "15px" }}>
              <div>
                {documentToReactComponents(props.proceedDescription.content)}
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center", marginTop: "25px" }}>
            <SecondaryButton
              id="summaryAdd"
              onClick={addMembership}
              text={t("addMembership.summary.add")}
              className="add-membership-button"
              width='100%'
            />
          </div>
        </div>
      </Mobile>
    </>
  )
}

export default withTranslation()(SummaryPage);