import { hasValue } from "../../../../Shared/Utilities/Validation";

const CarPassFields: FormField<AddCarPassDTO>[] = [
    {
        id: 'firstname',
        type: 'text',
        maxLength: 50,
        labelTranslationKey: 'myGuests.passes.add.fields.firstname',
        placeholderTranslationKey: 'myGuests.passes.add.fields.firstname',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.FirstName,
        setter: (pass, value) => pass.FirstName = value,
        flexBasis: '50%'
    },
    {
        id: 'lastname',
        type: 'text',
        maxLength: 50,
        labelTranslationKey: 'myGuests.passes.add.fields.lastname',
        placeholderTranslationKey: 'myGuests.passes.add.fields.lastname',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.LastName,
        setter: (pass, value) => pass.LastName = value,
        flexBasis: '50%'
    },
    {
        id: 'registrationnumber',
        type: 'text',
        maxLength: 10,
        labelTranslationKey: 'myGuests.passes.add.fields.registrationNumber',
        placeholderTranslationKey: 'myGuests.passes.add.fields.registrationNumber',
        validator: (value) => hasValue(value),
        getter: (pass) => pass.RegistrationNumber,
        setter: (pass, value) => pass.RegistrationNumber = value,
        flexBasis: '80%'
    }
];

export default CarPassFields;