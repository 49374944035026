import { PostApiCall } from './ApiCall';

export class PostConfirmPaymentCall extends PostApiCall {
    constructor(paymentDetails: ConfirmPaymentDTO) {
        let url: string = 'payment/confirmPayment';

        super(url);

        this.body = paymentDetails;
    }
}
