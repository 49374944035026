import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getParkInformation } from '../../../Information/Redux/ActionCreators';
import { RELOAD_TRANSLATIONS, SET_SELECTED_NEWS_ITEM } from '../../../Information/Redux/ActionTypes';
import HeaderContainer from '../../../Shared/Components/Container/HeaderContainer';
import MemberHeader from '../../../Shared/Components/Container/MemberHeader';
import NavOption from '../../../Shared/Components/Presentational/NavOption';
import { Desktop, Mobile, useIsDesktop } from '../../../Shared/Components/Presentational/ResponsiveViews';
import { LinkWidget, Widget, WidgetHeader, WidgetList } from '../../../Shared/Components/Presentational/Widget';
import MenuWidget from '../Presentational/MenuWidget';
import WelcomeMessageWidget from '../Presentational/WelcomeMessageWidget';
import * as Routes from "../../../Constants/Routes";
import { getPermissionsFromUserRoles, logout } from '../../../Login/Redux/ActionCreators';
import CalendarListWidget from '../../../Calendar/Components/Container/CalendarListWidget';
import MyCalendarListHeader from '../../../Calendar/Components/Container/MyCalendarListHeader';
import Features from '../../../Constants/Features';
import FeatureToggle from '../../../Shared/Components/Presentational/FeatureToggle';
import ReservationLinks from '../../../Calendar/Components/Container/ReservationLinks';
//@ts-ignore
import Zoom from "react-reveal/Zoom";
import Grid from '../../../Shared/Components/Presentational/Grid';
import NewsCardContainer from '../../../Information/Components/Container/NewsCardContainer';
import * as ActionTypes from '../../../Guests/Redux/ActionTypes';
import ReferredCreateMembershipModal from '../../../Modal/Components/Container/ReferredCreateMembershipModal';
import CalendarWidget from '../Presentational/CalendarWidget';
import { getMyMemberships } from '../../../Guests/Redux/ActionCreators';
import Spinner from '../../../Shared/Components/Presentational/Spinner';


interface MemberHomePageProps {
  userFirstName: string;
  logoutClick: () => void;
  setSelectedPark: (
    parkCode: string,
    parkName: string,
    parkFeatures: string[]
  ) => void;
  dispatch: any;
  hasOwnMembership: boolean;
  updateMembershipFormPage: (page: number) => void;
  selectedLodge: DropdownListOption;
  accountId: number;
  clearMembershipForm: () => void;
  setSelectedNewsItem: (selectedNewsItemId: string) => void;
  userRoles: UserRoleDTO[];
  membershipNumber: string;
  getPermissionsFromUserRoles: () => void;
  permissionCallSuccess: boolean;
  forcedToMakeMembership: boolean;
}

const MemberHomePage: React.FC<MemberHomePageProps> = (props) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [memberships, setMemberships] = useState<MembershipDTO[]>();

  useEffect(() => {
    props.dispatch({ type: RELOAD_TRANSLATIONS });
    props.dispatch(getParkInformation());
    setModalOpen(props.forcedToMakeMembership && !props.hasOwnMembership);
  }, []);

  const DesktopContents = (
    <>
      <MemberHeader
        logoutClick={props.logoutClick}
        backgroundContainerClassName="home-page-background"
      />
      <div className="home-page-container">
        <div className='widget-container'>
          <WelcomeMessageWidget
            userFirstName={props.userFirstName}
            position={{
              columnIndex: 0,
              columnSpan: 3,
              rowIndex: 0,
              rowSpan: 2,
            }}
          />
          <MenuWidget
            position={{
              columnIndex: 5,
              columnSpan: 8,
              rowIndex: 0,
              rowSpan: 4,
            }}
          >
            <LinkWidget
              to={Routes.MyParkAndHome}
              containerClassName="home-navigation"
            >
              <NavOption
                text={t("navigation.myPark")}
                icon="my-park-and-home.svg"
                iconAlt='My Park'
              />
            </LinkWidget>
            <LinkWidget
              to={Routes.MyGuests}
              containerClassName="home-navigation"
              id="MyMemberships"
            >
              <NavOption
                text={t("navigation.myMemberships")}
                icon="my-guests.svg"
                iconAlt="My Members"
              />
            </LinkWidget>
            <LinkWidget
              to={Routes.MyRequestsAndQueries}
              containerClassName="home-navigation"
            >
              <NavOption
                text={t("navigation.myRequests")}
                icon="my-requests.svg"
                iconAlt="My requests"
              />
            </LinkWidget>
          </MenuWidget>
          <FeatureToggle features={[Features.Events, Features.Offers]}>
            <CalendarListWidget
              position={{
                columnIndex: 0,
                columnSpan: 4,
                rowIndex: 4,
                rowSpan: 8,
              }}
              pageSize={3}
              canLoadMore={true}
              widgetComponents={{
                widgetHeader: <MyCalendarListHeader pageSize={3} />,
              }}
            />
          </FeatureToggle>
          <WidgetList position={{ columnIndex: 9, columnSpan: 4, rowIndex: 6, rowSpan: 7, }}>
            {/* <p className="park-news-title-home">{t('myParkAndHome.parkNewsFeed')}</p> */}
            <WidgetHeader title={t("myParkAndHome.parkNewsFeed")} />
            <br />
            <div className='news-feed-container' style={{ padding: "20px", overflowY: "scroll", height: "500px" }}>
              <NewsCardContainer setSelectedNewsItem={props.setSelectedNewsItem} />
            </div>
          </WidgetList>

          <FeatureToggle
            features={[
              Features.Restaurant,
              Features.Activities,
              Features.Spa,
            ]}
            replacement={
              <Widget
                containerClassName="home-park-image-widget-container"
                position={{
                  columnIndex: 5,
                  columnSpan: 4,
                  rowIndex: 6,
                  rowSpan: 6,
                }}
              >
                <div className="home-reservations-header">
                  <WidgetHeader title={t("homePage.myPark")}></WidgetHeader>
                </div>
                <div className="park-home-park-image-container">
                  <Zoom>
                    <img
                      src={t("park.parkImage")}
                      alt="image of the park"
                    ></img>
                  </Zoom>
                </div>
              </Widget>
            }
          >
            <Widget
              containerClassName="reservation-links-widget-container"
              position={{
                columnIndex: 5,
                columnSpan: 4,
                rowIndex: 6,
                rowSpan: 6,
              }}
            >
              <div className="home-reservations-header">
                <WidgetHeader
                  title={t("myCalendar.reservations.header")}
                ></WidgetHeader>
              </div>
              <ReservationLinks
                showHeader={false}
                membershipNumber={props.membershipNumber}
              />
            </Widget>
          </FeatureToggle>
        </div>
      </div>
    </>
  )

  const MobileContents = (
    <>
      <MemberHeader
        logoutClick={props.logoutClick}
      />
      <div className="widget-container-top mobile-home-widget-container">
        <WelcomeMessageWidget
          userFirstName={props.userFirstName}
          position={{
            columnIndex: 1,
            columnSpan: 12,
            rowIndex: 1,
            rowSpan: 2,
          }}
        />
      </div>
      <MenuWidget
        position={{
          columnIndex: 1,
          columnSpan: 12,
          rowIndex: 3,
          rowSpan: 3,
        }}
      >
        <div className="navigation-scroll">
          <div className="widget-mobile-container">
            <LinkWidget
              to={Routes.MyParkAndHome}
              containerClassName="nav-option"
            >
              <NavOption
                text={t("navigation.myPark")}
                icon="my-park-and-home.svg"
                iconAlt="My park"
              />
            </LinkWidget>
          </div>
          <div className="widget-mobile-container">
            <LinkWidget
              to={Routes.MyGuests}
              containerClassName="nav-option"
            >
              <NavOption
                text={t("navigation.myMemberships")}
                icon="my-guests.svg"
                iconAlt="My Members"
              />
            </LinkWidget>
          </div>
          <div className="widget-mobile-container">
            <LinkWidget
              to={Routes.MyRequestsAndQueries}
              containerClassName="nav-option"
            >
              <NavOption
                text={t("navigation.myRequests")}
                icon="my-requests.svg"
                iconAlt="My requests"
              />
            </LinkWidget>
          </div>
        </div>
      </MenuWidget>
      <Grid
        className="mobile-home-widget-container"
        rowCount={2}
        columnCount={12}
      >
        <FeatureToggle features={[Features.Events, Features.Offers]}>
          <CalendarListWidget
            position={{
              columnIndex: 1,
              columnSpan: 12,
              rowIndex: 1,
              rowSpan: 6,
            }}
            pageSize={3}
            canLoadMore={true}
            widgetComponents={{
              widgetHeader: <MyCalendarListHeader pageSize={3} />,
            }}
          />
        </FeatureToggle>
        <Widget
          containerClassName="reservation-links-widget-container"
          position={{
            columnIndex: 1,
            columnSpan: 12,
            rowIndex: 8,
            rowSpan: 6,
          }}
        >
          <WidgetHeader
            title={t("myCalendar.reservations.header")}
          ></WidgetHeader>
          <ReservationLinks
            showHeader={false}
            membershipNumber={props.membershipNumber}
          />
          <br />
        </Widget>
        <WidgetList position={{ columnIndex: 1, columnSpan: 12, rowIndex: 13, rowSpan: 6 }}>
          {/* <p className="park-news-title-mobile">{t('myParkAndHome.parkNewsFeed')}</p> */}
          <WidgetHeader title={t("myParkAndHome.parkNewsFeed")} />
          <br />
          <NewsCardContainer setSelectedNewsItem={props.setSelectedNewsItem} />
        </WidgetList>

      </Grid>
    </>
  );

  return (
    <>
      {props.permissionCallSuccess ? (
        <>
          <Desktop>
            {DesktopContents}
          </Desktop>
          <Mobile>
            {MobileContents}
          </Mobile>
          <ReferredCreateMembershipModal
            isOpen={modalOpen}
            closeModal={setModalOpen}
            clearMembershipForm={props.clearMembershipForm}
            updateMembershipFormPage={props.updateMembershipFormPage}
          />
        </>
      ) : (
        <div className="widget-container">
          <Widget
            position={{
              columnIndex: 1,
              columnSpan: 12,
              rowIndex: 3,
              rowSpan: 9,
            }}
          >
            <div className="memberships-spinner">
              <Spinner />
            </div>
          </Widget>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  let userFirstName: string = "";
  let selectedLodge: DropdownListOption = { value: "", label: "" };
  let lodges: LodgeDTO[] = [];
  let lodgeOptions: [DropdownListOption] = [{ value: "", label: "" }];
  let contractEndDate = "";
  let accountId = 0;
  let hasSublettings = false;
  let accountNumber = "";

  if (state.user) {
    userFirstName = state.user.firstname ? state.user.firstname : "";
    lodges = state.user.lodges ? state.user.lodges : [];

    for (let i = 0; i < lodges.length; i++) {
      lodgeOptions[i] = {
        value: lodges[i].LodgeId.toString(),
        label: lodges[i].Name,
      };

      if (state.user.lodge && state.user.lodge.LodgeId === lodges[i].LodgeId) {
        selectedLodge = lodgeOptions[i];
      }
    }
  }

  if (state.user.lodge) {
    contractEndDate = state.user.lodge.ContractEndDate;
    const selectedLodge: LodgeDTO = state.user.lodges.find(
      (x: LodgeDTO) => x.LodgeId === state.user.lodge.LodgeId
    );
    if (selectedLodge) {
      accountId = selectedLodge.AccountId;
      accountNumber = selectedLodge.AccountNo;
      hasSublettings = selectedLodge.HasSublettings;
    }
  }

  return {
    userFirstName,
    userRoles: state.user.userRoles,
    hasOwnMembership: state.user.hasOwnMembership,
    selectedLodge,
    membershipNumber: state.user.membershipNumber,
    permissionCallSuccess: state.user.permissionCallSuccess,
    forcedToMakeMembership: state.user.forcedToMakeMembership,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutClick: () => {
      dispatch(logout(false));
    },
    setSelectedNewsItem: (selectedNewsItemId: string) =>
      dispatch({ type: SET_SELECTED_NEWS_ITEM, payload: selectedNewsItemId }),
    clearMembershipForm: () => {
      dispatch({ type: ActionTypes.SAVE_AND_EXIT })
    },
    updateMembershipFormPage: (page: number) => {
      dispatch({ type: ActionTypes.UPDATE_MEMBERSHIP_FORM_PAGE, payload: page })
    },
    getPermissionsFromUserRoles: () =>
      dispatch(getPermissionsFromUserRoles()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberHomePage);