import React, { Component } from "react";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { ClickableIcon } from "../../../Shared/Components/Presentational/Icons";
import { Widget } from "../../../Shared/Components/Presentational/Widget";
import { PrimaryButton, SecondaryButton } from "../../../Shared/Components/Presentational/Buttons";
import MemberDetails from "../Presentational/ReferMembershipSubPages/MemberDetails";
import { SAVE_AND_EXIT, UPDATE_REFER_MEMBERSHIP_FORM_PAGE } from "../../Redux/ActionTypes";
import { getOwnerMembershipAvailability } from "../../../User/Redux/ActionCreators";
import { SET_ERROR_PROMPT_OPEN, SET_EXIT_PROMPT_OPEN, SET_REFER_SENT_PROMPT_OPEN } from "../../../Modal/Redux/ActionTypes";
import ReferralSentAlert from "../../../Modal/Components/Container/ReferralSentAlert";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import { GetHTMlContentBlockCall } from "../../../Contentful/Calls/GetHTMLContentBlockCall";
import { ReferMembershipForm } from "../../../ReferMembershipForm";
import ExitPrompt from "../../../Modal/Components/Container/ExitPrompt";
import Spinner from "../../../Shared/Components/Presentational/Spinner";
import { PostReferralMembership } from "../../../Api/Calls/PostReferralMembership";
import { Post, Put } from "../../../Api/ApiService";
import ErrorPrompt from "../../../Modal/Components/Container/ErrorPrompt";
import { getReferredMembershipsByOwner } from "../../Redux/ActionCreators";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { StaticContext } from "react-router";
import * as Routes from '../../../Constants/Routes';

interface ReferMembershipProps extends WithTranslation {
  isHeaderOpen: boolean;
  isReferSentPromptOpen: boolean;
  isErrorPrompt: boolean;
  referMembershipFormPage: number;
  referMembership: ReferMembershipDTO;
  referredMemberships: ReferralDTO[];
  ownerMembershipsAvailability: OwnerMembershipAvailabilityDTO;
  updateReferMembershipFormPage: (pageNum: number) => void;
  getReferredMembershipsByOwner: () => void;
  setReferralSentModalOpen: (isOpen: boolean) => void;
  setErrorModalOpen: (isOpen: boolean) => void;
  saveAndExit: () => void;
  getOwnerMembershipAvailability: () => void;
  canReferWithMemberships: boolean;
  canReferWithOutMemberships: boolean;
}

interface ReferMembershipState {
  showErrors: boolean;
  transferMembershipText: ContentfulContentObject | undefined;
  contentLoaded: boolean;
  isReferringinProcess: boolean;
  isButtonDisabled: boolean;
  referMembershipForm: ReferMembershipForm;
  isReferred: boolean;
  usedMembership: number;
  exit: boolean;
  memberDetailText: ContentfulContentObject | undefined;
  manageMemberText: ContentfulContentObject | undefined;
  reallocateMemberText: ContentfulContentObject | undefined;
  remainingMemberships: number;
  availableMemberships: number;
  transferredMemberships: number;
}

type Section = {
  key: number;
  title: string;
  name: string;
}

class ReferMembership extends Component<ReferMembershipProps, ReferMembershipState, RouteComponentProps>{

  constructor(props: any) {
    super(props);
    this.state = {
      showErrors: false,
      transferMembershipText: undefined,
      contentLoaded: false,
      isReferringinProcess: false,
      isButtonDisabled: false,
      exit: false,
      referMembershipForm: new ReferMembershipForm() as IReferMembership,
      isReferred: false,
      usedMembership: 0,
      memberDetailText: undefined,
      manageMemberText: undefined,
      reallocateMemberText: undefined,
      remainingMemberships: -1,
      availableMemberships: -1,
      transferredMemberships: -1,
    }
    this.props.getOwnerMembershipAvailability();
    Promise.all([
      this.getContentBlock("My Haulfryn - Membership - Referral - Transfer quota", "transferMembershipText"),
      this.getContentBlock("My Haulfryn - Membership - Create Referral", "memberDetailText"),
      this.getContentBlock("My Haulfryn - Membership - Referral - Manage Referral", "manageMemberText"),
      this.getContentBlock("My Haulfryn - Membership - Referral - Reallocate quota", "reallocateMemberText"),
    ]).then(r => {
      r.forEach(c => this.setState({ ...this.state, [c.name]: c }));
      this.setState({ contentLoaded: true });
    })
  }

  resolveQueryParams(queryParams: string): { [keys: string]: string } {
    let KVParams: {
      [keys: string]: string
    } = {};

    if (queryParams.startsWith("?", 0)) {
      queryParams = queryParams.slice(1);
      let params: string[] = queryParams.split("&");
      params.forEach((p) => {
        let keyValue = p.split("=");
        if (keyValue.length === 2) {
          KVParams[keyValue[0]] = keyValue[1];
        }
      });
    }
    return KVParams
  }

  resolveReferralId() {
    let queryParam: string = window.location.search;
    let params: { [keys: string]: string } = this.resolveQueryParams(queryParam);
    let form = new ReferMembershipForm();
    let idFound: boolean = false;
    if (this.props.referredMemberships) {
      this.props.referredMemberships.forEach((ref) => {
        if (ref.id && params["id"] === ref.id) {
          form = ReferMembershipForm.fromReferDTO(ref);
          this.setState({ referMembershipForm: form });
          this.setState({ usedMembership: ref.usedMembership });
          this.setState({ availableMemberships: ref.availableMemberships, transferredMemberships: ref.transferredMemberships });
          idFound = true;
        }
      })
    }
    if (idFound) {
      this.setState({ isReferred: true });
    }
  }

  getContentBlock = async (name: string, label: string) => {
    const transferText = await GetHTMlContentBlockCall(name, label);
    return transferText;
  }

  componentDidMount() {
    this.resolveReferralId();
  }

  componentDidUpdate(prevProps: Readonly<ReferMembershipProps>, prevState: Readonly<ReferMembershipState>, snapshot?: RouteComponentProps<{}, StaticContext, any> | undefined): void {
    if (this.props.ownerMembershipsAvailability && this.state.remainingMemberships === -1 && this.state.referMembershipForm && this.state.usedMembership !== undefined) {
      this.setState({ remainingMemberships: (this.props.ownerMembershipsAvailability.available + this.state.referMembershipForm.transferredMemberships.value) });
    }

    if (prevProps.ownerMembershipsAvailability !== this.props.ownerMembershipsAvailability) {
      this.setState({ remainingMemberships: (this.props.ownerMembershipsAvailability.available + this.state.referMembershipForm.transferredMemberships.value) });
    }
  }

  onBack() {
    this.setState({ showErrors: false });
    if (this.props.referMembershipFormPage - 1 >= 0) {
      switch (this.props.referMembershipFormPage) {
        case 1:
          if (!(this.state.referMembershipForm as IMembershipForm).contentfulMembershipTypeId!.value) {
            this.updateValue('', "contentfulMembershipTypeId", []);
          }
          break;
        default:
          break;
      }
      this.props.updateReferMembershipFormPage(this.props.referMembershipFormPage - 1);
    }
  }

  referMembershipDTO(referMembershipForm: ReferMembershipForm): ReferMembershipDTO {
    let referMembership: ReferMembershipDTO =
    {
      Id: referMembershipForm.id,
      FirstName: referMembershipForm.firstName.value,
      LastName: referMembershipForm.lastName.value,
      Email: referMembershipForm.email.value,
      PhoneNumber: referMembershipForm.phoneNumber.value,
      TransferredMemberships: referMembershipForm.transferredMemberships.value,
      AllowFurtherReferrals: referMembershipForm.allowFurtherReferrals.value,
    };
    return referMembership;
  }

  onSendReferral() {
    this.setState({ isReferringinProcess: true, isButtonDisabled: true });
    let invalid = this.state.referMembershipForm.IsNotValid();
    let form = this.state.referMembershipForm as IReferMembership;
    if (form.transferredMemberships) {
      if (this.props.ownerMembershipsAvailability) {
        if ((this.state.isReferred && form.transferredMemberships.value > this.state.remainingMemberships) && form.transferredMemberships.value > 0) {
          this.updateValue(form.transferredMemberships.value, "transferredMemberships", ["isLessThanRemaining"]);
          invalid = true;
        }
      }
    }

    if (invalid) {
      this.setState({ showErrors: true });
      this.setState({ isReferringinProcess: false, isButtonDisabled: false });
      return;
    } else {
      this.setState({ showErrors: false });
      Post<string>(new PostReferralMembership(this.referMembershipDTO(this.state.referMembershipForm)))
        .then((response) => {
          if (response.status === 200) {
            this.setState({ isReferringinProcess: false, isButtonDisabled: false });
            this.props.setReferralSentModalOpen(true)
          } else {
            this.setState({ isReferringinProcess: false, isButtonDisabled: false });
            this.props.setErrorModalOpen(true);
          }
        }).catch((error) => {
          this.setState({ isReferringinProcess: false, isButtonDisabled: false });
          this.props.setErrorModalOpen(true);
        });
    }
  }


  onSaveAndExit() {
    this.setState({exit: true});
  }

  updateValue(value: any, fieldName: any, errors: string[]) {
    const form = this.state.referMembershipForm as unknown as FormInput[];
    if (fieldName === "phoneNumber" && value === "") {
      form[fieldName] = { value: value, errors: [] };
    } else {
      form[fieldName] = { value: value, errors: errors };
    }
  }

  sections: Section[] =
    [
      {
        key: 0,
        title: "referMembership.memberDetails.title",
        name: 'memberDetails'
      },
      {
        key: 1,
        title: "referMembership.membership.title",
        name: 'membershipType'
      },
      {
        key: 2,
        title: "referMembership.transfer.title",
        name: 'transfer'
      },
      {
        key: 3,
        title: "referMembership.confirm.title",
        name: 'confirm'
      }
    ]

  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <>
        {this.state.exit && <Redirect to={Routes.MyGuests}/>}
        {this.state.contentLoaded ? (
          <div className="widget-container">
            <Desktop>
              {!this.props.isHeaderOpen && (
                <>
                  {/* <h1 className="widget-layout-title">{t("referMembership.title")}</h1> */}
                  {/* <p className="widget-layout-message">{t("referMembership.message")}</p> */}
                </>
              )}
            </Desktop>

            <Widget
              position={{
                columnIndex: 1,
                columnSpan: 12,
                rowIndex: 3,
                rowSpan: 9,
              }}
            >

              {(this.props.referMembershipFormPage > 0) && (
                <ClickableIcon
                  icon={faArrowAltCircleLeft}
                  clickAction={this.onBack.bind(this)}
                  className="back-button"
                  size={"2x"}
                />
              )}

              <div className="form-container">
                {this.props.referMembershipFormPage === 0 &&
                  <MemberDetails
                    isReferred={this.state.isReferred}
                    manageMemberText={this.state.manageMemberText!}
                    reallocateMemberText={this.state.reallocateMemberText!}
                    remainingMembership={this.state.remainingMemberships}
                    onInput={this.updateValue.bind(this)}
                    membershipForm={this.state.referMembershipForm}
                    showErrors={this.state.showErrors}
                    transferMemberText={this.state.transferMembershipText!}
                    memberDetailText={this.state.memberDetailText!}
                    ownerMembershipAvailability={this.props.ownerMembershipsAvailability}
                    usedMembership={this.state.usedMembership}
                    transferredMemberships={this.state.transferredMemberships}
                    availableMemberships={this.state.availableMemberships}
                    referWMemberships={this.props.canReferWithMemberships}
                    referWOutMemberships={this.props.canReferWithOutMemberships}
                  />
                }
              </div>

              <div className="widget-footer">
                <Desktop>
                  <SecondaryButton
                    id="exitBtn"
                    text={t("general.exit")}
                    type="submit"
                    onClick={this.onSaveAndExit.bind(this)}
                    className="membership-btn exit-btn"
                  />
                  <PrimaryButton
                    text={this.state.isReferred ? "Update Referral" : t("referMembership.sendReferral")}
                    id="saveAndContinue"
                    type="submit"
                    disabled={this.state.isButtonDisabled}
                    isLoading={this.state.isReferringinProcess}
                    onClick={this.onSendReferral.bind(this)}
                    className="save-cnt-btn membership-btn"
                  />
                </Desktop>
                <Mobile>
                  <PrimaryButton
                    text={this.state.isReferred ? "Update Referral" : t("referMembership.sendReferral")}
                    id="saveAndContinue"
                    type="submit"
                    disabled={this.state.isButtonDisabled}
                    isLoading={this.state.isReferringinProcess}
                    onClick={this.onSendReferral.bind(this)}
                    className="membership-btn"
                  />
                  <SecondaryButton
                    id="exitBtn"
                    text={t("general.exit")}
                    type="submit"
                    onClick={this.onSaveAndExit.bind(this)}
                    className="membership-btn"
                  />

                </Mobile>
              </div>

            </Widget>
            {this.props.isReferSentPromptOpen && (
              <ReferralSentAlert operationType={this.state.isReferred ? "update" : "create"} />
            )}
            {this.props.isErrorPrompt && (
              <ErrorPrompt />
            )}
          </div>
        ) : (
          <div className='memberships-spinner'>
            <Spinner />
          </div>
        )}
      </>
    );
  };
};



const mapStateToProps = (state: any) => {
  return {
    isHeaderOpen: state.application.isHeaderOpen,
    isReferSentPromptOpen: state.modal.referralSent.isOpen,
    referMembershipFormPage: state.guests.referMembershipFormPage,
    referMembership: state.guests.referMembership,
    ownerMembershipsAvailability: state.user.ownerMembershipsAvailability,
    referredMemberships: state.guests.referredMemberships,
    isErrorPrompt: state.modal.error.isOpen,
    canReferWithMemberships: state.user.canReferWithMemberships,
    canReferWithOutMemberships: state.user.canReferWithOutMemberships,
  };
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    getReferredMembershipsByOwner: () => { dispatch(getReferredMembershipsByOwner()) },
    updateReferMembershipFormPage: (pageNo: number) => {
      dispatch({ type: UPDATE_REFER_MEMBERSHIP_FORM_PAGE, payload: pageNo })
    },
    saveAndExit: () => {
      dispatch({ type: SAVE_AND_EXIT })
    },
    setErrorModalOpen: (isOpen: boolean) => {
      dispatch({ type: SET_ERROR_PROMPT_OPEN, payload: isOpen })
    },
    getOwnerMembershipAvailability: () => {
      dispatch(getOwnerMembershipAvailability());
    },
    setReferralSentModalOpen: (isOpen: boolean) => {
      dispatch({ type: SET_REFER_SENT_PROMPT_OPEN, payload: isOpen })
    },
  }
}

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ReferMembership);