import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Desktop, Mobile } from '../../../Shared/Components/Presentational/ResponsiveViews';
import ReservationLinksWidget from '../Presentational/ReservationLinksWidget';
import feature from '../../../Shared/Decorators/Feature';
import Features from '../../../Constants/Features';
import { getParkInformation } from '../../../Information/Redux/ActionCreators';
import { Entry } from 'contentful';
import UserRoles from '../../../Constants/UserRoles';

interface ReservationLinksProps {
    getParkInformation: any;
    showHeader: boolean;
    lodgeId?:number;
    accountId?:number;
    parkInformation: Entry<ContentfulParkInformation>;
    membershipNumber?: string;
    userRoles: UserRoleDTO[];
}

interface ReservationLinksState {
    
}
@feature({ features: [Features.Restaurant, Features.Spa, Features.Activities] })
class ReservationLinks extends Component<ReservationLinksProps, ReservationLinksState> {
    public state: ReservationLinksState = {};

    public componentDidMount(): void {
        this.props.getParkInformation();
    }

    public render(): JSX.Element {
        return (
            <>
                <Desktop>
                    <ReservationLinksWidget parkInformation={this.props.parkInformation} showHeader={this.props.showHeader} accountId={this.props.accountId} lodgeId={this.props.lodgeId} membershipNumber={this.props.membershipNumber} isMember={!UserRoles.IsOwner(this.props.userRoles)} />
                </Desktop>
                <Mobile>
                    <ReservationLinksWidget parkInformation={this.props.parkInformation} showHeader={this.props.showHeader} containerClassName="reservation-links-mobile" accountId={this.props.accountId} lodgeId={this.props.lodgeId} membershipNumber={this.props.membershipNumber} isMember={!UserRoles.IsOwner(this.props.userRoles)} />
                </Mobile>
            </>
        );
    };
}

const mapStateToProps = (state: any) => ({
    parkInformation: state.information.parkInformation,
    userRoles: state.user.userRoles,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        getParkInformation: () => dispatch(getParkInformation())
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ReservationLinks);