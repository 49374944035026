import { PostApiCall } from "./ApiCall";

export class PostAddMembershipCall extends PostApiCall {
    constructor(membership: NewMembershipDTO) {
        let url: string = "Membership/AddMembership";

        super(url);

        this.body = membership;
    }
}