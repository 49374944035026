import ContentfulClient from  "../ContentfulClient";

export async function GetSettingCall(key : string) {
    let filter ={
        content_type: 'setting',
        'fields.key': key
    }

    const settings = await ContentfulClient.getEntries<ContentfulSetting>(filter);

    return settings.items[0]
}