import { PutApiCall } from "./ApiCall";

export class PutUpdateSeasonPassCall extends PutApiCall {
  constructor(lodgeId: Number, id: string, coOwnerAccessType: string) {
    let url: string = `passes/${lodgeId}/UpdateSeasonPassAccess`;

    super(url);
    this.body = { coOwnerAccessType, id };
  }
}
