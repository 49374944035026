export class ReferMembershipForm implements IReferMembership {
  id?: string;
  firstName: FormInput;
  lastName: FormInput;
  email: FormInput;
  phoneNumber: FormInput;
  contentfulmembershipTypeId: FormInput;
  transferredMemberships: FormInput;
  allowFurtherReferrals: FormInput;

  public static fromReferMembership(membershipData: ReferMembershipDTO): ReferMembershipForm {
    let membership = new this();
    membership.id = membershipData.Id;
    membership.contentfulmembershipTypeId = (membershipData.ContentfulMembershipTypeId ? { value: membershipData.ContentfulMembershipTypeId, errors: [] } : { errors: [] });
    membership.transferredMemberships = (membershipData.TransferredMemberships ? { value: membershipData.TransferredMemberships, errors: [] } : { errors: [] });
    membership.firstName = (membershipData.FirstName ? { value: membershipData.FirstName, errors: [] } : { errors: ["hasValue"] });
    membership.lastName = (membershipData.LastName ? { value: membershipData.LastName, errors: [] } : { errors: ["hasValue"] });
    membership.phoneNumber = (membershipData.PhoneNumber ? { value: membershipData.PhoneNumber, errors: [] } : { errors: [] });
    membership.email = (membershipData.Email ? { value: membershipData.Email, errors: [] } : { errors: ["hasValue"] });
    membership.allowFurtherReferrals = (membershipData.AllowFurtherReferrals ? { value: membershipData.AllowFurtherReferrals, errors: [] } : { errors: ["hasValue"] });
    return membership;
  }

  public static fromReferDTO(data: ReferralDTO): ReferMembershipForm {
    let membership = new this();
    membership.id = data.id;
    membership.transferredMemberships = (data.transferredMemberships !== undefined ? { value: data.transferredMemberships, errors: [] } : { errors: [] });
    membership.firstName = (data.firstName ? { value: data.firstName, errors: [] } : { errors: [] });
    membership.lastName = (data.lastName ? { value: data.lastName, errors: [] } : { errors: [] });
    membership.allowFurtherReferrals = (data.allowFurtherReferrals ? { value: data.allowFurtherReferrals, errors: [] } : { errors: [] });
    membership.email = data.email ? { value: data.email, errors: [] } : { errors: [] };
    membership.phoneNumber = data.phoneNumber ? { value: data.phoneNumber, errors: [] } : { errors: [] };
    membership.contentfulmembershipTypeId = { errors: [] };
    return membership;
  }

  constructor() {
    this.contentfulmembershipTypeId = { value: '', errors: [] };
    this.transferredMemberships = { value: 0, errors: [] };
    this.firstName = { value: '', errors: ["hasValue"] };
    this.lastName = { value: '', errors: ["hasValue"] };
    this.email = { value: '', errors: ["hasValue"] };
    this.phoneNumber = { value: '', errors: [] };
    this.allowFurtherReferrals = { value: true, errors: [] };
  }

  public IsNotValid(): boolean {
    if (this.firstName && this.firstName.errors.length > 0) {
      return true;
    } else if (this.lastName && this.lastName.errors.length > 0){
      return true;
    } else if (this.email && this.email.errors.length > 0){
      return true;
    } else if (this.phoneNumber && this.phoneNumber.errors.length > 0){
      return true;
    } else if (this.contentfulmembershipTypeId && this.contentfulmembershipTypeId.errors.length > 0){
      return true;
    } else if (this.transferredMemberships && this.transferredMemberships.errors.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}