import {
  SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN,
  SET_CONTACT_HAULFRYN_MODAL_OPEN,
  SET_IS_UPDATE_MY_PASSWORD_SUCCES_MODAL_OPEN,
  SET_IS_MARKETING_PREFERENCES_WARNING_MODAL_OPEN,
  SET_REFER_MEMBERSHIPS_PROMPT_OPEN,
  SET_MEMBERSHIPS_FLOW_CONTACT_PROMPT_OPEN,
  SET_MEMBERSHIPS_CONTACT_PROMPT_NEXT_STEP,
  SET_REFER_SENT_PROMPT_OPEN,
  SET_EXIT_PROMPT_OPEN,
  SET_ERROR_PROMPT_OPEN,
} from "./ActionTypes";

const initialState = {
  myContactDetails: { isOpen: false },
  contactHaulfryn: { isOpen: false },
  referMembership: {isOpen: false},
  exit: {isOpen: false },
  error: {isOpen: false},
  referralSent: { isOpen: false },
  updatePasswordSuccess: { isOpen: false },
  marketingPreferencesWarningModalIsOpen: false,
  membershipflowContactprompt: { isOpen: false, type: ""},
  membershipContactPromptNextStep: { nextStep: false },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN:
      return {
        ...state,
        myContactDetails: { ...state.myContactDetails, isOpen: action.payload },
      };
    case SET_EXIT_PROMPT_OPEN:
      return {
        ...state,
        exit: { ...state.exit, isOpen: action.payload },
      };
    case SET_ERROR_PROMPT_OPEN:
      return {
        ...state,
        error: { ...state.error, isOpen: action.payload },
      };
    case SET_CONTACT_HAULFRYN_MODAL_OPEN:
      return {
        ...state,
        contactHaulfryn: { ...state.contactHaulfryn, isOpen: action.payload },
      };
    case SET_REFER_MEMBERSHIPS_PROMPT_OPEN:
      return {
        ...state,
        referMembership: {...state.referMembership, isOpen: action.payload },
      };
    case SET_REFER_SENT_PROMPT_OPEN:
      return {
        ...state,
        referralSent: {...state.referralSent, isOpen: action.payload },
      };
    case SET_MEMBERSHIPS_FLOW_CONTACT_PROMPT_OPEN:
      return {
        ...state,
        membershipflowContactprompt:  {...state.membershipflowContactprompt, isOpen: action.payload.isOpen, type: action.payload.type },
      }
    case SET_MEMBERSHIPS_CONTACT_PROMPT_NEXT_STEP:
      return {
        ...state,
        membershipContactPromptNextStep:  {...state.membershipContactPromptNextStep, nextStep: action.payload },
      }
    case SET_IS_UPDATE_MY_PASSWORD_SUCCES_MODAL_OPEN:
      return {
        ...state,
        updatePasswordSuccess: {
          ...state.updatePasswordSuccess,
          isOpen: action.payload,
        },
      };
    case SET_IS_MARKETING_PREFERENCES_WARNING_MODAL_OPEN:
      return {
        ...state,
        marketingPreferencesWarningModalIsOpen: action.payload,
      };
    default:
      return state;
  }
};
