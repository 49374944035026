import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Desktop, Mobile } from "../../../Shared/Components/Presentational/ResponsiveViews";
import {
  SET_IS_MARKETING_PREFERENCES_WARNING_MODAL_OPEN,
  SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN,
} from "../../Redux/ActionTypes";
import { SET_IS_UPDATE_MY_PASSWORD_SUCCES_MODAL_OPEN } from "../../../Modal/Redux/ActionTypes";
import MyContactDetailsModal from "../Presentational/MyContactDetailsModal";
import {
  updateMarketingPreferences,
  updateMyContactDetails,
  updateMyPassword,
} from "../../../User/Redux/ActionCreators";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  ConfirmModal,
  SuccessModal,
} from "../../../Shared/Components/Presentational/Modal";

interface MyContactDetailsProps extends WithTranslation {
  isOpen: boolean;
  loginMethod: string;
  myContactDetails: any;
  marketingPreferences: MarketingPreferencesDTO | undefined;
  updateMarketingPreferences: (preferences: MarketingPreferencesDTO) => void;
  onBackButtonClick: () => void;
  updateContactDetails: (newContactDetails: UpdateContactDetailsDTO) => void;
  updatePassword: (passwordDetails: SetPasswordDto) => void;
  toggleUpdatePasswordSuccessModal: (isOpen: boolean) => void;
  isUpdatePasswordSuccessModalOpen: boolean;
  toggleMarketingPreferencesWarningModal: (isOpen: boolean) => void;
  marketingPreferencesWarningModalIsOpen: boolean;
  updatingPasswordError: string;
  ownerId: number;
}

class MyContactDetails extends Component<MyContactDetailsProps> {
  newMarketingPreferences: MarketingPreferencesDTO = {} as MarketingPreferencesDTO;

  public render(): JSX.Element {
    const { t } = this.props;



    const initiateUpdateMarketingPreferences = (
      marketingPreferences: MarketingPreferencesDTO,
      showWarning: boolean) => {
      if (showWarning) {
        this.newMarketingPreferences = marketingPreferences;
        this.props.toggleMarketingPreferencesWarningModal(showWarning);
      } else {
        this.props.updateMarketingPreferences(marketingPreferences);
      }
    };

    return (
      <>
        {this.props.isOpen && (
          <>
            <Desktop>
              <MyContactDetailsModal
                isOpen={true}
                isEmail={this.props.loginMethod === "email"}
                myContactDetails={this.props.myContactDetails}
                onBackButtonClick={this.props.onBackButtonClick}
                onUpdateContactDetailsClick={this.props.updateContactDetails}
                onUpdatePasswordClick={this.props.updatePassword}
                updatingPasswordError={this.props.updatingPasswordError}
                isOwner={this.props.ownerId > 0}
                marketingPreferences={this.props.marketingPreferences}
                updateMarketingPreferences={initiateUpdateMarketingPreferences}
              />
            </Desktop>
            <Mobile>
              <MyContactDetailsModal
                className="mobile-modal owner-contact-details-modal-mobile"
                isOpen={true}
                isEmail={this.props.loginMethod === "email"}
                myContactDetails={this.props.myContactDetails}
                onBackButtonClick={this.props.onBackButtonClick}
                onUpdateContactDetailsClick={this.props.updateContactDetails}
                onUpdatePasswordClick={this.props.updatePassword}
                updatingPasswordError={this.props.updatingPasswordError}
                isOwner={this.props.ownerId > 0}
                marketingPreferences={this.props.marketingPreferences}
                updateMarketingPreferences={initiateUpdateMarketingPreferences}
              />
            </Mobile>
            <SuccessModal
              isOpen={this.props.isUpdatePasswordSuccessModalOpen}
              closeModal={() => {
                this.props.toggleUpdatePasswordSuccessModal(false);
              }}
              title={t("myDetailsModal.passwordUpdateSuccessTitle")}
              message={t("myDetailsModal.passwordUpdateSuccessMessage")}
              buttonText={t("general.ok")}
            />
            <ConfirmModal
              isOpen={this.props.marketingPreferencesWarningModalIsOpen}

              onClose={(confirmed) => {
                console.log(`confirmed: ${confirmed}`);

                if (confirmed) {
                  this.props.updateMarketingPreferences(this.newMarketingPreferences);
                }

                this.props.toggleMarketingPreferencesWarningModal(false);
              }}

              title={t("myDetailsModal.marketingPreferences.warningTitle")}
              message={t("myDetailsModal.marketingPreferences.warningMessage")}
              cancelButtonText={t("myDetailsModal.marketingPreferences.cancelation")}
              confirmButtonText={t("myDetailsModal.marketingPreferences.confirmation")}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isOpen: state.modal.myContactDetails.isOpen,
    loginMethod: state.login.loginMethod,
    isUpdatePasswordSuccessModalOpen: state.modal.updatePasswordSuccess.isOpen,
    marketingPreferencesWarningModalIsOpen: state.modal.marketingPreferencesWarningModalIsOpen,
    updatingPasswordError: state.user.updatingPasswordError,
    ownerId: state.user.ownerId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeSuccessModal: () => {
      dispatch({ type: SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN, payload: false });
    },
    updateContactDetails: (newContactDetails: UpdateContactDetailsDTO) =>
      dispatch(updateMyContactDetails(newContactDetails)),
    updateMarketingPreferences: (newMarketingPreferences: MarketingPreferencesDTO) => 
      dispatch(updateMarketingPreferences(newMarketingPreferences)),
    updatePassword: (passwordDetails: SetPasswordDto) => {
      dispatch(updateMyPassword(passwordDetails));
    },
    toggleUpdatePasswordSuccessModal: (open: boolean) => {
      dispatch({
        type: SET_IS_UPDATE_MY_PASSWORD_SUCCES_MODAL_OPEN,
        payload: open,
      });
      dispatch({ type: SET_IS_MY_CONTACT_DETAILS_MODAL_OPEN, payload: false });
    },
    toggleMarketingPreferencesWarningModal: (isOpen: boolean) => {
      dispatch({
        type: SET_IS_MARKETING_PREFERENCES_WARNING_MODAL_OPEN,
        payload: isOpen,
      });
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MyContactDetails)
);
