import { withTranslation, WithTranslation } from "react-i18next";
import React, { ChangeEvent, Component, useEffect, useState } from "react";
import { PrimaryButton } from "../../../../Shared/Components/Presentational/Buttons";
import { connect } from "react-redux";
import { Widget } from "../../../../Shared/Components/Presentational/Widget";
import { PostSetPaymentMethodCall } from "../../../../Api/Calls/PostSetPaymentMethodCall";
import { Get, Post } from "../../../../Api/ApiService";
import { Desktop, Mobile } from "../../../../Shared/Components/Presentational/ResponsiveViews";
import { GetHTMlContentBlockCall } from "../../../../Contentful/Calls/GetHTMLContentBlockCall";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Spinner from "../../../../Shared/Components/Presentational/Spinner";
import * as ActionTypes from '../../../Redux/ActionTypes';
import MemberBreakdownContainer from "./MemberBreakdownContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import MemberPaymentContainer from "./MemberPaymentContainer";
import { GetUpdateTermsAndConditionsSignedDateCall } from "../../../../Api/Calls/GetUpdateTermsAndConditionsSignedDateCall";
import FrequencyFormula from './../../../../Constants/FrequencyFormula';
import { GetStripeCheckoutUrlsCall } from "../../../../Api/Calls/GetStripeCheckoutUrlsCall";
import FriendMembershipModal from "../../../../Modal/Components/Container/FriendMembershipQuotaModal";
import { INLINES } from '@contentful/rich-text-types';

interface SelectPaymentProps extends WithTranslation {
  isHeaderOpen: boolean;
  membershipForm?: any;
  basketId: string;
  paymentDetails: PaymentBreakdownDTO;
}

const SelectPayment: React.FC<SelectPaymentProps> = (props: SelectPaymentProps) => {
  const { t } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [titleDescription, setTitleDescription] = useState(undefined as ContentfulContentObject | undefined);
  const [noPaymentDescription, setNoPaymentDescription] = useState(undefined as ContentfulContentObject | undefined);
  const [paymentDescription, setPaymentDescription] = useState(undefined as ContentfulContentObject | undefined);
  const [contentLoaded, setContentLoaded] = useState(false);

  const [isAgreementChecked, setisAgreementChecked] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlan | undefined>(undefined);

  const [datesExceededFriendQuota, setDatesExceededFriendQuota] = useState<FriendQuotaExceededDTO[] | undefined>();
  const [isFriendQuotaModalOpen, setIsFriendQuotaModalOpen] = useState(false);


  useEffect(() => {
    Promise.all([
      getContentBlock("My Haulfryn - Membership - Finalise Memberships - Title and description", "titleDescription"),
      getContentBlock("My Haulfryn - Membership - Finalise Memberships - No payment due body & button", "noPaymentDescription"),
      getContentBlock("My Haulfryn - Membership - Finalise Memberships - Payment due body & button", "paymentDescription"),
    ]).then(r => {
      setTitleDescription(r[0]);
      setNoPaymentDescription(r[1]);
      setPaymentDescription(r[2]);
      setContentLoaded(true);
    })
  }, []);

  const options = {
    renderNode: {
      [INLINES. HYPERLINK]: ({ data }: any, children: any) => {
        return <a  href={data.uri} target={data.uri.startsWith(window.origin) ? '' : '_blank'}>{children}</a>;
      }
    }
}

  const getContentBlock = async (name: string, label: string) => {
    const text = await GetHTMlContentBlockCall(name, label);
    return text;
  }

  const setPaymentMethod = async (basketId: string, paymentMethod: string): Promise<boolean> => {
    let paymentMethodDTO = { basketId: basketId, paymentMethod: paymentMethod } as PaymentMethodDTO;

    let value = await Post<any>(new PostSetPaymentMethodCall(paymentMethodDTO))
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      })

    return value;
  }

  const updateTermsAndConditionsSignedDate = async (basketId: string): Promise<boolean> => {
    let value = await Get<any>(new GetUpdateTermsAndConditionsSignedDateCall(basketId))
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      })
    return value;
  }

  const finaliseAndPay = async () => {
    setIsLoading(true);
    await updateTermsAndConditionsSignedDate(props.basketId);

    let frequencyFormula: FrequencyFormula;

    if (paymentPlan) {
      frequencyFormula = props.paymentDetails.PaymentTypes[0].FrequencyFormula
    } else {
      frequencyFormula = FrequencyFormula.OneOff;
    }

    if (paymentPlan) {
      let value = await setPaymentMethod(paymentPlan.basketId, paymentPlan.paymentMethod);
    } else {
      if (props.paymentDetails.PaymentTypes.length === 1) {
        if (props.paymentDetails.PaymentTypes[0].FrequencyFormula === FrequencyFormula.OneOff) {
          let value = await setPaymentMethod(props.basketId, "Card");
        } else if (props.paymentDetails.PaymentTypes[0].FrequencyFormula === FrequencyFormula.Monthly) {
          let value = await setPaymentMethod(props.basketId, "DirectDebit");
        } else if (props.paymentDetails.PaymentTypes[0].FrequencyFormula === FrequencyFormula.Annually) {
          let value = await setPaymentMethod(props.basketId, "Card");
        }
      }
      else {
        setIsLoading(false);
      }
    }

    GetStripeUrl(frequencyFormula);
  }

  const GetStripeUrl = (frequencyFormula: FrequencyFormula) => {
    Get<MembershipPaymentLinksDTO | FriendQuotaExceededDTO[]>(new GetStripeCheckoutUrlsCall(props.basketId, frequencyFormula))
      .then(response => {
        let data = (response.data as MembershipPaymentLinksDTO);
        if (data.CheckoutRequired === undefined) {
          let dates = response.data as FriendQuotaExceededDTO[];
          dates.map(quota => { quota.dateExceeded = new Date(quota.dateExceeded) });
          setDatesExceededFriendQuota(dates);
          setIsLoading(false);
          setIsFriendQuotaModalOpen(true);
        } else {
          if (data.CheckoutRequired) {
            window.location.assign(data.InitialCheckoutLink);
          } else {
            window.location.assign(data.CompleteLink);
          }
        }
      })
      .catch(() => {
        setIsLoading(false);
      })
  }

  const onAgreementSeen = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setisAgreementChecked(true);
    } else {
      setisAgreementChecked(false);
    }
  };

  const getFormulaText = (frequencyFormula: FrequencyFormula) => {
    if (frequencyFormula === FrequencyFormula.OneOff) {
      return "One Off";
    } else if (frequencyFormula === FrequencyFormula.Monthly) {
      return "Monthly";
    } else {
      return "Yearly";
    }
  }

  const updateValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "0") {
      setPaymentPlan({ basketId: props.basketId, paymentMethod: "Card" });
    } else if (e.target.value === "1") {
      setPaymentPlan({ basketId: props.basketId, paymentMethod: "DirectDebit" });
    } else if (e.target.value === "2") {
      setPaymentPlan({ basketId: props.basketId, paymentMethod: "Card" });
    }
  }

  const isPaymentRequired = (paymentTypes: PaymentTypeDTO[]) => {
    for (let i = 0; i < paymentTypes.length; i++) {
      if (paymentTypes[i].DueToday > 0) {
        return true;
      } else if (paymentTypes[i].Recurring > 0) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {contentLoaded ? (
        <div className="widget-container">
          <Desktop>
            {!props.isHeaderOpen && (
              <>
                <h1 className="widget-layout-title">{t("finaliseMemberships.title")}</h1>
                <p className="widget-layout-message">{t("finaliseMembership.message")}</p>
              </>
            )}
          </Desktop>

          <Widget
            position={{
              columnIndex: 1,
              columnSpan: 12,
              rowIndex: 3,
              rowSpan: 9,
            }}
          >
            <div style={{ margin: "15px" }}>

              <div className='row' style={{ marginBottom: "15px" }}>
                <div className='float-left'>
                  <h2 id="smTitle" style={{ margin: "0px" }}>{titleDescription!.title}</h2>
                </div>
              </div>

              <div className="row contentful-text" style={{ marginBottom: "20px" }}>
                {documentToReactComponents(titleDescription!.content, options)}
              </div>

              {props.paymentDetails && (
                <>
                  <div className="row grid-container-payment">
                    <div className="payment-data">
                      <details>
                        <summary>Memberships <FontAwesomeIcon className="details-chevron" style={{ float: "right" }} icon={faChevronDown} /> </summary>
                        {props.paymentDetails.Memberships.map(member => (
                          <MemberBreakdownContainer
                            currencySymbol={props.paymentDetails.PaymentCurrencySymbol}
                            details={member}
                            isAnnually={true}
                          />
                        ))}
                      </details>
                      {props.paymentDetails.PaymentTypes.length > 0 && isPaymentRequired(props.paymentDetails.PaymentTypes) && (
                        <>
                          {props.paymentDetails.PaymentTypes.length > 1 && (
                            <h3 style={{ marginTop: "10px" }}>Payment Plans:</h3>
                          )}
                          {props.paymentDetails.PaymentTypes.map(paymentType => (
                            <>
                              {props.paymentDetails.PaymentTypes.length > 1 && (
                                <h4 style={{ marginBottom: "10px", marginTop: "10px" }}>{getFormulaText(paymentType.FrequencyFormula) + ":"}</h4>
                              )}
                              <details>
                                <summary>
                                  {paymentType.DueToday > 0 && (
                                    <p>Due today: {props.paymentDetails.PaymentCurrencySymbol + paymentType.DueToday}<FontAwesomeIcon className="details-chevron" style={{ float: "right" }} icon={faChevronDown} /></p>
                                  )}
                                  {paymentType.Recurring > 0 && (
                                    <p>{getFormulaText(paymentType.FrequencyFormula) + " fee: " + props.paymentDetails.PaymentCurrencySymbol + paymentType.Recurring}</p>
                                  )}
                                </summary>
                                {props.paymentDetails.Memberships.map(membership => (
                                  <MemberPaymentContainer
                                    currencySymbol={props.paymentDetails.PaymentCurrencySymbol}
                                    membership={membership}
                                    frequencyFormula={paymentType.FrequencyFormula}
                                  />
                                ))}
                                {paymentType.NextPaymentDate && (
                                  <p className="next-payment-date">Your next payment will be taken {(new Date(paymentType.NextPaymentDate)).toDateString()}</p>
                                )}
                              </details>
                              <div className="payment-breakdown">
                              </div>
                            </>
                          ))}
                        </>
                      )}

                      <div style={{ marginBottom: "20px" }}>
                        {props.paymentDetails.PaymentTypes.length > 1 && isPaymentRequired(props.paymentDetails.PaymentTypes) && (
                          <>
                            <label>
                              Please select a payment plan:
                            </label>
                            <div>
                              {props.paymentDetails.PaymentTypes.map(paymentType => (
                                <div style={{ marginTop: "10px" }}>
                                  <input
                                    id={getFormulaText(paymentType.FrequencyFormula)}
                                    type="radio"
                                    value={paymentType.FrequencyFormula}
                                    name="paymentOption"
                                    onChange={updateValue}
                                  />
                                  <span>{getFormulaText(paymentType.FrequencyFormula)}</span>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row">

                        <p style={{ textAlign: "center" }}>
                          {(props.paymentDetails.PaymentTypes.length > 0 && isPaymentRequired(props.paymentDetails.PaymentTypes)) ? documentToReactComponents(paymentDescription!.content, options) : documentToReactComponents(noPaymentDescription!.content, options)}
                        </p>                        

                        <div className='agreement-checkbox'>
                          <input
                            id="agreementCheckbox"
                            type="checkbox"
                            name="agreementchecked"
                            required
                            onChange={onAgreementSeen}
                          />
                          <label htmlFor="agreementCheckbox">I accept the terms and conditions</label>
                        </div>
                      </div>

                      <Mobile>
                        <div className="center-input">
                          <div style={{ fontWeight: "bold" }}>
                            <PrimaryButton
                              id="Card"
                              text={(props.paymentDetails.PaymentTypes.length > 0 && isPaymentRequired(props.paymentDetails.PaymentTypes)) ? paymentDescription!.title : noPaymentDescription!.title}
                              onClick={finaliseAndPay}
                              isLoading={isLoading}
                              disabled={isLoading || !isAgreementChecked}
                              width="300px"
                            />
                          </div>
                        </div>
                      </Mobile>
                    </div>
                  </div>

                  <Desktop>
                    <div className="row">
                      {/* <div className="column center-input">
                      <div style={{ fontWeight: "bold" }}>
                        <PrimaryButton
                          text={t("addMembership.paymentType.directDebit.selectTitle")}
                          onClick={onClick1}
                          isLoading={isLoading}
                          disabled={isLoading}
                        />
                      </div>
                    </div> */}
                      {/* <div className="column center-input"> */}
                      <div style={{ fontWeight: "bold", textAlign: "center" }}>
                        <PrimaryButton
                          id="Card"
                          text={(props.paymentDetails.PaymentTypes.length > 0 && isPaymentRequired(props.paymentDetails.PaymentTypes)) ? paymentDescription!.title : noPaymentDescription!.title}
                          onClick={finaliseAndPay}
                          isLoading={isLoading}
                          disabled={isLoading || !isAgreementChecked}
                          width="500px"
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </Desktop>
                </>
              )}
            </div>
          </Widget>
        </div >
      ) : (
        <div className='memberships-spinner'>
          <Spinner />
        </div>
      )}
      {datesExceededFriendQuota && (
        <FriendMembershipModal
          isOpen={isFriendQuotaModalOpen}
          closeModal={() => setIsFriendQuotaModalOpen(false)}
          datesExceededFriendQuota={datesExceededFriendQuota}
          isFinalisePage={true}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    isHeaderOpen: state.application.isHeaderOpen,
    basketId: state.guests.basketId,
    paymentDetails: state.guests.paymentSummary
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPaymentDetailsDTO: (paymentDetails: PaymentBreakdownDTO) => {
      dispatch({ type: ActionTypes.SET_PAYMENT_SUMMARY_DTO, payload: paymentDetails });
      return Promise.resolve();
    },
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SelectPayment));

function dispatch(arg0: { type: any; payload: any; }) {
  throw new Error("Function not implemented.");
}
