import React from "react";
import {
  Modal,
  ModalProps,
} from "../../../Shared/Components/Presentational/Modal";
import { useTranslation } from "react-i18next";
import "./CalendarItemDetailsModal.scss";
import { ClickableIcon } from "../../../Shared/Components/Presentational/Icons";
import {
  faArrowLeft,
  faPoundSign,
  faCalendarAlt,
  faPaw,
  faInfo,
  faClock,
  faLocationArrow,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import { faAccessibleIcon } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectedCalendarWidgetOptions } from "../../Redux/ActionTypes";
import classNames from "classnames";
import { Entry } from "contentful";
import { Button } from "../../../Shared/Components/Presentational/Buttons";

interface CalendarItemDetailsModalProps extends ModalProps {
  isOpen: boolean;
  item: Partial<Entry<ContentfulEvent & ContentfulOffer>>;
  itemType: SelectedCalendarWidgetOptions;
  onBackButtonClick: () => void;
}

const CalendarItemDetailsModal: React.FC<CalendarItemDetailsModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const calenderItem = props.item && props.item.fields!;
  if (calenderItem == null || calenderItem == undefined) {return <></>;}

  const onImageError = (
    errorEvent: React.SyntheticEvent<HTMLDivElement, Event>
  ) => {
    console.log("Error triggered: " + errorEvent);
    (errorEvent.target as any).src =
      props.itemType === "events"
        ? "/images/default-event-image.png"
        : "/images/default-offer-image.png";
  };

  const getImageSrc = () => {
    if (calenderItem.image) {
      return calenderItem.image.fields.file.url;
    } else {
      return props.itemType === "events"
        ? "/images/default-event-image.png"
        : "/images/default-offer-image.png";
    }
  };
  
  return (
    <Modal
      className={classNames("item-details-modal", props.className)}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
    >
      <div className="item-details-content">
        <h1>{calenderItem.publicName}</h1>
        <div className="item-details-main">
          <div>
            {calenderItem.subHeading && (
              <div className="item-details">
                <div className="item-subheading">{calenderItem.subHeading}</div>
              </div>
            )}
            {calenderItem.description && (
              <div className="item-details">
                <div className="item-title">
                  {props.itemType === "events"
                    ? t("myCalendar.events.descriptionTitle")
                    : t("myCalendar.offers.descriptionTitle")}
                </div>
                <div className="item-details-description">
                  {calenderItem.description}
                </div>
              </div>
            )}
            {calenderItem.bookingPhoneNumber && (
              <div className="item-details">
                <div className="item-title">
                  {t("myCalendar.events.callNow")}
                </div>
                <div className="item-details-phone-number">
                  <a href={`tel:${calenderItem.bookingPhoneNumber}`}>{calenderItem.bookingPhoneNumber}</a>
                </div>
              </div>
            )}
          </div>

          <div className="event-image-container">
            <img
              className="item-image"
              src={getImageSrc()}
              onError={(errorEvent) => onImageError(errorEvent)}
              alt="error"
            />
          </div>
        </div>
        <div className="item-details-summary">
          <div className="item-details-summary-column">
            {calenderItem.termsAndConditions && (
              <div className="item-details">
                <div className="item-title">
                  <FontAwesomeIcon icon={faFileContract} />{" "}
                  {t("myCalendar.events.termsAndConditions")}
                </div>
                <div className="item-description">
                  {calenderItem.termsAndConditions}
                </div>
              </div>
            )}
            {calenderItem.price && (
              <div className="item-details">
                <div className="item-title">
                  <FontAwesomeIcon icon={faPoundSign} />{" "}
                  {t("myCalendar.events.price")}
                </div>
                <div className="item-description">{calenderItem.price}</div>
              </div>
            )}
            {/* {calenderItem.Location && (
              <div className="item-details">
                <div className="item-title">
                  <FontAwesomeIcon icon={faLocationArrow} />{" "}
                  {t("myCalendar.events.location")}
                </div>
                <div className="item-description">{calenderItem.Location}</div>
              </div>
            )} */}
            {calenderItem.information && (
              <div className="item-details">
                <div className="item-title">
                  <FontAwesomeIcon icon={faInfo} />{" "}
                  {t("myCalendar.events.information")}
                </div>
                <div className="item-description">{calenderItem.information}</div>
              </div>
            )}
          </div>
          <div className="item-details-summary-column">
            {calenderItem.date && (
              <div className="item-details">
                <div className="item-title">
                  <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                  {t("myCalendar.events.date")}
                </div>
                <div className="item-description">{calenderItem.date}</div>
              </div>
            )}
            {calenderItem.accessibility && (
              <div className="item-details">
                <div className="item-title">
                  <FontAwesomeIcon icon={faAccessibleIcon} />{" "}
                  {t("myCalendar.events.accessibility")}
                </div>
                <div className="item-description">{calenderItem.accessibility}</div>
              </div>
            )}
            {calenderItem.dogFriendly && (
              <div className="item-details">
                <div className="item-title">
                  <FontAwesomeIcon icon={faPaw} />{" "}
                  {t("myCalendar.events.dogFriendly")}
                </div>
                <div className="item-description">
                  {calenderItem.dogFriendly ? t("general.yes") : t("general.no")}
                </div>
              </div>
            )}
          </div>
          <div className="item-details-summary-column">
            {calenderItem.eventTime && (
              <div className="item-details">
                <div className="item-title">
                  <FontAwesomeIcon icon={faClock} />{" "}
                  {t("myCalendar.events.time")}
                </div>
                <div className="item-description">{calenderItem.eventTime}</div>
              </div>
            )}
          </div>
        </div>
        {calenderItem.linkButtonText && 
        (calenderItem.linkButtonUrl || calenderItem.linkUrl) && (
        <Button
            className={classNames('btn-primary', 'action-button-calendar-items')}
            text={calenderItem.linkButtonText}
            onClick={() => window.open(calenderItem.linkButtonUrl || calenderItem.linkUrl,'_blank')}
        />)}
        <ClickableIcon
          className="back-button back-button-calendar-items"
          icon={faArrowLeft}
          clickAction={props.onBackButtonClick}
        />
      </div>
    </Modal>
  );
};

export default CalendarItemDetailsModal;
