import React from 'react';
import './AddPassModalHeader.scss';

interface AddPassModalHeaderProps {
    headerText: string;
}

const AddPassModalHeader: React.FC<AddPassModalHeaderProps> = props => {
    return <h1 className="add-pass-modal-header">{props.headerText}</h1>;
};

export default AddPassModalHeader;