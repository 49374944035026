import React, { Component } from "react";
import {
  LinkWidget,
  Widget,
  ClickableWidget,
  WidgetHeader,
  WidgetList,
} from "../../../Shared/Components/Presentational/Widget";
import {
  CalendarEvent,
  CalendarResource,
} from "../../../Shared/Components/Presentational/Calendar";
import { WithTranslation, withTranslation } from "react-i18next";
import CalendarWidget from "./CalendarWidget";
import WelcomeMessageWidget from "./WelcomeMessageWidget";
import MenuWidget from "./MenuWidget";
import * as Routes from "../../../Constants/Routes";
import "./HomePage.mobile.scss";
import DropdownListWidget from "./DropdownListWidget";
import FeatureToggle from "../../../Shared/Components/Presentational/FeatureToggle";
import Features from "../../../Constants/Features";
import NavOption from "../../../Shared/Components/Presentational/NavOption";
import Grid from "../../../Shared/Components/Presentational/Grid";
import TransactionWidget from "./TransactionWidget";
import ReservationLinks from "../../../Calendar/Components/Container/ReservationLinks";
import CalendarListWidget from "../../../Calendar/Components/Container/CalendarListWidget";
import MyCalendarListHeader from "../../../Calendar/Components/Container/MyCalendarListHeader";
import NewsCardContainer from "../../../Information/Components/Container/NewsCardContainer";

interface HomePageMobileProps extends WithTranslation {
  calendarEvents: CalendarEvent[];
  resources: CalendarResource[];
  userFirstName: string;
  selectedLodge: DropdownListOption;
  options: [DropdownListOption];
  onSelectionChanged: (newValue: DropdownListOption) => void;
  contractEndDate: string;
  openMyContactDetailsModal: () => void;
  showMyDetailsLink: boolean;
  onContactHaulfrynClick: (isOpen: boolean) => void;
  hasSublettings: boolean;
  accountId: number;
  accountNumber: string;
  setAccountBalance: (newBalance: number) => void;
  setSelectedNewsItem: (selectedNewsItemId: string) => void;
  useMemberships: boolean;
}

class HomePageMobile extends Component<HomePageMobileProps> {


  render() {
    const { t } = this.props;
    return (
      <>
        <div className="widget-container-top mobile-home-widget-container">
          <WelcomeMessageWidget
            userFirstName={this.props.userFirstName}
            position={{
              columnIndex: 1,
              columnSpan: 12,
              rowIndex: 1,
              rowSpan: 2,
            }}
          />
        </div>
        <MenuWidget
          position={{
            columnIndex: 1,
            columnSpan: 12,
            rowIndex: 3,
            rowSpan: 3,
          }}
        >
          <div className="navigation-scroll">
            <div className="widget-mobile-container">
              <FeatureToggle features={[Features.ParkInfo]}>
                <LinkWidget
                  to={Routes.MyParkAndHome}
                  containerClassName="nav-option"
                >
                  <NavOption
                    text={t("navigation.myParkAndHome")}
                    icon="my-park-and-home.svg"
                    iconAlt="My park and home"
                  />
                </LinkWidget>
              </FeatureToggle>
            </div>
            {this.props.useMemberships ? (
              <div className="widget-mobile-container">
                <LinkWidget
                  to={Routes.MyGuests}
                  id="MyMemberships"
                  containerClassName="nav-option"
                >
                  <NavOption
                    text={t("navigation.myMemberships")}
                    icon="my-guests.svg"
                    iconAlt="My Members"
                  />
                </LinkWidget>
              </div>
            ) : (
              <div className="widget-mobile-container">
                <FeatureToggle features={[Features.Passes]}>
                  <LinkWidget
                    to={Routes.MyGuests2}
                    id="MyGuests"
                    containerClassName="nav-option"
                  >
                    <NavOption
                      text={t("navigation.myGuests")}
                      icon="my-guests.svg"
                      iconAlt="My Guests"
                    />
                  </LinkWidget>
                </FeatureToggle>
              </div>
            )}
            {this.props.hasSublettings && (
              <div className="widget-mobile-container">
                <FeatureToggle features={[Features.SubletBookings, Features.OwnerBookings]}>
                <LinkWidget
                  to={Routes.MyCalendar}
                  containerClassName="nav-option"
                >
                  <NavOption
                    text={t("navigation.myCalendar")}
                    icon="my-calendar.svg"
                    iconAlt="My calendar"
                  />
                </LinkWidget>
                </FeatureToggle>
              </div>
            )}
            {this.props.showMyDetailsLink ? (
              <div className="widget-mobile-container">
                <ClickableWidget
                  containerClassName="nav-option"
                  onClick={this.props.openMyContactDetailsModal}
                >
                  <NavOption
                    text={t("general.myDetails")}
                    icon="my-details.svg"
                    iconAlt="My details"
                  />
                </ClickableWidget>
              </div>
            ) : null}
            <div className="widget-mobile-container">
              <FeatureToggle features={[Features.MyAccount]}>
                <LinkWidget to={Routes.MyAccount} containerClassName="nav-option">
                  <NavOption
                    text={t("navigation.myAccount")}
                    icon="my-account.svg"
                    iconAlt="My account"
                  />
                </LinkWidget>
              </FeatureToggle>
            </div>
            <div className="widget-mobile-container">
              <FeatureToggle features={[Features.ContactHaulfryn]}>
                <ClickableWidget
                  containerClassName="nav-option"
                  onClick={() => this.props.onContactHaulfrynClick(true)}
                >
                  <NavOption
                    text={t("contactHaulfryn.title")}
                    icon="contact-haulfryn.svg"
                    iconAlt="Contact Haulfryn"
                  />
                </ClickableWidget>
              </FeatureToggle>
            </div>
            <div className="widget-mobile-container">
              <FeatureToggle features={[Features.MyRequests]}>
                <LinkWidget
                  to={Routes.MyRequestsAndQueries}
                  containerClassName="nav-option"
                >
                  <NavOption
                    text={t("navigation.myRequests")}
                    icon="my-requests.svg"
                    iconAlt="My requests"
                  />
                </LinkWidget>
              </FeatureToggle>
            </div>
          </div>
        </MenuWidget >
        {/* <Grid
          className="mobile-home-widget-container"
          rowCount={2}
          columnCount={12}
        > */}
        <div>
          <FeatureToggle features={[Features.LodgesDropdown]}>
            {this.props.options.length === 1 ? (
              <Widget
                position={{
                  columnIndex: 1,
                  columnSpan: 12,
                  rowIndex: 1,
                  rowSpan: 1,
                }}
                containerClassName="standard-widget-container-mobile"
              >
                <div>{this.props.options[0].label}</div>
              </Widget>
            ) : (
              <DropdownListWidget
                className="mobile-select-container"
                classNamePrefix="mobile-select"
                label={""}
                options={this.props.options}
                defaultItem={this.props.selectedLodge}
                onSelectionChanged={this.props.onSelectionChanged}
                position={{
                  columnIndex: 1,
                  columnSpan: 12,
                  rowIndex: 1,
                  rowSpan: 1,
                }}
              />
            )}
          </FeatureToggle>
          <FeatureToggle features={[Features.Transactions]}>
            <TransactionWidget
              position={{
                columnIndex: 1,
                columnSpan: 12,
                rowIndex: 2,
                rowSpan: 8,
              }}
              accountId={this.props.accountId}
              accountNumber={this.props.accountNumber}
              setAccountBalance={this.props.setAccountBalance}
            />
          </FeatureToggle>
          {this.props.hasSublettings && (
            <FeatureToggle features={[Features.SubletBookings, Features.OwnerBookings]}>
            <CalendarWidget
              events={this.props.calendarEvents}
              resources={this.props.resources}
              position={{
                columnIndex: 1,
                columnSpan: 12,
                rowIndex: 10,
                rowSpan: 6,
              }}
            />
            </FeatureToggle>
          )}
          {!this.props.hasSublettings && (
            <Widget
              containerClassName="reservation-links-widget-container"
              position={{
                columnIndex: 1,
                columnSpan: 12,
                rowIndex: 10,
                rowSpan: 6,
              }}
            >
              <WidgetHeader
                title={t("myCalendar.reservations.header")}
              ></WidgetHeader>
              <ReservationLinks
                showHeader={false}
                lodgeId={Number(this.props.selectedLodge.value)}
                accountId={this.props.accountId}
              />
            </Widget>
          )}
          {this.props.contractEndDate && (
            <Widget
              position={{
                columnIndex: 1,
                columnSpan: 12,
                rowIndex: 16,
                rowSpan: 1,
              }}
              containerClassName="standard-widget-container-mobile"
            >
              <div>{t("general.contractEnd")}</div>
              <div className="standard-widget-text">
                {this.props.contractEndDate}
              </div>
            </Widget>
          )}
        </div>
        {/* </Grid> */}
      </>
    );
  }
}

export default withTranslation()(HomePageMobile);
