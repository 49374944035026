import { combineReducers } from 'redux';
import login from './Login/Redux/Reducers';
import user from './User/Redux/Reducers';
import guests from './Guests/Redux/Reducers';
import information from './Information/Redux/Reducers';
import calendar from './Calendar/Redux/Reducers';
import application from './Shared/Redux/Reducers';
import account from './Account/Redux/Reducers';
import modal from './Modal/Redux/Reducers';
import parQForm from './ParQForm/Redux/Reducers';

export const rootReducer = combineReducers({
    login,
    user,
    guests,
    information,
    calendar,
    application,
    account,
    modal,
    parQForm
});

export type AppState = ReturnType<typeof rootReducer>;
