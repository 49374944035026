import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Post } from '../../../Api/ApiService';
import { PostRegisterCall } from '../../../Api/Calls/PostRegisterCall';
import { BackButton, LinkButton } from '../../../Shared/Components/Presentational/Buttons';
import './Register.scss';
import RegisterDetails from './RegisterDetails';
import RegisterPassword from './RegisterPassword';
import { Redirect } from 'react-router-dom';
import * as Routes from '../../../Constants/Routes';
import { PostResendConfirmAccountCall } from '../../../Api/Calls/PostResendConfirmAccountCall';
import GoogleLogin from 'react-google-login';
import { googleClientId, facebookAppId } from '../../../Shared/Environment';
//@ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';


export interface RegisterPopupState {
    email: string;
    firstName: string;
    lastName: string;
    isOnDetails: boolean;
    registerComplete: boolean;
    isRegistering: boolean;
    errorMessage: string;
    redirectLogin: boolean;
    newUserId: string;
}

export interface RegisterPopupProps extends WithTranslation {
    isMobile: boolean;
    isLoggingIn: boolean;
    loginClick: (email: string, password: string, type: string) => void;
    loginFailureMessage: string;
}

class RegisterPopup extends Component<RegisterPopupProps, RegisterPopupState> {

    constructor(props: RegisterPopupProps) {
        super(props);

        this.responseGoogle = this.responseGoogle.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
    }

    public state: RegisterPopupState = {
        email: '',
        firstName: '',
        lastName: '',
        isOnDetails: true,
        registerComplete: false,
        isRegistering: false,
        errorMessage: '',
        redirectLogin: false,
        newUserId: ''
    };

    onDetailsEntered = (email: string, firstName: string, lastName: string) => {
        this.setState({ email, firstName, lastName, isOnDetails: false });
    };

    onSubmit = async (e: any, password: string) => {
        e.preventDefault();
        this.setState({ isRegistering: true });

        const details: UserDTO = {
            Email: this.state.email,
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Password: password
        };

        try {
            await Post<string>(new PostRegisterCall(details)).then(userId => { 
                this.setState({ registerComplete: true, newUserId: userId.data });
            });
        } catch (e) {
            this.setState({ errorMessage: e.response.data });
        } finally {
            this.setState({ isRegistering: false });
        }
    };

    handleBack = () => {
        if (this.state.registerComplete || this.state.isOnDetails) {
            this.setState({ redirectLogin: true });
        } else {
            this.setState({ isOnDetails: true });
        }
    };

    resendConfirmationEmail = () => {

        const userId = this.state.newUserId;

        Post<{}>(new PostResendConfirmAccountCall({ UserId: userId, Code: '' })).then(() => {
        });
    }

    private responseGoogle(response: any) {
        if (response.tokenId) {
            this.props.loginClick('', response.tokenId, 'google');
        }
    }

    private responseFacebook(response: any) {
        if (response.accessToken) {
            this.props.loginClick('', response.accessToken, 'facebook');
        }
    }

    render() {
        const { t } = this.props;

        if (this.state.redirectLogin) {
            return <Redirect to={Routes.Login} />;
        }

        return (
            <div className="register-popup-container">
                <BackButton onClick={this.handleBack} text={t('login.back')} className="login-back" />

                <p className={classNames('register-explanation', this.props.isMobile ? 'mobile' : '')} dangerouslySetInnerHTML={{ __html: "Welcome to My Haulfryn, please register using the email address that we have for you.<br/>If your Google account also uses this email address, you can use it to register; otherwise, please complete the form below." }}></p> {/*t('register.registrationExplanation') old explanation*/}

                <div className={classNames('external-login-container', 'register', { mobile: this.props.isMobile })}>
                    {/* <div className="facebook-login-button-container"> disabled as broken
                        <FacebookLogin
                            appId={facebookAppId}
                            fields="email"
                            callback={this.responseFacebook}
                            render={(renderProps: any) => (
                                <button
                                    className={'facebook-login-button'}
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled || this.props.isLoggingIn}
                                >
                                    <img src="images/facebook-logo.svg" alt="Facebook" />
                                    <span className={classNames({ mobile: this.props.isMobile })}>Continue with Facebook</span>
                                </button>
                            )}
                        />
                    </div> */}
                    <div className="google-login-button-container">
                        <GoogleLogin
                            clientId={googleClientId}
                            render={renderProps => (
                                <button
                                    className={'google-login-button'}
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled || this.props.isLoggingIn}
                                >
                                    <img src="images/Google__G__Logo.svg" alt="Google" />
                                    <span className={classNames({ mobile: this.props.isMobile })}>Continue with Google</span>
                                </button>
                            )}
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                        />
                    </div>
                    <p
                        className={classNames(this.props.isMobile ? 'login-failure-message' : 'error-message', 'register-login-error', {
                            show: this.props.loginFailureMessage
                        })}
                    >
                        {t(this.props.loginFailureMessage)}
                    </p>
                </div>

                {!this.state.registerComplete && this.state.isOnDetails && (
                    <RegisterDetails
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        email={this.state.email}
                        onSubmit={this.onDetailsEntered}
                        isMobile={this.props.isMobile}
                        isLoggingIn={this.props.isLoggingIn}
                    />
                )}
                {!this.state.registerComplete && !this.state.isOnDetails && (
                    <RegisterPassword
                        onSubmit={this.onSubmit}
                        isRegistering={this.state.isRegistering}
                        errorMessage={this.state.errorMessage}
                        isMobile={this.props.isMobile}
                    />
                )}
                {this.state.registerComplete && (
                    <div className="registration-complete">
                        <p className="register-step-title complete">{t('register.registrationComplete')}</p>
                        <p className="register-complete-text register-complete-thank">
                            {t('register.thankForRegister')}
                        </p>
                        <p className="register-complete-text">{t('register.accountCreated')}</p>
                        <p className="register-complete-resend">
                            <span>{t('register.resendLinkPre')} </span>
                            <LinkButton onClick={this.resendConfirmationEmail} text={t('register.resendLink')} />
                        </p>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(RegisterPopup);
